import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/es/button';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { Button } from 'app-wrapper/view/components';

export const AddServiceButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button icon={<PlusOutlined />} {...props}>
      {t('Add Service(s)')}
    </Button>
  );
};
