import React, { FC, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { RouteNames } from 'app-wrapper/constants';
import {
  ContainerReeferTypesNamesLongConst,
  ContainerUsualTypesNamesLongConst,
  ContainerReeferTypes,
  ContainerUsualTypes,
} from 'shipment-operations/constants';
import { ContainerSumWithTypeDTM } from 'shipment-operations/models/dtm';
import { NoDataStub } from 'shipment-operations/view/components';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  OverviewContainersWrap,
  OverviewContainersTitle,
  OverviewContainersTableCell,
  OverviewContainersTable,
} from './ShipmentOverviewContainers.styled';
import { ShipmentOverviewMoreDetails } from '../index';

interface ShipmentOverviewContainersComponentProps {
  containersSumWithType: ContainerSumWithTypeDTM[];
  isLoading: boolean;
}

export const ShipmentOverviewContainersComponent: FC<ShipmentOverviewContainersComponentProps> = ({ containersSumWithType, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shipmentId = '' } = useParams();

  const handleRedirect = useCallback(() => {
    navigate(RouteNames.SHIPMENT_CONTAINERS(shipmentId));
  }, [navigate, shipmentId]);

  const columns = useMemo(() => [
    {
      title: t('Container Type'),
      dataIndex: 'type',
      key: 'type',
      width: '25%',
      render: (item: ContainerUsualTypes | ContainerReeferTypes) => {
        const containerName = ContainerUsualTypesNamesLongConst[item as ContainerUsualTypes] || ContainerReeferTypesNamesLongConst[item as ContainerReeferTypes];

        return (
          <OverviewContainersTableCell>
            {containerName}
          </OverviewContainersTableCell>
        );
      },
    },
    {
      title: t('QTY'),
      dataIndex: 'containersNumber',
      key: 'containers-qty',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <OverviewContainersTableCell>
          {item}
        </OverviewContainersTableCell>
      ),
    },
    {
      title: t('Pack QTY'),
      dataIndex: 'packagesNumber',
      key: 'packages-qty',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <OverviewContainersTableCell>
          {item}
        </OverviewContainersTableCell>
      ),
    },
    {
      title: t('Weight, kg'),
      dataIndex: 'weight',
      key: 'weight',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <OverviewContainersTableCell>
          {item}
        </OverviewContainersTableCell>
      ),
    },
    {
      title: t('Volume, cbm'),
      dataIndex: 'volume',
      key: 'volume',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <OverviewContainersTableCell>
          {item}
        </OverviewContainersTableCell>
      ),
    },
    {
      title: t('Value'),
      dataIndex: 'value',
      key: 'value',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <OverviewContainersTableCell>
          {Number(item).toFixed(2)} $
        </OverviewContainersTableCell>
      ),
    },
  ], []);

  if (isLoading) {
    return <SkeletonSection height="205px" />;
  }

  return (
    <OverviewContainersWrap>
      <>
        <OverviewContainersTitle>
          {t('Containers')}
        </OverviewContainersTitle>

        <OverviewContainersTable
          locale={{
            emptyText: <NoDataStub onAddData={handleRedirect} />,
          }}
          dataSource={containersSumWithType}
          pagination={false}
          columns={columns}
        />

        {containersSumWithType.length ? (
          <ShipmentOverviewMoreDetails onClick={handleRedirect} />
        ) : null}
      </>
    </OverviewContainersWrap>
  );
};
