import styled from 'styled-components';

import AntdUpload from 'antd/es/upload';

export const Upload = styled(AntdUpload)`
  display: flex;
  flex-direction: column;
`;

export const UploadButton = styled.button`
  background: none;
  border: none;
`;
