import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { ShippingPartyContactDTM } from 'shipment-operations/models/dtm';
import { Drawer, Option, Select } from 'app-wrapper/view/components';
import { InputTitle } from 'app-wrapper/view/guideline';
import {
  Content,
  SpinnerWrap,
} from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

import { DrawerFooter } from './components';
import { Fields } from './BookingDrayageSelectContact.styled';

const DRAWER_WIDTH = 400;

interface IBookingDrayageSelectContactComponentProps {
  onClose: () => void;
  isLoading: boolean;
  contacts?: ShippingPartyContactDTM[];
  contactId?: number | null;
  setContactId: (contactId: number) => void;
}

export const BookingDrayageSelectContactComponent: FC<IBookingDrayageSelectContactComponentProps> = ({
  onClose,
  isLoading,
  contacts,
  setContactId,
  contactId,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      visible
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
      push={false}
      title={t('Select Contact')}
    >
      <Content>
        {isLoading ? (
          <SpinnerWrap>
            <Spin size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <Fields>
              <InputTitle title={t('Contact Person')} />

              <Select
                placeholder={t('Select Contact Person')}
                optionFilterProp="children"
                value={contactId}
                onChange={setContactId}
              >
                {contacts?.map((contact) => (
                  <>
                    <Option key={contact.id as number} value={contact.id as number}>{contact.fullName}</Option>
                  </>
                ))}
              </Select>
            </Fields>

            <DrawerFooter />
          </>
        )}
      </Content>
    </Drawer>
  );
};
