import SearchOutlined from '@ant-design/icons/SearchOutlined';
import React from 'react';
import styled from 'styled-components';
import AntInput from 'antd/es/input';

import { InputCss } from 'app-wrapper/view/styles/mixins';

export const InputSearch = styled(AntInput).attrs((_: {
  prefix: React.ReactElement
}) => ({
  prefix: <SearchOutlined />,
}))`
  background-color: white;
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding6};
  border-radius: 2px;

  .ant-input-prefix {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
  }

  .ant-input {
    background-color: inherit;
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription}
  }
  ${InputCss}

`;
