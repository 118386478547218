import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { EditSuppliersScheduleDrawerController } from 'shipment-operations/controllers/EditSuppliersScheduleDrawer';
import { EditSupplierScheduleStore } from 'shipment-operations/repository/mobxStores/EditSupplierScheduleStore';
import { DatePicker } from 'app-wrapper/view/components';
import { Moment } from 'moment';

export const CargoReadyDate = observer(() => {
  const controller = useCurrentController<EditSuppliersScheduleDrawerController>();
  const store = useCurrentControllerStore<EditSupplierScheduleStore>();
  const { cargoReadyDate } = store.state;

  const handleOnChange = useCallback((value: Moment | null) => {
    controller.cargoReadyDate().onChange(value);
  }, []);

  const handleOnBlur = useCallback(() => {
    controller.cargoReadyDate().onBlur();
  }, []);

  return (
    <DatePicker
      value={cargoReadyDate?.value?.getDateAsMomentLocalOffset()}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
});
