import { USD } from 'shipment-operations/constants';
import { IShipmentActiveChargeState } from 'shipment-operations/models/states';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

const initialState: IShipmentActiveChargeState = {
  fullListCharges: {
    isLoading: false,
    error: false,
    charges: [],
  },
  shipmentParties: {
    companies: [],
    isLoadingCompanies: false,
    loadCompaniesError: false,
    selectedVendorCompany: '',
    selectedCustomerCompany: '',
  },
  activeCharge: {
    applyTo: '',
    currency: USD,
    designation: '',
    priceBy: '',
    measureBy: '',
    description: '',
    chargeCode: {
      code: '',
      description: '',
      mode: '',
      type: '',
      subType: '',
      occurrence: '',
      loadType: '',
      status: '',
    },
    savedContainer: '',
    errorCharge: false,
    APCostPerUnit: undefined,
    APNumberOfUnits: undefined,
    ARCostPerUnit: undefined,
    ARNumberOfUnits: undefined,
    documents: [],
    errors: [],
    containers: [],
    containersError: false,
    selectedContainers: [],
    selectedContainerType: '',
    selectedContainersError: false,
    successCreate: false,
    isLoadingCreate: false,
    isDisableDesignation: false,
  },
  deletedChargeId: null,
  isLoadingDelete: false,
  deleteChargeError: false,
  deleteChargeSuccess: false,
  isDocumentUploading: false,
  globalLoadingData: false,
  isLoadingPaymentTerms: false,
  tradeType: '',
  companies: [],
};

export const ShipmentActiveChargeSlice = createSlice({
  name: 'shipmentActiveCharge',
  initialState,
  reducers: {
    setGlobalLoadingData: (state, { payload }) => ({
      ...state,
      globalLoadingData: payload,
    }),
    setIsDocumentUploading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDocumentUploading: payload,
    }),
    setIsLoadingFullList: (state, { payload }) => ({
      ...state,
      fullListCharges: {
        ...state.fullListCharges,
        isLoading: payload,
      },
    }),
    setErrorFullList: (state, { payload }) => ({
      ...state,
      fullListCharges: {
        ...state.fullListCharges,
        error: payload,
        isLoading: false,
      },
    }),
    setFullLustCharges: (state, { payload }) => ({
      ...state,
      fullListCharges: {
        ...state.fullListCharges,
        error: false,
        isLoading: false,
        charges: payload,
      },
    }),
    setActiveChargeApplyTo: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        applyTo: payload,
      },
    }),
    setActiveChargeMeasureBy: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        measureBy: payload,
      },
    }),
    setActiveChargeDesignation: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        designation: payload,
      },
    }),
    setActiveChargeSelectedCharge: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        chargeCode: payload,
      },
    }),
    setActiveChargeValue: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        [payload.field]: payload.value,
      },
    }),
    setActiveChargeSavedValues: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        APCostPerUnit: payload.APCostPerUnit,
        APNumberOfUnits: payload.APNumberOfUnits,
        ARCostPerUnit: payload.ARCostPerUnit,
        ARNumberOfUnits: payload.ARNumberOfUnits,
        designation: payload.designation,
        measureBy: payload.measureBy,
        applyTo: payload.applyTo,
        description: payload.description,
        chargeCode: payload.chargeCode,
        id: payload.id,
        savedContainer: payload.savedContainer,
        arBudget: payload.arBudget,
        apBudget: payload.apBudget,
        documents: payload.documents,
        rateId: payload.rateId,
      },
    }),
    setComments: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        description: payload,
      },
    }),
    setActiveChargeInputsByFlat: (state) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        APNumberOfUnits: 1,
        ARNumberOfUnits: 1,
      },
    }),
    setActiveChargeInputsByFee: (state) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        APCostPerUnit: 0,
        APNumberOfUnits: 1,
        ARNumberOfUnits: 1,
      },
    }),
    setActiveChargeErrors: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        isLoadingCreate: false,
        errors: payload,
      },
    }),
    clearActiveChargeInputs: (state) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        APCostPerUnit: undefined,
        APNumberOfUnits: undefined,
        ARCostPerUnit: undefined,
        ARNumberOfUnits: undefined,
        applyTo: '',
        measureBy: '',
        designation: '',
        selectedContainerType: '',
      },
    }),
    clearSyncedInfo: (state) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        APCostPerUnit: undefined,
        APNumberOfUnits: undefined,
        ARCostPerUnit: undefined,
        ARNumberOfUnits: undefined,
        measureBy: '',
        designation: '',
      },
    }),
    clearActiveCharge: (state) => ({
      ...state,
      activeCharge: {
        ...initialState.activeCharge,
        successCreate: state.activeCharge.successCreate,
      },
      shipmentParties: { ...initialState.shipmentParties },
    }),
    setActiveChargeErrorCharge: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        isLoadingCreate: false,
        errorCharge: payload,
      },
    }),
    setActiveChargeContainers: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        containers: payload,
        containersError: false,
      },
    }),
    setActiveChargeContainersError: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        containersError: payload,
      },
    }),
    setActiveChargeSelectedContainers: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        selectedContainers: payload,
      },
    }),
    setActiveChargeSelectedContainerType: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        selectedContainerType: payload,
      },
    }),
    setActiveChargeSelectedContainersError: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        isLoadingCreate: false,
        selectedContainersError: payload,
      },
    }),
    setActiveChargeCreateSuccess: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        isLoadingCreate: false,
        successCreate: payload,
        errorCreate: '',
      },
    }),
    setActiveChargeCreateIsLoading: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        isLoadingCreate: payload,
      },
    }),
    setDeletedChargeId: (state, { payload }) => ({
      ...state,
      deletedChargeId: payload,
    }),
    setDeletedChargeLoading: (state, { payload }) => ({
      ...state,
      isLoadingDelete: payload,
    }),
    deleteChargeFailed: (state) => ({
      ...state,
      deleteChargeError: true,
      deleteChargeSuccess: false,
      isLoadingDelete: false,
    }),
    deleteChargeSuccess: (state) => ({
      ...state,
      deleteChargeError: false,
      deleteChargeSuccess: true,
      isLoadingDelete: false,
    }),
    onSetDeleteCharge: (state, { payload }) => ({
      ...state,
      deleteChargeSuccess: payload,
    }),
    onLoadCompaniesSuccess: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        isLoadingCompanies: false,
        companies: payload,
        loadCompaniesError: false,
      },
    }),
    setIsLoadingCompanies: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        isLoadingCompanies: payload,
      },
    }),
    setLoadCompaniesError: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        loadCompaniesError: payload,
      },
    }),
    setSelectedCompany: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        [payload.key]: payload.value,
      },
    }),
    addDocumentToActiveCharge: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        documents: [...state.activeCharge.documents || [], payload],
      },
    }),
    removeDocumentFromActiveCharge: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        documents: state.activeCharge.documents?.filter((doc) => doc.id !== payload),
      },
    }),
    setIsDisableDesignation: (state, { payload }) => ({
      ...state,
      activeCharge: {
        ...state.activeCharge,
        isDisableDesignation: payload,
      },
    }),
    setIsLoadingPaymentTerms: (state, { payload }) => ({
      ...state,
      isLoadingPaymentTerms: payload,
    }),
    setTradeType: (state, { payload }) => ({
      ...state,
      tradeType: payload,
    }),
    setCompanies: (state, { payload }) => ({
      ...state,
      companies: payload,
    }),
    clear: () => (initialState),
  },
});

export const shipmentActiveChargeActions = ShipmentActiveChargeSlice.actions;
export const shipmentActiveChargeReducer = ShipmentActiveChargeSlice.reducer;
