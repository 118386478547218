import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TitleTextSpan,
  BodyTextMediumSpan,
  SmallTextFootnoteDescriptionSpan,
} from 'app-wrapper/view/fonts';
import {
  SubPageContent,
  GapVerticalContainerStyled,
  ContentSection,
  FlexCol,
  RowSpaceBetween,
} from 'app-wrapper/view/guideline';

import { CollapseBodyOnly, CollapseButton } from 'app-wrapper/view/components';
import {
  Content,
  Steps,
  StepNumber,
  Divider,
  StepDescription,
} from './TasksSteps.styled';

interface ITasksStepsProps {
  steps: {
    title: string
    text?: string
  }[]
}

export const TasksSteps = memo(({ steps }: ITasksStepsProps) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapseButtonClick = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, setIsCollapsed]);

  return (
    <SubPageContent>
      <GapVerticalContainerStyled noGap={!isCollapsed}>
        <RowSpaceBetween>
          <TitleTextSpan>
            {t('Steps to Complete the Task')}
          </TitleTextSpan>

          <CollapseButton show={isCollapsed} onClick={handleCollapseButtonClick} />
        </RowSpaceBetween>

        <CollapseBodyOnly
          ghost
          isCollapsed={isCollapsed}
        >
          <ContentSection>
            <Content>
              <Steps>
                {steps.map((step, index) => (
                  <>
                    <StepNumber>
                      {index + 1}
                    </StepNumber>

                    {steps.length !== index + 1 ? <Divider type="vertical" /> : null}
                  </>
                ))}
              </Steps>

              <FlexCol>
                {steps.map((step) => (
                  <StepDescription key={step.title}>
                    <BodyTextMediumSpan>
                      {step.title}
                    </BodyTextMediumSpan>

                    {step.text ? (
                      <SmallTextFootnoteDescriptionSpan>
                        {step.text}
                      </SmallTextFootnoteDescriptionSpan>
                    ) : null}
                  </StepDescription>
                ))}
              </FlexCol>
            </Content>
          </ContentSection>
        </CollapseBodyOnly>
      </GapVerticalContainerStyled>
    </SubPageContent>
  );
});
