import { UploadFile } from 'antd/lib/upload/interface';
import { EChangeRequestType } from 'app-wrapper/constants';
import { NotificationTaskContentAttachmentDTM, NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { action, computed } from 'mobx';
import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { PermissionsDTM } from 'shipment-operations/view/drawers/ChangeRequestDrawer/permissions/Permissions.dtm';

interface FormInputs {
  text?: InputStore<string>
  files: InputStore<UploadFile>[]
}

interface IChangeRequestControllerState {
  containerId?: number
  isSubmitting: boolean
  type: EChangeRequestType
  permissions: PermissionsDTM | undefined
  changeRequest: NotificationTaskContentDTM | undefined
  changeRequestId: number | null
  editMode: boolean
  loadedFiles: NotificationTaskContentAttachmentDTM[]
  form: FormInputs
}

const initialState: IControllerState<IChangeRequestControllerState> = {
  isLoading: true,
  isSubmitting: false,
  type: EChangeRequestType.UPDATE_CONTAINERS_INFORMATION,
  permissions: undefined,
  changeRequest: undefined,
  changeRequestId: null,
  editMode: true,
  loadedFiles: [],
  form: {
    text: new InputStore(),
    files: [new InputStore()],
  },
};

export class ChangeRequestStore extends ControllerStore<IControllerState<IChangeRequestControllerState>> {
  @action
  setSubmitting(isSubmitting: boolean) {
    this.state.isSubmitting = isSubmitting;
  }

  @action
  setType(type: EChangeRequestType) {
    this.state.type = type;
  }

  @action
  setPermissions(permissions: PermissionsDTM) {
    this.state.permissions = permissions;
  }

  @action
  setEditMode(editMode: boolean) {
    this.state.editMode = editMode;
  }

  @action
  setChangeRequest(changeRequest: NotificationTaskContentDTM | undefined) {
    this.state.changeRequest = changeRequest;
  }

  @action
  setChangeRequestId(changeRequestId: number | null) {
    this.state.changeRequestId = changeRequestId;
  }

  @action
  setForm(formInputs: FormInputs) {
    this.state.form = formInputs;
  }

  @action
  setLoadedFiles(loadedFiles: NotificationTaskContentAttachmentDTM[]) {
    this.state.loadedFiles = loadedFiles;
  }

  @action
  addLoadedFiles(loadedFiles: NotificationTaskContentAttachmentDTM[]) {
    this.state.loadedFiles = [...this.state.loadedFiles, ...loadedFiles];
  }

  @action
  deleteLoadedFile(item: NotificationTaskContentAttachmentDTM) {
    const itemIndex = this.state.loadedFiles.findIndex((_item) => _item?.id === item?.id);
    this.state.loadedFiles.splice(itemIndex, 1);
    this.state.loadedFiles = [...this.state.loadedFiles];
  }

  @action
  deleteItem(item: UploadFile) {
    const itemIndex = this.state.form.files.findIndex((_item) => _item?.value?.uid === item?.uid);
    this.state.form.files.splice(itemIndex, 1);
    this.state.form.files = [...this.state.form.files];
  }

  @action
  addFile(file: UploadFile) {
    this.state.form.files = [...this.state.form.files, new InputStore(file)];
  }

  @action
  removeFiles() {
    this.state.form.files = [];
  }

  @computed
  get hasError() {
    return Object.values(this.state.form).some((input: InputStore<any>) => input.hasError);
  }

  get containerId() {
    return this.state.containerId;
  }
}

export const createChangeRequestStore = (_initialState?: IControllerState<IChangeRequestControllerState>) => new ChangeRequestStore(_initialState || initialState);
