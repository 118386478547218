import React, {
  FC, memo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  EFreightIncotermsTrade,
  freightLoadType,
} from 'monetary/constants';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import {
  Col, Option, RadioGroup, Row, TabPane, Tabs,
} from 'app-wrapper/view/components';
import {
  ContainerIcon, RoadFlySvg, RoadShipSvg, RoadSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { RadioButton } from 'app-wrapper/view/components/ant/Radio';
import { useWindowSize } from 'app-wrapper/hooks';

import {
  RFRIncotermsWrap,
  RFRTabs, RFRTabsFreightMode, RFRTabsSelect,
} from './RFRTabs.styled';

interface IRFRTabsComponentProps {
  loadType: string
  incotermsTrade: string
  isAllDisabled?: boolean
  isSubmitVisited?: boolean
  incotermsTradeError?: IDefaultFieldErrors
  onChangeFreightLoadType: (value: string) => void
  onChangeFreightIncotermsTrade: (value: string) => void
}

const RFRTabsComponent: FC<IRFRTabsComponentProps> = (({
  loadType,
  incotermsTrade,
  isAllDisabled,
  isSubmitVisited,
  incotermsTradeError,
  onChangeFreightLoadType,
  onChangeFreightIncotermsTrade,
}) => {
  const { t } = useTranslation();
  const { isFullMediaWidth } = useWindowSize();

  const getHasErrorField = useCallback(
    (filed?: IDefaultFieldErrors) => !!((filed?.isVisited && filed?.message) || (filed?.message && isSubmitVisited)),
    [isSubmitVisited],
  );

  const onChangeFreightIncotermsTradeHandler = useCallback(
    (e) => {
      onChangeFreightIncotermsTrade(e.target.value);
    },
    [],
  );

  return (
    <RFRTabs className="RFRFormRequest__Row__col_RFRTabs">
      <Row className="RFRFormRequest__Row" justify="center">
        <Col span={24}>
          <Tabs
            className="RFRFormRequest__Row__Tabs"
            moreIcon={<></>}
          >
            <TabPane
              key="Sea"
              tab={(
                <span className="RFRFormRequest__Row__Tabs__item">
                  <RoadShipSvg />
                  {t('Sea')}
                </span>
              )}
            />
            <TabPane
              key="Road"
              tab={(
                <span className="RFRFormRequest__Row__Tabs__item">
                  <RoadSvg />
                  {t('Road')}
                </span>
              )}
              disabled
            />
            <TabPane
              key="Air"
              tab={(
                <span className="RFRFormRequest__Row__Tabs__item">
                  <RoadFlySvg />
                  {t('Air')}
                </span>
              )}
              disabled
            />
          </Tabs>
        </Col>
      </Row>

      <RFRTabsFreightMode>
        <RFRTabsSelect
          value={loadType || null}
          disabled={isAllDisabled}
          onChange={onChangeFreightLoadType}
          optionFilterProp="children"
          prefixIcon={<ContainerIcon color={themesColors.secondaryDot45} />}
          cssWidth="100%"
        >
          <Option
            value={freightLoadType.FCL}
            key="RFRTabsSelect_FCL"
          >
            {t('FCL')}
          </Option>
          <Option
            value={freightLoadType.LCL}
            key="RFRTabsSelect_LCL"
          >
            {t('LCL')}
          </Option>
          <Option
            value={freightLoadType.Breakbulk}
            key="RFRTabsSelect_Breakbulk"
          >
            {t('Breakbulk')}
          </Option>
        </RFRTabsSelect>

        {/* temporarily hidden */}
        {false && (
          <RFRIncotermsWrap>
            <RadioGroup
              size={isFullMediaWidth ? 'large' : 'middle'}
              onChange={onChangeFreightIncotermsTradeHandler}
              hasError={getHasErrorField(incotermsTradeError)}
              disabled={isAllDisabled}
              value={incotermsTrade}
            >
              <RadioButton key="RFRTabsSelect_IMPORT" value={EFreightIncotermsTrade.IMPORT}>
                {t('Import')}
              </RadioButton>
              <RadioButton key="RFRTabsSelect_EXPORT" value={EFreightIncotermsTrade.EXPORT}>
                {t('Export')}
              </RadioButton>
            </RadioGroup>
          </RFRIncotermsWrap>
        )}
      </RFRTabsFreightMode>
    </RFRTabs>
  );
});

const RFRTabsComponentCache = memo(RFRTabsComponent);

export { RFRTabsComponentCache as RFRTabsComponent };
