import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TShippingInstructionsPermissionsDtm = {
  shippingInstructionsStepVisible?: PermissionAttributePolicy
  MBLVisible?: PermissionAttributePolicy
  chargesMBLVisible?: PermissionAttributePolicy
  paymentsMBLVisible?: PermissionAttributePolicy
  isSISectionsVisible?: PermissionAttributePolicy
  editSISections?: PermissionAttributePolicy
  editSIHBLSections?: PermissionAttributePolicy
  ableToViewMBLContact?: PermissionAttributePolicy
  isAbleToSubmitSI?: PermissionAttributePolicy
  customerEditShippingParty?: PermissionAttributePolicy
}

export class ShippingInstructionsPermissionsDtm extends BaseDTM<TShippingInstructionsPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  shippingInstructionsStepVisible?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  MBLVisible?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  chargesMBLVisible?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  paymentsMBLVisible?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editSISections?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editSIHBLSections?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  ableToViewMBLContact?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  isAbleToSubmitSI?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  customerEditShippingParty?: PermissionAttributePolicy
}
