import React, { FC } from 'react';

import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';
import { SkypaceInformationDTM } from 'app-wrapper/constants';

import { OrganizationInfoWrapper, Bold, LinkSpan } from './OrganizationInfo.styled';

interface OrganizationInfoProps {
  pricingDepartment: AccountDepartmentDTM | null;
  adminPublicInfo: AdminPublicInfoDTM | null;
  isOriginOrDestinationFromUS: boolean;
}

export const OrganizationInfo: FC<OrganizationInfoProps> = ({
  pricingDepartment,
  adminPublicInfo,
  isOriginOrDestinationFromUS,
}) => {
  if (!adminPublicInfo) {
    return null;
  }

  if (isOriginOrDestinationFromUS) {
    const adminUS = SkypaceInformationDTM.getCompanyUS();

    return (
      <OrganizationInfoWrapper>
        <div>
          <Bold>
            {adminUS.companyName}
          </Bold>
          <br />
          {adminUS.companyAddress1},
          &nbsp;
          {adminUS.companyAddress2}
        </div>

        <div>{pricingDepartment?.phone} <LinkSpan>{pricingDepartment?.email}</LinkSpan></div>
      </OrganizationInfoWrapper>
    );
  }

  const { address } = adminPublicInfo;

  return (
    <OrganizationInfoWrapper>
      <div>
        <Bold>
          {adminPublicInfo.name}
        </Bold>
        <br />
        {adminPublicInfo.address?.address1}
        &nbsp;
        {`${address?.city}, ${address?.state || ''} ${address?.postalCode}, ${address?.country || ''}`}
      </div>

      <div>{pricingDepartment?.phone} <LinkSpan>{pricingDepartment?.email}</LinkSpan></div>
    </OrganizationInfoWrapper>
  );
};
