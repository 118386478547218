import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import groupBy from 'lodash/fp/groupBy';
import head from 'lodash/fp/head';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import pathOr from 'lodash/fp/pathOr';
import prop from 'lodash/fp/prop';
import propOr from 'lodash/fp/propOr';
import reduce from 'lodash/fp/reduce';
import sortBy from 'lodash/fp/sortBy';
import uniq from 'lodash/fp/uniq';
import isEqual from 'lodash/isEqual';
import moment from 'moment/moment';
import message from 'antd/es/message';

import { DateDtm, DocumentDTM } from 'app-wrapper/models/dtm';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ETransportationType } from 'monetary/constants';
import { BaseController, controller } from 'proto/BaseController';
import {
  BillOfLadingType,
  ChargeCodeDesignation,
  ChargeCodeSubjectTo,
  EShipmentConfirmationTypes,
  EShippingPartyTypes,
  IncotermsType,
  ShipmentFreightMode,
  ShippingInstructionsRoutes,
  TRouteLegPhase,
} from 'shipment-operations/constants';
import { UC } from 'shipment-operations/controllers';
import { IGetShipmentPlansResponse } from 'shipment-operations/models/contracts';
import {
  BillOfLadingDTM,
  CargoDTM,
  ChargeDTM,
  ContainerDocumentDTM,
  ContainerWithCargoDTM,
  CustomsClearanceDTM,
  DocumentsDistributionDTM,
  FullShipmentDTM,
  HBLDocumentBOLDTM,
  IBillOfLadingDTM,
  IHBLMarksAndNumbersDTM, IRouteLegDTM,
  IShippingPartiesMapDTM,
  PaymentTermsDTM,
  ShipmentInstructionsDetailsDTM,
  ShippingPartyDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { BillOfLadingUseCase } from 'shipment-operations/usecases/BillOfLading.useCase';
import { R as userManagementR } from 'user-management/repository';
import { v4 as uuidv4 } from 'uuid';
import { validateCopies, validateOriginals } from './BillOfLading.validate';

const ALLOWED_SHIPPING_PARTIES_TO_SELECT = [
  EShippingPartyTypes.HOUSE_SHIPPER,
  EShippingPartyTypes.HOUSE_CONSIGNEE,
  EShippingPartyTypes.HOUSE_NOTIFY_PARTY,
  EShippingPartyTypes.SECOND_NOTIFY_PARTY,
];

@controller
export class BillOfLadingController extends BaseController {
  public fetchBillOfLading = async (shipmentId = '0') => {
    await this.fetchShippingParties(shipmentId);

    const { shippingParties } = R.selectors.billOfLading.getAllBillOfLadingInformation(this.store.getState());

    const billOfLading = await R.services.billOfLading.getBillOfLading(shipmentId, shippingParties);

    if (billOfLading) {
      billOfLading.originals = billOfLading.originals.length > 0
        ? billOfLading.originals : [DocumentsDistributionDTM.fromPlain({ id: uuidv4(), amount: '3', freighted: false })];
      billOfLading.copies = billOfLading.copies.length > 0
        ? billOfLading.copies : [DocumentsDistributionDTM.fromPlain({ id: uuidv4(), amount: '3', freighted: false })];

      this.dispatch(R.actions.billOfLading.setBillOfLading(billOfLading));
      this.dispatch(R.actions.billOfLading.setInitialState(BillOfLadingDTM.fromPlain({
        ...billOfLading,
      })));
    }

    this.updateDistributionErrors();
    this.dispatch(R.actions.billOfLading.setLoading(false));
  };

  public sendBillOflading = async (shipmentId = '0') => {
    this.updateDistributionErrors();

    const billOfLading = R.selectors.billOfLading.getAllBillOfLadingInformation(this.store.getState());

    const areThereAnyCopyErrors = billOfLading.errors.copies.length > 0;
    const areThereAnyOriginalErrors = billOfLading.type !== BillOfLadingType.EBL && billOfLading.errors.originals.length > 0;

    if (areThereAnyCopyErrors || areThereAnyOriginalErrors) {
      this.dispatch(R.actions.billOfLading.setUpdateAttemptStatus(true));
      return;
    }

    if (!(billOfLading.type && (billOfLading.copies.length || billOfLading.originals.length))) {
      return;
    }

    let response: BillOfLadingDTM | null = null;

    const requestBody: IBillOfLadingDTM | null = billOfLading.type ? {
      id: billOfLading.id,
      type: billOfLading.type,
      copies: billOfLading.copies,
      originals: billOfLading.originals,
    } : null;

    if (!requestBody) {
      return;
    }

    if (billOfLading.id) {
      response = await R.services.billOfLading.putBillOfLading(shipmentId, requestBody, billOfLading.shippingParties);
    }
    if (!billOfLading.id) {
      response = await R.services.billOfLading.postBillOfLading(shipmentId, requestBody, billOfLading.shippingParties);
    }

    if (!response) {
      this.dispatch(R.actions.billOfLading.setUpdateAttemptStatus(true));
      return;
    }

    if (response) {
      response.originals = response.originals.length > 0
        ? response.originals : [];
      response.copies = response.copies.length > 0
        ? response.copies : [];
    }

    await this.fetchMissMatchesDataAfterEdit(shipmentId);

    this.dispatch(R.actions.billOfLading.setBillOfLading(response));
    this.dispatch(R.actions.billOfLadingCommon.setBillOfLading(response));
    this.dispatch(R.actions.billOfLading.setInitialState(BillOfLadingDTM.fromPlain({
      ...response,
    })));
    UC.shipmentDrawer.closeDrawer();

    this.updateDistributionErrors();
  };

  public reset = () => {
    this.dispatch(R.actions.billOfLading.reset());
  }

  public touchField = (field: string) => {
    this.dispatch(R.actions.billOfLading.touchField(field));
  };

  public setType = (type: BillOfLadingType) => {
    this.dispatch(R.actions.billOfLading.setType(type));
  };

  public setOriginalParty = (party: EShippingPartyTypes | undefined, i: number) => {
    this.dispatch(R.actions.billOfLading.setEntryParty({
      distribution: 'originals',
      party,
      index: i,
    }));

    this.updateDistributionErrors();
  };

  public setOriginalAmount = (amount: string, i: number) => {
    const amountFormated = amount ? amount?.replace(/\D/g, '') : undefined;

    this.dispatch(R.actions.billOfLading.setEntryAmount({
      distribution: 'originals',
      amount: amountFormated,
      index: i,
    }));

    this.updateDistributionErrors();
  };

  public setOriginalFreighted = (freighted: boolean, index: number) => {
    this.dispatch(R.actions.billOfLading.setFreighted({
      distribution: 'originals',
      freighted,
      index,
    }));

    this.updateDistributionErrors();
  };

  public setCopyFreighted = (freighted: boolean, index: number) => {
    this.dispatch(R.actions.billOfLading.setFreighted({
      distribution: 'copies',
      freighted,
      index,
    }));

    this.updateDistributionErrors();
  };

  public setCopyParty = (party: EShippingPartyTypes, i: number) => {
    this.dispatch(R.actions.billOfLading.setEntryParty({
      distribution: 'copies',
      party,
      index: i,
    }));

    this.updateDistributionErrors();
  };

  public setCopyAmount = (amount: string, i: number) => {
    const amountFormated = amount ? amount?.replace(/\D/g, '') : undefined;

    this.dispatch(R.actions.billOfLading.setEntryAmount({
      distribution: 'copies',
      amount: amountFormated,
      index: i,
    }));

    this.updateDistributionErrors();
  };

  public addOriginal = () => {
    this.dispatch(R.actions.billOfLading.addEntry('originals'));
    this.updateDistributionErrors();
  };

  public removeOriginal = (i: number) => {
    this.dispatch(R.actions.billOfLading.removeEntry({
      distribution: 'originals',
      index: i,
    }));
    this.updateDistributionErrors();
  };

  public addCopy = () => {
    this.dispatch(R.actions.billOfLading.addEntry('copies'));
  };

  public removeCopy = (i: number) => {
    this.dispatch(R.actions.billOfLading.removeEntry({
      distribution: 'copies',
      index: i,
    }));
  };

  public setIsViewFreighted = (isFreighted: boolean) => {
    this.dispatch(R.actions.billOfLadingCommon.setIsFreightedView(isFreighted));
  };

  public async fetchInformationForBillOfLadingPage(shipmentId?: string) {
    return new BillOfLadingUseCase(this).fetchInformationForBillOfLadingPage(shipmentId);
  }

  public openMissMatchesDrawer = (withSubmitButton?: boolean) => {
    new DrawersUseCase(this).openBLMismatches();

    this.dispatch(R.actions.shipmentChanges.setWithSubmitButton(!!withSubmitButton));
  };

  public clearShipmentInstructionsError = () => {
    this.dispatch(R.actions.billOfLadingCommon.setSubmitSI({
      isSuccessful: false,
      error: undefined,
    }));
  };

  public openEditShippingPartyDrawer = async (shipmentId: string, shippingPartyId?: number) => {
    if (!shippingPartyId) {
      return;
    }

    const shippingParty = await R.services.shippingParties.getById(shipmentId, shippingPartyId);

    UC.shippingParties.setShippingPartyToState(shippingParty.role, shippingParty);

    this.dispatch(R.actions.shippingParties.setSelectedForm(shippingParty.role));

    if (shippingParty.company) {
      this.dispatch(R.actions.shippingParties.setTaxId(shippingParty.company.taxId || ''));
    }

    if (shippingParty.address) {
      this.dispatch(R.actions.shippingParties.setAddressLine1(shippingParty.address.address1));
      this.dispatch(R.actions.shippingParties.setAddressLine2(shippingParty.address.address2 || ''));
      this.dispatch(R.actions.shippingParties.setCountry(shippingParty.address.country || ''));
      this.dispatch(R.actions.shippingParties.setCity(shippingParty.address.city || ''));
      this.dispatch(R.actions.shippingParties.setState(shippingParty.address.state || ''));
      this.dispatch(R.actions.shippingParties.setPostalCode(shippingParty.address.postalCode || ''));
    }

    if (shippingParty.contact) {
      this.dispatch(R.actions.shippingParties.setFullName(shippingParty.contact.fullName || ''));
      this.dispatch(R.actions.shippingParties.setEmail(shippingParty.contact.email || ''));
      this.dispatch(R.actions.shippingParties.setPhone(shippingParty.contact.phone || ''));
      this.dispatch(R.actions.shippingParties.setAdditionalPhone(shippingParty.contact.phone2 || ''));
    }

    this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerLoading(true));
    this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerVisible(true));
    this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerLoading(false));
  }

  public saveEditShippingPartyDrawerChanges = async (shipmentId: string) => {
    this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerLoading(true));
    const isCustomerOrg = userManagementR.selectors.userOrganizationData.getIsCustomerOrganization(this.store.getState());
    const paymentTerms = R.selectors.billOfLadingCommon.getPaymentTerms(this.store.getState());

    await UC.shippingParties.updateShippingPartyContactData(shipmentId, isCustomerOrg, paymentTerms?.tradeType);

    const isRequiredErrorVisible = R.selectors.shippingParties.getIsRequiredErrorVisible(this.store.getState());
    const emailError = R.selectors.shippingParties.getEmailError(this.store.getState());

    if (isRequiredErrorVisible || emailError) {
      this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerLoading(false));
      return;
    }

    await new BillOfLadingUseCase(this).fetchShippingPartiesInformation(shipmentId);
    await this.fetchMissMatchesDataAfterEdit(shipmentId);
    this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerLoading(false));
    this.closeEditShippingPartyDrawer();
  }

  public closeEditShippingPartyDrawer = () => {
    this.dispatch(R.actions.billOfLadingCommon.setIsEditShippingPartyDrawerVisible(false));
  }

  public getHBLDocumentInformation = async (shipmentId: string) => {
    let charges: ChargeDTM[] = [];

    this.dispatch(R.actions.documentHBLPDF.setIsLoading(true));

    const fullShipment = await R.services.shipment.getFullShipment(shipmentId);

    if (!fullShipment) {
      this.dispatch(R.actions.documentHBLPDF.setIsLoading(false));
      message.error('Failed to open HBL PDF Document');

      return;
    }

    const shippingParties = await R.services.shippingParties.getList(shipmentId) || [];

    const billOfLading = await R.services.billOfLading.getBillOfLading(shipmentId);

    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);
    const cargoSupplier = shippingParties.find(({ role }) => role === EShippingPartyTypes.CARGO_SUPPLIER);
    const cargoReceiver = shippingParties.find(({ role }) => role === EShippingPartyTypes.CARGO_RECEIVER);

    this.dispatch(R.actions.documentHBLPDF.setCargoSupplier(cargoSupplier || null));
    this.dispatch(R.actions.documentHBLPDF.setCargoReceiver(cargoReceiver || null));

    if (customer && customer.company && customer.company.id) {
      this.dispatch(R.actions.shipmentDocumentsAll.setCustomer(customer));

      charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, customer.company.id);

      charges = charges?.filter((charge) => !(charge?.designation === ChargeCodeDesignation.FREIGHT && charge?.subjectTo === ChargeCodeSubjectTo.INCLUDED));
    }

    const entryNumberITN = R.selectors.billOfLadingCommon.getExportCustoms(this.store.getState());

    this.dispatch(R.actions.documentHBLPDF.setHBLDocumentBOL(this.getHBLBillOfLading(fullShipment, charges, shippingParties, billOfLading, entryNumberITN)));
    this.dispatch(R.actions.documentHBLPDF.setIsLoading(false));
  };

  public setPaymentsIncoterm = (value: IncotermsType) => {
    const shipment = R.selectors.shipment.getShipment(this.store.getState());
    const contractOwner = R.selectors.billOfLadingCommon.getContractOwner(this.store.getState());
    const paymentTerms = R.selectors.billOfLadingCommon.getPaymentTerms(this.store.getState());
    const isHBLPage = R.selectors.billOfLadingCommon.getIsHBLPage(this.store.getState());

    if (!paymentTerms || !shipment) {
      return;
    }

    const { isSelfService } = shipment;
    const { tradeType } = paymentTerms;

    const { origin, freight, destination } = PaymentTermsDTM.getCurrentAvailablePayments(value, tradeType, contractOwner, !!isSelfService, isHBLPage);

    this.dispatch(R.actions.billOfLadingCommon.setPaymentsFormState({ key: 'origin', value: origin }));
    this.dispatch(R.actions.billOfLadingCommon.setPaymentsFormState({ key: 'freight', value: freight }));
    this.dispatch(R.actions.billOfLadingCommon.setPaymentsFormState({ key: 'destination', value: destination }));
    this.dispatch(R.actions.billOfLadingCommon.setIncotermFormState(value));
  };

  public setClausesFromState = (value: string[]) => {
    this.dispatch(R.actions.billOfLadingCommon.setClausesFormState(value));
  };

  public setCommentsFromState = (value: string) => {
    this.dispatch(R.actions.billOfLadingCommon.setCommentsFormState(value));
  };

  public submitEditPaymentsChanges = async (shipmentId: string) => {
    const paymentTermsBody = R.selectors.billOfLadingCommon.getMergedPaymentTermsWithFormState(this.store.getState());
    let responsePaymentTerms: PaymentTermsDTM | null = null;

    this.dispatch(R.actions.billOfLadingCommon.setIsStateUpdatePending(true));

    responsePaymentTerms = await R.services.paymentTerms.putShipmentPaymentTerms(shipmentId, paymentTermsBody);

    if (responsePaymentTerms) {
      this.dispatch(R.actions.billOfLadingCommon.setPaymentTerms(responsePaymentTerms));
    }

    await this.fetchMissMatchesDataAfterEdit(shipmentId);

    UC.shipmentDrawer.closeDrawer();
    this.dispatch(R.actions.billOfLadingCommon.setIsStateUpdatePending(false));
  };

  public submitEditClausesChanges = async (shipmentId: string) => {
    const siDetails = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());
    const { clauses = [] } = R.selectors.billOfLadingCommon.getClausesFormState(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setIsStateUpdatePending(true));

    const responseSIDetails = await R.services.shipmentInstructionDetails.editSIDetails(shipmentId, { ...siDetails, clauses });

    this.dispatch(R.actions.billOfLadingCommon.setSIDetails(responseSIDetails));
    UC.shipmentDrawer.closeDrawer();
    this.dispatch(R.actions.billOfLadingCommon.setIsStateUpdatePending(false));
  };

  public submitEditBLCommentChanges = async (shipmentId: string) => {
    const siDetails = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());
    const { comment = '' } = R.selectors.billOfLadingCommon.getCommentsFormState(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setIsStateUpdatePending(true));

    const responseSIDetails = await R.services.shipmentInstructionDetails.editSIDetails(shipmentId, { ...siDetails, blComment: comment });

    this.dispatch(R.actions.billOfLadingCommon.setSIDetails(responseSIDetails));
    UC.shipmentDrawer.closeDrawer();
    this.dispatch(R.actions.billOfLadingCommon.setIsStateUpdatePending(false));
  };

  public goToMBL = () => {
    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (!currentOrganization) {
      return;
    }

    this.dispatch(R.actions.billOfLadingCommon.setActiveRoute(ShippingInstructionsRoutes.MBL_SI));
  };

  public goToHBL = () => {
    this.dispatch(R.actions.billOfLadingCommon.setActiveRoute(ShippingInstructionsRoutes.HBL_DRAFT));
  };

  public approveDraftHBLFromMissMatchesDrawer = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentChanges.setIsLoadingData(true));

    await this.approveDraftHBL(shipmentId);

    const siDetails = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setSIDetails(ShipmentInstructionsDetailsDTM.fromPlain({
      ...siDetails,
      finalMblDocument: null,
      mblFinalId: null,
    })));

    this.dispatch(R.actions.shipmentChanges.setIsLoadingData(false));
    new DrawersUseCase(this).closeDrawer();
  };

  public approveDraftMBL = async (shipmentId: string) => {
    this.dispatch(R.actions.billOfLadingCommon.setIsFlowStepLoading(true));

    await R.services.shipment.postConfirmations(shipmentId, {
      type: EShipmentConfirmationTypes.DRAFT_MBL,
    });

    this.dispatch(R.actions.billOfLadingCommon.setIsDraftMBLApproved(true));
    this.dispatch(R.actions.billOfLadingCommon.setIsFlowStepLoading(false));
  };

  public openReviewAndApproveEMBLDrawer = () => {
    new DrawersUseCase(this).openApproveEMBLDrawer();
  };

  public fetchMissMatchesDataAfterEdit = async (shipmentId: string) => {
    const short = await R.services.shipment.getShipmentShortById(+shipmentId);
    const siDetails = await R.services.shipmentInstructionDetails.getSIDetails(shipmentId);

    if (short) {
      this.dispatch(R.actions.shipment.setShipment(short));
    }

    this.dispatch(R.actions.billOfLadingCommon.setSIDetails(siDetails));

    const confirmations = await R.services.shipment.getConfirmations(shipmentId) || [];

    if (confirmations.length) {
      this.dispatch(R.actions.billOfLadingCommon.setIsDraftHBLApproved(!!confirmations.find(({ type }) => type === EShipmentConfirmationTypes.DRAFT_HBL)));
      this.dispatch(R.actions.billOfLadingCommon.setIsDraftMBLApproved(!!confirmations.find(({ type }) => type === EShipmentConfirmationTypes.DRAFT_MBL)));
    }

    const siMissMatches = await R.services.shipmentChanges.getShipmentChangesBL(shipmentId);

    this.dispatch(R.actions.billOfLadingCommon.setSIMissMatches(siMissMatches.filter(({ current, previous }) => !isEqual(current, previous))));
  };

  public approveDraftHBL = async (shipmentId: string) => {
    this.dispatch(R.actions.billOfLadingCommon.setIsFlowStepLoading(true));

    try {
      await R.services.shipment.postConfirmations(shipmentId, {
        type: EShipmentConfirmationTypes.DRAFT_HBL,
      });
    } catch (e) {
      this.dispatch(R.actions.billOfLadingCommon.setIsFlowStepLoading(false));

      throw e;
    }

    await this.fetchMissMatchesDataAfterEdit(shipmentId);
    this.dispatch(R.actions.billOfLadingCommon.setIsDraftHBLApproved(true));
    this.dispatch(R.actions.billOfLadingCommon.setIsFlowStepLoading(false));
  };

  public handleCleanup = () => {
    this.dispatch(R.actions.billOfLadingCommon.clear());
    apiWorker.abortAllRequests();
  };

  public setMBLFormStateDocument = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormState(document));
  };

  public validateMBLFileUpload = () => {
    const mblFileFormState = R.selectors.billOfLadingCommon.getMBLFileFormState(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setMBLFileUploadError(!mblFileFormState));
  };

  public submitDraftMBLDocument = async (shipmentId: string) => {
    this.validateMBLFileUpload();

    const error = R.selectors.billOfLadingCommon.getMBLFileFormError(this.store.getState());

    if (error) {
      return;
    }

    const mblFileFormState = R.selectors.billOfLadingCommon.getMBLFileFormState(this.store.getState()) as ContainerDocumentDTM;
    const siDetails = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormPending(true));

    const responseSIDetails = await R.services.shipmentInstructionDetails.editSIDetails(shipmentId, {
      ...siDetails,
      draftMblDocument: mblFileFormState.response,
    });

    this.dispatch(R.actions.billOfLadingCommon.setSIDetails(responseSIDetails));

    UC.shipmentDrawer.closeDrawer();
    this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormPending(false));
  };

  public submitFinalMBLDocument = async (shipmentId: string) => {
    this.validateMBLFileUpload();

    const error = R.selectors.billOfLadingCommon.getMBLFileFormError(this.store.getState());

    if (error) {
      return;
    }

    const mblFileFormState = R.selectors.billOfLadingCommon.getMBLFileFormState(this.store.getState()) as ContainerDocumentDTM;
    const siDetails = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormPending(true));

    const responseSIDetails = await R.services.shipmentInstructionDetails.editSIDetails(shipmentId, {
      ...siDetails,
      finalMblDocument: mblFileFormState.response,
    });

    this.dispatch(R.actions.billOfLadingCommon.setSIDetails(responseSIDetails));

    UC.shipmentDrawer.closeDrawer();
    this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormPending(false));
  };

  public downloadDocument = (shipmentId: string, document: DocumentDTM) => {
    R.services.shipmentDocument.getShipmentDocument(+shipmentId, document.id, document.name);
  }

  public downloadFinalDocument = (shipmentId: string) => {
    const { finalMblDocument } = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    if (finalMblDocument) {
      this.downloadDocument(shipmentId, finalMblDocument);
    }
  };

  public downloadDraftDocument = (shipmentId: string) => {
    const { draftMblDocument } = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    if (draftMblDocument) {
      this.downloadDocument(shipmentId, draftMblDocument);
    }
  };

  public downloadMBLFormStateDocument = (shipmentId: string) => {
    const documentFormState = R.selectors.billOfLadingCommon.getMBLFileFormState(this.store.getState());

    if (documentFormState && documentFormState.response && shipmentId) {
      this.downloadDocument(shipmentId, documentFormState.response);
    }
  };

  private updateDistributionErrors = () => {
    const { originals, copies } = R.selectors.billOfLading.getAllBillOfLadingInformation(this.store.getState());

    this.dispatch(R.actions.billOfLading.setErrorForEntries({
      originals: validateOriginals(originals),
      copies: validateCopies(copies),
    }));
  };

  private fetchShippingParties = async (shipmentId: string) => {
    const shippingPartiesMap: IShippingPartiesMapDTM = {};

    const shippingParties = await R.services.shippingParties.getList(shipmentId);

    shippingParties.forEach(({ id, role }) => {
      if (!id || !ALLOWED_SHIPPING_PARTIES_TO_SELECT.includes(role)) return;

      shippingPartiesMap[id] = role as EShippingPartyTypes;
    });

    this.dispatch(R.actions.billOfLading.setShippingPartiesMap(shippingPartiesMap));
  };

  private getChargesCurrency = (charges: ChargeDTM[]): string => {
    const headCharge = head(charges || []);

    return propOr('USD', 'currency', headCharge);
  }

  private getChargesSum = (charges: ChargeDTM[]) => reduce((acc, item) => {
    acc += propOr(0, 'buyTotalCost', item);

    return acc;
  }, 0, charges);

  private getCombinedCharge = (charges: ChargeDTM[]) => ChargeDTM.fromPlain({
    ...charges[0],
    buyTotalCost: charges.reduce((acc, next) => acc + next.buyTotalCost, 0),
  });

  private getCalculatedCharges = (charges: ChargeDTM[]) => {
    const actualCharges = charges.filter(({ applied }) => applied);
    const codeToChargesMap: Record<string, ChargeDTM[]> = {};
    const finalCharges: ChargeDTM[] = [];

    actualCharges.forEach((charge) => {
      const { code } = charge.chargeCode;

      if (codeToChargesMap[code]) {
        codeToChargesMap[code].push(charge);
      } else {
        codeToChargesMap[code] = [charge];
      }
    });

    Object.keys(codeToChargesMap).forEach((chargeCode) => {
      const localCharges = codeToChargesMap[chargeCode];
      const originalDescriptionToChargesMap: Record<string, ChargeDTM[]> = {};

      localCharges.forEach((charge) => {
        const key = charge.metadata && charge.metadata.originalDescription ? charge.metadata.originalDescription : 'none';

        if (originalDescriptionToChargesMap[key]) {
          originalDescriptionToChargesMap[key].push(charge);
        } else {
          originalDescriptionToChargesMap[key] = [charge];
        }
      });

      Object.values(originalDescriptionToChargesMap).forEach((_charges) => {
        const freightCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.FREIGHT);
        const originCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.ORIGIN);
        const destinationCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.DESTINATION);

        if (freightCharges.length) {
          finalCharges.push(this.getCombinedCharge(freightCharges));
        }

        if (originCharges.length) {
          finalCharges.push(this.getCombinedCharge(originCharges));
        }

        if (destinationCharges.length) {
          finalCharges.push(this.getCombinedCharge(destinationCharges));
        }
      });
    });

    return [
      ...finalCharges.filter(({ designation }) => designation === ChargeCodeDesignation.ORIGIN),
      ...finalCharges.filter(({ designation }) => designation === ChargeCodeDesignation.FREIGHT),
      ...finalCharges.filter(({ designation }) => designation === ChargeCodeDesignation.DESTINATION),
    ];
  }

  private getSeaLegsByTransportation(legs: IRouteLegDTM[], transportations: IGetShipmentPlansResponse['transportations']) {
    const seaTransportations = transportations.filter(({ transport }) => transport.type === ShipmentFreightMode.SEA);

    return seaTransportations.map(({ transportLeg }) => legs.find(({ id }) => id === transportLeg) as IRouteLegDTM);
  }

  private getHBLBillOfLading = (fullShipment: FullShipmentDTM, charges: ChargeDTM[], shipmentParties: ShippingPartyDTM[], billOfLading?: BillOfLadingDTM, exportCustoms?: CustomsClearanceDTM) => {
    const {
      id,
      paymentTerms,
      organization,
      transportationPlans,
      cargos,
      containers,
      siDetails,
      temperatureControl,
    } = fullShipment;
    const { clauses } = siDetails;
    const [transportationPlan] = transportationPlans;
    const { transportations, route } = transportationPlan;
    const { legs } = route;
    const seaLegs = this.getSeaLegsByTransportation(legs, transportations);

    const sortedTransportations = sortBy(prop('transportLeg'), transportations);
    const seaTransportations = filter(({ transport }) => transport.type === 'SEA', sortedTransportations);
    const firstSeaTransportation = head(seaTransportations);
    const firstSeaLeg = find(({ id: _id }) => _id === firstSeaTransportation?.transportLeg, legs);
    const lastSeaTransportations = last(seaTransportations);
    const lastSeaLeg = find(({ id: _id }) => _id === lastSeaTransportations?.transportLeg, legs);
    const firstLeg = head(seaLegs);
    const firstLegTransportation = find(({ transportLeg }) => transportLeg === firstLeg?.id, transportations);
    const lastLeg = last(seaLegs);
    const lastLegTransportation = find(({ transportLeg }) => transportLeg === lastLeg?.id, transportations);

    const vesselName = pathOr('', ['transport', 'name'], firstSeaTransportation);
    const voyagerNumber = pathOr('', ['transport', 'number'], firstSeaTransportation);
    const preCarriageLocation = pathOr('', ['departureLocation', 'name'], firstSeaLeg);
    const onCarriageLocation = pathOr('', ['arrivalLocation', 'name'], lastSeaLeg);
    const placeOfReceipt = pathOr('', ['departureLocation', 'name'], firstLeg);
    const portOfLoading = preCarriageLocation;
    const portOfDischarge = onCarriageLocation;
    const placeOfDelivery = pathOr('', ['arrivalLocation', 'name'], lastLeg);
    const firstLegTransportType = pathOr('ROAD', ['transport', 'type'], firstLegTransportation) as ETransportationType;
    const lastLegTransportType = pathOr('ROAD', ['transport', 'type'], lastLegTransportation) as ETransportationType;
    const firstSeaLegTransportType = pathOr('ROAD', ['transport', 'type'], firstSeaTransportation) as ETransportationType;
    const lastSeaLegTransportType = pathOr('ROAD', ['transport', 'type'], lastSeaTransportations) as ETransportationType;

    const transportationPlanInfo = {
      vesselName,
      voyagerNumber,
      preCarriageLocation,
      onCarriageLocation,
      placeOfReceipt,
      portOfLoading,
      portOfDischarge,
      placeOfDelivery,
      firstLegTransportType,
      lastLegTransportType,
      firstSeaLegTransportType,
      lastSeaLegTransportType,
    };

    let departureTimeString: string | undefined;
    transportationPlans.find((_transportationPlan) => {
      departureTimeString = _transportationPlan.transportations.find((transportation) => transportation.transport.type === ETransportationType.SEA)?.schedule.departureTime;
      return departureTimeString;
    });

    const departureTime = departureTimeString ? DateDtm.fromPlain({
      date: departureTimeString,
      offset: moment.parseZone(departureTimeString).utcOffset(),
    }) : undefined;

    const cargoValues = reduce((acc, item) => {
      const {
        packagesNumber,
        weight,
        volume,
      } = acc;
      acc = {
        packagesNumber: Number(packagesNumber) + Number(item.packagesNumber),
        netWeight: 0,
        weight: Number(weight) + Number(item.weight),
        volume: Number(volume) + Number(item.volume),
      };

      return acc;
    }, {
      packagesNumber: 0,
      netWeight: 0,
      weight: 0,
      volume: 0,
    }, cargos);

    const marksAndNumbers = reduce<CargoDTM, IHBLMarksAndNumbersDTM>((acc, item) => {
      const { references, marks } = acc;

      let newMarks = [...marks];

      if (item.marks) {
        newMarks = [...newMarks, item.marks];
      }

      acc = {
        references: [...references, ...item.references],
        marks: newMarks,
      };

      return acc;
    },
    { references: [], marks: [] },
    cargos);

    const cargo = {
      ...cargoValues,
      references: marksAndNumbers.references.map((item) => (item.value ? item.value : '')),
      marks: marksAndNumbers.marks,
    };
    const containerTypes = map(({ type }) => type, containers);
    const container = {
      quantity: containers.length,
      containerTypes: uniq(containerTypes),
      marks: marksAndNumbers.marks,
      references: marksAndNumbers.references,
    };
    const mappedContainers = map((_container) => {
      const cargoItems = map((item) => {
        const originalCargo = find((_cargo) => String(item.cargoId) === String(_cargo.id), cargos);

        return ({
          ...originalCargo,
          ...item,
        });
      }, _container.cargoItems);

      return ({
        ..._container,
        cargoItems,
      });
    }, containers);
    const filteredCharges = filter(({ applied }) => applied, charges);
    const groupedCharges = groupBy(prop('designation'), filteredCharges);

    const originChargesSum = this.getChargesSum(groupedCharges.ORIGIN);
    const originCurrency = this.getChargesCurrency(groupedCharges.ORIGIN);
    const freightChargesSum = this.getChargesSum(groupedCharges.FREIGHT);
    const freightCurrency = this.getChargesCurrency(groupedCharges.FREIGHT);
    const destinationChargesSum = this.getChargesSum(groupedCharges.DESTINATION);
    const destinationCurrency = this.getChargesCurrency(groupedCharges.DESTINATION);
    const cargoSum = reduce((acc, item) => {
      acc += +propOr(0, 'value', item);

      return acc;
    }, 0, cargos);
    const chargesInfo = {
      originChargesSum: [originCurrency, String(originChargesSum.toFixed(2))].join(' '),
      freightChargesSum: [freightCurrency, String(freightChargesSum.toFixed(2))].join(' '),
      destinationChargesSum: [destinationCurrency, String(destinationChargesSum.toFixed(2))].join(' '),
      cargoSum: `USD ${cargoSum.toFixed(2)}`,
    };

    const containersWithCargos = containers.map((_container) => (ContainerWithCargoDTM.fromPlain({
      ..._container,
      cargoItems: _container.cargoItems.map((cargoItem) => {
        const targetCargo = cargos.find(({ id: _id }) => +cargoItem.cargoId === _id) as CargoDTM;

        return {
          ...cargoItem,
          cargo: targetCargo,
        };
      }),
    })));

    return HBLDocumentBOLDTM.fromPlain({
      id,
      type: '',
      paymentTerms,
      billOfLading,
      organization,
      shipmentParties,
      transportationPlanInfo,
      // @ts-ignore different dtms
      exportCustoms,
      importCustoms: undefined,
      cargo,
      cargos,
      container,
      containersWithCargo: containersWithCargos,
      containers: mappedContainers,
      legs,
      chargesInfo,
      charges: this.getCalculatedCharges(charges),
      temperatureControl: temperatureControl || undefined,
      clauses,
      departureTime,
    });
  }
}
