import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'shipment-operations/repository';
import { R as appR } from 'app-wrapper/repository';
import { IQuotaServiceByIdContentRoutesLegsLocationDTM } from 'monetary/models/dtm';

import { SBLRoutingInformationComponent } from './SBLRoutingInformation.component';

const EMPTY_CELL = '-';

const getLocationNameFromLocation = (location: IQuotaServiceByIdContentRoutesLegsLocationDTM): string => `${location.name}, ${location.country?.name}`;

const SBLRoutingInformationContainer = () => {
  const { t } = useTranslation();
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const lowestLeg = useSelector(R.selectors.billOfLadingCommon.getLowestSequenceLeg);
  const highestLeg = useSelector(R.selectors.billOfLadingCommon.getHighestSequenceLeg);
  const lowestSeaLeg = useSelector(R.selectors.billOfLadingCommon.getLowestSequenceSeaLeg);
  const highestSeaLeg = useSelector(R.selectors.billOfLadingCommon.getHighestSequenceSeaLeg);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const countries = useSelector(appR.selectors.countriesList.getCountriesList);
  const cargoSupplier = useSelector(R.selectors.billOfLadingCommon.getCargoSupplier);
  const cargoReceiver = useSelector(R.selectors.billOfLadingCommon.getCargoReceiver);

  const items = useMemo(() => {
    const finalItems = [
      {
        title: t('Place of Receipt'),
        subtitle: EMPTY_CELL,
      },
      {
        title: t('Port of Load'),
        subtitle: EMPTY_CELL,
      },
      {
        title: t('Port of Discharge'),
        subtitle: EMPTY_CELL,
      },
      {
        title: t('Place of Delivery'),
        subtitle: EMPTY_CELL,
      },
    ];

    if (isHBLPage && shipment?.getIsOriginDoor() && cargoSupplier?.address) {
      const { city, country: countryCode } = cargoSupplier.address;
      const country = countries.find(({ code }) => code === countryCode);

      finalItems[0].subtitle = `${city}, ${country?.name || countryCode}`;
    } else if (lowestLeg?.departureLocation) {
      finalItems[0].subtitle = getLocationNameFromLocation(lowestLeg?.departureLocation);
    }

    if (lowestSeaLeg?.departureLocation) {
      finalItems[1].subtitle = getLocationNameFromLocation(lowestSeaLeg?.departureLocation);
    }

    if (highestSeaLeg?.arrivalLocation) {
      finalItems[2].subtitle = getLocationNameFromLocation(highestSeaLeg?.arrivalLocation);
    }

    if (isHBLPage && shipment?.getIsDestinationDoor() && cargoReceiver?.address) {
      const { city, country: countryCode } = cargoReceiver.address;
      const country = countries.find(({ code }) => code === countryCode);

      finalItems[3].subtitle = `${city}, ${country?.name || countryCode}`;
    } else if (highestLeg?.arrivalLocation) {
      finalItems[3].subtitle = getLocationNameFromLocation(highestLeg?.arrivalLocation);
    }

    return finalItems;
  }, [lowestLeg, highestLeg, lowestSeaLeg, highestSeaLeg, t, shipment, cargoReceiver, cargoSupplier, isHBLPage]);

  return <SBLRoutingInformationComponent items={items} />;
};

export { SBLRoutingInformationContainer as SBLRoutingInformation };
