import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';

import { ContainerActionsComponenet } from './ContainerActions.component';

interface IContainerActionsProps {
  containerId: string;
}

export const ContainerActions: FC<IContainerActionsProps> = React.memo(({ containerId }: IContainerActionsProps) => (
  <ContainerActionsComponenet
    deleteContainer={UC.bookingWizard.removeContainer}
    containerId={containerId}
  />
));
