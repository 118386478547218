import React, {
  FC,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import {
  ButtonPlus,
  Divider,
  TypeSwitch,
} from 'app-wrapper/view/components';
import { SHIPPING_PARTY_MAX_LENGTHS } from 'app-wrapper/constants';
import {
  ICompanyContactDTM,
  IContactBookUpdateStateContactPersonsErrorsDTM,
} from 'user-management/models/dtm';
import { TooltipError } from 'user-management/view/components';

import {
  ContactsContactPersonBlock,
  ContactsContactPersonBlockFirst,
  ContactsContactPersonBlockFirstInput,
  ContactsContactPersonBlockFirstTitle,
  ContactsContactPersonBlockFourth,
  ContactsContactPersonBlockName,
  ContactsContactPersonBlockSecond,
  ContactsContactPersonBlockSecondPhone,
  ContactsContactPersonBlockSecondPhoneWrapper,
  ContactsContactPersonBlockSecondInputDiv,
  ContactsContactPersonBlockSecondAPhone,
  ContactsContactPersonBlockSecondAPhoneWrapper,
  ContactsContactPersonBlockSecondTitle,
  ContactsContactPersonBlockSecondTitleDiv,
  CBDividerMargins,
  ContactsContactPersonBlockFirstInputDiv,
  ContactsContactPersonBlockFirstInputWrapper,
  ContactsContactPersonBlockFirstInputWrapperRight,
  ContactsContactPersonBlockNameWrapper,
  ContactsContactPersonBlockDeleteButton,
  CBContentBodyFormPrimary,
} from './ContactsContactPersonBlock.styled';

interface IContactsContactPersonBlockComponentProps {
  largeInput: boolean
  contactPerson?: ICompanyContactDTM
  contactIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
  errors: IContactBookUpdateStateContactPersonsErrorsDTM
  onChangeContactPerson: (indexContacts: string) => (contactPerson: string) => void
  onBlurContactPerson: (indexContacts: string) => () => void
  onFocusContactPerson: (indexContacts: string) => () => void
  onChangeAdditionalPhone: (indexContacts: string) => (additionalPhone: string) => void
  onBlurAdditionalPhone: (indexContacts: string) => () => void
  onFocusAdditionalPhone: (indexContacts: string) => () => void
  onChangeEmail: (indexContacts: string) => (email: string) => void
  onBlurEmail: (indexContacts: string) => () => void
  onFocusEmail: (indexContacts: string) => () => void
  onChangeIsPrimary?: (indexContacts: string) => (isPrimary: boolean) => void
  onChangePhone: (indexContacts: string) => (phone: string) => void
  onBlurPhone: (indexContacts: string) => () => void
  onFocusPhone: (indexContacts: string) => () => void
  addContactPersonItem: (indexList: string) => () => void
  onRemoveContactPerson: (indexList: string, indexValue: string) => () => void
  showPrimaryAsSwitch?: boolean;
}

const ContactsContactPersonBlockComponentLocal: FC<IContactsContactPersonBlockComponentProps> = (props) => {
  const {
    largeInput,
    contactPerson,
    contactIndex,
    companyListStateIndex,
    isFirst,
    isLast,
    errors,
    onChangeContactPerson,
    onChangeIsPrimary,
    onBlurContactPerson,
    onFocusContactPerson,
    onChangeAdditionalPhone,
    onBlurAdditionalPhone,
    onFocusAdditionalPhone,
    onChangeEmail,
    onBlurEmail,
    onFocusEmail,
    onChangePhone,
    onBlurPhone,
    onFocusPhone,
    addContactPersonItem,
    onRemoveContactPerson,
    showPrimaryAsSwitch,
  } = props;
  const { t } = useTranslation();

  const contactPersonId = contactPerson?.customId || '';

  const onChangeHandlerContactPerson = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeContactPerson(contactPersonId)(event.target.value);
    },
    [onChangeContactPerson, contactPersonId],
  );

  const hasErrorContactPerson = useMemo(() => {
    const errDocument = errors?.contactPerson;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerAdditionalPhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeAdditionalPhone(contactPersonId)(event.target.value);
    },
    [onChangeAdditionalPhone, contactPersonId],
  );

  const handleIsPrimaryChange = useCallback((isPrimary: boolean) => {
    if (onChangeIsPrimary) {
      onChangeIsPrimary(contactPersonId)(isPrimary);
    }
  }, [onChangeIsPrimary, contactPersonId]);

  const hasErrorAdditionalPhone = useMemo(() => {
    const errDocument = errors?.additionalPhone;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeEmail(contactPersonId)(event.target.value);
    },
    [onChangeEmail, contactPersonId],
  );

  const hasErrorEmail = useMemo(() => {
    const errDocument = errors?.email;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerPhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangePhone(contactPersonId)(event.target.value);
    },
    [onChangePhone, contactPersonId],
  );

  const hasErrorPhone = useMemo(() => {
    const errDocument = errors?.phone;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const isVisibleEmail = useMemo(() => {
    const msg = errors?.email?.message !== t('basicErrors.REQUIRED_MESSAGE');
    return !!(hasErrorEmail && errors?.email?.isTooltipVisible && msg);
  }, [errors, hasErrorEmail]);

  const isVisiblePhone = useMemo(() => {
    const msg = errors?.phone?.message !== t('basicErrors.REQUIRED_MESSAGE');

    return !!(hasErrorPhone && errors?.phone?.isTooltipVisible && msg);
  }, [errors, hasErrorEmail]);

  const isVisibleAdditionalPhone = useMemo(() => {
    const msg = errors?.additionalPhone?.message !== t('basicErrors.REQUIRED_MESSAGE');

    return !!(hasErrorAdditionalPhone && errors?.additionalPhone?.isTooltipVisible && msg);
  }, [errors, hasErrorEmail]);

  return (
    <ContactsContactPersonBlock
      key={`ContactsContactPersonBlock-${contactPersonId}`}
    >
      <ContactsContactPersonBlockNameWrapper>
        <ContactsContactPersonBlockName>
          {`${t('Contact Person')}`}

          {showPrimaryAsSwitch ? (
            <TypeSwitch
              onChange={handleIsPrimaryChange}
              rightText={t('Primary')}
              checkedChildren="On"
              unCheckedChildren="Off"
              value={!!contactPerson?.primary}
              disabledbg="true"
            />
          ) : (
            <>
              {contactIndex > 0
                ? ` #${contactIndex + 1}`
                : <CBContentBodyFormPrimary>{t('Primary')}</CBContentBodyFormPrimary>}
            </>
          )}
        </ContactsContactPersonBlockName>
        {!isFirst && (
          <ContactsContactPersonBlockDeleteButton
            size="small"
            type="dashed"
            icon={<DeleteOutlined />}
            onClick={onRemoveContactPerson(companyListStateIndex, contactPersonId)}
          >
            {t('Remove')}
          </ContactsContactPersonBlockDeleteButton>
        )}
      </ContactsContactPersonBlockNameWrapper>
      <ContactsContactPersonBlockFirst>
        <ContactsContactPersonBlockFirstInputDiv>
          <ContactsContactPersonBlockFirstInputWrapper>
            <ContactsContactPersonBlockFirstTitle>
              {`${t('Full Name')} ${t('requiredSymbol')}`}
            </ContactsContactPersonBlockFirstTitle>
            <ContactsContactPersonBlockFirstInput
              data-class="ContactsContactPersonFullNameInput"
              largeInput={largeInput}
              value={contactPerson?.fullName || ''}
              hasError={hasErrorContactPerson}
              onChange={onChangeHandlerContactPerson}
              onBlur={onBlurContactPerson(contactPersonId)}
              onFocus={onFocusContactPerson(contactPersonId)}
              maxLength={SHIPPING_PARTY_MAX_LENGTHS.FULL_NAME}
            />
          </ContactsContactPersonBlockFirstInputWrapper>
          <ContactsContactPersonBlockFirstInputWrapperRight>
            <ContactsContactPersonBlockFirstTitle>
              {`${t('Email')} ${t('requiredSymbol')}`}
            </ContactsContactPersonBlockFirstTitle>
            <TooltipError
              message={errors?.email?.message || ''}
              visible={isVisibleEmail}
            >
              <ContactsContactPersonBlockFirstInput
                data-class="ContactsContactPersonEmailInput"
                largeInput={largeInput}
                value={contactPerson?.email || ''}
                hasError={hasErrorEmail}
                onChange={onChangeHandlerEmail}
                onBlur={onBlurEmail(contactPersonId)}
                onFocus={onFocusEmail(contactPersonId)}
              />
            </TooltipError>
          </ContactsContactPersonBlockFirstInputWrapperRight>
        </ContactsContactPersonBlockFirstInputDiv>
      </ContactsContactPersonBlockFirst>

      <ContactsContactPersonBlockSecond>
        <ContactsContactPersonBlockSecondInputDiv>
          <ContactsContactPersonBlockSecondPhoneWrapper>
            <ContactsContactPersonBlockSecondTitle>
              {`${t('Phone')} ${t('requiredSymbol')}`}
            </ContactsContactPersonBlockSecondTitle>
            <TooltipError
              message={errors?.phone?.message || ''}
              visible={isVisiblePhone}
            >
              <ContactsContactPersonBlockSecondPhone
                data-class="ContactsContactPersonPhoneInput"
                largeInput={largeInput}
                value={contactPerson?.phone || ''}
                hasError={hasErrorPhone}
                onChange={onChangeHandlerPhone}
                onBlur={onBlurPhone(contactPersonId)}
                onFocus={onFocusPhone(contactPersonId)}
              />
            </TooltipError>
          </ContactsContactPersonBlockSecondPhoneWrapper>
          <ContactsContactPersonBlockSecondAPhoneWrapper>
            <ContactsContactPersonBlockSecondTitleDiv>
              <ContactsContactPersonBlockSecondTitle>
                {`${t('Additional Phone')}`}
              </ContactsContactPersonBlockSecondTitle>
            </ContactsContactPersonBlockSecondTitleDiv>
            <TooltipError
              message={errors?.additionalPhone?.message || ''}
              visible={isVisibleAdditionalPhone}
            >
              <ContactsContactPersonBlockSecondAPhone
                data-class="ContactsContactPersonPhone2Input"
                largeInput={largeInput}
                value={contactPerson?.phone2 || ''}
                onChange={onChangeHandlerAdditionalPhone}
                hasError={hasErrorAdditionalPhone}
                onBlur={onBlurAdditionalPhone(contactPersonId)}
                onFocus={onFocusAdditionalPhone(contactPersonId)}
              />
            </TooltipError>
          </ContactsContactPersonBlockSecondAPhoneWrapper>
        </ContactsContactPersonBlockSecondInputDiv>
      </ContactsContactPersonBlockSecond>

      {isLast && (
        <ContactsContactPersonBlockFourth>
          <ButtonPlus
            name={t('AddContactPerson')}
            onClick={addContactPersonItem(companyListStateIndex)}
          />
        </ContactsContactPersonBlockFourth>
      )}

      <Divider dashed style={CBDividerMargins} />

    </ContactsContactPersonBlock>
  );
};

const ContactsContactPersonBlockComponentCached = memo(ContactsContactPersonBlockComponentLocal);

export { ContactsContactPersonBlockComponentCached as ContactsContactPersonBlockComponent };
