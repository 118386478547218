// eslint-disable-next-line @typescript-eslint/no-unused-vars
export abstract class BaseService<DTM = null> {
  // abstract get(): DTM;
  //
  // abstract post(): DTM;
  //
  // abstract put(): DTM;
  //
  // abstract delete(): DTM;

  protected downloadBlob(file: Blob, fileName: string) {
    const url = window.URL.createObjectURL(file);
    const anchor = document.createElement('a');

    anchor.href = url;
    anchor.download = fileName;
    anchor.click();

    window.URL.revokeObjectURL(url);
    anchor.remove();
  }
}
