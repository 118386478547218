import {
  IsDefined,
  IsOptional,
  IsNumber,
  IsEnum,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import {
  EShipmentReceivingMethod,
  EShipmentLoadingMethod,
  EDrayageLoadingSide,
} from 'shipment-operations/constants';

export interface ILoadingControlDTM {
  id?: number;
  loadingTime?: number;
  drayageSide: EDrayageLoadingSide;
  loadingMethod?: EShipmentLoadingMethod;
  receivingMethod?: EShipmentReceivingMethod;
}

export class LoadingControlDTM extends BaseDTM<ILoadingControlDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsNumber()
  loadingTime?: number;

  @IsDefined()
  @IsEnum(EDrayageLoadingSide)
  drayageSide: EDrayageLoadingSide;

  @IsOptional()
  @IsEnum(EShipmentLoadingMethod)
  loadingMethod?: EShipmentLoadingMethod;

  @IsOptional()
  @IsEnum(EShipmentReceivingMethod)
  receivingMethod?: EShipmentReceivingMethod;

  static createDefault = (drayageSide: EDrayageLoadingSide) => LoadingControlDTM.fromPlain({
    id: undefined,
    loadingTime: undefined,
    drayageSide,
    loadingMethod: undefined,
    receivingMethod: undefined,
  });

  hasLiveLoadingTime() {
    return Boolean(this.loadingMethod === EShipmentLoadingMethod.LIVE_LOAD && this.loadingTime);
  }

  liveLoadingTime() {
    return this.hasLiveLoadingTime() && this.loadingTime
      ? this.loadingTime
      : null;
  }
}
