import React, {
  FC, useCallback, useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { AccountDepartmentDTM } from 'user-management/models/dtm';
import { Button, Option, Select } from 'app-wrapper/view/components';
import { DEPARTMENT_TYPE_NAMES } from 'user-management/constants';
import { Field, Name } from 'user-management/view/pages/Account/components/AccountDrawer/components/UserManagementEdit/UserManagementEdit.styled';

import {
  FirstNameContainer,
  LastNameContainer,
  PhoneInput,
  JobTitleInput,
} from './components';
import {
  ChangePasswordContainer,
  ChangePasswordFooter,
  ChangePasswordWrapper,
} from './UserEditProfile.styled';

interface ITransportationLocationDataComponentProps {
  isLoading: boolean
  onFormOpen: () => void
  onOk: () => void
  onCancel: () => void
  availableDepartments: AccountDepartmentDTM[]
  departments: string[]
  setDepartments: (departments: string[]) => void
  isPrimary: boolean
  userDepartmentsAndTeamsEdit?: PermissionAttributePolicy
}

export const UserEditProfileComponent: FC<ITransportationLocationDataComponentProps> = observer(({
  isLoading,
  onFormOpen,
  onOk,
  onCancel,
  availableDepartments,
  departments,
  setDepartments,
  isPrimary,
  userDepartmentsAndTeamsEdit,
}) => {
  const { t } = useTranslation();

  const isAbleToEditDepartments = hasAccess(userDepartmentsAndTeamsEdit, PermissionAttributePolicy.WRITE);

  useEffect(() => {
    onFormOpen();
  }, []);

  const onOkHandler = useCallback(() => {
    onOk();
  }, []);

  return (
    <ChangePasswordWrapper>
      <ChangePasswordContainer>
        <FirstNameContainer />

        <LastNameContainer />

        <PhoneInput />

        <JobTitleInput />

        {isPrimary && isAbleToEditDepartments ? (
          <Field>
            <Name>
              {t('Departments')}
            </Name>

            <Select
              mode="multiple"
              value={departments}
              onChange={setDepartments}
              allowClear
              disabled={isLoading}
            >
              {availableDepartments.map((department) => (
                <Option key={String(department?.id)} value={String(department?.id)}>
                  {DEPARTMENT_TYPE_NAMES[department.type]}
                </Option>
              ))}
            </Select>
          </Field>
        ) : null}
      </ChangePasswordContainer>

      <ChangePasswordFooter>
        <Button type="default" onClick={onCancel}>{t('Cancel')}</Button>
        <Button
          data-class="SubmitUserProfileEdit"
          loading={isLoading}
          onClick={onOkHandler}
        >
          {t('Save')}
        </Button>
      </ChangePasswordFooter>
    </ChangePasswordWrapper>
  );
});
