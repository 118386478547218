import i18n from 'app-wrapper/i18n/i18n';

export enum EReceivingMethod {
  FCFS = 'FCFS',
  APPOINTMENT = 'APPOINTMENT'
}

export const ReceivingMethodTranslations = {
  [EReceivingMethod.FCFS]: i18n.t('First Come / First Served'),
  [EReceivingMethod.APPOINTMENT]: i18n.t('Appointment'),
};
