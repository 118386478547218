import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { DrayageRateDownloadModalComponent } from './DrayageRateDownloadModal.component';

const DrayageRateDownloadModalContainer: FC = React.memo(() => {
  const isLoading = useSelector(R.selectors.oceanRatesModal.getLoading);
  const isFormDataFilled = useSelector(R.selectors.oceanRatesModal.getIsDrayageFormDataValid);
  const isOriginLocationSearchListLoading = useSelector(R.selectors.oceanRatesModal.getOriginLocationSearchListLoading);
  const effectiveDate = useSelector(R.selectors.oceanRatesModal.getEffectiveDate);
  const originLocation = useSelector(R.selectors.oceanRatesModal.getOriginLocation);
  const originLocationSearchList = useSelector(R.selectors.oceanRatesModal.getOriginLocationSearchList);

  return (
    <DrayageRateDownloadModalComponent
      isLoading={isLoading}
      isFormDataFilled={isFormDataFilled}
      isOriginLocationSearchListLoading={isOriginLocationSearchListLoading}
      originLocationSearchList={originLocationSearchList}
      effectiveDate={effectiveDate}
      originLocationValue={originLocation}
      onEffectiveDateChange={UC.oceanRatesModal.setEffectiveDate}
      onEffectiveDateBlur={UC.oceanRatesModal.blurEffectiveDateField}
      onOriginLocationChange={UC.oceanRatesModal.setOriginLocation}
      onOriginLocationBlur={UC.oceanRatesModal.blurOriginLocationField}
      onOriginLocationSearch={UC.oceanRatesModal.searchOriginLocation}
      onDownload={UC.oceanRatesModal.downloadDryageRates}
      onClose={appUC.drawer.closeDrawer}
    />
  );
});

export { DrayageRateDownloadModalContainer as DrayageRateDownloadModal };
