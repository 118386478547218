import styled from 'styled-components';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import Table from 'antd/es/table';

import { StyledTable } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';
import { Container } from 'shipment-operations/view/components';

export const MainContainer = styled(Container)`
  margin-top: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 18px 0 18px;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  color: #202C3C;
  margin: 0;
`;

export const TableName = styled.div`
  color: #202C3C;
  font-weight: 500;
  font-size: 16px;
  padding: 14px 18px;
`;

export const StyledHeaderIcon = styled(ArrowRightOutlined)`
  color: #202C3C;
  margin: 0 6px;
  font-size: 10px;
`;

export const StyledHeaderCell = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #202C3C;
`;

export const TableWrapper = styled.div`
  padding: 0 18px;
`;

export const CustomTableHeader = styled(Table).attrs((props: {
  fullColumns: boolean
}) => ({
  fullColumns: props.fullColumns,
}))`
  .ant-table-tbody {
    display: none;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  ${({ fullColumns }) => (fullColumns ? (
    `
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(1)::before,
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(4)::before {
      display: none;
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(3)::before {
      background-color: #b6bed3;
    }
    `
  ) : (
    `
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(1)::before,
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(4)::before {
      display: none;
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(3)::before {
      display: none;
    }
    `
  ))}
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
`;

export const CustomStyledTable = styled(StyledTable).attrs((props: {
  fullColumns: boolean
}) => ({
  fullColumns: props.fullColumns,
}))`
  ${({ fullColumns }) => (fullColumns ? (
    `
      .ant-table-tbody tr .ant-table-cell:nth-child(2)::before,
      .ant-table-tbody tr .ant-table-cell:nth-child(3)::before {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 1.6em;
        background-color: #b6bed3;
        transform: translateY(-50%);
        transition: background-color 0.3s;
        content: '';
      }
    `
  ) : (
    `
      .ant-table-tbody tr .ant-table-cell:nth-child(2)::before,
      .ant-table-tbody tr .ant-table-cell:nth-child(3)::before {
        display: none;
      }
    `
  ))}
`;

export const StyledMoreIcon = styled(MoreOutlined)`
  cursor: pointer;
  margin-right: -5px;
`;
