import styled from 'styled-components';
import Typography from 'antd/es/typography';

import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';

export const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 23px 16px;
  width: 100%;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(234, 238, 248, 1);
  }
`;

export const ChosenStub = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: rgba(108, 121, 222, 1);
`;

export const StepName = styled(Typography)`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(32, 44, 60, 1);
`;

export const WaitingIcon = styled(ClockCircleOutlined)`
  font-size: 16px;
  color: rgba(115, 129, 155, 1);
`;

export const PassedIcon = styled(CheckCircleFilled)`
  font-size: 16px;
  color: rgba(209, 215, 226, 1);
`;

export const CompletedIcon = styled(CheckCircleFilled)`
  font-size: 16px;
  color: rgba(117, 203, 63, 1);
`;
