import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';
import { MobxStoresContext } from 'app-wrapper/mobxStores';
import { UserEditProfileComponent } from './UserEditProfile.component';

const UserEditProfileContainer: FC = observer(() => {
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementProfilePermissions);
  const { userEditProfileStore } = useContext(MobxStoresContext);
  const {
    isLoading,
    departments,
    availableDepartments,
    isUserPrimary,
  } = userEditProfileStore.state;

  return (
    <UserEditProfileComponent
      isLoading={isLoading}
      departments={departments}
      availableDepartments={availableDepartments}
      isPrimary={!!isUserPrimary}
      setDepartments={UC.userEditProfile.setDepartments}
      onFormOpen={UC.userEditProfile.onFormOpen}
      onOk={UC.userEditProfile.changeUserProfileData}
      onCancel={UC.userEditProfile.closeUserEditProfileDrawer}
      userDepartmentsAndTeamsEdit={permissions?.userDepartmentsAndTeamsEdit}
    />
  );
});

export { UserEditProfileContainer as UserEditProfile };
