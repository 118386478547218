import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { RollShipmentDrawerComponent } from './RollShipmentDrawer.component';

const RollShipmentDrawerContainer = () => {
  const { shipmentId = '0' } = useParams();
  const errorMessage = useSelector(R.selectors.rollShipmentWizard.getErrorMessage);
  const isLoading = useSelector(R.selectors.rollShipmentWizard.getIsLoading);
  const schedules = useSelector(R.selectors.rollShipmentWizard.getSchedules);

  return (
    <RollShipmentDrawerComponent
      errorMessage={errorMessage}
      onClose={appUC.drawer.closeDrawer}
      onClear={UC.rollShipment.clearRequestsTimeoutsAndIntervals}
      shipmentId={shipmentId}
      init={UC.rollShipment.getInitialRFQRequestData}
      isLoading={isLoading}
      schedules={schedules}
    />
  );
};

export { RollShipmentDrawerContainer as RollShipmentDrawer };
