import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormVolumeInputComponent } from './CargoFormVolumeInput.component';

const CargoFormVolumeInputContainer: FC = () => {
  const { volume } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormVolumeInputComponent
      volume={volume}
      touchField={UC.cargo.touchField}
      setVolume={UC.cargo.setVolume}
      cargosAvailability={permissions.cargosAvailability}
      customerCargosAvailability={permissions.customerCargosAvailability}
    />
  );
};

export {
  CargoFormVolumeInputContainer as CargoFormVolumeInput,
};
