import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { SHIPMENT_CONTAINER } from 'app-wrapper/models/routes';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainersFormTitleComponent } from './ShipmentContainersFormTitle.component';

const ShipmentContainersFormTitleContainer: FC = () => {
  const { shipmentId } = useParams<SHIPMENT_CONTAINER>();
  const isRemoveLoading = useSelector(R.selectors.shipmentContainers.getRemoveLoadingState);
  const container = useSelector(R.selectors.shipmentContainers.getSelectedContainer);
  const disable = useSelector(R.selectors.shipment.getIsDisableChanges);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainersFormTitleComponent
      isRemoveLoading={isRemoveLoading}
      container={container}
      onRemove={UC.shipmentContainers.containerRemove}
      disable={disable}
      shipmentId={shipmentId}
      customerEditAvailability={permissions.customerEditAvailability}
    />
  );
};

export {
  ShipmentContainersFormTitleContainer as ShipmentContainersFormTitle,
};
