import { action } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';

import { MarkupPolicyDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { ICustomerSalesState } from 'user-management/models/states';

export const initialState: ICustomerSalesState = {
  isLoading: false,
  isSalesDrawerLoading: false,
  markupPolicy: undefined,
  salesDepartmentMembers: [],
  tempSaleRepresentativeEmail: undefined,
};

export class CustomerSalesStore extends BaseMobxStore<ICustomerSalesState> {
  @action
  setLoading(loading: boolean) {
    this.state.isLoading = loading;
  }

  @action
  setMarkupPolicy(markupPolicy?: MarkupPolicyDTM) {
    this.state.markupPolicy = markupPolicy;
  }

  @action
  setSalesDepartmentMembers(members: OrganizationMemberDTM[]) {
    this.state.salesDepartmentMembers = members;
  }

  @action
  setIsSalesDrawerLoading(isLoading: boolean) {
    this.state.isSalesDrawerLoading = isLoading;
  }

  @action
  setTempSaleRepresentativeEmail(email?: string) {
    this.state.tempSaleRepresentativeEmail = email;
  }
}

export const createCustomerSalesStore = (_initialState?: ICustomerSalesState) => new CustomerSalesStore(_initialState || initialState);
