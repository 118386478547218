import { RouteNames } from 'app-wrapper/constants';
import { ForbiddenError, NotFoundError } from 'app-wrapper/models/errors';
import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';
import { GetChargesParams } from 'shipment-operations/models/contracts';
import { ETransportationType } from 'monetary/constants';
import { ChargeCodeDesignation, ChargeCodeLoadTypeEnum } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { ChargeDTM, ModuleFunctionalityPermissionsDtm, PaymentTermsDTM } from 'shipment-operations/models/dtm';

@controller
export class ShipmentTransportationChargesController extends BaseController {
  loadData = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    let response: ChargeDTM[] | null;

    this.dispatch(R.actions.shipmentTransportationCharges.setIsLoading(true));

    try {
      response = await R.services.shipmentTransportationCharges.getCharges(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentTransportationCharges.setError(true));
      return;
    }
    const filteredRes = response.filter((item) => item.chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
    this.dispatch(R.actions.shipmentTransportationCharges.setData(filteredRes));
  }

  loadDrayageImport = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }

    await this.loadDrayage(shipmentId, {
      mode: ETransportationType.ROAD,
      designation: ChargeCodeDesignation.DESTINATION,
      loadType: ChargeCodeLoadTypeEnum.DRAYAGE,
    });
  }

  loadDrayageExport = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }

    await this.loadDrayage(shipmentId, {
      mode: ETransportationType.ROAD,
      designation: ChargeCodeDesignation.ORIGIN,
      loadType: ChargeCodeLoadTypeEnum.DRAYAGE,
    });
  }

  loadDrayage = async (shipmentId: string, params: GetChargesParams) => {
    let response: ChargeDTM[] | null;

    this.dispatch(R.actions.shipmentTransportationCharges.setIsLoading(true));

    await this.fetchPermissions(shipmentId);

    try {
      response = await R.services.shipmentTransportationCharges.getCharges(shipmentId, params);
    } catch (e) {
      this.dispatch(R.actions.shipmentTransportationCharges.setError(true));
      return;
    }

    this.dispatch(R.actions.shipmentTransportationCharges.setData(response));
  }

  public fetchPermissions = async (shipmentId: string) => {
    let paymentTerms: PaymentTermsDTM | null = null;

    try {
      paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId, true);
    } catch (e) {
      if (e instanceof NotFoundError || e instanceof ForbiddenError) {
        this.navigate(RouteNames.SHIPMENTS());
      }

      console.error(e);

      return;
    }

    let shipmentPermissions: ModuleFunctionalityPermissionsDtm | null = null;

    const userData = AuthR.selectors.auth.getUser(this.store.getState());

    try {
      shipmentPermissions = await this.repositories.shipmentPermissionsRepository.get(shipmentId, paymentTerms, userData.isAdmin, true) || null;
    } catch (e) {
      if (e instanceof NotFoundError || e instanceof ForbiddenError) {
        this.navigate(RouteNames.SHIPMENTS());
      }

      console.error(e);

      return;
    }

    if (shipmentPermissions) {
      this.dispatch(R.actions.shipment.setPermissions(shipmentPermissions));
    }
  }

  togglePercentage = (status: boolean) => {
    this.dispatch(R.actions.shipmentTransportationCharges.setPercentage(status));
  }

  onLeave = () => {
    this.dispatch(R.actions.shipmentTransportationCharges.clear());
  }
}
