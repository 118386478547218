import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ShipmentContainersFormCardsListHeaderContainer,
} from './ShipmentContainersFormCardsListHeader.styled';

export const ShipmentContainersFormCardsListHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <ShipmentContainersFormCardsListHeaderContainer>
      <span>{ t('Unit') }</span>
      <span>{ t('Weight') }</span>
      <span>{ t('Volume') }</span>
      <span>{ t('Value') }</span>
    </ShipmentContainersFormCardsListHeaderContainer>
  );
};
