import styled from 'styled-components';
import LinkOutlined from '@ant-design/icons/LinkOutlined';

import { FlexRow } from 'app-wrapper/view/guideline';
import { Select as BaseSelect } from 'app-wrapper/view/components';

export const LinkIcon = styled(LinkOutlined)`
  svg {
    color: ${({ theme }) => theme.themesColors.characterSecondaryDot45} !important;
  }
`;

export const Select = styled(BaseSelect)`
  width: 232px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 22px;

  &.ant-select {
    height: 32px !important;
    min-height: 32px !important;

    .ant-select-selector, .ant-select-selection-search-input {
      height: 32px !important;
    }

    .ant-select-selection-placeholder, .ant-select-selection-item {
      line-height: 32px !important;
    }
  }
  
  span {
    color: rgba(32, 44, 60, 1) !important;
  }
`;

export const OptionContent = styled(FlexRow)`
  min-width: 100%;
  justify-content: space-between;
`;
