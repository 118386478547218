import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/es/row';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';

import { validationEmail, formatPhone } from 'app-wrapper/utils';
import {
  Col,
  Input,
  Tooltip,
} from 'app-wrapper/view/components';
import { InputWithError } from 'app-wrapper/view/components/InputWithError/InputWithError.component';
import { RowSpaceBetween, GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { TooltipError } from 'user-management/view/components';
import { MAX_LENGTH_20 } from 'app-wrapper/constants';
import { Label, SectionTitle } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/SupplierLayout/SupplierLayout.styled';
import { SectionButton } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/components/SectionButton';

interface IContactSectionProps {
  contactPerson?: string;
  setContactPerson: (person: string) => void;
  email?: string;
  setEmail: (email: string) => void;
  phone?: string;
  setPhone: (phone: string) => void;
  additionalPhone?: string;
  setAdditionalPhone: (phone: string) => void;
  isRequiredErrorVisible?: boolean;
  phoneErrorMessage?: string;
  additionalPhoneErrorMessage?: string;
  openBookingDrayageSelectContactDrawer: () => void
  isAbleToSelectFromContactBook: boolean;
  saveContactToContactBookErrorMessage?: string;
  isSavingContact: boolean;
  isCustomer: boolean;
  saveContactToContactBook: () => void;
}

export const ContactSectionComponent: FC<IContactSectionProps> = ({
  contactPerson,
  setContactPerson,
  email,
  setEmail,
  phone,
  setPhone,
  additionalPhone,
  setAdditionalPhone,
  isRequiredErrorVisible,
  phoneErrorMessage,
  additionalPhoneErrorMessage,
  openBookingDrayageSelectContactDrawer,
  isAbleToSelectFromContactBook,
  saveContactToContactBookErrorMessage,
  isSavingContact,
  isCustomer,
  saveContactToContactBook,
}) => {
  const { t } = useTranslation();

  const { errorMessage } = validationEmail(email || '');

  return (
    <>
      <RowSpaceBetween>
        <SectionTitle>
          {t('Contact')}
        </SectionTitle>

        <GapHorizontalContainerStyled
          style={{
            width: 'fit-content',
          }}
        >
          {isCustomer ? (
            <>
              {saveContactToContactBookErrorMessage ? (
                <Tooltip
                  placement="top"
                  title={saveContactToContactBookErrorMessage}
                >
                  <div>
                    <SectionButton
                      disabled
                      type="default"
                      icon={<FileOutlined />}
                    >
                      {t('Save')}
                    </SectionButton>
                  </div>
                </Tooltip>
              ) : (
                <SectionButton
                  type="default"
                  icon={<FileOutlined />}
                  onClick={saveContactToContactBook}
                  disabled={isSavingContact}
                >
                  {t('Save')}
                </SectionButton>
              )}
            </>
          ) : null}

          <SectionButton
            type="default"
            onClick={openBookingDrayageSelectContactDrawer}
            icon={<ContactsOutlined />}
            disabled={!isAbleToSelectFromContactBook}
          >
            {t('Select')}
          </SectionButton>
        </GapHorizontalContainerStyled>
      </RowSpaceBetween>

      <Row
        style={{
          marginTop: 16,
        }}
        gutter={[8, 16]}
      >
        <Col span={12}>
          <Label>{t('Contact Person')} *</Label>

          <Input
            value={contactPerson}
            onChange={({ target }) => setContactPerson(target.value)}
            error={isRequiredErrorVisible && !contactPerson?.length}
          />
        </Col>

        <Col span={12}>
          <Label>
            {t('Email')} *
          </Label>

          <TooltipError
            visible={isRequiredErrorVisible && !!errorMessage.length}
            message={errorMessage}
          >
            <Input
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              error={isRequiredErrorVisible && !!errorMessage.length}
            />
          </TooltipError>
        </Col>

        <Col span={12}>
          <Label>{t('Phone')} *</Label>

          <InputWithError
            errorMessage={phoneErrorMessage}
            maxLength={MAX_LENGTH_20}
            value={phone}
            onChange={({ target }) => setPhone(formatPhone(target.value))}
            error={(isRequiredErrorVisible && !phone?.length) || !!phoneErrorMessage?.length}
          />
        </Col>

        <Col span={12}>
          <Label>{t('Additional Phone')}</Label>

          <InputWithError
            errorMessage={additionalPhoneErrorMessage}
            maxLength={MAX_LENGTH_20}
            value={additionalPhone}
            error={!!additionalPhoneErrorMessage?.length}
            onChange={({ target }) => setAdditionalPhone(formatPhone(target.value))}
          />
        </Col>
      </Row>
    </>
  );
};
