import React, {
  FC, useCallback, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { Collapse, Panel } from 'app-wrapper/view/components/Collapse';
import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';
import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import { Vector5Svg } from 'app-wrapper/view/icons';
import { Tooltip } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { normalizationCost } from 'app-wrapper/utils';

import { IDetailAccessorialsContainerDTM, IDetailAccessorialsTransportationDTM } from 'monetary/models/dtm';

import {
  GetStepAccessorialsTabItemComponentRowItem,
  GetStepAccessorialsTabItemComponentRowItemContainerItem,
  GetStepAccessorialsTabItemComponentRowItemContainerItemCost,
  GetStepAccessorialsTabItemComponentRowItemContainerItemSvg,
  GetStepAccessorialsTabItemComponentRowItemContainerItemText,
  GetStepAccessorialsTabItemComponentRowItemContainerItemTextInfo,
  GetStepAccessorialsTabItemComponentRowItemContainerItemTotalCost,
} from './GetStepAccessorialsTabItemComponent.styled';

interface IGetStepGetStepAccessorialsTabItemComponentComponent {
  fieldName: string
  nameComponent: string
  dataComponent?: IDetailAccessorialsContainerDTM[]
  dataComponentGroup?: IDetailAccessorialsTransportationDTM[]
}

export const GetStepGetStepAccessorialsTabItemComponentComponent: FC<IGetStepGetStepAccessorialsTabItemComponentComponent> = (props) => {
  const {
    nameComponent, dataComponent, fieldName,
    dataComponentGroup,
  } = props;
  const { t } = useTranslation();
  const endVisible = useRef<HTMLElement>(null);

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );
  const [
    changeCollapseState, setChangeCollapseState,
  ] = useState<{ [key: string]: string[] }>({ [fieldName]: [] });

  const onCollapseChangeHandler = useCallback(
    (name: string) => CollapseOnChangeHandler(
      endVisible.current, () => {
        setChangeCollapseState((prev) => ({
          ...prev,
          [name]: prev?.[name]?.length ? [] : [name],
        }
        ));
      },
    ),
    [],
  );

  const isShow = useMemo(() => !!(dataComponent && dataComponent.length), [dataComponent]);

  const getItem = useCallback((itemStep: IDetailAccessorialsContainerDTM, indexStep: number) => (
    <GetStepAccessorialsTabItemComponentRowItemContainerItem
      className={`GetStepAccessorialsTabItemComponentRowItemContainerItem__${nameComponent}`}
      key={`${nameComponent}_steps_${itemStep.name}_${indexStep + 0}`}
    >

      <GetStepAccessorialsTabItemComponentRowItemContainerItemSvg>
        <Vector5Svg />
      </GetStepAccessorialsTabItemComponentRowItemContainerItemSvg>
      <GetStepAccessorialsTabItemComponentRowItemContainerItemText>
        {itemStep.name}
        {!!itemStep.steps?.length && (
          <GetStepAccessorialsTabItemComponentRowItemContainerItemTextInfo>
            <Tooltip title={itemStep.steps.join(' ')} placement="top" maxWidth="300px">
              <InfoCircleOutlined
                className="FreightQuoteFormRequest__Row__Commodity__content_title_icon"
              />
            </Tooltip>
          </GetStepAccessorialsTabItemComponentRowItemContainerItemTextInfo>
        )}
      </GetStepAccessorialsTabItemComponentRowItemContainerItemText>
      <GetStepAccessorialsTabItemComponentRowItemContainerItemCost>
        {itemStep.perUnit ? `per ${itemStep.perUnit}` : ''}
      </GetStepAccessorialsTabItemComponentRowItemContainerItemCost>
      <GetStepAccessorialsTabItemComponentRowItemContainerItemTotalCost>
        $ {normalizationCost(itemStep.summary, { toFixed: 2, thousandthSeparatorComma: true })}
      </GetStepAccessorialsTabItemComponentRowItemContainerItemTotalCost>
    </GetStepAccessorialsTabItemComponentRowItemContainerItem>
  ), []);

  return isShow
    ? (
      <GetStepAccessorialsTabItemComponentRowItem className={`GetStepAccessorialsTabItemComponentRowItem__${nameComponent}`}>
        <Collapse
          expandIcon={onExpandIcon}
          onChange={onCollapseChangeHandler(nameComponent)}
          activeKey={changeCollapseState[nameComponent]}
        >
          <Panel
            header={t(nameComponent)}
            key={nameComponent}
          >
            {dataComponentGroup?.length ? dataComponentGroup?.map((item, index) => (
              <Collapse
                key={`Accessorials_${item.name}_${index + 1}`}
                expandIcon={onExpandIcon}
                onChange={onCollapseChangeHandler(`Accessorials_${item.name}_${index}`)}
                activeKey={changeCollapseState[`Accessorials_${item.name}_${index}`]}
              >
                <Panel
                  header={item.name}
                  key={`Accessorials_${item.name}_${index + 0}`}
                >
                  {item.containers.map((itemStep, indexStep) => getItem(itemStep, indexStep))}
                </Panel>
              </Collapse>
            )) : null}

            {!dataComponentGroup?.length ? dataComponent?.map((itemStep, indexStep) => getItem(itemStep, indexStep)) : null}
          </Panel>
        </Collapse>
      </GetStepAccessorialsTabItemComponentRowItem>
    )
    : null;
};
