import React, { FC, useCallback } from 'react';

import { InputWithError } from 'app-wrapper/view/components/InputWithError/InputWithError.component';
import { MAX_LENGTH_20 } from 'app-wrapper/constants';
import { formatPhone } from 'app-wrapper/utils';

interface IShippingPartiesPhoneInputComponentProps {
  phone: string;
  setPhone: (phone: string) => void;
  error?: boolean;
  errorMessage?: string;
}

export const PhoneInputComponent: FC<IShippingPartiesPhoneInputComponentProps> = ({
  phone,
  setPhone,
  error,
  errorMessage,
}) => {
  const handleChange = useCallback((e) => {
    setPhone(formatPhone(e.target.value));
  }, [setPhone]);

  return (
    <InputWithError
      errorMessage={errorMessage}
      value={phone}
      onChange={handleChange}
      maxLength={MAX_LENGTH_20}
      error={(error && !phone.length) || !!errorMessage}
    />
  );
};
