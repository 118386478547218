import styled from 'styled-components';

export const CollapseButtonStyled = styled.button.attrs({
  'data-class': 'CollapseButton',
})<{ $show?: boolean; }>`
  background: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme, $show }) => ($show ? theme.themesColors.primaryBranding6 : theme.themesColors.neutralBranding5)};
  border-radius: 2px;
  padding: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme, $show }) => ($show ? theme.themesColors.primaryBranding7 : theme.themesColors.characterBrandingTitle85)};
  svg {
    ${({ $show }) => ($show ? 'transform: rotate(270deg);' : 'transform: rotate(90deg);')}
    transition: transform 150ms ease;
  }
`;

export const CollapseButtonContent = styled.div`
  width: 16px;
  height: 16px;
`;
