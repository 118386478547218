import { Type } from 'class-transformer';
import {
  IsOptional,
  IsDefined,
  IsString,
  IsNumber,
  ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EPlaceTypes } from 'monetary/constants';

interface IFreightDoorsAutocompleteDTM {
  description: string
  placeId: string
  id?: string
}

export class FreightDoorsAutocompleteDTM extends BaseDTM<IFreightDoorsAutocompleteDTM> {
  @IsDefined()
  @IsString()
  description: string;

  @IsDefined()
  @IsString()
  placeId: string;

  @IsOptional()
  @IsString()
  id?: string;
}

interface IFreightDoorsByPlaceIdAddressDTM {
  longName?: string
  shortName?: string
  types?: string[]
}

export class FreightDoorsByPlaceIdAddressDTM extends BaseDTM<IFreightDoorsByPlaceIdAddressDTM> {
  @IsOptional()
  @IsString()
  longName?: string;

  @IsOptional()
  @IsString()
  shortName?: string;

  @IsOptional()
  @IsString({ each: true })
  types?: string[];
}

export interface IFreightDoorsByPlaceIdDTM {
  addressComponents: IFreightDoorsByPlaceIdAddressDTM[]
  fullAddress?: string
  placeId: string
  utcOffset?: number
  timezoneId?: string
  types?: string[]
}

export class FreightDoorsByPlaceIdDTM extends BaseDTM<IFreightDoorsByPlaceIdDTM> {
  @ValidateNested({ each: true })
  @Type(() => FreightDoorsByPlaceIdAddressDTM)
  addressComponents: FreightDoorsByPlaceIdAddressDTM[];

  @IsOptional()
  @IsString()
  fullAddress?: string;

  @IsString()
  placeId: string;

  @IsOptional()
  @IsNumber()
  utcOffset?: number;

  @IsOptional()
  @IsString()
  timezoneId?: string;

  @IsOptional()
  @IsString({ each: true })
  types?: string[];

  isValidAddress = () => {
    let isStreet = false;
    let isCountry = false;
    let isLocality = false;
    let isPostalCode = false;

    this.addressComponents?.forEach((item) => {
      if (item.types?.find((itemType) => itemType === EPlaceTypes.street_address) || item.types?.find((itemType) => itemType === EPlaceTypes.route)) {
        isStreet = true;
      }

      if (item.types?.find((itemType) => itemType === EPlaceTypes.country)) {
        isCountry = true;
      }

      if (item.types?.find((itemType) => itemType === EPlaceTypes.locality)) {
        isLocality = true;
      }

      if (item.types?.find((itemType) => itemType === EPlaceTypes.postal_code)) {
        isPostalCode = true;
      }
    });

    return isStreet && isCountry && isLocality && isPostalCode;
  }

  getInvalidAddressesTypes = () => {
    let isStreet = false;
    let isCountry = false;
    let isLocality = false;
    let isPostalCode = false;

    const invalidAddresses: string[] = [];

    this.addressComponents?.forEach((item) => {
      if (item.types?.find((itemType) => itemType === EPlaceTypes.street_address) || item.types?.find((itemType) => itemType === EPlaceTypes.route)) {
        isStreet = true;
      }

      if (item.types?.find((itemType) => itemType === EPlaceTypes.country)) {
        isCountry = true;
      }

      if (item.types?.find((itemType) => itemType === EPlaceTypes.locality) || item.types?.find((itemType) => itemType === EPlaceTypes.sublocality)) {
        isLocality = true;
      }

      if (item.types?.find((itemType) => itemType === EPlaceTypes.postal_code)) {
        isPostalCode = true;
      }
    });

    if (!isStreet) {
      invalidAddresses.push(EPlaceTypes.street_address);
    }
    if (!isCountry) {
      invalidAddresses.push(EPlaceTypes.country);
    }
    if (!isLocality) {
      invalidAddresses.push(EPlaceTypes.locality);
    }
    if (!isPostalCode) {
      invalidAddresses.push(EPlaceTypes.postal_code);
    }

    return invalidAddresses?.length ? invalidAddresses : undefined;
  }

  getCountryCode = () => {
    let name = '';

    this.addressComponents?.forEach((item) => {
      if (item.types?.find((itemType) => itemType === EPlaceTypes.country) && item.shortName) {
        name = item.shortName;
      }
    });

    return name;
  }
}

export interface ISessionFreightDoorsByPlaceId {
  // name search address this
  [key: string]: {
    result: IFreightDoorsByPlaceIdDTM
    maxAge: number
  }
}
