import styled from 'styled-components';

import { Divider as CommonDivider } from 'app-wrapper/view/components';
import { BodyTextMedium } from 'app-wrapper/view/fonts';
import {
  FlexCol,
  SubPageContent,
} from 'app-wrapper/view/guideline';

export const Wrap = styled(SubPageContent)`
  margin-top: 8px;
`;

export const Title = styled.span`
  ${BodyTextMedium}
`;

export const Divider = styled(CommonDivider)`
  margin: 16px 0;
`;

export const Content = styled(FlexCol)`
  gap: 8px;
`;
