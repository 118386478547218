import styled from 'styled-components';

interface FooterContainerProps {
  justifyContent?: string;
}

export const FooterContainer = styled.div.attrs({
  'data-class': 'FooterContainer',
})<FooterContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'flex-end'};
`;
