import React, { forwardRef } from 'react';
import AntRadio, { RadioProps } from 'antd/es/radio';
import { RadioButtonProps } from 'antd/es/radio/radioButton';

export const Radio = forwardRef((props: RadioProps) => (
  <AntRadio
    className="AntRadio"
    {...props}
  />
));

export const RadioButton = forwardRef((props: RadioButtonProps) => (
  <AntRadio.Button
    className="RadioButton"
    {...props}
  />
));
