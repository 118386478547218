import styled from 'styled-components';
import Typography from 'antd/es/typography';
import CompassOutlined from '@ant-design/icons/CompassOutlined';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 24px;
  padding: 8px 20px;
  width: calc(100% - 48px);
  border: 1px solid rgba(234, 238, 248, 1);
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
`;

export const Image = styled.img`
  width: 56px;
  height: 56px;
`;

export const Content = styled.div`
  margin: auto 0 auto 20px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const SubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;

export const ExploreButton = styled(Button)`
  margin: auto 0 auto auto;
  font-size: 14px;
  line-height: 22px;
  height: 32px;
`;

export const CompassIcon = styled(CompassOutlined)`
  margin-top: 2px;
  font-size: 14px;
  color: rgba(153, 163, 181, 1);
`;
