import {
  ContentSection,
  InfoCardComponent, MissingTextValue,
  StatusLight,
  TableListContentCell,
  TableListContentExpand,
  TableListContentItem,
  TableListContentItemBottom,
  TableListContentItemTop,
} from 'app-wrapper/view/guideline';
import {
  Collapse, EditEllipsisComponent, Menu, Panel,
} from 'app-wrapper/view/components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import {
  CONTAINER_TYPES_TO_NAME,
  DepartureDetailsStatusEnum,
  DepartureDetailsStatusTranslations,
} from 'shipment-operations/constants';
import {
  Dropdown,
  MenuItem,
} from 'user-management/view/pages/OrganizationDocuments/components/DocumentSectionPrint/components/ActionMenu/ActionMenu.styled';
import { observer } from 'mobx-react-lite';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';

interface DepartureContainerComponentProps {
  container: ContainerDTM
  openEditDrawer: (id: number) => void
  drayageSide: EDrayageSide
  isAbleToEdit?: boolean
  companyName?: string
}

export const DepartureContainerComponent = observer(({
  container,
  openEditDrawer,
  drayageSide,
  isAbleToEdit,
  companyName,
}: DepartureContainerComponentProps) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const departureDetail = container?.departureDetailByType(drayageSide);
  const handleExpand = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const handleOpenDrawer = useCallback(() => {
    openEditDrawer(Number(container.id));
  }, [container.id]);

  return (
    <ContentSection>
      <TableListContentItem key={1}>
        <TableListContentItemTop>
          <TableListContentCell
            width="20"
          >
            <TableListContentExpand
              onClick={handleExpand}
              id="expandIcon"
            >
              <VerticalFormItemSvg
                rotate={isCollapsed ? 0 : -90}
                style={{ color: isCollapsed ? themesColors.primaryBranding6 : themesColors.primaryBranding7 }}
              />
            </TableListContentExpand>
            {CONTAINER_TYPES_TO_NAME[container.type]} / {t('ID')}-{container.id}
          </TableListContentCell>
          <TableListContentCell
            width="20"
          >
            {container.number || <MissingTextValue />}
          </TableListContentCell>
          <TableListContentCell
            width="20"
          >
            {departureDetail?.arrivalDate ? departureDetail.arrivalDate?.getFormatDMMMHHmmWithOffset() : <MissingTextValue />}
          </TableListContentCell>
          <TableListContentCell
            width="20"
          >
            {departureDetail?.departureDate ? departureDetail.departureDate?.getFormatDMMMHHmmWithOffset() : <MissingTextValue />}
          </TableListContentCell>
          <TableListContentCell
            width="7"
          >
            {departureDetail?.dispatchOrder || <MissingTextValue />}
          </TableListContentCell>
          <TableListContentCell
            width="8"
            align="right"
          >
            <StatusLight
              colorIcon={departureDetail?.status === DepartureDetailsStatusEnum.DISPATCHED ? themesColors.characterBrandingSuccess : themesColors.secondaryDot45}
            />
            {
              departureDetail?.status
                ? DepartureDetailsStatusTranslations[departureDetail.status]
                : DepartureDetailsStatusTranslations[DepartureDetailsStatusEnum.UNASSIGNED]
            }
          </TableListContentCell>
          <TableListContentCell
            width="5"
            align="right"
          >
            {
              isAbleToEdit
                ? (
                  <Dropdown
                    placement="bottomRight"
                    overlay={(
                      <Menu>
                        <MenuItem onClick={handleOpenDrawer} id="customEditClick">
                          {t('Edit')}
                        </MenuItem>
                      </Menu>
                  )}
                  >
                    <EditEllipsisComponent id="customEditHover" />
                  </Dropdown>
                )
                : null
            }
          </TableListContentCell>
        </TableListContentItemTop>

        <TableListContentItemBottom>
          <Collapse
            activeKey={isCollapsed ? '1' : ''}
          >
            <Panel header="" key="1" extra={null}>
              <TableListContentItemTop>
                <TableListContentCell
                  width="20"
                >
                  <TableListContentExpand />
                  <InfoCardComponent
                    title={t('Drayage Carrier')}
                    value={companyName || <MissingTextValue />}
                  />
                </TableListContentCell>
                <TableListContentCell
                  width="20"
                >
                  <InfoCardComponent
                    title={t('Pickup Reference(s)')}
                    value={departureDetail?.reference || <MissingTextValue />}
                  />
                </TableListContentCell>
              </TableListContentItemTop>
            </Panel>
          </Collapse>
        </TableListContentItemBottom>
      </TableListContentItem>
    </ContentSection>
  );
});
