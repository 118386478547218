import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CreateOrSelectCompany } from 'shipment-operations/view/components';

const ShippingPartiesCompanyNameContainer: FC = () => {
  const selectedCompany = useSelector(R.selectors.shippingParties.getSelectedCompany);
  const selectedCurrentCompany = useSelector(R.selectors.shippingParties.getSelectedCurrentCompany);
  const companyError = useSelector(R.selectors.shippingParties.getCompanyErrorFromSelectedForm);
  const companyList = useSelector(R.selectors.shippingParties.getCompanyList);

  return (
    <CreateOrSelectCompany
      companyList={companyList}
      availability={PermissionAttributePolicy.WRITE}
      selectedCompany={selectedCompany}
      selectedCurrentCompany={selectedCurrentCompany}
      companyError={companyError}
      setCompanyName={UC.shippingParties.setCompanyName}
      onBlur={UC.shippingParties.validateCompanyName}
      openAddCompanyDrawer={UC.bookingWizardDrawer.openSPAddCompanyDrawer}
    />
  );
};

export {
  ShippingPartiesCompanyNameContainer as ShippingPartiesCompanyName,
};
