import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { CompanySelect as CompanySelectComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components';

export const CompanySelect = React.memo(() => {
  const companiesList = useSelector(R.selectors.bookingWizard.getCompaniesList);
  const company = useSelector(R.selectors.bookingWizard.getCargoSupplierCompany);
  const cargoSupplier = useSelector(R.selectors.bookingWizard.getCargoSupplierAddress);
  const isCargoSupplierErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoSupplierRequiredErrorVisible);

  return (
    <CompanySelectComponent
      companyId={company?.id}
      setCompanyId={UC.bookingWizard.setSupplierCompanyId}
      isRequiredErrorVisible={isCargoSupplierErrorVisible}
      companiesList={companiesList}
      prefilledAddress={cargoSupplier}
      openAddCompanyDrawer={UC.bookingWizardDrawer.openAddCompanyDrawerFromDryage}
    />
  );
});
