import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';

interface IOverviewControllerState {
  test: string
}

const initialState: IControllerState<IOverviewControllerState> = {
  isLoading: true,
  test: '',
};

export class ShipmentTransportationExportInlandPickupOverviewStore extends ControllerStore<IControllerState<IOverviewControllerState>> {}

export const createShipmentTransportationExportInlandPickupOverviewStore = (_initialState?: IControllerState<IOverviewControllerState>) => new ShipmentTransportationExportInlandPickupOverviewStore(_initialState || initialState);
