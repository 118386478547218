import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.oceanRatesModal;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getOriginLocationSearchListLoading = createSelector(
  localState,
  (state) => state.isOriginLocationSearchListLoading,
);

const getDestinationLocationSearchListLoading = createSelector(
  localState,
  (state) => state.isDestinationLocationSearchListLoading,
);

const getOpenState = createSelector(
  localState,
  (state) => state.isOpen,
);

const getRateType = createSelector(
  localState,
  (state) => state.rateType,
);

const getEffectiveDate = createSelector(
  localState,
  (state) => state.effectiveDate,
);

const getEffectiveDateError = createSelector(
  localState,
  (state) => state.effectiveDateError,
);

const getExpirationDate = createSelector(
  localState,
  (state) => state.expirationDate,
);

const getExpirationDateError = createSelector(
  localState,
  (state) => state.expirationDateError,
);

const getOriginLocation = createSelector(
  localState,
  (state) => state.originLocation,
);

const getOriginLocationError = createSelector(
  localState,
  (state) => state.originLocationError,
);

const getDestinationLocation = createSelector(
  localState,
  (state) => state.destinationLocation,
);

const getDestinationLocationError = createSelector(
  localState,
  (state) => state.destinationLocationError,
);

const getOriginLocationSearchList = createSelector(
  localState,
  (state) => state.originLocationSearchList,
);

const getDestinationLocationSearchList = createSelector(
  localState,
  (state) => state.destinationLocationSearchList,
);

const getCarrierScacList = createSelector(
  localState,
  (state) => state.carrierScacList,
);

const isFormDataFilled = createSelector(
  localState,
  (state) => (
    !!state.effectiveDate
    && !!state.expirationDate
  ),
);

const isFormDataValid = createSelector(
  localState,
  (state) => (
    !state.effectiveDateError
    && !state.expirationDateError
    && !state.originLocationError
    && !state.destinationLocationError
  ),
);

const getIsDrayageFormDataValid = createSelector(
  localState,
  (state) => Boolean(state.effectiveDate),
);

const getDrayageDrawerRateType = createSelector(
  localState,
  (state) => state.drayageDrawerRateType,
);

export const oceanRatesModalSelectors = {
  getCarrierScacList,
  getDestinationLocation,
  getDestinationLocationError,
  getDestinationLocationSearchList,
  getDestinationLocationSearchListLoading,
  getEffectiveDate,
  getEffectiveDateError,
  getExpirationDate,
  getExpirationDateError,
  getLoading,
  getOpenState,
  getOriginLocation,
  getOriginLocationError,
  getOriginLocationSearchList,
  getOriginLocationSearchListLoading,
  getRateType,
  isFormDataFilled,
  isFormDataValid,
  getIsDrayageFormDataValid,
  getDrayageDrawerRateType,
};
