import styled from 'styled-components';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Wrap = styled(FlexRow)`
  gap: 4px;
  cursor: pointer;
`;

export const UploadIcon = styled(UploadOutlined)`
  color: ${({ theme }) => theme.themesColors.primaryBranding6};
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
`;
