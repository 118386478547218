import styled from 'styled-components';
import Typography from 'antd/es/typography';
import RightOutlined from '@ant-design/icons/RightOutlined';

import { WaypointTypePortIcon, WareHouseSvg } from 'app-wrapper/view/icons';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const TitleRowText = styled(Typography)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const SubTitleRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const TransitWrap = styled(FlexRow)``;

export const TransitItem = styled(FlexRow)``;

export const TransitItemText = styled(Typography)`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(32, 44, 60, 1);
`;

export const TransitStorageIconWrap = styled.div`
  margin-top: 1px;
`;

export const TransitStorageIcon = styled(WareHouseSvg)`
  width: 16px;
  height: 16px;
  
  * {
    fill: rgba(209, 215, 226, 1);
  }
`;

export const TransitShipIcon = styled(WaypointTypePortIcon)`
  width: 16px;
  height: 16px;
  margin-top: 2px;
  
  * {
    fill: rgba(209, 215, 226, 1);
  }
`;

export const TransitItemDivider = styled(RightOutlined)`
  margin: 0 12px;

  svg {
    width: 10px;
    height: 10px;
    margin-top: 5px;
  }
`;

export const ScheduleWrap = styled(FlexRow)``;

export const ScheduleText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const ScheduleTextGrey = styled(ScheduleText)`
  margin-right: 4px;
  color: rgba(115, 129, 155, 1);
`;
