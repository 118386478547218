import moment, { Moment } from 'moment';
import get from 'lodash/fp/get';
import isEqual from 'lodash/isEqual';
import notification from 'antd/es/notification';
import i18n from 'i18next';
import { AxiosError } from 'axios';

import { DateDtm } from 'app-wrapper/models/dtm';
import { BaseController, controller } from 'proto/BaseController';

import { R } from 'shipment-operations/repository';
import { R as MonetaryR } from 'monetary/repository';
import {
  ContainerDTM,
  UpdatedDateDTM, UpdatedDatesRequestDTM,
} from 'shipment-operations/models/dtm';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';
import { ILocationsServiceDTM } from 'monetary/models/dtm/Quotas';
import {
  getArrivalLocation,
  getDepartureLocation,
  getLocationToOneString,
  getUSAArrivalLocation,
  getUSADepartureLocation,
} from 'app-wrapper/utils';
import { N_A, TERMINAL_CUTOFF } from 'shipment-operations/constants';
import { ETransportationType } from 'monetary/constants';
import { LoadPlansUseCase, GetEstimatedChargesUseCase } from 'shipment-operations/usecases';
import { ServerError } from 'app-wrapper/types/ServerError';
import { InternalServerError } from 'app-wrapper/models/errors';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';

@controller
export class ShipmentTrackerController extends BaseController {
  searchTimeoutId: number | undefined = undefined

  loadContainers = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }

    this.dispatch(R.actions.shipmentTracker.setError(false));
    this.dispatch(R.actions.shipmentTracker.setLoading(true));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLoading(true));
    const response = await R.services.shipmentTracker.getContainers(shipmentId);
    this.dispatch(R.actions.shipmentTracker.setContainers(response));
    this.dispatch(R.actions.shipmentTrackerRoutes.setContainers(response));

    if (response.length) {
      const planIds = response.map((plan) => plan.planId);
      await new LoadPlansUseCase(this).loadPlans(planIds);
    } else {
      this.dispatch(R.actions.shipmentTrackerRoutes.setSchedules([]));
    }
    const containers = await R.services.shipmentTracker.getContainersNew(shipmentId);
    this.dispatch(R.actions.shipmentTracker.setDispatchedContainers(containers));
    this.dispatch(R.actions.shipmentTracker.setPageLoading(false));
    this.dispatch(R.actions.shipmentTracker.setShouldUpdateData(false));
  }

  onChangeDate = (id: string, type: string, date: Moment | null) => {
    const allEvents = R.selectors.shipmentTracker.getAllEvents(this.store.getState());
    const matchedEvent = allEvents.find((elem) => elem.id === id);
    if (!matchedEvent) {
      console.error('ShipmentTrackerController.onPostDates error: no matchedEvent');
    }

    const locationUtcOffset = matchedEvent?.location.timeZoneId ? moment.tz(new Date(), matchedEvent.location.timeZoneId).utcOffset() : 0;
    const preparedEvent = UpdatedDateDTM.fromPlain({
      id,
      type,
      time: date ? DateDtm.fromPlainWithDateShift({
        date: date.format(),
        offset: locationUtcOffset,
      }) : undefined,
    });

    const updatedDates = R.selectors.shipmentTracker.getUpdatedDates(this.store.getState());
    if (date && updatedDates.find((item) => item.id === id && item.type === type)) {
      const dates = updatedDates.map((elem) => (elem.id === id ? preparedEvent : elem));
      this.dispatch(R.actions.shipmentTracker.setUpdatedDates(dates));

      return;
    }

    if (!date && updatedDates.find((item) => item.id === id && item.type === type)) {
      const dates = updatedDates.filter((elem) => elem.id !== id || elem.type !== type);
      this.dispatch(R.actions.shipmentTracker.setUpdatedDates(dates));

      return;
    }

    const dates = [...updatedDates, preparedEvent];
    this.dispatch(R.actions.shipmentTracker.setUpdatedDates(dates));
  }

  onResetChanges = () => {
    this.dispatch(R.actions.shipmentTracker.resetChanges());
  }

  onPostDates = async (shipmentId?: string) => {
    this.dispatch(R.actions.shipmentTracker.setUpdateDatesLoading(true));

    const updatedDates = R.selectors.shipmentTracker.getUpdatedDates(this.store.getState());
    const allEvents = R.selectors.shipmentTracker.getAllEvents(this.store.getState());
    const preparedDates = updatedDates.map((item) => {
      const matchedEvent = allEvents.find((elem) => elem.id === item.id);
      const time = item.time ? item.time.getDateAsMomentWithOffset() : null;

      const result = {
        planId: matchedEvent?.planId,
        code: matchedEvent?.code,
        type: item.type,
        time: time ? time.format() : null,
        place: matchedEvent?.location.type === 'DOOR' ? {
          type: matchedEvent?.location.type,
          country: matchedEvent?.location.country.name,
          countryCode: matchedEvent?.location.country.code,
          placeId: matchedEvent?.location.placeId,
        } : {
          type: matchedEvent?.location.type,
          country: matchedEvent?.location.country.name,
          countryCode: matchedEvent?.location.country.code,
          code: matchedEvent?.location.code,
        },
        source: 'MANUAL',
      };

      return UpdatedDatesRequestDTM.fromPlain(result);
    });

    try {
      await R.services.shipmentTracker.updateDates(preparedDates);
      this.dispatch(R.actions.shipmentTracker.setUpdateDatesLoading(false));
      this.dispatch(R.actions.shipmentTracker.setUpdatedDates([]));
      this.loadContainers(shipmentId);
    } catch {
      this.dispatch(R.actions.shipmentTracker.setUpdateDatesError(true));
    }
  }

  resetErrorPostDates = () => {
    this.dispatch(R.actions.shipmentTracker.setUpdateDatesError(false));
  }

  setDefaultLegs = () => {
    const plan = R.selectors.shipmentTrackerRoutes.getPlan(this.store.getState());
    const currentLegs = get(['route', 'legs'], plan[0]);
    const currentTransportation = get(['transportations'], plan[0]);
    const preparedLegs = currentLegs.reduce((acc: any, leg) => {
      const matchedTransportation = currentTransportation.find((item) => item.transportLeg === leg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? getUSADepartureLocation(leg) : getDepartureLocation(leg);
      const arrivalLocation = isUSAArrival ? getUSAArrivalLocation(leg) : getArrivalLocation(leg);

      return [...acc, {
        id: leg?.id,
        type: matchedTransportation?.transport.type,
        location: departureLocation,
        locationType: leg?.departureLocation.type,
        locationLoading: false,
        isUpdatedLocation: false,
        locationList: [],
        nextLocation: arrivalLocation,
        nextLocationType: leg?.arrivalLocation.type,
        isUpdatedNextLocation: false,
        nextLocationLoading: false,
        nextLocationList: [],
        etdTime: moment.parseZone(matchedTransportation?.schedule.departureTime),
        etaTime: moment.parseZone(matchedTransportation?.schedule.arrivalTime),
        vessel: matchedTransportation?.transport.name,
        voyage: matchedTransportation?.voyageCode,
        number: matchedTransportation?.transport.number,
        timeOutId: undefined,
      }];
    }, []);
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(preparedLegs));
  }

  toggleIsChangeTransportPlan = () => {
    const isChangedTransportPlan = R.selectors.shipmentTrackerRoutes.getIsChangedTransportPlan(this.store.getState());
    if (!isChangedTransportPlan) {
      this.dispatch(R.actions.shipmentTrackerRoutes.setIsChangedTransportPlan(true));
    }
  }

  setLocation = (code: string, id: number, type: string) => {
    this.toggleIsChangeTransportPlan();
    const legs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const matchedLeg = legs.find((item) => item.id === id);
    let updatedLeg = { ...matchedLeg };
    const updatedField = type === 'destination' ? 'nextLocation' : 'location';
    const fieldForUpdate = type === 'destination' ? 'isUpdatedNextLocation' : 'isUpdatedLocation';
    updatedLeg = {
      ...matchedLeg,
      [updatedField]: code,
      [fieldForUpdate]: true,
    };

    const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));
  }

  setDate = (date: Moment | null, id: number, type: string) => {
    this.toggleIsChangeTransportPlan();
    const legs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const matchedLeg = legs.find((item) => item.id === id);
    let updatedLeg = { ...matchedLeg };
    const updatedField = type === 'etd' ? 'etdTime' : 'etaTime';
    updatedLeg = {
      ...matchedLeg,
      [updatedField]: date,
    };

    const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));
  }

  setMode = (mode: string, id: number) => {
    this.toggleIsChangeTransportPlan();
    const legs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const matchedLeg = legs.find((item) => item.id === id);
    const updatedLeg = {
      ...matchedLeg,
      type: mode,
    };

    const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));
  }

  setVessel = (vessel: string, id: number, index: number) => {
    this.toggleIsChangeTransportPlan();
    const legs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const matchedLeg = legs.find((item) => item.id === id);
    const updatedLeg = {
      ...matchedLeg,
      vessel,
    };
    const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));

    const vesselErrors = R.selectors.shipmentTrackerRoutes.getVesselErrorIndexes(this.store.getState());
    const updatedVesselErrors = vesselErrors.filter((item) => item !== index);
    this.dispatch(R.actions.shipmentTrackerRoutes.setVesselErrorIndex(updatedVesselErrors));
  }

  setVoyage = (voyage: string, id: number, index: number) => {
    this.toggleIsChangeTransportPlan();
    const legs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const matchedLeg = legs.find((item) => item.id === id);
    const updatedLeg = {
      ...matchedLeg,
      voyage,
    };
    const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));

    const voyageErrors = R.selectors.shipmentTrackerRoutes.getVoyageErrorIndexes(this.store.getState());
    const updatedVoyageErrors = voyageErrors.filter((item) => item !== index);
    this.dispatch(R.actions.shipmentTrackerRoutes.setVoyageErrorIndex(updatedVoyageErrors));
  }

  setSchedule = (type: string, value: Moment) => {
    const schedules = R.selectors.shipmentTrackerRoutes.getEditableSchedules(this.store.getState());
    const updatedSchedules = {
      ...schedules,
      [type]: value,
    };
    if (type === TERMINAL_CUTOFF) {
      this.dispatch(R.actions.shipmentTrackerRoutes.setTerminalCutOffError(false));
      this.dispatch(R.actions.shipmentTrackerRoutes.setValidationLoaded(false));
      this.dispatch(R.actions.shipmentTrackerRoutes.setShowAddChargeButton(false));
    }

    this.dispatch(R.actions.shipmentTrackerRoutes.setEditableSchedules(updatedSchedules));
  }

  searchLocation = async (searchValue: string, id: number, type: string) => {
    const legs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const matchedLeg = legs.find((item) => item.id === id);
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    clearInterval(this.searchTimeoutId);

    await new Promise((resolve) => {
      this.searchTimeoutId = window.setTimeout(resolve, 400);
    });

    if (!searchValue) {
      let updatedLeg = { ...matchedLeg };
      const updatedFieldFirst = type === 'destination' ? 'nextLocation' : 'location';
      const updatedFieldSec = type === 'destination' ? 'nextLocationList' : 'locationList';
      updatedLeg = {
        ...matchedLeg,
        [updatedFieldFirst]: '',
        [updatedFieldSec]: [],
      };

      const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
      this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));

      return;
    }

    let updatedLeg = { ...matchedLeg };
    const updatedField = type === 'destination' ? 'nextLocationLoading' : 'locationLoading';
    updatedLeg = {
      ...matchedLeg,
      [updatedField]: true,
    };

    const updatedLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(updatedLegs));

    response = await MonetaryR.services.RFQServiceById.getLocationService({
      query: searchValue,
    });

    if (!response) {
      return;
    }

    result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
      description: getLocationToOneString({
        country: itemLocation?.country?.name,
        state: itemLocation?.locationName,
        code: itemLocation?.code,
      }),
      code: itemLocation.code,
      locationName: itemLocation.locationName,
      subdivisionCode: itemLocation.subdivisionCode,
      subdivisionName: itemLocation.subdivisionName,
      countryCode: itemLocation.country?.code,
      countryName: itemLocation.country?.name,
      timezoneId: itemLocation.timeZoneId,
      coordinates: itemLocation.coordinates,
    }));

    if (!result) return;

    if (type === 'destination') {
      updatedLeg = {
        ...matchedLeg,
        nextLocationList: result,
        nextLocationLoading: false,
      };
    } else {
      updatedLeg = {
        ...matchedLeg,
        locationList: result,
        locationLoading: false,
      };
    }
    const newLegs = legs.map((item) => (item.id === id ? updatedLeg : item));
    this.dispatch(R.actions.shipmentTrackerRoutes.setLegs(newLegs));
  }

  updateTracker = async (shipmentId: string) => {
    const schedule = R.selectors.shipmentTrackerRoutes.getEditableSchedules(this.store.getState());
    const originalSchedule = R.selectors.shipmentTrackerRoutes.getOriginalSchedules(this.store.getState());
    const isEqualSchedule = isEqual(schedule, originalSchedule);

    const savedLegs = R.selectors.shipmentTrackerRoutes.getLegs(this.store.getState());
    const savedOffset = savedLegs[0].etdTime.format().substring(19);
    const filteredLegs = savedLegs.map((leg) => ({
      ...leg,
      vessel: leg.vessel === N_A ? '' : leg.vessel,
    }));
    const firstEtd = savedLegs[0].etdTime;
    const isBefore = moment(schedule.terminalCutOff).isBefore(moment(firstEtd));
    const isFirstLegOcean = (savedLegs[0].type === ETransportationType.OCEAN);
    if (!isBefore && isFirstLegOcean) {
      this.dispatch(R.actions.shipmentTrackerRoutes.setTerminalCutOffError(true));
      notification.error({
        message: i18n.t('Port Cutoff Date must not be later then Origin ETD Date'),
        placement: 'bottomRight',
        duration: 5,
      });
      return;
    }
    this.dispatch(R.actions.shipmentTrackerRoutes.setTerminalCutOffError(false));
    let withError = false;
    const voyageErrors: number[] = [];
    const vesselErrors: number[] = [];
    filteredLegs.forEach((leg, index) => {
      if (leg.type === ETransportationType.SEA) {
        if (!leg.voyage) {
          voyageErrors.push(index);
          withError = true;
          return;
        }
      }
      if (![ETransportationType.ROAD, ETransportationType.RAIL].includes(leg.type as ETransportationType)) {
        if (leg.vessel || leg.voyage) {
          if (!leg.voyage) {
            voyageErrors.push(index);
            withError = true;
          }
          if (!leg.vessel) {
            vesselErrors.push(index);
            withError = true;
          }
        }
      }
    });
    this.dispatch(R.actions.shipmentTrackerRoutes.setVoyageErrorIndex(voyageErrors));
    this.dispatch(R.actions.shipmentTrackerRoutes.setVesselErrorIndex(vesselErrors));
    if (withError) {
      return;
    }
    this.dispatch(R.actions.shipmentTrackerRoutes.setIsLoadingUpdate(true));

    let updatedPlan = {};

    if (!isEqualSchedule) {
      const diff = new GetEstimatedChargesUseCase(this).findDiff(schedule, originalSchedule);
      const preparedCutoffs = new GetEstimatedChargesUseCase(this).parseCutoffs(diff);
      const cutoffsWithOffset = preparedCutoffs.map((item) => ({
        ...item,
        value: item.value.format().substring(0, 19) + savedOffset,
      }));
      updatedPlan = {
        ...updatedPlan,
        cutOffs: cutoffsWithOffset,
      };
    }

    const isChangedTransportPlan = R.selectors.shipmentTrackerRoutes.getIsChangedTransportPlan(this.store.getState());

    if (isChangedTransportPlan) {
      updatedPlan = {
        ...updatedPlan,
        transportationPlan: new GetEstimatedChargesUseCase(this).parseTransportPlan(),
      };
    }

    try {
      await R.services.shipmentTracker.updateTransportPlan(shipmentId, updatedPlan);
    } catch (e) {
      this.dispatch(R.actions.shipmentTrackerRoutes.setIsLoadingUpdate(false));
      throw e;
    }
    this.dispatch(R.actions.shipmentTrackerRoutes.setIsLoadingUpdate(false));
    this.dispatch(R.actions.shipmentTrackerRoutes.setIsUpdateSuccess(true));
    this.dispatch(R.actions.shipmentTrackerRoutes.setIsChangedTransportPlan(false));
    this.loadContainers(shipmentId);
  }

  clearEditPlan = () => {
    this.dispatch(R.actions.shipmentTrackerRoutes.clearEditPlan());
  }

  validate = async (shipmentId: string, type: string) => {
    if (!shipmentId) {
      return;
    }
    const companyId = R.selectors.shipment.getShipmentCustomerCompanyId(this.store.getState());
    const requestedCompanyId = R.selectors.shipmentChanges.getShortShipmentCompanyId(this.store.getState());
    if (!companyId && !requestedCompanyId) {
      return;
    }

    this.dispatch(R.actions.shipmentTrackerRoutes.setValidationLoading(true));
    const response = await R.services.shipmentCharges.getCustomerCharges(shipmentId, companyId || requestedCompanyId);
    this.dispatch(R.actions.shipmentTrackerRoutes.setSavedCharges(response));
    try {
      await new GetEstimatedChargesUseCase(this).getEstimatedCharges(shipmentId, type);
    } catch (e) {
      this.dispatch(R.actions.shipmentTrackerRoutes.setValidationLoading(false));
      const error = e as AxiosError<ServerError>;
      if (error instanceof InternalServerError) {
        this.dispatch(R.actions.shipmentTrackerRoutes.setShowAddChargeButton(true));
        return;
      }
      throw e;
    }
  }

  onUnlinkContainer = async (shipmentId: string, containerId: number) => {
    const containers = R.selectors.shipment.getContainers(this.store.getState());
    const matchedContainer = containers.find((item) => parseInt(item.id, 10) === containerId);
    if (matchedContainer) {
      this.dispatch(R.actions.shipmentTracker.setActionLoading(true));
      await R.services.shipmentTracker.updateContainer(shipmentId, ContainerDTM.fromPlain({ ...matchedContainer, number: null }));
      this.dispatch(R.actions.shipmentTracker.setActionLoading(false));
      this.dispatch(R.actions.shipmentTracker.setPageLoading(true));
      this.dispatch(R.actions.shipmentTracker.setShouldUpdateData(true));
    }
  }

  onLinkContainer = async (shipmentId: string, containerNumber: string, planId: number) => {
    const containers = R.selectors.shipment.getContainers(this.store.getState());
    const matchedContainer = containers.find((item) => parseInt(item.planId, 10) === planId);
    if (matchedContainer) {
      this.dispatch(R.actions.shipmentTracker.setActionLoading(true));
      await R.services.shipmentTracker.updateContainer(shipmentId, ContainerDTM.fromPlain({ ...matchedContainer, number: containerNumber }));
      this.dispatch(R.actions.shipmentTracker.setActionLoading(false));
      this.dispatch(R.actions.shipmentTracker.setPageLoading(true));
      this.dispatch(R.actions.shipmentTracker.setShouldUpdateData(true));
      new DrawersUseCase(this).closeDrawer();
    }
  }

  onClosePage() {
    super.onClosePage();
    this.dispatch(R.actions.shipmentTracker.clear());
    this.dispatch(R.actions.shipmentTrackerRoutes.clear());
    this.dispatch(R.actions.shipmentTracker.setShouldUpdateData(true));
  }
}
