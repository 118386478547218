import React from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { R } from 'shipment-operations/repository';
import {
  CarriersDetailsComponent,
} from 'shipment-operations/view/components/TransportationDrayage';
import { ECarrierSCAC } from 'monetary/constants';

export const CarriersDetails = observer(() => {
  const shipment = useSelector(R.selectors.shipment.getShipment);
  return (
    <CarriersDetailsComponent
      carrierSCAC={shipment?.scac as ECarrierSCAC}
      carrierName={shipment?.carrier}
      carrierReferenceNumber={shipment?.carrierReferenceNumber}
    />
  );
});
