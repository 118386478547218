import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import i18n from 'app-wrapper/i18n/i18n';

import { ChargeCodeMeasureBy } from 'shipment-operations/constants';

import { EFreightPaymentTerms } from '../Freight/freightPaymentTerms.dtm';
import { FreightQuotaTransportationDTM, IFreightQuotaTransportationDTM } from '../Freight/freightQuotaContent.dtm';
import { DetailBreakdownTransportationDTM, IDetailBreakdownTransportationDTM } from './detailBreakdown.dtm';

export interface IDetailRoutingContainerStepDTM {
  name?: string
  summary?: number
  cost?: number
  totalUnits: number
  totalCost?: number
  numberOfUnits?: number
  measureBy?: string
  infoSteps?: string[]
  code?: string
  isUnits?: boolean
  isIncludedBaseRate?: boolean
  descriptionText?: string
}

export class DetailRoutingContainerStepDTM extends BaseDTM<IDetailRoutingContainerStepDTM> {
  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsNumber()
  summary?: number;

  @IsOptional()
  @IsNumber()
  cost?: number;

  @IsDefined()
  @IsNumber()
  totalUnits: number;

  @IsOptional()
  @IsNumber()
  totalCost?: number;

  @IsOptional()
  @IsNumber()
  numberOfUnits?: number;

  @IsOptional()
  @IsString()
  measureBy?: string;

  @IsOptional()
  @IsString({ each: true })
  infoSteps?: string[];

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsBoolean()
  isUnits?: boolean;

  @IsOptional()
  @IsBoolean()
  isIncludedBaseRate?: boolean;

  @IsOptional()
  @IsString()
  descriptionText?: string;

  getEnumeratorValue = () => {
    if (this.measureBy === ChargeCodeMeasureBy.MILE
      && (this?.numberOfUnits || 0) > 1
    ) {
      return ` (${this?.numberOfUnits} ${i18n.t('measureBy.miles')})`;
    }

    return '';
  }
}

export interface IDetailRoutingStepsDTM {
  summary: number
  steps: IDetailRoutingContainerStepDTM[]
}

export class DetailRoutingStepsDTM extends BaseDTM<IDetailRoutingStepsDTM> {
  @IsDefined()
  @IsNumber()
  summary: number;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => DetailRoutingContainerStepDTM)
  steps: DetailRoutingContainerStepDTM[];
}

export interface IDetailRoutingDTM {
  from: string
  to: string
  duration: number
  legId?: number
  customId?: string
  type?: string
  etd: string
  eta: string
  pickupFromTime: string;
  pickupToTime: string;
  fullPickupTime: string;
  deliveryTime: string;
  fullReturnFromTime: string;
  fullReturnToTime: string;
  transportType: string
  paymentTerms: EFreightPaymentTerms
  VesselName?: string
  VoyageNumber?: string
  arrivalTerminal?: string
  departureTerminal?: string
  transportations?: IFreightQuotaTransportationDTM[]
  fees?: IDetailRoutingStepsDTM
  service?: IDetailBreakdownTransportationDTM
}

export class DetailRoutingDTM extends BaseDTM<IDetailRoutingDTM> {
  @IsDefined()
  @IsString()
  from: string;

  @IsDefined()
  @IsString()
  to: string;

  @IsDefined()
  @IsNumber()
  duration: number;

  @IsOptional()
  @IsNumber()
  legId?: number;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsString()
  type?: string;

  @IsDefined()
  @IsString()
  etd: string;

  @IsDefined()
  @IsString()
  eta: string;

  @IsDefined()
  @IsString()
  pickupFromTime: string;

  @IsDefined()
  @IsString()
  pickupToTime: string;

  @IsDefined()
  @IsString()
  fullPickupTime: string;

  @IsDefined()
  @IsString()
  deliveryTime: string;

  @IsDefined()
  @IsString()
  fullReturnFromTime: string;

  @IsDefined()
  @IsString()
  fullReturnToTime: string;

  @IsDefined()
  @IsString()
  transportType: string;

  @IsDefined()
  @IsEnum(EFreightPaymentTerms)
  paymentTerms: EFreightPaymentTerms;

  @IsOptional()
  @IsString()
  VesselName?: string;

  @IsOptional()
  @IsString()
  VoyageNumber?: string;

  @IsOptional()
  @IsString()
  arrivalTerminal?: string;

  @IsOptional()
  @IsString()
  departureTerminal?: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaTransportationDTM)
  transportations?: FreightQuotaTransportationDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailRoutingStepsDTM)
  fees?: DetailRoutingStepsDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailBreakdownTransportationDTM)
  service?: DetailBreakdownTransportationDTM;
}
