import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TitleTextSpan } from 'app-wrapper/view/fonts';
import {
  SubPageContent,
  ContentSection,
  GapVerticalContainerStyled,
} from 'app-wrapper/view/guideline';

interface ITaskDescriptionProps {
  description: string;
}

export const TaskDescription: FC<ITaskDescriptionProps> = ({ description }) => {
  const { t } = useTranslation();

  return (
    <SubPageContent>
      <GapVerticalContainerStyled>
        <TitleTextSpan>
          {t('Task Description')}
        </TitleTextSpan>

        <ContentSection>
          {description}
        </ContentSection>
      </GapVerticalContainerStyled>
    </SubPageContent>
  );
};
