import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from 'antd/es/menu';

import { VerticalDotsSvg } from 'app-wrapper/view/icons';

import {
  Dropdown,
  MenuRemoveItem,
} from 'user-management/view/pages/CustomerDocuments/components/DocumentsSection/components/ActionMenu/ActionMenu.styled';

import {
  Actions,
  DropdownIcon,
} from './ContainerActions.styled';

interface IContainerActionsComponentProps {
  deleteContainer: (id: string) => void;
  containerId: string;
}

export const ContainerActionsComponenet: FC<IContainerActionsComponentProps> = ({
  deleteContainer,
  containerId,
}) => {
  const { t } = useTranslation();

  const menu = useMemo(() => (
    <Menu>
      {deleteContainer ? (
        <MenuRemoveItem onClick={() => deleteContainer(containerId)}>
          {t('Delete')}
        </MenuRemoveItem>
      ) : null}
    </Menu>
  ), [deleteContainer]);

  return (
    <Actions>
      <Dropdown
        placement="bottomRight"
        overlay={menu}
      >
        <DropdownIcon>
          <VerticalDotsSvg />
        </DropdownIcon>
      </Dropdown>
    </Actions>
  );
};
