import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { ModalRateDateComponent } from './ModalRateDate.component';

const ModalRateDate = () => {
  const {
    quotaRFRRequestOptions,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <ModalRateDateComponent
      valueDate={quotaRFRRequestOptions?.fromDate}
      errorRateDate={quotaRFRRequestOptions?.errorRateDate}
      isDoor={quotaRFRRequestOptions?.isDoor}
      onClickCloseModal={UC.FreightRFRQuote.onChangeIsOpenModalRate(false)}
      onClickEmptyQuotaHasSchedule={UC.FreightRFRQuote.onClickEmptyQuotaCreateNewRates}
      onChangeModalRateDate={UC.FreightRFRQuote.onChangeModalRateDate}
    />
  );
};

export { ModalRateDate };
