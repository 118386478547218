import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { FlexCenter, GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';

export const BankDetailsWrap = styled.div`
  width: 100%;
  padding: 24px;
  page-break-inside: avoid;
`;

export const BankDetails = styled.div`
  width: 100%;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 0.5px solid rgba(209, 215, 226, 1);
`;

export const Title = styled(Typography)`
  margin-bottom: 7.2px;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 600;
  color: rgba(32, 44, 60, 1);
`;

export const Items = styled(GapHorizontalContainerStyled)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled(Typography)`
  font-size: 9.6px;
  line-height: 14.4px;
  font-weight: 700;
`;

export const ItemText = styled(Typography)`
  font-size: 9.6px;
  line-height: 12px;
`;

export const ReferenceWrap = styled(FlexCenter)`
  margin-bottom: 12px;
  width: 100%;
  height: 21px;
  border-radius: 2px;
  border: 0.5px solid ${({ theme }) => theme.themesColors.neutralBranding6};
  font-size: 8px;
  line-height: 8px;
`;

export const ReferenceBold = styled.span`
  font-weight: 700;
`;
