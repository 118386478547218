import message from 'antd/es/message';
import { PASSWORD_MIN_LENGTH } from 'authentication/consts/PasswordMinLength';
import i18n from 'i18next';

import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';
import { R } from 'user-management/repository';

@controller
export class ChangePasswordController extends BaseController {
  public changeUserPassword = async () => {
    const currentPassword = R.selectors.changePassword.getCurrentPassword(this.store.getState());
    const newPassword = R.selectors.changePassword.getNewPassword(this.store.getState());

    this.validateCurrentPassword();
    this.validateNewPassword();
    this.validateConfirmNewPassword();

    if (!currentPassword || !newPassword) {
      console.error('No password');
      return;
    }

    await AuthR.services.auth.changePassword(currentPassword, newPassword);
    this.dispatch(R.actions.changePassword.clear());
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Password changed successfully'));
  };

  public setCurrentPassword = (value: string) => {
    this.dispatch(R.actions.changePassword.setCurrentPassword(value));
  }

  public onBlurCurrentPassword = () => {
    this.validateCurrentPassword();
  }

  public setNewPassword = (value: string) => {
    this.dispatch(R.actions.changePassword.setNewPassword(value));
  }

  public onBlurNewPassword = () => {
    this.validateNewPassword();
  }

  public setConfirmNewPassword = (value: string) => {
    this.dispatch(R.actions.changePassword.setConfirmNewPassword(value));
  }

  public onBlurConfirmNewPassword = () => {
    this.validateConfirmNewPassword();
  }

  public closeChangePasswordDrawer = () => {
    this.dispatch(R.actions.changePassword.clear());
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  private validateCurrentPassword = () => {
    const currentPassword = R.selectors.changePassword.getCurrentPassword(this.store.getState());

    this.dispatch(R.actions.changePassword.clearCurrentPasswordError());

    if (!currentPassword) {
      this.dispatch(R.actions.changePassword.setCurrentPasswordError(new Error()));
    }
  }

  private validateNewPassword = () => {
    const newPassword = R.selectors.changePassword.getNewPassword(this.store.getState());

    this.dispatch(R.actions.changePassword.clearNewPasswordError());

    if (!newPassword) {
      this.dispatch(R.actions.changePassword.setNewPasswordError(new Error()));

      return;
    }

    if (newPassword.length < PASSWORD_MIN_LENGTH) {
      this.dispatch(R.actions.changePassword.setNewPasswordError(new Error(i18n.t('basicErrors.MIN_LENGTH', { count: PASSWORD_MIN_LENGTH }))));
    }
  }

  private validateConfirmNewPassword = () => {
    const newPassword = R.selectors.changePassword.getNewPassword(this.store.getState());
    const confirmNewPassword = R.selectors.changePassword.getConfirmNewPassword(this.store.getState());

    this.dispatch(R.actions.changePassword.clearConfirmNewPasswordError());

    if (!confirmNewPassword) {
      this.dispatch(R.actions.changePassword.setConfirmNewPasswordError(new Error()));

      return;
    }

    if (newPassword !== confirmNewPassword) {
      this.dispatch(R.actions.changePassword.setConfirmNewPasswordError(new Error(i18n.t('New password and confirm password should be equal'))));
    }
  }
}
