import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Popover from 'antd/es/popover';

import { hasAccess } from 'app-wrapper/utils';
import { Button, SkeletonSection } from 'app-wrapper/view/components';
import {
  ContainerBasicTypes,
  ContainerReeferTypes,
  ContainerReeferTypesArray,
  ContainerReeferTypesNamesLongConst,
  ContainerUsualTypes,
  ContainerUsualTypesArray,
  ContainerUsualTypesNamesLongConst,
  PermissionAttributePolicy,
} from 'shipment-operations/constants';
import {
  PopoverContainer,
} from 'shipment-operations/view/pages/ShipmentOverview/components/ShipmentOverviewTitle/ShipmentOverviewTitle.styled';
import {
  DisableButton,
} from 'shipment-operations/view/pages/ShipmentContainers/components/ShipmentContainersTitle/ShipmentContainersTitle.styled';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { ContainersPermissionsDtm } from 'shipment-operations/models/dtm';

interface IShipmentContainersAddContainerModalContainerProps {
  isLoading: boolean
  isLoadingPage: boolean
  isDisabled: boolean
  containerBasicType?: ContainerBasicTypes
  onAddHandler: (shipmentId: string | undefined, type: ContainerReeferTypes | ContainerUsualTypes) => void
  openChangeRequestDrawer: () => void
  shipmentId: string | undefined
  disable: boolean
  permissions: ContainersPermissionsDtm
}

export const ShipmentContainersAddContainerModalComponent: FC<IShipmentContainersAddContainerModalContainerProps> = ({
  isLoading,
  isLoadingPage,
  isDisabled,
  containerBasicType,
  onAddHandler,
  disable,
  shipmentId,
  openChangeRequestDrawer,
  permissions,
}) => {
  const { t } = useTranslation();

  const onAdd = useCallback((_shipmentId: string | undefined, type: ContainerReeferTypes | ContainerUsualTypes) => () => {
    onAddHandler(_shipmentId, type);
  }, []);

  const onOpenChangeRequestDrawer = useCallback(() => {
    openChangeRequestDrawer();
  }, []);

  const isReeferType = containerBasicType === ContainerBasicTypes.REEFER;

  const menu = useMemo(() => (
    <Menu>
      {(isReeferType ? ContainerReeferTypesArray : ContainerUsualTypesArray).map((item) => (
        <Menu.Item key={item}>
          <Button
            size="small"
            type="text"
            onClick={onAdd(shipmentId, item)}
          >
            {(isReeferType
              ? ContainerReeferTypesNamesLongConst[item as ContainerReeferTypes]
              : ContainerUsualTypesNamesLongConst[item as ContainerUsualTypes])}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  ), [isReeferType]);

  if (isLoadingPage) {
    return <SkeletonSection height="33.11px" width="143.78px" />;
  }

  if (disable) {
    return (
      <Popover
        trigger="hover"
        content={<PopoverContainer>{t('changesForbidden')}</PopoverContainer>}
        placement="left"
        overlayInnerStyle={{
          backgroundColor: 'rgba(32, 44, 60, 1)',
        }}
      >
        <DisableButton>{t('addContainer')}</DisableButton>
      </Popover>
    );
  }

  if (hasAccess(permissions.requestChangesAvailability, PermissionAttributePolicy.WRITE)) {
    return (
      <Button
        icon={<EditOutlined />}
        size="middle"
        onClick={onOpenChangeRequestDrawer}
        disabled={disable}
      >
        {t('Request Changes')}
      </Button>
    );
  }

  return (
    <Dropdown overlay={menu} disabled={isDisabled || !hasAccess(permissions.customerEditAvailability, PermissionAttributePolicy.WRITE)}>
      <Button
        icon={<PlusOutlined />}
        size="middle"
        loading={isLoading}
      >
        {t('addContainer')}
      </Button>
    </Dropdown>
  );
};
