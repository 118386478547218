import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { ICountryListDTM } from 'app-wrapper/models/dtm';
import { Option, SelectWithError, Tooltip } from 'app-wrapper/view/components';

import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  EditProfileOrganizationStateContainer,
} from 'user-management/view/pages/Account/components/AccountDrawer/components/EditProfileOrganization/components/EditProfileOrganizationAddress/components/EditProfileOrganizationState.container';
import {
  EditProfileOrganizationAddress, EditProfileOrganizationHeader, EditProfileOrganizationInput, EditProfileOrganizationSpaceColumn, EditProfileOrganizationSpaceInput, EditProfileOrganizationSpaceRow, EditProfileOrganizationSubTitle, EditProfileOrganizationTitle,
} from './EditProfileOrganizationAddress.styled';

interface IEditProfileOrganizationAddressProps {
  organization?: OrganizationDTM
  organizationEditProfileCountry?: boolean
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  countriesList?: ICountryListDTM[]
  onChangeUpdateAddress1: (address1: string) => void
  onChangeUpdateAddress2: (address2: string) => void
  onChangeUpdateAddressCity: (city: string) => void
  onChangeUpdateAddressPostalCode: (postalCode: string) => void
  onChangeUpdateAddressCountry: (country: string) => void
  onClearUpdateAddressCountry: () => void
}

export const EditProfileOrganizationAddressComponent: FC<IEditProfileOrganizationAddressProps> = (props) => {
  const {
    organization,
    organizationEditProfileCountry,
    organizationFieldsErrors,
    countriesList,
    onChangeUpdateAddress1,
    onChangeUpdateAddress2,
    onChangeUpdateAddressCity,
    onChangeUpdateAddressPostalCode,
    onChangeUpdateAddressCountry,
    onClearUpdateAddressCountry,
  } = props;
  const { t } = useTranslation();

  const onChangeAddress1 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddress1(event.target.value);
  }, []);

  const onChangeAddress2 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddress2(event.target.value);
  }, []);

  const onChangeAddressCity = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddressCity(event.target.value);
  }, []);

  const onChangeAddressPostalCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateAddressPostalCode(event.target.value);
  }, []);

  const onChangeAddressCountry = useCallback((country: string) => {
    onChangeUpdateAddressCountry(country);
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!filed?.message,
    [],
  );

  return (
    <EditProfileOrganizationAddress>
      <EditProfileOrganizationHeader>
        {t('address')}
      </EditProfileOrganizationHeader>
      <EditProfileOrganizationTitle>
        {`${t('Address Line 1')} *`}
      </EditProfileOrganizationTitle>
      <EditProfileOrganizationInput
        value={organization?.address?.address1}
        hasError={getHasErrorField(organizationFieldsErrors?.address1)}
        onChange={onChangeAddress1}
      />
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationTitle>
        {`${t('Address Line 2')}`}
      </EditProfileOrganizationTitle>
      <EditProfileOrganizationInput
        value={organization?.address?.address2}
        hasError={getHasErrorField(organizationFieldsErrors?.address2)}
        onChange={onChangeAddress2}
      />
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Country')} *`}
            <Tooltip title={t('At this moment, we can only support countries from the list. More countries will be added during 2024. We apologize for any inconvenience.')} placement="top">
              <InfoCircleOutlined />
            </Tooltip>
          </EditProfileOrganizationTitle>
          <SelectWithError
            data-class="EditProfileOrganizationSelectCountry"
            value={organization?.address?.country}
            disabled={organizationEditProfileCountry}
            hasError={getHasErrorField(organizationFieldsErrors?.addressCountry)}
            showSearch
            allowClear={!!organization?.address.country}
            onChange={onChangeAddressCountry}
            onClear={onClearUpdateAddressCountry}
            optionFilterProp="children"
          >
            {countriesList?.map((item) => (
              <Option
                value={item.code}
                key={`EditProfileOrganizationSelect_${item.code}`}
              >
                {item.name}
              </Option>
            ))}

          </SelectWithError>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('State')}`}
            <EditProfileOrganizationSubTitle>{`${t('USRequired')}`}</EditProfileOrganizationSubTitle>
          </EditProfileOrganizationTitle>
          <EditProfileOrganizationStateContainer />
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('City')} *`}
          </EditProfileOrganizationTitle>
          <EditProfileOrganizationInput
            value={organization?.address?.city}
            hasError={getHasErrorField(organizationFieldsErrors?.addressCity)}
            onChange={onChangeAddressCity}
          />
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('PostalCode')} *`}
          </EditProfileOrganizationTitle>
          <EditProfileOrganizationInput
            value={organization?.address?.postalCode}
            hasError={getHasErrorField(organizationFieldsErrors?.addressPostalCode)}
            onChange={onChangeAddressPostalCode}
          />
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
    </EditProfileOrganizationAddress>
  );
};
