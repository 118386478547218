import styled from 'styled-components';

import { BodyTextMedium } from 'app-wrapper/view/fonts';

import {
  SubPageContent,
  FlexRow,
  GapVerticalContainerStyled,
} from 'app-wrapper/view/guideline';

export const Content = styled(GapVerticalContainerStyled)`
  padding-bottom: 120px;
  justify-content: flex-start;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled(SubPageContent)`
  flex-direction: row;
`;

export const Title = styled.span`
  ${BodyTextMedium}
`;

export const HeaderContent = styled(FlexRow)`
  margin-left: auto;
  gap: 20px;
`;
