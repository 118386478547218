import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ShipmentTransportationTrackerComponent } from './ShipmentTransportationTracker.component';

const ShipmentTransportationTrackerContainer = ({ isCommon = false }: { isCommon?: boolean }) => {
  const errorUpdate = useSelector(R.selectors.shipmentTracker.getIsErrorUpdate);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);
  const isLoading = useSelector(R.selectors.shipmentTracker.getIsLoadingPage);
  const isLoadingData = useSelector(R.selectors.shipmentTracker.getIsLoading);
  const showUntrackedWarning = useSelector(R.selectors.shipmentTracker.getIsShowUntrackedWarning);
  const shouldUpdateData = useSelector(R.selectors.shipmentTracker.getShouldUpdateData);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const isOriginPartner = useSelector(R.selectors.shipment.getIsOriginPartner);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);

  return (
    <ShipmentTransportationTrackerComponent
      isCommon={isCommon}
      loadData={UC.shipmentTracker.loadContainers}
      isLoading={isLoading || isLoadingData}
      onClosePage={UC.shipmentTracker.onClosePage}
      resetError={UC.shipmentTracker.resetErrorPostDates}
      errorUpdate={errorUpdate}
      withEdit={hasAccess(permissions.trackerAvailability, PermissionAttributePolicy.WRITE)}
      showUntrackedWarning={showUntrackedWarning}
      shouldUpdateData={shouldUpdateData}
      isOriginDoor={shipment?.getIsOriginDoor()}
      isOriginPartner={isOriginPartner}
      isDestinationPartner={isDestinationPartner}
    />
  );
};

export { ShipmentTransportationTrackerContainer as ShipmentTransportationTracker };
