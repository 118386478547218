export enum ETasksType {
  NRA_AGREEMENT_TASK = 'ConfirmYourBookingTask',
  CREATE_BOOKING_REQUEST_TASK = 'CreateBookingRequestTask',
  MISSING_DATES = 'ProvideMissingTrackerDates',
  VGM_TASK = 'SubmitVgmTask',
  SUBMIT_RAIL_BILLING = 'SubmitRailBillingTask',
  VALIDATE_BOOKING_INFORMATION = 'ValidateBookingInformationTask',
  VALIDATE_SCHEDULE_UPDATES = 'ValidateScheduleUpdatesTask',
  VALIDATE_SHIPMENT_CHANGES = 'ValidateShipmentChangesTask',
  SUBMIT_BOOKING_AMEND_REQUEST = 'SubmitBookingAmendRequestTask',
  CONFIRM_SHIPMENT_REQUEST = 'ConfirmShipmentRequestTask',
  CONFIRM_SHIPMENT_COST_CHANGE_TASK = 'ConfirmShipmentCostChangeTask',
  SEND_ISF_TASK = 'SendIsfFormTask',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  COMPLETE_BOOKING_TASK = 'CompleteBookingTask',
}
