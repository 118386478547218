import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { InputStyled } from './ValueInput.styled';

interface WeightInputComponentProps {
  cargoId: number;
  value: string;
  touchCargoField: (field: string, cargoId: number) => void
  setValue: (description: string, cargoId: number) => void
  error?: ValidationErrorDTM;
}

export const ValueInputComponent: FC<WeightInputComponentProps> = ({
  cargoId,
  value,
  touchCargoField,
  setValue,
  error,
}) => {
  const { t } = useTranslation();

  const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value, cargoId);
  }, [setValue, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('weight', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <InputStyled
      placeholder={t('Enter Value')}
      value={value}
      onChange={handleChangeValue}
      onBlur={handleBlur}
      error={!!error}
    />
  );
};
