import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { R as RApp } from 'app-wrapper/repository';

import { OrganizationAddressComponent } from './OrganizationAddress.component';

const OrganizationAddressContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);
  const countriesList = useSelector(RApp.selectors.countriesList.getCountriesList);

  return (
    <OrganizationAddressComponent
      organization={organization}
      countries={countriesList}
    />
  );
};

export { OrganizationAddressContainer as OrganizationAddress };
