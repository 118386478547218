import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shipment-operations/view/components';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { ContentSkeletons } from 'shipment-operations/view/components/BookingDrawer/BookingDrawer.styled';
import { TermsAndConditions } from 'shipment-operations/view/components/BookingDrawer/components';
import { ScheduleStep } from 'shipment-operations/view/components/BookingDrayageDrawer/components/ScheduleStep/ScheduleStep.container';

import {
  StepsHeader,
  Footer,
  BookingDetails,
  PickupDeliveryStep,
} from './components';

interface BookingDrawerComponentProps {
  onClose: () => void;
  shipmentId: string;
  init: (shipmentId: string) => void;
  isLoading: boolean;
  currentStep: number;
  isOriginPartner: boolean;
  isShipmentWaitingApproval: boolean;
}

export const BookingDrayageDrawerComponent: FC<BookingDrawerComponentProps> = ({
  onClose,
  shipmentId,
  init,
  isLoading,
  currentStep,
  isOriginPartner,
  isShipmentWaitingApproval,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (shipmentId) {
      init(shipmentId);
    }
  }, [shipmentId]);

  return (
    <CustomModal
      title={t('New Booking')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      <StepsHeader />

      {isLoading ? (
        <ContentSkeletons>
          <SkeletonSection height="143px" />
          <SkeletonSection height="55px" />
          <SkeletonSection height="56px" />
          <SkeletonSection height="66px" />
        </ContentSkeletons>
      ) : (
        <>
          {isShipmentWaitingApproval ? (
            <>
              {currentStep === 1 ? <BookingDetails /> : null}
              {currentStep === 2 ? <ScheduleStep /> : null}
              {currentStep === 3 ? <PickupDeliveryStep /> : null}
            </>
          ) : (
            <>
              {currentStep === 1 ? <BookingDetails /> : null}
              {currentStep === 2 ? <PickupDeliveryStep /> : null}
              {currentStep === 3 && !isOriginPartner ? <TermsAndConditions /> : null}
            </>
          )}
        </>
      )}

      <Footer />
    </CustomModal>
  );
};
