import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { ExpandRenderComponent } from './ExpandRender.component';

const ExpandRenderContainer = memo(() => {
  const { origin, freight, destination } = useSelector(R.selectors.bookingWizard.getCurrentScheduleSchema);

  return (
    <ExpandRenderComponent
      origin={origin}
      freight={freight}
      destination={destination}
    />
  );
});

export { ExpandRenderContainer as ExpandRender };
