import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SupplierLayout } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components';

import {
  CompanySelect,
  AddressSection,
  ContactSection,
  LoadingDetails,
  ReferencesSection,
} from './components';

export const CargoSupplierComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <SupplierLayout
      title={t('Cargo Supplier')}
      company={<CompanySelect />}
      address={<AddressSection />}
      contact={<ContactSection />}
      loadingDetails={<LoadingDetails />}
      references={<ReferencesSection />}
    />
  );
};
