import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { normalizationCost } from 'app-wrapper/utils';

import { DetailRoutingContainerStepDTM, IFreightQuotaContentSchedulesChargesDTM } from 'monetary/models/dtm';

import {
  SectionSubTitle, Separator, TableHeader, Table, TableBody, TableName, TableRow,
  TableTitleExcludedText,
} from './PrintRFQ.styled';

interface ITableProps {
  title?: string | number
  charges: IFreightQuotaContentSchedulesChargesDTM[]
  short?: boolean
  excludedOrigin?: boolean
  excludedDestination?: boolean
}

interface ITableCharges extends IFreightQuotaContentSchedulesChargesDTM {
  service: string
  serviceMiles?: string
  perUnit: string
  qty: number
  total: string
}

const prepareData = (charges: IFreightQuotaContentSchedulesChargesDTM[]) => charges.map((item) => ({
  service: item?.chargeCode?.description || '',
  serviceMiles: DetailRoutingContainerStepDTM.fromPlain({
    numberOfUnits: item?.numberOfUnits,
    measureBy: item?.measureBy,
    totalUnits: 0,
  })?.getEnumeratorValue(),
  unitType: item.customUnitType,
  perUnit: item.subjectTo === 'INCLUDED' ? '' : `$ ${normalizationCost((item?.totalCost || 0), { toFixed: 2, thousandthSeparatorComma: true })}`,
  qty: item.subjectTo === 'INCLUDED' ? '' : item.customQuantity || item.numberOfUnits,
  total: item.subjectTo === 'INCLUDED' ? 'Included' : `$ ${normalizationCost(item?.customTotal || item?.totalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}`,
}));

const TableApplied: FC<ITableProps> = ({
  title, charges, short = false, excludedOrigin = false, excludedDestination = false,
}) => {
  const { t } = useTranslation();
  const origin = useMemo(() => (charges.length ? charges.filter((item) => item.designation === 'ORIGIN') : []), [charges, title]);
  const freight = useMemo(() => (charges.length ? charges.filter((item) => item.designation === 'FREIGHT') : []), [charges, title]);
  const designation = useMemo(() => (charges.length ? charges.filter((item) => item.designation === 'DESTINATION') : []), [charges, title]);

  const originTable = useMemo(() => prepareData(origin), [charges, title]);

  const freightTable = useMemo(() => prepareData(freight), [charges, title]);

  const designationTable = useMemo(() => prepareData(designation), [charges, title]);

  const renderHeader = useMemo(() => (short ? (
    <TableHeader>
      <div style={{ width: '60%' }}>{t('print.service')}</div>
      <div style={{ width: '20%' }}>{t('print.unitType')}</div>
      <div style={{ width: '20%', textAlign: 'right' }}>{t('print.perUnit')}</div>
    </TableHeader>
  ) : (
    <TableHeader>
      <div style={{ width: '40%' }}>{t('print.service')}</div>
      <div style={{ width: '15%' }}>{t('print.unitType')}</div>
      <div style={{ width: '15%', textAlign: 'right' }}>{t('print.perUnit')}</div>
      <div style={{ width: '15%', textAlign: 'right' }}>{t('print.qty')}</div>
      <div style={{ width: '15%', textAlign: 'right' }}>{t('print.total')}</div>
    </TableHeader>
  )), []);

  const renderBody = useCallback((data, excluded?: boolean) => data.map((item: ITableCharges) => (short ? (
    <>
      <TableRow excluded={excluded}>
        <div style={{ width: '60%', textTransform: 'capitalize' }}>{item.service}{`${item?.serviceMiles ? (
          <span style={{ textTransform: 'none' }}>
            {` ${item.serviceMiles}`}
          </span>
        ) : ''}`}
        </div>
        <div style={{ width: '20%' }}>{item.unitType}</div>
        <div style={{ width: '20%', textAlign: 'right' }}>{item.perUnit}</div>
      </TableRow>
      <Separator />
    </>
  ) : (
    <>
      <TableRow excluded={excluded}>
        <div style={{ width: '40%', textTransform: 'capitalize' }}>
          {item.service}
          <span style={{ textTransform: 'none' }}>{`${item?.serviceMiles ? item.serviceMiles : ''}`}</span>
        </div>
        <div style={{ width: '15%' }}>{item.unitType}</div>
        <div style={{ width: '15%', textAlign: 'right' }}>{item.perUnit}</div>
        <div style={{ width: '15%', textAlign: 'right' }}>{item.qty}</div>
        <div style={{ width: '15%', textAlign: 'right' }}>{item.total}</div>
      </TableRow>
      <Separator />
    </>
  ))), []);

  return (!!originTable || !!freightTable || !!designationTable) ? (
    <>
      {title && <SectionSubTitle>{title}</SectionSubTitle>}
      <Table>
        {renderHeader}
        <Separator style={{ marginTop: '4px' }} />
        <TableBody>
          {!!originTable.length && (
            <>
              <TableName>{t('print.origin')}
                {excludedOrigin
                  ? (
                    <>
                      <TableTitleExcludedText>{`${t('Shipper will be invoiced for charges occurred at Origin')}`}</TableTitleExcludedText>
                    </>
                  )
                  : ''}
              </TableName>
              {renderBody(originTable, excludedOrigin)}
            </>
          )}
          {!!freightTable.length && (
            <>
              <TableName>{t('print.freight')}</TableName>
              {renderBody(freightTable)}
            </>
          )}
          {!!designationTable.length && (
            <>
              <TableName>{t('print.destination')} {excludedDestination ? <TableTitleExcludedText>{t('Consignee will be invoiced for charges occurred at Destination')}</TableTitleExcludedText> : ''}</TableName>
              {renderBody(designationTable, excludedDestination)}
            </>
          )}
        </TableBody>
      </Table>
    </>
  ) : null;
};

export { TableApplied };
