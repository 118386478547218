import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';
import { DoubleRadioSelect } from 'app-wrapper/view/guideline';
import { LOADING_METHODS } from 'shipment-operations/constants';

export const LoadingMethod = observer(() => {
  const { t } = useTranslation();
  const { editLoadingControlsDrawer: { state } } = useMobxStore();

  return (
    <DoubleRadioSelect
      value={state.loadingControls?.loadingMethod}
      error={state.isRequiredErrorVisible && !state.loadingControls?.loadingMethod}
      options={LOADING_METHODS}
      setValue={UC.editLoadingControlsDrawer.setLoadingMethod}
      title={t('Loading Method')}
    />
  );
});
