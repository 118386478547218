import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';

import { CommandCenterTasksCompletedComponent } from './CommandCenterTasksCompleted.component';

const CommandCenterTasksCompletedContainer: FC = () => {
  const commandCenterIsHaveActiveNotification = useSelector(R.selectors.commandCenter.getCommandCenterIsHaveActiveNotification);
  const commandCenterCurrent = useSelector(R.selectors.commandCenter.getCommandCenterCurrent);

  const getCommandCenterCurrentAllTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentAllTasks);

  const getCommandCenterCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatus);
  const getCommandCenterCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatusAdd);
  const commandCenterActionCurrentIsClearAllFiltersTasksCompleted = useSelector(R.selectors.commandCenter.getCommandCenterActionCurrentIsClearAllFiltersTasksCompleted);

  return (
    <CommandCenterTasksCompletedComponent
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterTasksShowOnlyUnread()}
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStart={UC.CommandCenter.onStartTaskCompletedPage()}
      nextPageTasks={UC.CommandCenter.nextPageTasksCompleted()}
      checkAnotherDownloadNextPageTask={UC.CommandCenter.checkAnotherDownloadNextPageTask}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterCompletionDates()}
      onResetReceivedDate={UC.CommandCenter.onResetFilterCompletionDates()}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterTasksCompletionDates()}
      onApplyModule={UC.CommandCenter.onApplyTasksModule()}
      onResetModule={UC.CommandCenter.onResetTasksModule()}
      onChangeGroupModule={UC.CommandCenter.onChangeTasksGroupModule()}
      onApplyAssignee={UC.CommandCenter.onApplyTasksAssignee()}
      onResetAssignee={UC.CommandCenter.onResetTasksAssignee()}
      onChangeGroupAssignee={UC.CommandCenter.onChangeTasksGroupAssignee()}
      onChangeGroupObject={UC.CommandCenter.onChangeTasksGroupObject()}
      onApplyObject={UC.CommandCenter.onApplyTasksObject()}
      onResetObject={UC.CommandCenter.onResetTasksObject()}
      onClearAllFilters={UC.CommandCenter.onClearAllTasksFilters()}
      openTaskDrawer={UC.drawer.openTaskDrawer}
      treeDataObject={commandCenterCurrent?.filters?.filterTreeDataObject?.treeData}
      isVisibleClearAll={commandCenterActionCurrentIsClearAllFiltersTasksCompleted}
      filters={commandCenterCurrent?.filters}
      isLoad={getCommandCenterCurrentStatus}
      isLoadAdd={getCommandCenterCurrentStatusAdd}
      allTasks={getCommandCenterCurrentAllTasks}
      countTasks={commandCenterCurrent.totalElementsLater}
      isHaveNotification={commandCenterIsHaveActiveNotification}
    />
  );
};

export { CommandCenterTasksCompletedContainer as CommandCenterTasksCompleted };
