import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import Row from 'antd/es/row';

import { Col } from 'app-wrapper/view/components';

import {
  SectionButton,
  ShippingPartiesReferences,
} from '../index';
import {
  AddressLine1Input,
  AddressLine2Input,
  CityInput,
  CountryInput,
  PostalCodeInput,
  StateInput,
  FullNameInput,
  EmailInput,
  PhoneInput,
  AdditionalPhoneInput,
} from './components';
import {
  AddressSection,
  AddressSectionHeader,
  SectionTitle,
  Label,
  LabelGrey,
  Divider,
} from './AddressSection.styled';

interface IAddressSectionComponentProps {
  openContactDrawer: () => void;
  openAddressDrawer: () => void;
  hideDrawerButtons?: boolean;
  isSelectedCompanyRelatesToUser: boolean;
  isAbleToEditAddressAndContact: boolean;
}

export const AddressSectionComponent: FC<IAddressSectionComponentProps> = ({
  openAddressDrawer,
  openContactDrawer,
  hideDrawerButtons,
  isSelectedCompanyRelatesToUser,
  isAbleToEditAddressAndContact,
}) => {
  const { t } = useTranslation();

  return (
    <AddressSection>
      <AddressSectionHeader>
        <SectionTitle>
          {t('Address')}
        </SectionTitle>

        {!hideDrawerButtons && isAbleToEditAddressAndContact ? (
          <SectionButton
            disabled={!isSelectedCompanyRelatesToUser}
            onClick={openAddressDrawer}
            icon={<ContactsOutlined />}
          >
            {t('Add from Contact Book')}
          </SectionButton>
        ) : null}
      </AddressSectionHeader>

      <Col>
        <Label>
          {t('Address Line 1*')}
        </Label>
        <AddressLine1Input />
      </Col>

      <Col>
        <Label>
          {t('Address Line 2')}
        </Label>
        <AddressLine2Input />
      </Col>

      <Row gutter={[8, 16]}>
        <Col span={12}>
          <Label>{t('Country')} *</Label>
          <CountryInput />
        </Col>

        <Col span={12}>
          <Label>
            {t('State ')}

            <LabelGrey>
              {t('US Required')}
            </LabelGrey>
          </Label>
          <StateInput />
        </Col>

        <Col span={12}>
          <Label>{t('City')} *</Label>
          <CityInput />
        </Col>

        <Col span={12}>
          <Label>{t('Postal Code')} *</Label>
          <PostalCodeInput />
        </Col>
      </Row>

      <Divider dashed />

      <AddressSectionHeader>
        <SectionTitle>
          {t('Contact Person')}
        </SectionTitle>

        {!hideDrawerButtons && isAbleToEditAddressAndContact ? (
          <SectionButton
            onClick={openContactDrawer}
            icon={<ContactsOutlined />}
            disabled={!isSelectedCompanyRelatesToUser}
          >
            {t('Add from Contact Book')}
          </SectionButton>
        ) : null}
      </AddressSectionHeader>

      <Row gutter={[8, 16]}>
        <Col span={12}>
          <Label>{t('Full Name')} *</Label>
          <FullNameInput />
        </Col>
        <Col span={12}>
          <Label>{t('Email')} *</Label>
          <EmailInput />
        </Col>

        <Col span={12}>
          <Label>{t('Phone')} *</Label>
          <PhoneInput />
        </Col>
        <Col span={12}>
          <Label>{t('Additional Phone')}</Label>
          <AdditionalPhoneInput />
        </Col>
      </Row>

      <Divider dashed />

      <SectionTitle>
        {t('Additional Information')}
      </SectionTitle>

      <Col>
        <Label>{t('Reference Number')}</Label>
        <ShippingPartiesReferences />
      </Col>
    </AddressSection>
  );
};
