import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TUserEditProfileState } from 'user-management/models/states';

export const initialState: TUserEditProfileState = {
  isLoading: false,
  availableDepartments: [],
  departments: [],
};

export const userEditProfileStore = createSlice({
  name: 'userEditProfile',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setFirstNameError: (state, action: PayloadAction<Error>) => {
      state.firstNameError = action.payload;
    },
    clearFirstNameError: (state) => {
      state.firstNameError = undefined;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setLastNameError: (state, action: PayloadAction<Error>) => {
      state.lastNameError = action.payload;
    },
    clearLastNameError: (state) => {
      state.lastNameError = undefined;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clear: () => (initialState),
  },
});

export const userEditProfileActions = userEditProfileStore.actions;
export const userEditProfileReducer = userEditProfileStore.reducer;
