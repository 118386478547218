import styled from 'styled-components';
import Typography from 'antd/es/typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

export const BillOfLadingTypeSelectContainer = styled.div.attrs({
  'data-class': 'BillOfLadingTypeSelectContainer',
})`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BillOfLadingLabel = styled(Typography.Text).attrs({
  'data-class': 'BillOfLadingLabel',
})`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 8px;
  font-size: 10px;
  color: #F1AE00;
`;
