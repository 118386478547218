import { InputMobxUseCase } from 'app-wrapper/usecases/InputMobx.useCase';
import { BaseController } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';

export class SelectMobxUseCase<T extends InputStore<string | number | undefined> = InputStore<string | number | undefined>> extends InputMobxUseCase<T> {
  value: string

  storeMobx: T

  validate: () => void

  constructor(
    controller: BaseController,
    storeMobx: T,
    validate?: () => void,
  ) {
    super(controller, storeMobx, validate);
    this.storeMobx = storeMobx;
    this.validate = validate || (() => {});
  }

  onChange(value: string | number) {
    let validValue = value;
    if (typeof value === 'string') {
      validValue = value.slice(0, this.maxLength);
    }
    this.storeMobx.setValue(validValue);

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate();
  }

  onClear() {
    this.storeMobx.setValue(undefined);

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate();
  }
}
