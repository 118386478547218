import { AnyAction } from '@reduxjs/toolkit';
import notification from 'antd/es/notification';
import I18n from 'app-wrapper/i18n/i18n';
import { MobxStoresInterface } from 'app-wrapper/mobxStores';
import { RequestEntityTooLargeError, TokenExpiredError } from 'app-wrapper/models/errors';
import { BadRequestError } from 'app-wrapper/models/errors/httpErrors/BadRequestError';
import { ConflictError } from 'app-wrapper/models/errors/httpErrors/ConflictError';
import { ForbiddenError } from 'app-wrapper/models/errors/httpErrors/ForbiddenError/Forbidden.error';
import { GatewayTimeoutError } from 'app-wrapper/models/errors/httpErrors/GatewayTimeoutError';
import { InternalServerError } from 'app-wrapper/models/errors/httpErrors/InternalServerError';
import { NotificationMessageError } from 'app-wrapper/models/errors/NotificationMessageError';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { currentSession } from 'app-wrapper/utils';
import { RepositoriesInterface } from 'app-wrapper/view/repositories';
import { CanceledError } from 'axios';
import { ControllerStore } from 'proto/BaseMobxStore';
import { NavigateFunction } from 'react-router';
import { store as _store, dispatch } from 'app-wrapper/store';
import { Location } from 'history';
import {
  PreventPromiseExecutionError,
} from 'app-wrapper/models/errors/PreventPromiseExecutionError/PreventPromiseExecution.error';
import { R } from 'authentication/repository';

const handleErrors = (error: Error): void => {
  if (error instanceof TokenExpiredError) {
    currentSession.signOut();
    dispatch(R.actions.auth.logOut());
  }

  if (error instanceof BadRequestError || error instanceof ForbiddenError || error instanceof ConflictError) {
    notification.error({
      message: error.getErrorMessage(),
      placement: 'bottomRight',
      duration: 5,
    });

    return;
  }

  if (error instanceof RequestEntityTooLargeError) {
    notification.error({
      message: I18n.t('File size can’t be more than number MB.', { size: 10 }),
      placement: 'bottomRight',
      duration: 5,
    });

    return;
  }

  if (error instanceof InternalServerError || error instanceof GatewayTimeoutError) {
    notification.error({
      message: I18n.t('Something went wrong. Please try reloading the page or contact our support team at support@skypace.com'),
      placement: 'bottomRight',
      duration: 5,
    });

    console.error(error);

    return;
  }

  if (error instanceof CanceledError) {
    console.error(error);
    return;
  }

  if (error instanceof PreventPromiseExecutionError) {
    console.error(error);
    return;
  }

  if (error instanceof NotificationMessageError) {
    notification.error({
      message: error.message,
      placement: 'bottomRight',
      duration: 5,
    });

    console.error(error);

    return;
  }

  console.error(error);
};

function defineMethod(this: any, originalMethod: any) {
  let boundMethod;
  if (originalMethod.constructor.name === 'Function') {
    boundMethod = (...args: any): any => {
      try {
        return originalMethod.apply(this, args);
      } catch (e) {
        handleErrors(e as Error);
      }

      return undefined;
    };
  } else if (originalMethod.constructor.name === 'AsyncFunction') {
    boundMethod = async (...args: any) => originalMethod.apply(this, args).catch(handleErrors);
  }

  return boundMethod?.bind(this);
}

// @ts-ignore
function autobind(_target, name, descriptor) {
  const originalMethod = descriptor.value;
  return {
    configurable: true,
    get() {
      const boundMethod = defineMethod.call(this, originalMethod);
      Object.defineProperty(this, name, {
        value: boundMethod,
        configurable: true,
        writable: true,
      });
      return boundMethod;
    },
  };
}

export function controller<T extends { new(...args: any[]): {} }>(this: any, constructor: T) {
  const proto = constructor.prototype;
  const methodNames = Object.getOwnPropertyNames(proto)
    // @ts-ignore
    .filter((name) => name !== 'constructor' && typeof proto[name] === 'function');
  // @ts-ignore
  methodNames.forEach((methodName) => {
    const descriptor = Object.getOwnPropertyDescriptor(proto, methodName);
    Object.defineProperty(proto, methodName, autobind(proto, methodName, descriptor));
  });

  return class extends constructor {
    constructor(...args: any[]) {
      super(...args);
      Object.keys(this).forEach((prop) => {
        // @ts-ignore
        if (typeof this[prop] === 'function') {
          // @ts-ignore
          this[prop] = defineMethod(this[prop]);
        }
      });
    }
  };
}

export interface ControllerParams<CurrentStore extends ControllerStore<any> = ControllerStore<any>> {
  store: typeof _store
  mobxStores: MobxStoresInterface
  navigate: NavigateFunction
  params: Record<string, string | undefined>
  location: Location
  repositories: RepositoriesInterface
  currentStore?: CurrentStore
}

export class BaseController<CurrentStore extends ControllerStore<any> = ControllerStore<any>> {
  timersIds: number[];

  navigate: NavigateFunction

  store: typeof _store

  mobxStore: MobxStoresInterface

  params: Record<string, string | undefined>

  location: Location

  repositories: RepositoriesInterface

  currentStore?: CurrentStore

  constructor({
    store, mobxStores, navigate, params, location, repositories, currentStore,
  }: ControllerParams<CurrentStore>) {
    this.timersIds = [];
    this.store = store;
    this.mobxStore = mobxStores;
    this.dispatch = store.dispatch;
    this.navigate = navigate;
    this.params = { ...params };
    this.location = location;
    this.repositories = repositories;
    this.currentStore = currentStore;
  }

  dispatch(action: AnyAction) {
    return this.store.dispatch(action);
  }

  setTimeout(handler: () => void, timeout?: number): number {
    let timerId: number;
    // eslint-disable-next-line prefer-const
    timerId = window.setTimeout(() => {
      handler();
      this.removeTimerId(timerId);
    }, timeout);
    this.addTimerId(timerId);
    return timerId;
  }

  addTimerId(id: number) {
    this.timersIds.push(id);
  }

  removeTimerId(id?: number) {
    if (!id) {
      return;
    }
    const timerIdIndex = this.timersIds.findIndex((timerId) => id === timerId);
    window.clearTimeout(this.timersIds[timerIdIndex]);
    this.timersIds.splice(timerIdIndex, 1);
  }

  onLoadedPage() {}

  onClosePage() {
    apiWorker.abortAllRequests();
    this.timersIds.forEach((id) => clearTimeout(id));
    this.timersIds = [];
  }
}
