import React, { FC, useContext, useMemo } from 'react';

import 'typeface-roboto';

import { Routes } from 'app-wrapper/view/routes';

import {
  createAdditionalServicesDrawerStore,
} from 'monetary/repository/mobxStores/AdditionalServicesDrawer';

import './fonts.css';
import { initUC as initAuthenticationUC } from 'authentication/controllers';
import { useLocation, useNavigate, useParams } from 'react-router';
import { initUC as initMonetaryUC } from 'monetary/controllers';
import { initUC as initShipmentOperationsUC } from 'shipment-operations/controllers';
import { initUC as initUserManagementUC } from 'user-management/controllers';
import { initUC as initAppWrapperUC } from 'app-wrapper/controllers';
import { ReactReduxContext } from 'react-redux';
import {
  createUserEditProfileStore,
} from 'user-management/repository/mobxStores/UserEditProfile';
import { createCustomerSalesStore } from 'user-management/repository/mobxStores/CustomerSales';
import {
  createAdditionalServicesStore,
  createShipmentContainersStore,
  createShippingPartiesStore,
  createShipmentSchedulesStore,
  createEditLoadingControlsDrawerStore,
  createEditSupplierScheduleStore,
  createCompaniesStore,
  createCreatedCompanyStore,
} from 'shipment-operations/repository/mobxStores';
import { MobxStoresContext, MobxStoresInterface } from 'app-wrapper/mobxStores';
import { ControllerParams } from 'proto/BaseController';
import { createDrawerStore } from 'app-wrapper/repository/mobxStores/DrawerStore';
import { createContractsStore } from 'app-wrapper/repository/mobxStores/Contracts';
import {
  createOverviewShipmentStore,
} from 'app-wrapper/repository/mobxStores/OverviewShipmentStore';
import {
  createShipmentCostChangesDrawerStore,
} from 'app-wrapper/repository/mobxStores/ShipmentCostChangesDrawerStore';
import { createISFDrawerStore } from 'app-wrapper/repository/mobxStores/ISFDrawerStore/ISFDrawerStore.store';
import { RepositoriesContext, RepositoriesInterface } from 'app-wrapper/view/repositories';
import { OrganizationRepository } from 'user-management/repositories/OrganizationRepository';
import { ShipmentPermissionsRepository } from 'shipment-operations/repositories/ShipmentPermissionsRepository';
import { createAllRequestsStore } from 'monetary/repository/mobxStores/AllRequestsStore';
import {
  createShipmentLoadingControlsStore,
} from 'shipment-operations/repository/mobxStores/ShipmentLoadingControlsStore';
import {
  createShipmentPaymentTermsStore,
} from 'shipment-operations/repository/mobxStores/ShipmentPaymentTerms';
import { AppWrapper } from './App.styled';

export const App: FC = () => {
  const { store } = useContext(ReactReduxContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const mobxStores = useMemo<MobxStoresInterface>(() => ({
    overviewDepartingShipmentStore: createOverviewShipmentStore(),
    overviewArrivingShipmentStore: createOverviewShipmentStore(),
    overviewPendingConfirmationsShipmentStore: createOverviewShipmentStore(),
    userEditProfileStore: createUserEditProfileStore(),
    additionalServicesDrawerStore: createAdditionalServicesDrawerStore(),
    drawerStore: createDrawerStore(),
    shipmentServices: createAdditionalServicesStore(),
    customerSales: createCustomerSalesStore(),
    contracts: createContractsStore(),
    shipmentCostChangesDrawer: createShipmentCostChangesDrawerStore(),
    allRequestsStore: createAllRequestsStore(),
    shipmentLoadingControlsStore: createShipmentLoadingControlsStore(),
    shipmentContainersStore: createShipmentContainersStore(),
    shipmentSchedulesStore: createShipmentSchedulesStore(),
    shippingPartiesStore: createShippingPartiesStore(),
    editLoadingControlsDrawer: createEditLoadingControlsDrawerStore(),
    editSupplierScheduleStore: createEditSupplierScheduleStore(),
    isfDrawer: createISFDrawerStore(),
    shipmentPaymentTermsStore: createShipmentPaymentTermsStore(),
    companies: createCompaniesStore(),
    createdCompany: createCreatedCompanyStore(),
  }), []);

  const repositories = useMemo<RepositoriesInterface>(() => ({
    organizationRepository: new OrganizationRepository(store),
    shipmentPermissionsRepository: new ShipmentPermissionsRepository(store),
  }), []);

  const controllerParams: ControllerParams = {
    store, mobxStores, navigate, params, location, repositories,
  };

  initAppWrapperUC(controllerParams);
  initAuthenticationUC(controllerParams);
  initMonetaryUC(controllerParams);
  initShipmentOperationsUC(controllerParams);
  initUserManagementUC(controllerParams);
  return (
    <MobxStoresContext.Provider value={mobxStores}>
      <RepositoriesContext.Provider value={repositories}>
        <AppWrapper>
          <Routes />
        </AppWrapper>
      </RepositoriesContext.Provider>
    </MobxStoresContext.Provider>
  );
};
