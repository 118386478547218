import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CargoDTM, ContainerWithCargoDTM, TemperatureControlDTM } from 'shipment-operations/models/dtm';
import { AirVentUnitsNamesConst, ContainerIsReeferType } from 'shipment-operations/constants';
import { IncompleteStub } from 'shipment-operations/view/components';
import { RouteNames } from 'app-wrapper/constants';

import {
  SBLCargosInfoDivider,
  SBLCollapseCargosInfo,
  SBLCollapseContainerInfo,
  SBLContainerInfoSectionText,
  SBLContainerInfoSectionTitle,
  SBLInfoSectionDivider,
  SBLTableRowCollapse,
} from './SBLTableRowCollapse.styled';
import { SBLGoodsTableComponent, SBLHazmatTableComponent } from '../../../index';

interface ISBLTableRowCollapseComponentProps {
  container: ContainerWithCargoDTM;
  temperatureControl: TemperatureControlDTM;
  isAbleToEdit: boolean;
}

const SBLTableRowCollapseComponent: FC<ISBLTableRowCollapseComponentProps> = ({
  container,
  temperatureControl,
  isAbleToEdit,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();

  const goodsTableCargos = useMemo(() => container.cargoItems.map(({
    cargo,
    packagesNumber,
    volume,
    weight,
    value,
  }) => (CargoDTM.fromPlain({
    ...cargo,
    packagesNumber: String(packagesNumber || 0),
    volume: String(volume || 0),
    weight: String(weight || 0),
    value: String(value || 0),
  }))), [container.cargoItems]);

  const hazmatTableCargos = useMemo(() => goodsTableCargos.filter(({ isHazmat }) => isHazmat), [goodsTableCargos]);

  const containerRedirectURL = useMemo(() => RouteNames.SHIPMENT_CONTAINER(shipmentId, container.id), [shipmentId, container]);

  const hasReefer = useMemo(() => ContainerIsReeferType(container.type), [container.type]);

  return (
    <SBLTableRowCollapse>
      <SBLCollapseContainerInfo>
        <SBLContainerInfoSectionTitle>
          {t('Seal')}
        </SBLContainerInfoSectionTitle>

        <SBLContainerInfoSectionText>
          {t('Seal Number:')}
          <SBLInfoSectionDivider />
          {container.sealNumber || <IncompleteStub redirectURL={containerRedirectURL} hideNavigateIcon={!isAbleToEdit} />}
        </SBLContainerInfoSectionText>

        {temperatureControl.temperature || (hasReefer && !temperatureControl.temperature) ? (
          <>
            <SBLContainerInfoSectionTitle>
              {t('Temperature Control')}
            </SBLContainerInfoSectionTitle>

            <SBLContainerInfoSectionText>
              {t('Temperature')}:
              <SBLInfoSectionDivider />
              {temperatureControl.temperature ? `${temperatureControl.temperature} °C` : (
                <IncompleteStub redirectURL={containerRedirectURL} hideNavigateIcon={!isAbleToEdit} />
              )}
            </SBLContainerInfoSectionText>
            <SBLContainerInfoSectionText>
              {t('Air Vent Settings')}: {temperatureControl.airVent.flowRate} {temperatureControl.airVent.flowRateUnit ? AirVentUnitsNamesConst[temperatureControl.airVent.flowRateUnit] : ''}
            </SBLContainerInfoSectionText>
            <SBLContainerInfoSectionText>
              {t('Genset')}: {temperatureControl.genset ? t('Yes') : t('No')}
            </SBLContainerInfoSectionText>
          </>
        ) : null}
      </SBLCollapseContainerInfo>

      <SBLCollapseCargosInfo>
        <SBLGoodsTableComponent
          cargos={goodsTableCargos}
          isNested
          redirectURL={containerRedirectURL}
          hideNoDataAddButton={!isAbleToEdit}
        />
        <SBLCargosInfoDivider />
        <SBLHazmatTableComponent
          isNested
          cargos={hazmatTableCargos}
        />
      </SBLCollapseCargosInfo>
    </SBLTableRowCollapse>
  );
};

export { SBLTableRowCollapseComponent as SBLTableRowCollapse };
