import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PendingSubmissionContentComponent } from './PendingSubmissionContent.component';

export const PendingSubmissionContent = React.memo(() => {
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsFlowStepLoading);
  const isAbleToSubmitSI = useSelector(R.selectors.billOfLadingCommon.getIsAbleToSubmitSI);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const isShippingPartiesDataFilled = useSelector(R.selectors.billOfLadingCommon.getIsShippingPartyDataFilled);
  const isCargosDataFilled = useSelector(R.selectors.billOfLadingCommon.getIsCargosDataFilled);
  const isContainersFilled = useSelector(R.selectors.billOfLadingCommon.getIsContainersDataFilled);
  const isBLReleaseTypeFilled = useSelector(R.selectors.billOfLadingCommon.getIsBLReleaseTypeDataFilled);
  const isEntryNumberFilled = useSelector(R.selectors.billOfLadingCommon.getIsEntryNumberDataFilled);
  const isOriginUS = useSelector(R.selectors.billOfLadingCommon.getIsOriginUS);
  const isCargoLoadPlanFilled = useSelector(R.selectors.billOfLadingCommon.getIsCargoLoadPlanFilled);
  const isHazmatDataFilled = useSelector(R.selectors.billOfLadingCommon.getIsHazmatDataFilled);

  return (
    <PendingSubmissionContentComponent
      isLoading={isLoading}
      isShippingPartiesDataFilled={isShippingPartiesDataFilled}
      isCargosDataFilled={isCargosDataFilled}
      isContainersFilled={isContainersFilled}
      isBLReleaseTypeFilled={isBLReleaseTypeFilled}
      isEntryNumberFilled={isEntryNumberFilled}
      isOriginUS={isOriginUS}
      submitSIAndApprove={UC.billOfLading.approveDraftHBL}
      isAbleToSubmitSI={isAbleToSubmitSI}
      permissionsSI={permissions.editSISections}
      isCargoLoadPlanFilled={isCargoLoadPlanFilled}
      isHazmatDataFilled={isHazmatDataFilled}
      submitSIPermissions={permissions?.isAbleToSubmitSI}
    />
  );
});
