import styled from 'styled-components';

import { BodyTextMedium } from 'app-wrapper/view/fonts';
import { FlexCol } from 'app-wrapper/view/guideline';
import { StyledTable } from 'app-wrapper/view/guideline/ContainersChargesChangesTable/ContainersChargesChangesTable.styled';
import { DesignationHeader } from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components/CostChangesTable/CostChangesTable.styled';

export const Wrap = styled(FlexCol)`
  padding: 16px 16px 0 16px;
`;

export const Title = styled.span`
  margin-bottom: 8px;
  ${BodyTextMedium}
`;

export const HeaderWrap = styled.div`
  margin-bottom: 16px;
`;

export const HeaderTable = styled(StyledTable)`
  .ant-table-tbody {
    display: none;
  }
`;

export const DesignationHeaderStyled = styled(DesignationHeader)`
  margin-bottom: 0;
`;
