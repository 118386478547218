import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentListFiltersComponent } from './ShipmentListFilters.component';

const ShipmentListFilters: FC = () => {
  const shipmentFilterLocation = useSelector(R.selectors.shipmentList.geShipmentFilterLocation);
  const shipmentFilterShippingParty = useSelector(R.selectors.shipmentList.geShipmentFilterShippingParty);
  const shipmentFilterShippingCarrier = useSelector(R.selectors.shipmentList.geShipmentFilterShippingCarrier);
  const shipmentFilterContainerType = useSelector(R.selectors.shipmentList.geShipmentFilterContainerType);
  const shipmentFilterStatus = useSelector(R.selectors.shipmentList.geShipmentFilterStatus);
  const shipmentFilterSortBy = useSelector(R.selectors.shipmentList.geShipmentFilterSortBy);
  const searchValue = useSelector(R.selectors.shipmentList.getSearchValue);

  return (
    <ShipmentListFiltersComponent
      onApplyFilterLocation={UC.shipmentList.onApplyFilterLocation}
      onChangeFilterLocation={UC.shipmentList.onChangeFilterLocation}
      onResetFilterLocation={UC.shipmentList.onResetFilterLocation}
      onApplyFilterShippingParty={UC.shipmentList.onApplyFilterShippingParty}
      onChangeFilterShippingParty={UC.shipmentList.onChangeFilterShippingParty}
      onResetFilterShippingParty={UC.shipmentList.onResetFilterShippingParty}
      onApplyFilterShippingCarrier={UC.shipmentList.onApplyFilterShippingCarrier}
      onChangeFilterShippingCarrier={UC.shipmentList.onChangeFilterShippingCarrier}
      onResetFilterShippingCarrier={UC.shipmentList.onResetFilterShippingCarrier}
      onApplyFilterContainerType={UC.shipmentList.onApplyFilterContainerType}
      onChangeFilterContainerType={UC.shipmentList.onChangeFilterContainerType}
      onResetFilterContainerType={UC.shipmentList.onResetFilterContainerType}
      onApplyFilterStatus={UC.shipmentList.onApplyFilterStatus}
      onChangeFilterStatus={UC.shipmentList.onChangeFilterStatus}
      onResetFilterStatus={UC.shipmentList.onResetFilterStatus}
      onChangeFilterSortBy={UC.shipmentList.onChangeFilterSortBy}
      onApplyFilterSortBy={UC.shipmentList.onApplyFilterSortBy}
      onSearch={UC.shipmentList.onSearch}
      filterLocation={shipmentFilterLocation?.values}
      filterShippingParty={shipmentFilterShippingParty?.values}
      filterShippingCarrier={shipmentFilterShippingCarrier?.values}
      filterContainerType={shipmentFilterContainerType?.values}
      filterStatus={shipmentFilterStatus?.values}
      filterSortBy={shipmentFilterSortBy?.values}
      searchValue={searchValue}
      clearAllFilters={UC.shipmentList.clearAllFilters}
    />
  );
};

const ShipmentListFiltersCached = memo(ShipmentListFilters);

export { ShipmentListFiltersCached as ShipmentListFilters };
