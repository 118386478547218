import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const Description = styled.div`
  ${divFlex}
  width: 100%;

  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding2};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const Message = styled.div`
  border: 1px solid #EAEEF8;
  border-radius: 3px;
  padding: 16px;
  background-color: white;
  margin-bottom: 8px;
`;
