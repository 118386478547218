import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { ShipmentBillingInvoiceDtm } from 'shipment-operations/models/dtm';
import {
  US_BANK_INFORMATION,
  UK_BANK_INFORMATION,
  PL_BANK_INFORMATION,
} from 'shipment-operations/constants';
import { CreditNotes, Payment } from 'shipment-operations/view/pages/ShipmentBillingInvoice/components/Footer/components';

import {
  Wrapper,
  BilledWrapper,
  PaymentDetailsWrapper,
  Title,
  PaymentDataWrapper,
  PaymentLabel,
  PaymentData,
  PaymentValue,
  CopyIcon,
  FooterDialog,
  StyledInvoice,
  Row,
  BoldValue,
  SubTitle,
  SubTitleValue,
} from './Footer.styled';

interface IFooterProps {
  invoice?: ShipmentBillingInvoiceDtm;
  isVATVisible: boolean;
  isAdminFromUS: boolean;
  shouldShowPLBankInfo: boolean;
}

const FooterComponent: FC<IFooterProps> = ({
  invoice,
  isVATVisible,
  isAdminFromUS,
  shouldShowPLBankInfo,
}) => {
  const { t } = useTranslation();

  const onCopyAccountNumber = useCallback(() => navigator.clipboard.writeText(isAdminFromUS ? US_BANK_INFORMATION.accountNumber : UK_BANK_INFORMATION.accountNumber), [isAdminFromUS]);
  const onCopyAccountRouting = useCallback(() => navigator.clipboard.writeText(isAdminFromUS ? US_BANK_INFORMATION.routingNumber : UK_BANK_INFORMATION.routingNumber), [isAdminFromUS]);
  const onCopyBeneficiaryBank = useCallback(() => navigator.clipboard.writeText(isAdminFromUS ? US_BANK_INFORMATION.beneficiaryBank : UK_BANK_INFORMATION.beneficiaryBank), [isAdminFromUS]);

  const onCopyBankName = useCallback(() => navigator.clipboard.writeText(PL_BANK_INFORMATION.bankName), [shouldShowPLBankInfo]);
  const onCopyIBAN = useCallback(() => navigator.clipboard.writeText(PL_BANK_INFORMATION.iban), [shouldShowPLBankInfo]);
  const onCopyAccountHolder = useCallback(() => navigator.clipboard.writeText(PL_BANK_INFORMATION.accountHolder), [shouldShowPLBankInfo]);
  const onCopyAccountCurrency = useCallback(() => navigator.clipboard.writeText(PL_BANK_INFORMATION.currency), [shouldShowPLBankInfo]);

  const onCopyInvoice = useCallback(() => {
    if (invoice?.number) {
      return navigator.clipboard.writeText(invoice.number);
    }
    return null;
  }, [invoice?.number]);

  return (
    <Wrapper>
      <PaymentDetailsWrapper>
        <Title>{t('paymentDetails')}</Title>

        {shouldShowPLBankInfo ? (
          <PaymentDataWrapper>
            <PaymentData>
              <PaymentLabel>{t('Account Holder')}</PaymentLabel>
              <div>
                <PaymentValue>
                  {PL_BANK_INFORMATION.accountHolder}
                </PaymentValue>
                <CopyIcon onClick={onCopyAccountHolder} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('Bank Name')}</PaymentLabel>
              <div>
                <PaymentValue>
                  {PL_BANK_INFORMATION.bankName}
                </PaymentValue>
                <CopyIcon onClick={onCopyBankName} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('IBAN')}</PaymentLabel>
              <div>
                <PaymentValue>
                  {PL_BANK_INFORMATION.iban}
                </PaymentValue>
                <CopyIcon onClick={onCopyIBAN} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('SWIFT/BIC')}</PaymentLabel>
              <div>
                <PaymentValue>
                  {PL_BANK_INFORMATION.swiftOrBIC}
                </PaymentValue>
                <CopyIcon onClick={onCopyIBAN} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('Currency')}</PaymentLabel>
              <div>
                <PaymentValue>
                  {PL_BANK_INFORMATION.currency}
                </PaymentValue>
                <CopyIcon onClick={onCopyAccountCurrency} />
              </div>
            </PaymentData>
          </PaymentDataWrapper>
        ) : (
          <PaymentDataWrapper>
            <PaymentData>
              <PaymentLabel>{t('accountNumber')}</PaymentLabel>
              <div>
                <PaymentValue>
                  {isAdminFromUS ? US_BANK_INFORMATION.accountNumber : UK_BANK_INFORMATION.accountNumber}
                </PaymentValue>
                <CopyIcon onClick={onCopyAccountNumber} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('Beneficiary Bank')}</PaymentLabel>
              <div>
                <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.beneficiaryBank : UK_BANK_INFORMATION.beneficiaryBank}</PaymentValue>
                <CopyIcon onClick={onCopyBeneficiaryBank} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('routingNumber')}</PaymentLabel>
              <div>
                <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.routingNumber : UK_BANK_INFORMATION.routingNumber}</PaymentValue>
                <CopyIcon onClick={onCopyAccountRouting} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('SWIFT/BIC')}</PaymentLabel>
              <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.swiftOrBIC : UK_BANK_INFORMATION.swiftOrBIC}</PaymentValue>
            </PaymentData>
          </PaymentDataWrapper>
        )}

        <Divider dashed style={{ margin: '12px 0' }} />
        <FooterDialog>{t('footerDialogFirst')}
          <StyledInvoice>{invoice?.number}</StyledInvoice>
          <CopyIcon onClick={onCopyInvoice} />
          {t('footerDialogSecond')}
        </FooterDialog>
      </PaymentDetailsWrapper>
      <BilledWrapper>
        <Row>
          <Title>{t('totalFooter')}</Title>
          <BoldValue>$ {invoice?.billed.toFixed(2)}</BoldValue>
        </Row>

        {isVATVisible ? (
          <Row style={{ marginTop: 8 }}>
            <SubTitle>{t('Including VAT (0%)')}</SubTitle>
            <SubTitleValue>$ 0.00</SubTitleValue>
          </Row>
        ) : null}

        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('adjustedFooter')}</Title>
          <BoldValue>($ {invoice?.adjusted.toFixed(2)})</BoldValue>
        </Row>
        <CreditNotes />
        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('paid')}</Title>
          <BoldValue>($ {invoice?.paid.toFixed(2)})</BoldValue>
        </Row>
        <Payment />
        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('footerBalance')}</Title>
          <BoldValue>$ {invoice?.balance.toFixed(2)}</BoldValue>
        </Row>
      </BilledWrapper>
    </Wrapper>
  );
};

export { FooterComponent };
