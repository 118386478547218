import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from 'app-wrapper/view/themes/themesColors';

import {
  CargoReceiver,
  CargoSupplier,
} from './components';
import {
  StepContent,
  Toggle,
  ToggleOption,
} from './PickupDeliveryStep.styled';

export enum EPickupDeliveryTabs {
  CARGO_SUPPLIER = 'CARGO_SUPPLIER',
  CARGO_RECEIVER = 'CARGO_RECEIVER',
}

const TOGGLED_STYLES = {
  border: `1px solid ${colors.purple60}`,
  color: colors.gray80,
};
export const TOGGLED_ERROR_STYLES = {
  border: `1px solid ${colors.red60}`,
  color: colors.gray80,
};

interface IPickupDeliveryStepComponentProps {
  isCargoSupplierErrorVisible: boolean;
  isCargoReceiverErrorVisible: boolean;
  hasCargoSupplier: boolean;
  hasCargoReceiver: boolean;
  validateSuppliers: () => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const PickupDeliveryStepComponent: FC<IPickupDeliveryStepComponentProps> = ({
  isCargoReceiverErrorVisible,
  isCargoSupplierErrorVisible,
  hasCargoSupplier,
  hasCargoReceiver,
  validateSuppliers,
  setActiveTab,
  activeTab,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (hasCargoSupplier && !hasCargoReceiver) {
      setActiveTab(EPickupDeliveryTabs.CARGO_SUPPLIER);
    } else if (hasCargoReceiver && !hasCargoSupplier) {
      setActiveTab(EPickupDeliveryTabs.CARGO_RECEIVER);
    } else if (hasCargoSupplier && hasCargoReceiver) {
      setActiveTab(EPickupDeliveryTabs.CARGO_SUPPLIER);
    }
  }, []);

  const handleToggle = useCallback((section: string) => {
    if (isCargoSupplierErrorVisible || isCargoReceiverErrorVisible) {
      validateSuppliers();
    }

    setActiveTab(section);
  }, [setActiveTab, validateSuppliers, isCargoSupplierErrorVisible, isCargoReceiverErrorVisible]);

  return (
    <StepContent>
      {hasCargoReceiver && hasCargoSupplier ? (
        <Toggle>
          <ToggleOption
            style={activeTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? TOGGLED_STYLES : {
              ...(isCargoSupplierErrorVisible ? TOGGLED_ERROR_STYLES : {}),
            }}
            onClick={() => handleToggle(EPickupDeliveryTabs.CARGO_SUPPLIER)}
          >
            {t('Cargo Supplier')}
          </ToggleOption>

          <ToggleOption
            style={activeTab === EPickupDeliveryTabs.CARGO_RECEIVER ? TOGGLED_STYLES : {
              ...(isCargoReceiverErrorVisible ? TOGGLED_ERROR_STYLES : {}),
            }}
            onClick={() => handleToggle(EPickupDeliveryTabs.CARGO_RECEIVER)}
          >
            {t('Cargo Receiver')}
          </ToggleOption>
        </Toggle>
      ) : null}

      {activeTab === EPickupDeliveryTabs.CARGO_SUPPLIER ? <CargoSupplier /> : <CargoReceiver />}
    </StepContent>
  );
};
