import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { EDrayageLoadingSide } from 'shipment-operations/constants';
import { v4 as uuidv4 } from 'uuid';

import { IBookingWizardState } from 'shipment-operations/models/states';
import {
  ShipmentPreviewDTM,
  CargoDTM,
  CargoBaseDTM,
  CargoErrorsDTM,
  ContainerDTM,
  BookingWizardContainersErrorsDTM,
  ContainerDocumentDTM,
  CompanyDTM,
  CompanyAddressDTM,
  CompanyContactDTM,
  ContactBookDefaultStateCompanyListStateDTM,
  ShipmentReferenceDTM,
  ShippingPartyDTM,
  LoadingControlDTM,
  ShipmentScheduleDTM,
  PaymentTermsDTM,
} from 'shipment-operations/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';
import { FreightQuotaContentSchedulesDTM, FreightQuotaContentDTM } from 'monetary/models/dtm';
import { ValidationErrorDTM } from 'app-wrapper/types';
import { EShipmentOrganizationRole } from 'user-management/constants';
import {
  ISetUpdateAddCompanyListAddressesById, ISetUpdateAddCompanyListContactPersonsById, ISetUpdateAddCompanyListStateAddressByIndexDTM, ISetUpdateAddCompanyListStateByIndexDTM, ISetUpdateAddCompanyListStateContactPersonByIndexDTM, ISetUpdateCompanyListIsUpdateByIdByIndexDTM, ISetUpdateCompanyListStateByIdDTM, ISetUpdateErrorsByIdDTM, ISetUpdateRemoveCompanyListStateAddressByIndexDTM, OrganizationDTM,
} from 'user-management/models/dtm';
import { REQUEST_STATUS } from 'app-wrapper/constants';
import { WizardContactBookInitialState } from './WizardContactBook.initState';

type ChangeHsCodeAction = PayloadAction<Pick<CargoDTM, 'code' | 'name'> & {
  errorsCode: CargoErrorsDTM['code']
  errorsName: CargoErrorsDTM['name']
  cargoId: number
}>
type ChangeValidatedFieldAction<T extends keyof Omit<CargoErrorsDTM, 'hasErrors'>> = PayloadAction<
  Pick<CargoDTM, T> & { error?: CargoErrorsDTM[T]; cargoId: number }
>;

const initialState: IBookingWizardState = {
  isLoading: true,
  isSelfService: false,
  incotermsTrade: '',
  isContentUpdating: false,
  wasInformationSavedOnce: false,
  totalCost: '0',
  nameSCAC: '0',
  containersAmount: 0,
  step: 1,
  shipmentId: '',
  shipmentData: null,
  paymentTerms: null,
  cargos: [],
  cargoSupplier: null,
  cargoReceiver: null,
  createdCargosIds: [],
  cargosToDeleteIds: [],
  toggledHazmatCargoIds: [],
  currentOrganization: null,
  hasTemperatureControl: false,
  hasSOC: false,
  emptyReleaseDateError: '',
  shouldHaveHazmats: false,
  isHazmatErrorVisible: false,
  currentQuota: null,
  schedules: [],
  initialQuotaRequestId: 0,
  initialQuotaId: 0,
  initialScheduleId: 0,
  currentQuotaRequestId: undefined,
  currentSchedule: null,
  durationDays: '0',
  defaultCargo: CargoBaseDTM.createEmpty(),
  emptyReleaseDate: null,
  containers: [],
  containersErrors: [],
  companiesList: [],
  selectedCompany: null,
  companyError: false,
  isNRAChecked: false,
  isHaveAccountLimit: false,
  isShowAccountLimit: false,
  isTermsAndConditionsChecked: false,
  shipmentPartyReferenceError: false,
  shipmentConsigneeReference: undefined,
  shipmentConsigneeReferenceError: false,
  wizardContactBook: WizardContactBookInitialState,
  activeQuoteCharges: [],
  selectedConsigneeCompany: null,
  consigneeCompanyError: false,
  isCargoSupplierRequiredErrorVisible: false,
  isCargoReceiverRequiredErrorVisible: false,
  shipmentRoles: [],
  isCreateCompanyAddressBlocked: false,
  suppliersStepActiveTab: '',
  supplierLoadingControls: null,
  receiverLoadingControls: null,
  supplierPhoneErrorMessage: undefined,
  supplierAdditionalPhoneErrorMessage: undefined,
  receiverPhoneErrorMessage: undefined,
  receiverAdditionalPhoneErrorMessage: undefined,
  chosenContactId: null,
  isSavingAddress: false,
  exportSchedule: ShipmentScheduleDTM.createEmpty(EDrayageLoadingSide.ORIGIN_DRAYAGE),
  importSchedule: ShipmentScheduleDTM.createEmpty(EDrayageLoadingSide.DESTITATION_DRAYAGE),
  isScheduleStepRequiredErrorVisible: false,
  shouldChangeQuotaDueToContainer: false,
  notSavedContainers: [],
};

export const bookingWizardSlice = createSlice({
  name: 'bookingWizardSlice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setNewBookPartiesId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      newBookPartiesId: payload,
    }),
    setNewBookPartiesConsigneeId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      newBookPartiesConsigneeId: payload,
    }),
    setIsContentUpdating: (state, { payload }) => ({
      ...state,
      isContentUpdating: payload,
    }),
    setIsNRAChecked: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isNRAChecked: payload,
    }),
    setSuppliersStepActiveTab: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      suppliersStepActiveTab: payload,
    }),
    setIsHaveAccountLimit: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isHaveAccountLimit: payload,
    }),
    setIsCreateCompanyAddressBlocked: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCreateCompanyAddressBlocked: payload,
    }),
    setIsShowAccountLimit: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isShowAccountLimit: payload,
    }),
    setExportSchedule: (state, { payload }: PayloadAction<ShipmentScheduleDTM>) => ({
      ...state,
      exportSchedule: payload,
    }),
    setImportSchedule: (state, { payload }: PayloadAction<ShipmentScheduleDTM>) => ({
      ...state,
      importSchedule: payload,
    }),
    setPaymentTerms: (state, { payload }: PayloadAction<PaymentTermsDTM | null>) => ({
      ...state,
      paymentTerms: payload,
    }),
    setCurrentQuotaRequestId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      currentQuotaRequestId: payload,
    }),
    setNotSavedContainers: (state, { payload }: PayloadAction<ContainerDTM[]>) => ({
      ...state,
      notSavedContainers: payload,
    }),
    addNotSavedContainer: (state, { payload }: PayloadAction<ContainerDTM>) => ({
      ...state,
      notSavedContainers: [
        ...state.notSavedContainers,
        ContainerDTM.fromPlain({
          ...payload,
          id: uuidv4(),
        }),
      ],
    }),
    setSchedules: (state, { payload }: PayloadAction<FreightQuotaContentSchedulesDTM[]>) => ({
      ...state,
      schedules: payload,
    }),
    setSupplierPhoneErrorMessage: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      supplierPhoneErrorMessage: payload,
    }),
    setChosenContactId: (state, { payload }: PayloadAction<number | null>) => ({
      ...state,
      chosenContactId: payload,
    }),
    setSupplierAdditionalPhoneErrorMessage: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      supplierAdditionalPhoneErrorMessage: payload,
    }),
    setReceiverPhoneErrorMessage: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      receiverPhoneErrorMessage: payload,
    }),
    setInitialQuotaRequestId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      initialQuotaRequestId: payload,
    }),
    setInitialQuotaId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      initialQuotaId: payload,
    }),
    setInitialScheduleId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      initialScheduleId: payload,
    }),
    setReceiverAdditionalPhoneErrorMessage: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      receiverAdditionalPhoneErrorMessage: payload,
    }),
    setIsTermsAndConditionsChecked: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTermsAndConditionsChecked: payload,
    }),
    setStep: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      step: payload,
    }),
    setIsSelfService: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSelfService: payload,
    }),
    setCurrentOrganization: (state, { payload }: PayloadAction<OrganizationDTM | null>) => ({
      ...state,
      currentOrganization: payload,
    }),
    setIsSavingAddress: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSavingAddress: payload,
    }),
    setIncotermsTrade: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      incotermsTrade: payload,
    }),
    setIsScheduleStepRequiredErrorVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isScheduleStepRequiredErrorVisible: payload,
    }),
    setShipmentId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      shipmentId: payload,
    }),
    setSupplierLoadingControls: (state, { payload }: PayloadAction<LoadingControlDTM | null>) => ({
      ...state,
      supplierLoadingControls: payload,
    }),
    setCurrentSchedule: (state, { payload }: PayloadAction<FreightQuotaContentSchedulesDTM | null>) => ({
      ...state,
      currentSchedule: payload,
    }),
    setCurrentQuota: (state, { payload }: PayloadAction<FreightQuotaContentDTM | null>) => ({
      ...state,
      currentQuota: payload,
    }),
    setReceiverLoadingControls: (state, { payload }: PayloadAction<LoadingControlDTM | null>) => ({
      ...state,
      receiverLoadingControls: payload,
    }),
    setContainersAmount: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      containersAmount: payload,
    }),
    setShipmentRoles: (state, { payload }: PayloadAction<EShipmentOrganizationRole[]>) => ({
      ...state,
      shipmentRoles: payload,
    }),
    setDurationDays: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      durationDays: payload,
    }),
    setTotalCost: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      totalCost: payload,
    }),
    setNameSCAC: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      nameSCAC: payload,
    }),
    setCompaniesList: (state, { payload }: PayloadAction<CompanyDTM[]>) => ({
      ...state,
      companiesList: payload,
    }),
    setCargoReceiver: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      cargoReceiver: payload,
    }),
    setCargoSupplier: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      cargoSupplier: payload,
    }),
    setSelectedCompany: (state, { payload }: PayloadAction<CompanyDTM | null>) => ({
      ...state,
      selectedCompany: payload,
    }),
    setIsCargoSupplierRequiredErrorVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCargoSupplierRequiredErrorVisible: payload,
    }),
    setIsCargoReceiverRequiredErrorVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCargoReceiverRequiredErrorVisible: payload,
    }),
    setShouldChangeQuotaDueToContainer: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldChangeQuotaDueToContainer: payload,
    }),
    setCompanyError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      companyError: payload,
    }),
    setSelectedConsigneeCompany: (state, { payload }: PayloadAction<CompanyDTM | null>) => ({
      ...state,
      selectedConsigneeCompany: payload,
    }),
    setConsigneeCompanyError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      сonsigneeСompanyError: payload,
    }),
    setShipmentReference: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      shipmentReference: payload,
    }),
    setShipmentReferenceData: (state, { payload }: PayloadAction<ShipmentReferenceDTM | undefined>) => ({
      ...state,
      shipmentReferenceData: payload,
    }),
    setShipmentReferenceError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shipmentReferenceError: payload,
    }),
    setShipmentPartyReference: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      shipmentPartyReference: payload,
    }),
    setShipmentPartyReferenceError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shipmentReferencePartyError: payload,
    }),
    setShipmentConsigneeReference: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      shipmentConsigneeReference: payload,
    }),
    setShipmentConsigneeReferenceError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shipmentConsigneeReferenceError: payload,
    }),
    setWasInformationSavedOnce: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      wasInformationSavedOnce: payload,
    }),
    setEmptyReleaseDateError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      emptyReleaseDateError: payload,
    }),
    addCreatedCargosId: (state, { payload }: PayloadAction<string>) => {
      state.createdCargosIds = [
        ...state.createdCargosIds,
        payload,
      ];
    },
    addCargosToRemoveId: (state, { payload }: PayloadAction<string>) => {
      state.cargosToDeleteIds = [
        ...state.cargosToDeleteIds,
        payload,
      ];
    },
    setEmptyReleaseDate: (state, { payload }: PayloadAction<DateDtm | null>) => ({
      ...state,
      emptyReleaseDate: payload,
    }),
    clearCargosToRemoveId: (state) => ({
      ...state,
      cargosToDeleteIds: [],
    }),
    setShipmentData: (state, { payload }: PayloadAction<ShipmentPreviewDTM>) => ({
      ...state,
      shipmentData: payload,
    }),
    setCargos: (state, { payload }: PayloadAction<CargoDTM[]>) => ({
      ...state,
      cargos: payload,
    }),
    setHasTemperatureControl: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasTemperatureControl: payload,
    }),
    setHasSOC: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasSOC: payload,
    }),
    setShouldHaveHazmats: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldHaveHazmats: payload,
    }),
    setIsHazmatErrorVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isHazmatErrorVisible: payload,
    }),
    addCargo: (state, { payload }: PayloadAction<string | undefined>) => {
      const newCargo = CargoDTM.fromPlain({
        ...state.defaultCargo,
        code: payload || state.defaultCargo.code,
        id: Date.now(),
        renderId: uuidv4(),
        errors: {},
        touchedFields: {},
        isHazmat: false,
        isHazmatCollapseOpen: false,
        wasUpdateAttempted: false,
        hsCodeValidationStatus: state.hasTemperatureControl ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
        initialState: state.defaultCargo,
      });

      state.createdCargosIds = [
        ...state.createdCargosIds,
        String(newCargo.id),
      ];

      state.cargos = [...state.cargos, newCargo];
    },
    changeCargoIdByIndex: (state, { payload }: PayloadAction<{
      cargoId: number;
      index: number;
    }>) => {
      const tempCargos = [...state.cargos];
      const cargo = CargoDTM.fromPlain({
        ...tempCargos[payload.index],
        id: payload.cargoId,
      });

      tempCargos.splice(payload.index, 1, cargo);

      state.cargos = [...tempCargos];
    },
    setContainers: (state, { payload }: PayloadAction<ContainerDTM[]>) => ({
      ...state,
      containers: [...payload],
      containersErrors: payload.map(({ id }) => BookingWizardContainersErrorsDTM.createEmpty(id)),
    }),
    setDefaultCargo: (state, action: PayloadAction<CargoBaseDTM>) => {
      state.defaultCargo = {
        ...action.payload,
      };
    },
    setHsCode: (state, { payload }: ChangeHsCodeAction) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;
      const {
        code,
        name,
        errorsName,
        errorsCode,
      } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        code,
        name,
        errors: {
          ...cargo.errors,
          code: errorsCode,
          name: errorsName,
        },
        touchedFields: {
          ...cargo.touchedFields,
          code: true,
        },
      });
    },
    setHsCodeValidationStatus: (state, { payload }: PayloadAction<{
      cargoId: number;
      status: CargoDTM['hsCodeValidationStatus']
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        hsCodeValidationStatus: payload.status,
      });
    },
    setHsCodeError: (state, { payload }: PayloadAction<{
      code: CargoErrorsDTM['code'];
      cargoId: number;
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          code: payload.code,
        },
      });
    },
    setCargoError: (state, { payload }: PayloadAction<{
      cargoId: number;
      field: keyof CargoErrorsDTM;
      error?: ValidationErrorDTM;
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          [payload.field]: payload.error,
        },
      });
    },
    touchCargoField: (state, { payload }: PayloadAction<{
      field: string;
      cargoId: number;
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        touchedFields: {
          ...cargo.touchedFields,
          [payload.field]: true,
        },
      });
    },
    setCargoErrors: (state, action: PayloadAction<{ index: number, errors: CargoErrorsDTM }>) => {
      const { index, errors } = action.payload;

      state.cargos[index] = CargoDTM.fromPlain({
        ...state.cargos[index],
        errors,
      });
    },
    setDescription: (state, { payload }: PayloadAction<{
      cargoId: number;
      description: string;
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        description: payload.description,
        touchedFields: {
          ...cargo.touchedFields,
          description: true,
        },
      });
    },
    setPackageType: (state, { payload }: ChangeValidatedFieldAction<'packageType'>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;
      const { packageType, error } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        packageType,
        errors: {
          ...cargo.errors,
          packageType: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          packageType: true,
        },
      });
    },
    setPackagesNumber: (state, { payload }: ChangeValidatedFieldAction<'packagesNumber'>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;
      const { packagesNumber, error } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        packagesNumber,
        errors: {
          ...cargo.errors,
          packagesNumber: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          packagesNumber: true,
        },
      });
    },
    setWeight: (state, { payload }: ChangeValidatedFieldAction<'weight'>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;
      const { weight, error } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        weight,
        errors: {
          ...cargo.errors,
          weight: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          weight: true,
        },
      });
    },
    setVolume: (state, { payload }: PayloadAction<{
      cargoId: number;
      volume: string;
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;
      const { volume } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        volume,
        touchedFields: {
          ...cargo.touchedFields,
          volume: true,
        },
      });
    },
    setValue: (state, { payload }: ChangeValidatedFieldAction<'value'>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;
      const { value, error } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        value,
        errors: {
          ...cargo.errors,
          value: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          value: true,
        },
      });
    },
    removeCargoById: (state, { payload }: PayloadAction<number>) => {
      const cargos = [...state.cargos];
      const targetIndex = cargos.findIndex(({ id }) => id === payload) as number;

      cargos.splice(targetIndex, 1);

      return {
        ...state,
        cargos: [
          ...cargos,
        ],
      };
    },
    addToggledHazmatCargoId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      toggledHazmatCargoIds: [
        ...state.toggledHazmatCargoIds,
        payload,
      ],
    }),
    removeToggledHazmatCargoId: (state, { payload }: PayloadAction<number>) => {
      const cargoIds = [...state.toggledHazmatCargoIds];
      const targetIndex = cargoIds.findIndex((id) => id === payload) as number;

      cargoIds.splice(targetIndex, 1);

      state.toggledHazmatCargoIds = [
        ...cargoIds,
      ];
    },
    setImoClass: (state, { payload }: PayloadAction<{
      cargoId: number;
      imoClass: CargoDTM['imoClass'];
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        imoClass: payload.imoClass,
        touchedFields: {
          ...cargo.touchedFields,
          imoClass: true,
        },
      });
    },
    setUnNumber: (state, { payload }: PayloadAction<{
      cargoId: number;
      unNumber: CargoDTM['unNumber'];
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        unNumber: payload.unNumber,
        touchedFields: {
          ...cargo.touchedFields,
          unNumber: true,
        },
      });
    },
    setPackingGroup: (state, { payload }: PayloadAction<{
      cargoId: number;
      packingGroup: CargoDTM['packingGroup'];
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        packingGroup: payload.packingGroup,
        touchedFields: {
          ...cargo.touchedFields,
          packingGroup: true,
        },
      });
    },
    setShippingName: (state, { payload }: PayloadAction<{
      cargoId: number;
      shippingName: CargoDTM['shippingName'];
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        shippingName: payload.shippingName,
        touchedFields: {
          ...cargo.touchedFields,
          shippingName: true,
        },
      });
    },
    setMsdsDocument: (state, { payload }: PayloadAction<{
      cargoId: number;
      msdsDocument: CargoDTM['msdsDocument'];
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        msdsDocument: payload.msdsDocument,
        touchedFields: {
          ...cargo.touchedFields,
          msdsDocument: true,
        },
      });
    },
    setIsHazmat: (state, { payload }: PayloadAction<{
      isHazmat: CargoDTM['isHazmat'],
      cargoId: number,
    }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        isHazmat: payload.isHazmat,
      });
    },
    clearHazmatErrors: (state, { payload }: PayloadAction<number>) => {
      const cargo = state.cargos.find(({ id }) => id === payload) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload) as number;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          unNumber: undefined,
          imoClass: undefined,
          packingGroup: undefined,
          shippingName: undefined,
          msdsDocument: undefined,
        },
      });
    },
    setHazmatErrors: (state, { payload }: PayloadAction<Pick<CargoErrorsDTM, 'imoClass' | 'unNumber' | 'packingGroup' | 'shippingName' | 'msdsDocument'> & { cargoId: number }>) => {
      const cargo = state.cargos.find(({ id }) => id === payload.cargoId) as CargoDTM;
      const cargoIndex = state.cargos.findIndex(({ id }) => id === payload.cargoId) as number;

      const {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument,
      } = payload;

      state.cargos[cargoIndex] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          unNumber,
          imoClass,
          packingGroup,
          shippingName,
          msdsDocument,
        },
      });
    },
    setContainerNumber: (state, { payload }: PayloadAction<{ id: string; containerNumber: string }>) => {
      const container = state.containers.find(({ id }) => id === payload.id) as ContainerDTM;
      const containerIndex = state.containers.findIndex(({ id }) => id === payload.id);

      state.containers[containerIndex] = ContainerDTM.fromPlain({
        ...container,
        number: payload.containerNumber,
      });
    },
    setContainersError: (state, { payload }: PayloadAction<{ containerId: string; errors: BookingWizardContainersErrorsDTM }>) => {
      const containerIndex = state.containersErrors.findIndex((errors) => errors.containerId === payload.containerId);

      state.containersErrors[containerIndex] = BookingWizardContainersErrorsDTM.fromPlain({
        ...payload.errors,
      });
    },
    setContainersErrorByKey: (state, { payload }: PayloadAction<{ containerId: string; errorKey: 'certificate' | 'sealNumber' | 'number' | 'estimatedVolume' | 'estimatedWeight'; error?: ValidationErrorDTM }>) => {
      const { containerId, error, errorKey } = payload;
      const containerIndex = state.containersErrors.findIndex((errors) => errors.containerId === containerId);

      state.containersErrors[containerIndex] = BookingWizardContainersErrorsDTM.fromPlain({
        ...state.containersErrors[containerIndex],
        [errorKey]: error,
      });
    },
    setSealNumber: (state, { payload }: PayloadAction<{ id: string; sealNumber: string }>) => {
      const container = state.containers.find(({ id }) => id === payload.id) as ContainerDTM;
      const containerIndex = state.containers.findIndex(({ id }) => id === payload.id);

      state.containers[containerIndex] = ContainerDTM.fromPlain({
        ...container,
        sealNumber: payload.sealNumber,
      });
    },
    setContainerDocument: (state, { payload }: PayloadAction<{ document: ContainerDocumentDTM | null; containerId: string }>) => {
      const container = state.containers.find(({ id }) => id === payload.containerId) as ContainerDTM;
      const containerIndex = state.containers.findIndex(({ id }) => id === payload.containerId);

      state.containers[containerIndex] = ContainerDTM.fromPlain({
        ...container,
        seaworthyCertificate: payload.document,
      });
    },
    clearCreatedCargosIds: (state) => {
      state.createdCargosIds = [];
    },
    reset: () => ({
      ...initialState,
    }),
    setWCBDefaultGetRequestStatusStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          defaultState: {
            ...state?.wizardContactBook?.defaultState,
            getRequestStatus: payload,
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListStateNameById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors.companyListState.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              companyListState: [
                ...state.wizardContactBook.updateState.errors.companyListState?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  name: {
                    ...oldError.name,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateCompanyListByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateByIndexDTM>) => {
      const { listId, companyList: companyListNew } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyList = listState.length
        ? listState.map((item) => {
          if (listId === item.customId) {
            return companyListNew;
          }
          return item;
        })
        : [companyListNew];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList,
          },
        },
      };
    },
    setWCBDefaultAddCompanyListStateById: (state, action: PayloadAction<ISetUpdateAddCompanyListStateByIndexDTM>) => {
      const { listId, companyList } = action.payload;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          defaultState: {
            ...state.wizardContactBook.defaultState,
            companyList: [
              ...state.wizardContactBook.defaultState?.companyList?.map((item) => {
                if (item.customId === listId) {
                  return companyList;
                }
                return item;
              }) || [],
            ],
          },
        },
      };
    },
    setWCBDefaultAddCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          defaultState: {
            ...state.wizardContactBook.defaultState,
            companyListAddresses: [
              ...state.wizardContactBook.defaultState.companyListAddresses,
              payload,
            ],
          },
        },
      };
    },
    setWCBDefaultAddCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          defaultState: {
            ...state.wizardContactBook.defaultState,
            companyListContactPersons: [
              ...state.wizardContactBook.defaultState.companyListContactPersons,
              payload,
            ],
          },
        },
      };
    },
    setWCBDefaultEditCompanyListAddressesById: (state, action: PayloadAction<ISetUpdateAddCompanyListAddressesById>) => {
      const { listId, companyListAddresses } = action.payload;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          defaultState: {
            ...state.wizardContactBook.defaultState,
            companyListAddresses: state.wizardContactBook.defaultState.companyListAddresses.map((item) => {
              if (item.customId === listId) {
                return companyListAddresses;
              }
              return item;
            }),
          },
        },
      };
    },
    setWCBDefaultEditCompanyListContactPersonsById: (state, action: PayloadAction<ISetUpdateAddCompanyListContactPersonsById>) => {
      const { listId, companyListContactPersons } = action.payload;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          defaultState: {
            ...state.wizardContactBook.defaultState,
            companyListContactPersons: state.wizardContactBook.defaultState.companyListContactPersons.map((item) => {
              if (item.customId === listId) {
                return companyListContactPersons;
              }
              return item;
            }),
          },
        },
      };
    },
    setWCBUpdateEditCompanyListAddressesById: (state, action: PayloadAction<ISetUpdateAddCompanyListAddressesById>) => {
      const { listId, companyListAddresses } = action.payload;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListAddresses: state.wizardContactBook.updateState.companyListAddresses.map((item) => {
              if (item.customId === listId) {
                return companyListAddresses;
              }
              return item;
            }),
          },
        },
      };
    },
    setWCBUpdateEditCompanyListContactPersonsById: (state, action: PayloadAction<ISetUpdateAddCompanyListContactPersonsById>) => {
      const { listId, companyListContactPersons } = action.payload;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListContactPersons: state.wizardContactBook.updateState.companyListContactPersons.map((item) => {
              if (item.customId === listId) {
                return companyListContactPersons;
              }
              return item;
            }),
          },
        },
      };
    },
    setWCBUpdateCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListAddresses: payload,
          },
        },
      };
    },
    setWCBUpdateCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListContactPersons: payload,
          },
        },
      };
    },
    setWCBUpdateCompanyList: (state, action: PayloadAction<ContactBookDefaultStateCompanyListStateDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: payload,
          },
        },
      };
    },
    setWCBUpdateCompanyListById: (state, action: PayloadAction<ISetUpdateCompanyListStateByIdDTM>) => {
      const { listId, companyList } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            ...companyList,
          };
        }
        return item;
      });

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: companyListState,
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              addresses: [
                ...state.wizardContactBook.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  addressLineFirst: {
                    ...oldError.addressLineFirst,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListAddressesCityById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              addresses: [
                ...state.wizardContactBook.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  city: {
                    ...oldError.city,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListAddressesCountryById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              addresses: [
                ...state.wizardContactBook.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  country: {
                    ...oldError.country,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListAddressesStateById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              addresses: [
                ...state.wizardContactBook.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  state: {
                    ...oldError.state,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListAddressesPostalCodeById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              addresses: [
                ...state.wizardContactBook.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  postalCode: {
                    ...oldError.postalCode,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListStateContactsContactPersonById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              contactPersons: [
                ...state.wizardContactBook.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  contactPerson: {
                    ...oldError.contactPerson,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListStateContactsEmailById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              contactPersons: [
                ...state.wizardContactBook.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  email: {
                    ...oldError.email,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListStateContactsPhoneById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              contactPersons: [
                ...state.wizardContactBook.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  phone: {
                    ...oldError.phone,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              contactPersons: [
                ...state.wizardContactBook.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  additionalPhone: {
                    ...oldError.additionalPhone,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateCompanyListIsUpdateById: (state, action: PayloadAction<ISetUpdateCompanyListIsUpdateByIdByIndexDTM>) => {
      const { listId, flag } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            isUpdate: flag,
          };
        }
        return item;
      });

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: companyListState,
          },
        },
      };
    },
    setWCBUpdateAddCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListAddresses: [
              ...state.wizardContactBook.updateState.companyListAddresses,
              payload,
            ],
          },
        },
      };
    },
    setWCBUpdateAddCompanyList: (state, action: PayloadAction<ContactBookDefaultStateCompanyListStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: [
              ...state.wizardContactBook.updateState.companyList,
              {
                ...payload,
              },
            ],
          },
        },
      };
    },
    setWCBUpdateAddCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListContactPersons: [
              ...state.wizardContactBook.updateState.companyListContactPersons,
              payload,
            ],
          },
        },
      };
    },
    setWCBUpdateSetActiveListTabIndex: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            activeListTabIndex: payload,
          },
        },
      };
    },
    setWCBUpdateErrorsCompanyListAddressesAddressLineSecondById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.wizardContactBook.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            errors: {
              ...state.wizardContactBook.updateState.errors,
              addresses: [
                ...state.wizardContactBook.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
                {
                  ...oldError,
                  customId: indexList,
                  addressLineSecond: {
                    ...oldError.addressLineSecond,
                    ...error,
                  },
                },
              ],
            },
          },
        },
      };
    },
    setWCBUpdateAddCompanyListAddressByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateAddressByIndexDTM>) => {
      const { listId, addressesId } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            addressesIds: [
              ...item.addressesIds || [],
              addressesId,
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: companyListState,
          },
        },
      };
    },
    setWCBUpdateRemoveCompanyListAddressByIndex: (state, action: PayloadAction<ISetUpdateRemoveCompanyListStateAddressByIndexDTM>) => {
      const { listId, addressesId } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            addressesIds: [
              ...item?.addressesIds?.filter((itemId) => itemId !== addressesId) || [],
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: companyListState,
          },
        },
      };
    },
    setWCBUpdateEditCompanyListContactPersons: (state, action: PayloadAction<ISetUpdateAddCompanyListContactPersonsById>) => {
      const { listId, companyListContactPersons } = action.payload;

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyListContactPersons: state.wizardContactBook.updateState.companyListContactPersons.map((item) => {
              if (item.customId === listId) {
                return companyListContactPersons;
              }

              return item;
            }),
          },
        },
      };
    },
    setWCBUpdateAddCompanyListContactPersonByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateContactPersonByIndexDTM>) => {
      const { listId, contactPersonId } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            contactPersonsIds: [
              ...item.contactPersonsIds || [],
              contactPersonId,
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: companyListState,
          },
        },
      };
    },
    setWCBUpdateRemoveCompanyListContactPersonByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateContactPersonByIndexDTM>) => {
      const { listId, contactPersonId } = action.payload;

      const listState = state.wizardContactBook.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            contactPersonsIds: [
              ...item?.contactPersonsIds?.filter((itemId) => itemId !== contactPersonId) || [],
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        wizardContactBook: {
          ...state.wizardContactBook,
          updateState: {
            ...state.wizardContactBook.updateState,
            companyList: companyListState,
          },
        },
      };
    },
    setActiveQuoteCharges: (state, { payload }) => ({
      ...state,
      activeQuoteCharges: payload,
    }),
  },
});

export const bookingWizardActions = bookingWizardSlice.actions;
export const bookingWizardReducer = bookingWizardSlice.reducer;
