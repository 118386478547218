import { ENotificationCriticality } from 'app-wrapper/constants';
import { R as AuthR } from 'authentication/repository';
import { ShipmentStatusEnum } from 'shipment-operations/constants';
import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';
import { R as shipmentOperationsR } from 'shipment-operations/repository';

import { R as userManagementR } from 'user-management/repository';
import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { R } from 'app-wrapper/repository';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM, CommandCenterGetTasksDTM, NotificationTaskDTM,
} from 'app-wrapper/models/dtm';

const TasksBlockSize = '100';

const defaultParams = CommandCenterGetTasksDTM.fromPlain({
  page: `${0}`,
  size: TasksBlockSize,
  sort: 'dueDate,asc',
  query: 'TODO',
});

const getTasksStats = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<ShipmentAllStatsDTM | undefined>((resolve, reject) => {
  try {
    const res = R.services.CommandCenter.getOrganizationStats({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

export class OverviewDashboardUseCase extends BaseUseCase {
  public async resetStatisticsTasksAndNotification() {
    this.dispatch(R.actions.overview.setInfoCountAllTasks(0));
    this.dispatch(R.actions.overview.setInfoCountOverdueTasks(0));
    this.dispatch(R.actions.overview.setInfoCountDueTasks(0));
    this.dispatch(R.actions.overview.setInfoCountPlannedTasks(0));
    this.dispatch(R.actions.overview.setCountUnreadNotifications(0));
    this.dispatch(R.actions.overview.setCountImportantNotifications(0));
    this.dispatch(R.actions.overview.setCountRegularNotifications(0));
    this.dispatch(R.actions.overview.setTasks([]));
    this.dispatch(R.actions.overview.setCountAllTasks(0));
  }

  public async onLoadStatisticsTasks(shipmentId?: string) {
    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await userManagementR.services.organization.getCurrentOrganization();
    }

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;
    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: 'TODO',
      shipmentId,
      assigneeUserEmails: assigneeOrganizationUsers,
      assigneeOrganizationIds,
    });

    const [res11] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
    ]);

    const tasksOverdue1 = res11?.taskStats.overdue;
    const tasksDue1 = res11?.taskStats.expiring;
    const tasksPlanned1 = res11?.taskStats.todo;

    this.dispatch(R.actions.overview.setInfoCountOverdueTasks(tasksOverdue1 || 0));
    this.dispatch(R.actions.overview.setInfoCountDueTasks(tasksDue1 || 0));
    this.dispatch(R.actions.overview.setInfoCountPlannedTasks(tasksPlanned1 || 0));
  }

  public async onLoadStatisticsNotifications(shipmentId?: string) {
    const organization = await this.repositories.organizationRepository.get();
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    const params = CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
      page: `${0}`,
      size: '1',
      sort: 'createdAt,desc',
      shipmentId,
      assigneeAudienceOrganizationIds,
    });

    const [res11] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        targetAudienceOrganizationIds: assigneeAudienceOrganizationIds,
      })),
    ]);

    const notificationUnread1 = res11?.notificationStats.unread;
    const notificationImportant1 = res11?.notificationStats.unreadCritical;
    const notificationRegular = res11?.notificationStats.unreadRegular;

    this.dispatch(R.actions.overview.setCountUnreadNotifications(notificationUnread1 || 0));
    this.dispatch(R.actions.overview.setCountImportantNotifications(notificationImportant1 || 0));
    this.dispatch(R.actions.overview.setCountRegularNotifications((notificationRegular || 0)));
  }

  public async completeOnboarding() {
    const organization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const organizationMember = userManagementR.selectors.userOrganizationData.getCurrentOrganizationUser(this.store.getState());
    const hasOwnRequestedQuotas = R.selectors.overview.getHasOwnRequestedQuotas(this.store.getState());
    const hasOwnBookedShipments = R.selectors.overview.getHasOwnBookedShipments(this.store.getState());
    const members = userManagementR.selectors.userManagement.getUserList(this.store.getState());
    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      if (organization && hasOwnRequestedQuotas && hasOwnBookedShipments && ((organizationMember && organizationMember.primary && members.length > 1) || (organizationMember && !organizationMember.primary)) && userEmail && organizationMember && !organizationMember?.onboarded) {
        await userManagementR.services.organization.putOrganizationMember(organization.id, userEmail, OrganizationMemberDTM.fromPlain({
          ...organizationMember,
          onboarded: true,
        }));

        this.dispatch(R.actions.overview.setIsUserOnboarded(true));
        this.loadDashboard();
      }
    } finally {
      this.dispatch(R.actions.overview.setIsLoading(false));
    }
  }

  public async loadDashboard() {
    let tasks: NotificationTaskDTM | null = null;
    let notifications: NotificationTaskDTM | null = null;
    const { email } = this.store.getState().auth;
    const assigneeUserEmails = email ? [email] : undefined;
    const { organization } = this.store.getState().userOrganizationData;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    this.dispatch(R.actions.overview.setIsLoadingTasksBoard(true));
    this.loadShipments();

    try {
      tasks = await R.services.CommandCenter.getTasks(CommandCenterGetTasksDTM.fromPlain({
        page: '0',
        sort: 'dueDate,desc',
        query: 'TODO',
        size: '3',
        criticality: [ENotificationCriticality.HIGH],
        assigneeUserEmails,
      }));
    } catch (e) {
      console.error('OverviewController: init getCurrentOrganization');
    }

    this.dispatch(R.actions.overview.setIsLoadingTasksBoard(false));

    if (tasks) {
      this.dispatch(R.actions.overview.setTasks(tasks.content));
      this.dispatch(R.actions.overview.setCountAllTasks(tasks.totalElements));
    }

    try {
      notifications = await R.services.CommandCenter.getNotifications(CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
        page: '0',
        sort: 'createdAt,desc',
        assigneeAudienceOrganizationIds,
      }));
    } catch (e) {
      console.error('OverviewController: init getCurrentOrganization');
    }

    if (notifications) {
      this.dispatch(R.actions.overview.setNotifications(notifications.content.splice(0, 5)));
    }

    this.dispatch(R.actions.overview.setIsLoadingNotificationsStatistics(false));
  }

  private async loadShipments() {
    const departingShipmentPromise = shipmentOperationsR.services.shipment.postShipmentSearch({
      locations: [],
      companies: [],
      containerTypes: [],
      statuses: [ShipmentStatusEnum.IN_TRANSIT, ShipmentStatusEnum.CONFIRMED],
      pageRequest: { page: 0, size: 3, sort: 'DEPARTURE' },
      query: '',
    });

    const arrivingShipmentPromise = shipmentOperationsR.services.shipment.postShipmentSearch({
      locations: [],
      companies: [],
      containerTypes: [],
      statuses: [ShipmentStatusEnum.IN_TRANSIT],
      pageRequest: { page: 0, size: 3, sort: 'ARRIVAL' },
      query: '',
    });

    const pendingConfirmationsShipmentPromise = shipmentOperationsR.services.shipment.postShipmentSearch({
      locations: [],
      companies: [],
      containerTypes: [],
      statuses: [ShipmentStatusEnum.PENDING_CONFIRMATION],
      pageRequest: { page: 0, size: 3, sort: 'CREATED_AT' },
      query: '',
    });

    const [departingShipment, arrivingShipment, pendingConfirmationsShipment] = await Promise.all([departingShipmentPromise, arrivingShipmentPromise, pendingConfirmationsShipmentPromise]);

    this.mobxStores.overviewDepartingShipmentStore.setShipments(departingShipment.shipmentList || []);
    this.mobxStores.overviewDepartingShipmentStore.setLoading(false);
    this.mobxStores.overviewArrivingShipmentStore.setShipments(arrivingShipment.shipmentList || []);
    this.mobxStores.overviewArrivingShipmentStore.setLoading(false);
    this.mobxStores.overviewPendingConfirmationsShipmentStore.setShipments(pendingConfirmationsShipment.shipmentList || []);
    this.mobxStores.overviewPendingConfirmationsShipmentStore.setLoading(false);
  }
}
