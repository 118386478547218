import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';
import { EDrayageLoadingSide } from 'shipment-operations/constants';
import { SupplierScheduleComponent } from 'shipment-operations/view/components/TransportationDrayage';

export const SuppliersSchedule = observer(() => {
  const { t } = useTranslation();
  const { shipmentSchedulesStore } = useMobxStore();
  const permissions = useSelector(R.selectors.shipment.getTransportationDrayagePermissions);

  return (
    <SupplierScheduleComponent
      title={t('Supplier’s Schedule')}
      drayageSide={EDrayageLoadingSide.ORIGIN_DRAYAGE}
      openEditDrawer={appUC.drawer.openEditSupplierScheduleDrawer}
      cargoReadyDatePermission={permissions?.editExportSchedule}
      schedule={shipmentSchedulesStore.getExportSchedule}
    />
  );
});
