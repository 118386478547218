import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import { hasAccess } from 'app-wrapper/utils';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { CardTopFormStatus } from 'shipment-operations/view/components';

import { CargoFormTopPanel, CargoFormName } from './CargoFormHeader.styled';

interface ICargoFormHeaderComponentProps {
  isError: boolean
  removeCargo: (shipmentId: number) => void
  code?: CargoDTM['code']
  cargosAvailability?: PermissionAttributePolicy
  customerCargosAvailability?: PermissionAttributePolicy
}

export const CargoFormHeaderComponent: FC<ICargoFormHeaderComponentProps> = ({
  isError,
  code,
  removeCargo,
  cargosAvailability,
  customerCargosAvailability,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();

  const handleRemove = useCallback(() => {
    removeCargo(+shipmentId);
  }, []);

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, customerCargosAvailability]);

  return (
    <CargoFormTopPanel>
      <CargoFormName>
        {code && <Typography.Text>{code}</Typography.Text>}
        <CardTopFormStatus isError={isError} />
      </CargoFormName>
      <Button
        size="small"
        type="dashed"
        icon={<DeleteOutlined />}
        onClick={handleRemove}
        disabled={isDisabled}
      >
        {t('Remove')}
      </Button>
    </CargoFormTopPanel>
  );
};
