import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { DateDtm } from 'app-wrapper/models/dtm';

import { CurrentScheduleComponent } from './CurrentSchedule.component';

const CurrentScheduleContainer = React.memo(() => {
  const schedule = useSelector(R.selectors.bookingWizard.getCurrentSchedule);
  const totalCost = useSelector(R.selectors.bookingWizard.getTotalCost);

  if (!schedule) {
    return null;
  }

  const {
    departureTime,
    arrivalTime,
    terminalCutOff,
    documentCutOff,
    hazmatCutOff,
  } = schedule;

  return (
    <CurrentScheduleComponent
      etd={departureTime ? DateDtm.fromPlain(departureTime).getDateDMMMYYYYWithOffset() : ''}
      eta={arrivalTime ? DateDtm.fromPlain(arrivalTime).getDateDMMMYYYYWithOffset() : ''}
      terminalCutoffDate={terminalCutOff ? DateDtm.fromPlain(terminalCutOff).getDateDMMMYYYYHHmmWithOffset() : ''}
      documentCutoffDate={documentCutOff ? DateDtm.fromPlain(documentCutOff).getDateDMMMYYYYHHmmWithOffset() : ''}
      hazmatCutoffDate={hazmatCutOff ? DateDtm.fromPlain(hazmatCutOff).getDateDMMMYYYYHHmmWithOffset() : ''}
      totalCost={Number(totalCost)}
    />
  );
});

export { CurrentScheduleContainer as CurrentSchedule };
