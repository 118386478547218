import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'antd/es/select';

import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline';
import { SectionTitle } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/SupplierLayout/SupplierLayout.styled';
import { ShippingPartyReference } from 'shipment-operations/models/dtm';

interface IReferencesSectionsProps {
  references: ShippingPartyReference[];
  setReferences: (references: string[]) => void;
}

export const ReferencesSectionComponent: FC<IReferencesSectionsProps> = ({
  references,
  setReferences,
}) => {
  const { t } = useTranslation();

  return (
    <GapVerticalContainerStyled>
      <SectionTitle>
        {t('References')}
      </SectionTitle>

      <Select
        size="middle"
        allowClear
        mode="tags"
        value={references.map((_reference) => _reference.value)}
        onChange={setReferences}
      />
    </GapVerticalContainerStyled>
  );
};
