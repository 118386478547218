import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import {
  EFreightIncotermsByExport,
  EFreightIncotermsByImport,
  EFreightIncotermsTrade, FreightIncotermsNames,
  getOriginAndDestinationIncotermsExportArray,
  getOriginAndDestinationIncotermsImportArray,
} from 'monetary/constants';

import {
  Option,
  Tooltip,
} from 'app-wrapper/view/components';

import { RFQTabsSelect } from '../RFQTabs/RFQTabs.styled';
import {
  RAPContent, RAPContentSelect, RAPContentTitle, RAPContentTitleIcon, RAPContentTitleWrap, RAPWrap, RFQFormHeader, RFQFormHeaderMore, RFQFormHeaderName,
  RFQPaymentWrap,
} from './RisksAndPayments.styled';
import { PaymentsTradeStatusContainer } from './PaymentsTradeStatus';

interface IRisksAndPaymentsComponentProps {
  forceCollapse?: boolean
  isPortOrigin?: boolean
  isPortDestination?: boolean
  incoterms: string
  incotermsTrade: string
  isAllDisabled?: boolean
  isActiveTextOption?: boolean
  isActiveFirstStep?: boolean
  isActiveSecondStep?: boolean
  isActiveThirdsStep?: boolean
  onChangeFreightIncoterms: (value: string) => void
}

const RisksAndPaymentsComponent: FC<IRisksAndPaymentsComponentProps> = (props) => {
  const {
    isPortOrigin,
    isPortDestination,
    forceCollapse,
    incoterms,
    incotermsTrade,
    isAllDisabled,
    isActiveTextOption,
    isActiveFirstStep,
    isActiveSecondStep,
    isActiveThirdsStep,
    onChangeFreightIncoterms,
  } = props;
  const { t } = useTranslation();

  const onChangeFreightIncotermsHandler = useCallback(
    (value) => {
      onChangeFreightIncoterms(value);
    },
    [],
  );

  return (
    <RAPWrap>
      <RFQFormHeader>
        <RFQFormHeaderName>
          {t('Risks & Payments')}
        </RFQFormHeaderName>

        {/* temporarily hidden */}
        {false && (
          <RFQFormHeaderMore>
            {t('More Details')}
          </RFQFormHeaderMore>
        )}
      </RFQFormHeader>

      <RAPContent>
        <RAPContentTitleWrap>
          <RAPContentTitle>
            {t('Incoterm')}
          </RAPContentTitle>
          <RAPContentTitleIcon>
            <Tooltip title={t('TBD')} placement="top">
              <InfoCircleOutlined />
            </Tooltip>
          </RAPContentTitleIcon>
        </RAPContentTitleWrap>

        <RAPContentSelect
          $flexDirection={!forceCollapse ? 'column' : undefined}
        >
          <RFQTabsSelect
            value={incoterms || null}
            disabled={!incotermsTrade || isAllDisabled}
            onChange={onChangeFreightIncotermsHandler}
            optionFilterProp="children"
            cssWidth={forceCollapse ? '34%' : '100%'}
          >
            {!!(incotermsTrade === EFreightIncotermsTrade.IMPORT) && getOriginAndDestinationIncotermsImportArray(!isPortOrigin, !isPortDestination).map((item) => (
              <Option
                value={item}
                key={`RFQTabsSelect_${item}`}
              >
                {`${FreightIncotermsNames?.[item as EFreightIncotermsByImport] || ''} (${item})`}
              </Option>
            ))}
            {!!(incotermsTrade === EFreightIncotermsTrade.EXPORT) && getOriginAndDestinationIncotermsExportArray(!isPortOrigin, !isPortDestination).map((item) => (
              <Option
                value={item}
                key={`RFQTabsSelect_${item}`}
              >
                {`${FreightIncotermsNames?.[item as EFreightIncotermsByExport] || ''} (${item})`}
              </Option>
            ))}
          </RFQTabsSelect>

          <RFQPaymentWrap
            $width={forceCollapse ? '60%' : '100%'}
            $marginLeft={forceCollapse ? '10px' : '0'}
          >
            <PaymentsTradeStatusContainer
              textOption={t('You pay for')}
              firstStep={t('Origin')}
              secondStep={t('Freight')}
              thirdsStep={t('Destination')}
              isFull={!forceCollapse}
              isActiveTextOption={isActiveTextOption}
              isActiveFirstStep={isActiveFirstStep}
              isActiveSecondStep={isActiveSecondStep}
              isActiveThirdsStep={isActiveThirdsStep}
            />
          </RFQPaymentWrap>
        </RAPContentSelect>

      </RAPContent>
    </RAPWrap>
  );
};

const RisksAndPaymentsComponentCache = memo(RisksAndPaymentsComponent);

export { RisksAndPaymentsComponentCache as RisksAndPaymentsComponent };
