import React from 'react';
import UnlockOutlined from '@ant-design/icons/UnlockOutlined';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const UnlockSvg = (props: IPropsIcon) => {
  const { fill } = props;
  return (
    <UnlockOutlined style={{ color: fill }} />
  );
};
