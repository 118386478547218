import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ReferencesSectionComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components';

export const ReferencesSection = React.memo(() => {
  const cargoReceiver = useSelector(R.selectors.bookingWizard.getCargoReceiver);

  return (
    <ReferencesSectionComponent
      references={cargoReceiver?.references || []}
      setReferences={UC.bookingWizard.setReceiverReferences}
    />
  );
});
