import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntTag from 'antd/es/tag';

import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { scaffoldSection } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/ShipmentInstructionsContent/components/SBLScaffold/SBLScaffold.styled';

export const SBLReferencesInformationWrapper = styled('div').attrs({
  'data-class': 'SBLReferencesInformation',
})`
  ${scaffoldSection}
  width: 100%;
  height: 100%;
  max-height: 168px;
`;

export const SBLReferencesInformation = styled('div').attrs({
  'data-class': 'SBLReferencesInformation',
})`
  ${divFlex}
  flex-direction: column;
`;

export const SBLReferencesTitle = styled(Typography)`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SBLReferencesList = styled('div')`
  ${divFlex}
  flex-wrap: wrap;
`;

export const SBLReferencesItem = styled('div')`
  ${divFlex}
  width: 16.66%;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-direction: column;
`;

export const SBLReferencesItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const SBLReferencesItemSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const Tag = styled(AntTag)`
  width: fit-content;
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EditIcon = styled(EditOutlined)`
  margin-left: 8px;
  color: #73819B;
  cursor: pointer;
`;

export const ClockIcon = styled(ClockCircleOutlined)`
  font-size: 11px;
`;
