import styled from 'styled-components';
import Typography from 'antd/es/typography';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';

export const Leg = styled.div`
  border-top: 1px solid #F4F5F8;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px dashed #EAEEF8;
`;

export const FirstRow = styled(Row)`
  padding: 16px 0 8px 0;
`;

export const LastRow = styled(Row)`
  border-bottom: 0;
`;

export const LegName = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LegNameText = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
`;

export const LegNameTextBold = styled(LegNameText)`
  font-weight: 500;
`;

export const CheckIcon = styled(CheckCircleFilled)`
  svg {
    fill: #6C79DE;
  }
`;

export const CircleWrap = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleIcon = styled.div`
  background: transparent;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  border: 2px solid #6C79DE;
`;

export const InputsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
