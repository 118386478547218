import moment, { Moment } from 'moment';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import { FreightSelectFieldDTM } from 'monetary/models/dtm';

import {
  OceanRateDownloadModalBody,
  OceanRateDownloadModalDatePicker,
  OceanRateDownloadModalFooter,
  OceanRateDownloadModalItem,
  OceanRateDownloadModalSelect,
  OceanRateDownloadModalTitle,
} from 'monetary/view/pages/RatesUpload/components/OceanRateDownloadModal/OceanRateDownloadModal.styled';

const TIME_FORMAT = 'DD/MM/YYYY';
const MODAL_WIDTH = 416;

interface IDrayageRateDownloadModalComponentProps {
  isLoading: boolean
  isFormDataFilled: boolean
  isOriginLocationSearchListLoading: boolean
  originLocationSearchList: FreightSelectFieldDTM[]
  effectiveDate?: DateDtm
  originLocationValue?: string
  onEffectiveDateChange: (date: DateDtm | null) => void
  onEffectiveDateBlur: () => void
  onOriginLocationChange: (code: string) => void
  onOriginLocationBlur: () => void
  onOriginLocationSearch: (searchValue: string) => void
  onDownload: () => void
  onClose: () => void
}

export const DrayageRateDownloadModalComponent: FC<IDrayageRateDownloadModalComponentProps> = ({
  effectiveDate,
  isFormDataFilled,
  isLoading,
  isOriginLocationSearchListLoading,
  onClose,
  onDownload,
  onEffectiveDateBlur,
  onEffectiveDateChange,
  onOriginLocationBlur,
  onOriginLocationChange,
  onOriginLocationSearch,
  originLocationSearchList,
  originLocationValue,
}) => {
  const { t } = useTranslation();

  const onEffectiveDateChangeHandler = useCallback((date: Moment | null) => {
    const newDate = date ? DateDtm.fromPlain({
      date: date?.format(),
      offset: moment().utcOffset(),
    }) : null;

    onEffectiveDateChange(newDate);
  }, []);

  const onOriginLocationChangeHandler = useCallback((code: string) => {
    onOriginLocationChange(code);
  }, []);

  const onOriginLocationBlurHandler = useCallback(() => {
    onOriginLocationBlur();
  }, []);

  const onOriginLocationSearchHandler = useCallback((searchValue: string) => {
    onOriginLocationSearch(searchValue);
  }, []);

  return (
    <Modal
      visible
      closable={false}
      width={MODAL_WIDTH}
      onCancel={onClose}
      footer={(
        <OceanRateDownloadModalFooter>
          <Button
            type="ghost"
            disabled={isLoading}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onDownload}
            loading={isLoading}
            disabled={!isFormDataFilled}
          >
            {t('Download')}
          </Button>
        </OceanRateDownloadModalFooter>
      )}
    >
      <OceanRateDownloadModalBody>
        <OceanRateDownloadModalTitle>{t('Download Rates')}</OceanRateDownloadModalTitle>
        <OceanRateDownloadModalItem>
          <span>{t('Date')} *</span>
          <OceanRateDownloadModalDatePicker
            disabled={isLoading}
            value={effectiveDate?.getDateAsMomentWithOffset() || null}
            format={TIME_FORMAT}
            onChange={onEffectiveDateChangeHandler}
            onBlur={onEffectiveDateBlur}
            allowClear={false}
          />
        </OceanRateDownloadModalItem>

        <OceanRateDownloadModalItem>
          <span>{t('Port/Ramp')}</span>

          <OceanRateDownloadModalSelect
            showSearch
            loading={isOriginLocationSearchListLoading}
            disabled={isLoading}
            showArrow={false}
            filterOption={false}
            options={
              originLocationSearchList.map((item) => ({
                label: item.description || '',
                value: item.code || '',
              }))
            }
            value={originLocationValue}
            onSearch={onOriginLocationSearchHandler}
            onChange={onOriginLocationChangeHandler}
            onBlur={onOriginLocationBlurHandler}
          />
        </OceanRateDownloadModalItem>
      </OceanRateDownloadModalBody>
    </Modal>
  );
};
