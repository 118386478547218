import i18n from 'app-wrapper/i18n/i18n';
import { BaseValidation, BaseValidationError } from 'proto/BaseValidation/BaseValidation';

class MaxLengthValidationError extends BaseValidationError {
  message = '';

  constructor(maxLength: number) {
    super();
    this.message = i18n.t('basicErrors.MAX_LENGTH', { count: maxLength });
  }
}

export class MaxLengthValidation<T extends string | undefined> extends BaseValidation<T> {
  maxLength: number

  constructor(maxLength: number) {
    super();
    this.maxLength = maxLength;
  }

  validate(value: T) {
    if (value && value.length > this.maxLength) {
      return this.error();
    }
    return null;
  }

  error() {
    return new MaxLengthValidationError(this.maxLength);
  }
}

export const maxLength2048Validation = new MaxLengthValidation(2048);
