import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { StepsHeaderComponent } from './StepsHeader.component';

const StepsHeaderContainer = () => {
  const currentStep = useSelector(R.selectors.bookingWizard.getCurrentStep);
  const shipment = useSelector(R.selectors.bookingWizard.getShipment);
  const isCustomerOrg = useSelector(R.selectors.bookingWizard.getIsCurrentOrganizationCustomer);
  const isSuppliersInformationMissing = useSelector(R.selectors.bookingWizard.getIsLoadingMethodsInformationMissing);
  const isShipmentWaitingApproval = useSelector(R.selectors.bookingWizard.getIsShipmentWaitingForApproval);

  return (
    <StepsHeaderComponent
      currentStep={currentStep}
      isCustomerOrg={isCustomerOrg}
      isSuppliersInformationMissing={isSuppliersInformationMissing}
      isShipmentWaitingApproval={isShipmentWaitingApproval}
      isOriginDoor={shipment?.getIsOriginDoor()}
      isDestinationDoor={shipment?.getIsDestinationDoor()}
    />
  );
};

export { StepsHeaderContainer as StepsHeader };
