import { components } from 'api-contracts/dev/planning-service/v1/schema';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import {
  ContainerNumberDTM,
  ShipmentTrackerDTM,
  UpdatedDatesRequestDTM, ShipmentEventDTM, TrackerScheduleDTM, ContainerDTM,
} from 'shipment-operations/models/dtm';
import {
  GetShipmentTrackerContainers,
  TGetTrackingContainerListResponse,
  TPutContainerRequest,
} from 'shipment-operations/models/contracts';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { DateDtm } from 'app-wrapper/models/dtm';

import {
  ContainerReeferTypes,
  ContainerTypesConst,
  ContainerUsualTypes,
  DepartureDetailsStatusEnum,
} from 'shipment-operations/constants';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';

export class ShipmentTrackerService {
  private base = '/tracking-service/api/v1/shipments';

  public getContainers = async (shipmentId: string) => {
    const response = await apiWorker.requestGet<GetShipmentTrackerContainers[]>(`/shipment-service/api/v1/shipments/${shipmentId}/tracking`);
    const items = response.data;

    const parsedResponse = items.map((item) => {
      const events = item.container.events.map((event) => ShipmentEventDTM.fromPlain({
        id: uuidv4(),
        planId: item.planId,
        location: {
          id: event.location.id,
          address: event.location.address,
          country: {
            code: event.location.country.code,
            name: event.location.country.name,
          },
          state: {
            code: event.location.state?.code,
            name: event.location.state?.name,
          },
          code: event.location.code,
          name: event.location.name,
          city: event.location.city,
          coordinates: {
            lat: event.location?.coordinates?.lat,
            lng: event.location?.coordinates?.lng,
          },
          timeZoneId: event.location?.timeZoneId,
          type: event.location.type,
          placeId: event.location.placeId,
        },
        transport: {
          number: event.transport?.number,
          name: event.transport?.name,
          type: event.transport?.type,
        },
        estimated: (event.estimated ? DateDtm.fromPlain({
          date: event.estimated,
          offset: moment.parseZone(event.estimated).utcOffset(),
        }) : undefined),
        actual: (event.actual ? DateDtm.fromPlain({
          date: event.actual,
          offset: moment.parseZone(event.actual).utcOffset(),
        }) : undefined),
        predicted: (event.predicted ? DateDtm.fromPlain({
          date: event.predicted,
          offset: moment.parseZone(event.predicted).utcOffset(),
        }) : undefined),
        code: event?.code,
        voyageCode: null,
      }));

      const parsedItem = ShipmentTrackerDTM.fromPlain({
        planId: item.planId,
        container: {
          id: item.container.id,
          type: ContainerTypesConst[item.container.type as keyof typeof ContainerTypesConst],
          number: item.container.number,
          events,
        },
      });
      if (!parsedItem.isValid()) {
        console.error('Data from API does not match with contract');
      }
      return parsedItem;
    });
    const result = parsedResponse.filter((el) => el !== null) as ShipmentTrackerDTM[];

    return result;
  }

  public getContainersNew = async (shipmentId: string) => {
    let list: ContainerNumberDTM[] = [];

    try {
      const rawResponse = await apiWorker.requestGet<TGetTrackingContainerListResponse>(`${this.base}/${shipmentId}/containers`);

      const parsedResponse = rawResponse.data.map((item) => {
        const parsedItem = ContainerNumberDTM.fromPlain({
          number: item.number,
          type: item.type as ContainerReeferTypes | ContainerUsualTypes,
          typeDescription: item.typeDescription,
          registeredAt: moment(item.registeredAt),
          events: item.events.map((event) => ({
            id: event.id,
            type: event.type,
            time: event.time ? DateDtm.fromPlain({
              date: event.time,
              offset: moment.parseZone(event.time).utcOffset(),
            }) : undefined,
            createdAt: event.createdAt,
            place: {
              city: event.place.city,
              country: event.place.country,
              code: event.place.code,
              countryCode: event.place.countryCode,
            },
          })),
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      list = parsedResponse.filter((el) => el !== null) as ContainerNumberDTM[];
    } catch (e) {
      throw new Error('ShipmentTrackerService.getContainersNew error');
    }

    return list;
  }

  // TODO: Reimplement logic to use plans service
  // TODO: type it
  // TODO: fix contract
  public getSchedules = async (planId: number) => {
    let result: TrackerScheduleDTM[] = [];
    const responseRaw = await apiWorker.requestGetBySchema(`/planning-service/api/v1/plans?ids=${planId}` as '/api/v1/plans');
    const response = responseRaw.data;

    if (response && response[0].transportations.length === 0) {
      return result;
    }

    result = response[0].transportations.map((item) => {
      const schedule = item.schedule as components['schemas']['OceanSchedule'];

      return TrackerScheduleDTM.fromPlain({
        cyAvailable: schedule?.carrierCyAvailable ? schedule.carrierCyAvailable.slice(0, 19) : undefined,
        ctoAvailable: schedule?.carrierCtoAvailable ? schedule.carrierCtoAvailable.slice(0, 19) : undefined,
        terminalCutOff: schedule.terminalCutOff ? schedule.terminalCutOff.slice(0, 19) : undefined,
        vgmCutOff: schedule.vgmCutOff ? schedule.vgmCutOff.slice(0, 19) : undefined,
        documentCutOff: schedule.documentCutOff ? schedule.documentCutOff.slice(0, 19) : undefined,
      });
    });
    return result;
  }

  public updateDates = async (dates: UpdatedDatesRequestDTM[]) => {
    try {
      await apiWorker.requestPost(
        '/planning-service/api/v1/events',
        dates,
      );

      return true;
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
  }

  public updateTransportPlan = async (shipmentId: string, transportPlan: any) => {
    await apiWorker.requestPost(
      `/shipment-service/api/v1/shipments/${shipmentId}/booking/transportations/update`,
      transportPlan,
    );
    return true;
  }

  public updateContainer = async (shipmentId: string, container: ContainerDTM) => {
    const requestBody: TPutContainerRequest = {
      type: container.type,
      number: container.number ? container.number : null,
      sealNumber: container.sealNumber,
      ownContainer: container.ownContainer,
      rateId: +container.rateId,
      planId: container.planId,
      cargoItems: container.cargoItems.map((item) => ({
        id: +item.id,
        cargoId: +item.cargoId,
        packagesNumber: +item.packagesNumber,
        weight: +item.weight,
        volume: +item.volume,
      })),
      references: container.references.map((item) => ({
        id: +item.id,
        type: item.type,
        value: item.value,
      })),
      estimatedVolume: +container.estimatedVolume,
      estimatedWeight: +container.estimatedWeight,
      departureDetails: container?.departureDetails?.map((detail) => ({
        ...detail,
        id: detail.id || undefined,
        drayageSide: detail.drayageSide as keyof typeof EDrayageSide,
        arrivalDate: detail.arrivalDate?.getBackendFormatWithOffset(),
        departureDate: detail.departureDate?.getBackendFormatWithOffset(),
        status: detail.status as keyof typeof DepartureDetailsStatusEnum,
      })),
      seaworthyCertificate: container.seaworthyCertificate ? container.seaworthyCertificate.response : null,
      vgm: container.vgm ? {
        status: container.vgm.status,
        method: container.vgm.method,
        value: container.vgm.value,
      } : null,
      railBilling: container.railBilling ? {
        status: container.railBilling.status,
      } : null,
    };
    // fix put params
    // @ts-ignore
    await apiWorker.requestPutBySchema(`shipment-service/api/v1/shipments/${shipmentId}/containers/${container.id}` as '/api/v1/shipments/{shipmentId}/containers/{containerId}', requestBody);
    return true;
  }
}
