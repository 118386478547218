import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';
import { CustomModal } from 'shipment-operations/view/components';
import { EmptyStateForPages } from 'app-wrapper/view/components';
import {
  Content,
  Spinner,
  EmptyImage,
} from 'shipment-operations/view/components/RollShipmentDrawer/RollShipmentDrawer.styled';
import noContentSRC from 'shipment-operations/view/components/RollShipmentDrawer/no-content.png';

import {
  RollOption,
  Footer,
  Header,
} from './components';

interface RollShipmentDrawerComponentProps {
  isLoading: boolean;
  onClose: () => void;
  init: (shipmentId: string) => void;
  onClear: () => void;
  shipmentId: string;
  schedules: FreightQuotaContentSchedulesDTM[];
  isChangeQuotaMode: boolean;
}

export const ChangeBookingScheduleDrawerComponent: FC<RollShipmentDrawerComponentProps> = ({
  isLoading,
  onClose,
  onClear,
  shipmentId,
  init,
  schedules,
  isChangeQuotaMode,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init(shipmentId);

    return () => {
      onClear();
    };
  }, [shipmentId]);

  return (
    <CustomModal
      onClose={onClose}
      title={isChangeQuotaMode ? t('Change Quota') : t('Change Schedule')}
      contentStyles={{
        left: 'unset',
        width: '1000px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Content>
            <Header />

            {schedules.length ? (
              <>
                {schedules.map((schedule) => (
                  <RollOption key={schedule.id} scheduleId={Number(schedule.id)} />
                ))}
              </>
            ) : (
              <EmptyStateForPages
                primaryIcon={<EmptyImage src={noContentSRC} alt="" />}
                headerText={isChangeQuotaMode ? t('Unfortunately, there are no available rates for this type of container at the moment.') : t('Unfortunately, there are no other schedules available at this moment.')}
              />
            )}
          </Content>

          <Footer />
        </>
      )}
    </CustomModal>
  );
};
