import styled from 'styled-components';

import { BodyTextRegular } from 'app-wrapper/view/fonts';
import {
  FlexRow,
  FlexCenter,
  FlexCol,
} from 'app-wrapper/view/guideline';
import { HiddenScrollbar } from 'app-wrapper/view/styles/mixins';

export const StepContent = styled(FlexCol)`
  ${HiddenScrollbar}
  margin-top: 24px;
  gap: 10px;
  align-items: center;
  overflow: scroll;
  height: 100%;
  padding-bottom: 160px;
`;

export const Toggle = styled(FlexRow)``;

export const ToggleOption = styled(FlexCenter)`
  ${BodyTextRegular}
  width: 200px;
  height: 38px;
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding6};
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
  transition: all .2s ease-in;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding6};
    color: rgba(32, 44, 60, 1);
  }
`;
