import React, {
  createContext, ReactNode, useContext, useEffect, useMemo,
} from 'react';
import { BaseController, ControllerParams } from 'proto/BaseController';
import { ControllerStore } from 'proto/BaseMobxStore';
import { ReactReduxContext } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { useRepositories } from 'app-wrapper/view/repositories';

export const ControllerContext = createContext<BaseController | undefined>(undefined);
export const ControllerStoresContext = createContext<ControllerStore<any> | undefined>(undefined);

export const useCurrentController = <T extends BaseController>() => useContext(ControllerContext) as T;
export const useCurrentControllerStore = <T extends ControllerStore<any>>() => useContext(ControllerStoresContext) as T;

export const PageComponent = <CurrentStore extends ControllerStore<any>>({
  controller, ControllerClass, StoreFactory, children, component,
}: {
  controller?: BaseController
  ControllerClass?: typeof BaseController<CurrentStore>
  StoreFactory?: () => CurrentStore
  children?: ReactNode | ReactNode[]
  component?: ReactNode | ReactNode[]
}) => {
  const { store } = useContext(ReactReduxContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const mobxStores = useMobxStore();
  const repositories = useRepositories();

  const instanceStore = useMemo(() => StoreFactory && StoreFactory(), []);

  const controllerParams: ControllerParams<CurrentStore> = {
    store, mobxStores, navigate, params, location, repositories, currentStore: instanceStore as CurrentStore,
  };

  const instanceController = useMemo(() => ControllerClass && new ControllerClass(controllerParams), []);

  useEffect(() => {
    (controller || instanceController)?.onLoadedPage();
    return () => {
      (controller || instanceController)?.onClosePage();
    };
  }, [controller, instanceController]);

  return (
    <ControllerContext.Provider value={instanceController}>
      <ControllerStoresContext.Provider value={instanceStore}>
        {children || component}
      </ControllerStoresContext.Provider>
    </ControllerContext.Provider>
  );
};

export const Page = (controller: BaseController, component: React.ReactNode) => (
  <PageComponent controller={controller}>
    {component}
  </PageComponent>
);
