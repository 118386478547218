import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './VerticalLongDashedSvg.styled';

export const VerticalLongDashedSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="VerticalLongDashedSvg"
      width={width || '25'}
      height={height || '2'}
      viewBox="0 0 25 2"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24.75" height="1" fill="currentColor" />
    </Icon>
  );
};
