import styled from 'styled-components';

import { SubPageContent } from 'app-wrapper/view/guideline';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/components/ShipmentTrackerHeader/ShipmentTrackerHeader.styled';

export const Drawer = styled(ShipmentDrawerWrapper)`
  button {
    height: 32px !important;
    min-height: 32px !important;
  }
`;

export const Content = styled(SubPageContent)`
  gap: 8px;
`;
