import React, {
  FC, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { ShippingPartyAddressDTM, AddressDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  SelectStyled, SelectStyledFooter, SelectStyledFooterText,
} from './ShippingPartiesAddressLine.syled';

interface IShippingPartiesAddressLineInputComponentProps {
  addressList: AddressDTM[]
  availability?: PermissionAttributePolicy
  selectedAddress?: ShippingPartyAddressDTM
  selectedCurrentAddress?: ShippingPartyAddressDTM
  addressError?: Error
  setAddress: (addressId: AddressDTM['id']) => void
  onBlur: () => void
  openAddAddressDrawer?: () => void
  isSelectedCompanyRelatesToUser: boolean
}

export const ShippingPartiesAddressLineComponent: FC<IShippingPartiesAddressLineInputComponentProps> = ({
  addressList,
  availability,
  selectedAddress,
  selectedCurrentAddress,
  addressError,
  setAddress,
  onBlur,
  openAddAddressDrawer,
  isSelectedCompanyRelatesToUser,
}) => {
  const isError = addressError !== undefined;
  const selectValue = selectedCurrentAddress === selectedAddress ? selectedCurrentAddress?.id : selectedAddress?.id;

  const { t } = useTranslation();
  const isDisabled = useMemo(() => availability !== PermissionAttributePolicy.WRITE, [availability]);

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}

      {openAddAddressDrawer ? (
        <SelectStyledFooter onClick={openAddAddressDrawer}>
          <PlusSvg
            style={{ color: '#6C79DE' }}
            height={12}
            width={12}
          />
          <SelectStyledFooterText>{t('Add Address')}</SelectStyledFooterText>
        </SelectStyledFooter>
      ) : null}
    </>
  ), [openAddAddressDrawer]);

  return (
    <SelectStyled
      showSearch
      placeholder={t('Select Address')}
      optionFilterProp="children"
      disabled={isDisabled || !isSelectedCompanyRelatesToUser}
      value={selectValue}
      error={isError}
      aria-errormessage={addressError?.message}
      onChange={setAddress}
      onBlur={onBlur}
      dropdownRender={dropdownRender}
    >
      {
        addressList.map((address) => (
          <>
            <Option key={address.id} value={address.id}>{address.getAddressLine()}</Option>
          </>
        ))
      }
    </SelectStyled>
  );
};
