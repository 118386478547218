import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FooterComponent } from './Footer.component';

const FooterContainer = React.memo(() => {
  const isLoading = useSelector(R.selectors.changeBookingSchedule.getIsLoading);
  const isChangeQuotaMode = useSelector(R.selectors.changeBookingSchedule.getIsChangeQuotaMode);

  return (
    <FooterComponent
      isLoading={isLoading}
      changeBookingSchedule={UC.changeBookingScheduleDrawer.submitChangeBookingSchedule}
      isChangeQuotaMode={isChangeQuotaMode}
    />
  );
});

export { FooterContainer as Footer };
