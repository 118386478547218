import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ICountryListDTM } from 'app-wrapper/models/dtm';
import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  OrganizationAddressData,
  OrganizationAddressHeader,
  OrganizationAddressIconBG,
  OrganizationAddressItem,
  OrganizationAddressRow,
  OrganizationAddressTitle,
  OrganizationAddressValue,
  OrganizationAddressWrapper,
} from './OrganizationAddress.styled';

interface IOrganizationAddressComponentProps {
  organization?: OrganizationDTM
  countries: ICountryListDTM[];
}

export const OrganizationAddressComponent: FC<IOrganizationAddressComponentProps> = ({
  organization,
  countries,
}) => {
  const { t } = useTranslation();

  const fullCountryName = useMemo(() => {
    if (!organization?.address) {
      return undefined;
    }

    const { country } = organization.address;
    const targetCountry = countries.find(({ code }) => code === country);

    return targetCountry?.name;
  }, [countries, organization]);

  const addressValue = useMemo(() => organization && OrganizationDTM.fromPlain(organization)?.getAddressLine(fullCountryName), [organization]);

  return (
    <OrganizationAddressWrapper>
      <OrganizationAddressHeader>{t('Address')}</OrganizationAddressHeader>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <EnvironmentOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Address #1')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{addressValue}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
        {/* temporarily hidden */}
        {false && (
          <OrganizationAddressItem>
            <OrganizationAddressIconBG>
              <BorderlessTableOutlined />
            </OrganizationAddressIconBG>
            <OrganizationAddressData>
              <OrganizationAddressTitle>{t('Address #2')}</OrganizationAddressTitle>
              <OrganizationAddressValue>
                <NoDataLineItem />
              </OrganizationAddressValue>
            </OrganizationAddressData>
          </OrganizationAddressItem>
        )}
      </OrganizationAddressRow>
    </OrganizationAddressWrapper>
  );
};
