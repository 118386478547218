import React, { FC } from 'react';
import { Tooltip } from 'app-wrapper/view/components/Tooltip';
import omit from 'lodash/fp/omit';
import { TextAreaProps } from 'antd/es/input';
import { BaseValidationError } from 'proto/BaseValidation';
import { TextAreaWithErrorStyled } from './TextAreaWithError.styled';

export interface TextAreaWithErrorProps extends TextAreaProps{
  errorMessage?: BaseValidationError | string | null
  hasError?: boolean,
  'data-class'?: string,
}

export const TextAreaWithError: FC<TextAreaWithErrorProps> = (props) => {
  const { errorMessage } = props;
  return (
    <Tooltip mode="danger" title={errorMessage} visible={Boolean(errorMessage)}>
      <TextAreaWithErrorStyled
        {...omit(['errorMessage'], props)}
      />
    </Tooltip>
  );
};
