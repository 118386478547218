import i18n from 'app-wrapper/i18n/i18n';

export enum DepartureDetailsStatusEnum {
  UNASSIGNED = 'UNASSIGNED',
  DISPATCHED = 'DISPATCHED',
}

export const DepartureDetailsStatusTranslations = {
  [DepartureDetailsStatusEnum.UNASSIGNED]: i18n.t('Unassigned'),
  [DepartureDetailsStatusEnum.DISPATCHED]: i18n.t('Dispatched'),
};
