import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  TitleRow,
  TitleRowText,
  SubTitleRow,
  TransitWrap,
  TransitItem,
  TransitItemText,
  TransitStorageIconWrap,
  TransitStorageIcon,
  TransitShipIcon,
  TransitItemDivider,
  ScheduleWrap,
  ScheduleText,
  ScheduleTextGrey,
} from './Header.styled';

interface HeaderComponentProps {
  originDate: string;
  destinationDate: string;
  destinationLocationName: string;
  originLocationName: string;
  totalCost: number;
  hideTotalCost?: boolean;
  carrierName?: string;
  isOriginDoor?: boolean;
  isDestinationDoor?: boolean;
}

export const HeaderComponent: FC<HeaderComponentProps> = ({
  originDate,
  destinationDate,
  destinationLocationName,
  originLocationName,
  totalCost,
  hideTotalCost,
  carrierName,
  isOriginDoor,
  isDestinationDoor,
}) => {
  const { t } = useTranslation();

  return (
    <Header>
      <TitleRow>
        {carrierName ? (
          <TitleRowText>
            {carrierName}
          </TitleRowText>
        ) : null}

        {!hideTotalCost ? (
          <TitleRowText data-testid="roll-drawer-total-cost-testid">
            {t('$ ')}
            {totalCost.toFixed(2)}
          </TitleRowText>
        ) : null}
      </TitleRow>

      <SubTitleRow>
        <TransitWrap>
          <TransitItem>
            {isOriginDoor ? (
              <TransitStorageIconWrap>
                <TransitStorageIcon />
              </TransitStorageIconWrap>
            ) : (
              <TransitShipIcon />
            )}

            <TransitItemText>
              {originLocationName}
            </TransitItemText>
          </TransitItem>

          <TransitItemDivider />

          <TransitItem>
            {isDestinationDoor ? (
              <TransitStorageIconWrap>
                <TransitStorageIcon />
              </TransitStorageIconWrap>
            ) : (
              <TransitShipIcon />
            )}

            <TransitItemText>
              {destinationLocationName}
            </TransitItemText>
          </TransitItem>
        </TransitWrap>

        <ScheduleWrap>
          <ScheduleTextGrey>
            {t('Current Schedule')}
          </ScheduleTextGrey>

          <ScheduleText>
            {t('ETD')}
            {' '}
            {originDate}
            {' '}
            -
            {' '}
            {t('ETA')}
            {' '}
            {destinationDate}
          </ScheduleText>
        </ScheduleWrap>
      </SubTitleRow>
    </Header>
  );
};
