import {
  IsBoolean, IsDefined, IsNumber, IsOptional, IsString, ValidateNested, IsArray, IsEnum,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShortChargeStatusEnum, EContractCategory, ChargeCodeMeasureBy } from 'shipment-operations/constants';
import { ChargeCodeDTM, IChargeCodeDTM } from 'shipment-operations/models/dtm';
import i18n from 'app-wrapper/i18n/i18n';

export interface IApplianceRangeDTM {
  minValue?: number
  maxValue?: number
}

export class ApplianceRangeDTM extends BaseDTM<IApplianceRangeDTM> {
  @IsNumber()
  @IsOptional()
  minValue: number;

  @IsNumber()
  @IsOptional()
  maxValue: number;
}

export interface IChargesContainerDTM {
  id?: number
  type: string
  number?: string
  originalType?: string
}

export class ChargesContainerDTM extends BaseDTM<IChargesContainerDTM> {
  @IsNumber()
  @IsOptional()
  id: number;

  @IsString()
  @IsOptional()
  type: string;

  @IsString()
  @IsOptional()
  number: string;

  @IsString()
  @IsOptional()
  originalType?: string;
}

export interface IBudgetDTM {
  balance: number
  costPerUnit: number
  numberOfUnits: number
  totalCost: number
}

export class BudgetDTM extends BaseDTM<IBudgetDTM> {
  @IsNumber()
  @IsDefined()
  balance: number;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;
}

export interface IChangeHistory {
  id: number
  createdAt: string
  createdBy: string
  description?: string
  document: string
  costPerUnit: number
  numberOfUnits: number
  totalCost: number
}

export class ChangeHistoryDTM implements IChangeHistory {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  createdBy: string;

  @IsString()
  @IsOptional()
  description: string;

  @IsString()
  @IsDefined()
  document: string;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;
}

export interface IMetaData {
  originalDescription?: string
}

export class MetaDataDTM implements IMetaData {
  @IsString()
  @IsOptional()
  originalDescription?: string;
}

export interface IContractDTM {
  id: number
  number: string
  name: string
  scac: string
  category?: `${EContractCategory}`;
}

export class ContractDTM extends BaseDTM<IContractDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  name: string;

  @IsString()
  @IsDefined()
  scac: string;

  @IsOptional()
  @IsEnum(EContractCategory)
  category?: EContractCategory;
}

export interface IChargeCompanyDTM {
  id: number
  organizationId?: number
  name?: string
  phone?: string
  phone2?: string
  email?: string
  usci?: string
  taxId?: string
}

export class ChargeCompanyDTM extends BaseDTM<IChargeCompanyDTM> {
  @IsNumber()
  id: number;

  @IsNumber()
  @IsOptional()
  organizationId?: number;

  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsOptional()
  phone2?: string;

  @IsString()
  @IsOptional()
  email?: string;

  @IsString()
  @IsOptional()
  usci?: string;

  @IsString()
  @IsOptional()
  taxId?: string;
}

interface IChargeDocumentDTM {
  id: number
  name: string
}

export class ChargeDocumentDTM extends BaseDTM<IChargeDocumentDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  name: string
}

interface IChargeVersionsDTM {
  id: number
  active: boolean
  createdAt: string
  createdBy: string
  description?: string
  designation: string
  measureBy: string
  applied: boolean
  currency: string
  documents?: IChargeDocumentDTM[]
  costPerUnit: number
  numberOfUnits: number
  totalCost: number
  removed: boolean
}

export class ChargeVersionsDTM extends BaseDTM<IChargeVersionsDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsBoolean()
  @IsDefined()
  active: boolean;

  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  createdBy: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsDefined()
  designation: string;

  @IsString()
  @IsDefined()
  measureBy: string;

  @IsBoolean()
  @IsDefined()
  applied: boolean;

  @IsString()
  @IsDefined()
  currency: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeDocumentDTM)
  documents?: ChargeDocumentDTM[];

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;

  @IsBoolean()
  @IsDefined()
  removed: boolean;
}

export interface IChargeHistoryViewDTM {
  createdAt: string
  createdBy: string
  description?: string
  documents: IChargeDocumentDTM[]
  type: string
  action: string
  deltaCostPerUnit: number
  deltaNumberOfUnits: number
  deltaTotalCost: number
  costPerUnit: number
  numberOfUnits: number
  totalCost: number
}

export class ChargeHistoryViewDTM extends BaseDTM<IChargeHistoryViewDTM> {
  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  createdBy: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsArray()
  @IsDefined()
  @ValidateNested()
  @Type(() => ChargeDocumentDTM)
  documents: ChargeDocumentDTM[];

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  action: string;

  @IsNumber()
  @IsDefined()
  deltaCostPerUnit: number;

  @IsNumber()
  @IsDefined()
  deltaNumberOfUnits: number;

  @IsNumber()
  @IsDefined()
  deltaTotalCost: number;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;
}

export interface IChargeDTM {
  id: number
  active: boolean
  additional?: boolean
  status?: string
  createdAt: string
  createdBy: string
  designation: string
  priceBy: string
  measureBy: string
  chargeCode: IChargeCodeDTM
  applianceRange?: IApplianceRangeDTM
  subjectTo?: string
  applied: boolean
  currency: string
  costPerUnit: number
  numberOfUnits: number
  buyCostPerUnit: number
  totalCost: number
  buyTotalCost: number
  buyNumberOfUnits: number
  profitAmount: number
  apPaymentTerms?: string;
  arPaymentTerms?: string;
  profitPercent: number
  container?: IChargesContainerDTM
  contract?: IContractDTM
  transportationIds: number[]
  rateId?: number
  changeHistory?: IChangeHistory[]
  apBudget?: IBudgetDTM
  arBudget?: IBudgetDTM
  description?: string
  apStatus?: ShortChargeStatusEnum
  arStatus?: ShortChargeStatusEnum
  customAppliance?: string
  metadata?: IMetaData
  debtor?: IChargeCompanyDTM
  creditor?: IChargeCompanyDTM
  documents?: IChargeDocumentDTM[]
  apVersions?: IChargeVersionsDTM[]
  arVersions?: IChargeVersionsDTM[]
}

export class ChargeDTM extends BaseDTM<IChargeDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsBoolean()
  @IsDefined()
  active: boolean;

  @IsOptional()
  @IsBoolean()
  additional?: boolean;

  @IsString()
  @IsOptional()
  status?: string;

  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  createdBy: string;

  @IsString()
  @IsDefined()
  designation: string;

  @IsString()
  @IsDefined()
  priceBy: string;

  @IsString()
  @IsDefined()
  measureBy: string;

  @ValidateNested()
  @Type(() => ChargeCodeDTM)
  chargeCode: ChargeCodeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ApplianceRangeDTM)
  applianceRange?: ApplianceRangeDTM;

  @IsString()
  @IsOptional()
  subjectTo?: string;

  @IsBoolean()
  @IsDefined()
  applied: boolean;

  @IsString()
  @IsDefined()
  currency: string;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsOptional()
  @IsString()
  apPaymentTerms?: string;

  @IsOptional()
  @IsString()
  arPaymentTerms?: string;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  buyCostPerUnit: number;

  @IsNumber()
  @IsDefined()
  buyNumberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;

  @IsNumber()
  @IsDefined()
  buyTotalCost: number;

  @IsNumber()
  @IsDefined()
  profitAmount: number;

  @IsNumber()
  @IsDefined()
  profitPercent: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargesContainerDTM)
  container: ChargesContainerDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContractDTM)
  contract?: ContractDTM;

  @IsArray()
  @IsNumber({}, { each: true })
  @IsDefined()
  transportationIds: number[];

  @IsNumber()
  @IsOptional()
  rateId?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChangeHistoryDTM)
  changeHistory?: ChangeHistoryDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => BudgetDTM)
  apBudget?: BudgetDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => BudgetDTM)
  arBudget?: BudgetDTM;

  @IsOptional()
  @IsString()
  description?: string;

  @IsEnum(ShortChargeStatusEnum)
  @IsOptional()
  arStatus?: ShortChargeStatusEnum;

  @IsEnum(ShortChargeStatusEnum)
  @IsOptional()
  apStatus?: ShortChargeStatusEnum;

  @IsOptional()
  @IsString()
  customAppliance?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => MetaDataDTM)
  metadata?: MetaDataDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCompanyDTM)
  debtor?: ChargeCompanyDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCompanyDTM)
  creditor?: ChargeCompanyDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeDocumentDTM)
  documents?: ChargeDocumentDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeVersionsDTM)
  apVersions?: ChargeVersionsDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeVersionsDTM)
  arVersions?: ChargeVersionsDTM[];

  getEnumeratorValue = () => {
    if (this.measureBy === ChargeCodeMeasureBy.MILE
      && (this?.apBudget?.numberOfUnits || 0) > 1
    ) {
      return ` (${this?.apBudget?.numberOfUnits} ${i18n.t('measureBy.miles')})`;
    }

    return '';
  }
}

export interface IChargeViewDtm {
  active?: boolean
  key: number
  designation?: string
  description?: string
  type?: string
  apBudget: number
  apTotalCost: number
  arBudget: number
  arTotalCost: number
  profit: number
  profitPercentage?: number | string
  posted?: ShortChargeStatusEnum
  invoiced?: ShortChargeStatusEnum
  unitType?: string
  buyCostPerUnit?: number
  buyQuantity?: number
  buyTotalCost?: number
  costPerUnit?: number
  quantity?: number
  totalCost?: number
  history?: IChargeVersionsDTM[]
  includedCharges?: string[]
}

export class ChargeViewDtm extends BaseDTM<IChargeViewDtm> {
  @IsBoolean()
  @IsOptional()
  active?: boolean

  @IsNumber()
  @IsDefined()
  key: number;

  @IsString()
  @IsOptional()
  designation?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsOptional()
  @IsString()
  type?: string;

  @IsNumber()
  @IsDefined()
  apBudget: number;

  @IsNumber()
  @IsDefined()
  apTotalCost: number;

  @IsNumber()
  @IsDefined()
  arBudget: number;

  @IsNumber()
  @IsDefined()
  arTotalCost: number;

  @IsNumber()
  @IsDefined()
  profit: number;

  @IsOptional()
  profitPercentage?: number | string;

  @IsEnum(ShortChargeStatusEnum)
  @IsOptional()
  posted?: ShortChargeStatusEnum

  @IsEnum(ShortChargeStatusEnum)
  @IsOptional()
  invoiced?: ShortChargeStatusEnum

  @IsOptional()
  @IsString()
  unitType?: string;

  @IsNumber()
  @IsOptional()
  buyCostPerUnit?: number;

  @IsNumber()
  @IsOptional()
  buyQuantity?: number;

  @IsNumber()
  @IsOptional()
  buyTotalCost?: number;

  @IsNumber()
  @IsOptional()
  costPerUnit?: number;

  @IsNumber()
  @IsOptional()
  quantity?: number;

  @IsNumber()
  @IsOptional()
  totalCost?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeVersionsDTM)
  history?: ChargeVersionsDTM[];

  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  includedCharges?: string[];
}

export interface IChargeTableDTM {
  key: number
  type: string
  APPerUnit: string
  APTotal: string
  ARPerUnit: string
  ARTotal: number
  profit: number
}

interface IFullChargeTableDTM {
  key: number
  type: string
  APPerUnit: string
  APTotal: string
  ARPerUnit: string
  ARTotal: number
  profit: number
  posted: string
  invoiced: string
}

export class ChargeTableDTM extends BaseDTM<IChargeTableDTM> {
  @IsNumber()
  @IsDefined()
  key: number;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  APPerUnit: string;

  @IsString()
  @IsDefined()
  APTotal: string;

  @IsString()
  @IsDefined()
  ARPerUnit: string;

  @IsNumber()
  @IsDefined()
  ARTotal: number;

  @IsNumber()
  @IsDefined()
  profit: number;
}

export class FullChargeTableDTM extends BaseDTM<IFullChargeTableDTM> {
  @IsNumber()
  @IsDefined()
  key: number;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  APPerUnit: string;

  @IsString()
  @IsDefined()
  APTotal: string;

  @IsString()
  @IsDefined()
  ARPerUnit: string;

  @IsNumber()
  @IsDefined()
  ARTotal: number;

  @IsNumber()
  @IsDefined()
  profit: number;

  @IsString()
  @IsDefined()
  posted: string;

  @IsString()
  @IsDefined()
  invoiced: string;
}

export interface IChargeContainerDTM {
  id: number
  type?: string
  number?: string
}

export class ChargeContainerDTM extends BaseDTM<IChargeContainerDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsOptional()
  @IsString()
  type?: string

  @IsOptional()
  @IsString()
  number?: string
}

interface IChargeCompanyCreateDTM {
  id?: number
}

export class ChargeCompanyCreateDTM extends BaseDTM<IChargeCompanyCreateDTM> {
  @IsOptional()
  @IsString()
  id?: number
}

export interface ICreateChargeDTM {
  chargeCode?: IChargeCodeDTM
  applianceRange?: IApplianceRangeDTM
  additional?: boolean
  subjectTo: string | null
  applied: boolean
  currency: string
  costPerUnit?: number
  buyCostPerUnit?: number
  numberOfUnits?: number
  buyNumberOfUnits?: number
  buyTotalCost?: number
  totalCost?: number
  description?: string
  container: IChargeContainerDTM | null
  designation: string
  measureBy: string
  priceBy: string
  creditor?: IChargeCompanyCreateDTM
  debtor?: IChargeCompanyCreateDTM
  documents?: IChargeDocumentDTM[]
  rateId?: number
}

export class CreateChargeDTM extends BaseDTM<ICreateChargeDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCodeDTM)
  chargeCode: ChargeCodeDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ApplianceRangeDTM)
  applianceRange?: ApplianceRangeDTM

  @IsOptional()
  @IsBoolean()
  additional?: boolean

  @IsOptional()
  @IsString()
  subjectTo: string | null

  @IsDefined()
  @IsBoolean()
  applied: boolean

  @IsDefined()
  @IsString()
  currency: string

  @IsOptional()
  @IsNumber()
  costPerUnit?: number

  @IsOptional()
  @IsNumber()
  buyCostPerUnit?: number

  @IsOptional()
  @IsNumber()
  numberOfUnits?: number

  @IsOptional()
  @IsNumber()
  buyTotalCost?: number

  @IsOptional()
  @IsNumber()
  buyNumberOfUnits?: number

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeContainerDTM)
  container: ChargeContainerDTM

  @IsDefined()
  @IsString()
  designation: string

  @IsDefined()
  @IsString()
  measureBy: string

  @IsDefined()
  @IsString()
  priceBy: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCompanyCreateDTM)
  creditor?: ChargeCompanyCreateDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCompanyCreateDTM)
  debtor?: ChargeCompanyCreateDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeDocumentDTM)
  documents?: ChargeDocumentDTM[]

  @IsOptional()
  @IsNumber()
  rateId?: number
}

export interface IGroupedChargesDTM {
  [key: string]: ChargeDTM[]
}

export interface IEstimatedChargeDTM {
  chargeCode: IChargeCodeDTM
  subjectTo?: string
  priceBy: string
  rateId?: number
  metadata?: IMetaData
  transportationIds: number[]
  applianceRange?: IApplianceRangeDTM
  contract?: IContractDTM
  container?: IChargesContainerDTM
  designation: string
  measureBy: string
  applied: boolean
  currency: string
  originalCurrency?: string
  costPerUnit: number
  originalCostPerUnit?: number
  numberOfUnits: number
  totalCost: number
  originalTotalCost?: number
}

export class EstimatedChargeDTM extends BaseDTM<IEstimatedChargeDTM> {
  @ValidateNested()
  @Type(() => ChargeCodeDTM)
  chargeCode: ChargeCodeDTM;

  @IsOptional()
  @IsString()
  subjectTo?: string;

  @IsString()
  @IsDefined()
  priceBy: string;

  @IsNumber()
  @IsOptional()
  rateId?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => MetaDataDTM)
  metadata?: MetaDataDTM;

  @IsArray()
  @IsNumber({}, { each: true })
  @IsDefined()
  transportationIds: number[];

  @IsOptional()
  @ValidateNested()
  @Type(() => ApplianceRangeDTM)
  applianceRange?: ApplianceRangeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContractDTM)
  contract?: ContractDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargesContainerDTM)
  container?: ChargesContainerDTM;

  @IsString()
  @IsDefined()
  designation: string;

  @IsString()
  @IsDefined()
  measureBy: string;

  @IsBoolean()
  @IsDefined()
  applied: boolean;

  @IsString()
  @IsDefined()
  currency: string;

  @IsString()
  @IsOptional()
  originalCurrency?: string;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsOptional()
  originalCostPerUnit?: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;

  @IsNumber()
  @IsOptional()
  originalTotalCost?: number;

  static getCalculatedTotalCost = (charges: EstimatedChargeDTM[]) => charges
    .filter(({ applied }) => applied)
    .reduce((acc, item) => acc + (item.totalCost || 0), 0);
}
