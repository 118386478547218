import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Popover from 'antd/es/popover';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &:last-of-type {
    margin-left: auto;
  }
`;

export const Image = styled.img`
  width: 185px;
  height: auto;
  margin-right: 8px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Text = styled(Typography)`
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  color: rgba(115, 129, 155, 1);
`;

export const BookButton = styled(Button)`
  width: 118px;
  margin-top: 16px;
  height: 32px;
  font-size: 14px;
  line-height: 22px;
`;

export const Popup = styled(Popover)``;

export const PopupContent = styled.div`
  width: 236px;
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const WarningIcon = styled(ExclamationCircleFilled)`
  margin-top: 4px;
  color: rgba(250, 173, 20, 1);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PopupText = styled(Typography)`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
`;
