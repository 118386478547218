import React from 'react';
import {
  ContentSectionTitle,
  GapVerticalContainerStyled,
  SubPageContent,
  ContentGap,
  TableList,
  TableListHeader,
  TableListHeaderItem,
  TableListContent,
} from 'app-wrapper/view/guideline';
import { useTranslation } from 'react-i18next';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import { ServicesDivider } from 'app-wrapper/view/components';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import {
  DepartureContainer,
} from 'shipment-operations/view/components/TransportationDrayage/DepartureContainer';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';

interface DepartureContainerComponentProps {
  containers: ContainerDTM[]
  openEditDrawer: (id: number) => void
  drayageSide: EDrayageSide
  isAbleToEdit?: boolean
}

export const DepartureContainersComponent = ({
  openEditDrawer,
  containers,
  drayageSide,
  isAbleToEdit,
}: DepartureContainerComponentProps) => {
  const { t } = useTranslation();
  return (
    <SubPageContent>
      <GapVerticalContainerStyled width="100%">
        <ContentGap justifyContent="space-between">
          <ContentSectionTitle>
            {t(drayageSide === EDrayageSide.DESTITATION_DRAYAGE ? 'Arrival(s)' : 'Departure(s)')}
          </ContentSectionTitle>
        </ContentGap>
        <ContentSectionContainer>
          <ServicesDivider noMargin />
        </ContentSectionContainer>
        <ContentSectionContainer>
          <TableList>
            <TableListHeader>
              <TableListHeaderItem
                width="20%"
                align="left"
              >
                {t('Container ID')}
              </TableListHeaderItem>
              <TableListHeaderItem
                width="20%"
                align="left"
              >
                {t('Number')}
              </TableListHeaderItem>
              <TableListHeaderItem
                width="20%"
                align="left"
              >
                {t(drayageSide === EDrayageSide.DESTITATION_DRAYAGE ? 'Full Arrival' : 'Empty Arrival')}
              </TableListHeaderItem>
              <TableListHeaderItem
                width="20%"
                align="left"
              >
                {t(drayageSide === EDrayageSide.DESTITATION_DRAYAGE ? 'Empty Departure' : 'Full Departure')}
              </TableListHeaderItem>
              <TableListHeaderItem
                width="7%"
                align="left"
              >
                {t('Dispatch Order')}
              </TableListHeaderItem>
              <TableListHeaderItem
                width="8%"
                align="right"
              >
                {t('Status')}
              </TableListHeaderItem>
              <TableListHeaderItem
                width="5%"
                align="right"
              />
            </TableListHeader>

            <TableListContent id="CCContentTableContent">
              <GapVerticalContainerStyled width="100%">
                {containers.map((container) => (
                  <DepartureContainer
                    key={container.id}
                    container={container}
                    drayageSide={drayageSide}
                    openEditDrawer={openEditDrawer}
                    isAbleToEdit={isAbleToEdit}
                  />
                ))}
              </GapVerticalContainerStyled>
            </TableListContent>
          </TableList>
        </ContentSectionContainer>
      </GapVerticalContainerStyled>
    </SubPageContent>
  );
};
