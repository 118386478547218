import i18n from 'app-wrapper/i18n/i18n';
import { InputUseCase } from 'app-wrapper/usecases/Input.useCase';
import { PASSWORD_MIN_LENGTH } from 'authentication/consts/PasswordMinLength';

import { R } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';

import { RouteNames } from 'app-wrapper/constants';
import { validationEmail } from 'app-wrapper/utils';

@controller
export class SignUpController extends BaseController {
  get firstNameInputUseCase() {
    return new InputUseCase(
      this,
      R.actions.signUp.setFirstName,
      R.actions.signUp.setFirstNameError,
      this.validateFirstName,
    );
  }

  get lastNameInputUseCase() {
    return new InputUseCase(
      this,
      R.actions.signUp.setLastName,
      R.actions.signUp.setLastNameError,
      this.validateLastName,
    );
  }

  get emailInputUseCase() {
    return new InputUseCase(
      this,
      R.actions.signUp.setEmail,
      R.actions.signUp.setEmailError,
      this.validateEmail,
    );
  }

  get passwordInputUseCase() {
    return new InputUseCase(
      this,
      R.actions.signUp.setPassword,
      R.actions.signUp.setPasswordError,
      this.validatePassword,
    );
  }

  public async registerNewAccount() {
    const email = R.selectors.signUp.getEmail(this.store.getState());
    const password = R.selectors.signUp.getPassword(this.store.getState());
    const firstName = R.selectors.signUp.getFirstName(this.store.getState());
    const lastName = R.selectors.signUp.getLastName(this.store.getState());

    this.validateEmail();
    this.validatePassword();
    this.validateFirstName();
    this.validateLastName();

    if (!email || !password || !firstName || !lastName || !this.validatePassword()) {
      return;
    }

    this.dispatch(R.actions.signUp.setLoading(true));

    try {
      await R.services.auth.signUpAuth({
        firstName, lastName, email, password,
      });
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === 'PreSignUp failed with error Registration is allowed only from corporate email domains. Please use your company email to proceed..') {
          this.dispatch(R.actions.signUp.setRequestError(i18n.t('Please use your business email address.')));
        } else {
          this.dispatch(R.actions.signUp.setRequestError(e.message));
        }

        this.dispatch(R.actions.signUp.setLoading(false));

        return;
      }

      this.dispatch(R.actions.signUp.setLoading(false));

      return;
    }

    this.dispatch(R.actions.signUp.setLoading(false));

    this.dispatch(R.actions.signUpSuccess.setEmail(email));

    this.navigate(RouteNames.SIGN_UP_SUCCESS());
  }

  public setFirstName(value: string) {
    this.firstNameInputUseCase.onChange(value);
  }

  public onBlurFirstName() {
    this.firstNameInputUseCase.onBlur();
  }

  public setLastName(value: string) {
    this.lastNameInputUseCase.onChange(value);
  }

  public onBlurLastName() {
    this.lastNameInputUseCase.onBlur();
  }

  public setEmail(value: string) {
    this.emailInputUseCase.onChange(value);
  }

  public onBlurEmail() {
    this.emailInputUseCase.onBlur();
  }

  public setPassword(value: string) {
    this.passwordInputUseCase.onChange(value);
  }

  public onBlurPassword() {
    this.passwordInputUseCase.onBlur();
  }

  public onChangeField() {
    this.dispatch(R.actions.signUp.setRequestError(''));
  }

  public onClear() {
    this.dispatch(R.actions.signUp.clear());
  }

  private validateFirstName() {
    const firstName = R.selectors.signUp.getFirstName(this.store.getState());

    if (!firstName) {
      this.dispatch(R.actions.signUp.setFirstNameError({
        hasError: true,
        message: '',
      }));

      return false;
    }

    this.dispatch(R.actions.signUp.setFirstNameError({
      hasError: false,
      message: undefined,
    }));

    return true;
  }

  private validateLastName() {
    const lastName = R.selectors.signUp.getLastName(this.store.getState());

    if (!lastName) {
      this.dispatch(R.actions.signUp.setLastNameError({
        hasError: true,
        message: '',
      }));

      return false;
    }

    this.dispatch(R.actions.signUp.setLastNameError({
      hasError: false,
      message: undefined,
    }));

    return true;
  }

  private validateEmail() {
    const email = R.selectors.signUp.getEmail(this.store.getState());

    const { errorMessage } = validationEmail(email || '');

    if (errorMessage) {
      this.dispatch(R.actions.signUp.setEmailError({
        hasError: true,
        message: errorMessage,
      }));
      return false;
    }

    if (!email) {
      this.dispatch(R.actions.signUp.setEmailError({
        hasError: true,
        message: '',
      }));
      return false;
    }

    this.dispatch(R.actions.signUp.setEmailError({
      hasError: false,
      message: undefined,
    }));

    return true;
  }

  private validatePassword() {
    const newPassword = R.selectors.signUp.getPassword(this.store.getState());

    if (!newPassword) {
      this.dispatch(R.actions.signUp.setPasswordError({
        hasError: true,
        message: '',
      }));

      return false;
    }

    if (newPassword.length < PASSWORD_MIN_LENGTH) {
      this.dispatch(R.actions.signUp.setPasswordError({
        hasError: true,
        message: i18n.t('basicErrors.MIN_LENGTH', { count: PASSWORD_MIN_LENGTH }),
      }));

      return false;
    }

    this.dispatch(R.actions.signUp.setPasswordError({
      hasError: false,
      message: undefined,
    }));

    return true;
  }
}
