import i18n from 'i18next';

export enum ShipmentTransactionStatusEnum {
  EMPTY_OUTGATED = 'EMPTY_OUTGATED',
  ARRIVED_AT_PLACE_OF_RECEIPT = 'ARRIVED_AT_PLACE_OF_RECEIPT',
  DEPARTED_PLACE_OF_RECEIPT = 'DEPARTED_PLACE_OF_RECEIPT',
  ARRIVED_AT_PORT_OF_LOAD = 'ARRIVED_AT_PORT_OF_LOAD',
  LADEN_ON_BOARD = 'LADEN_ON_BOARD',
  ON_THE_WAY_TO_TRANSIT_PORT = 'ON_THE_WAY_TO_TRANSIT_PORT',
  ARRIVED_AT_TRANSIT_PORT = 'ARRIVED_AT_TRANSIT_PORT',
  ON_THE_WAY_TO_DISCHARGE_PORT = 'ON_THE_WAY_TO_DISCHARGE_PORT',
  ARRIVED_AT_DISCHARGE_PORT = 'ARRIVED_AT_DISCHARGE_PORT',
  ON_THE_WAY_TO_PLACE_OF_DELIVERY = 'ON_THE_WAY_TO_PLACE_OF_DELIVERY',
  ARRIVED_AT_PLACE_OF_DELIVERY = 'ARRIVED_AT_PLACE_OF_DELIVERY',
  RELEASED_FOR_DELIVERY = 'RELEASED_FOR_DELIVERY',
  FULL_OUTGATED = 'FULL_OUTGATED',
  RETURNED_EMPTY = 'RETURNED_EMPTY',
  ARRIVED_TO_SUPPLIER = 'ARRIVED_TO_SUPPLIER',
  DEPARTED_FROM_SUPPLIER = 'DEPARTED_FROM_SUPPLIER',
  ARRIVED_TO_RECEIVER = 'ARRIVED_TO_RECEIVER',
  DEPARTED_FROM_RECEIVER = 'DEPARTED_FROM_RECEIVER',
}

export const ShipmentTransactionStatusNames = {
  [ShipmentTransactionStatusEnum.EMPTY_OUTGATED]: i18n.t('Container(s) Outgated (empty)'),
  [ShipmentTransactionStatusEnum.ARRIVED_AT_PLACE_OF_RECEIPT]: i18n.t('Container(s) at Place of Receipt'),
  [ShipmentTransactionStatusEnum.DEPARTED_PLACE_OF_RECEIPT]: i18n.t('Departed Place of Receipt'),
  [ShipmentTransactionStatusEnum.ARRIVED_AT_PORT_OF_LOAD]: i18n.t('Arrived at Load Port'),
  [ShipmentTransactionStatusEnum.LADEN_ON_BOARD]: i18n.t('Laden on Board'),
  [ShipmentTransactionStatusEnum.ON_THE_WAY_TO_TRANSIT_PORT]: i18n.t('On the way to Transit Port'),
  [ShipmentTransactionStatusEnum.ARRIVED_AT_TRANSIT_PORT]: i18n.t('Arrived at Transit Port'),
  [ShipmentTransactionStatusEnum.ON_THE_WAY_TO_DISCHARGE_PORT]: i18n.t('On the way to Discharge Port'),
  [ShipmentTransactionStatusEnum.ARRIVED_AT_DISCHARGE_PORT]: i18n.t('Arrived at Discharge Port'),
  [ShipmentTransactionStatusEnum.ON_THE_WAY_TO_PLACE_OF_DELIVERY]: i18n.t('On the way to Place of Delivery'),
  [ShipmentTransactionStatusEnum.ARRIVED_AT_PLACE_OF_DELIVERY]: i18n.t('Arrived at Place of Delivery'),
  [ShipmentTransactionStatusEnum.RELEASED_FOR_DELIVERY]: i18n.t('Released for Delivery'),
  [ShipmentTransactionStatusEnum.FULL_OUTGATED]: i18n.t('Container(s) Outgated (full)'),
  [ShipmentTransactionStatusEnum.RETURNED_EMPTY]: i18n.t('Container(s) Returned Empty'),
  [ShipmentTransactionStatusEnum.ARRIVED_TO_SUPPLIER]: i18n.t('Arrived to Supplier'),
  [ShipmentTransactionStatusEnum.DEPARTED_FROM_SUPPLIER]: i18n.t('Departed from Supplier'),
  [ShipmentTransactionStatusEnum.ARRIVED_TO_RECEIVER]: i18n.t('Arrived to Receiver'),
  [ShipmentTransactionStatusEnum.DEPARTED_FROM_RECEIVER]: i18n.t('Departed from Receiver'),
};
