import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormValueInputComponent } from './CargoFormValueInput.component';

const CargoFormValueInputContainer: FC = () => {
  const {
    value,
    errors,
    wasUpdateAttempted,
  } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormValueInputComponent
      value={value}
      touchField={UC.cargo.touchField}
      setValue={UC.cargo.setValue}
      cargosAvailability={permissions.cargosAvailability}
      customerCargosAvailability={permissions.customerCargosAvailability}
      error={wasUpdateAttempted && !!errors.value?.message}
    />
  );
};

export {
  CargoFormValueInputContainer as CargoFormValueInput,
};
