import moment from 'moment';
import { BaseController, controller } from 'proto/BaseController';

import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';
import { R as monetaryR } from 'monetary/repository';
import {
  IPostCreateQuotaParamsQuotaCommoditiesRequest,
  postCreateQuotaParamsRequest,
} from 'monetary/models/contracts';
import {
  FreightQuotaContentContainerDTM,
  FreightQuotaContentDTM,
  FreightQuotaContentSchedulesDTM,
} from 'monetary/models/dtm';
import { QUOTAS_STATUS } from 'app-wrapper/constants';
import { IGetShipmentPlansResponse } from 'shipment-operations/models/contracts';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ELocationType } from 'app-wrapper/types/LocationType';
import { R as userManagementR } from 'user-management/repository';
import {
  CargoDTM,
  ChargeDTM,
  ContainerDTM,
  ContainerWithCargoDTM,
  PaymentTermsDTM,
  ShipmentPreviewDTM,
  TemperatureControlDTM,
  ShippingPartyDTM,
} from 'shipment-operations/models/dtm';
import { ECarrierSCAC, EFreightLoadType, ORGANIZATION } from 'monetary/constants';
import { EShippingPartyTypes, ShipmentFreightMode } from 'shipment-operations/constants';
import { IRFQQuotasDTM } from 'monetary/models/dtm/Quotas';
import { EOrganizationMemberRole } from 'user-management/constants';
import { MAX_QUOTE_REQUEST_TIMEOUT, QUOTE_REQUEST_STATUS_CHECK_INTERVAL } from 'shipment-operations/controllers/RollShipmentWizard/RollShipmentWizard.controller';

const MAX_QUOTAS_SIZE = '100';

@controller
export class ChangeBookingScheduleDrawerController extends BaseController {
  public closeDrawerDueToError = () => {
    this.dispatch(R.actions.changeBookingSchedule.setIsLoading(false));
    this.dispatch(R.actions.changeBookingSchedule.setIsDrawerOpened(false));
  };

  public getInitialRFQRequestData = async () => {
    this.dispatch(R.actions.changeBookingSchedule.setIsLoading(true));

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (!shipment?.id) {
      return;
    }

    const shipmentId = String(shipment.id);

    let shippingParties: ShippingPartyDTM[] = [];

    try {
      shippingParties = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getShippingParties');
    }

    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);

    if (!customer) {
      console.error('RollShipmentWizardController: customer is missing');
      this.closeDrawerDueToError();

      return;
    }

    let charges: ChargeDTM[] = [];

    try {
      charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, Number(customer?.company?.id)) || [];
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getCharges');
      this.closeDrawerDueToError();

      return;
    }

    const appliedActiveCharges = charges.filter(({ applied, active }) => applied && active);
    const shipmentAdditionalCharges = appliedActiveCharges.filter(({ additional }) => additional);
    const shipmentAdditionalChargesTotalCost = shipmentAdditionalCharges.reduce((acc, item) => acc + (item.buyTotalCost || 0), 0);

    this.dispatch(R.actions.changeBookingSchedule.setSavedCharges(charges));
    this.dispatch(R.actions.changeBookingSchedule.setShipmentAdditionalChargesTotalCost(+shipmentAdditionalChargesTotalCost.toFixed(2)));

    let paymentTerms: PaymentTermsDTM | null = null;

    try {
      paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getShipmentPaymentTerms');
      this.closeDrawerDueToError();

      return;
    }

    if (!paymentTerms) {
      this.closeDrawerDueToError();

      return;
    }

    this.dispatch(R.actions.changeBookingSchedule.setPaymentTerms(paymentTerms));

    let containers: ContainerDTM[] = [];
    let cargos: CargoDTM[];

    try {
      containers = await R.services.shipmentContainers.getContainersList(shipmentId);
      cargos = await R.services.cargo.getCargos(+shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getContainersList and getCargos');
      this.closeDrawerDueToError();

      return;
    }

    containers = [
      ...containers,
      ...R.selectors.bookingWizard.getNotSavedContainers(this.store.getState()),
    ];

    const commodities = cargos.map((cargo) => (cargo.isHazmat ? {
      code: cargo.code,
      description: cargo.description || cargo.name,
      imoClass: cargo.imoClass,
      unNumber: cargo.unNumber,
      value: cargo.value,
      name: cargo.name,
    } : {
      code: cargo.code,
      description: cargo.description || cargo.name,
      imoClass: cargo.imoClass,
      unNumber: null,
      value: cargo.value,
      name: cargo.name,
    })) as IPostCreateQuotaParamsQuotaCommoditiesRequest[];

    const containersWithCargos = containers.map((container) => (ContainerWithCargoDTM.fromPlain({
      ...container,
      cargoItems: container.cargoItems.map((cargoItem) => {
        const cargo = cargos.find(({ id = 0 }) => +cargoItem.cargoId === id) as CargoDTM;

        return {
          ...cargoItem,
          cargo,
        };
      }),
    })));

    if (!containersWithCargos.length) {
      this.closeDrawerDueToError();

      return;
    }

    let plans: IGetShipmentPlansResponse[] = [];

    if (containersWithCargos[0].planId) {
      try {
        plans = await R.services.shipmentPlans.getShipmentPlans(containersWithCargos[0].planId);
      } catch (e) {
        console.error('RollShipmentWizardController: getInitialRFQRequestData getShipmentPlans');
        this.closeDrawerDueToError();

        return;
      }
    }

    const plan = plans[0];

    if (!plan) {
      this.closeDrawerDueToError();

      return;
    }

    // fetching temperature control part

    let temperatureControl: TemperatureControlDTM | null = null;

    try {
      temperatureControl = await R.services.temperatureControl.getTemperatureControlData(shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getTemperatureControlData');
      this.closeDrawerDueToError();

      return;
    }

    const additionalServices = await R.services.additionalService.getAdditionalServices(shipmentId);
    const isChangeQuotaMode = R.selectors.changeBookingSchedule.getIsChangeQuotaMode(this.store.getState());
    const bookingWizardStep = R.selectors.bookingWizard.getCurrentStep(this.store.getState());

    if (shipment) {
      this.dispatch(R.actions.changeBookingSchedule.setShipment(shipment));
    }

    const exportSchedule = R.selectors.bookingWizard.getExportSchedule(this.store.getState());
    const cargoReadyDate = exportSchedule.cargoReadyDate?.getBackendFormatWithOffset();

    const body: postCreateQuotaParamsRequest = {
      isSelfServiceRequest: false,
      includeRelatedPorts: false,
      freightMode: ShipmentFreightMode.SEA,
      incoterm: paymentTerms.incoterm,
      loadType: EFreightLoadType.FCL,
      tradeType: paymentTerms.tradeType,
      customer: null,
      additionalServices: additionalServices.map((service) => ({
        code: service.code,
        phase: service.phase,
        quantity: service.quantity,
      })) as postCreateQuotaParamsRequest['additionalServices'],
      ...(shipment && !isChangeQuotaMode ? { oceanCarriers: [shipment.scac as ECarrierSCAC] } : {}),
      containerRequests: containersWithCargos.map((container) => {
        const { route } = plan;
        const { origin, destination } = route;
        const { weight, volume } = container.cargoItems.reduce((acc, item) => ({ weight: +acc.weight + +item.weight, volume: +acc.volume + +item.volume }), { weight: 0, volume: 0 });
        const isOriginDoor = shipment?.getIsOriginDoor();
        const isDestinationDoor = shipment?.getIsDestinationDoor();

        return {
          container: {
            commodities,
            ownContainer: container.ownContainer,
            temperatureControl: !!temperatureControl,
            type: container.type,
            volume: volume > 0 ? volume : 1,
            weight: weight > 0 ? weight : 1,
          },
          origin: {
            ...(isOriginDoor && cargoReadyDate ? {
              pickupTime: cargoReadyDate,
            } : {
              earliestDate: this.getContainerEarliestDateAsUTC(shipment),
              latestDate: this.getContainerLatestDateAsUTC(shipment),
            }),
            location: {
              ...(isOriginDoor ? {
                placeId: origin.placeId,
              } : {
                code: origin.code,
              }),
              type: isOriginDoor ? ELocationType.DOOR : ELocationType.PORT,
            },
            type: isOriginDoor ? ELocationType.DOOR : ELocationType.PORT,
          },
          destination: {
            location: {
              ...(isDestinationDoor ? {
                placeId: destination.placeId,
              } : {
                code: destination.code,
              }),
              type: isDestinationDoor ? ELocationType.DOOR : ELocationType.PORT,
            },
            type: isDestinationDoor ? ELocationType.DOOR : ELocationType.PORT,
          },
          ...(isChangeQuotaMode || bookingWizardStep === 1 ? {} : { referencePlanId: +container.planId }),
          ...(isChangeQuotaMode ? {} : {
            rateId: +container.rateId,
          }),
        };
      }),
    };

    let requestsResult: { id: number } | null = null;

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (shipment && currentOrganization && currentOrganization.role !== EOrganizationMemberRole.CUSTOMER) {
      body.customer = {
        type: ORGANIZATION,
        organizationId: shipment.customerOrgId,
      };
    }

    try {
      requestsResult = await monetaryR.services.RFQServiceById.postCreateQuota(body);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData postCreateQuota');
      this.closeDrawerDueToError();

      return;
    }

    const requestId = requestsResult?.id;

    if (!requestId) {
      this.closeDrawerDueToError();

      return;
    }

    this.dispatch(R.actions.changeBookingSchedule.setQuotaRequestId(requestId));

    let isTimeExceeded = false;

    try {
      await new Promise((resolve, reject) => {
        const timeoutId = window.setTimeout(() => {
          isTimeExceeded = true;
        }, MAX_QUOTE_REQUEST_TIMEOUT);

        this.dispatch(R.actions.changeBookingSchedule.setTimeoutId(timeoutId));

        const intervalId = window.setInterval(async () => {
          if (isTimeExceeded) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            reject();
          }

          let isStatusComplete = false;

          try {
            isStatusComplete = await this.getIsRQFRequestCheckStatusComplete(requestId);
          } catch (e) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            reject();
          }

          if (isStatusComplete) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            resolve(null);
          }
        }, QUOTE_REQUEST_STATUS_CHECK_INTERVAL);

        this.dispatch(R.actions.changeBookingSchedule.setIntervalId(intervalId));
      });
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData request status check');
      this.closeDrawerDueToError();

      return;
    }

    let service: IRFQQuotasDTM | null = null;

    try {
      service = await monetaryR.services.RFQServiceById.getRFQServiceById({
        serviceId: `${requestId}`,
        size: MAX_QUOTAS_SIZE,
      });
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getRFQServiceById');
      this.closeDrawerDueToError();

      return;
    }

    if (service && service.content && service.content.length) {
      let quotas = service.content.map((item) => FreightQuotaContentDTM.fromPlain(item));

      if (!isChangeQuotaMode) {
        const currentQuota = quotas.find((_quota) => {
          let areRatesEqual = true;

          _quota?.containers?.forEach(({ rateId }) => {
            if (!containers.find((_container) => _container.rateId === String(rateId))) {
              areRatesEqual = false;
            }
          });

          return areRatesEqual;
        });

        if (currentQuota) {
          quotas = [currentQuota];
        }
      }

      const quota = quotas[0];

      if (quota) {
        this.dispatch(R.actions.changeBookingSchedule.setQuotaId(quota.id));
      }

      const schedules: FreightQuotaContentSchedulesDTM[] = quotas.reduce((acc, item) => [...acc, ...(item.schedules || [])], [] as FreightQuotaContentSchedulesDTM[]);
      const quotasContainers = quotas.reduce((acc, item) => [...acc, ...(item.containers || [])], [] as FreightQuotaContentContainerDTM[]);
      const sortedSchedules = schedules.sort((a, b) => ((a?.departureTime || '') > (b?.departureTime || '') ? 1 : -1));
      const schedule = sortedSchedules.length ? sortedSchedules[0] : null;

      this.dispatch(R.actions.changeBookingSchedule.setQuotas(quotas));
      this.dispatch(R.actions.changeBookingSchedule.setSchedules(sortedSchedules));
      this.dispatch(R.actions.changeBookingSchedule.setContainers(quotasContainers));

      if (schedule) {
        this.dispatch(R.actions.changeBookingSchedule.setChosenScheduleId(Number(schedule.id)));
      }
    }

    this.dispatch(R.actions.changeBookingSchedule.setIsLoading(false));
  };

  public chooseScheduleById = (scheduleId: number) => {
    this.dispatch(R.actions.changeBookingSchedule.setChosenScheduleId(scheduleId));
  };

  public toggleScheduleExpanding = (scheduleId: number) => {
    this.dispatch(R.actions.changeBookingSchedule.toggleExpandedScheduleId(scheduleId));
  };

  public submitChangeBookingSchedule = async () => {
    this.dispatch(R.actions.changeBookingSchedule.setIsLoading(true));

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (!shipment?.id) {
      return;
    }

    const shipmentId = String(shipment.id);
    const quotaRequestId = R.selectors.changeBookingSchedule.getQuotaRequestId(this.store.getState());
    const quotas = R.selectors.changeBookingSchedule.getQuotas(this.store.getState());
    const schedules = R.selectors.changeBookingSchedule.getSchedules(this.store.getState());
    const scheduleId = R.selectors.changeBookingSchedule.getChosenScheduleId(this.store.getState());
    const schedule = schedules.find(({ id }) => id === scheduleId);
    const quota = quotas.find(({ schedules: _schedules }) => _schedules?.map(({ id }) => id).includes(scheduleId));

    if (!quota || !schedule) {
      this.dispatch(R.actions.changeBookingSchedule.setIsLoading(false));

      return;
    }

    const scheduleTotalCost = R.selectors.changeBookingSchedule.getScheduleTotalCost(scheduleId)(this.store.getState());

    this.dispatch(R.actions.bookingWizard.setTotalCost(String(scheduleTotalCost)));
    this.dispatch(R.actions.bookingWizard.setCurrentSchedule(schedule));
    this.dispatch(R.actions.bookingWizard.setCurrentQuota(quota));
    this.dispatch(R.actions.bookingWizard.setCurrentQuotaRequestId(quotaRequestId));

    if (schedule.id && quota.id) {
      await R.services.shipment.rollShipment(shipmentId, quota.id, schedule.id, quotaRequestId);

      const updatedShipment = await R.services.shipment.getShipmentShortById(+shipmentId);

      this.dispatch(R.actions.bookingWizard.setShipmentData(updatedShipment));
      this.dispatch(R.actions.shipment.setShipment(ShipmentPreviewDTM.fromPlain(updatedShipment)));

      const bookingWizardStep = R.selectors.bookingWizard.getCurrentStep(this.store.getState());

      if (bookingWizardStep === 1) {
        const notSavedContainers = R.selectors.bookingWizard.getNotSavedContainers(this.store.getState());
        const containers = R.selectors.bookingWizard.getContainers(this.store.getState());

        const savedContainers = await Promise.all(notSavedContainers.map((_container) => R.services.shipmentContainers.postContainer(shipmentId, _container)));

        this.dispatch(R.actions.bookingWizard.setContainers([
          ...containers,
          ...savedContainers,
        ]));
        this.dispatch(R.actions.bookingWizard.setNotSavedContainers([]));
        this.dispatch(R.actions.bookingWizard.setShouldChangeQuotaDueToContainer(false));
      }
    }

    this.dispatch(R.actions.changeBookingSchedule.setIsLoading(false));
    appUC.drawer.closeDrawer();
  };

  public clearRequestsTimeoutsAndIntervals = () => {
    const intervalId = R.selectors.changeBookingSchedule.getIntervalId(this.store.getState());
    const timeoutId = R.selectors.changeBookingSchedule.getTimeoutId(this.store.getState());

    apiWorker.abortAllRequests();

    if (intervalId) {
      clearInterval(intervalId);
      this.dispatch(R.actions.changeBookingSchedule.setIntervalId(0));
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
      this.dispatch(R.actions.changeBookingSchedule.setTimeoutId(0));
    }
  };

  private getIsRQFRequestCheckStatusComplete = async (id: number) => {
    const requestStatus = await monetaryR.services.RFQServiceById.getQuotasMakeCheckStatus(id);

    return requestStatus === QUOTAS_STATUS.complete;
  };

  private getContainerEarliestDateAsUTC = (shipment?: ShipmentPreviewDTM) => {
    if (!shipment) {
      return '';
    }

    const offset = shipment.origin.getTimezoneByLocation();
    const today = moment.now();

    return moment(today).utcOffset(offset).add('days', 4).format();
  };

  private getContainerLatestDateAsUTC = (shipment?: ShipmentPreviewDTM) => {
    if (!shipment) {
      return '';
    }

    const offset = shipment.origin.getTimezoneByLocation();
    const earliestDate = moment(moment.now()).add('days', 4);

    return moment(earliestDate).utcOffset(offset).add('weeks', 4).format();
  };
}
