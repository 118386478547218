import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Column,
  ContentWrap,
  RowEnd,
  RowsDivider,
  DateText,
  TextGrey,
  DividerArrow,
  PortDate,
  DocumentTitle,
  DocumentSubTitle,
  RoutingButton,
  RoutingButtonIcon,
  ExpandedData,
  TotalPrice,
} from 'shipment-operations/view/components/RollShipmentDrawer/components/RollOption/RollOption.styled';

import { ExpandRender } from './components';
import { Wrapper } from './CurrentSchedule.styled';

interface RollOptionComponentProps {
  etd: string;
  eta: string;
  terminalCutoffDate?: string;
  documentCutoffDate?: string;
  hazmatCutoffDate?: string;
  totalCost: number;
}

export const CurrentScheduleComponent: FC<RollOptionComponentProps> = ({
  etd,
  eta,
  terminalCutoffDate,
  documentCutoffDate,
  hazmatCutoffDate,
  totalCost,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleScheduleExpand = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setIsExpanded((_isExpanded) => !_isExpanded);
  }, [setIsExpanded]);

  return (
    <Wrapper>
      <ContentWrap>
        <Row>
          <Column>
            <RowEnd>
              <Row>
                <DateText>
                  {t('ETD')}
                  {' '}
                  {etd}
                  {' '}
                  -
                  {' '}
                  {t('ETA')}
                  {' '}
                  {eta}
                </DateText>
              </Row>

              <Row>
                <TotalPrice>
                  {totalCost.toFixed(2)} $
                </TotalPrice>
              </Row>
            </RowEnd>

            <RowsDivider />

            <RowEnd>
              <Row>
                <TextGrey>
                  {t('Cutoffs')}
                </TextGrey>

                <DividerArrow />

                <TextGrey>
                  {t('Port')}
                </TextGrey>

                <PortDate>
                  {terminalCutoffDate}
                </PortDate>

                <DocumentTitle>
                  {t('Documentation')}
                </DocumentTitle>

                <DocumentSubTitle>
                  {documentCutoffDate}
                </DocumentSubTitle>

                {hazmatCutoffDate ? (
                  <>
                    <DocumentTitle>
                      {t('Hazmat')}
                    </DocumentTitle>

                    <DocumentSubTitle>
                      {hazmatCutoffDate}
                    </DocumentSubTitle>
                  </>
                ) : null}
              </Row>

              <RoutingButton
                onClick={handleScheduleExpand}
                icon={<RoutingButtonIcon isExpanded={isExpanded} />}
                type="default"
              >
                {t('viewDetails')}
              </RoutingButton>
            </RowEnd>
          </Column>
        </Row>

        {isExpanded ? (
          <ExpandedData>
            <ExpandRender />
          </ExpandedData>
        ) : null}
      </ContentWrap>
    </Wrapper>
  );
};
