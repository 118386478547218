import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormPackageTypeSelectComponent } from './CargoFormPackageTypeSelect.component';

const CargoFormPackageTypeSelectContainer: FC = () => {
  const {
    packageType,
    errors,
    touchedFields,
    wasUpdateAttempted,
  } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormPackageTypeSelectComponent
      packageType={packageType}
      touchField={UC.cargo.touchField}
      setPackageType={UC.cargo.setPackageType}
      error={!!errors.packageType && (touchedFields.packageType || wasUpdateAttempted)}
      cargosAvailability={permissions.cargosAvailability}
      customerCargosAvailability={permissions.customerCargosAvailability}
    />
  );
};

export {
  CargoFormPackageTypeSelectContainer as CargoFormPackageTypeSelect,
};
