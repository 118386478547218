import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { StateInputComponent } from './StateInput.component';

const StateInputContainer: FC = () => {
  const countryState = useSelector(R.selectors.shippingParties.getCountryState);
  const country = useSelector(R.selectors.shippingParties.getCountry);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <StateInputComponent
      state={countryState}
      country={country}
      setState={UC.shippingParties.setCountryState}
      error={requiredError}
      isAbleToEdit={permissions.isAbleToEditMainFormData}
    />
  );
};

const StateInputContainerCached = React.memo(StateInputContainer);

export {
  StateInputContainerCached as StateInput,
};
