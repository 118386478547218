import React from 'react';
import { useSelector } from 'react-redux';

import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BookingDrayageSelectContactComponent } from './BookingDrayageSelectContact.component';

export const BookingDrayageSelectContact = React.memo(() => {
  const isLoading = useSelector(R.selectors.shippingParties.getIsSelectCompanyDrawerLoading);
  const contacts = useSelector(R.selectors.bookingWizard.getContactsForChosenCompany);
  const contactId = useSelector(R.selectors.bookingWizard.getChosenContactId);

  return (
    <BookingDrayageSelectContactComponent
      onClose={appUC.drawer.closeDrawer}
      isLoading={isLoading}
      contacts={contacts}
      contactId={contactId}
      setContactId={UC.bookingWizard.setChosenContactId}
    />
  );
});
