import { IPaginationState, PaginationStore } from 'proto/BaseMobxStore';
import { EDrayageLoadingSide } from 'shipment-operations/constants';

import { ShipmentScheduleDTM } from 'shipment-operations/models/dtm';

const initialState: IPaginationState<ShipmentScheduleDTM> = {
  isLoading: false,
  items: [],
};

export class ShipmentSchedulesStore extends PaginationStore<ShipmentScheduleDTM> {
  public get getExportSchedule() {
    return this.items.find((item) => item.drayageSide === EDrayageLoadingSide.ORIGIN_DRAYAGE);
  }

  public get getImportSchedule() {
    return this.items.find((item) => item.drayageSide === EDrayageLoadingSide.DESTITATION_DRAYAGE);
  }
}

export const createShipmentSchedulesStore = (_initialState?: IPaginationState<ShipmentScheduleDTM>) => new ShipmentSchedulesStore(_initialState || initialState);
