import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { TrackerHeaderDrayageComponent } from './TrackerHeaderDrayage.component';

interface ITrackerHeaderContainerProps {
  isPageLoading: boolean
  skeletonHeight: string
}

const TrackerHeaderDrayageContainer: FC<ITrackerHeaderContainerProps> = ({ skeletonHeight, isPageLoading }) => {
  const { isExport } = useIsExportOrImport();

  const routesExport = useSelector(R.selectors.shipmentTrackerRoutes.getDrayageRoutesExport);
  const routesImport = useSelector(R.selectors.shipmentTrackerRoutes.getDrayageRoutesImport);
  const isLoadingSchedules = useSelector(R.selectors.shipmentTrackerRoutes.getIsLoadingSchedules);
  const isErrorSchedules = useSelector(R.selectors.shipmentTrackerRoutes.getIsErrorSchedules);
  const schedule = useSelector(R.selectors.shipmentTrackerRoutes.getSchedule);
  const reEvent = useSelector(R.selectors.shipmentTracker.getREEvent);

  return (
    <TrackerHeaderDrayageComponent
      setDefaultLegs={UC.shipmentTracker.setDefaultLegs}
      onClear={UC.shipmentTracker.clearEditPlan}
      routes={isExport ? routesExport : routesImport}
      skeletonHeight={skeletonHeight}
      isPageLoading={isPageLoading}
      isLoadingSchedules={isLoadingSchedules}
      isErrorSchedules={isErrorSchedules}
      schedule={schedule}
      reEvent={reEvent}
    />
  );
};

export {
  TrackerHeaderDrayageContainer as TrackerHeaderDrayage,
};
