import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { R } from 'monetary/repository';

import { PrintRFQComponent } from './PrintRFQ.component';

interface PrintRFQContainerProps {
  additionalPage?: ReactNode;
  headerComponent?: ReactNode;
  fileNamePrint?: string;
}

const PrintRFQContainer = React.forwardRef((props: PrintRFQContainerProps, ref) => {
  const quotaId = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.printedQuotaId);
  const customerInfo = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getCustomerInfo);
  const cost = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getCost);
  const totalCost = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getTotalCost);
  const incoterm = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getIncoterm);
  const tradeType = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getTradeType);
  const isExcludedOrigin = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getIsExcludedOriginPhase);
  const isExcludedDestination = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getIsExcludedDestinationPhase);
  const containers = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getContainers);
  const commodity = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getCommodity);
  const isFullCommodity = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.isFullColumnCommodity);
  const routingFrom = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getRoutingFrom);
  const routingTo = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getRoutingTo);
  const carrierName = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getCarrierName);
  const carrier = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getCarrierTable);
  const containersTypes = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getUniqContainersTypes);
  const transportation = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedTransportationGrouped);
  const servicesGrouped = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedServicesGrouped);
  const servicesList = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedServicesList);
  const feesGrouped = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedFeesGrouped);
  const feesList = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedFeesList);
  const servicesTotal = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedServicesChargesTotal);
  const feesTotal = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedFeesChargesTotal);
  const transportationTotal = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getPrintedTransportationChargesTotal);
  const accessorialGrouped = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getAccessorialGrouped);
  const accessorialList = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getAccessorialList);
  const exceptionGrouped = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getExceptionGrouped);
  const exceptionList = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getExceptionList);
  const validity = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getValidity);
  const freeTimeCharges = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getFreeTime);
  const headerInfo = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getHeaderSkypaceInformation);
  const relatedAdmin = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.getCurrentOrgRelatedAdmin);
  const additionalRatesServicesCharges = useSelector(R.selectors.freightQuote.getAdditionalServicesRatesCharges);

  return (
    <PrintRFQComponent
      id={quotaId}
      fileNamePrint={props.fileNamePrint}
      customerInfo={customerInfo}
      cost={cost}
      headerComponent={props.headerComponent}
      header={{
        company: headerInfo,
      }}
      totalCost={totalCost}
      incoterm={incoterm}
      tradeType={tradeType}
      isExcludedOrigin={isExcludedOrigin}
      isExcludedDestination={isExcludedDestination}
      containers={containers}
      commodity={commodity}
      isFullCommodity={isFullCommodity}
      routingFrom={routingFrom}
      routingTo={routingTo}
      carrierName={carrierName}
      carrier={carrier}
      containersTypes={containersTypes}
      transportation={transportation}
      servicesGrouped={servicesGrouped}
      servicesList={servicesList}
      feesGrouped={feesGrouped}
      feesList={feesList}
      servicesTotal={servicesTotal}
      feesTotal={feesTotal}
      transportationTotal={transportationTotal}
      accessorialGrouped={accessorialGrouped}
      accessorialList={accessorialList}
      exceptionGrouped={exceptionGrouped}
      exceptionList={exceptionList}
      freeTimeCharges={freeTimeCharges}
      validity={validity}
      additionalPage={props.additionalPage}
      relatedAdmin={relatedAdmin}
      additionalRatesServicesCharges={additionalRatesServicesCharges}
      // @ts-ignore
      ref={ref}
    />
  );
});

export { PrintRFQContainer as PrintRFQ };
