import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'app-wrapper/controllers';

import { ScheduleStepComponent } from './ScheduleStep.component';

export const ScheduleStep = React.memo(() => {
  const shipment = useSelector(R.selectors.bookingWizard.getShipment);

  return (
    <ScheduleStepComponent
      isOriginDoor={shipment?.getIsOriginDoor()}
      openChangeScheduleDrawer={UC.drawer.openChangeBookingScheduleDrawer}
      openChangeQuotaDrawer={UC.drawer.openChangeBookingQuotaDrawer}
    />
  );
});
