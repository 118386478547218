import styled from 'styled-components';

import { Select as BaseSelect } from 'app-wrapper/view/components';

export const Select = styled(BaseSelect)`
  &.ant-select {
    height: 32px !important;
    min-height: 32px !important;

    .ant-select-selector, .ant-select-selection-search-input {
      height: 32px !important;
    }

    .ant-select-selection-placeholder, .ant-select-selection-item {
      line-height: 32px !important;
    }
  }
`;
