import React, { ChangeEvent, FC, useCallback } from 'react';

import { UC } from 'shipment-operations/controllers';

import { CardValueField } from './CardValueField.styled';

interface ICardUnitFieldContainerProps {
  cargoId: string
  value: string
  isError: boolean
  disabled: boolean
}

const CardValueFieldContainer: FC<ICardUnitFieldContainerProps> = React.memo(({
  cargoId,
  value,
  isError,
  disabled,
}: ICardUnitFieldContainerProps) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    UC.shipmentContainers.updateChangedCargoValue(event.target.value, cargoId);
  }, []);

  return (
    <CardValueField
      data-class="CardValueField"
      type="number"
      value={value}
      disabled={disabled}
      error={isError}
      onChange={handleChange}
    />
  );
});

export {
  CardValueFieldContainer as CardValueField,
};
