import styled from 'styled-components';
import AntPopover from 'antd/es/popover';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

export const Popover = styled(AntPopover)``;

export const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 8px;
  color: rgba(115, 129, 155, 1);
`;

export const Content = styled.div`
  width: 250px;
  background-color: rgba(32, 44, 60, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  line-height: 22px;
`;
