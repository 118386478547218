import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { LoadingTimeComponent } from './LoadingTime.component';

export const LoadingTime = observer(() => {
  const { editLoadingControlsDrawer: { state } } = useMobxStore();

  return (
    <LoadingTimeComponent
      loadingTime={state.loadingControls?.loadingTime}
      error={state.isRequiredErrorVisible && !state.loadingControls?.loadingTime}
      loadingMethod={state.loadingControls?.loadingMethod}
      setLoadingTime={UC.editLoadingControlsDrawer.setLoadingTime}
    />
  );
});
