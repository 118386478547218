import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ContainerDTM, ContainersPermissionsDtm } from 'shipment-operations/models/dtm';
import { hasAccess } from 'app-wrapper/utils';

interface IShippingPartiesFormFooterComponentProps {
  isLoading: boolean
  selectedContainer?: ContainerDTM
  isContainerDataUpdated: boolean
  isContainerDataHaveErrors: boolean
  discardChanges: () => void
  updateParties: (shipmentId?: string, containerId?: string) => void
  permissions: ContainersPermissionsDtm
  shipmentId: string | undefined
  containerId: string | undefined
}

export const ShipmentContainersFormFooterComponent: FC<IShippingPartiesFormFooterComponentProps> = ({
  isLoading,
  selectedContainer,
  isContainerDataUpdated,
  isContainerDataHaveErrors,
  discardChanges,
  updateParties,
  shipmentId,
  containerId,
  permissions,
}) => {
  const { t } = useTranslation();

  const sendData = useCallback(() => {
    updateParties(shipmentId, containerId);
  }, [shipmentId, containerId]);

  const isDisabled = useMemo(() => !hasAccess(permissions.customerEditAvailability, PermissionAttributePolicy.WRITE), [permissions]);

  const discardIsDisabled = isDisabled || !isContainerDataUpdated || isLoading;
  const updateIsDisabled = isDisabled || (!isContainerDataUpdated && !selectedContainer?.isVirtual) || isContainerDataHaveErrors;
  const containerName = selectedContainer?.name
    ? `${t('updateContainer')} ${selectedContainer?.name}`
    : `${t('updateContainer')} # ${selectedContainer?.id}`;
  const buttonText = selectedContainer?.isVirtual ? t('Create New Container') : containerName;

  return (
    <>
      <Button
        size="large"
        type="default"
        disabled={discardIsDisabled}
        onClick={discardChanges}
      >
        {t('Discard Changes')}
      </Button>
      <Button
        size="large"
        disabled={updateIsDisabled}
        loading={isLoading}
        onClick={sendData}
      >
        {buttonText}
      </Button>
    </>
  );
};
