import i18n from 'i18next';
import { ContainerUsualTypes, ContainerReeferTypes } from 'shipment-operations/constants';

export const shipmentContainersNewContainerTypeValidate = (type?: ContainerUsualTypes | ContainerReeferTypes) => {
  if (!type) {
    return new Error('type should be selected');
  }

  return null;
};

export const shipmentContainersNewContainerQTYValidate = (qty: string) => {
  if (!/^([1-9])/.test(qty)) {
    return new Error('qty should be number');
  }

  return null;
};

export const shipmentContainerNumberValidate = (containerNumber?: string) => {
  if (containerNumber && !containerNumber.match(/^[a-zA-Z]{4}\d{7}$/)) {
    return new Error(i18n.t('shipmentContainerErrors.INVALID_CONTAINER_NUMBER'));
  }

  return null;
};

export const shipmentContainerReferenceValidate = (containerReference?: string) => {
  if (containerReference && !containerReference.match(/^[a-zA-Z0-9 ]+$/)) {
    return new Error(i18n.t('shipmentContainerErrors.INVALID_CONTAINER_REFERENCE'));
  }

  return null;
};
