import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const CCSpanEllipsis = styled.span.attrs({
  'data-class': 'CCSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const CCContentTable = styled.div.attrs((props: {
  isCompleted?: boolean,
}) => ({
  isCompleted: props.isCompleted,
  'data-class': 'CCContentTable',
}))`
  ${divFlex}
  flex-direction: column;
  overflow: unset;

  margin: 13px 0 13px 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CCContentTableHeader = styled.div.attrs({
  'data-class': 'CCContentTableHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
  padding-right: 8px;
`;

export const CCContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
  isLast?: boolean,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  isLast: props.isLast,
  'data-class': 'CCContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};
  ${({ isLast }) => (isLast ? 'margin-left: auto' : '')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const CCContentTableContent = styled.div.attrs({
  'data-class': 'CCContentTableContent',
})`
  ${divFlex}
  ${CustomScrollbar}
  flex-direction: column;
`;

export const CCContentTableContentItem = styled.div.attrs({
  'data-class': 'CCContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const CCContentTableContentExpand = styled.div.attrs((props: {
  isActive?: boolean,
  isEmpty?: boolean,
}) => ({
  isActive: props.isActive,
  isEmpty: props.isEmpty,
  'data-class': 'CCContentTableContentExpand',
}))`
  ${divFlex}
  align-items: center;
  width: 8px;

  margin-left: 4px;
  cursor: ${({ isEmpty }) => (isEmpty ? 'pointer' : 'auto')};
  height: 100%;
`;

export const CCContentTableContentPriority = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentPriority',
}))`
  ${divFlex}
  align-items: center;
  width: 16px;

  height: 100%;
  margin-left: 15px;
`;

export const CCContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentTitle',
}))`
  ${divFlex}
  /* temporarily hidden */
  /* width: calc(41% - 12px - 31px - 10px); */
  width: calc(41% - 12px - 31px - 10px + 35px);
  align-items: center;
  justify-content: left;

  margin-left: 10px;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 500;
`;

export const CCContentTableContentDueDate = styled.div.attrs((props: {
  isWarning?: boolean,
}) => ({
  isWarning: props.isWarning,
  'data-class': 'CCContentTableContentDueDate',
}))`
  ${divFlex}
  width: 14.5%;
  align-items: center;
  justify-content: left;

  > div {
    min-width: ${({ isWarning }) => (isWarning ? '109px' : '92px')};
  }
`;

export const CCContentTableContentItemBottom = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentItemBottom',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isActive }) => isActive && 'margin-top: -11px;'};

  .ant-collapse-header {
    display: none !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse.ant-collapse-icon-position-left,
  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-item.ant-collapse-item-active,
  .ant-collapse-item {
    border: none !important;
    background: none;
  }
`;
