export enum EAccountDrawerContent {
  USER_EDIT_PROFILE = 'USER_EDIT_PROFILE',
  USER_EDIT_PROFILE_ORGANIZATION = 'USER_EDIT_PROFILE_ORGANIZATION',
  USER_ACCOUNT_DEPARTMENT_EDIT = 'USER_ACCOUNT_DEPARTMENT_EDIT',
  USER_INVITE = 'USER_INVITE',
  USER_PASSWORD_CHANGE = 'USER_PASSWORD_CHANGE',
  USER_CUSTOMER_ADD_INTERNAL = 'USER_CUSTOMER_ADD_INTERNAL',
  USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS = 'USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS',
  USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS = 'USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS',
  USER_ADDRESSES_AND_CONTACTS_SECOND = 'USER_ADDRESSES_AND_CONTACTS_SECOND',
  USER_AAC_CONTACT_ADD_CONTACT_PERSON = 'USER_AAC_CONTACT_ADD_CONTACT_PERSON',
  USER_AAC_CONTACT_EDIT_CONTACT_PERSON = 'USER_AAC_CONTACT_EDIT_CONTACT_PERSON',
  CONTACT_BOOK_ADD_CONTACT = 'CONTACT_BOOK_ADD_CONTACT',
  CONTACT_BOOK_BY_ID_ADD_ADDRESS = 'CONTACT_BOOK_BY_ID_ADD_ADDRESS',
  CONTACT_BOOK_BY_ID_EDIT_COMPANY = 'CONTACT_BOOK_BY_ID_EDIT_COMPANY',
  CONTACT_BOOK_BY_ID_EDIT_ADDRESS = 'CONTACT_BOOK_BY_ID_EDIT_ADDRESS',
  CONTACT_BOOK_BY_ID_ADD_CONTACT = 'CONTACT_BOOK_BY_ID_ADD_CONTACT',
  CONTACT_BOOK_BY_ID_EDIT_CONTACT = 'CONTACT_BOOK_BY_ID_EDIT_CONTACT',
  USER_MANAGEMENT_EDIT = 'USER_MANAGEMENT_EDIT',
  BOOKING_ADD_COMPANY = 'BOOKING_ADD_COMPANY',
  SHIPMENT_PARTY_ADD_COMPANY = 'SHIPMENT_PARTY_ADD_COMPANY',
  SHIPMENT_PARTY_ADD_ADDRESS = 'SHIPMENT_PARTY_ADD_ADDRESS',
  SHIPMENT_PARTY_ADD_CONTACT = 'SHIPMENT_PARTY_ADD_CONTACT',
  RFQ_ADD_ADDITIONAL_SERVICES = 'RFQ_ADD_ADDITIONAL_SERVICES',
  EDIT_CONTAINER_ADD_COMPANY = 'EDIT_CONTAINER_ADD_COMPANY',
}
