import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';
import { DoubleRadioSelect } from 'app-wrapper/view/guideline';
import { RECEIVING_METHODS, EShipmentLoadingMethod } from 'shipment-operations/constants';

export const ReceivingMethod = observer(() => {
  const { t } = useTranslation();
  const { editLoadingControlsDrawer: { state } } = useMobxStore();

  if (!state.loadingControls?.loadingMethod || state.loadingControls?.loadingMethod === EShipmentLoadingMethod.DROP_AND_PICKUP) {
    return null;
  }

  return (
    <DoubleRadioSelect
      value={state.loadingControls?.receivingMethod}
      error={state.isRequiredErrorVisible && !state.loadingControls?.receivingMethod}
      options={RECEIVING_METHODS}
      setValue={UC.editLoadingControlsDrawer.setReceivingMethod}
      title={t('Receiving Method')}
    />
  );
});
