import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { BookingDetailsComponent } from './BookingDetailsStep.component';

export const BookingDetails = React.memo(() => {
  const hasTemperatureControl = useSelector(R.selectors.bookingWizard.getHasTemperatureControl);
  const shouldShowShipperInformation = useSelector(R.selectors.bookingWizard.getShouldShowShipperSection);
  const shouldShowConsigneeInformation = useSelector(R.selectors.bookingWizard.getShouldShowConsigneeSection);

  return (
    <BookingDetailsComponent
      shouldShowShipperInformation={shouldShowShipperInformation}
      shouldShowConsigneeInformation={shouldShowConsigneeInformation}
      hasTemperatureControl={hasTemperatureControl}
    />
  );
});
