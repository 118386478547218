import AntTable from 'antd/es/table';
import styled from 'styled-components';

import { BodyTextMedium } from 'app-wrapper/view/fonts';

export const TableCell = styled.span``;

export const MissingValueWrap = styled.div`
  width: 32px;
  margin-left: auto;
`;

export const TableCellDifference = styled(TableCell)`
  * {
    width: fit-content;
  }
`;

export const CustomTable = styled(AntTable)`
  .ant-table-tbody {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding2};
  }
  
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }

  .ant-table-tbody .ant-table-cell {
    color: rgba(32, 44, 60, 1);
    border: 0;
    ${BodyTextMedium}

    &:first-of-type {
      padding-left: 32px;
    }
  }
`;
