import styled from 'styled-components';
import Typography from 'antd/es/typography';
import EditOutlined from '@ant-design/icons/EditOutlined';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div.attrs({
  'data-class': 'TradeReferencesComponentWrap',
})`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled(Typography)`
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Item = styled.div`
  width: 25%;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(240, 242, 252, 1);
  border-radius: 4px;

  * {
    color: rgba(74, 85, 173, 1);
  }
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const ItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;

export const ItemSubtitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const EditButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 22px;
  font-size: 12px;
  line-height: 20px;
  border: 1px dashed rgba(222, 225, 233, 1);
  box-shadow: none;
`;

export const EditIcon = styled(EditOutlined)``;
