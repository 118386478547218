import { createSelector } from 'reselect';
import moment from 'moment';
import i18n from 'app-wrapper/i18n/i18n';

import { RootState } from 'app-wrapper/store';
import {
  ShipmentStatusEnum,
  ShipmentBookingStatusEnum,
  isContainerAllTypes,
  ContainerAllTypesNamesLongConst,
  ChargeCodeDesignation,
  IncotermsType,
  ChargeCodePriceBy,
  priceByBol,
  priceByContainer,
  MscChargeDescription,
} from 'shipment-operations/constants';
import { ShipmentChargeChangeDTM, DateDtm, ContainerWithChangeChargesDTM } from 'app-wrapper/models/dtm';
import {
  DetailBreakdownContainerDTM,
  DetailBreakdownServicesDTM,
  DetailRoutingContainerStepDTM,
  DetailRoutingDTM,
  DetailRoutingStepsDTM,
  EFreightPaymentTerms,
  FreightQuotaContentContainerDTM,
  FreightQuotaContentSchedulesChargesDTM,
  FreightQuotaContentSchedulesDTM,
  IDetailBreakdownStepsContainersTypesByIdDTM,
  IDetailBreakdownStepsContainersTypesDTM,
  IFreightQuotaContentSchedulesChargesDTM,
} from 'monetary/models/dtm';
import {
  CarrierSCACNames,
  chargeCodeCode,
  chargeCodeDesignation,
  chargeCodeMeasureBy,
  chargeCodeOccurrence,
  chargeCodePriceBy,
  chargeCodeSubjectTo,
  chargeCodeType,
  ECarrierSCAC,
  EFreightIncotermsByExport,
  EFreightIncotermsByImport,
  EFreightIncotermsTrade,
} from 'monetary/constants';
import { getLocationToOneString } from 'app-wrapper/utils';

const FORBIDDEN_EVENTS_FOR_ROLLING = [
  'AE',
  'AL',
  'AM',
  'VD',
  'OA',
  'RL',
];

const localState = (state: RootState) => state.rollShipmentWizard;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getChosenScheduleId = createSelector(
  localState,
  (state) => state.chosenScheduleId,
);

const getQuotaId = createSelector(
  localState,
  (state) => state.quotaId,
);

const getErrorMessage = createSelector(
  localState,
  (state) => state.errorMessage,
);

const getIsRollShipmentAvailable = createSelector(
  localState,
  (state) => {
    const { shipment, events } = state;
    let isRollAvailable = true;

    if (!shipment) {
      isRollAvailable = false;
    }

    if (shipment && (shipment.status === ShipmentStatusEnum.DRAFT || shipment.bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || shipment.status === ShipmentStatusEnum.AWAITING_APPROVAL)) {
      isRollAvailable = false;
    }

    if (events.some(({ code }) => FORBIDDEN_EVENTS_FOR_ROLLING.includes(String(code)))) {
      isRollAvailable = false;
    }

    return isRollAvailable;
  },
);

const getOriginDate = createSelector(
  localState,
  (state) => {
    const { shipment } = state;

    if (!shipment || !shipment.origin.estimatedDate) {
      return '';
    }

    return shipment.origin.estimatedDate.getDateDMMMYYYYWithOffset();
  },
);

const getDestinationDate = createSelector(
  localState,
  (state) => {
    const { shipment } = state;

    if (!shipment || !shipment.destination.estimatedDate) {
      return '';
    }

    return shipment.destination.estimatedDate.getDateDMMMYYYYWithOffset();
  },
);

const getOriginLocationName = createSelector(
  localState,
  (state) => {
    const { shipment } = state;

    if (!shipment || !shipment.origin.address) {
      return '';
    }

    return shipment.origin.address;
  },
);

const getDestinationLocationName = createSelector(
  localState,
  (state) => {
    const { shipment } = state;

    if (!shipment || !shipment.destination.address) {
      return '';
    }

    return shipment.destination.address;
  },
);

const getIsScheduleChosen = (scheduleId: number) => createSelector(
  localState,
  (state) => state.chosenScheduleId === scheduleId,
);

const getSchedules = createSelector(
  localState,
  (state) => state.schedules,
);

const getIsScheduleExpandedById = (scheduleId: number) => createSelector(
  localState,
  (state) => state.expandedSchedulesIds.includes(scheduleId),
);

const getScheduleTerminalCutoffDate = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;

    if (!schedule.terminalCutOff) {
      return '';
    }

    const terminalCutoffDate = DateDtm.fromPlain({
      date: schedule.terminalCutOff,
      offset: moment.parseZone(schedule.terminalCutOff).utcOffset(),
    });

    return terminalCutoffDate.getDateDMMMYYYYHHmmWithOffset();
  },
);

const getScheduleTotalCost = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const { paymentTerms } = state;
    const incoterm = paymentTerms?.incoterm;
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;

    if (!schedule?.charges) {
      return 0;
    }

    const originCharges = schedule.charges.filter(({ designation }) => designation === ChargeCodeDesignation.ORIGIN);
    const freightCharges = schedule.charges.filter(({ designation }) => designation === ChargeCodeDesignation.FREIGHT);
    const destinationCharges = schedule.charges.filter(({ designation }) => designation === ChargeCodeDesignation.DESTINATION);

    const isExcludeDesignationCase = incoterm === IncotermsType.CFR || incoterm === IncotermsType.CIF;
    const isExcludedOriginCase = incoterm === IncotermsType.FOB || incoterm === IncotermsType.FAS || incoterm === IncotermsType.FCA;

    return +Number([
      ...(isExcludedOriginCase ? [] : originCharges),
      ...freightCharges,
      ...(isExcludeDesignationCase ? [] : destinationCharges),
    ].filter(({ applied }) => applied).reduce((acc, cur) => acc + (cur?.totalCost || 0), 0)).toFixed(2);
  },
);

const getScheduleDocumentCutoffDate = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;

    if (!schedule.documentCutOff) {
      return '';
    }

    const documentCutOffDate = DateDtm.fromPlain({
      date: schedule.documentCutOff,
      offset: moment.parseZone(schedule.documentCutOff).utcOffset(),
    });

    return documentCutOffDate.getDateDMMMYYYYHHmmWithOffset();
  },
);

const getScheduleHazmatCutoffDate = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;

    if (!schedule.hazmatCutOff) {
      return '';
    }

    const hazmatCutOffDate = DateDtm.fromPlain({
      date: schedule.hazmatCutOff,
      offset: moment.parseZone(schedule.hazmatCutOff).utcOffset(),
    });

    return hazmatCutOffDate.getDateDMMMYYYYHHmmWithOffset();
  },
);

const getScheduleETD = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;

    if (!schedule.departureTime) {
      return '';
    }

    const dateETD = DateDtm.fromPlain({
      date: schedule.departureTime,
      offset: moment.parseZone(schedule.departureTime).utcOffset(),
    });

    return dateETD.getDateDMMMYYYYWithOffset();
  },
);

const getScheduleETA = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;

    if (!schedule.arrivalTime) {
      return '';
    }

    const dateETD = DateDtm.fromPlain({
      date: schedule.arrivalTime,
      offset: moment.parseZone(schedule.arrivalTime).utcOffset(),
    });

    return dateETD.getDateDMMMYYYYWithOffset();
  },
);

const getScheduleDurationDays = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const schedule = state.schedules.find(({ id }) => id === scheduleId) as FreightQuotaContentSchedulesDTM;
    const departure = moment(schedule.departureTime);
    const arrival = moment(schedule.arrivalTime);
    const duration = moment.duration(arrival.diff(departure));

    return Math.round(duration.as('minutes'));
  },
);

export const getPaymentTerms = (name: string, incoterm?: string, incotermTrade?: string) => {
  switch (name) {
    case 'origin':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP
          || incoterm === EFreightIncotermsByExport.DPU
          || incoterm === EFreightIncotermsByExport.CIF
          || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.PREPAID;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return EFreightPaymentTerms.COLLECT;
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.PREPAID;
        }
      }
      return EFreightPaymentTerms.DEFAULT;

    case 'freight':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP
          || incoterm === EFreightIncotermsByExport.DPU
          || incoterm === EFreightIncotermsByExport.CIF
          || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.PREPAID;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW
          || incoterm === EFreightIncotermsByImport.FCA
          || incoterm === EFreightIncotermsByImport.FAS
          || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      return EFreightPaymentTerms.DEFAULT;

    case 'destination':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return EFreightPaymentTerms.PREPAID;
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW
          || incoterm === EFreightIncotermsByImport.FCA
          || incoterm === EFreightIncotermsByImport.FAS
          || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      return EFreightPaymentTerms.DEFAULT;

    default:
      return EFreightPaymentTerms.DEFAULT;
  }
};

const getScheduleSchemeById = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const { quotas } = state;
    const quota = quotas.find(({ schedules }) => (schedules ? schedules.map((schedule) => (schedule ? schedule.id : '')).includes(scheduleId) : false));

    const containersTypes: IDetailBreakdownStepsContainersTypesDTM = {};
    let containersTypesById: IDetailBreakdownStepsContainersTypesByIdDTM = {};

    quota?.containers?.forEach((itemContainer) => {
      if (containersTypes[itemContainer?.type || 0]) {
        containersTypes[itemContainer?.type || ''].push(itemContainer?.id || 0);
      } else {
        containersTypes[itemContainer?.type || ''] = [itemContainer?.id || 0];
      }
      containersTypesById = {
        ...containersTypesById,
        [itemContainer?.id || 0]: itemContainer.type,
      };
    });
    const indexSchedule = quota?.schedules?.findIndex(({ id }) => id === scheduleId) || 0;
    const allQuotas = quota;
    const freight: DetailRoutingDTM[] = [];
    const origin: DetailRoutingDTM[] = [];
    const destination: DetailRoutingDTM[] = [];

    const getTerminal = (type: string, propsGetTerminal?: {
      name?: string
      address?: string
      postalCode?: string
    }) => {
      const {
        name,
        address,
        postalCode,
      } = propsGetTerminal || {};

      if (type === 'DOOR') {
        if (address) {
          return `${address}${postalCode ? `, ${postalCode}` : ''}`;
        }
        return `${postalCode || ''}`;
      }

      return name;
    };

    allQuotas?.routes?.forEach((item) => {
      item?.legs?.forEach((itemLegs) => {
        const transportLegType = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportations) => itemTransportations.transportLeg === itemLegs.id);

        if (!transportLegType?.length) {
          return;
        }

        const transportLegTypeFirst = transportLegType?.[0]?.transport?.type || '';
        const { country, name: city, city: cityDoor } = itemLegs.arrivalLocation;
        const {
          country: countryFrom, name: cityFrom, city: cityFromDoor,
        } = itemLegs.departureLocation;

        const findTransportations = allQuotas
          ?.schedules?.[indexSchedule]
          ?.transportations
          ?.filter((itemTransportation) => itemTransportation.transportLeg === itemLegs.id);
        let isFindOrigin = false;
        let isFindDestination = false;

        const findService: IFreightQuotaContentSchedulesChargesDTM[] = [];
        const findFees = allQuotas
          ?.schedules?.[indexSchedule]
          ?.charges
          ?.filter((itemSurcharges) => {
            isFindOrigin = itemSurcharges?.designation === chargeCodeDesignation.ORIGIN ? true : isFindOrigin;
            isFindDestination = itemSurcharges?.designation === chargeCodeDesignation.DESTINATION ? true : isFindDestination;

            if (itemSurcharges.subjectTo === chargeCodeSubjectTo.INCLUDED) {
              return false;
            }

            if (itemSurcharges?.chargeCode?.occurrence === chargeCodeOccurrence.ADDITIONAL) {
              findService.push(itemSurcharges);
              return false;
            }

            return itemSurcharges?.priceBy === chargeCodePriceBy.BOL || itemSurcharges?.chargeCode?.type === chargeCodeType.FEE;
          });

        const findTransportation = findTransportations?.[0];
        const objPush: DetailRoutingDTM = DetailRoutingDTM.fromPlain({
          duration: findTransportation
            ?.schedule?.transitDuration || 0,
          paymentTerms: getPaymentTerms(itemLegs.phase, allQuotas.incoterm, allQuotas.tradeType),
          to: getLocationToOneString({ country: country?.code || '', city: city || cityDoor, firstCityName: true }),
          from: getLocationToOneString({ country: countryFrom?.code || '', city: cityFrom || cityFromDoor, firstCityName: true }),
          etd: findTransportation
            ?.schedule?.departureTime || '',
          eta: findTransportation
            ?.schedule?.arrivalTime || '',
          pickupFromTime: findTransportation?.schedule?.pickupFromTime || '',
          pickupToTime: findTransportation?.schedule?.pickupToTime || '',
          fullPickupTime: findTransportation?.schedule?.fullPickupTime || '',
          deliveryTime: findTransportation?.schedule?.deliveryTime || '',
          fullReturnFromTime: findTransportation?.schedule?.fullReturnFromTime || '',
          fullReturnToTime: findTransportation?.schedule?.fullReturnToTime || '',
          transportType: transportLegTypeFirst,
          VesselName: findTransportation?.transport?.name,
          VoyageNumber: findTransportation?.voyageCode || undefined,
          arrivalTerminal: getTerminal(itemLegs?.arrivalLocation?.type || '', {
            name: findTransportation?.arrivalTerminal || '',
            address: itemLegs.arrivalLocation.address,
            postalCode: itemLegs.arrivalLocation.postalCode,
          }),
          departureTerminal: getTerminal(itemLegs?.departureLocation?.type || '', {
            name: findTransportation?.departureTerminal || '',
            address: itemLegs.departureLocation.address,
            postalCode: itemLegs.departureLocation.postalCode,
          }),
          transportations: findTransportations || undefined,
        });

        const errorsobjPush = item.validate();

        if (errorsobjPush.length) {
          console.error('DTM valid RFQ selector: getFreightQuoteCurrentAllQuotasDetailsRoutingByIndex', errorsobjPush);
        }

        const getFindSteps = (name: string,
          dataStep: FreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const findData: DetailRoutingContainerStepDTM[] = dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .map((itemFees) => {
              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees?.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                ? itemFees?.chargeCode?.originalDescription?.toLowerCase()
                : itemFees?.chargeCode?.description || '';
              findSummary += itemFees?.totalCost || 0;
              return DetailRoutingContainerStepDTM.fromPlain({
                name: nameFees,
                summary: itemFees.totalCost,
                cost: itemFees.costPerUnit,
                totalUnits: 1,
                descriptionText,
                isUnits: !!descriptionText,
              });
            });

          return { findData, findSummary };
        };

        const getFindServiceSteps = (name: string,
          dataStep: IFreightQuotaContentSchedulesChargesDTM[]) => {
          let findSummary = 0;
          const stackNames: string[] = [];
          const newDataService: {
            [key: string]: DetailRoutingContainerStepDTM
          } = {};

          const containersServicesArray: DetailBreakdownContainerDTM[] = [];
          const checkSomeType = new Set();

          dataStep
            .filter((itemFees) => itemFees?.designation?.toLocaleLowerCase() === name)
            .forEach((itemFees) => {
              if (checkSomeType
                .has(containersTypesById[itemFees.containerId || ''])
              ) {
                return;
              }
              checkSomeType.add(containersTypesById[itemFees.containerId || ''] || null);

              const descriptionText = (itemFees?.priceBy === chargeCodePriceBy.BOL
                && itemFees.measureBy === chargeCodeMeasureBy.FLAT)
                ? i18n.t('perBL')
                : undefined;

              const totalUnitsName = containersTypesById?.[itemFees?.containerId || ''] || '';
              findSummary += itemFees?.totalCost || 0;

              if (itemFees?.priceBy === chargeCodePriceBy.CONTAINER) {
                const nameFees = itemFees?.chargeCode?.code === chargeCodeCode.MSC && itemFees?.chargeCode?.originalDescription
                  ? itemFees?.chargeCode?.originalDescription
                  : itemFees?.chargeCode?.description || '';

                if (newDataService[itemFees?.chargeCode?.description || '']) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));
                } else if (itemFees?.chargeCode?.code !== chargeCodeCode.MSC) {
                  const containerNameType = containersTypesById[itemFees.containerId || ''] || '';
                  const containerName = (isContainerAllTypes(containerNameType) && ContainerAllTypesNamesLongConst[containerNameType]) || '';

                  containersServicesArray.push(DetailBreakdownContainerDTM.fromPlain({
                    name: containerName,
                    steps: [{
                      name: nameFees,
                      summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                      cost: itemFees.costPerUnit || 0,
                      totalUnits: Number(containersTypes?.[totalUnitsName]?.length) || 0,
                      isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                      descriptionText,
                    }],
                    summary: Number(((itemFees?.totalCost || 0) * containersTypes[totalUnitsName]?.length)?.toFixed(2)),
                    cost: Number(itemFees?.totalCost?.toFixed(2)),
                    totalUnits: 0,
                  }));

                  if (itemFees?.chargeCode?.description) {
                    stackNames.push(itemFees.chargeCode.description);
                  }
                }
              } else if (newDataService[itemFees?.chargeCode?.description || '']) {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: (newDataService[itemFees?.chargeCode?.description || ''].summary || 0) + (itemFees?.totalCost || 0),
                  cost: itemFees.costPerUnit || 0,
                  totalUnits: newDataService[itemFees?.chargeCode?.description || '']?.totalUnits + 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });
              } else {
                newDataService[itemFees?.chargeCode?.description || ''] = DetailRoutingContainerStepDTM.fromPlain({
                  name: itemFees?.chargeCode?.description || '',
                  summary: itemFees.totalCost || 0,
                  cost: itemFees.costPerUnit || 0,
                  totalUnits: 1,
                  isUnits: itemFees?.priceBy === chargeCodePriceBy.CONTAINER || !!descriptionText,
                  descriptionText,
                });

                if (itemFees?.chargeCode?.description) {
                  stackNames.push(itemFees.chargeCode.description);
                }
              }
            });

          const findData: DetailRoutingContainerStepDTM[] = Object.values(newDataService).map((itemValue) => DetailRoutingContainerStepDTM.fromPlain({
            name: itemValue?.name || '',
            summary: itemValue.summary,
            cost: itemValue.cost,
            totalUnits: itemValue.totalUnits,
            isUnits: itemValue.isUnits,
            descriptionText: itemValue.descriptionText,
          }));

          const containersServices: DetailBreakdownServicesDTM = DetailBreakdownServicesDTM.fromPlain({
            summary: findSummary,
            containers: containersServicesArray,
            other: {
              summary: 0,
              steps: findData,
            },
          });

          return { findData: containersServices, findSummary };
        };

        if (itemLegs.phase.toLocaleLowerCase() === 'freight') {
          const { findData: findFeesSteps, findSummary: findFeesSummary } = getFindSteps('freight', findFees || []);
          const { findData: findServiceSteps, findSummary: findServiceSummary } = getFindServiceSteps('freight', findService);

          freight.push({
            ...objPush,
            fees: DetailRoutingStepsDTM.fromPlain({
              summary: findFeesSummary,
              steps: findFeesSteps,
            }),
            service: DetailBreakdownServicesDTM.fromPlain({
              summary: findServiceSummary,
              containers: findServiceSteps.containers,
              other: findServiceSteps.other,
            }),
          });
        }

        if (itemLegs.phase.toLocaleLowerCase() === 'origin') {
          origin.push({ ...objPush });
        }
        if (itemLegs.phase.toLocaleLowerCase() === 'destination') {
          destination.push({ ...objPush });
        }
      });
    });

    return {
      freight,
      origin,
      destination,
    };
  },
);

const getSchedulerId = createSelector(
  localState,
  (state) => {
    const { schedules, chosenScheduleId } = state;
    const schedule = schedules.find(({ id }) => id === chosenScheduleId);

    return schedule ? schedule.oceanScheduleId || 0 : 0;
  },
);

const getSavedCharges = createSelector(
  localState,
  (state) => state.savedCharges,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getSavedFilteredCharges = createSelector(
  getSavedCharges,
  (savedCharges) => {
    const filtered = savedCharges.filter((item) => item.applied && item.active);
    return filtered.map((item, index) => ShipmentChargeChangeDTM.fromPlain({
      chargeCode: item.chargeCode.code,
      chargeDescription: item.chargeCode.description,
      chargeId: index,
      prevVersion: {
        costPerUnit: item.buyCostPerUnit,
        totalCost: item.buyTotalCost,
        currency: item.currency,
        designation: item.designation as ChargeCodeDesignation,
      },
      priceBy: item.priceBy as ChargeCodePriceBy,
      container: {
        ...item.container,
        type: item?.container?.originalType,
      },
      designation: item.designation as ChargeCodeDesignation,
    }));
  },
);

const parseCharges = (charges?: IFreightQuotaContentSchedulesChargesDTM[], containers?: FreightQuotaContentContainerDTM[]) => (charges ? charges.map((item, index) => {
  const matchedContainer = containers?.find(({ id }) => id === item.containerId);
  return ShipmentChargeChangeDTM.fromPlain({
    chargeCode: item.chargeCode?.code || '',
    chargeDescription: item.chargeCode?.code === chargeCodeCode.MSC ? (item.chargeCode?.originalDescription?.toLowerCase() || '') : (item.chargeCode?.description || ''),
    chargeId: index,
    newVersion: {
      costPerUnit: item.costPerUnit || 0,
      totalCost: item.totalCost || 0,
      currency: item.currency || '',
      designation: item.designation as ChargeCodeDesignation,
    },
    priceBy: item.priceBy as ChargeCodePriceBy,
    container: {
      id: matchedContainer?.id || 0,
      type: matchedContainer?.type,
    },
    designation: item.designation as ChargeCodeDesignation,
  });
}) : []);

const getComparedChargesTableBol = (scheduleId: number) => createSelector(
  getSavedFilteredCharges,
  getSchedules,
  getContainers,
  (savedCharges, schedules, containers) => {
    const charges = schedules.find(({ id }) => id === scheduleId)?.charges;
    const filtered = charges?.filter((item) => item.applied);
    const estimatedCharges = parseCharges(filtered, containers);

    const allCharges = [...savedCharges, ...estimatedCharges].filter((item) => item.priceBy === priceByBol);
    const merged: ShipmentChargeChangeDTM[] = [];
    allCharges.forEach((item) => {
      const existing = merged.find((el) => (el.chargeCode === item.chargeCode) && item.designation === el.designation && item.chargeDescription === el.chargeDescription);

      if (existing) {
        if (item.prevVersion !== undefined) {
          existing.prevVersion = item.prevVersion;
        }
        if (item.newVersion !== undefined) {
          existing.newVersion = item.newVersion;
        }
      } else if (item.chargeDescription !== MscChargeDescription) {
        merged.push({ ...item } as ShipmentChargeChangeDTM);
      }
    });
    return merged;
  },
);

const getComparedChargesTableContainer = (scheduleId: number) => createSelector(
  getSavedFilteredCharges,
  getSchedules,
  getContainers,
  (savedCharges, schedules, containers) => {
    const charges = schedules.find(({ id }) => id === scheduleId)?.charges;
    const filtered = charges?.filter((item) => item.applied);
    const estimatedCharges = parseCharges(filtered, containers);

    const allCharges = [...savedCharges, ...estimatedCharges].filter((item) => item.priceBy === priceByContainer);
    const savedChargesContainers: { [key: string]: string } = {};
    const estimatedChargesContainers: { [key: string]: string } = {};
    savedCharges.forEach((item) => {
      if (item.container?.id && item.container?.type) {
        savedChargesContainers[item.container?.id] = item.container?.type;
      }
    });
    estimatedCharges.forEach((item) => {
      if (item.container?.id && item.container?.type) {
        estimatedChargesContainers[item.container?.id] = item.container?.type;
      }
    });
    const mergedId: { [key: string]: number } = {};

    const keysA = Object.keys(savedChargesContainers);
    const keysB = Object.keys(estimatedChargesContainers);

    keysA.forEach((keyA) => {
      keysB.some((keyB, index) => {
        if (savedChargesContainers[keyA] === estimatedChargesContainers[keyB]) {
          mergedId[keyA] = Number(keyB);
          keysB.splice(index, 1);
          return true;
        }
        return false;
      });
    });

    const chargesWithId = allCharges.map((item) => ({
      ...item,
      container: {
        ...item.container,
        id: (item.container?.id && Object.keys(savedChargesContainers).includes(String(item.container?.id)) ? mergedId[item.container?.id] : item.container?.id) || item.container?.id,
      },
    }));

    const result: ShipmentChargeChangeDTM[] = [];

    chargesWithId.forEach((item) => {
      const existing = result.find((el) => (el.chargeCode === item.chargeCode) && el.container?.id === item.container?.id && item.designation === el.designation && item.chargeDescription === el.chargeDescription);

      if (existing) {
        if (item.prevVersion !== undefined) {
          existing.prevVersion = item.prevVersion;
        }
        if (item.newVersion !== undefined) {
          existing.newVersion = item.newVersion;
        }
      } else if (item.chargeDescription !== MscChargeDescription) {
        result.push({ ...item } as ShipmentChargeChangeDTM);
      }
    });

    const containersWithCharges: ContainerWithChangeChargesDTM[] = [];

    result.forEach((charge) => {
      if (charge.container) {
        const { container } = charge;

        const targetContainer = containersWithCharges.find(({ id }) => container?.id === id);

        if (targetContainer) {
          targetContainer.charges.push(charge);
        } else {
          containersWithCharges.push(ContainerWithChangeChargesDTM.fromPlain({
            key: container?.id,
            id: container?.id,
            type: container?.type,
            number: container?.number,
            charges: [charge],
          }));
        }
      }
    });
    return containersWithCharges;
  },
);

const getIntervalId = createSelector(
  localState,
  (state) => state.intervalId,
);

const getTimeoutId = createSelector(
  localState,
  (state) => state.timeoutId,
);

const getSchedulePlansIds = createSelector(
  localState,
  (state) => {
    const { schedules, chosenScheduleId } = state;
    const schedule = schedules.find(({ id }) => id === chosenScheduleId);

    if (!schedule) {
      return [];
    }

    return schedule.plans.map(({ id }) => id);
  },
);

const getShipmentAppliedChargesTotalCost = createSelector(
  localState,
  (state) => state.shipmentAppliedChargesTotalCost,
);

const getShipmentAdditionalChargesTotalCost = createSelector(
  localState,
  (state) => state.shipmentAdditionalChargesTotalCost,
);

const getQuotaRequestId = createSelector(
  localState,
  (state) => state.quotaRequestId,
);

const getQuotas = createSelector(
  localState,
  (state) => state.quotas,
);

const getCarrierByScheduleId = (scheduleId: number) => createSelector(
  localState,
  (state) => {
    const { quotas } = state;
    const quota = quotas.find(({ schedules: _schedules }) => _schedules?.map(({ id }) => id).includes(scheduleId));

    if (!quota?.contracts) {
      return undefined;
    }

    const { contracts = [] } = quota;
    const [contract] = contracts;

    if (!contract?.scac) {
      return undefined;
    }

    return CarrierSCACNames[contract.scac as ECarrierSCAC];
  },
);

export const rollShipmentWizardSelectors = {
  getChosenScheduleId,
  getQuotaId,
  getIsLoading,
  getErrorMessage,
  getIsRollShipmentAvailable,
  getOriginDate,
  getDestinationDate,
  getDestinationLocationName,
  getOriginLocationName,
  getShipmentAppliedChargesTotalCost,
  getIsScheduleChosen,
  getSchedules,
  getIsScheduleExpandedById,
  getScheduleTerminalCutoffDate,
  getScheduleDocumentCutoffDate,
  getScheduleHazmatCutoffDate,
  getScheduleETD,
  getScheduleETA,
  getScheduleDurationDays,
  getScheduleSchemeById,
  getSchedulerId,
  getIntervalId,
  getTimeoutId,
  getSchedulePlansIds,
  getScheduleTotalCost,
  getQuotaRequestId,
  getShipmentAdditionalChargesTotalCost,
  getQuotas,
  getComparedChargesTableBol,
  getComparedChargesTableContainer,
  getCarrierByScheduleId,
};
