import React, { memo } from 'react';

import { Container, InputContainer, Title } from './InputTitle.styled';

interface InfoCardComponentProps {
  title: string | React.ReactElement
  children?: React.ReactElement
}

export const InputTitle = memo(({ title, children }: InfoCardComponentProps) => (
  <Container>
    <Title>{title}</Title>
    <InputContainer>{children}</InputContainer>
  </Container>
));
