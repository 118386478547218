import {
  IsDefined, ValidateNested, IsOptional, IsString, IsBoolean, IsNumber,
} from 'class-validator';
import { plainToInstance, Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { IShippingPartyOverviewDTM, ShippingPartyOverviewDTM } from './TransportationOverview.dtm';

export interface IOverviewShippingPartiesDTM {
  shipper: IShippingPartyOverviewDTM
  consignee: IShippingPartyOverviewDTM
  notifyParty: IShippingPartyOverviewDTM
  accountHolder: IShippingPartyOverviewDTM
  forwarderAgent: IShippingPartyOverviewDTM
  deliveryAgent: IShippingPartyOverviewDTM
  oceanCarrier: IShippingPartyOverviewDTM
}

export class OverviewShippingPartiesDTM extends BaseDTM<IOverviewShippingPartiesDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  shipper: ShippingPartyOverviewDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  consignee: ShippingPartyOverviewDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  notifyParty: ShippingPartyOverviewDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  accountHolder: ShippingPartyOverviewDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  forwarderAgent: ShippingPartyOverviewDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  deliveryAgent: ShippingPartyOverviewDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  oceanCarrier: ShippingPartyOverviewDTM
}

export interface IOverviewReferencesDTM {
  mbl?: string
  contract?: string
  inttra?: string
  booking?: string
}

export class OverviewReferencesDTM extends BaseDTM<IOverviewReferencesDTM> {
  @IsOptional()
  @IsString()
  mbl?: string;

  @IsOptional()
  @IsString()
  contract?: string;

  @IsOptional()
  @IsString()
  inttra?: string;

  @IsOptional()
  @IsString()
  booking?: string;
}

export interface IRollWarningInfoDTM {
  shouldShowWarning: boolean;
  prevLocation: string;
  nextLocation: string;
  prevETD: string;
  prevETA: string;
  nextETD: string;
  nextETA: string;
}

export class RollWarningInfoDTM extends BaseDTM<IRollWarningInfoDTM> {
  @IsBoolean()
  shouldShowWarning: boolean;

  @IsString()
  prevLocation: string;

  @IsString()
  nextLocation: string;

  @IsString()
  prevETD: string;

  @IsString()
  prevETA: string;

  @IsString()
  nextETD: string;

  @IsString()
  nextETA: string;

  static createEmpty = () => plainToInstance(RollWarningInfoDTM, {
    prevLocation: '',
    nextLocation: '',
    prevETD: '',
    prevETA: '',
    nextETD: '',
    nextETA: '',
    shouldShowWarning: false,
  });
}

export interface IShipmentStatsCustomer {
  totalInvoiced: number
  totalPaid: number
  totalUnInvoiced: number
}

export class ShipmentStatsCustomer extends BaseDTM<IShipmentStatsCustomer> {
  @IsNumber()
  totalInvoiced: number

  @IsNumber()
  totalPaid: number

  @IsNumber()
  totalUnInvoiced: number

  static createEmpty = () => plainToInstance(ShipmentStatsCustomer, {
    totalInvoiced: '',
    totalPaid: '',
    totalUnInvoiced: '',
  })
}

export interface IShipmentAllStatsObjectReferenceDTM {
  id?: number
  type: string
  shipmentId?: number
}

export class ShipmentAllStatsObjectReferenceDTM extends BaseDTM<IShipmentAllStatsObjectReferenceDTM> {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  type: string

  @IsOptional()
  @IsNumber()
  shipmentId?: number

  static createEmpty = () => plainToInstance(ShipmentAllStatsObjectReferenceDTM, {
    id: 0,
    type: '',
    shipmentId: 0,
  })
}

export interface IShipmentAllStatsTaskStatsDTM {
  todo: number
  done: number
  expiring: number
  overdue: number
  alerts: number
  highTodo: number
  highExpiring: number
  highOverdue: number
}

export class ShipmentAllStatsTaskStatsDTM extends BaseDTM<IShipmentAllStatsTaskStatsDTM> {
  @IsNumber()
  todo: number

  @IsNumber()
  done: number

  @IsNumber()
  expiring: number

  @IsNumber()
  overdue: number

  @IsNumber()
  alerts: number

  @IsNumber()
  highTodo: number

  @IsNumber()
  highExpiring: number

  @IsNumber()
  highOverdue: number

  static createEmpty = () => plainToInstance(ShipmentAllStatsTaskStatsDTM, {
    todo: 0,
    done: 0,
    expiring: 0,
    overdue: 0,
  })
}

export interface IShipmentAllStatsNotificationStatsDTM {
  unread: number
  read: number
  unreadCritical: number
  unreadRegular: number
}

export class ShipmentAllStatsNotificationStatsDTM extends BaseDTM<IShipmentAllStatsNotificationStatsDTM> {
  @IsNumber()
  unread: number

  @IsNumber()
  read: number

  @IsNumber()
  unreadCritical: number

  @IsNumber()
  unreadRegular: number

  static createEmpty = () => plainToInstance(ShipmentAllStatsNotificationStatsDTM, {
    unread: 0,
    read: 0,
    unreadCritical: 0,
  })
}

export interface IShipmentAllStatsDTM {
  objectReference?: IShipmentAllStatsObjectReferenceDTM
  taskStats: IShipmentAllStatsTaskStatsDTM
  notificationStats: IShipmentAllStatsNotificationStatsDTM
  taskHighStats?: IShipmentAllStatsTaskStatsDTM
  alertsHighStats?: IShipmentAllStatsTaskStatsDTM
  requestsHighStats?: IShipmentAllStatsTaskStatsDTM
  notificationHighStats?: IShipmentAllStatsNotificationStatsDTM
}

export class ShipmentAllStatsDTM extends BaseDTM<IShipmentAllStatsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentAllStatsObjectReferenceDTM)
  objectReference?: ShipmentAllStatsObjectReferenceDTM

  @ValidateNested()
  @Type(() => ShipmentAllStatsTaskStatsDTM)
  taskStats: ShipmentAllStatsTaskStatsDTM

  @ValidateNested()
  @Type(() => ShipmentAllStatsNotificationStatsDTM)
  notificationStats: ShipmentAllStatsNotificationStatsDTM

  @ValidateNested()
  @Type(() => ShipmentAllStatsTaskStatsDTM)
  taskHighStats?: ShipmentAllStatsTaskStatsDTM

  @ValidateNested()
  @Type(() => ShipmentAllStatsTaskStatsDTM)
  alertsHighStats?: ShipmentAllStatsTaskStatsDTM

  @ValidateNested()
  @Type(() => ShipmentAllStatsTaskStatsDTM)
  requestsHighStats?: ShipmentAllStatsTaskStatsDTM

  @ValidateNested()
  @Type(() => ShipmentAllStatsNotificationStatsDTM)
  notificationHighStats?: ShipmentAllStatsNotificationStatsDTM

  static createEmpty = () => plainToInstance(ShipmentAllStatsDTM, {
    objectReference: ShipmentAllStatsObjectReferenceDTM.createEmpty(),
    taskStats: ShipmentAllStatsTaskStatsDTM.createEmpty(),
    notificationStats: ShipmentAllStatsNotificationStatsDTM.createEmpty(),
  })

  getShipmentAllTasks = () => `${this.taskStats.todo}`

  getShipmentAllAlerts = () => {
    const expiring = Number(this.taskStats.expiring || 0);
    const overdue = Number(this.taskStats.overdue || 0);
    const unreadCritical = Number(this.notificationStats.unreadCritical || 0);

    const summary = expiring + overdue + unreadCritical;

    return `${summary || ''}`;
  }

  getShipmentAllAlertsTasks = () => {
    const expiring = Number(this.taskStats.expiring || 0);
    const overdue = Number(this.taskStats.overdue || 0);
    const alerts = Number(this.taskStats.alerts || 0);

    const summary = expiring + overdue + alerts;

    return `${summary || ''}`;
  }

  getShipmentAllHighNotifications = () => {
    const tasks = Number(this.taskHighStats?.expiring || 0);
    const alerts = Number(this.alertsHighStats?.expiring || 0);
    const notifications = Number(this.notificationHighStats?.unreadCritical || 0);

    const summary = tasks + alerts + notifications;

    return summary;
  }
}

export interface IShipmentAccountStatsCustomer {
  totalPaid: number
  totalInvoiced: number
  totalUnInvoiced: number
  totalTransferred: number
  totalCredited: number
  accountBalance: number
}

export class ShipmentAccountStatsCustomer extends BaseDTM<IShipmentAccountStatsCustomer> {
  @IsNumber()
  totalPaid: number

  @IsNumber()
  totalInvoiced: number

  @IsNumber()
  totalUnInvoiced: number

  @IsNumber()
  totalTransferred: number

  @IsNumber()
  totalCredited: number

  @IsNumber()
  accountBalance: number

  static createEmpty = () => plainToInstance(ShipmentAccountStatsCustomer, {
    totalPaid: 0,
    totalInvoiced: 0,
    totalUnInvoiced: 0,
    totalTransferred: 0,
    totalCredited: 0,
    accountBalance: 0,
  })
}
