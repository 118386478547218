import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';

export class AuthUseCase extends BaseUseCase {
  setLastUserId(userId: string) {
    window.localStorage.setItem('lastUserId', userId);
  }

  getLastUserId() {
    return window.localStorage.getItem('lastUserId');
  }
}
