import { createSelector } from 'reselect';
import moment from 'moment/moment';

import {
  CONTAINER_TYPES_TO_NAME,
  ContainerReeferTypes,
  ContainerUsualTypes,
  EShippingPartyTypes,
  CargoReferenceTypeNamesShort,
  ShipmentRoutesType,
  ChargeCodeDesignation,
} from 'shipment-operations/constants';
import {
  CargoDTM,
  ShareInvoiceShipmentDetailsDTM,
  BookingConfirmationPDFGeneralInfoDTM,
  BookingConfirmationFreightItemsDTM,
} from 'shipment-operations/models/dtm';

import { RootState } from 'app-wrapper/store';
import { DateDtm } from 'app-wrapper/models/dtm';
import { SkypaceInformationDTM } from 'app-wrapper/constants';
import i18n from 'app-wrapper/i18n/i18n';

import { IQuotaServiceByIdContentRoutesLegsLocationDTM } from 'monetary/models/dtm';

const getLocationNameFromLocation = (location: IQuotaServiceByIdContentRoutesLegsLocationDTM): string => `${location.name}, ${location.country?.name}`;
const dateStringToCorrectUIFormat = (dateString: string) => {
  const date = DateDtm.fromPlain({
    date: dateString,
    offset: moment.parseZone(dateString).utcOffset(),
  });

  return date.getDateDMMMYYYYHHmmWithOffset();
};

const localState = (state: RootState) => state.bookingConfirmationPDF;
const getUserOrganizationData = (state: RootState) => state.userOrganizationData;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsLoadingFinished = createSelector(
  localState,
  (state) => state.isLoadingFinished,
);

const getBookingAgent = createSelector(
  localState,
  (state) => state.shippingParties.find(({ role }) => role === EShippingPartyTypes.BOOKING_AGENT),
);

const getHeaderCompanyName = createSelector(
  localState,
  getBookingAgent,
  (state, bookingAgent) => (bookingAgent && bookingAgent.company ? bookingAgent.company.name : ''),
);

const getHeaderAddress = createSelector(
  localState,
  getBookingAgent,
  (state, bookingAgent) => (bookingAgent && bookingAgent.address ? `${bookingAgent.address.address1 || ''} ${bookingAgent.address.address2 || ''}` : ''),
);

const getHeaderAddressLine = createSelector(
  localState,
  getBookingAgent,
  (state, bookingAgent) => (bookingAgent && bookingAgent.address ? `${bookingAgent.address.city}, ${bookingAgent.address.state || ''} ${bookingAgent.address.postalCode}, ${bookingAgent.address.country}` : ''),
);

const getHeaderPhone = createSelector(
  localState,
  getBookingAgent,
  (state, bookingAgent) => (bookingAgent && bookingAgent.contact && bookingAgent.contact.phone ? bookingAgent.contact.phone : i18n.t('Phone is not specified') as string),
);

const getHeaderEmail = createSelector(
  localState,
  getBookingAgent,
  (state, bookingAgent) => (bookingAgent && bookingAgent.contact && bookingAgent.contact.email ? bookingAgent.contact.email : i18n.t('Phone is not specified') as string),
);

const getHeaderSkypaceInformation = createSelector(
  getUserOrganizationData,
  (state) => {
    if (state?.organization?.address.country?.toLocaleLowerCase() === 'us') {
      return SkypaceInformationDTM.getCompanyUS();
    }

    return SkypaceInformationDTM.getCompanyUK();
  },
);

const getLowestSequenceLeg = createSelector(
  localState,
  (state) => state.lowestSequenceLeg,
);

const getLowestSequenceSeaLeg = createSelector(
  localState,
  (state) => state.lowestSequenceSeaLeg,
);

const getHighestSequenceSeaLeg = createSelector(
  localState,
  (state) => state.highestSequenceSeaLeg,
);

const getHighestSequenceLeg = createSelector(
  localState,
  (state) => state.highestSequenceLeg,
);

const getLowestTransportation = createSelector(
  localState,
  (state) => state.lowestTransportation,
);

const getLowestSeaTransportation = createSelector(
  localState,
  (state) => state.lowestSeaTransportation,
);

const getHighestTransportation = createSelector(
  localState,
  (state) => state.highestTransportation,
);

const getRoutingInformationItems = createSelector(
  localState,
  getLowestSequenceLeg,
  getHighestSequenceLeg,
  getLowestSequenceSeaLeg,
  getHighestSequenceSeaLeg,
  (
    state,
    lowestLeg,
    highestLeg,
    lowestSeaLeg,
    highestSeaLeg,
  ) => {
    const items: ShareInvoiceShipmentDetailsDTM[] = [];
    const {
      shipment,
      shippingParties,
      countriesList,
    } = state;
    const cargoSupplier = shippingParties.find(({ role }) => role === EShippingPartyTypes.CARGO_SUPPLIER);
    const cargoReceiver = shippingParties.find(({ role }) => role === EShippingPartyTypes.CARGO_RECEIVER);

    if (shipment?.getIsOriginDoor() && cargoSupplier?.address) {
      const { address } = cargoSupplier;
      const country = countriesList.find(({ code }) => address.country === code);

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Place of Receipt'),
        subtitles: [`${address.city}, ${country?.name || address.country}`],
      }));
    } else if (lowestLeg?.departureLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Place of Receipt'),
        subtitles: [getLocationNameFromLocation(lowestLeg?.departureLocation)],
      }));
    }

    if (lowestSeaLeg?.departureLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Port of Loading'),
        subtitles: [getLocationNameFromLocation(lowestSeaLeg?.departureLocation)],
      }));
    }

    if (highestSeaLeg?.arrivalLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Port of Discharge'),
        subtitles: [getLocationNameFromLocation(highestSeaLeg?.arrivalLocation)],
      }));
    }

    if (shipment?.getIsDestinationDoor() && cargoReceiver?.address) {
      const { address } = cargoReceiver;
      const country = countriesList.find(({ code }) => address.country === code);

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Place of Delivery'),
        subtitles: [`${address.city}, ${country?.name || address.country}`],
      }));
    } else if (highestLeg?.arrivalLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Place of Delivery'),
        subtitles: [getLocationNameFromLocation(highestLeg?.arrivalLocation)],
      }));
    }

    return items;
  },
);

const getBookingDetailsItems = createSelector(
  localState,
  getLowestTransportation,
  getHighestTransportation,
  getLowestSeaTransportation,
  getRoutingInformationItems,
  (
    state,
    lowestTransportation,
    highestTransportation,
    lowestSeaTransportation,
    routingItems,
  ) => {
    let items: ShareInvoiceShipmentDetailsDTM[] = [];

    if (lowestTransportation) {
      const { schedule } = lowestTransportation;
      const { departureTime } = schedule;
      const date = DateDtm.fromPlain({
        date: departureTime,
        offset: moment.parseZone(departureTime).utcOffset(),
      });

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('ETD'),
        subtitles: [date.getDateDMMMYYYYHHmmWithOffset()],
      }));
    }

    if (highestTransportation) {
      const { schedule } = highestTransportation;
      const { arrivalTime } = schedule;
      const date = DateDtm.fromPlain({
        date: arrivalTime,
        offset: moment.parseZone(arrivalTime).utcOffset(),
      });

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('ETA'),
        subtitles: [date.getDateDMMMYYYYHHmmWithOffset()],
      }));
    }

    if (lowestSeaTransportation) {
      const { transport } = lowestSeaTransportation;

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Vessel Name'),
        subtitles: [transport.name],
      }));
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Voyage Number'),
        subtitles: [`${lowestSeaTransportation.voyageCode}`],
      }));
    }

    if (routingItems.length) {
      items = [...items, ...routingItems];
    }

    const { fullShipment } = state;

    if (fullShipment && fullShipment.bookingDetails) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Carrier Reference'),
        subtitles: [fullShipment.bookingDetails.carrierReferenceNumber],
      }));
    }

    if (fullShipment) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Shipment ID'),
        subtitles: [`${fullShipment.id}`],
      }));

      if (fullShipment.shipmentReference) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Customer Reference'),
          subtitles: [fullShipment.shipmentReference],
        }));
      }
    }

    return items;
  },
);

const getCutoffItems = createSelector(
  localState,
  getLowestTransportation,
  (
    state,
    lowestTransportation,
  ) => {
    const items: ShareInvoiceShipmentDetailsDTM[] = [];

    if (lowestTransportation) {
      const { schedule } = lowestTransportation;
      const {
        terminalCutOff,
        documentCutOff,
        vgmCutOff,
        hazmatCutOff,
      } = schedule;

      if (terminalCutOff) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Port'),
          subtitles: [dateStringToCorrectUIFormat(terminalCutOff)],
        }));
      }

      if (documentCutOff) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Documentation'),
          subtitles: [dateStringToCorrectUIFormat(documentCutOff)],
        }));
      }

      if (vgmCutOff) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('VGM'),
          subtitles: [dateStringToCorrectUIFormat(vgmCutOff)],
        }));
      }

      if (hazmatCutOff) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Hazmat'),
          subtitles: [dateStringToCorrectUIFormat(hazmatCutOff)],
        }));
      }
    }

    return items;
  },
);

const getCargos = createSelector(
  localState,
  (state) => (state.fullShipment ? state.fullShipment.cargos : []),
);

const getFullShipment = createSelector(
  localState,
  (state) => state.fullShipment,
);

const getContainersItems = createSelector(
  localState,
  getFullShipment,
  (state, shipment) => {
    if (!shipment) {
      return [];
    }

    const items: ShareInvoiceShipmentDetailsDTM[] = [];
    const { containers } = shipment;
    const containersToTypeMap: Record<ContainerUsualTypes | ContainerReeferTypes, number> = {
      [ContainerUsualTypes['22G0']]: 0,
      [ContainerUsualTypes['45G0']]: 0,
      [ContainerUsualTypes['42G0']]: 0,
      [ContainerUsualTypes.L5G0]: 0,
      [ContainerReeferTypes['22R0']]: 0,
      [ContainerReeferTypes['25R1']]: 0,
      [ContainerReeferTypes['42R0']]: 0,
      [ContainerReeferTypes['45R1']]: 0,
      [ContainerReeferTypes.L5R1]: 0,
    };

    containers.forEach(({ type }) => {
      containersToTypeMap[type] += 1;
    });

    const containersToTypeKeys = Object.keys(containersToTypeMap);
    const containersSubtitle: string[] = [];

    containersToTypeKeys.forEach((containersKey) => {
      const containersAmount = containersToTypeMap[containersKey as ContainerUsualTypes | ContainerReeferTypes];

      if (containersAmount) {
        containersSubtitle.push(CONTAINER_TYPES_TO_NAME[containersKey as ContainerUsualTypes | ContainerReeferTypes]);
      }
    });

    items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
      title: i18n.t('Container Type(s)'),
      subtitles: [containersSubtitle.join(', ')],
    }));

    items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
      title: i18n.t('Total Number of Containers'),
      subtitles: [`(${containers.length})`],
    }));

    return items;
  },
);

const getMarks = createSelector(
  localState,
  getCargos,
  (state, cargos) => {
    const references = cargos.reduce((acc, current) => [...acc, ...current.references], [] as CargoDTM['references']);

    return references.map((reference) => (reference.type ? `${CargoReferenceTypeNamesShort[reference.type]}${reference.value}` : '')).filter((mark) => !!mark);
  },
);

const getNotes = createSelector(
  localState,
  getCargos,
  (state, cargos) => cargos.filter(({ marks }) => marks).map(({ marks }) => marks) as string[],
);

const getPickupAndDropoffItems = createSelector(
  localState,
  getFullShipment,
  (
    storeState,
    fullShipment,
  ) => {
    if (!fullShipment) {
      return [];
    }

    const items: Array<ShareInvoiceShipmentDetailsDTM | undefined> = [];

    const { locations } = fullShipment;
    const emptyContainerPickup = locations.find(({ type }) => type === ShipmentRoutesType.ORIGIN_CONTAINER_YARD);
    const fullContainerDropoff = locations.find(({ type }) => type === ShipmentRoutesType.ORIGIN_CONTAINER_TERMINAL);

    if (emptyContainerPickup) {
      const {
        terminalName,
        passCode,
        address1,
        address2,
        address3,
        address4,
        city,
        state,
        country,
        postcode,
        time,
      } = emptyContainerPickup;

      const subtitles = [`${terminalName}`];

      if (passCode) {
        subtitles.push(`${i18n.t('Passcode')}: ${passCode}`);
      }

      if (address1) {
        subtitles.push(address1);
      }
      if (address2) {
        subtitles.push(address2);
      }
      if (address3) {
        subtitles.push(address3);
      }
      if (address4) {
        subtitles.push(address4);
      }

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Empty Container Pick-up Location'),
        subtitles: [
          ...subtitles,
          `${city}, ${state || ''} ${postcode}, ${country}`,
        ],
      }));

      if (time) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Earliest CY Empty Release'),
          subtitles: [time.getDateDMMMYYYYHHmmWithOffset()],
        }));
      } else {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Earliest CY Empty Release'),
          subtitles: ['Not Available'],
        }));
      }
    }

    if (fullContainerDropoff) {
      const {
        terminalName,
        passCode,
        address1,
        address2,
        address3,
        address4,
        city,
        state,
        country,
        postcode,
        time,
      } = fullContainerDropoff;

      const subtitles = [`${terminalName}`];

      if (passCode) {
        subtitles.push(`${i18n.t('Passcode')}: ${passCode}`);
      }

      if (address1) {
        subtitles.push(address1);
      }
      if (address2) {
        subtitles.push(address2);
      }
      if (address3) {
        subtitles.push(address3);
      }
      if (address4) {
        subtitles.push(address4);
      }

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Full Container Drop-Off Location'),
        subtitles: [
          ...subtitles,
          `${city}, ${state || ''} ${postcode}, ${country}`,
        ],
      }));

      if (time) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Latest Closing Date'),
          subtitles: [time.getDateDMMMYYYYHHmmWithOffset()],
        }));
      } else {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Latest Closing Date'),
          subtitles: ['Not Available'],
        }));
      }
    }

    return items;
  },
);

const getGeneralInfo = createSelector(
  localState,
  (state) => {
    const { shippingParties, fullShipment } = state;
    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);

    if (!customer || !fullShipment) {
      return null;
    }

    const { company, address, contact } = customer;

    return BookingConfirmationPDFGeneralInfoDTM.fromPlain({
      bcNumber: `${fullShipment.id}`,
      companyName: company ? company.name : '',
      address: `${address?.address1}`,
      addressLine: `${address?.city}, ${address?.state || ''} ${address?.postalCode}, ${address?.country}`,
      fullName: contact ? contact.fullName : '',
      phone: contact ? contact.phone : '',
      email: contact ? contact.email : '',
    });
  },
);

const getGeneralInfoTotalAmount = createSelector(
  localState,
  (state) => {
    const { chargesPairs } = state;
    const appliedCharges = chargesPairs.filter(({ applied }) => applied);

    return appliedCharges.reduce((acc, current) => acc + current.buyTotalCost, 0);
  },
);

const getDeclaredCargoValue = createSelector(
  localState,
  getFullShipment,
  (state, fullShipment) => {
    if (!fullShipment) {
      return 'USD 0';
    }

    const value = fullShipment.cargos.reduce((acc, current) => acc + (current.value ? +current.value : 0), 0);

    return `USD ${value.toFixed(2)}`;
  },
);

const getPaymentTermsItems = createSelector(
  localState,
  getFullShipment,
  (state, fullShipment) => {
    if (!fullShipment) {
      return [];
    }

    const { chargesPairs } = state;
    const { paymentTerms } = fullShipment;
    const originTotal = chargesPairs
      .filter(({ applied, designation }) => applied && designation === ChargeCodeDesignation.ORIGIN)
      .reduce((acc, current) => acc + current.buyTotalCost, 0);
    const freightTotal = chargesPairs
      .filter(({ applied, designation }) => applied && designation === ChargeCodeDesignation.FREIGHT)
      .reduce((acc, current) => acc + current.buyTotalCost, 0);
    const destinationTotal = chargesPairs
      .filter(({ applied, designation }) => applied && designation === ChargeCodeDesignation.DESTINATION)
      .reduce((acc, current) => acc + current.buyTotalCost, 0);

    return [
      BookingConfirmationFreightItemsDTM.fromPlain({
        title: i18n.t('Origin'),
        subtitle: paymentTerms.origin.toUpperCase(),
        text: `USD ${originTotal.toFixed(2)}`,
      }),
      BookingConfirmationFreightItemsDTM.fromPlain({
        title: i18n.t('Freight'),
        subtitle: paymentTerms.freight.toUpperCase(),
        text: `USD ${freightTotal.toFixed(2)}`,
      }),
      BookingConfirmationFreightItemsDTM.fromPlain({
        title: i18n.t('Destination'),
        subtitle: paymentTerms.destination.toUpperCase(),
        text: `USD ${destinationTotal.toFixed(2)}`,
      }),
    ];
  },
);

export const bookingConfirmationPDFSelectors = {
  getIsLoading,
  getIsLoadingFinished,
  getBookingAgent,
  getHeaderCompanyName,
  getHeaderAddress,
  getHeaderAddressLine,
  getHeaderPhone,
  getHeaderEmail,
  getHeaderSkypaceInformation,
  getBookingDetailsItems,
  getCutoffItems,
  getCargos,
  getContainersItems,
  getMarks,
  getNotes,
  getPickupAndDropoffItems,
  getGeneralInfo,
  getGeneralInfoTotalAmount,
  getDeclaredCargoValue,
  getPaymentTermsItems,
};
