import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { ICountryListDTM } from 'app-wrapper/models/dtm';
import { EOrganizationMemberRole } from 'user-management/constants';
import { formatAllowedMembershipTypes } from 'user-management/repository/store/UserOrganizationData/UserOrganizationData.selectors';

const localState = (state: RootState) => state.newOrganizationData;

const limitedCountries = [
  'AT',
  'AU',
  'BE',
  'BG',
  'HR',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'IE',
  'IT',
  'LV',
  'LT',
  'NL',
  'PL',
  'PT',
  'ES',
  'SE',
  'NO',
  'AE',
  'SA',
  'IL',
  'CA',
  'MX',
  'JP',
  'KR',
  'US',
  'GB',
  'CN',
  'IN',
  'QA',
  'BD',
  'VN',
  'JO',
  'HK',
];

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getUserOrganization = createSelector(
  localState,
  (state) => state.organization,
);

const getUserOrganizationUpdate = createSelector(
  localState,
  (state) => state.organizationUpdate,
);

const getOrganizationAllowedMembershipTypes = createSelector(
  getUserOrganizationUpdate,
  formatAllowedMembershipTypes,
);

const organizationFieldsErrors = createSelector(
  localState,
  (state) => state.organizationFieldsErrors,
);

const organizationCountries = createSelector(
  localState,
  (state) => state.countries?.map((itemList): ICountryListDTM => ({
    code: itemList.code,
    name: itemList.name,
  })),
);

const organizationCountriesLimited = createSelector(
  localState,
  (state) => state.countries?.filter((item) => limitedCountries.includes(item.code))
    ?.map((itemList): ICountryListDTM => ({
      code: itemList.code,
      name: itemList.name,
    })),
);

const getCurrentOrganizationUser = createSelector(
  localState,
  (state) => state.organizationUserData,
);

const getCurrentOrganizationUserRole = createSelector(
  localState,
  (state) => state.organizationUserData?.role,
);

const isAdmin = createSelector(
  localState,
  (state) => state.organizationUserData?.role === EOrganizationMemberRole.admin,
);

const getIsTradeReferenceCollapsed = createSelector(
  localState,
  (state) => state.isTradeReferenceCollapsed,
);

const getTradeReference = createSelector(
  localState,
  (state) => state.tradeReference,
);

const getWasSubmittedOnce = createSelector(
  localState,
  (state) => state.wasSubmittedOnce,
);

const getShouldValidateTradeReference = createSelector(
  getTradeReference,
  getWasSubmittedOnce,
  (tradeReference, wasSubmittedOnce) => {
    const {
      companyName,
      firstName,
      lastName,
      email,
      phone,
    } = tradeReference;

    return !!(companyName || firstName || lastName || email || phone) && wasSubmittedOnce;
  },
);

const getShouldSaveTradeReference = createSelector(
  getUserOrganization,
  getTradeReference,
  (organization, tradeReference) => !!(tradeReference.companyName.length || tradeReference.firstName.length || tradeReference.lastName.length || tradeReference.email.length || tradeReference.phone.length),
);

const getIsTradeReferenceEmpty = createSelector(
  getTradeReference,
  (tradeReference) => !tradeReference.companyName.length,
);

export const newOrganizationDataSelectors = {
  getIsLoading,
  getCurrentOrganizationUser,
  getCurrentOrganizationUserRole,
  getUserOrganization,
  getUserOrganizationUpdate,
  isAdmin,
  organizationCountries,
  organizationCountriesLimited,
  organizationFieldsErrors,
  getIsTradeReferenceCollapsed,
  getTradeReference,
  getShouldValidateTradeReference,
  getShouldSaveTradeReference,
  getIsTradeReferenceEmpty,
  getOrganizationAllowedMembershipTypes,
};
