import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FooterComponent } from './Footer.component';

const FooterContainer = observer(() => {
  const isLoading = useSelector(R.selectors.bookingWizard.getIsLoading);
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);
  const currentStep = useSelector(R.selectors.bookingWizard.getCurrentStep);
  const voyageDuration = useSelector(R.selectors.bookingWizard.getVoyageDuration);
  const origin = useSelector(R.selectors.bookingWizard.getShipmentOrigin);
  const destination = useSelector(R.selectors.bookingWizard.getShipmentDestination);
  const { weight, volume } = useSelector(R.selectors.bookingWizard.getCargosTotalWeightAndVolume);
  const containersAmount = useSelector(R.selectors.bookingWizard.getContainersAmount);
  const isAgreeAndBookDisabled = useSelector(R.selectors.bookingWizard.getIsAgreeAndBookDisabled);
  const totalCost = useSelector(R.selectors.bookingWizard.getTotalCost);
  const carrierName = useSelector(R.selectors.bookingWizard.getNameSCAC);
  const isWaitingApproval = useSelector(R.selectors.bookingWizard.getIsShipmentWaitingForApproval);
  const shouldChangeQuotaDueToContainer = useSelector(R.selectors.bookingWizard.getShouldChangeQuotaDueToContainer);

  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesDrawerTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerTotalCost;
  const servicesGetQuoteTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesDrawerServicesGetQuoteTotalCost;

  const sumTotalCost = Number(totalCost || 0) + (Number(addAdditionalServicesDrawerTotalCost || 0) - Number(servicesGetQuoteTotalCost || 0));

  return (
    <FooterComponent
      isLoading={isLoading}
      isContentUpdating={isContentUpdating}
      containersAmount={containersAmount}
      currentStep={currentStep}
      goPickupAndDeliveryStep={UC.bookingWizard.saveDrayageBookingDetailsWithContainerValueCheck}
      goBookingDetailsStep={UC.bookingWizard.goFirstStep}
      saveSuppliers={UC.bookingWizard.saveSuppliers}
      agreeAndBook={UC.bookingWizard.agreeAndBook}
      goSecondStep={UC.bookingWizard.setSecondStep}
      saveScheduleStep={UC.bookingWizard.saveScheduleStep}
      voyageDuration={voyageDuration}
      isAgreeAndBookDisabled={isAgreeAndBookDisabled}
      origin={origin}
      destination={destination}
      totalWeight={weight}
      totalVolume={volume}
      totalCost={`${sumTotalCost}`}
      nameSCAC={carrierName}
      isShipmentWaitingApproval={isWaitingApproval}
      shouldChangeQuotaDueToContainer={shouldChangeQuotaDueToContainer}
    />
  );
});

export { FooterContainer as Footer };
