import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import styled from 'styled-components';

export const Customers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const HiddenCustomers = styled.div`
  margin-left: 4px;
  padding: 1px 8px;
  height: 22px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme?.themesColors?.neutralBranding4}`};
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  font-size: 12px;
  line-height: 20px;
`;

export const NoCustomersIcon = styled(ClockCircleOutlined)`
  color: rgba(209, 215, 226, 1);
`;
