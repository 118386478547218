import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';

interface IOverviewControllerState {
  test: string
}

const initialState: IControllerState<IOverviewControllerState> = {
  isLoading: true,
  test: '',
};

export class ShipmentTransportationImportInlandDeliveryOverviewStoreStore extends ControllerStore<IControllerState<IOverviewControllerState>> {}

export const createShipmentTransportationImportInlandDeliveryOverviewStore = (_initialState?: IControllerState<IOverviewControllerState>) => new ShipmentTransportationImportInlandDeliveryOverviewStoreStore(_initialState || initialState);
