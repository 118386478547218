import styled from 'styled-components';

import { Modal } from 'shipment-operations/view/pages/ShipmentOverview/components/CancelShipmentModal/CancelShipmentModal.styled';

export const StyledModal = styled(Modal)`
  button:last-of-type {
    border-color: ${({ theme }) => theme.themesColors.primaryBranding6} !important;
    background: ${({ theme }) => theme.themesColors.primaryBranding6} !important;
  }

  button:last-of-type:disabled {
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }
`;
