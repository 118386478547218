import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { RouteNames } from 'app-wrapper/constants';

import { UC as ShipmentUC } from 'shipment-operations/controllers';
import { CommandCenterTasksComponent } from './CommandCenter/CommandCenterTasks.component';

const CommandCenterTasksAlertsPage: FC = () => {
  const { t } = useTranslation();
  const commandCenterIsHaveActiveNotification = useSelector(R.selectors.commandCenter.getCommandCenterIsHaveActiveNotification);
  const commandCenterCurrent = useSelector(R.selectors.commandCenter.getCommandCenterCurrent);

  const commandCenterCurrentOverdueTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentOverdueTasks);
  const commandCenterCurrentTodayTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentTodayTasks);
  const commandCenterCurrentThisWeekTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentThisWeekTasks);
  const commandCenterCurrentLaterTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentLaterTasks);

  const commandCenterCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatus);
  const commandCenterCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatusAdd);
  const commandCenterActionCurrentIsClearAllFiltersTasks = useSelector(R.selectors.commandCenter.getCommandCenterActionCurrentIsClearAllFiltersTasks);

  return (
    <CommandCenterTasksComponent
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterTasksShowOnlyUnread()}
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStart={UC.CommandCenter.onStartTaskAlertsPage()}
      nextPageTasks={UC.CommandCenter.nextPageTasks()}
      checkAnotherDownloadNextPageTask={UC.CommandCenter.checkAnotherDownloadNextPageTask}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterDueDates()}
      onResetReceivedDate={UC.CommandCenter.onResetFilterDueDates()}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterTasksDueDates()}
      onApplyModule={UC.CommandCenter.onApplyTasksModule()}
      onResetModule={UC.CommandCenter.onResetTasksModule()}
      onChangeGroupModule={UC.CommandCenter.onChangeTasksGroupModule()}
      onApplyAssignee={UC.CommandCenter.onApplyTasksAssignee()}
      onResetAssignee={UC.CommandCenter.onResetTasksAssignee()}
      onChangeGroupAssignee={UC.CommandCenter.onChangeTasksGroupAssignee()}
      onChangeGroupObject={UC.CommandCenter.onChangeTasksGroupObject()}
      onApplyObject={UC.CommandCenter.onApplyTasksObject()}
      onResetObject={UC.CommandCenter.onResetTasksObject()}
      openAgreementsDrawer={UC.agreements.openDrawerFromTasks}
      onClearAllFilters={UC.CommandCenter.onClearAllTasksFilters()}
      treeDataObject={commandCenterCurrent?.filters?.filterTreeDataObject?.treeData}
      isVisibleClearAll={commandCenterActionCurrentIsClearAllFiltersTasks}
      filters={commandCenterCurrent?.filters}
      isLoad={commandCenterCurrentStatus}
      isAlerts
      isLoadAdd={commandCenterCurrentStatusAdd}
      overdueTasks={commandCenterCurrentOverdueTasks}
      todayTasks={commandCenterCurrentTodayTasks}
      thisWeekTasks={commandCenterCurrentThisWeekTasks}
      laterTasks={commandCenterCurrentLaterTasks}
      countTasksOverdue={commandCenterCurrent.totalElementsOverdue}
      countTasksToday={commandCenterCurrent.totalElementsToday}
      countTasksThisWeek={commandCenterCurrent.totalElementsThisWeek}
      countTasksLater={commandCenterCurrent.totalElementsLater}
      infoCountAllTasks={commandCenterCurrent.infoCountAllTasks}
      infoCountOverdueTasks={commandCenterCurrent.infoCountOverdueTasks}
      infoCountDueTasks={commandCenterCurrent.infoCountDueTasks}
      infoCountPlannedTasks={commandCenterCurrent.infoCountPlannedTasks}
      isHaveNotification={commandCenterIsHaveActiveNotification}
      titleLink2={RouteNames.COMMAND_CENTER_TASKS_ALERTS_COMPLETED()}
      titleHeader={t('Alerts')}
      emptyContentTextNoTasks={t('There are no alerts assigned to you right now. See how you can assist your colleagues.')}
      titleStatisticsBlockFirst={t('All Alerts')}
      titleFilterBlockAll={t('View Organization Alerts')}
      titleNameFirstColumn={t('Alerts')}
      setShipmentId={ShipmentUC.overview.setShipmentId}
      openTaskDrawer={UC.drawer.openTaskDrawer}
    />
  );
};

const CommandCenterTasksAlertsPageCache = memo(CommandCenterTasksAlertsPage);

export { CommandCenterTasksAlertsPageCache as CommandCenterTasksAlertsPage };
