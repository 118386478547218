import React, { DOMAttributes, forwardRef } from 'react';

import { CopyIcon } from './CopyOutlined.styled';

export const CopyOutlined = forwardRef<any, DOMAttributes<any>>((props, ref) => (
  <CopyIcon
    className="CopyOutlined"
    ref={ref as React.RefObject<any>}
    {...props}
  />
));
