import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormWeightInputComponent } from './CargoFormWeightInput.component';

const CargoFormWeightInputContainer: FC = () => {
  const {
    weight,
    errors,
    touchedFields,
    wasUpdateAttempted,
  } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormWeightInputComponent
      weight={weight}
      touchField={UC.cargo.touchField}
      setWeight={UC.cargo.setWeight}
      error={!!errors.weight && (touchedFields.weight || wasUpdateAttempted)}
      cargosAvailability={permissions.cargosAvailability}
      customerCargosAvailability={permissions.customerCargosAvailability}
    />
  );
};

export {
  CargoFormWeightInputContainer as CargoFormWeightInput,
};
