import React, { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { TableCell } from 'app-wrapper/view/guideline';
import { Link } from 'react-router-dom';
import { RouteNames } from 'app-wrapper/constants';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/es/table';
import { ShipmentComponent } from 'app-wrapper/view/pages/Overview/components/Dashboard/components/Shipment/Shipment.component';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { EmptyStateForSection } from 'app-wrapper/view/components';
import { RoadShipSvg } from 'app-wrapper/view/icons';

const PendingConfirmationShipmentContainer = observer(() => {
  const { overviewPendingConfirmationsShipmentStore } = useMobxStore();
  const { t } = useTranslation();

  const columns: ColumnType<object>[] = useMemo(() => [
    {
      title: t('Shipment'),
      key: 'id',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          <Link to={RouteNames.SHIPMENT_OVERVIEW(shipment.id)}>{shipment.shipmentName}</Link>

        </TableCell>
      ),
    },
    {
      title: t('Origin'),
      key: 'origin',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.origin.address}
        </TableCell>
      ),
    },
    {
      title: t('Destination'),
      key: 'destination',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.destination.address}
        </TableCell>
      ),
    },
    {
      title: t('Carrier'),
      key: 'carrier',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.carrier}
        </TableCell>
      ),
    },
    {
      title: t('ETD'),
      key: 'origin',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.origin.estimatedDate?.getFormatDMMMHHmm()}
        </TableCell>
      ),
    },
  ], [t, overviewPendingConfirmationsShipmentStore.initialState.shipments]);

  const redirectLink = `${RouteNames.SHIPMENTS_ACTIVE()}?status=PENDING_CONFIRMATION`;

  return (
    <ShipmentComponent
      title={t('Pending Confirmations')}
      emptyState={(
        <EmptyStateForSection
          icon={(
            <RoadShipSvg
              fill={themesColors.emptyStateIconColor}
            />
          )}
          headerText={t('No Arriving Shipments Yet')}
          contentText={t('List of arriving shipments here')}
        />
      )}
      shipments={overviewPendingConfirmationsShipmentStore.shipmentsWithKey}
      columns={columns}
      redirectLink={redirectLink}
      isLoading={overviewPendingConfirmationsShipmentStore.initialState.isLoading}
    />
  );
});

export { PendingConfirmationShipmentContainer as PendingConfirmationShipment };
