import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';

import { ContainerActionsComponenet } from 'shipment-operations/view/components/BookingDrawer/components/ContainersDetails/components/Container/components/ContainerActions/ContainerActions.component';

interface IContainerActionsProps {
  containerId: string;
}

export const ContainerActions: FC<IContainerActionsProps> = React.memo(({ containerId }: IContainerActionsProps) => (
  <ContainerActionsComponenet
    deleteContainer={UC.bookingWizard.removeNotSavedContainer}
    containerId={containerId}
  />
));
