export enum EShippingPartyTypes {
  // required
  SHIPPER = 'SHIPPER',
  CONSIGNEE = 'CONSIGNEE',
  NOTIFY_PARTY = 'NOTIFY_PARTY',
  CUSTOMER = 'CUSTOMER',
  HOUSE_SHIPPER = 'HOUSE_SHIPPER',
  HOUSE_CONSIGNEE = 'HOUSE_CONSIGNEE',
  HOUSE_NOTIFY_PARTY = 'HOUSE_NOTIFY_PARTY',

  // not required
  EXPORT_BROKER = 'EXPORT_BROKER',
  IMPORT_BROKER = 'IMPORT_BROKER',
  SECOND_NOTIFY_PARTY = 'SECOND_NOTIFY_PARTY',
  FORWARDER_AGENT = 'FORWARDER_AGENT',
  DELIVERY_AGENT = 'DELIVERY_AGENT',
  ACCOUNT_HOLDER = 'ACCOUNT_HOLDER',
  ULTIMATE_CUSTOMER = 'ULTIMATE_CUSTOMER',
  OCEAN_CARRIER = 'OCEAN_CARRIER',
  BOOKING_AGENT = 'BOOKING_AGENT',

  ORIGIN_PARTNER_AGENT = 'ORIGIN_PARTNER_AGENT',
  DESTINATION_PARTNER_AGENT = 'DESTINATION_PARTNER_AGENT',
  CARGO_SUPPLIER = 'CARGO_SUPPLIER',
  CARGO_RECEIVER = 'CARGO_RECEIVER',
}

export enum ShippingPartyRoutes {
  HOUSE_BILL_OF_LADING = 'HOUSE_BILL_OF_LADING',
  MASTER_BILL_OF_LADING = 'MASTER_BILL_OF_LADING',
  OTHER = 'OTHER',
}
