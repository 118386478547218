import styled from 'styled-components';
import { Container } from 'shipment-operations/view/components';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';

export const MainContainer = styled(Container)`
  margin-top: 0;
  padding: 0 16px 16px 16px;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: #202C3C;
  margin: 0;
  padding: 16px 0 0 0;
`;

export const ContainerTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
  margin: 0;
  padding-top: 14px;
`;

export const ChargeName = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #202C3C;
  margin: 0;
  padding: 16px 0 16px 0;
  text-transform: capitalize;
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 36px;
  border-top: 1px solid #EAEEF8;
  border-bottom: 1px solid #EAEEF8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FirstColumn = styled.div`
  width: 50%;
  color: #73819B;
  font-weight: 400;
  font-size: 12px;
`;

export const SecondColumn = styled.div`
  width: 25%;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
`;

export const ColumnWithBorder = styled(SecondColumn).attrs((props: {
  fullColumns?: boolean,
}) => ({
  fullColumns: props.fullColumns,
}))`
  position: relative;

  ${({ fullColumns }) => fullColumns && (
    `
      &:before {
      position: absolute;
      top: 50%;
      right: -10px;
      width: 1px;
      height: 1.6em;
      background-color: #b6bed3;
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
     }
    `)
}
`;

export const StyledRow = styled.div`
  font-weight: 400;
  font-size: 14px;
  padding: 16.5px 0;
  border-bottom: 1px solid #EAEEF8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCell = styled.div`
  width: 50%;
`;

export const StyledCellRight = styled.div`
  width: 25%;
  text-align: right;
`;

export const StyledCellWithBorder = styled(SecondColumn).attrs((props: {
  fullColumns?: boolean,
}) => ({
  fullColumns: props.fullColumns,
}))`
  position: relative;

  ${({ fullColumns }) => fullColumns && (
    `
      &:before {
      position: absolute;
      top: 50%;
      right: -10px;
      width: 1px;
      height: 1.6em;
      background-color: #b6bed3;
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
     }
    `)
}
`;

export const StyledHeaderIcon = styled(ArrowRightOutlined)`
  color: #202C3C;
  margin: 0 6px;
  font-size: 10px;
`;

export const StyledPerUnit = styled.span`
  color: #73819B;
`;

export const ChargeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DaysWrapper = styled.div`
  background: #F9F9FB;
  border-radius: 3px;
  color: #202C3C;
  padding: 2px 7px;
`;

export const StyledDays = styled.span`
  font-weight: 700;
`;
