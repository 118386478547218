import React, { FC, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import Tooltip from 'antd/es/tooltip';

import { ContainerWithCargoDTM, TemperatureControlDTM } from 'shipment-operations/models/dtm';
import { ContainerIsReeferType, ContainerTypesConst } from 'shipment-operations/constants';
import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';
import { NoDataStub } from 'shipment-operations/view/components';

import {
  SBLTable,
  SBLTableCell,
  SBLTableContainerNameCell,
  SBLTableHeader,
  SBLTableLayoutNoMargin,
  SBLWarningIcon,
  StyledExpandIcon,
} from './SBLContainersTable.styled';
import { SBLTableRowCollapse } from './components';

const EMPTY_CELL = '-';

const getContainerWarningsMessage = (container: ContainerWithCargoDTM, temperatureControl: TemperatureControlDTM, t: TFunction<'translation'>): string => {
  const messages = [];
  let finalMessage = '';

  if (!container.name) {
    messages.push(t('Container Number'));
  }

  if (!container.sealNumber) {
    messages.push(t('Seal Number'));
  }

  if (!container.cargoItems.length) {
    messages.push(t('Load Plan'));
  }

  const isReeferType = ContainerIsReeferType(container.type);

  if (isReeferType && !temperatureControl.temperature) {
    messages.push(t('Temperature'));
  }

  finalMessage = messages.join(', ');

  return finalMessage ? `${t('Please specify:')} ${finalMessage}` : '';
};

interface ISBLContainersTableComponentProps {
  containers: ContainerWithCargoDTM[];
  temperatureControl: TemperatureControlDTM;
  hideTitle?: boolean;
  isAbleToEdit: boolean;
}

export const SBLContainersTableComponent: FC<ISBLContainersTableComponentProps> = ({
  temperatureControl,
  containers,
  hideTitle,
  isAbleToEdit,
}) => {
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const containersDataSource = useMemo(() => containers.map((container, index) => {
    const totalCargoItem = container.cargoItems.reduce((prev, next) => ({
      volume: prev.volume + (Number(next.volume) || 0),
      weight: prev.weight + (Number(next.weight) || 0),
      packagesNumber: prev.packagesNumber + (Number(next.packagesNumber) || 0),
    }), {
      volume: 0,
      weight: 0,
      packagesNumber: 0,
    });

    return {
      ...container,
      key: index,
      weight: totalCargoItem.weight,
      volume: totalCargoItem.volume,
      packagesNumber: totalCargoItem.packagesNumber,
    };
  }), [containers]);

  const columns = useMemo(() => [
    {
      title: t('Container'),
      dataIndex: 'container-name',
      key: 'container-name',
      width: '25%',
      render: (item: string, record: ContainerWithCargoDTM | {}) => {
        const container = record as ContainerWithCargoDTM;
        const key = get(['key'], record);
        const warningMessage = getContainerWarningsMessage(container, temperatureControl, t);

        return (
          <SBLTableContainerNameCell onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
            <StyledExpandIcon isActive={openedKeys.includes(key)} />

            {ContainerTypesConst[container.type] ? (
              <ContainerType active={false}>
                {ContainerTypesConst[container.type]} &apos;
              </ContainerType>
            ) : null}

            {container.name ? container.name : t('Number pending entry')}

            {warningMessage ? (
              <Tooltip title={warningMessage} placement="top">
                <SBLWarningIcon />
              </Tooltip>
            ) : null}
          </SBLTableContainerNameCell>
        );
      },
    },
    {
      title: t('Pack QTY'),
      dataIndex: 'packagesNumber',
      key: 'packages-qty',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <SBLTableCell>
          {item}
        </SBLTableCell>
      ),
    },
    {
      title: t('Weight, kg'),
      dataIndex: 'weight',
      key: 'weight',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <SBLTableCell>
          {item ? Number(item).toFixed(2) : 0}
        </SBLTableCell>
      ),
    },
    {
      title: t('Volume, cbm'),
      dataIndex: 'volume',
      key: 'volume',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string) => (
        <SBLTableCell>
          {item ? Number(item).toFixed(2) : 0}
        </SBLTableCell>
      ),
    },
    {
      title: t('Value'),
      dataIndex: 'value',
      key: 'value',
      width: '10%',
      align: 'right' as 'right',
      render: (item: string, record: ContainerWithCargoDTM | {}) => {
        const container = record as ContainerWithCargoDTM;

        return (
          <SBLTableCell>
            {container.getTotalCargosValue()} $
          </SBLTableCell>
        );
      },
    },
    {
      title: t('Tare Weight, kg'),
      dataIndex: 'tareWeight',
      key: 'tareWeight',
      width: '10%',
      align: 'right' as 'right',
      render: () => (
        <SBLTableCell>
          {EMPTY_CELL}
        </SBLTableCell>
      ),
    },
    {
      title: t('VGM'),
      dataIndex: 'vgm',
      key: 'vgm',
      width: '10%',
      align: 'right' as 'right',
      render: () => (
        <SBLTableCell>
          {EMPTY_CELL}
        </SBLTableCell>
      ),
    },
  ], [openedKeys]);

  const expandable = useMemo(() => ({
    expandedRowRender: (record: ContainerWithCargoDTM | {}) => (
      <SBLTableRowCollapse
        container={record as ContainerWithCargoDTM}
        temperatureControl={temperatureControl}
        isAbleToEdit={isAbleToEdit}
      />
    ),
    expandIconColumnIndex: -1,
    expandedRowKeys: openedKeys,
  }), [temperatureControl, openedKeys, isAbleToEdit]);

  const content = useMemo(() => (
    <>
      <SBLTableHeader>
        {t('Containers')}
      </SBLTableHeader>
      <SBLTable
        locale={{
          emptyText: <NoDataStub />,
        }}
        pagination={false}
        dataSource={containersDataSource}
        columns={columns}
        expandable={expandable}
      />
    </>
  ), [containersDataSource, columns, expandable, hideTitle, t]);

  return (
    <SBLTableLayoutNoMargin>
      {content}
    </SBLTableLayoutNoMargin>
  );
};
