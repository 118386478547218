import styled from 'styled-components';

import {
  BodyTextMedium,
  BodyTextRegular,
} from 'app-wrapper/view/fonts';
import {
  FlexRow,
} from 'app-wrapper/view/guideline';
import {
  Divider as CommonDivider,
  Input as CommonInput,
} from 'app-wrapper/view/components';

export const ContainerWrap = styled.div`
  height: 72px;
  padding: 16px 24px;
  border-radius: 4px;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  display: grid;
  grid-template-columns: 552px 200px 100px 100px 40px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const Divider = styled(CommonDivider)`
  margin: 0 24px;
  height: 100%;
`;

export const ContainerInformation = styled(FlexRow)`
  
`;

export const Number = styled.span`
  margin-left: 8px;
  ${BodyTextRegular}
  color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
`;

export const Name = styled.span`
  ${BodyTextMedium}
`;

export const Input = styled(CommonInput)`
  height: 32px;
  max-height: 32px;
`;
