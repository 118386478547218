import { DrawerController } from 'app-wrapper/controllers/DrawerController';
import { RoutesController } from 'authentication/controllers/RoutersController';
import { ControllerParams } from 'proto/BaseController';
import { CommandCenterController } from './CommandCenterController';
import { HomeControllerController } from './HomeController';
import { OverviewController } from './OverviewController';
import { AgreementsController } from './AgreementsController';
import { VGMTaskDrawerController } from './VGMTaskDrawerController';
import { RailBillingDrawerController } from './RailBillingDrawerController';
import { ContractsController } from './ContractsController';
import { ShipmentCostChangesDrawerController } from './ShipmentCostChangesDrawerController';
import { ISFDrawerController } from './ISFDrawerController';

type UseCases = {
  CommandCenter: CommandCenterController
  home: HomeControllerController
  overview: OverviewController
  routes: RoutesController
  agreements: AgreementsController
  vgmTaskDrawer: VGMTaskDrawerController
  railBilling: RailBillingDrawerController
  drawer: DrawerController
  contracts: ContractsController
  shipmentCostChangesDrawer: ShipmentCostChangesDrawerController
  isfDrawer: ISFDrawerController
}

// @ts-ignore
export const UC: UseCases = {};

export const initUC = (controllerParams: ControllerParams) => {
  UC.CommandCenter = new CommandCenterController(controllerParams);
  UC.home = new HomeControllerController(controllerParams);
  UC.overview = new OverviewController(controllerParams);
  UC.routes = new RoutesController(controllerParams);
  UC.agreements = new AgreementsController(controllerParams);
  UC.vgmTaskDrawer = new VGMTaskDrawerController(controllerParams);
  UC.railBilling = new RailBillingDrawerController(controllerParams);
  UC.drawer = new DrawerController(controllerParams);
  UC.contracts = new ContractsController(controllerParams);
  UC.shipmentCostChangesDrawer = new ShipmentCostChangesDrawerController(controllerParams);
  UC.isfDrawer = new ISFDrawerController(controllerParams);
};
