import { SmallTextFootnoteDescription } from 'app-wrapper/view/guideline/Font';
import styled from 'styled-components';

export const Container = styled.div.attrs(() => ({
  'data-class': 'Container',
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 4px;
  width: 100%;
`;

export const Title = styled.div.attrs(() => ({
  'data-class': 'Title',
}))`
  ${SmallTextFootnoteDescription}
  width: 100%;
`;

export const InputContainer = styled.div.attrs(() => ({
  'data-class': 'InputContainer',
}))`
  width: 100%;
`;
