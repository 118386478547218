import { IPaginationState, PaginationStore } from 'proto/BaseMobxStore';
import { ContainerDTM } from 'shipment-operations/models/dtm';

const initialState: IPaginationState<ContainerDTM> = {
  isLoading: false,
  items: [],
};

export class ShipmentContainersStore extends PaginationStore<ContainerDTM> {}

export const createShipmentContainersStore = (_initialState?: IPaginationState<ContainerDTM>) => new ShipmentContainersStore(_initialState || initialState);
