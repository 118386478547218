import React, { useCallback, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import uniq from 'lodash/fp/uniq';
import Table from 'antd/es/table';

import { prepareChargeHistory } from 'shipment-operations/repository/store/ShipmentTransportationCharges';
import { ChargeTableDTM, ChargeViewDtm, IGroupedChargesDTM } from 'shipment-operations/models/dtm';
import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { CustomTable } from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable';
import {
  StyledExpandIcon, CustomHeader, StyledType, StyledBodyCell, FirstHeaderWrapper, SubHeader,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';
import { ContainerTitleWrapper, ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';

import { subjectToIncluded } from 'shipment-operations/constants';
import { chargeCodeCode } from 'monetary/constants';
import { TableBody } from './ContainersTable.styled';

interface IContainersTableProps {
  dataSource: ChargeViewDtm[]
  innerTableData: IGroupedChargesDTM
  isPercentage: boolean
  subTitle?: string
}

const { Column, ColumnGroup } = Table;

const ContainersTableComponent: FC<IContainersTableProps> = ({
  dataSource, innerTableData, isPercentage, subTitle,
}) => {
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const renderOpenedCells = useCallback((value) => (
    <StyledBodyCell active>$ {value.toFixed(2)}</StyledBodyCell>
  ), [openedKeys]);

  const renderProfit = useCallback((value, record) => {
    const percent = record.profitPercent ? `% ${record.profitPercentage.toFixed(2)}` : '';

    return (
      <StyledBodyCell active>
        {isPercentage ? percent : `$ ${value.toFixed(2)}`}
      </StyledBodyCell>
    );
  }, [openedKeys, isPercentage]);

  const columns = useMemo(() => (
    <>
      <ColumnGroup align="left" title={<FirstHeaderWrapper>{subTitle}</FirstHeaderWrapper>}>
        <Column
          title={t('shipmentOperation.transportation.container')}
          dataIndex="description"
          key="description"
          width="22%"
          render={(number: string, record: ChargeTableDTM | {}) => {
            const key = get(['key'], record);
            const type = get(['type'], record);
            return (
              <StyledType active onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
                <StyledExpandIcon isActive={openedKeys.includes(key)} />
                <ContainerTitleWrapper>
                  <ContainerType active={openedKeys.includes(key)}>
                    {type} &apos;
                  </ContainerType>
                </ContainerTitleWrapper>
                {number || t('Number pending entry')}
              </StyledType>
            );
          }}
        />
        <Column
          title={t('Unit')}
          dataIndex="unitType"
          key="unitType"
          width="13%"
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('payables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="buyCostPerUnit"
          key="buyCostPerUnit"
          width="10%"
          align={'right' as 'right'}
        />
        <Column
          title={t('QTY')}
          dataIndex="buyQuantity"
          key="buyQuantity"
          width="5%"
          align={'right' as 'right'}
        />
        <Column
          title={t('Buy')}
          dataIndex="buyTotalCost"
          key="buyTotalCost"
          width="10%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('receivables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="costPerUnit"
          key="costPerUnit"
          width="10%"
          align={'right' as 'right'}
        />
        <Column
          title={t('QTY')}
          dataIndex="quantity"
          key="quantity"
          width="5%"
          align={'right' as 'right'}
        />
        <Column
          title={t('sell')}
          dataIndex="totalCost"
          key="totalCost"
          width="10%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
      </ColumnGroup>
      <ColumnGroup align="right">
        <Column
          title={t('shipmentOperation.transportation.profit')}
          dataIndex="profit"
          key="profit"
          width="15%"
          align={'right' as 'right'}
          render={renderProfit}
        />
      </ColumnGroup>
    </>
  ), [openedKeys, isPercentage]);

  const expandRender = useCallback((record: ChargeTableDTM | {}) => {
    const key = get(['key'], record);
    const matchedData = innerTableData[key];
    const filteredData = matchedData.filter((item) => item.subjectTo !== subjectToIncluded);
    const includedCharges = matchedData.filter((item) => item.subjectTo === subjectToIncluded).map((item) => item.chargeCode.description);
    const indexOfDray = filteredData.findIndex((item) => item.chargeCode.code === chargeCodeCode.DRAY);
    const dray = filteredData.find((item) => item.chargeCode.code === chargeCodeCode.DRAY);
    if (indexOfDray > -1 && dray) {
      filteredData.splice(indexOfDray, 1);
      filteredData.unshift(dray);
    }
    const tableData = filteredData.map((item) => (ChargeViewDtm.fromPlain({
      key: item.id,
      active: item.active,
      description: item.chargeCode.description,
      apBudget: (item?.apBudget?.totalCost || 0),
      apTotalCost: item.buyTotalCost,
      arBudget: (item?.arBudget?.totalCost || 0),
      arTotalCost: item.totalCost,
      profit: item.profitAmount,
      profitPercentage: item.profitPercent,
      unitType: item.measureBy,
      buyCostPerUnit: item.buyCostPerUnit,
      buyQuantity: item.buyNumberOfUnits,
      buyTotalCost: item.buyTotalCost,
      costPerUnit: item.costPerUnit,
      quantity: item.numberOfUnits,
      totalCost: item.totalCost,
      history: prepareChargeHistory(item.arVersions, item.apVersions, item.additional),
      includedCharges: item.chargeCode.code === chargeCodeCode.FRT ? uniq(includedCharges) : [],
    })));
    return (
      <CustomTable data={tableData} />
    );
  }, [openedKeys]);

  return (
    <>
      <CustomHeader
        expandable={{
          expandIconColumnIndex: -1,
        }}
      >
        {columns}
      </CustomHeader>
      <TableBody
        pagination={false}
        showHeader={false}
        dataSource={dataSource}
        expandedRowClassName={() => 'expandContainersRow'}
        expandable={{
          expandedRowRender: expandRender,
          expandedRowKeys: openedKeys,
          expandIconColumnIndex: -1,
        }}
      >
        {columns}
      </TableBody>
    </>
  );
};

export { ContainersTableComponent };
