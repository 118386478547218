import React, { FC, useCallback } from 'react';

import { Button } from 'app-wrapper/view/components';
import { EmptyWindowForIconsSvg } from 'app-wrapper/view/icons';

import {
  EmptyStateForPagesDiv,
  EmptyStateForPagesDivWrapper,
  EmptyStateForPagesIconSecondWrap,
  EmptyStateForPagesIconWrap,
  EmptyStateForPagesText,
  EmptyStateForPagesTextButton,
  EmptyStateForPagesTextFirst,
  EmptyStateForPagesTextSecond,
} from './EmptyStateForPages.styled';

interface IEmptyStateForPagesProps {
  icon?: React.ReactNode
  primaryIcon?: React.ReactNode
  headerText?: string
  contentText?: string
  buttonText?: string
  onClickButton?: () => void
  disabled?: boolean;
}

export const EmptyStateForPages: FC<IEmptyStateForPagesProps> = (props) => {
  const {
    icon,
    primaryIcon,
    headerText,
    contentText,
    buttonText,
    onClickButton,
    disabled,
  } = props;

  const onClickButtonHandle = useCallback(
    () => {
      if (onClickButton) {
        onClickButton();
      }
    },
    [onClickButton],
  );

  return (
    <EmptyStateForPagesDiv>
      <EmptyStateForPagesDivWrapper>
        <EmptyStateForPagesIconWrap>
          {primaryIcon || <EmptyWindowForIconsSvg />}

          {icon ? (
            <EmptyStateForPagesIconSecondWrap>
              {icon}
            </EmptyStateForPagesIconSecondWrap>
          ) : null}
        </EmptyStateForPagesIconWrap>

        <EmptyStateForPagesText>
          {headerText ? (
            <EmptyStateForPagesTextFirst>
              {headerText || ''}
            </EmptyStateForPagesTextFirst>
          ) : null}
          {contentText ? (
            <EmptyStateForPagesTextSecond>
              {contentText || ''}
            </EmptyStateForPagesTextSecond>
          ) : null}
        </EmptyStateForPagesText>
        {onClickButton ? (
          <EmptyStateForPagesTextButton>
            <Button
              disabled={disabled}
              size="middle"
              onClick={onClickButtonHandle}
            >{buttonText || ''}
            </Button>
          </EmptyStateForPagesTextButton>
        ) : null}
      </EmptyStateForPagesDivWrapper>
    </EmptyStateForPagesDiv>
  );
};
