import React, { FC } from 'react';
import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';

import { RFQItemDetailRoutingComponent } from 'monetary/view/components/RFQResponse/RFQItemDetailRouting';
import {
  SubPageTabs,
} from 'app-wrapper/view/guideline';
import { DetailRoutingDTM } from 'monetary/models/dtm';

interface IExpandRenderComponentProps {
  origin: DetailRoutingDTM[]
  freight: DetailRoutingDTM[]
  destination: DetailRoutingDTM[]
}

const ExpandRenderComponent: FC<IExpandRenderComponentProps> = ({
  origin, freight, destination,
}) => {
  const { t } = useTranslation();

  return (
    <SubPageTabs>
      <Tabs.TabPane tab={t('Routing')} key="1">
        <RFQItemDetailRoutingComponent
          origin={origin}
          freight={freight}
          destination={destination}
        />
      </Tabs.TabPane>
    </SubPageTabs>
  );
};

export { ExpandRenderComponent };
