import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { LoadingControlDTM } from 'shipment-operations/models/dtm';
import {
  ContentGap,
  ContentSection,
  GapHorizontalContainerStyled,
  GapVerticalContainerStyled,
  InfoCardComponent,
  MissingTextValue,
} from 'app-wrapper/view/guideline';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import { ServicesDivider, EditButton } from 'app-wrapper/view/components';
import {
  LOADING_METHOD_NAMES,
  RECEIVING_METHOD_NAMES,
  LOADING_TIME_NAMES,
  PermissionAttributePolicy,
  EDrayageLoadingSide,
} from 'shipment-operations/constants';

import { Header, SectionTitle } from './LoadingControls.styled';

interface ILoadingControlsProps {
  drayageSide: EDrayageLoadingSide
  loadingControls?: LoadingControlDTM;
  loadingControlsPermission?: PermissionAttributePolicy;
  openEditDrawer: (loadingControls: LoadingControlDTM) => void;
}

export const LoadingControlsComponent: FC<ILoadingControlsProps> = ({
  drayageSide,
  loadingControls,
  loadingControlsPermission,
  openEditDrawer,
}) => {
  const { t } = useTranslation();

  const isAbleToEdit = hasAccess(loadingControlsPermission, PermissionAttributePolicy.WRITE);

  const handleEdit = useCallback(() => {
    openEditDrawer(loadingControls || LoadingControlDTM.createDefault(drayageSide));
  }, [openEditDrawer, loadingControls, drayageSide]);

  return (
    <GapHorizontalContainerStyled>
      <ContentSection>
        <GapVerticalContainerStyled width="100%">
          <Header>
            <ContentSectionContainer>
              <SectionTitle>
                {t('Shipping Method')}
              </SectionTitle>
            </ContentSectionContainer>

            {isAbleToEdit ? (
              <EditButton onClick={handleEdit} />
            ) : null}
          </Header>

          <ContentSectionContainer>
            <ServicesDivider noMargin />
          </ContentSectionContainer>

          <ContentSectionContainer>
            <ContentGap>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Loading Method')}
                  value={loadingControls?.loadingMethod ? LOADING_METHOD_NAMES[loadingControls.loadingMethod] : <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Loading Time')}
                  value={loadingControls?.loadingTime ? LOADING_TIME_NAMES[loadingControls.loadingTime] : <MissingTextValue />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Receiving Method')}
                  value={loadingControls?.receivingMethod ? RECEIVING_METHOD_NAMES[loadingControls.receivingMethod] : <MissingTextValue />}
                />
              </ContentSectionContainer>
            </ContentGap>
          </ContentSectionContainer>
        </GapVerticalContainerStyled>
      </ContentSection>
    </GapHorizontalContainerStyled>
  );
};
