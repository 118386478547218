import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { EShipmentOrganizationRole } from 'user-management/constants';
import {
  PermissionsDTM,
} from './Permissions.dtm';

export class PermissionsService {
  userRoles?: EShipmentOrganizationRole[]

  constructor(userRoles?: EShipmentOrganizationRole[]) {
    this.userRoles = userRoles;
  }

  mergePermissions(currentPermissions: PermissionsDTM, newPermissions: PermissionsDTM) {
    Object.keys(currentPermissions).forEach((key) => {
      const permissionKey = key as keyof PermissionsDTM;
      if (!currentPermissions[permissionKey]
        || (currentPermissions[permissionKey] === PermissionAttributePolicy.READ && newPermissions[permissionKey] === PermissionAttributePolicy.WRITE)
      ) {
        // @ts-ignore
        currentPermissions[permissionKey] = newPermissions[permissionKey];
      }
    });
  }

  getFunctionalityPermissions() {
    const permissions: PermissionsDTM = PermissionsDTM.fromPlain({
      requestInformation: undefined,
      rejectButton: undefined,
      completeRequestButton: undefined,
    });

    if (this.userRoles?.includes(EShipmentOrganizationRole.CUSTOMER)) {
      this.mergePermissions(permissions, PermissionsDTM.fromPlain({
        requestInformation: PermissionAttributePolicy.WRITE,
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: undefined,
      }));
    }

    if (this.userRoles?.includes(EShipmentOrganizationRole.ACCOUNT_HOLDER)) {
      this.mergePermissions(permissions, PermissionsDTM.fromPlain({
        requestInformation: undefined,
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: PermissionAttributePolicy.WRITE,
      }));
    }

    if (this.userRoles?.includes(EShipmentOrganizationRole.ORIGIN_PARTNER)) {
      this.mergePermissions(permissions, PermissionsDTM.fromPlain({
        requestInformation: undefined,
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: PermissionAttributePolicy.WRITE,
      }));
    }

    if (this.userRoles?.includes(EShipmentOrganizationRole.DESTINATION_PARTNER)) {
      this.mergePermissions(permissions, PermissionsDTM.fromPlain({
        requestInformation: undefined,
        rejectButton: PermissionAttributePolicy.WRITE,
        completeRequestButton: PermissionAttributePolicy.WRITE,
      }));
    }

    return permissions;
  }
}
