import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  Container,
  ContainerWrap,
  Actions,
  Button,
} from './Footer.styled';

interface FooterComponentProps {
  onAccept: () => void;
  closeDrawer: () => void;
  isLoading: boolean;
  isAbleToAccept: boolean;
  hideCancel?: boolean;
  okText?: string;
  isLoadingAccept?: boolean;
}

export const Footer: FC<FooterComponentProps> = ({
  onAccept,
  closeDrawer,
  isLoading,
  isAbleToAccept,
  hideCancel,
  okText,
  isLoadingAccept = false,
}) => {
  const { t } = useTranslation();

  return (
    <Container data-class="vgm-drawer-footer">
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <Actions>
            {!hideCancel ? (
              <Button
                onClick={closeDrawer}
                type="default"
              >
                {t('Close')}
              </Button>
            ) : null}

            <Button onClick={onAccept} disabled={!isAbleToAccept} loading={isLoadingAccept}>
              {okText || t('Accept')}
            </Button>
          </Actions>
        </ContainerWrap>
      )}
    </Container>
  );
};
