import { BadRequestError, ConflictError } from 'app-wrapper/models/errors';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import debounce from 'lodash/debounce';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { validateDecimalFraction } from 'app-wrapper/utils/decimalFraction';
import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';
import { BaseController, controller } from 'proto/BaseController';

import {
  CargoReferenceDTM,
  CargoErrorsDTM,
  CommodityDTM, CargoDTM, CargoBaseDTM, ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

import { getHazmatStatus } from './getHazmatStatus';
import { validateHazmat } from './validateHazmat';
import { validateReferences } from './validateReferences';
import { validateRequiredField } from './validateRequiredField';

const EXCLUDED_HS_CODES = ['000000'];

@controller
export class CargoController extends BaseController {
  private updateCargoErrors = (index: number) => {
    const shipment = R.selectors.shipment.getShipment(this.store.getState());
    const cargo = R.selectors.cargo.getCargos(this.store.getState())[index];

    if (!cargo) {
      return;
    }

    const {
      code,
      name,
      packageType,
      packagesNumber,
      weight,
      references,
      value,
    } = cargo;

    let cargoErrors = CargoErrorsDTM.fromPlain({
      code: validateRequiredField(code),
      name: validateRequiredField(name),
      packageType: validateRequiredField(packageType),
      packagesNumber: validateRequiredField(packagesNumber),
      weight: validateRequiredField(weight),
      references: validateReferences(references),
      value: shipment?.isDrayageShipment() ? validateRequiredField(value) : undefined,
    });

    if (cargo.isHazmat) {
      const {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument,
      } = cargo;

      cargoErrors = CargoErrorsDTM.fromPlain({
        ...cargoErrors,
        ...validateHazmat(
          unNumber,
          imoClass,
          packingGroup,
          shippingName,
          msdsDocument,
        ),
      });
    }

    this.dispatch(R.actions.cargo.setCargoErrors({
      index,
      errors: cargoErrors,
    }));
  };

  private updateHazmatStatus = () => {
    const cargo = R.selectors.cargo.getCargo(this.store.getState());
    const isHazmat = getHazmatStatus(cargo);
    this.dispatch(R.actions.cargo.setIsHazmat(isHazmat));
  };

  private updateHazmatErrors = () => {
    const {
      isHazmat,
      imoClass,
      unNumber,
      packingGroup,
      shippingName,
      msdsDocument,
    } = R.selectors.cargo.getCargo(this.store.getState());

    if (!isHazmat) {
      this.dispatch(R.actions.cargo.clearHazmatErrors());
      return;
    }

    const errors = CargoErrorsDTM.fromPlain(validateHazmat(
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
    ));

    this.dispatch(R.actions.cargo.setHazmatErrors(errors));
  };

  //

  public selectCargo = (index: number) => {
    const cargos = R.selectors.cargo.getCargos(this.store.getState());
    const selectedCargo = R.selectors.cargo.getSelectedCargo(this.store.getState());

    if (index === selectedCargo) {
      return;
    }

    if (index > cargos.length - 1) {
      index = cargos.length - 1;
    }

    this.dispatch(R.actions.cargo.selectCargo(index));
  };

  public addCargo = () => {
    const isLoading = R.selectors.cargo.getLoading(this.store.getState());

    if (isLoading) {
      return;
    }

    this.dispatch(R.actions.cargo.addCargo());

    const cargos = R.selectors.cargo.getCargos(this.store.getState());
    const newIndex = cargos.length - 1;

    this.selectCargo(newIndex);
    this.updateCargoErrors(newIndex);
  };

  public removeCargo = async (shipmentId: number) => {
    const isLoading = R.selectors.cargo.getLoading(this.store.getState());

    if (isLoading) {
      return;
    }

    const cargos = R.selectors.cargo.getCargos(this.store.getState());

    if (cargos.length < 2) {
      return;
    }

    const { id: currentCargoId } = R.selectors.cargo.getCargo(this.store.getState());

    if (cargos.filter(({ id }) => !!id).length === 1 && currentCargoId) {
      return;
    }

    if (currentCargoId) {
      try {
        await R.services.cargo.deleteCargo(shipmentId, currentCargoId);
      } catch (e) {
        console.error('DELETE CARGO: CONTROLLER ERROR');
      }
    }

    await this.updateShipmentShort(shipmentId);

    const previouslySelectedCargo = R.selectors.cargo.getSelectedCargo(this.store.getState());
    if (previouslySelectedCargo) {
      this.selectCargo(previouslySelectedCargo - 1);
    }
    this.dispatch(R.actions.cargo.removeCargo(previouslySelectedCargo));
  };

  public updateCargo = async (shipmentId: number) => {
    const cargo = R.selectors.cargo.getCargo(this.store.getState());

    if (cargo.errors.hasErrors()) {
      this.dispatch(R.actions.cargo.setWasUpdateAttempted(true));
      return;
    }

    let response = null;

    try {
      if (cargo.id) {
        response = await R.services.cargo.putCargo(shipmentId, cargo);
      }
      if (!cargo.id) {
        response = await R.services.cargo.postCargo(shipmentId, cargo);
      }
    } catch (e) {
      console.error('UPDATE CARGO: CONTROLLER ERROR');
    }

    if (!response) {
      this.dispatch(R.actions.cargo.setWasUpdateAttempted(true));
      return;
    }

    await this.updateShipmentShort(shipmentId);

    this.dispatch(R.actions.cargo.setCargo(response));
  };

  public resetCargo = () => {
    const cargo = R.selectors.cargo.getCargo(this.store.getState());

    const isHazmat = getHazmatStatus(cargo.initialState);

    const initialCargo = CargoDTM.fromPlain({
      renderId: cargo.renderId,
      id: cargo.id,
      ...cargo.initialState,
      isHazmat,
      isHazmatCollapseOpen: cargo.isHazmatCollapseOpen,
      hsCodeValidationStatus: cargo.initialState.code ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
      initialState: cargo.initialState,
      wasUpdateAttempted: false,
      errors: {},
      touchedFields: {},
    });

    this.dispatch(R.actions.cargo.setCargo(initialCargo));

    const selectedCargo = R.selectors.cargo.getSelectedCargo(this.store.getState());
    this.updateCargoErrors(selectedCargo);
  }

  private updateShipmentShort = async (shipmentId: number) => {
    let shipment: ShipmentPreviewDTM | null = null;
    shipment = await R.services.shipment.getShipmentShortById(shipmentId);
    if (shipment) {
      this.dispatch(R.actions.shipment.setShipment(shipment));
    }
  }

  private fetchCommodities = debounce(async () => {
    const { query } = R.selectors.commodity.getCommodity(this.store.getState());

    if (!query) {
      this.dispatch(R.actions.commodity.setStatusCommoditiesRequest(REQUEST_STATUS.complete));
      return;
    }

    let response = null;

    try {
      response = await R.services.commodity.getCommodities(query,
        {
          query,
          size: 10,
        });
    } catch (e) {
      console.error('GET COMMODITIES: CONTROLLER ERROR');

      this.dispatch(R.actions.commodity.setStatusCommoditiesRequest(REQUEST_STATUS.rejected));
    }

    if (!response) {
      this.dispatch(R.actions.commodity.setStatusCommoditiesRequest(REQUEST_STATUS.complete));
      return;
    }

    this.dispatch(R.actions.commodity.setStatusCommoditiesRequest(REQUEST_STATUS.complete));

    this.dispatch(R.actions.commodity.setCommodities(response.filter(({ code }) => !EXCLUDED_HS_CODES.includes(code))));
    this.dispatch(R.actions.commodity.setLoading(false));
  }, 1000);

  public fetchCargos = async (shipmentId?: number) => {
    if (!shipmentId) {
      console.error('FETCH CARGOS: CONTROLLER ERROR');
      return;
    }

    let temperatureControl = false;
    try {
      temperatureControl = await R.services.temperatureControl.getTemperatureControl(shipmentId);
    } catch (e) {
      console.error('GET TEMPERATURE CONTROL STATUS: CONTROLLER ERROR');
    }

    this.dispatch(R.actions.cargo.setTempertaureControl(temperatureControl));

    let cargos: CargoDTM[] = [];
    try {
      cargos = await R.services.cargo.getCargos(shipmentId);
    } catch (e) {
      console.error('FETCH CARGOS: CONTROLLER ERROR');
    }

    if (temperatureControl) {
      cargos = cargos.map((cargo) => ({
        ...cargo,
        hsValidationStatus: 'REQUEST_SENT_AND_VALID',
      }));

      const { defaultCargo } = R.selectors.cargo.getAllCargoInformation(this.store.getState());
      const { code, name } = cargos[0];

      this.dispatch(R.actions.cargo.setDefaultCargo(CargoBaseDTM.fromPlain({
        ...defaultCargo,
        code,
        name,
      })));
    }

    if (!cargos.length) {
      this.dispatch(R.actions.cargo.addCargo());
    }

    if (cargos.length) {
      this.dispatch(R.actions.cargo.setCargos(cargos));
    }

    const cargosInStore = R.selectors.cargo.getCargos(this.store.getState());

    const commodityPromises: Promise<CommodityDTM[]>[] = [];
    cargosInStore.forEach(({ code }) => {
      if (!code) {
        return;
      }

      try {
        commodityPromises.push(R.services.commodity.getCommodities(code));
      } catch (e) {
        console.error('GET COMMODITIES: CONTROLLER ERROR');
      }
    });

    const commoditySearchResults = await Promise.all(commodityPromises);

    const commodities = commoditySearchResults.map((commodityList, i) => (
      commodityList.find((commodity) => commodity.code === cargosInStore[i].code)!
    )).filter((v) => !!v);

    this.dispatch(R.actions.commodity.setCommodities(commodities));

    for (let i = 0; i < cargosInStore.length; i += 1) {
      this.updateCargoErrors(i);
    }

    this.dispatch(R.actions.cargo.setLoading(false));
  };

  public reset = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.cargo.reset());
  }

  //

  public touchField = (fieldName: string) => {
    this.dispatch(R.actions.cargo.touchField(fieldName));
  }

  public touchReferenceType = (index: number) => {
    this.dispatch(R.actions.cargo.touchReferenceType(index));
  }

  public touchReferenceValue = (index: number) => {
    this.dispatch(R.actions.cargo.touchReferenceValue(index));
  }

  public searchCommodities = async (query: string) => {
    this.dispatch(R.actions.commodity.setQuery(query));
    this.dispatch(R.actions.commodity.setStatusCommoditiesRequest(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commodity.setLoading(true));
    this.dispatch(R.actions.commodity.clearCommodities());
    await this.fetchCommodities();
  };

  public setHsCode = async (shipmentId: number, hsCode: CargoDTM['code']) => {
    const { commodities } = R.selectors.commodity.getCommodity(this.store.getState());
    let code = hsCode;
    let name;

    const commodity = commodities.find((item) => item.code === hsCode);
    if (commodity) {
      code = commodity.code;
      name = commodity.name;
    }

    this.dispatch(R.actions.cargo.setHsCodeValidationStatus('REQUEST_NOT_SENT'));

    this.dispatch(R.actions.cargo.setHsCode({
      code,
      name,
      errorsCode: validateRequiredField(code),
      errorsName: validateRequiredField(name),
    }));

    const temperatureControl = R.selectors.cargo.getTemperatureControl(this.store.getState());

    if (temperatureControl) {
      return;
    }

    if (!code) {
      return;
    }

    try {
      await R.services.hsValidation.getHsGroup(shipmentId, code);
    } catch (error: unknown) {
      if (error instanceof BadRequestError || error instanceof ConflictError) {
        this.dispatch(R.actions.cargo.setHsCodeError(ValidationErrorDTM.fromPlain({
          type: ValidationErrorType.ALERT,
          message: error.getErrorMessage(),
        })));
      }

      this.dispatch(R.actions.cargo.setHsCodeValidationStatus('REQUEST_SENT_AND_ERROR'));
    }

    this.dispatch(R.actions.cargo.setHsCodeValidationStatus('REQUEST_SENT_AND_VALID'));
  };

  public setDescription = (description: CargoDTM['description']) => {
    this.dispatch(R.actions.cargo.setDescription(description?.slice(0, 512)));
  };

  public setValue = (value: CargoDTM['value']) => {
    const shipment = R.selectors.shipment.getShipment(this.store.getState());
    const validValue = value ? validateDecimalFraction(value, 18, 2) : '';

    this.dispatch(R.actions.cargo.setValue({
      value: validValue,
      error: shipment?.isDrayageShipment() ? validateRequiredField(validValue) : undefined,
    }));
  };

  public setPackageType = (packageType: CargoDTM['packageType']) => {
    this.dispatch(R.actions.cargo.setPackageType({
      packageType,
      error: validateRequiredField(packageType),
    }));
  };

  public setPackagesNumber = (packagesNumber: CargoDTM['packagesNumber']) => {
    const slicedPackagesNumber = packagesNumber ? (
      packagesNumber
        .replace(/^0+/, '')
        .replace(/[^0-9]/g, '')
        .slice(0, 6)
    ) : undefined;

    this.dispatch(R.actions.cargo.setPackagesNumber({
      packagesNumber: slicedPackagesNumber,
      error: validateRequiredField(slicedPackagesNumber),
    }));
  };

  public setWeight = (weight: CargoDTM['weight']) => {
    const slicedWeight = weight ? validateDecimalFraction(weight, 11, 3, {
      withoutZero: true,
      integerMaxLength: 7,
    }) : undefined;

    this.dispatch(R.actions.cargo.setWeight({
      weight: slicedWeight,
      error: validateRequiredField(weight),
    }));
  };

  public setVolume = (volume: CargoDTM['volume']) => {
    const slicedVolume = volume ? validateDecimalFraction(volume, 8, 3, {
      withoutZero: true,
      integerMaxLength: 4,
    }) : undefined;

    this.dispatch(R.actions.cargo.setVolume(slicedVolume));
  };

  public setMarks = (marks: CargoDTM['marks']) => {
    const slicedMarks = marks?.slice(0, 17);

    this.dispatch(R.actions.cargo.setMarks(slicedMarks));
  };

  //

  public addReference = () => {
    this.dispatch(R.actions.cargo.addReference());

    const { references } = R.selectors.cargo.getCargo(this.store.getState());
    this.dispatch(R.actions.cargo.setReferencesErrors(validateReferences(references)));
  };

  public removeReference = (referenceIndex: number) => {
    this.dispatch(R.actions.cargo.removeReference(referenceIndex));

    const { references } = R.selectors.cargo.getCargo(this.store.getState());
    this.dispatch(R.actions.cargo.setReferencesErrors(validateReferences(references)));
  };

  public setReferenceType = (type: CargoReferenceDTM['type'], referenceIndex: number) => {
    this.dispatch(R.actions.cargo.setReferenceType({ index: referenceIndex, type }));

    const { references } = R.selectors.cargo.getCargo(this.store.getState());
    this.dispatch(R.actions.cargo.setReferencesErrors(validateReferences(references)));
  };

  public setReferenceNumber = (value: CargoReferenceDTM['value'], referenceIndex: number) => {
    this.dispatch(R.actions.cargo.setReferenceValue({ index: referenceIndex, value: value?.slice(0, 16) }));

    const { references } = R.selectors.cargo.getCargo(this.store.getState());
    this.dispatch(R.actions.cargo.setReferencesErrors(validateReferences(references)));
  };

  public switchHazmatCollapse = () => {
    const { isHazmatCollapseOpen } = R.selectors.cargo.getCargo(this.store.getState());
    this.dispatch(R.actions.cargo.setHazmatCollapseStatus(!isHazmatCollapseOpen));
  };

  public setImoClass = (imoClass: CargoDTM['imoClass']) => {
    this.dispatch(R.actions.cargo.setImoClass(imoClass));
    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };

  public setUnNumber = (unNumber: CargoDTM['unNumber']) => {
    const slicedUnNumber = unNumber?.replace(/\D/g, '').slice(0, 4);
    this.dispatch(R.actions.cargo.setUnNumber(slicedUnNumber));

    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };

  public setPackingGroup = (packingGroup: CargoDTM['packingGroup']) => {
    this.dispatch(R.actions.cargo.setPackingGroup(packingGroup));

    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };

  public setShippingName = (shippingName: CargoDTM['shippingName']) => {
    const slicedShippingName = shippingName?.slice(0, 90);
    this.dispatch(R.actions.cargo.setShippingName(slicedShippingName));

    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };

  public setMsdsDocument = (msdsDocument: CargoDTM['msdsDocument']) => {
    this.dispatch(R.actions.cargo.setMsdsDocument(msdsDocument));

    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };

  public downloadMsdsDocument = (shipmentId: number) => {
    const { msdsDocument } = R.selectors.cargo.getCargo(this.store.getState());

    if (msdsDocument[0].status !== 'done') {
      return;
    }

    const documentId = msdsDocument[0].response.id;
    const documentName = msdsDocument[0].response.name;

    try {
      R.services.shipmentDocument.getShipmentDocument(shipmentId, documentId, documentName);
    } catch (e) {
      console.error('PREVIEW SHIPMENT DOCUMENT: CONTROLLER ERROR');
    }
  };

  public setContactName = (contactName: CargoDTM['contactName']) => {
    this.dispatch(R.actions.cargo.setContactName(contactName?.slice(0, 35)));
    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };

  public setContactNumber = (contactNumber: CargoDTM['contactNumber']) => {
    this.dispatch(R.actions.cargo.setContactNumber(contactNumber?.slice(0, 35)));
    this.updateHazmatStatus();
    this.updateHazmatErrors();
  };
}
