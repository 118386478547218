import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { CustomModal } from 'shipment-operations/view/components';
import {
  TaskInfo,
  TaskDescription,
  TasksSteps,
  GapVerticalContainerStyled,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/drawers/components';
import { TASKS_STEPS } from 'app-wrapper/constants';

interface ISFTaskDrawerComponentProps {
  init: (taskId: string) => void;
  onClose: () => void;
  isLoading: boolean;
  onAccept: () => void;
  taskId: string;
  task?: NotificationTaskContentDTM;
}

export const ISFTaskDrawerComponent: FC<ISFTaskDrawerComponentProps> = ({
  onClose,
  onAccept,
  init,
  isLoading,
  taskId,
  task,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (taskId) {
      init(taskId);
    }
  }, [taskId]);

  return (
    <CustomModal
      title={t('Send ISF Form')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '736px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading || !task ? (
        <Skeleton />
      ) : (
        <GapVerticalContainerStyled>
          <TaskInfo notificationTask={task} shipmentIdClick={onClose} />

          {task.description ? (
            <TaskDescription description={task.description} />
          ) : null}

          {task.metadata?.taskType ? (
            <TasksSteps steps={TASKS_STEPS[task.metadata?.taskType as keyof typeof TASKS_STEPS]} />
          ) : null}
        </GapVerticalContainerStyled>
      )}

      <Footer
        onAccept={onAccept}
        closeDrawer={onClose}
        isLoading={isLoading}
        isAbleToAccept
        hideCancel
        okText={t('Complete')}
      />
    </CustomModal>
  );
};
