import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TTransportationPermissionsDtm = {
  overviewTab?: PermissionAttributePolicy;
  trackerTab?: PermissionAttributePolicy;
  chargesTab?: PermissionAttributePolicy;
  accessorialsTab?: PermissionAttributePolicy;
  freeTimeTab?: PermissionAttributePolicy;
  locationTab?: PermissionAttributePolicy;
  inttraReferenceNumberAvailability?: PermissionAttributePolicy;
  contractNumberAvailability?: PermissionAttributePolicy;
  carrierCutoffsAvailability?: PermissionAttributePolicy;
  carrierCommentsAvailability?: PermissionAttributePolicy;
  carrierTOCAvailability?: PermissionAttributePolicy;
  trackerAvailability?: PermissionAttributePolicy;
  locationsAvailability?: PermissionAttributePolicy;
  trackerWarningsAvailability?: PermissionAttributePolicy;
  transportationAvailability?: PermissionAttributePolicy;
}

export class TransportationPermissionsDtm extends BaseDTM<TTransportationPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  overviewTab?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  trackerTab?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  chargesTab?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  accessorialsTab?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  freeTimeTab?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  locationTab?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  inttraReferenceNumberAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  contractNumberAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  carrierCutoffsAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  carrierCommentsAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  carrierTOCAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  trackerAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  locationsAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  trackerWarningsAvailability: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  transportationAvailability: PermissionAttributePolicy;
}
