import { useLocation } from 'react-router';
import { EXPORT, IMPORT, OCEAN_BOOKING } from 'shipment-operations/constants';

const useIsExportOrImport = () => {
  const { pathname } = useLocation();
  const isExport = pathname.includes(EXPORT);
  const isImport = pathname.includes(IMPORT);
  const isOceanBooking = pathname.includes(OCEAN_BOOKING);
  const isExportOrImport = isExport || isImport;
  const isNotDrayage = !isExport && !isImport && !isOceanBooking;

  return {
    isExport, isImport, isExportOrImport, isOceanBooking, isNotDrayage,
  };
};

export { useIsExportOrImport };
