import React, { FC, useCallback } from 'react';

import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  UploadIcon,
} from 'shipment-operations/view/pages/ShipmentDocumentsAll/ShipmentDocumentsAll.styled';
import { ContentGap } from 'app-wrapper/view/guideline/ContentGap';
import { ColumnType } from 'antd/es/table';
import Upload from 'antd/es/upload';

import { UploadFile } from 'antd/lib/upload/interface';
import { UploadButton } from 'app-wrapper/view/controllers/UploadController/Upload';
import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  TableDocuments,
} from './DocumentsSection.styled';

interface DocumentsSectionProps {
  sectionName: string
  isCollapsed?: boolean
  columns: ColumnType<object>[]
  files: (UploadFile | {id?: number | undefined, name: string})[]
  addFile: (file: UploadFile) => void
  disableUpload?: boolean
}

export const DocumentsSection: FC<DocumentsSectionProps> = ({
  sectionName,
  columns,
  files,
  isCollapsed,
  addFile,
  disableUpload,
}) => {
  const handleBeforeUpload = useCallback((file: UploadFile) => {
    addFile(file);

    return false;
  }, []);

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  const handleUploadClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  return (
    <DocumentCollapse expandIcon={onExpandIcon}>
      <CollapsePanel
        key={sectionName}
        header={(
          <ContentGap justifyContent="space-between">
            <CollapseLabel>
              {isCollapsed ? (
                <CollapseIconActive />
              ) : (
                <CollapseIcon />
              )}

              <CollapseLabelText $isCollapsed={isCollapsed}>
                {sectionName}
              </CollapseLabelText>

            </CollapseLabel>

            { disableUpload
              ? null
              : (
                <UploadButton onClick={handleUploadClick}>
                  <Upload
                    maxCount={1}
                    multiple={false}
                    showUploadList={false}
                    beforeUpload={handleBeforeUpload}
                  >
                    <UploadIcon />
                  </Upload>
                </UploadButton>
              )}
          </ContentGap>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={files}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
