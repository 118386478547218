import React, {
  FC, memo, SyntheticEvent, useCallback, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  Collapse, Panel, Tooltip, TableSkeleton,
} from 'app-wrapper/view/components';
import {
  VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import { sanitizeHtml } from 'app-wrapper/utils/sanitizeHtml';
import { RouteNames } from 'app-wrapper/constants';
import { ETasksType } from 'shipment-operations/constants';

import {
  CCContentTableContentDueDate,
  CCContentTableContentLink,
  CCContentTableContentExpand,
  CCContentTableContentItem,
  CCContentTableContentItemBottom,
  CCContentTableContentItemTop,
  CCContentTableContentTitle,
  CCContentTableContentModule,
  CCContentTableContentAssignee,
  CCContentTableContentAction,
  CCContentTableContentItemBottomDescription,
  CCSpanEllipsis,
  CCContentTableContentHeaderBlock,
  CCContentTableContentExpandButton,
  CCContentTableContentExpandButtonWrap,
  CCContentTableContentExpandButtonCount,
  CCContentTableContentHeaderSubBlock,
  TextLink,
} from './ContentTableTasksSection.styled';

import { DatePriority } from '../../DatePriority';
import { ContentTableTasksAssigneeUsers } from './ContentTableTasksAssigneeUsers.component';

interface IContentTableTasksSectionComponentProps {
  nameSection?: string;
  isCompleted?: boolean;
  hideExtend?: boolean;
  isLoad?: boolean;
  dataTasks?: CommandCenterComponentTaskDTM[];
  countTasks?: number;
  viewDueDate?: boolean;
  viewCreatedBy?: boolean;
  viewCreatedAt?: boolean;
  viewCompletionDate?: boolean;
  viewCompletedBy?: boolean;
  viewModule?: boolean;
  viewStatus?: boolean;
  viewAssignee?: boolean;
  openAgreementsDrawer?: (shipmentId: string) => void;
  setShipmentId?: (shipmentId: string) => void;
  openTaskDrawer?: (task: CommandCenterComponentTaskDTM, e: SyntheticEvent) => void;
}

const overdueDefaultVisibleElements = 3;

const ContentTableTasksSectionComponent: FC<IContentTableTasksSectionComponentProps> = (props) => {
  const {
    nameSection,
    isCompleted,
    hideExtend,
    isLoad,
    dataTasks,
    countTasks,
    viewDueDate = true,
    viewCreatedBy,
    viewCreatedAt,
    viewCompletionDate,
    viewCompletedBy,
    viewModule = true,
    viewStatus,
    viewAssignee = true,
    openAgreementsDrawer,
    setShipmentId,
    openTaskDrawer,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getCountTasks = useMemo(() => {
    if (countTasks) {
      return (
        <CCContentTableContentHeaderSubBlock>
          {countTasks}
        </CCContentTableContentHeaderSubBlock>
      );
    }

    return null;
  }, [countTasks]);

  const [expandButton, setExpandButton] = useState(false);

  const expandButtonOnClick = useCallback(
    () => {
      setExpandButton((prev) => !prev);
    },
    [],
  );
  const [
    changeIsCollapseState, setChangeIsCollapseState,
  ] = useState<Array<{
    id: string;
    activeKey: string[];
  }>>([]);

  const endVisible = useRef<HTMLElement>(null);

  const changeErrorIsCollapseCallback = useCallback(
    (id: string) => () => {
      setChangeIsCollapseState((prev) => {
        let isFindId = false;

        const newState = prev.map((item) => {
          if (item.id === id) {
            isFindId = true;

            return {
              id,
              activeKey: item.activeKey?.length ? [] : ['1'],
            };
          }

          return item;
        });

        if (!isFindId) {
          newState.push({
            id,
            activeKey: ['1'],
          });
        }

        return newState;
      });
    },
    [],
  );
  const collapseOnChangeHandler = useCallback(
    (id: string) => CollapseOnChangeHandler(
      endVisible.current, changeErrorIsCollapseCallback(id),
    ),
    [],
  );

  const overdueAllCount = dataTasks && dataTasks?.length > overdueDefaultVisibleElements
    ? dataTasks?.length - overdueDefaultVisibleElements
    : 0;

  const getObjectName = useCallback((item: CommandCenterComponentTaskDTM) => {
    if (item.rateOrganizationIdName) {
      return item.rateOrganizationIdName ? `${item.rateOrganizationType?.toLocaleLowerCase() || ''} #${item.rateOrganizationIdName.toLowerCase() || ''}` : '';
    }

    if (item.rateRequestName) {
      return item.rateRequestName ? `${item.rateRequestType?.toLocaleLowerCase() || ''} #${item.rateRequestName.toLowerCase() || ''}` : '';
    }

    if (item.shipmentName) {
      return item.shipmentName || '';
    }

    return '';
  }, []);

  const handleGoToShipment = useCallback((item: CommandCenterComponentTaskDTM) => {
    if (item.shipmentName && item?.shipmentId) {
      navigate(RouteNames.SHIPMENT_OVERVIEW(item.shipmentId));
    }

    if (item.rateRequestName && item?.requestId) {
      navigate(RouteNames.RATES_FREIGHT_BY_ID(item.requestId));
    }

    if (item.rateOrganizationIdName && item?.organizationId) {
      navigate(RouteNames.ACCOUNT_CUSTOMER_GENERAL_INFO(item.organizationId));
    }
  }, [navigate]);

  const handleTaskActionClick = useCallback((item: CommandCenterComponentTaskDTM, e: SyntheticEvent) => {
    if (setShipmentId) {
      setShipmentId(String(item.shipmentId) || '');
    }
    if (item.taskType === ETasksType.NRA_AGREEMENT_TASK && item.shipmentId && openAgreementsDrawer) {
      e.preventDefault();
      openAgreementsDrawer(String(item.shipmentId));
    } else if (openTaskDrawer) {
      openTaskDrawer(item, e);
    }
  }, [openTaskDrawer, openAgreementsDrawer, setShipmentId]);

  if (isLoad) {
    return <TableSkeleton />;
  }

  if (!dataTasks?.length) {
    return null;
  }

  return (
    <div>
      {nameSection && (
        <CCContentTableContentHeaderBlock>
          {nameSection || ''}{getCountTasks}
        </CCContentTableContentHeaderBlock>
      )}
      {dataTasks?.slice(0, expandButton || hideExtend ? dataTasks?.length : 3).map((item) => (
        <CCContentTableContentItem key={item.customId}>
          <CCContentTableContentItemTop>
            <CCContentTableContentExpand
              onClick={item.description ? changeErrorIsCollapseCallback(item.customId || item.id) : undefined}
              isEmpty={!!item.description}
            >
              {!!item.description && (
                <VerticalFormItemSvg
                  rotate={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? 0 : -90}
                  style={{
                    color: changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? themesColors.primaryBranding6 : themesColors.secondaryDot45,
                  }}
                />
              )}
            </CCContentTableContentExpand>
            {/* temporarily hidden */}
            {/* <CCContentTableContentPriority>
                {getPriority(item.priority)}
              </CCContentTableContentPriority> */}
            <CCContentTableContentTitle isActive={!!changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length}>
              <CCSpanEllipsis
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title || '') }}
              />
            </CCContentTableContentTitle>
            {viewDueDate ? (
              <CCContentTableContentDueDate isWarning={item.dateWarning}>
                <DatePriority date={item.dueDate?.getFormatDMMMHHmm() || ''} mode={item.datePriority} isWarning={item.dateWarning} />
              </CCContentTableContentDueDate>
            ) : null}
            {viewCreatedAt ? (
              <CCContentTableContentDueDate>
                <DatePriority date={item.createdAt?.getFormatDMMMHHmm() || ''} mode="DEFAULT" />
              </CCContentTableContentDueDate>
            ) : null}
            {viewCompletionDate ? (
              <CCContentTableContentDueDate>
                <DatePriority date={item.completedAt?.getFormatDMMMHHmm() || ''} mode="DEFAULT" />
              </CCContentTableContentDueDate>
            ) : null}
            <CCContentTableContentLink>
              {isCompleted
                ? (
                  <CCSpanEllipsis>
                    {item.completedAt?.getFormatDMMMHHmm()}
                  </CCSpanEllipsis>
                )
                : (
                  <TextLink onClick={() => handleGoToShipment(item)}>
                    {getObjectName(item)}
                  </TextLink>
                )}
            </CCContentTableContentLink>
            {viewCreatedBy ? (
              <CCContentTableContentAssignee>
                <div>
                  {item.createdByObj?.firstName && (
                    <Tooltip
                      title={(
                        <>
                          {item?.createdByObj?.getFullName() || ''}<br />
                          {item?.createdByObj?.email || ''}<br />
                          {item?.createdByObj?.phone || ''}
                        </>
                      )}
                      placement="top"
                    >
                      {!!item.createdByObj && item.createdByObj.getFirstLetterOfName().toLocaleUpperCase()}
                    </Tooltip>
                  )}
                </div>
              </CCContentTableContentAssignee>
            ) : null}
            {viewCompletedBy ? (
              <CCContentTableContentModule>
                <CCSpanEllipsis>
                  {item.completedBy?.getFullName ? item.completedBy?.getFullName() : ''}
                </CCSpanEllipsis>
              </CCContentTableContentModule>
            ) : null}
            {viewModule ? (
              <CCContentTableContentModule>
                <CCSpanEllipsis>
                  {item.module}
                </CCSpanEllipsis>
              </CCContentTableContentModule>
            ) : null}
            {viewStatus ? (
              <CCContentTableContentModule>
                <CCSpanEllipsis>
                  {item?.getStatusName()}
                </CCSpanEllipsis>
              </CCContentTableContentModule>
            ) : null}
            {viewAssignee ? (
              <CCContentTableContentAssignee isBgNone={!!item?.assigneeUsers?.length}>
                <div>
                  {item.assignee?.firstName && (
                    <Tooltip
                      title={(
                        <>
                          {item?.assignee?.getFullName() || ''}<br />
                          {item?.assignee?.email || ''}<br />
                          {item?.assignee?.phone || ''}
                        </>
                      )}
                      placement="top"
                    >
                      {!!item.assignee && item.assignee.getFirstLetterOfName().toLocaleUpperCase()}
                    </Tooltip>
                  )}
                  {!item.assignee?.firstName && item?.assigneeUsers?.length && (
                    <ContentTableTasksAssigneeUsers users={item.assigneeUsers} />
                  )}
                </div>
              </CCContentTableContentAssignee>
            ) : null}
            <CCContentTableContentAction>
              <div>
                <NavLink onClick={(e) => handleTaskActionClick(item, e)} to={item.link()}>
                  <VerticalFormItemSvg
                    rotate={-90}
                    style={{
                      color: themesColors.neutralBranding1,
                    }}
                  />
                </NavLink>
              </div>
            </CCContentTableContentAction>
          </CCContentTableContentItemTop>

          <CCContentTableContentItemBottom isActive={!!changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length}>
            <Collapse
              onChange={collapseOnChangeHandler(item?.customId || item.id)}
              activeKey={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey || []}
            >
              <Panel header="" key="1" extra={null}>
                <CCContentTableContentItemBottomDescription
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.description || '') }}
                />
              </Panel>
            </Collapse>
          </CCContentTableContentItemBottom>
        </CCContentTableContentItem>
      ))}

      {!hideExtend && (
        <CCContentTableContentExpandButtonWrap>
          {(overdueAllCount > 0) && (
            <CCContentTableContentExpandButton onClick={expandButtonOnClick}>
              <VerticalFormItemSvg
                rotate={expandButton ? 180 : 0}
                style={{
                  color: themesColors.neutralBranding7,
                }}
              />
              {expandButton
                ? t('collapseAll')
                : t('expandAll')}
              {!expandButton && dataTasks?.length
                ? (
                  <CCContentTableContentExpandButtonCount>
                    ({overdueAllCount})
                  </CCContentTableContentExpandButtonCount>
                )
                : ''}
            </CCContentTableContentExpandButton>
          )}
        </CCContentTableContentExpandButtonWrap>
      )}
    </div>
  );
};

const cachedContentTableTasksSectionComponent = memo(ContentTableTasksSectionComponent);

export { cachedContentTableTasksSectionComponent as ContentTableTasksSectionComponent };
