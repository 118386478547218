import { IPayloadState, PayloadStore } from 'proto/BaseMobxStore';
import { PaymentTermsDTM } from 'shipment-operations/models/dtm';

const initialState: IPayloadState<PaymentTermsDTM> = {
  isLoading: false,
  payload: undefined,
};

export class ShipmentPaymentTermsStore extends PayloadStore<PaymentTermsDTM> {
}

export const createShipmentPaymentTermsStore = (_initialState?: IPayloadState<PaymentTermsDTM>) => new ShipmentPaymentTermsStore(_initialState || initialState);
