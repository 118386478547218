import {
  IsBoolean,
  IsDefined, IsEnum, IsOptional, IsString,
} from 'class-validator';
import { Transform, Type } from 'class-transformer';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { BaseDTM } from 'proto/BaseDTM';

import { ContainerReeferTypes, ContainerUsualTypes } from 'shipment-operations/constants';
import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';

export interface IEventDTM {
  id: number
  type: string
  time?: IDateDTM
  createdAt: string
  place: IPlaceDTM
}

interface IPlaceDTM {
  city: string
  country: string
  code: string
  countryCode: string
}

class PlaceDTM extends BaseDTM<IPlaceDTM> {
  @IsDefined()
  @IsString()
  city: string

  @IsDefined()
  @IsString()
  country: string

  @IsDefined()
  @IsString()
  code: string

  @IsDefined()
  @IsString()
  countryCode: string
}

export class EventDTM extends BaseDTM<IEventDTM> {
  @IsDefined()
  @IsString()
  type: string

  @IsOptional()
  @IsString()
  time?: DateDtm

  @IsDefined()
  @IsString()
  createdAt: string

  @IsDefined()
  @Type(() => PlaceDTM)
  place: IPlaceDTM
}

export interface IContainerNumberDTM {
  number: string
  type: ContainerReeferTypes | ContainerUsualTypes
  typeDescription: string
  registeredAt: Moment
  isManual?: boolean
  events?: IEventDTM[]
}

export class ContainerNumberDTM extends BaseDTM<IContainerNumberDTM> {
  @IsDefined()
  @IsString()
  number: string

  @IsDefined()
  @IsString()
  typeDescription: string

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsDefined()
  @Type(() => Date)
  @Transform(({ value }) => value && moment(value), { toClassOnly: true })
  registeredAt: Moment;

  @IsOptional()
  @IsBoolean()
  isManual?: boolean;

  @IsOptional()
  @Type(() => EventDTM)
  events?: IEventDTM[]
}
