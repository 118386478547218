import { action } from 'mobx';

import { BaseMobxStore } from 'proto/BaseMobxStore';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';

interface ISFDrawerState {
  task?: NotificationTaskContentDTM;
  isLoading: boolean
}

export const initialState: ISFDrawerState = {
  isLoading: true,
  task: undefined,
};

export class ISFDrawerStore extends BaseMobxStore<ISFDrawerState> {
  initialState = { ...initialState }

  @action
  setTask(task: NotificationTaskContentDTM | undefined) {
    this.state.task = task;
  }

  @action
  setLoading(loading: boolean) {
    this.state.isLoading = loading;
  }
}

export const createISFDrawerStore = (_initialState?: ISFDrawerState) => new ISFDrawerStore(_initialState || initialState);
