import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { R as userManagementR } from 'user-management/repository';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { CommandCenterTasksShipmentComponent } from 'app-wrapper/view/pages/CommandCenterPage/CommandCenter';
import { RouteNames } from 'app-wrapper/constants';

const CommandCenterAlertsContainer: FC = () => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  const commandCenterIsHaveActiveNotification = useSelector(R.selectors.commandCenter.getCommandCenterIsHaveActiveNotification);
  const commandCenterCurrent = useSelector(R.selectors.commandCenter.getCommandCenterCurrent);
  const userManagementPermissions = useSelector(userManagementR.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const commandCenterCurrentOverdueTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentOverdueTasks);
  const commandCenterCurrentTodayTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentTodayTasks);
  const commandCenterCurrentThisWeekTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentThisWeekTasks);
  const commandCenterCurrentLaterTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentLaterTasks);

  const commandCenterCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatus);
  const commandCenterCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatusAdd);
  const commandCenterActionCurrentIsClearAllFiltersShipmentTasksCompleted = useSelector(R.selectors.commandCenter.getCommandCenterActionCurrentIsClearAllFiltersShipmentTasksCompleted);
  const isVisibleAssigneeOrganization = userManagementPermissions?.isVisibleAssigneeOrganizationTasks;

  return (
    <CommandCenterTasksShipmentComponent
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterTasksShowOnlyUnread(shipmentId)}
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStart={UC.CommandCenter.onStartTaskAlertsPage(shipmentId)}
      nextPageTasks={UC.CommandCenter.nextPageTasks(shipmentId)}
      checkAnotherDownloadNextPageTask={UC.CommandCenter.checkAnotherDownloadNextPageTask}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterDueDates(shipmentId)}
      onResetReceivedDate={UC.CommandCenter.onResetFilterDueDates(shipmentId)}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterTasksDueDates()}
      onApplyModule={UC.CommandCenter.onApplyTasksModule(shipmentId)}
      onResetModule={UC.CommandCenter.onResetTasksModule(shipmentId)}
      onChangeGroupModule={UC.CommandCenter.onChangeTasksGroupModule()}
      onApplyAssignee={UC.CommandCenter.onApplyTasksAssignee(shipmentId)}
      onResetAssignee={UC.CommandCenter.onResetTasksAssignee(shipmentId)}
      onChangeGroupAssignee={UC.CommandCenter.onChangeTasksGroupAssignee()}
      onApplyAssigneeOrganization={UC.CommandCenter.onApplyTasksAssigneeOrganization(shipmentId)}
      onResetAssigneeOrganization={UC.CommandCenter.onResetTasksAssigneeOrganization(shipmentId)}
      onChangeGroupAssigneeOrganization={UC.CommandCenter.onChangeTasksGroupAssigneeOrganization()}
      onChangeGroupObject={UC.CommandCenter.onChangeTasksGroupObject()}
      onApplyObject={UC.CommandCenter.onApplyTasksObject(shipmentId)}
      onResetObject={UC.CommandCenter.onResetTasksObject(shipmentId)}
      openAgreementsDrawer={UC.agreements.openDrawerFromShipmentTasks}
      onClearAllFilters={UC.CommandCenter.onClearAllTasksFilters(shipmentId)}
      openTaskDrawer={UC.drawer.openTaskDrawer}
      treeDataObject={commandCenterCurrent?.filters?.filterTreeDataObject?.treeData}
      filters={commandCenterCurrent?.filters}
      isLoad={commandCenterCurrentStatus}
      isAlerts
      isLoadAdd={commandCenterCurrentStatusAdd}
      isVisibleAssigneeOrganization={isVisibleAssigneeOrganization}
      overdueTasks={commandCenterCurrentOverdueTasks}
      todayTasks={commandCenterCurrentTodayTasks}
      thisWeekTasks={commandCenterCurrentThisWeekTasks}
      laterTasks={commandCenterCurrentLaterTasks}
      hideHeader
      isVisibleClearAll={commandCenterActionCurrentIsClearAllFiltersShipmentTasksCompleted}
      titleLink2={RouteNames.SHIPMENT_CENTER_ALERTS_COMPLETED(shipmentId)}
      countTasksOverdue={commandCenterCurrent.totalElementsOverdue}
      countTasksToday={commandCenterCurrent.totalElementsToday}
      countTasksThisWeek={commandCenterCurrent.totalElementsThisWeek}
      countTasksLater={commandCenterCurrent.totalElementsLater}
      infoCountAllTasks={commandCenterCurrent.infoCountAllTasks}
      infoCountOverdueTasks={commandCenterCurrent.infoCountOverdueTasks}
      infoCountDueTasks={commandCenterCurrent.infoCountDueTasks}
      infoCountPlannedTasks={commandCenterCurrent.infoCountPlannedTasks}
      isHaveNotification={commandCenterIsHaveActiveNotification}
      titleHeader={t('Alerts')}
      emptyContentTextNoTasks={t('There are no alerts assigned to you right now. See how you can assist your colleagues.')}
      titleStatisticsBlockFirst={t('All Alerts')}
      titleFilterBlockAll={t('View Organization Alerts')}
      titleNameFirstColumn={t('Alerts')}
    />
  );
};

const CommandCenterAlertsContainerCached = memo(CommandCenterAlertsContainer);

export { CommandCenterAlertsContainerCached as ShipmentAlertsPage };
