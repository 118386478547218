import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AddressLine1InputComponent } from './AddressLine1Input.component';

const AddressLine1Input = () => {
  const addressLine = useSelector(R.selectors.shippingParties.getAddressLine1);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <AddressLine1InputComponent
      error={requiredError}
      addressLine={addressLine}
      setAddressLine={UC.shippingParties.setAddressLine1}
      isAbleToEdit={permissions.isAbleToEditMainFormData}
    />
  );
};

const AddressLine1InputCached = React.memo(AddressLine1Input);

export { AddressLine1InputCached as AddressLine1Input };
