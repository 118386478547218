import React, { useCallback, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';
import { CreateOrSelectCompany } from 'shipment-operations/view/components';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ShipmentEditContainerController,
} from 'shipment-operations/controllers/ShipmentEditContainerController/ShipmentEditContainer.controller';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

const SelectCarrierContainer = observer(() => {
  const controller = useCurrentController<ShipmentEditContainerController>();
  const store = useCurrentControllerStore<ShipmentEditContainerStore>();

  const { companies } = useMobxStore();
  const { createdCompany } = useMobxStore();

  const newCompany = createdCompany.getNewCompany;

  const handleOnChange = useCallback((value) => {
    controller.carrier().onChange(value);
  }, []);

  const handleOnBlur = useCallback(() => {
    controller.carrier().onBlur();
  }, []);

  const selectedCompany = useMemo(() => companies.items.find((company) => company.id === store.state.form.carrier?.value), [companies.items, store.state.form.carrier?.value]);

  useEffect(() => {
    if (newCompany?.id) {
      controller.carrier().onChange(newCompany.id);
    }
  }, [newCompany]);

  return (
    <CreateOrSelectCompany
      companyList={companies.items}
      setCompanyName={handleOnChange}
      onBlur={handleOnBlur}
      availability={PermissionAttributePolicy.WRITE}
      openAddCompanyDrawer={UC.bookingWizardDrawer.openEditContainerAddCompanyDrawer}
      selectedCompany={selectedCompany}
    />
  );
});

export { SelectCarrierContainer as SelectCarrier };
