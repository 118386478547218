import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';
import { LinkContainerComponent } from './LinkContainer.component';

export const LinkContainerContainer = memo(({ planId }: { planId: number }) => {
  const containers = useSelector(R.selectors.shipmentTracker.getDispatchedContainersTable);
  const isLoadingAction = useSelector(R.selectors.shipmentTracker.getActionLoading);

  return (
    <LinkContainerComponent
      onClose={appUC.drawer.closeDrawer}
      containers={containers}
      onLinkContainer={UC.shipmentTracker.onLinkContainer}
      planId={planId}
      isLoadingAction={isLoadingAction}
    />
  );
});

export { LinkContainerContainer as LinkContainer };
