import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { RFRTabsComponent } from './RFRTabs.component';

const RFRTabsContainer: FC = () => {
  const {
    loadType,
    incotermsTrade,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getFreightQuoteErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  return (
    <RFRTabsComponent
      loadType={loadType || ''}
      isAllDisabled
      incotermsTrade={incotermsTrade || ''}
      isSubmitVisited
      incotermsTradeError={{
        ...getFreightQuoteErrors?.incotermsTrade,
        message: '',
      }}
      onChangeFreightLoadType={UC.FreightQuote.onChangeFreightLoadType}
      onChangeFreightIncotermsTrade={UC.FreightQuote.onChangeFreightIncotermsTrade}
    />
  );
};

const RFRTabsContainerCache = memo(RFRTabsContainer);

export { RFRTabsContainerCache as RFRTabs };
