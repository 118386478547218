import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appWrapperUC } from 'app-wrapper/controllers';

import { observer } from 'mobx-react-lite';
import { ShipmentContainersAddContainerModalComponent } from './ShipmentContainersAddContainerModal.component';

const ShipmentContainersAddContainerModalContainer: FC = observer(() => {
  const { shipmentId } = useParams<'shipmentId'>();
  const containerBasicType = useSelector(R.selectors.shipment.getContainersBasicType);
  const isLoading = useSelector(R.selectors.shipmentNewContainer.getLoadingState);
  const isLoadingPage = useSelector(R.selectors.shipmentContainers.getLoadingState);
  const isDisabled = useSelector(R.selectors.shipmentContainers.isNewContainerCreated);
  const disable = useSelector(R.selectors.shipment.getIsDisableChanges);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainersAddContainerModalComponent
      isLoading={isLoading}
      isLoadingPage={isLoadingPage}
      isDisabled={isDisabled}
      containerBasicType={containerBasicType}
      onAddHandler={UC.shipmentContainers.addContainer}
      openChangeRequestDrawer={appWrapperUC.drawer.openChangeRequestDrawer}
      shipmentId={shipmentId}
      disable={disable}
      permissions={permissions}
    />
  );
});

export {
  ShipmentContainersAddContainerModalContainer as ShipmentContainersAddContainerModal,
};
