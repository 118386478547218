import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { CompanySelect as CompanySelectComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components';

export const CompanySelect = React.memo(() => {
  const companiesList = useSelector(R.selectors.bookingWizard.getCompaniesList);
  const company = useSelector(R.selectors.bookingWizard.getCargoReceiverCompany);
  const cargoReceiverAddress = useSelector(R.selectors.bookingWizard.getCargoReceiverAddress);
  const isErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoReceiverRequiredErrorVisible);

  return (
    <CompanySelectComponent
      companyId={company?.id}
      setCompanyId={UC.bookingWizard.setReceiverCompanyId}
      isRequiredErrorVisible={isErrorVisible}
      companiesList={companiesList}
      prefilledAddress={cargoReceiverAddress}
      openAddCompanyDrawer={UC.bookingWizardDrawer.openAddCompanyDrawerFromDryage}
    />
  );
});
