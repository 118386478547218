import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const DatePriorityWrapHigh = styled.div.attrs({
  'data-class': 'DatePriorityWrapHigh',
})`
  ${divFlex}
  align-items: center;

  padding: 5px 8px;
  height: 22px;

  background-color: ${({ theme }) => theme.themesColors.redBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.redBranding3};
  border-radius: 2px;

  > svg {
    margin-right: 3px;
  }
`;

export const DatePriorityTitleHigh = styled.div.attrs({
  'data-class': 'DatePriorityTitleHigh',
})`
  ${divFlex}

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.redBranding6};
`;

export const DatePriorityWrapMedium = styled.div.attrs({
  'data-class': 'DatePriorityWrapMedium',
})`
  ${divFlex}
  align-items: center;

  padding: 5px 8px;
  height: 22px;

  background-color: ${({ theme }) => theme.themesColors.yellowBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.yellowBranding3};
  border-radius: 2px;
`;

export const DatePriorityTitleMedium = styled.div.attrs({
  'data-class': 'DatePriorityTitleMedium',
})`
  ${divFlex}

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.yellowBranding6};
`;

export const DatePriorityWrapLow = styled.div.attrs({
  'data-class': 'DatePriorityWrapLow',
})`
  ${divFlex}
  align-items: center;

  padding: 5px 8px;
  height: 22px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 2px;
  overflow: hidden;
`;

export const DatePriorityWrapDefault = styled.div.attrs({
  'data-class': 'DatePriorityWrapDefault',
})`
  ${divFlex}
  align-items: center;

  padding: 5px 0px;
  height: 22px;

  border-radius: 2px;
  overflow: hidden;
`;

export const DatePriorityTitleLow = styled.div.attrs({
  'data-class': 'DatePriorityTitleLow',
})`
  ${divFlex}
  display: inline;

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};

  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;
