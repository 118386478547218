import styled from 'styled-components';
import {
  RangePicker,
  Select, Row,
  DatePicker,
} from 'app-wrapper/view/components';
import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';

export const AddressBlockSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'AddressBlockSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }

  input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10}
  }
`;

export const AddressBlockRangePicker = styled(RangePicker).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockRangePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${responsive.forFullMedia`
    min-height: 40px;
  `}

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;

export const RowAddress = styled(Row)`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 12px;
`;

export const RowAddressNotCollapsed = styled(RowAddress)`
  .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
  .ant-picker-dropdown {
    top: 32px !important;
  }
`;

export const RowAddressBoth = styled(Row)`
  display: flex;
  position: relative;
  width: 100%;
`;

export const RowAddressMsg = styled(Row)`
  width: 100%;
`;

export const AddressBlockRadioGroup = styled.div.attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockRadioGroup',
}))`
  ${divFlex}
`;

export const AddressBlockDatePicker = styled(DatePicker).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockDatePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${responsive.forFullMedia`
    min-height: 40px;
  `}

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;
