export const US_COUNTRY_CODE = 'US';
export const PL_COUNTRY_CODE = 'PL';

export const getLocationDoorCode = (address?: string) => {
  if (address?.includes('Poland') || address?.includes(PL_COUNTRY_CODE)) {
    return PL_COUNTRY_CODE;
  }

  return US_COUNTRY_CODE;
};
