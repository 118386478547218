import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';

import { DrawerFooterComponent } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ChangeCompanyDrawer/components/DrawerFooter/DrawerFooter.component';

const DrawerFooterContainer = React.memo(() => {
  const contactId = useSelector(R.selectors.bookingWizard.getChosenContactId);

  return (
    <DrawerFooterComponent
      onClose={appUC.drawer.closeDrawer}
      onSave={UC.bookingWizard.selectContactFromBook}
      isAbleToSave={!!contactId}
    />
  );
});

export { DrawerFooterContainer as DrawerFooter };
