import styled from 'styled-components';
import AntDropdown from 'antd/es/dropdown';
import UserOutlined from '@ant-design/icons/UserOutlined';

import {
  BodyTextMedium,
  BodyTextRegular,
} from 'app-wrapper/view/fonts';
import {
  FlexCol,
  FlexRow,
  FlexCenter,
} from 'app-wrapper/view/guideline';
import { Button } from 'app-wrapper/view/components';

export const WorkgroupWrap = styled.div.attrs({
  'data-class': 'WorkgroupWrap',
})``;

export const Title = styled.span`
  margin-top: 6px;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const RoleText = styled.span`
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 12px;
  padding-left: 12px;
  height: 24px;
  border-left: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  color: #73819b;
  ${BodyTextRegular}
`;

export const AddButton = styled(Button)`
  width: 63px;
  margin-left: auto;
  height: 24px;
  font-size: 14px;
  line-height: 22px;
`;

export const MemberMainInfo = styled(FlexRow)`
  width: 25%;
`;

export const RoleTitle = styled.span`
  ${BodyTextMedium}
`;

export const CustomerBlock = styled(FlexCenter)`
  position: absolute;
  top: 0;
  left: 0;
  width: 98px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const CustomerUserIcon = styled(UserOutlined)`
  font-size: 16px;
  color: rgba(211, 215, 245, 1);
`;

export const Member = styled.div`
  position: relative;
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  width: 100%;
  min-height: 76px;
  height: fit-content;
`;

export const NameLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  font-size: 14px;
  line-height: 22px;
`;

export const Info = styled(FlexCol)`
  margin-left: 16px;
`;

export const InfoFullName = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const InfoJobTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const Item = styled(FlexRow)`
  width: 25%;
`;

export const ItemIconWrap = styled(FlexCenter)`
  margin-right: 16px;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
  
  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const ItemText = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const Dropdown = styled(AntDropdown)`
  
`;

export const DropdownOverlay = styled.div.attrs({
  'data-class': 'DropdownOverlay',
})`
  width: 118px;
  background-color: #FFFFFF;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const RemoveOption = styled.div`
  padding: 5px 12px;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  height: 32px;
  color: rgba(255, 77, 79, 1);
  transition: all ease-in .2s;
  cursor: pointer;

  &:hover {
    background-color: rgba(245, 245, 245, 1);
  }
`;

export const NoMembers = styled(FlexCenter)`
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding5};
  height: 64px;
`;

export const NoMembersIcon = styled(FlexCenter)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.043);
  cursor: pointer;
`;
