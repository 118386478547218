import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IBookingConfirmationPDFState } from 'shipment-operations/models/states';
import {
  FullShipmentDTM,
  IRouteLegDTM,
  ShippingPartyDTM,
  ShortChargeDTM,
  ChargeDTM,
  ShipmentPreviewDTM,
  CountryDTM,
} from 'shipment-operations/models/dtm';
import { IRFQServiceByIdContentRoutesTransportation } from 'monetary/models/dtm/Quotas';

const initialState: IBookingConfirmationPDFState = {
  isLoading: false,
  isLoadingFinished: false,
  fullShipment: null,
  shippingParties: [],
  countriesList: [],
  shipment: null,
  charges: [],
  chargesPairs: [],
};

export const bookingConfirmationPDFSlice = createSlice({
  name: 'bookingConfirmationPDF',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsLoadingFinished: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoadingFinished: payload,
    }),
    setCountriesList: (state, { payload }: PayloadAction<CountryDTM[]>) => ({
      ...state,
      countriesList: payload,
    }),
    setCharges: (state, { payload }: PayloadAction<ShortChargeDTM[]>) => ({
      ...state,
      charges: payload,
    }),
    setChargesPairs: (state, { payload }: PayloadAction<ChargeDTM[]>) => ({
      ...state,
      chargesPairs: payload,
    }),
    setFullShipment: (state, { payload }: PayloadAction<FullShipmentDTM | null>) => ({
      ...state,
      fullShipment: payload,
    }),
    setShippingParties: (state, { payload }: PayloadAction<ShippingPartyDTM[]>) => ({
      ...state,
      shippingParties: payload,
    }),
    setLowestSequenceLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      lowestSequenceLeg: action.payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM | null>) => ({
      ...state,
      shipment: payload,
    }),
    setLowestSequenceSeaLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      lowestSequenceSeaLeg: action.payload,
    }),
    setHighestSequenceSeaLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      highestSequenceSeaLeg: action.payload,
    }),
    setHighestSequenceLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      highestSequenceLeg: action.payload,
    }),
    setLowestSequenceTransportation: (state, action: PayloadAction<IRFQServiceByIdContentRoutesTransportation | undefined>) => ({
      ...state,
      lowestTransportation: action.payload,
    }),
    setHighestSequenceTransportation: (state, action: PayloadAction<IRFQServiceByIdContentRoutesTransportation | undefined>) => ({
      ...state,
      highestTransportation: action.payload,
    }),
    setHighestSeaSequenceTransportation: (state, action: PayloadAction<IRFQServiceByIdContentRoutesTransportation | undefined>) => ({
      ...state,
      highestSeaTransportation: action.payload,
    }),
    setLowestSeaSequenceTransportation: (state, action: PayloadAction<IRFQServiceByIdContentRoutesTransportation | undefined>) => ({
      ...state,
      lowestSeaTransportation: action.payload,
    }),
    clear: () => initialState,
  },
});

export const bookingConfirmationPDFActions = bookingConfirmationPDFSlice.actions;
export const bookingConfirmationPDFReducer = bookingConfirmationPDFSlice.reducer;
