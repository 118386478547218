import { action } from 'mobx';

import { BaseMobxStore } from 'proto/BaseMobxStore';
import { IEditLoadingControlsState } from 'shipment-operations/models/states';
import { EShipmentLoadingMethod, EShipmentReceivingMethod } from 'shipment-operations/constants';
import { LoadingControlDTM } from 'shipment-operations/models/dtm';

const initialState: IEditLoadingControlsState = {
  isLoading: false,
  loadingControls: undefined,
  isRequiredErrorVisible: false,
};

export class EditLoadingControlsDrawerStore extends BaseMobxStore<IEditLoadingControlsState> {
  @action
  setLoading(loading: boolean) {
    this.state.isLoading = loading;
  }

  @action
  setIsRequiredErrorVisible(isVisible: boolean) {
    this.state.isRequiredErrorVisible = isVisible;
  }

  @action
  setLoadingControls(loadingControls: LoadingControlDTM | undefined) {
    this.state.loadingControls = loadingControls;
  }

  @action
  setLoadingTime(loadingTime?: number) {
    if (this.state.loadingControls) {
      this.state.loadingControls = LoadingControlDTM.fromPlain({
        ...this.state.loadingControls,
        loadingTime,
      });
    }
  }

  @action
  setLoadingMethod(loadingMethod: EShipmentLoadingMethod) {
    if (this.state.loadingControls) {
      this.state.loadingControls = LoadingControlDTM.fromPlain({
        ...this.state.loadingControls,
        loadingMethod,
      });
    }
  }

  @action
  setReceivingMethod(receivingMethod?: EShipmentReceivingMethod) {
    if (this.state.loadingControls) {
      this.state.loadingControls = LoadingControlDTM.fromPlain({
        ...this.state.loadingControls,
        receivingMethod,
      });
    }
  }
}

export const createEditLoadingControlsDrawerStore = (_initialState?: IEditLoadingControlsState) => new EditLoadingControlsDrawerStore(_initialState || initialState);
