import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { LoadingDetailsComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/LoadingDetails';

export const LoadingDetails = React.memo(() => {
  const isOriginPartner = useSelector(R.selectors.bookingWizard.getIsOriginPartner);
  const isWarningVisible = useSelector(R.selectors.bookingWizard.getIsLoadingMethodsInformationMissing);
  const loadingControls = useSelector(R.selectors.bookingWizard.getSupplierLoadingControls);
  const requiredError = useSelector(R.selectors.bookingWizard.getIsCargoSupplierRequiredErrorVisible);

  return (
    <LoadingDetailsComponent
      loadingMethod={loadingControls?.loadingMethod}
      receivingMethod={loadingControls?.receivingMethod}
      setLoadingMethod={UC.bookingWizard.setSupplierLoadingMethod}
      setReceivingMethod={UC.bookingWizard.setSupplierReceivingMethod}
      isWarningVisible={isWarningVisible}
      isOriginOrDestinationPartner={isOriginPartner}
      isRequiredErrorVisible={requiredError}
    />
  );
});
