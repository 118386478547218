import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import FileAddOutlined from '@ant-design/icons/FileAddOutlined';

import { Button, WarningBlock } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1660px;
  margin: 0 auto;
`;

export const TabsStyled = styled(Tabs)`
  min-height: 54px;
  width: 100%;

  & > div {
    margin-bottom: 0px !important;
  }
`;

export const Footer = styled.div`
  position: absolute;
  padding: 14px 24px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid rgba(244, 245, 248, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px -12px 24px -10px rgba(0,0,0,0.21);
`;

export const ApproveButton = styled(Button)`
  margin-left: 8px;
`;

export const RejectButton = styled(Button)`
  margin-left: auto;
  border-color: rgba(255, 77, 79, 1) !important;
  color: rgba(255, 77, 79, 1) !important;

  &:disabled {
    color: rgb(144, 144, 144) !important;
  }
`;

export const AdditionalInfoButton = styled(Button)`
  margin-right: auto;
  
  &:hover .anticon {
    color: rgb(108, 121, 222);
  }
`;

export const AdditionalInfoIcon = styled(FileAddOutlined)`
  margin-top: 2px;
  color: #a2a4ab;
  font-size: 15px;
  
  &.anticon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

export const WarningWrap = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const Warning = styled(WarningBlock)`
  min-height: fit-content;
`;
