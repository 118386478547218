import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';
import { EOrganizationType, OrganizationIndustryArray } from 'user-management/constants';

import { CustomerAddInternalGeneralInformationComponent } from './CustomerAddInternalGeneralInformation.component';

const CustomerAddInternalGeneralInformationContainer: FC = () => {
  const customerOrganization = useSelector(R.selectors.userOrganizationData.getCustomersAddInternal);
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalErrors);

  const organizationDisabledFieldsErrors = userManagementPermissions?.organizationEditProfileIdentifiers;
  const organizationEditProfileIdentifiersIndustryErrors = organizationDisabledFieldsErrors || customerOrganization?.type === EOrganizationType.FF
    || customerOrganization?.type !== EOrganizationType.BCO;

  return (
    <CustomerAddInternalGeneralInformationComponent
      organization={customerOrganization}
      organizationIndustry={OrganizationIndustryArray}
      organizationFieldsErrors={organizationFieldsErrors}
      organizationEditProfileIdentifiersIndustryErrors={organizationEditProfileIdentifiersIndustryErrors}
      onChangeUpdateName={UC.organizationProfile.onChangeCustomerAddInternalName}
      onChangeUpdateType={UC.organizationProfile.onChangeCustomerAddInternalType}
      onChangeUpdateIndustry={UC.organizationProfile.onChangeCustomerAddInternalIndustry}
    />
  );
};

export const CustomerAddInternalGeneralInformation = memo(CustomerAddInternalGeneralInformationContainer);
