import styled from 'styled-components';

import { TitleText, BodyTextRegular } from 'app-wrapper/view/fonts';
import { SubPageContent } from 'app-wrapper/view/guideline';
import { TeamsTable } from 'user-management/view/pages/Teams/components/TeamsTable/TeamsTable.styled';

export const Table = styled(TeamsTable)`
  .ant-table table {
    padding: 0;
  }
`;

export const Content = styled(SubPageContent)``;

export const Title = styled.span`
  ${TitleText}
`;

export const Text = styled.span`
  ${BodyTextRegular}
`;
