import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import MailOutlined from '@ant-design/icons/MailOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { EditEllipsisComponent } from 'app-wrapper/view/components';

import { WORKGROUP_ROLES, EWorkgroupTypesEnum } from 'shipment-operations/constants';
import { FlexRow, MissingTextValue } from 'app-wrapper/view/guideline';
import { WorkgroupDTM } from 'shipment-operations/models/dtm';

import {
  WorkgroupWrap,
  Title,
  RoleText,
  AddButton,
  Member,
  MemberMainInfo,
  NameLetter,
  Info,
  InfoFullName,
  InfoJobTitle,
  Item,
  ItemText,
  RoleTitle,
  ItemIconWrap,
  Dropdown,
  DropdownOverlay,
  RemoveOption,
  NoMembers,
  NoMembersIcon,
  CustomerBlock,
  CustomerUserIcon,
} from './Workgroup.styled';

interface IWorkgroupComponentProps {
  workgroup: WorkgroupDTM;
  onRemove?: (memberId: number) => void;
  openAddMemberDrawer?: (workgroup?: WorkgroupDTM) => void;
  currentOrganizationId?: number;
  isAbleToViewCompanyName: boolean;
}

const WorkgroupComponent: FC<IWorkgroupComponentProps> = ({
  workgroup,
  onRemove,
  openAddMemberDrawer,
  currentOrganizationId,
  isAbleToViewCompanyName,
}) => {
  const { t } = useTranslation();
  const { members } = workgroup;

  const handleRemove = useCallback((memberId: number) => {
    Modal.confirm({
      title: t('Are you sure you want to remove this member?'),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        if (onRemove) {
          onRemove(memberId);
        }
      },
    });
  }, [onRemove]);

  const handleOpenMemberDrawer = useCallback(() => {
    if (openAddMemberDrawer) {
      openAddMemberDrawer(workgroup.type === EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES ? undefined : workgroup);
    }
  }, [openAddMemberDrawer, workgroup]);

  const isMyOrganization = useMemo(() => currentOrganizationId === workgroup.organizationId, [currentOrganizationId, workgroup]);

  return (
    <WorkgroupWrap>
      <FlexRow>
        <Title>
          {workgroup.type === EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES && isMyOrganization ? `${t('Your Organization')}` : (
            <>
              {`${workgroup.name} ${isMyOrganization ? t('(Your Organization)') : ''}`}
            </>
          )}
        </Title>

        {isAbleToViewCompanyName ? (
          <>
            {WORKGROUP_ROLES[workgroup.type].length ? (
              <RoleText>
                {WORKGROUP_ROLES[workgroup.type]}
              </RoleText>
            ) : null}
          </>
        ) : null}

        {isMyOrganization && !!workgroup.members.length && openAddMemberDrawer ? (
          <AddButton
            onClick={handleOpenMemberDrawer}
            type="default"
            icon={<PlusOutlined />}
          >
            {workgroup.type === EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES ? t('Add') : t('Edit')}
          </AddButton>
        ) : null}
      </FlexRow>

      {members.length ? (
        <>
          {members.map((member) => (
            <Member key={`WorkgroupComponent_${member.id}`}>
              {workgroup.type === EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES ? (
                <CustomerBlock>
                  <CustomerUserIcon />
                </CustomerBlock>
              ) : null}

              <Item>
                <RoleTitle>
                  {member.getRoleName()}
                </RoleTitle>
              </Item>

              <MemberMainInfo>
                <NameLetter>
                  {member.getFirstLetterOfName()}
                </NameLetter>

                <Info>
                  <InfoFullName>
                    {member.getFullName()}
                  </InfoFullName>

                  <InfoJobTitle>
                    {member.jobTitle ? (
                      <>
                        {member.jobTitle ? `${t('Job Title: ')} ${member.jobTitle}` : ''}
                        <br />
                      </>
                    ) : null}
                  </InfoJobTitle>
                </Info>
              </MemberMainInfo>

              <Item>
                <ItemIconWrap>
                  <MailOutlined />
                </ItemIconWrap>

                <ItemText>
                  {member.email}
                </ItemText>
              </Item>

              <Item>
                <ItemIconWrap>
                  <PhoneOutlined />
                </ItemIconWrap>

                {member.phone ? (
                  <ItemText>
                    {member.phone}
                  </ItemText>
                ) : <MissingTextValue />}

                {onRemove && isMyOrganization && workgroup.type === EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES ? (
                  <Dropdown
                    overlay={(
                      <DropdownOverlay>
                        <RemoveOption onClick={() => handleRemove(member.id)}>
                          {t('Remove')}
                        </RemoveOption>
                      </DropdownOverlay>
                    )}
                  >
                    <EditEllipsisComponent />
                  </Dropdown>
                ) : null}
              </Item>
            </Member>
          ))}
        </>
      ) : (
        <NoMembers>
          <NoMembersIcon onClick={handleOpenMemberDrawer}>
            <PlusOutlined />
          </NoMembersIcon>
        </NoMembers>
      )}
    </WorkgroupWrap>
  );
};

export { WorkgroupComponent as Workgroup };
