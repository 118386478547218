import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { ICountryListDTM } from 'app-wrapper/models/dtm';
import { EMembershipType, EOrganizationMemberRole, EOrganizationType } from 'user-management/constants';
import { EOrganizationStatus, OrganizationDTM } from 'user-management/models/dtm';
import { DocumentType } from 'shipment-operations/constants';

const localState = (state: RootState) => state.userOrganizationData;

export const formatAllowedMembershipTypes = (organization: OrganizationDTM) => {
  const { memberships } = organization;
  const membershipsTypes = memberships?.map(({ type }) => type) || [];
  const allowedTypes = [
    ...(!membershipsTypes.includes(EMembershipType.WCA_ID) ? [EMembershipType.WCA_ID] : []),
    ...(!membershipsTypes.includes(EMembershipType.DNB_ID) ? [EMembershipType.DNB_ID] : []),
    ...(!membershipsTypes.includes(EMembershipType.XLP) ? [EMembershipType.XLP] : []),
    ...(!membershipsTypes.includes(EMembershipType.GAA) ? [EMembershipType.GAA] : []),
    ...(!membershipsTypes.includes(EMembershipType.JC_TRANS) ? [EMembershipType.JC_TRANS] : []),
    ...(!membershipsTypes.includes(EMembershipType.GLA) ? [EMembershipType.GLA] : []),
  ];

  return allowedTypes;
};

const limitedCountries = [
  'AT',
  'AU',
  'BE',
  'BG',
  'HR',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'IE',
  'IT',
  'LV',
  'LT',
  'NL',
  'PL',
  'PT',
  'ES',
  'SE',
  'NO',
  'AE',
  'SA',
  'IL',
  'CA',
  'MX',
  'JP',
  'KR',
  'US',
  'GB',
  'CN',
  'IN',
  'QA',
  'BD',
  'VN',
  'JO',
  'HK',
];

const getUserOrganization = createSelector(
  localState,
  (state) => state.organization,
);

const getUserOrganizationId = createSelector(
  getUserOrganization,
  (organization) => organization?.id,
);

const getUserOrganisationRole = createSelector(
  getUserOrganization,
  (organisation) => organisation?.role,
);

const getHasRequiredDocuments = createSelector(
  getUserOrganization,
  (organization) => {
    if (!organization) {
      return false;
    }

    const { documents } = organization;
    const isBLICRequired = organization.type === EOrganizationType.NVOCC || organization.type === EOrganizationType.FF;

    const docsALO = documents.find(({ type }) => type === DocumentType.ALO);
    const docsCCI = documents.find(({ type }) => type === DocumentType.CCI);
    const docsBLIC = documents.find(({ type }) => type === DocumentType.BLIC);
    const docsTCER = documents.find(({ type }) => type === DocumentType.TCER);

    return Boolean(docsALO && docsCCI && (!isBLICRequired || (isBLICRequired && docsBLIC)) && docsTCER);
  },
);

const getOrganizations = createSelector(
  localState,
  (state) => state.organizations,
);

const getOrganizationsActiveCustomer = createSelector(
  localState,
  (state) => state.organizationsActive,
);

const getOrganizationsWaitingApprovalCustomer = createSelector(
  localState,
  (state) => state.organizationsWaitingApproval,
);

const getOrganizationsRejectCustomer = createSelector(
  localState,
  (state) => state.organizationsReject,
);

const getInternalOrganization = createSelector(
  localState,
  (state) => state.organizationInternal,
);

const getOrganizationsStatus = createSelector(
  localState,
  (state) => state.organizationsStatus,
);

const getOrganizationsAddStatus = createSelector(
  localState,
  (state) => state.organizationsAddStatus,
);

const getOrganizationsPage = createSelector(
  localState,
  (state) => state.organizationsPage,
);

const getOrganizationsIsEnd = createSelector(
  localState,
  (state) => state.organizationsIsEnd,
);

const getOrganizationsActiveIsEnd = createSelector(
  localState,
  (state) => state.organizationsActiveIsEnd,
);

const getOrganizationsWaitingApprovalIsEnd = createSelector(
  localState,
  (state) => state.organizationsWaitingApprovalIsEnd,
);

const getOrganizationsRejectIsEnd = createSelector(
  localState,
  (state) => state.organizationsRejectIsEnd,
);

const getUserOrganizationUpdate = createSelector(
  localState,
  (state) => state.organizationUpdate,
);

const getOrganizationAllowedMembershipTypes = createSelector(
  getUserOrganizationUpdate,
  formatAllowedMembershipTypes,
);

const getUserOrganizationUpdateCoverageAreas = createSelector(
  getUserOrganizationUpdate,
  (state) => state.coverageAreas,
);

const getCustomersAddInternal = createSelector(
  localState,
  (state) => state.customersAddInternal,
);

const organizationFieldsErrors = createSelector(
  localState,
  (state) => state.organizationFieldsErrors,
);

const getCustomersAddInternalErrors = createSelector(
  localState,
  (state) => state.customersAddInternalErrors,
);

const getCustomersAddInternalAllowedMembershipTypes = createSelector(
  getCustomersAddInternal,
  formatAllowedMembershipTypes,
);

const organizationCountries = createSelector(
  localState,
  (state) => state.countries?.map((itemList): ICountryListDTM => ({
    code: itemList.code,
    name: itemList.name,
  })),
);

const organizationCountriesLimited = createSelector(
  localState,
  (state) => state.countries?.filter((item) => limitedCountries.includes(item.code))
    ?.map((itemList): ICountryListDTM => ({
      code: itemList.code,
      name: itemList.name,
    })),
);

const organizationCountriesAll = createSelector(
  localState,
  (state) => state.countries?.map((itemList): ICountryListDTM => ({
    code: itemList.code,
    name: itemList.name,
  })),
);

const organizationErrorMessage = createSelector(
  localState,
  (state) => state.errorMessage,
);

const organizationSuccessMessage = createSelector(
  localState,
  (state) => state.successMessage,
);

const getOrganizationUserMemberData = createSelector(
  localState,
  (state) => state.organizationUserData,
);

const getOrganizationUserPhone = createSelector(
  localState,
  (state) => state.organizationUserData?.phone,
);

const getCurrentOrganizationUser = createSelector(
  localState,
  (state) => state.organizationUserData,
);

const getCurrentOrganizationUserRole = createSelector(
  localState,
  (state) => state.organizationUserData?.role,
);

const isAdmin = createSelector(
  localState,
  (state) => state.organizationUserData?.role === EOrganizationMemberRole.admin,
);

const isMember = createSelector(
  localState,
  (state) => state.organizationUserData?.role === EOrganizationMemberRole.member,
);

const getIsCustomerOrganization = createSelector(
  getUserOrganization,
  (organization) => !organization || !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER),
);

const getIsPartnerOrganization = createSelector(
  getUserOrganization,
  (organization) => !!(organization && organization.role === EOrganizationMemberRole.PARTNER),
);

const getHasOrganizationTeamOrManager = createSelector(
  getUserOrganization,
  (organization) => !!organization && (!!organization.accountManager || !!organization.accountTeam),
);

const isOrganizationsDataLoading = createSelector(
  localState,
  (state) => state.organizationsDataIsLoading,
);

const getIsOrganizationInDraft = createSelector(
  localState,
  (state) => !state.organization || state.organization.status === EOrganizationStatus.DRAFT,
);

const getIsOrganizationInWaitingForApproval = createSelector(
  localState,
  (state) => Boolean(state.organization && state.organization.status === EOrganizationStatus.WAITING_APPROVAL),
);

const getIsOrganizationActive = createSelector(
  localState,
  (state) => Boolean(state.organization && state.organization.status === EOrganizationStatus.ACTIVE),
);

const getIsOrganizationRejected = createSelector(
  getUserOrganization,
  (organization) => !!(organization && organization.status === EOrganizationStatus.REJECTED),
);

const getOrganizationReview = createSelector(
  getUserOrganization,
  (organization) => organization?.review,
);

const getIsOrganizationTermsAndConditionAccepted = createSelector(
  getUserOrganization,
  (organization) => organization?.isTermsAndConditionAccepted(),
);

const getMustOrganizationAcceptTermsAndCondition = createSelector(
  getUserOrganization,
  (organization) => organization?.mustAccepTermsAndConditions(),
);

const getTeams = createSelector(
  localState,
  (state) => state.teams,
);

const getAccountManagers = createSelector(
  localState,
  (state) => state.accountManagers,
);

const getSalesManagers = createSelector(
  localState,
  (state) => state.salesManagers,
);

const getTempTeam = createSelector(
  localState,
  (state) => state.tempTeam,
);

const getTempAccountManager = createSelector(
  localState,
  (state) => state.tempAccountManager,
);

const getTeamToSave = createSelector(
  localState,
  (state) => {
    const { tempTeam, teams } = state;

    return teams.find(({ id }) => tempTeam === id) || undefined;
  },
);

const getAccountManagerToSave = createSelector(
  localState,
  (state) => {
    const { tempAccountManager, accountManagers } = state;

    return accountManagers.find(({ email }) => email === tempAccountManager) || undefined;
  },
);

const getTempTeamError = createSelector(
  localState,
  (state) => state.tempTeamError,
);

const getTempAccountManagerError = createSelector(
  localState,
  (state) => state.tempAccountManagerError,
);

const getTempSalesManager = createSelector(
  localState,
  (state) => state.tempSalesManager,
);

const getTempSalesManagerError = createSelector(
  localState,
  (state) => state.tempSalesManagerError,
);

const getSalesManagerToSave = createSelector(
  localState,
  (state) => {
    const { tempSalesManager, salesManagers } = state;

    return salesManagers.find(({ email }) => email === tempSalesManager) || undefined;
  },
);

export const userOrganizationDataSelectors = {
  getCurrentOrganizationUser,
  getCurrentOrganizationUserRole,
  getUserOrganisationRole,
  getCustomersAddInternal,
  getCustomersAddInternalErrors,
  getInternalOrganization,
  getOrganizations,
  getOrganizationsActiveCustomer,
  getOrganizationsWaitingApprovalCustomer,
  getOrganizationsRejectCustomer,
  getOrganizationsAddStatus,
  getOrganizationsIsEnd,
  getOrganizationsActiveIsEnd,
  getOrganizationsWaitingApprovalIsEnd,
  getOrganizationsRejectIsEnd,
  getOrganizationsPage,
  getOrganizationsStatus,
  getUserOrganization,
  getUserOrganizationUpdate,
  getUserOrganizationUpdateCoverageAreas,
  isAdmin,
  isMember,
  isOrganizationsDataLoading,
  organizationCountries,
  organizationCountriesLimited,
  organizationCountriesAll,
  organizationErrorMessage,
  organizationFieldsErrors,
  organizationSuccessMessage,
  getIsOrganizationInDraft,
  getIsOrganizationInWaitingForApproval,
  getHasRequiredDocuments,
  getIsOrganizationActive,
  getIsOrganizationRejected,
  getOrganizationReview,
  getIsCustomerOrganization,
  getIsPartnerOrganization,
  getHasOrganizationTeamOrManager,
  getIsOrganizationTermsAndConditionAccepted,
  getMustOrganizationAcceptTermsAndCondition,
  getTeams,
  getAccountManagers,
  getTempAccountManager,
  getTempTeam,
  getTeamToSave,
  getAccountManagerToSave,
  getTempTeamError,
  getTempAccountManagerError,
  getOrganizationUserPhone,
  getOrganizationUserMemberData,
  getSalesManagers,
  getTempSalesManager,
  getTempSalesManagerError,
  getSalesManagerToSave,
  getUserOrganizationId,
  getOrganizationAllowedMembershipTypes,
  getCustomersAddInternalAllowedMembershipTypes,
};
