import styled from 'styled-components';

import { Input, Select } from 'app-wrapper/view/components';
import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { TooltipLocCss } from 'app-wrapper/view/styles/mixins';

export const CommoditySelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CommoditySelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }

  input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }
`;

export const CommoditySelectOptionHeader = styled.div.attrs((props: {
  isDisabled: boolean
  'data-class'?: string
}) => ({
  isDisabled: props.isDisabled,
  'data-class': props?.['data-class'] || 'CommoditySelectOptionHeader',
}))`
  color: ${({ theme, isDisabled }) => (isDisabled ? theme?.themesColors?.secondaryDot45 : theme?.themesColors?.neutralBranding10)};
  font-weight: 500;
  font-size: 12px;
`;

export const CommodityInput = styled(Input).attrs((props: {
  $isError: boolean
  $isValue: boolean
  'data-class'?: string
}) => ({
  $isError: props.$isError,
  $isValue: props.$isValue,
  'data-class': props?.['data-class'] || 'CommodityInput',
}))`
  ${({ $isError, theme }) => $isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ $isError }) => $isError && 'z-index: 2;'};
  .ant-input-prefix {
    ${({ $isValue, theme }) => $isValue && `color: ${theme.themesColors.secondaryDot45};`};
  }
`;

export const CommodityWrapper = styled.div.attrs((props: {
  isNotCollapsed: boolean
}) => ({
  isNotCollapsed: props.isNotCollapsed,
  'data-class': 'CommodityWrapper',
}))`
  .FreightQuoteFormRequest__Row__Commodity__content__col_add {
    &__tooltip {
      min-width: 32px;
      max-width: 178px;

      ${TooltipLocCss}

      ${responsive.forFullMedia`
        min-width: 40px;
      `}
    }
  }

  ${({ isNotCollapsed }) => isNotCollapsed && `
    .FreightQuoteFormRequest__Row__Commodity__content__col_add {
      padding-bottom: 7px;
    }
    .ant-col-18 {
      .FreightQuoteFormRequest__Row__Commodity__content_title {
        padding-top: 4px;
      }
    }
  `}

  .ant-tooltip-inner {
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
  }

  .addCommodity__title {
    font-size: 12px;
    margin-left: 10px;
  }

  span.commoditiesGoodsValueSpan {
    &::after {
      content: '$';
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #73819B;
      font-size: 12px;
    }

    &.notEmpty::after {
      color: #202C3C;
    }

    input {
      padding-left: 21px;
    }
  }

  .FreightQuoteFormRequest {
    &__Row {
      &__Commodity {
        margin-top: 10px;

        ${responsive.forFullDesktop`
            margin-top: 0px;
        `}
        ${responsive.forFullDesktopMax`
          .ant-collapse-content-box {
            padding: 0;
          }

          .ant-collapse-content-active {
            border-top: none;
          }

          .FreightQuoteFormRequest__Row__col_both_col__title_owned {
            .TypeSwitchController__text-left {
              margin-right: 6px;
            }
            .isMedium:nth-child(1) {
              margin-left: 0 !important;
            }
          }

          .isMedium {
            margin-left: auto !important;

            .TypeSwitchController {
              margin-left: auto;
              display: flex;
              justify-content: end;
              align-items: center;

              .Switch {
                margin-right: 0;
              }
            }
          }
        `}

        &__content__col_add {
          background-color: unset;
        }

        &__parent_content {
          &_item {
            &__medium {
              background-color: #f4f5f8;
              padding: 15px;
              border-radius: 2px;

              .Row {
                background-color: #f4f5f8;
              }

              .Col {
                background-color: #f4f5f8;
              }

              > .Col {
                margin-left: auto !important;
              }

              .ant-col:not(:first-child) {
                margin-left: auto;
              }

              .FreightQuoteFormRequest__Row__Commodity__content__col_add {
                margin-left: auto !important;
                display: flex;
                align-items: center;
                justify-content: end;

                &_btn {
                  position: relative;
                  width: 24px;
                  height: 24px;
                  min-height: 24px;
                  background-color: white;
                  border-radius: 0;
                }
              }
            }
          }
        }

        .FreightQuoteFormRequest__Row__Commodity__content__col_add_btn__medium {
          position: relative;
          height: 100%;
          min-height: unset;
          display: flex;
          justify-content: start;
          background: none;
          width: 125px;
          padding: 2px 5px;
          padding-left: 9px;
          border-radius: 3px;

          .addCommodity__title {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 400;
            margin-left: 10px;
            color: #202c3c;
            letter-spacing: normal;
          }

          svg {
            width: 11px;
            height: 11px;
            color: #314258;
          }
        }

        &__content {
          &__row {
            &_second {
              margin-top: 12px;

              .ant-col-8 {
                max-width: 32%;
              }
            }
          }

          .ant-tooltip {
            max-width: 260px;
          }
        }
      }

      &__col {
        &__commodityPanel {
          .ant-collapse {
            background-color: white;
            border-left: none;
            border-right: none;
            border-top: none;

            .ant-collapse-header {
              padding: 16px 0px 16px 0;
            }
          }
        }
      }
    }
  }

  .FreightQuoteFormRequest__Row__col_both__title {
    .ant-typography {
      color: ${({ theme }) => theme.themesColors.neutralBranding10};
      ${({ theme }) => theme?.fonts?.BodyTextMedium};
    }
  }

  .FreightQuoteFormRequest__Row__Commodity__content .FreightQuoteFormRequest__Row__Commodity__content_title .ant-typography {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }
`;

export const CommodityHazmats = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'CommodityHazmats',
}))`
  ${divFlex}
  align-items: center;

  margin-top: 8px;
`;

export const CommodityHazmatsIcon = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'CommodityHazmatsIcon',
}))`
  ${divFlex}

  margin-right: 4px;
`;

export const CommodityHazmatsText = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'CommodityHazmatsText',
}))`
  ${divFlex}

  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;
