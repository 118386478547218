import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

import { GapHorizontalContainerStyled, FlexCenter } from 'app-wrapper/view/guideline';

export const DropdownIcon = styled(FlexCenter)`
  ${divFlex}
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;
`;

export const Actions = styled(GapHorizontalContainerStyled)`
  margin-left: 16px;
  border-left: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;
