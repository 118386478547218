import { action, computed } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

interface OverviewShipmentState {
  shipments: ShipmentPreviewDTM[]
  isLoading: boolean
}

export const initialState: OverviewShipmentState = {
  shipments: [],
  isLoading: true,
};

export class OverviewShipmentStore extends BaseMobxStore<OverviewShipmentState> {
  initialState = { ...initialState }

  @action
  setShipments(shipments: ShipmentPreviewDTM[]) {
    this.initialState.shipments = shipments;
  }

  @action
  setLoading(loading: boolean) {
    this.initialState.isLoading = loading;
  }

  @computed
  get shipmentsWithKey() {
    return this.initialState.shipments.map((item) => (ShipmentPreviewDTM.fromPlain({
      ...item,
      key: item.id,
    })));
  }
}

export const createOverviewShipmentStore = (_initialState?: OverviewShipmentState) => new OverviewShipmentStore(_initialState || initialState);
