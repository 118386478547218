import notification from 'antd/es/notification';
import moment from 'moment';
import uniq from 'lodash/fp/uniq';
import uniqBy from 'lodash/fp/uniqBy';

import i18n from 'app-wrapper/i18n/i18n';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { R as userManagementR } from 'user-management/repository';
import { BaseController, controller } from 'proto/BaseController';
import {
  CREATE_AP_INVOICE, EShippingPartyTypes, PAYABLES, RECEIVABLES,
} from 'shipment-operations/constants';

import { IShipmentBillingInvoiceDtm, ShippingPartyDTM, ShortChargeDTM } from 'shipment-operations/models/dtm';

import { R } from 'shipment-operations/repository';

@controller
export class CreateInvoiceChargesController extends BaseController {
  public loadCharges = async (shipmentId: string) => {
    let response: ShortChargeDTM[] | null;
    const invoiceType = R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal(this.store.getState());

    const type = invoiceType === CREATE_AP_INVOICE ? PAYABLES : RECEIVABLES;

    this.dispatch(R.actions.createInvoiceCharges.setIsLoading(true));
    try {
      response = await R.services.createInvoiceCharges.getCharges(shipmentId, type);
    } catch (e) {
      this.dispatch(R.actions.createInvoiceCharges.setError(true));
      return;
    }
    this.dispatch(R.actions.createInvoiceCharges.setCharges(response));
  }

  public loadShipmentParties = async (shipmentId: string) => {
    let shippingPartyList: ShippingPartyDTM[] | null;

    this.dispatch(R.actions.createInvoiceCharges.setIsLoadingCompanies(true));

    try {
      shippingPartyList = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.createInvoiceCharges.setLoadCompaniesError(true));
      return;
    }

    const companies = await R.services.contacts.getCompanyList();

    const parsedCompanies = companies.map((item) => ShippingPartyDTM.fromPlain({
      id: item.id,
      role: EShippingPartyTypes.OCEAN_CARRIER,
      references: [],
      addressList: [],
      contactList: [],
      company: {
        name: item.name,
        id: item.id,
      },
    }));

    const commonCompanies = [...shippingPartyList, ...parsedCompanies];

    const uniqCompanies = uniqBy((item) => item.id, commonCompanies);
    if (!uniqCompanies) {
      return;
    }

    const owner = R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal(this.store.getState());
    const charges = R.selectors.createInvoiceCharges.getPreparedCharges(this.store.getState());

    let matchedCompanies;
    if (owner === CREATE_AP_INVOICE) {
      const creditors = charges.filter((item) => item.creditor?.id).map((item) => item.creditor?.id);
      const uniqId = uniq(creditors);
      const uniqCompaniesNames = uniqBy((item) => item.company?.id, uniqCompanies);
      matchedCompanies = uniqCompaniesNames.filter((item) => uniqId.includes(item.company?.id));
    } else {
      const debtors = charges.filter((item) => item.debtor?.id).map((item) => item.debtor?.id);
      const uniqId = uniq(debtors);
      const uniqCompaniesNames = uniqBy((item) => item.company?.id, uniqCompanies);
      matchedCompanies = uniqCompaniesNames.filter((item) => uniqId.includes(item.company?.id));
    }

    if (matchedCompanies && matchedCompanies.length) {
      const [matchedCompany] = matchedCompanies;

      if (matchedCompany.role === EShippingPartyTypes.CUSTOMER && matchedCompany.company && matchedCompany.company.organizationId) {
        const customer = await userManagementR.services.organization.getOrganizationById(matchedCompany.company.organizationId);

        if (customer && customer.paymentMethod && customer.paymentMethod.creditTerm) {
          this.dispatch(R.actions.createInvoiceCharges.setDueDate(moment(new Date()).add(customer.paymentMethod.creditTerm, 'days')));
        }
      }

      this.onSetSelectedCompany(matchedCompanies[0].company?.id);
    }

    this.dispatch(R.actions.createInvoiceCharges.onLoadCompaniesSuccess(matchedCompanies));
  }

  public loadDataForCreateInvoice = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    await this.loadCharges(shipmentId);
    await this.loadShipmentParties(shipmentId);
  }

  setSelectedCharge = (id: number) => this.dispatch(R.actions.createInvoiceCharges.setSelectedCharge(id));

  setGroupSelectedCharges = (elems: number[]) => this.dispatch(R.actions.createInvoiceCharges.setSubTableCharges(elems));

  onSetSelectedCompany = (id?: number) => this.dispatch(R.actions.createInvoiceCharges.setSelectedCompanyId(id));

  onSetDueDate = (date: string) => this.dispatch(R.actions.createInvoiceCharges.setDueDate(date));

  onSetBilledDate = (date: string) => this.dispatch(R.actions.createInvoiceCharges.setBilledDate(date));

  onClearSuccess = () => this.dispatch(R.actions.createInvoiceCharges.setCreateInvoiceSuccess({ status: false, invoiceType: '' }));

  onClearSelectedCompanyError = () => this.dispatch(R.actions.createInvoiceCharges.setSelectedCompanyError(false));

  onCreateInvoice = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    const selectedCompanyId = R.selectors.createInvoiceCharges.getSelectedCompanyId(this.store.getState());
    if (!selectedCompanyId) {
      this.dispatch(R.actions.createInvoiceCharges.setSelectedCompanyError(true));
      return;
    }

    this.dispatch(R.actions.createInvoiceCharges.setCreateInvoiceLoading(true));

    const selectedCharges = R.selectors.createInvoiceCharges.getSelectedCharges(this.store.getState());
    const companies = R.selectors.createInvoiceCharges.getCompanies(this.store.getState());
    const dueDate = R.selectors.createInvoiceCharges.getCreateInvoiceDueDate(this.store.getState());

    const preparedCharges = selectedCharges.map((item) => ({
      charge: {
        id: item,
      },
    }));
    const fullCompany = companies.find((item) => item.company?.id === selectedCompanyId);
    const invoiceType = R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal(this.store.getState());

    let body = {
      type: 'INVOICE',
      dueDate,
      description: '',
      items: [...preparedCharges],
      [invoiceType === CREATE_AP_INVOICE ? 'billFrom' : 'billTo']: {
        id: fullCompany?.company?.id,
      },
    };

    if (invoiceType === CREATE_AP_INVOICE) {
      const reference = R.selectors.createInvoiceCharges.getReference(this.store.getState());
      const billedDate = R.selectors.createInvoiceCharges.getBilledDate(this.store.getState());
      body = {
        ...body,
        reference,
        billDate: billedDate,
      };
    }

    let invoice: IShipmentBillingInvoiceDtm | null;

    const type = invoiceType === CREATE_AP_INVOICE ? PAYABLES : RECEIVABLES;

    try {
      invoice = await R.services.createInvoiceCharges.createInvoice(shipmentId, body, type);
    } catch (e) {
      this.dispatch(R.actions.createInvoiceCharges.onCreateInvoiceFailed());
      throw e;
    }

    this.dispatch(R.actions.createInvoiceCharges.onCreateInvoiceSuccess({ invoice, type }));
    notification.success({
      message: `${i18n.t('createInvoiceSuccessStart')} ${invoice?.number} ${i18n.t('createInvoiceSuccess')}`,
      placement: 'topRight',
      duration: 5,
    });
  }

  closeCreateInvoiceModal = () => this.dispatch(R.actions.createInvoiceCharges.onCloseCreateInvoiceModal());

  toggleCreateInvoiceModal = (state: string) => this.dispatch(R.actions.createInvoiceCharges.setIsOpenCreateInvoiceModal(state));

  setReference = (reference: string) => this.dispatch(R.actions.createInvoiceCharges.setReference(reference));

  resetStore = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.createInvoiceCharges.clear());
  };
}
