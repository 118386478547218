import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import {
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';

import { DrayageTransportationTabs } from 'shipment-operations/view/components';

export const TransportationImportInlandDelivery = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Import Inland Delivery')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>
      <DrayageTransportationTabs />
      <Outlet />
    </Wrapper>
  );
};
