import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { OrganizationPaymentMethod } from 'shipment-operations/constants';

import { CustomerAddInternalComponent } from './CustomerAddInternal.component';

const CustomerAddInternalContainer: FC = () => {
  const customerOrganization = useSelector(R.selectors.userOrganizationData.getCustomersAddInternal);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalErrors);
  const salesManagers = useSelector(R.selectors.userOrganizationData.getSalesManagers);
  const tempSalesManager = useSelector(R.selectors.userOrganizationData.getTempSalesManager);
  const tempSalesManagerError = useSelector(R.selectors.userOrganizationData.getTempSalesManagerError);

  const getOrganizationsAddStatus = useSelector(R.selectors.userOrganizationData.getOrganizationsAddStatus);

  return (
    <CustomerAddInternalComponent
      organization={customerOrganization}
      isOrganizationsAddStatus={getOrganizationsAddStatus === REQUEST_STATUS.pending}
      organizationFieldsErrors={organizationFieldsErrors}
      isDeferredPayment={customerOrganization.paymentMethod?.getTypeReq() === OrganizationPaymentMethod.DEFERRED_PAYMENT}
      closeCAIDrawer={UC.organizationProfile.closeCustomerAddInternalDrawer}
      saveCAI={UC.organizationProfile.saveCustomerAddInternal}
      onChangeUpdateEmail={UC.organizationProfile.onChangeCustomerAddInternalPrimaryEmail}
      onChangeUpdatePhone={UC.organizationProfile.onChangeCustomerAddInternalPhone}
      onChangeUpdatePhone2={UC.organizationProfile.onChangeCustomerAddInternalPhone2}
      salesManagers={salesManagers}
      tempSalesManager={tempSalesManager}
      tempSalesManagerError={tempSalesManagerError}
      setTempSalesManager={UC.organizationProfile.setTempSalesManager}
      onChangeUpdateEmailInformation={UC.organizationProfile.onChangeCustomerAddInternalEmail}
      onChangeUpdateWebUrl={UC.organizationProfile.onChangeCustomerAddInternalWebUrl}
      onChangeUpdatePrimaryName={UC.organizationProfile.onChangeCustomerAddInternalPrimaryName}
      onChangeUpdatePrimaryLastName={UC.organizationProfile.onChangeCustomerAddInternalPrimaryLastName}
      onChangeUpdatePrimaryPhone={UC.organizationProfile.onChangeCustomerAddInternalPrimaryPhone}
      onChangeUpdatePaymentType={UC.organizationProfile.onChangeCustomerAddInternalPaymentType}
      onChangeUpdatePaymentCreditTerm={UC.organizationProfile.onChangeCustomerAddInternalPaymentCreditTerm}
      onChangeUpdatePaymentCreditLimit={UC.organizationProfile.onChangeCustomerAddInternalPaymentCreditLimit}
    />
  );
};

export const CustomerAddInternalPage = memo(CustomerAddInternalContainer);
