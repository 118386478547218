import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const OrganizationAddressWrapper = styled.div.attrs({
  'data-class': 'OrganizationAddressWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const OrganizationAddressIconBG = styled.div.attrs({
  'data-class': 'OrganizationAddressIconBG',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background-color: transparent;
  border-radius: 4px;
  color: ${themesColors.primaryBranding5};
  border: 1px solid ${themesColors.neutralBranding4};
  font-size: 14px;
`;

export const OrganizationAddressRow = styled.div.attrs({
  'data-class': 'OrganizationAddressRow',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 12px;
`;

export const OrganizationAddressItem = styled.div.attrs({
  'data-class': 'UserOrganizationIconBG',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;

export const OrganizationAddressMembershipItem = styled(OrganizationAddressItem)`
  width: 30%;
`;

export const OrganizationAddressHeader = styled.div.attrs({
  'data-class': 'OrganizationAddressHeader',
})`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const OrganizationAddressData = styled.div.attrs({
  'data-class': 'OrganizationAddressData',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  
  margin-left: 16px;
`;

export const OrganizationAddressTitle = styled.div.attrs({
  'data-class': 'OrganizationAddressTitle',
})`
  font-size: 12px;
  color: ${themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const OrganizationAddressValue = styled.div.attrs({
  'data-class': 'OrganizationAddressValue',
})`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`;
