import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BankOutlined from '@ant-design/icons/BankOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';

import { normalizationCost, validateDecimalFraction } from 'app-wrapper/utils';

import { OrganizationPaymentMethodDTM } from 'user-management/models/dtm';

import { ChartBarTopBorderSvg } from 'app-wrapper/view/icons';

import {
  Wrap,
  Title,
  Content,
  Item,
  ItemIcon,
  ItemContent,
  ItemTitle,
  ItemSubtitle,
  EditButton,
  EditIcon,
} from './TradeReferences.styled';

const DEFAULT_VALUE = '-';

interface TradeReferencesComponentProps {
  tradeReference?: OrganizationPaymentMethodDTM;
  isPrepayment?: boolean;
  openEditDrawer: () => void;
}

export const TradeReferencesComponent: FC<TradeReferencesComponentProps> = ({
  tradeReference, openEditDrawer, isPrepayment,
}) => {
  const { t } = useTranslation();

  const creditLimit = normalizationCost(validateDecimalFraction(`${tradeReference?.creditLimit || ''}`, 22, 2, { withoutZero: true }),
    {
      toFixed: 2,
      thousandthSeparatorComma: true,
    });

  return (
    <Wrap>
      <Title>
        {t('Credit Information')}
      </Title>

      <EditButton
        onClick={openEditDrawer}
        type="default"
        icon={<EditIcon />}
      >
        {t('Edit')}
      </EditButton>

      <Content>
        <Item>
          <ItemIcon>
            <BankOutlined />
          </ItemIcon>

          <ItemContent>
            <ItemTitle>
              {t('Payment Type')}
            </ItemTitle>

            <ItemSubtitle isCapitalize>
              {tradeReference?.getType() || DEFAULT_VALUE}
            </ItemSubtitle>
          </ItemContent>
        </Item>

        {!isPrepayment && (
          <Item>
            <ItemIcon>
              <ClockCircleOutlined />
            </ItemIcon>

            <ItemContent>
              <ItemTitle>
                {t('Term')}
              </ItemTitle>

              <ItemSubtitle>
                {tradeReference?.creditTerm ? `${tradeReference.creditTerm} ${t('Day(s)')}` : DEFAULT_VALUE}
              </ItemSubtitle>
            </ItemContent>
          </Item>
        )}

        {!isPrepayment && (
          <Item>
            <ItemIcon>
              <ChartBarTopBorderSvg />
            </ItemIcon>

            <ItemContent>
              <ItemTitle>
                {t('Credit Limit')}
              </ItemTitle>

              <ItemSubtitle>
                $ {creditLimit || DEFAULT_VALUE}
              </ItemSubtitle>
            </ItemContent>
          </Item>
        )}

      </Content>
    </Wrap>
  );
};
