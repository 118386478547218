import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AddContainerComponent } from './AddContainer.component';

const AddContainerContainer: FC = React.memo(() => {
  const hasTemperatureControl = useSelector(R.selectors.bookingWizard.getHasTemperatureControl);

  return (
    <AddContainerComponent
      isLoading={false}
      isTemperatureControlled={hasTemperatureControl}
      onAddContainer={UC.bookingWizard.addContainer}
    />
  );
});

export {
  AddContainerContainer as AddContainer,
};
