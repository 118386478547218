import { BaseValidation, BaseValidationError } from 'proto/BaseValidation/BaseValidation';

class RequiredValidationError extends BaseValidationError {
  message = ''
}

export class RequiredValidation<T extends string | undefined> extends BaseValidation<T> {
  validate(value: T) {
    if (value) {
      return this.error();
    }
    return null;
  }

  error() {
    return new RequiredValidationError();
  }
}

export const requiredValidation = new RequiredValidation();
