import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'antd/es/spin';

import { Textarea } from 'app-wrapper/view/components';
import {
  Modal,
  Title,
  TitleIcon,
  TitleContentTitle,
  TitleContent,
  TitleContentSubTitle,
  Label,
  SpinnerWrap,
} from './AdditionalInformationModal.styled';

interface RejectModalComponentProps {
  isPending: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  reviewNote: string;
  setReviewNote: (note: string) => void;
}

export const AdditionalInformationModalComponent: FC<RejectModalComponentProps> = ({
  isOpen,
  isPending,
  onOk,
  onClose,
  reviewNote,
  setReviewNote,
}) => {
  const { t } = useTranslation();

  const handleReviewNote = useCallback((e) => {
    setReviewNote(e.target.value);
  }, [setReviewNote]);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      onOk={onOk}
      okText={t('Submit')}
      okButtonProps={{
        disabled: !reviewNote.length,
      }}
    >
      {isPending ? (
        <SpinnerWrap>
          <Spinner size="large" />
        </SpinnerWrap>
      ) : null}

      <Title>
        <TitleIcon />

        <TitleContent>
          <TitleContentTitle>
            {t('Request Additional Information')}
          </TitleContentTitle>

          <TitleContentSubTitle>
            {t('Please specify what information is needed from the client. We will send this comment by Email. Subsequent communication will take place via Email.')}
          </TitleContentSubTitle>
        </TitleContent>
      </Title>

      <Label>
        {t('Description of required information')}
      </Label>

      <Textarea
        placeholder={t('Type your comment here...')}
        value={reviewNote}
        onChange={handleReviewNote}
      />
    </Modal>
  );
};
