import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
  ButtonWrap,
} from './OrganizationCreated.styled';
import illustrationSrc from './assets/Illustration.png';

interface IOrganizationCreatedProps {
  goToOrganization: () => void;
}

export const OrganizationCreated: FC<IOrganizationCreatedProps> = ({ goToOrganization }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Content>
        <Title>
          {t('You have successfully created your organization')}
        </Title>

        <Text>
          {t('Your organization’s details will be reviewed within 2 days. We will contact you via email, and if the review is successful, you will be officially registered on the platform.')}
        </Text>

        <ButtonWrap>
          <Button onClick={goToOrganization}>
            {t('View Organization')}
          </Button>
        </ButtonWrap>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
