import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'antd/es/tag';

import { PackageTypeNames, ReversePackageTypesCodes } from 'app-wrapper/types/PackageType';
import { PackageType as PackageTypeIcon } from 'app-wrapper/view/icons/PackageType';
import { CargoWeight as CargoWeightIcon } from 'app-wrapper/view/icons/CargoWeight';
import { PackagesNumber as PackagesNumberIcon } from 'app-wrapper/view/icons/PackagesNumber';
import { FlexRow } from 'app-wrapper/view/guideline';
import { CargoDTM } from 'shipment-operations/models/dtm';

import {
  CargoCardBottomContainer,
  HazmatTagContainer,
  PackageTypeText,
} from './CargoCardBottom.styled';

interface ICargoCardBottomProps {
  packageType?: CargoDTM['packageType']
  packagesNumber?: CargoDTM['packagesNumber']
  weight?: CargoDTM['weight']
  isHazmat?: boolean
}

export const CargoCardBottom: FC<ICargoCardBottomProps> = ({
  packageType,
  packagesNumber,
  weight,
  isHazmat,
}) => {
  const { t } = useTranslation();
  const reversePackageType = packageType ? ReversePackageTypesCodes[packageType as unknown as keyof typeof ReversePackageTypesCodes] : '';

  return (
    <CargoCardBottomContainer>
      {reversePackageType && (
        <FlexRow>
          <PackageTypeIcon />
          <PackageTypeText>
            {t(PackageTypeNames[reversePackageType])}
          </PackageTypeText>
        </FlexRow>
      )}
      <span><PackagesNumberIcon />{packagesNumber || 0} {t('pcs')}</span>
      <span><CargoWeightIcon />{weight || 0} {t('kg')}</span>
      {isHazmat && <HazmatTagContainer><Tag color="gold">{t('Hazmat')}</Tag></HazmatTagContainer>}
    </CargoCardBottomContainer>
  );
};
