import React, {
  memo, useCallback,
} from 'react';
import { ArrowRightSvg } from 'app-wrapper/view/icons';
import { CollapseButtonContent, CollapseButtonStyled } from './CollapseButton.styled';

interface ICollapseButtonProps {
  onClick: (show: boolean) => void
  show?: boolean
}

export const CollapseButton = memo(({ onClick, show }: ICollapseButtonProps) => {
  const handleOnCLick = useCallback(() => {
    onClick(Boolean(!show));
  }, [show]);

  return (
    <CollapseButtonStyled $show={Boolean(show)} onClick={handleOnCLick}>
      <CollapseButtonContent>
        <ArrowRightSvg />
      </CollapseButtonContent>
    </CollapseButtonStyled>
  );
});
