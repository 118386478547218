import React from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';
import { ChangeRequestDrawerController } from 'shipment-operations/controllers/ChangeRequestDrawerController';
import {
  FooterButtonsComponent,
} from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/FooterButtons/FooterButtons.component';

export const FooterButtonsContainer = observer(() => {
  const controller = useCurrentController<ChangeRequestDrawerController>();
  const store = useCurrentControllerStore<ChangeRequestStore>();

  return (
    <FooterButtonsComponent
      isSubmitting={store.state.isSubmitting}
      onSave={controller.submitForm}
      onDelete={controller.deleteChangeRequest}
      onEdit={controller.editForm}
      onCompleteTask={controller.completeChangeRequest}
      onCancelEditMode={controller.cancelEditMode}
      editMode={store.state.editMode}
      isNewRequest={!store.state.changeRequestId}
      permissions={store.state.permissions}
    />
  );
});
