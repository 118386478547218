import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CIFinancialAndLegalWrapper = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalWrapper',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CIFinancialAndLegalIconBG = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalIconBG',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;

  background-color: transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  font-size: 14px;

  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const CIFinancialAndLegalRow = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalRow',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  margin-top: 12px;
`;

export const CIFinancialAndLegalItem = styled.div.attrs({
  'data-class': 'UserOrganizationIconBG',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 50%;
`;

export const CIFinancialAndLegalMembershipItem = styled(CIFinancialAndLegalItem)`
  width: 30%;
`;

export const CIFinancialAndLegalHeader = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalHeader',
})`
  ${divFlex}

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const CIFinancialAndLegalData = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalData',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  margin-left: 16px;
`;

export const CIFinancialAndLegalTitle = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalTitle',
})`
  ${divFlex}

  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const CIFinancialAndLegalValue = styled.div.attrs({
  'data-class': 'CIFinancialAndLegalValue',
})`
  ${divFlex}

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
