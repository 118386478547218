import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from '@aws-amplify/core';

import 'reflect-metadata';

import './i18n/i18n';
import './view/yup/yup';
import './index.css';
import './index.less';

import { store } from 'app-wrapper/store';
// import * as serviceWorker from 'app-wrapper/serviceWorker';
import amplifyConfig from 'app-wrapper/amplifyConfig';

import { PostHogProvider } from 'posthog-js/react';
import posthog, { PostHog } from 'posthog-js';
import { App } from './view/pages';
import getTheme from './view/themes';

interface AppProps {
  POSTHOG_SETTINGS: {
    apiKey?: string
    apiHost?: string
  }
}

Amplify.configure(amplifyConfig);

export const init = ({ POSTHOG_SETTINGS }: AppProps) => {
  const posthogClient = posthog.init(
    POSTHOG_SETTINGS.apiKey as string,
    {
      api_host: POSTHOG_SETTINGS.apiHost,
    },
  );

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={getTheme()}>
          <PostHogProvider client={posthogClient as PostHog}>
            <Router>
              <App />
            </Router>
          </PostHogProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
  // serviceWorker.unregister();
  // if (process.env.REACT_APP_MOCK) {
  //   import('./mock');
  // }
};
