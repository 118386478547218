import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { RisksAndPaymentsComponent } from './RisksAndPayments.component';

interface IRisksAndPaymentsContainerProps {
  forceCollapse?: boolean
}

const RisksAndPaymentsContainer: FC<IRisksAndPaymentsContainerProps> = (props) => {
  const {
    forceCollapse,
  } = props;
  const {
    incoterms, incotermsTrade,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const Origin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const Destination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentDestination);
  const paymentTermsSelected = useSelector(R.selectors.freightQuote.getPaymentTermsSelected);

  return (
    <RisksAndPaymentsComponent
      isPortOrigin={Origin?.isPort}
      isPortDestination={Destination?.isPort}
      forceCollapse={forceCollapse}
      isAllDisabled={getCurrent.isAllFieldDisabled}
      incoterms={incoterms || ''}
      incotermsTrade={incotermsTrade || ''}
      isActiveTextOption
      isActiveFirstStep={paymentTermsSelected?.hasOriginCollect}
      isActiveSecondStep={paymentTermsSelected?.hasFreightCollect}
      isActiveThirdsStep={paymentTermsSelected?.hasDestinationCollect}
      onChangeFreightIncoterms={UC.FreightQuote.onChangeFreightIncoterms}

    />
  );
};

const RisksAndPaymentsContainerCache = memo(RisksAndPaymentsContainer);

export { RisksAndPaymentsContainerCache as RisksAndPayments };
