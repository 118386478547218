import i18n from 'app-wrapper/i18n/i18n';
import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';

import {
  ChargeCodeLoadTypeEnum,
  ContainerReeferTypesArray,
  EShippingPartyTypes,
  IncotermsType,
  PaymentsType,
  ShippingInstructionsRoutes,
  ShippingInstructionsStatusEnum,
  TradeType,
  TRouteLegPhase,
} from 'shipment-operations/constants';
import {
  ChargeDTM,
  ContainerCargoShortItemDTM,
  IContainerCargoShortItemDTM,
  PaymentTermsChargesAmountDTM,
  PaymentTermsDTM,
  ShippingPartyOverviewDTM,
} from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.billOfLadingCommon;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getBookingInformation = createSelector(
  localState,
  (state) => ({
    mblNumber: state.mblNumber,
    contractNumber: state.contractNumber,
  }),
);

const getLowestSequenceLeg = createSelector(
  localState,
  (state) => state.lowestSequenceLeg,
);

const getLowestSequenceSeaLeg = createSelector(
  localState,
  (state) => state.lowestSequenceSeaLeg,
);

const getHighestSequenceSeaLeg = createSelector(
  localState,
  (state) => state.highestSequenceSeaLeg,
);

const getHighestSequenceLeg = createSelector(
  localState,
  (state) => state.highestSequenceLeg,
);

const getLowestSequenceTransportation = createSelector(
  localState,
  (state) => state.lowestSequenceTransportation,
);

const getLowestSequenceTransportationDocCuttoff = createSelector(
  getLowestSequenceTransportation,
  (transportation) => {
    if (!transportation) {
      return undefined;
    }

    const { schedule } = transportation;

    if (schedule) {
      const { documentCutOff } = schedule;

      return documentCutOff;
    }

    return undefined;
  },
);

const getShippingParties = createSelector(
  localState,
  (state) => ({
    notifyParty: state.notifyParty,
    consignee: state.consignee,
    shipper: state.shipper,
    houseShipper: state.houseShipper,
    houseConsignee: state.houseConsignee,
    houseNotifyParty: state.houseNotifyParty,
    forwardingAgent: state.forwardingAgent,
    ultimateCustomer: state.ultimateCustomer,
    customer: state.customer,
  }),
);

const getShippingPartiesAsMap = createSelector(
  localState,
  (state) => {
    const {
      notifyParty,
      consignee,
      shipper,
      houseShipper,
      houseConsignee,
      houseNotifyParty,
      forwardingAgent,
      deliveryAgent,
      customer,
      ultimateCustomer,
    } = state;

    // TODO: Refactor to DTM
    return {
      [EShippingPartyTypes.NOTIFY_PARTY]: notifyParty,
      [EShippingPartyTypes.CONSIGNEE]: consignee,
      [EShippingPartyTypes.SHIPPER]: shipper,
      [EShippingPartyTypes.HOUSE_SHIPPER]: houseShipper,
      [EShippingPartyTypes.HOUSE_CONSIGNEE]: houseConsignee,
      [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: houseNotifyParty,
      [EShippingPartyTypes.FORWARDER_AGENT]: forwardingAgent,
      [EShippingPartyTypes.CUSTOMER]: customer,
      [EShippingPartyTypes.ULTIMATE_CUSTOMER]: ultimateCustomer,
      [EShippingPartyTypes.DELIVERY_AGENT]: deliveryAgent,
    } as Record<EShippingPartyTypes, ShippingPartyOverviewDTM>;
  },
);

const getExportCustoms = createSelector(
  localState,
  (state) => state.exportCustoms,
);

const getAdditionalInformationReference = createSelector(
  localState,
  (state) => state.additionalInformationReference,
);

const getTemperatureControl = createSelector(
  localState,
  (state) => state.temperatureControl,
);

const getCargos = createSelector(
  localState,
  (state) => state.cargos,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getBillOfLading = createSelector(
  localState,
  (state) => state.billOfLading,
);

const getSubmitSI = createSelector(
  localState,
  (state) => state.submitSI,
);

const getPaymentsFormState = createSelector(
  localState,
  (state) => state.paymentsFormState,
);

export const getActiveRoute = createSelector(
  localState,
  (state) => state.activeRoute,
);

const getIsHBLPage = createSelector(
  getActiveRoute,
  (activeRoute) => activeRoute === ShippingInstructionsRoutes.HBL_DRAFT,
);

const getPortOfDischarge = createSelector(
  localState,
  (state) => state.highestSequenceSeaLeg && state.highestSequenceSeaLeg.arrivalLocation,
);

export const getClausesFormState = createSelector(
  localState,
  (state) => state.clausesFormState,
);

export const getClausesFormErrors = createSelector(
  localState,
  (state) => state.clausesFormErrors,
);

export const getCommentsFormState = createSelector(
  localState,
  (state) => state.commentsFormState,
);

export const getCommentsFormErrors = createSelector(
  localState,
  (state) => state.commentsFormErrors,
);

export const getIsStatePending = createSelector(
  localState,
  (state) => state.isStateUpdatePending,
);

export const getOceanCarrier = createSelector(
  localState,
  (state) => state.oceanCarrier,
);

export const getSIDetails = createSelector(
  localState,
  (state) => state.details,
);

export const getAllCargoItems = createSelector(
  localState,
  (state) => {
    const reduced = state.containers.reduce((prev, next) => [...prev, ...next.cargoItems], [] as IContainerCargoShortItemDTM[]);
    return reduced.map((item) => ContainerCargoShortItemDTM.fromPlain(item));
  },
);

export const getDraftMBL = createSelector(
  localState,
  (state) => state.draftMBL,
);

export const getFinalMBL = createSelector(
  localState,
  (state) => state.finalMBL,
);

export const getMBLFileFormState = createSelector(
  localState,
  (state) => state.mblFileFormState,
);

export const getMBLFileFormError = createSelector(
  localState,
  (state) => state.mblFileFormStateError,
);

export const getIsMBLFileFormPending = createSelector(
  localState,
  (state) => state.mblFileFormPending,
);

const calculateAmountsForPaymentTerms = (charges: ChargeDTM[], originCharges?: ChargeDTM[], freightCharges?: ChargeDTM[], destinationCharges?: ChargeDTM[]) => {
  const calculatedAmounts = charges.filter(({ applied }) => applied).reduce((prevItem, curItem) => {
    const chargeType = curItem.designation as TRouteLegPhase;

    return {
      origin: chargeType === TRouteLegPhase.ORIGIN ? prevItem.origin + (curItem.buyTotalCost || 0) : prevItem.origin,
      freight: chargeType === TRouteLegPhase.FREIGHT ? prevItem.freight + (curItem.buyTotalCost || 0) : prevItem.freight,
      destination: chargeType === TRouteLegPhase.DESTINATION ? prevItem.destination + (curItem.buyTotalCost || 0) : prevItem.destination,
    };
  }, {
    origin: 0,
    freight: 0,
    destination: 0,
  });

  const calculatedOriginAmount = originCharges ? originCharges.filter(({ applied }) => applied).reduce((prevItem, curItem) => {
    const chargeType = curItem.designation as TRouteLegPhase;

    return chargeType === TRouteLegPhase.ORIGIN ? prevItem + (curItem.buyTotalCost || 0) : prevItem;
  }, 0) : 0;
  const calculatedFreightAmount = freightCharges ? freightCharges.filter(({ applied }) => applied).reduce((prevItem, curItem) => {
    const chargeType = curItem.designation as TRouteLegPhase;

    return chargeType === TRouteLegPhase.FREIGHT ? prevItem + (curItem.buyTotalCost || 0) : prevItem;
  }, 0) : 0;
  const calculatedDestinationAmount = destinationCharges ? destinationCharges.filter(({ applied }) => applied).reduce((prevItem, curItem) => {
    const chargeType = curItem.designation as TRouteLegPhase;

    return chargeType === TRouteLegPhase.DESTINATION ? prevItem + (curItem.buyTotalCost || 0) : prevItem;
  }, 0) : 0;

  return PaymentTermsChargesAmountDTM.fromPlain({
    origin: originCharges ? calculatedOriginAmount : calculatedAmounts.origin,
    freight: freightCharges ? calculatedFreightAmount : calculatedAmounts.freight,
    destination: destinationCharges ? calculatedDestinationAmount : calculatedAmounts.destination,
  });
};

export const getCalculatedAmountsForMBLPaymentTerms = createSelector(
  localState,
  ({
    chargesMBL,
    houseShipperCharges,
    houseConsigneeCharges,
    deliveryAgentCharges,
    paymentTerms,
  }) => {
    let originCharges;
    let freightCharges;
    let destinationCharges;

    if (paymentTerms && paymentTerms.incoterm) {
      const { incoterm, tradeType } = paymentTerms;

      if (incoterm === IncotermsType.CIF || incoterm === IncotermsType.CFR) {
        destinationCharges = houseConsigneeCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
      }

      if (tradeType === TradeType.IMPORT && (incoterm === IncotermsType.FAS || incoterm === IncotermsType.FCA || incoterm === IncotermsType.FOB)) {
        originCharges = houseShipperCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
        freightCharges = deliveryAgentCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
        destinationCharges = deliveryAgentCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
      }

      if (tradeType === TradeType.IMPORT && incoterm === IncotermsType.EXW) {
        originCharges = deliveryAgentCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
        freightCharges = deliveryAgentCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
        destinationCharges = deliveryAgentCharges.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
      }
    }

    return calculateAmountsForPaymentTerms(
      chargesMBL.filter(({ chargeCode }) => chargeCode?.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE),
      originCharges,
      freightCharges,
      destinationCharges,
    );
  },
);

export const getCalculatedAmountsForHBLPaymentTerms = createSelector(
  localState,
  ({ charges }) => calculateAmountsForPaymentTerms(charges),
);

export const getPaymentTerms = createSelector(
  localState,
  (state) => state.paymentTerms,
);

export const getMergedPaymentTermsWithFormState = createSelector(
  localState,
  (state) => {
    const { paymentTerms, paymentsFormState } = state;

    return PaymentTermsDTM.fromPlain({
      ...paymentTerms as PaymentTermsDTM,
      origin: paymentsFormState.origin as PaymentsType,
      freight: paymentsFormState.freight as PaymentsType,
      destination: paymentsFormState.destination as PaymentsType,
      incoterm: paymentsFormState.incoterm as IncotermsType,
    });
  },
);

export const getHBLCharges = createSelector(
  localState,
  (state) => state.chargesHBL,
);

export const getIsFreightedView = createSelector(
  localState,
  (state) => state.isFreightedView,
);

const getCarrierBookingOffice = createSelector(
  localState,
  (state) => (state.oceanCarrier ? state.oceanCarrier.companyName : ''),
);

const getBookingAgentOffice = createSelector(
  localState,
  (state) => (state.bookingAgent ? state.bookingAgent.companyName : ''),
);

const getContractOwner = createSelector(
  localState,
  (state) => state.contractOwner,
);

const formatShippingPartyToInformation = (shippingParty: ShippingPartyOverviewDTM): string => `${shippingParty.companyName}
  ${shippingParty.addressLine}
  ${shippingParty.phone}${shippingParty.additionalPhone ? `
    ${shippingParty.additionalPhone}` : ''}
  ${shippingParty.email}
  ${i18n.t('Contact')}: ${shippingParty.contactPerson}${shippingParty.references.length > 0 ? `
  ${i18n.t('Reference')}: ${shippingParty.references[0].value}` : ''}
  ${shippingParty.taxId ? `${i18n.t('Tax ID')}: ${shippingParty.taxId}` : ''}
`;

const formatOnBehalfShippingPartyToInformation = (shippingParty: ShippingPartyOverviewDTM, customer: ShippingPartyOverviewDTM, ultimateCustomer: ShippingPartyOverviewDTM): string => `${customer.companyName} on behalf of
  ${ultimateCustomer.companyName}
  ${shippingParty.addressLine}
  ${shippingParty.phone}${shippingParty.additionalPhone ? `
    ${shippingParty.additionalPhone}` : ''}
  ${shippingParty.email}
  ${i18n.t('Contact')}: ${shippingParty.contactPerson}${shippingParty.references.length > 0 ? `
  ${i18n.t('Reference')}: ${shippingParty.references[0].value}` : ''}
  ${shippingParty.taxId ? `${i18n.t('Tax ID')}: ${shippingParty.taxId}` : ''}
`;

const getShipperInformation = createSelector(
  getPaymentTerms,
  getShippingParties,
  getIsHBLPage,
  (paymentTerms, shippingParties, isHBLPage) => {
    const {
      customer,
      shipper,
      ultimateCustomer,
      houseShipper,
    } = shippingParties;

    if (!paymentTerms) {
      return null;
    }

    if (!isHBLPage) {
      return formatShippingPartyToInformation(shipper);
    }

    if (customer && customer.isFreightForwarderOrgType && ultimateCustomer && paymentTerms.tradeType === TradeType.EXPORT) {
      return formatOnBehalfShippingPartyToInformation(houseShipper, customer, ultimateCustomer);
    }

    return formatShippingPartyToInformation(houseShipper);
  },
);

const getConsigneeInformation = createSelector(
  getPaymentTerms,
  getShippingParties,
  getIsHBLPage,
  (paymentTerms, shippingParties, isHBLPage) => {
    const {
      customer,
      consignee,
      houseConsignee,
      ultimateCustomer,
    } = shippingParties;

    if (!paymentTerms) {
      return null;
    }

    if (!isHBLPage) {
      return formatShippingPartyToInformation(consignee);
    }

    if (customer && customer.isFreightForwarderOrgType && ultimateCustomer && paymentTerms.tradeType === TradeType.IMPORT) {
      return formatOnBehalfShippingPartyToInformation(houseConsignee, customer, ultimateCustomer);
    }

    return formatShippingPartyToInformation(houseConsignee);
  },
);

export const getCarrierSCAC = createSelector(
  localState,
  (state) => state.carrierSCAC,
);

export const getAccountHolderDocOpsExportDepartment = createSelector(
  localState,
  (state) => state.accountHolderDocOpsExportDepartment,
);

export const getCurrentOrgRelatedAdminPublicInfo = createSelector(
  localState,
  (state) => state.currentOrgRelatedAdminPublicInfo,
);

export const getCarrierCompanyInfo = createSelector(
  localState,
  getIsHBLPage,
  getOceanCarrier,
  getCarrierSCAC,
  getAccountHolderDocOpsExportDepartment,
  getCurrentOrgRelatedAdminPublicInfo,
  (
    state,
    isHBLPage,
    oceanCarrier,
    carrierSCAC,
    accountHolderDocExportDepartment,
    currentOrgRelatedAdmin,
  ) => {
    const {
      carrierName,
      contactName,
      contactPhones,
      contactEmails,
    } = state;
    const { address } = oceanCarrier;

    if (isHBLPage) {
      return {
        carrierName,
        contactName,
        carrierSCAC,
        carrierScac: carrierSCAC,
        companyAddress1: currentOrgRelatedAdmin ? `${currentOrgRelatedAdmin?.address?.address1}${currentOrgRelatedAdmin?.address?.address2 ? ` , ${currentOrgRelatedAdmin?.address.address2}` : ''}` : '',
        companyAddress2: `${currentOrgRelatedAdmin?.address?.city}, ${currentOrgRelatedAdmin?.address?.state || ''} ${currentOrgRelatedAdmin?.address?.postalCode}, ${currentOrgRelatedAdmin?.address?.country || ''}`,
        emails: accountHolderDocExportDepartment ? [accountHolderDocExportDepartment.email] : [],
        phones: accountHolderDocExportDepartment ? [accountHolderDocExportDepartment.phone] : [],
      };
    }

    return {
      carrierName,
      contactName,
      carrierSCAC,
      carrierScac: carrierSCAC,
      companyAddress1: address ? `${address.address1}${address.address2 ? ` , ${address.address2}` : ''}` : '',
      companyAddress2: address ? `${address?.city}, ${address?.state || ''} ${address?.postalCode}, ${address?.country || ''}` : '',
      emails: contactEmails,
      phones: contactPhones,
    };
  },
);

const getIsDraftHBLApproved = createSelector(
  localState,
  (state) => state.isDraftHBLApproved,
);

const getIsDraftMBLApproved = createSelector(
  localState,
  (state) => state.isDraftMBLApproved,
);

const getIsFirstTimeSubmittingSI = createSelector(
  getSIDetails,
  ({ status }) => status === ShippingInstructionsStatusEnum.SI_PREPARATION,
);

const getIsShippingPartyDataFilled = createSelector(
  localState,
  (state) => {
    const { shipper, consignee, notifyParty } = state;

    return !!shipper.companyName && !!consignee.companyName && !!notifyParty.companyName;
  },
);

const getIsCargosDataFilled = createSelector(
  localState,
  (state) => !state.cargos.some((cargo) => {
    const packagesNumbers = cargo.packagesNumber ? Number(cargo.packagesNumber) : 0;
    const weight = cargo.weight ? Number(cargo.weight) : 0;

    return !cargo.code || !cargo.packageType || packagesNumbers < 1 || weight < 1;
  }),
);

const getIsHazmatDataFilled = createSelector(
  localState,
  (state) => !state.cargos.some((cargo) => {
    const {
      unNumber,
      imoClass,
      shippingName,
      packingGroup,
      contactName,
      contactNumber,
      isHazmat,
    } = cargo;

    return isHazmat && (!unNumber || !imoClass || !shippingName || !packingGroup || !contactName || !contactNumber);
  }),
);

const getIsCargoLoadPlanFilled = createSelector(
  localState,
  getAllCargoItems,
  (state, allCargoItems) => {
    const { cargos } = state;

    const cargoSum = cargos.reduce((prev, next) => ({
      volume: prev.volume + Number(next.volume || 0),
      weight: prev.weight + Number(next.weight || 0),
      packagesNumber: prev.packagesNumber + Number(next.packagesNumber || 0),
    }), {
      volume: 0,
      weight: 0,
      packagesNumber: 0,
    });

    const cargoItemSum = allCargoItems.reduce((prev, next) => ({
      volume: +prev.volume + +next.volume,
      weight: +prev.weight + +next.weight,
      packagesNumber: +prev.packagesNumber + +next.packagesNumber,
    }), {
      volume: 0,
      weight: 0,
      packagesNumber: 0,
    });

    return cargoSum.weight === cargoItemSum.weight && cargoSum.volume === cargoItemSum.volume && cargoSum.packagesNumber === cargoItemSum.packagesNumber;
  },
);

const getIsContainersDataFilled = createSelector(
  localState,
  (state) => !state.containers.some(({ cargoItems, number, sealNumber }) => !cargoItems.length || !sealNumber || !number),
);

const getIsBLReleaseTypeDataFilled = createSelector(
  localState,
  (state) => !!state.billOfLading.type,
);

const getIsEntryNumberDataFilled = createSelector(
  localState,
  (state) => !!state.exportCustoms?.number,
);

const getIsAbleToSubmitSI = createSelector(
  localState,
  getIsHBLPage,
  getAllCargoItems,
  getCargos,
  (state, isHBLPage, allCargoItems) => {
    const {
      shipper,
      consignee,
      notifyParty,
      houseShipper,
      houseConsignee,
      houseNotifyParty,
      billOfLading,
      exportCustoms,
      cargos,
      containers,
      temperatureControl,
      highestSequenceSeaLeg,
      paymentTerms,
      isOriginUS,
      isUserFromDestinationPartner,
    } = state;

    let isAbleToSubmit = true;

    // shipping parties are filled

    if (isUserFromDestinationPartner) {
      return false;
    }

    if (isHBLPage) {
      if (!houseShipper.companyName || !houseConsignee.companyName || !houseNotifyParty.companyName) {
        isAbleToSubmit = false;
      }
    } else if (!shipper.companyName || !consignee.companyName || !notifyParty.companyName) {
      isAbleToSubmit = false;
    }

    // bill of lading type is filled

    if (!billOfLading.type) {
      isAbleToSubmit = false;
    }

    // export entry number is filled

    if (isOriginUS && !exportCustoms?.number) {
      isAbleToSubmit = false;
    }

    // cargos are filled

    const cargosAreInvalid = cargos.some((cargo) => {
      const packagesNumbers = cargo.packagesNumber ? Number(cargo.packagesNumber) : 0;
      const weight = cargo.weight ? Number(cargo.weight) : 0;

      return !cargo.code || !cargo.packageType || packagesNumbers < 1 || weight < 1;
    });

    if (cargosAreInvalid) {
      isAbleToSubmit = false;
    }

    // hazmats are filled

    const hazmatsAreInvalid = cargos.some((cargo) => {
      const {
        unNumber,
        imoClass,
        shippingName,
        packingGroup,
        contactName,
        contactNumber,
        isHazmat,
      } = cargo;

      return isHazmat && (!unNumber || !imoClass || !shippingName || !packingGroup || !contactName || !contactNumber);
    });

    if (hazmatsAreInvalid) {
      isAbleToSubmit = false;
    }

    // containers are filled

    const containersAreInvalid = containers.some(({ cargoItems, number, sealNumber }) => !cargoItems.length || !sealNumber || !number);

    if (containersAreInvalid) {
      isAbleToSubmit = false;
    }

    // temperature control is filled for reefer containers

    const hasReefer = containers.some(({ type }) => ContainerReeferTypesArray.find((item) => item === type));

    if (hasReefer && !temperatureControl.temperature) {
      isAbleToSubmit = false;
    }

    // in case port of discharge is China - checking for shipper's and consignee's TAX id

    const portOfDischarge = highestSequenceSeaLeg?.arrivalLocation;
    const isPortOfDischargeChina = portOfDischarge && portOfDischarge.country && portOfDischarge.country.name === 'China';

    if (isPortOfDischargeChina && (!shipper.taxId || !consignee.taxId)) {
      isAbleToSubmit = false;
    }

    // payment terms check

    if (!paymentTerms || !paymentTerms.origin || !paymentTerms.freight || !paymentTerms.destination) {
      isAbleToSubmit = false;
    }

    const cargoSum = cargos.reduce((prev, next) => ({
      volume: prev.volume + Number(next.volume || 0),
      weight: prev.weight + Number(next.weight || 0),
      packagesNumber: prev.packagesNumber + Number(next.packagesNumber || 0),
    }), {
      volume: 0,
      weight: 0,
      packagesNumber: 0,
    });

    const cargoItemSum = allCargoItems.reduce((prev, next) => ({
      volume: +prev.volume + +next.volume,
      weight: +prev.weight + +next.weight,
      packagesNumber: +prev.packagesNumber + +next.packagesNumber,
    }), {
      volume: 0,
      weight: 0,
      packagesNumber: 0,
    });

    if (cargoSum.weight !== cargoItemSum.weight) {
      isAbleToSubmit = false;
    }
    if (cargoSum.volume !== cargoItemSum.volume) {
      isAbleToSubmit = false;
    }
    if (cargoSum.packagesNumber !== cargoItemSum.packagesNumber) {
      isAbleToSubmit = false;
    }

    return isAbleToSubmit;
  },
);

const getIsEditShippingPartyDrawerVisible = createSelector(
  localState,
  (state) => state.isEditShippingPartyDrawerVisible,
);

const getIsEditShippingPartyDrawerLoading = createSelector(
  localState,
  (state) => state.isEditShippingPartyDrawerLoading,
);

const getIsOriginUS = createSelector(
  localState,
  (state) => state.isOriginUS,
);

const getHasAdditionalServicesWithITNActivity = createSelector(
  localState,
  (state) => state.hasAdditionalServicesWithITNActivity,
);

const getHouseConsigneeCharges = createSelector(
  localState,
  (state) => state.houseConsigneeCharges,
);

const getHouseShipperCharges = createSelector(
  localState,
  (state) => state.houseShipperCharges,
);

const getSIMissMatches = createSelector(
  localState,
  (state) => state.siMissMatches,
);

const getIsThereAreShippingPartiesMissMatches = createSelector(
  getSIMissMatches,
  (missMatches) => !!missMatches.find(({ relatesToType }) => relatesToType === 'SHIPMENT_PARTY'),
);

const getIsThereAreCargosMissMatches = createSelector(
  getSIMissMatches,
  (missMatches) => !!missMatches.find(({ relatesToType }) => relatesToType === 'CARGO'),
);

const getIsThereAreContainersMissMatches = createSelector(
  getSIMissMatches,
  (missMatches) => !!missMatches.find(({ relatesToType }) => relatesToType === 'CONTAINER'),
);

const getIsThereAreBLReleaseMissMatches = createSelector(
  getSIMissMatches,
  (missMatches) => !!missMatches.find(({ relatesToType }) => relatesToType === 'BILL_OF_LADING_OPTIONS'),
);

const getIsThereAreEntryNumberMissMatches = createSelector(
  getSIMissMatches,
  (missMatches) => !!missMatches.find(({ relatesToType }) => relatesToType === 'ENTRY_NUMBER'),
);

const getIsFlowStepLoading = createSelector(
  localState,
  (state) => state.isFlowStepLoading,
);

const getCargoSupplier = createSelector(
  localState,
  (state) => state.cargoSupplier,
);

const getCargoReceiver = createSelector(
  localState,
  (state) => state.cargoReceiver,
);

export const billOfLadingCommonSelectors = {
  getSubmitSI,
  getIsAbleToSubmitSI,
  getCarrierBookingOffice,
  getBookingAgentOffice,
  getAllCargoItems,
  getIsLoading,
  getBookingInformation,
  getLowestSequenceLeg,
  getHighestSequenceLeg,
  getHouseConsigneeCharges,
  getHouseShipperCharges,
  getLowestSequenceTransportation,
  getLowestSequenceSeaLeg,
  getHighestSequenceSeaLeg,
  getShippingParties,
  getShippingPartiesAsMap,
  getExportCustoms,
  getAdditionalInformationReference,
  getTemperatureControl,
  getCargos,
  getContainers,
  getBillOfLading,
  getPaymentTerms,
  getPaymentsFormState,
  getClausesFormState,
  getClausesFormErrors,
  getCommentsFormState,
  getCommentsFormErrors,
  getPortOfDischarge,
  getIsStatePending,
  getCarrierCompanyInfo,
  getSIDetails,
  getDraftMBL,
  getFinalMBL,
  getMBLFileFormState,
  getMBLFileFormError,
  getIsMBLFileFormPending,
  getCalculatedAmountsForMBLPaymentTerms,
  getCalculatedAmountsForHBLPaymentTerms,
  getMergedPaymentTermsWithFormState,
  getHBLCharges,
  getIsFreightedView,
  getActiveRoute,
  getContractOwner,
  getShipperInformation,
  getConsigneeInformation,
  getIsHBLPage,
  getIsDraftHBLApproved,
  getIsDraftMBLApproved,
  getIsFirstTimeSubmittingSI,
  getIsEditShippingPartyDrawerVisible,
  getIsEditShippingPartyDrawerLoading,
  getAccountHolderDocOpsExportDepartment,
  getCurrentOrgRelatedAdminPublicInfo,
  getIsOriginUS,
  getHasAdditionalServicesWithITNActivity,
  getSIMissMatches,
  getIsThereAreShippingPartiesMissMatches,
  getIsThereAreCargosMissMatches,
  getIsThereAreContainersMissMatches,
  getIsThereAreBLReleaseMissMatches,
  getIsThereAreEntryNumberMissMatches,
  getIsShippingPartyDataFilled,
  getIsCargosDataFilled,
  getIsContainersDataFilled,
  getIsBLReleaseTypeDataFilled,
  getIsEntryNumberDataFilled,
  getIsCargoLoadPlanFilled,
  getIsHazmatDataFilled,
  getIsFlowStepLoading,
  getLowestSequenceTransportationDocCuttoff,
  getCargoSupplier,
  getCargoReceiver,
};
