import styled from 'styled-components';

import { BodyTextMedium } from 'app-wrapper/view/fonts';
import { SpinnerWrap as BaseSpinnerWrap } from 'app-wrapper/view/guideline';

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const Fields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const SpinnerWrap = styled(BaseSpinnerWrap)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  z-index: 10;
`;

export const FieldWrap = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.span`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding3};
  padding: 9px 16px;
`;

export const RolesTitle = styled.span`
  border-top: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
  padding: 10px 0;
  ${BodyTextMedium}
`;
