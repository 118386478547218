import styled from 'styled-components';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Content = styled(FlexRow)`
  padding: 8px 24px;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding2};
  border-radius: 4px;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
  cursor: pointer;
`;
