import React, {
  FC,
  memo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Option,
  Select,
  Tooltip,
} from 'app-wrapper/view/components';
import {
  OrganizationDTM,
  OrganizationFieldsErrorsDTM,
  OrganizationFieldsErrorsFieldDTM,
  OrganizationMemberDTM,
} from 'user-management/models/dtm';
import { DeferredPaymentValues } from 'shipment-operations/constants';
import { Field, Label } from 'user-management/view/pages/Account/components/AccountDrawer/components/CustomerAddInternal/components/CustomerAddInternalTeam/CustomerAddInternalTeam.styled';
import { CAISelect, CAIInput } from 'user-management/view/pages/Account/components/AccountDrawer/components/CustomerAddInternal/components/CustomerAddInternalContactInformation/CustomerAddInternalContactInformation.styled';

import {
  CustomerAddInternalGeneralInformation,
  CustomerAddInternalFinancial,
  CustomerAddInternalContactInformation,
} from './components';
import {
  CAIButtonCancel, CAIButtonSave, CAIContact, CAIContactInformation, CAIContent, CAICreditInformation, CAIDivider,
  CAIFooter, CAIHeader, CAISpaceColumn, CAISpaceInput, CAISpaceRow, CAISubTitle, CAITitle, CAIWrap, StyledInputNumber,
} from './CustomerAddInternal.styled';

interface ICAIComponentProps {
  organization?: OrganizationDTM
  isOrganizationsAddStatus?: boolean
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  isDeferredPayment?: boolean
  closeCAIDrawer: () => void
  saveCAI: () => void
  salesManagers: OrganizationMemberDTM[]
  tempSalesManager?: string;
  tempSalesManagerError: boolean;
  setTempSalesManager: (email: string) => void;
  onChangeUpdateEmail: (email: string) => void
  onChangeUpdatePhone: (phone: string) => void
  onChangeUpdatePhone2: (phone2: string) => void
  onChangeUpdateEmailInformation: (emailInformation: string) => void
  onChangeUpdateWebUrl: (webUrl: string) => void
  onChangeUpdatePrimaryName: (name: string) => void
  onChangeUpdatePrimaryLastName: (name: string) => void
  onChangeUpdatePrimaryPhone: (email: string) => void
  onChangeUpdatePaymentType: (value: string) => void
  onChangeUpdatePaymentCreditTerm: (value: number) => void
  onChangeUpdatePaymentCreditLimit: (value: string) => void
}

const CustomerAddInternalComponent: FC<ICAIComponentProps> = (props) => {
  const {
    organization,
    isOrganizationsAddStatus,
    organizationFieldsErrors,
    isDeferredPayment,
    closeCAIDrawer,
    saveCAI,
    onChangeUpdateEmail,
    onChangeUpdatePhone,
    onChangeUpdatePhone2,
    onChangeUpdateEmailInformation,
    onChangeUpdateWebUrl,
    onChangeUpdatePrimaryName,
    onChangeUpdatePrimaryLastName,
    onChangeUpdatePrimaryPhone,
    onChangeUpdatePaymentType,
    onChangeUpdatePaymentCreditTerm,
    onChangeUpdatePaymentCreditLimit,
    salesManagers,
    tempSalesManager,
    tempSalesManagerError,
    setTempSalesManager,
  } = props;
  const { t } = useTranslation();

  const onCancelClick = useCallback(() => {
    closeCAIDrawer();
  }, []);

  const onSaveClick = useCallback(() => {
    saveCAI();
  }, []);

  const onChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateEmail(event.target.value);
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.message),
    [],
  );

  const onChangePhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePhone(event.target.value);
  }, []);

  const onChangePhone2 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePhone2(event.target.value);
  }, []);

  const onChangeEmailInformation = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateEmailInformation(event.target.value);
  }, []);

  const onChangeWebUrl = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateWebUrl(event.target.value);
  }, []);

  const onChangePrimaryName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryName(event.target.value);
  }, []);

  const onChangePrimaryLastName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryLastName(event.target.value);
  }, []);

  const onChangePrimaryPhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryPhone(event.target.value);
  }, []);

  const onChangePaymentType = useCallback((value: string) => {
    onChangeUpdatePaymentType(value);
  }, [onChangeUpdatePaymentType]);

  const onChangePaymentTerm = useCallback((value: string) => {
    onChangeUpdatePaymentCreditTerm(Number(value));
  }, [onChangeUpdatePaymentCreditTerm]);

  const onChangePaymentCreditLimit = useCallback((value) => {
    onChangeUpdatePaymentCreditLimit(value);
  }, [onChangeUpdatePaymentCreditLimit]);

  const formatterInput = useCallback((value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), []);
  const parserInput = useCallback((value) => value!.replace(/\$\s?|(,*)/g, ''), []);

  return (
    <CAIWrap id="CAIWrap">
      <CAIContent>
        <CustomerAddInternalGeneralInformation />

        <CAIDivider />

        <CustomerAddInternalContactInformation />

        <CAIDivider />

        <CAIContactInformation>
          <CAIHeader>
            {t('ContactInformation')}
          </CAIHeader>
          <CAISpaceRow>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('Phone')} *`}
              </CAITitle>
              <Tooltip
                visible={!!organizationFieldsErrors?.phone?.message
                  && organizationFieldsErrors?.phone?.message !== t('basicErrors.REQUIRED_MESSAGE')}
                title={`${organizationFieldsErrors?.phone?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.phone?.message || ''}`}
                placement="bottom"
                mode="danger"
              >
                <CAIInput
                  data-class="CAIInputPhone"
                  value={organization?.phone}
                  hasError={getHasErrorField(organizationFieldsErrors?.phone)}
                  onChange={onChangePhone}
                />
              </Tooltip>
            </CAISpaceColumn>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('Additional Phone')}`}
              </CAITitle>
              <Tooltip
                visible={!!organizationFieldsErrors?.phone2?.message
                  && organizationFieldsErrors?.phone2?.message !== t('basicErrors.REQUIRED_MESSAGE')}
                title={`${organizationFieldsErrors?.phone2?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.phone2?.message || ''}`}
                placement="bottom"
                mode="danger"
              >
                <CAIInput
                  data-class="CAIInputPhone2"
                  value={organization?.phone2}
                  hasError={getHasErrorField(organizationFieldsErrors?.phone2)}
                  onChange={onChangePhone2}
                />
              </Tooltip>
            </CAISpaceColumn>
          </CAISpaceRow>
          <CAISpaceInput />
          <CAISpaceRow>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('Email')}`}
              </CAITitle>
              <Tooltip
                visible={!!organizationFieldsErrors?.emailInformation?.message
                  && organizationFieldsErrors?.emailInformation?.message !== t('basicErrors.REQUIRED_MESSAGE')}
                title={`${organizationFieldsErrors?.emailInformation?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.emailInformation?.message || ''}`}
                placement="bottom"
                mode="danger"
              >
                <CAIInput
                  data-class="CAIInputEmailInformation"
                  value={organization?.emailInformation}
                  hasError={getHasErrorField(organizationFieldsErrors?.emailInformation)}
                  onChange={onChangeEmailInformation}
                />
              </Tooltip>
            </CAISpaceColumn>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('CorporateWebsiteURL')}`}
              </CAITitle>
              <Tooltip
                visible={!!organizationFieldsErrors?.webUrl?.message
                  && organizationFieldsErrors?.webUrl?.message !== t('basicErrors.REQUIRED_MESSAGE')}
                title={`${organizationFieldsErrors?.webUrl?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.webUrl?.message || ''}`}
                placement="bottom"
                mode="danger"
              >
                <CAIInput
                  data-class="CAIInputWebUrl"
                  value={organization?.webUrl?.getValidValue()}
                  hasError={getHasErrorField(organizationFieldsErrors?.webUrl)}
                  onChange={onChangeWebUrl}
                />
              </Tooltip>
            </CAISpaceColumn>
          </CAISpaceRow>
        </CAIContactInformation>

        <CAIDivider />

        <CAIContact>
          <CAIHeader>
            {t('AdminUserInformation')}
          </CAIHeader>
          <CAISpaceRow>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('First Name')} *`}
              </CAITitle>
              <CAIInput
                data-class="CAIInputFirstName"
                value={organization?.primaryContact?.firstName}
                hasError={getHasErrorField(organizationFieldsErrors?.primaryName)}
                onChange={onChangePrimaryName}
              />
            </CAISpaceColumn>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('Last Name')} *`}
              </CAITitle>
              <CAIInput
                data-class="CAIInputLastName"
                value={organization?.primaryContact?.lastName}
                hasError={getHasErrorField(organizationFieldsErrors?.primaryLastName)}
                onChange={onChangePrimaryLastName}
              />
            </CAISpaceColumn>
          </CAISpaceRow>

          <CAISpaceInput />

          <CAISpaceRow>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('Email')} *`}
              </CAITitle>
              <Tooltip
                visible={!!organizationFieldsErrors?.primaryEmail?.message
                  && organizationFieldsErrors?.primaryEmail?.message !== t('basicErrors.REQUIRED_MESSAGE')}
                title={`${organizationFieldsErrors?.primaryEmail?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryEmail?.message || ''}`}
                placement="bottom"
                mode="danger"
              >
                <CAIInput
                  data-class="CAIInputPrimaryContactEmail"
                  value={organization?.primaryContact?.email}
                  hasError={getHasErrorField(organizationFieldsErrors?.primaryEmail)}
                  onChange={onChangeEmail}
                />
              </Tooltip>
            </CAISpaceColumn>
            <CAISpaceColumn widthProp="50%">
              <CAITitle>
                {`${t('Phone')} *`}
              </CAITitle>
              <Tooltip
                visible={!!organizationFieldsErrors?.primaryPhone?.message
                  && organizationFieldsErrors?.primaryPhone?.message !== t('basicErrors.REQUIRED_MESSAGE')}
                title={`${organizationFieldsErrors?.primaryPhone?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryPhone?.message || ''}`}
                placement="bottom"
                mode="danger"
              >
                <CAIInput
                  data-class="CAIInputPrimaryContactPhone"
                  value={organization?.primaryContact?.phone}
                  hasError={getHasErrorField(organizationFieldsErrors?.primaryPhone)}
                  onChange={onChangePrimaryPhone}
                />
              </Tooltip>
            </CAISpaceColumn>
          </CAISpaceRow>
        </CAIContact>

        <CAIDivider />

        <CAICreditInformation>
          <CAIHeader>
            {t('Credit Information')}
          </CAIHeader>
          <CAISpaceRow>
            <CAISpaceColumn widthProp="100%">
              <CAITitle>
                {`${t('Payment Type')} *`}
              </CAITitle>
              <CAISelect
                data-class="CAIInputPaymentMethodType"
                value={organization?.paymentMethod?.type}
                hasError={getHasErrorField(organizationFieldsErrors?.paymentType)}
                allowClear={!!organization?.address.country}
                onChange={onChangePaymentType}
                optionFilterProp="children"
              >
                <Option key={`${0}_CAISelect_PaymentType.map`} value={`${t('Deferred Payment')}`}>
                  {t('Deferred Payment')}
                </Option>
                <Option key={`${1}_CAISelect_PaymentType.map`} value={`${t('Prepayment')}`}>
                  {t('Prepayment')}
                </Option>
              </CAISelect>
            </CAISpaceColumn>
          </CAISpaceRow>

          {isDeferredPayment && (
            <CAISpaceInput />
          )}

          {isDeferredPayment && (
            <CAISpaceRow>
              <CAISpaceColumn widthProp="50%">
                <CAITitle>
                  {`${t('Term ')}`}<CAISubTitle>{`${t('Day(s)')} *`}</CAISubTitle>
                </CAITitle>
                <CAISelect
                  data-class="CAIInputPaymentMethodCreditTerm"
                  value={organization?.paymentMethod?.creditTerm || ''}
                  hasError={getHasErrorField(organizationFieldsErrors?.paymentCreditTerm)}
                  onChange={onChangePaymentTerm}
                  optionFilterProp="children"
                >
                  {DeferredPaymentValues.map((item) => (
                    <Option key={`${item}_CAISelect_PaymentTerm.map`} value={item}>
                      {item}
                    </Option>
                  ))}
                </CAISelect>
              </CAISpaceColumn>
              <CAISpaceColumn widthProp="50%">
                <CAITitle>
                  {`${t('Credit Limit ')}`}<CAISubTitle>{`${t('$')} *`}</CAISubTitle>
                </CAITitle>
                <StyledInputNumber
                  data-class="CAIInputPaymentMethodCreditLimit"
                  value={organization?.paymentMethod?.creditLimit}
                  formatter={formatterInput}
                  parser={parserInput}
                  isError={getHasErrorField(organizationFieldsErrors?.paymentCreditLimit)}
                  onChange={onChangePaymentCreditLimit}
                />
              </CAISpaceColumn>
            </CAISpaceRow>
          )}
        </CAICreditInformation>

        <CAIDivider />

        <CustomerAddInternalFinancial />

        <CAIDivider />

        <CAIHeader>
          {t('Sales Manager')}
        </CAIHeader>

        <Field>
          <Label>{t('Sales representative *')}</Label>

          <Select
            data-class="sales-representatvie-select"
            cssWidth="324px"
            value={tempSalesManager}
            onChange={setTempSalesManager}
            error={tempSalesManagerError}
          >
            {salesManagers.map((manager) => (
              <Option
                data-class="sales-manager-select-option"
                key={manager.email}
                value={manager.email}
              >
                {manager.getFullName()}
              </Option>
            ))}
          </Select>
        </Field>
      </CAIContent>

      <CAIFooter>
        <CAIButtonCancel
          onClick={onCancelClick}
          disabled={isOrganizationsAddStatus}
        >
          {t('addChargeMenu.cancel')}
        </CAIButtonCancel>
        <CAIButtonSave
          onClick={onSaveClick}
          disabled={isOrganizationsAddStatus}
        >
          {t('addChargeMenu.add')}
        </CAIButtonSave>
      </CAIFooter>
    </CAIWrap>
  );
};

const CustomerAddInternalComponentCache = memo(CustomerAddInternalComponent);

export { CustomerAddInternalComponentCache as CustomerAddInternalComponent };
