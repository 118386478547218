import { ENotificationStatus } from 'app-wrapper/constants';
import { UC } from 'app-wrapper/controllers/index';
import { R } from 'app-wrapper/repository';
import { instanceToPlain } from 'app-wrapper/utils';
import { BaseController, controller } from 'proto/BaseController';
import { ETasksType } from 'shipment-operations/constants';

@controller
export class ISFDrawerController extends BaseController {
  public init = async (taskId: string) => {
    this.mobxStore.isfDrawer.setLoading(true);

    const commandCenterState = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const commandCenterTasks = [
      ...commandCenterState.content,
      ...(commandCenterState.contentLater || []),
      ...(commandCenterState.contentToday || []),
      ...(commandCenterState.contentThisWeek || []),
      ...(commandCenterState.contentOverdue || []),
    ];
    const overviewTasks = R.selectors.overview.getTasks(this.store.getState());
    const task = [...commandCenterTasks, ...overviewTasks].find(({ id }) => id === taskId);

    this.mobxStore.isfDrawer.setTask(task);
    this.mobxStore.isfDrawer.setLoading(false);
    this.dispatch(R.actions.railBillingDrawer.setIsLoading(false));
  };

  public acceptISFTask = async () => {
    const { task } = this.mobxStore.isfDrawer.state;

    if (!task || !task.metadata || !task.metadata.shipmentId) {
      return;
    }

    const { metadata, id: taskId } = task;
    const { shipmentId } = metadata;

    this.mobxStore.isfDrawer.setLoading(true);

    await R.services.CommandCenter.putTask({
      ...instanceToPlain(task),
      status: ENotificationStatus.DONE,
      dueDate: this.currentStore?.state.changeRequest?.dueDate,
      createdAt: this.currentStore?.state.changeRequest?.createdAt,
    });

    UC.CommandCenter.removeTaskFromTheStore(String(shipmentId), ETasksType.SEND_ISF_TASK, taskId);
    UC.overview.removeTaskFromDashboard(String(shipmentId), ETasksType.SEND_ISF_TASK, taskId);

    this.mobxStore.isfDrawer.setLoading(false);
    UC.drawer.closeDrawer();
  };
}
