import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import {
  IResponseScheduleDTM, ShipmentEventDTM, ShipmentTrackerContainerDTM,
  ShipmentTrackerDTM,
  TransportPlanDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import moment from 'moment/moment';
import { ETransportationType } from 'monetary/constants';
import { DateDtm } from 'app-wrapper/models/dtm';

export class LoadPlansUseCase extends BaseUseCase {
  async loadPlans(planIds: number[], overview?: boolean) {
    if (!planIds) {
      return;
    }

    let schedules;
    let results;
    let plans: TransportPlanDTM[];

    this.dispatch(R.actions.shipmentTrackerRoutes.setLoadingSchedules(true));

    try {
      results = await Promise.all<TransportPlanDTM[] | null>(
        planIds.map((planId) => R.services.shipmentPlans.getShipmentPlans(planId.toString())),
      );

      plans = results.reduce((acc: TransportPlanDTM[], result) => acc.concat(result || []), [] as TransportPlanDTM[]);
      const [response] = plans;
      schedules = response && !!response.transportations.length && response.transportations.map((item: IResponseScheduleDTM) => {
        const schedule = overview ? {
          ...item.schedule,
          cyAvailable: item.schedule.carrierCyAvailable,
          ctoAvailable: item.schedule.carrierCtoAvailable,
        } : {
          arrivalTime: item.schedule.arrivalTime,
          documentCutOff: item.schedule.carrierDocumentCutOff,
          cyAvailable: item.schedule.carrierCyAvailable,
          ctoAvailable: item.schedule.carrierCtoAvailable,
          terminalCutOff: item.schedule.carrierTerminalCutOff,
          vgmCutOff: item.schedule.carrierVgmCutOff,
        };

        return {
          documentCutOff: schedule.documentCutOff ? moment(schedule.documentCutOff.slice(0, 19)) : null,
          cyAvailable: schedule.cyAvailable ? moment(schedule.cyAvailable.slice(0, 19)) : null,
          ctoAvailable: schedule.ctoAvailable ? moment(schedule.ctoAvailable.slice(0, 19)) : null,
          terminalCutOff: schedule.terminalCutOff ? moment(schedule.terminalCutOff.slice(0, 19)) : null,
          vgmCutOff: schedule.vgmCutOff ? moment(schedule.vgmCutOff.slice(0, 19)) : null,
          arrivalTime: schedule.arrivalTime ? DateDtm.fromPlain({ date: schedule?.arrivalTime, offset: moment().utcOffset() }) : undefined,
        };
      });
    } catch {
      this.dispatch(R.actions.shipmentTrackerRoutes.setErrorSchedules());
      return;
    }
    this.dispatch(R.actions.shipmentTrackerRoutes.setSchedules(schedules));
    this.dispatch(R.actions.shipmentTrackerRoutes.setPlan(plans));

    const containers: ShipmentTrackerDTM[] = R.selectors.shipmentTracker.getContainers(this.store.getState());
    const updatedContainers = containers.map((shipmentTracker) => (ShipmentTrackerDTM.fromPlain({
      ...shipmentTracker,
      container: ShipmentTrackerContainerDTM.fromPlain({
        ...shipmentTracker.container,
        events: shipmentTracker.container.events.map((event) => {
          const plan = plans.find((_plan) => event.planId === _plan.id);
          const transportation = plan?.transportations.find(
            (_transportation) => (_transportation.transport.type === ETransportationType.SEA || _transportation.transport.type === ETransportationType.WATER) && (
              _transportation?.leg?.arrivalLocation.id === event?.location.id
              || _transportation?.leg?.departureLocation.id === event?.location.id),
          );

          const voyageCode = transportation?.voyageCode;

          if (voyageCode) {
            return ShipmentEventDTM.fromPlain({
              ...event,
              voyageCode,
            });
          }
          return event;
        }),
      }),
    })));

    this.dispatch(R.actions.shipmentTracker.setContainers(updatedContainers));
    this.dispatch(R.actions.shipmentTrackerRoutes.setContainers(updatedContainers));

    const terminalCutOff = schedules && schedules.find((item) => item.terminalCutOff)?.terminalCutOff;
    const vgmCutOff = schedules && schedules.find((item) => item.vgmCutOff)?.vgmCutOff;
    const documentCutOff = schedules && schedules.find((item) => item.documentCutOff)?.documentCutOff;
    this.dispatch(R.actions.shipmentTrackerRoutes.setEditableSchedules({
      terminalCutOff: terminalCutOff || '',
      vgmCutOff: vgmCutOff || '',
      documentCutOff: documentCutOff || '',
    }));
    this.dispatch(R.actions.shipmentTrackerRoutes.setOriginalSchedules({
      terminalCutOff: terminalCutOff || '',
      vgmCutOff: vgmCutOff || '',
      documentCutOff: documentCutOff || '',
    }));
  }
}
