import message from 'antd/es/message';
import i18n from 'i18next';

import { DateDtm } from 'app-wrapper/models/dtm';
import { getLocationToOneString } from 'app-wrapper/utils';

import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'monetary/repository';
import { ECarrierSCAC, ERateTypes } from 'monetary/constants';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';
import { ILocationsServiceDTM } from 'monetary/models/dtm/Quotas';
import { BaseController, controller } from 'proto/BaseController';

@controller
export class OceanRatesModalController extends BaseController {
  searchOriginLocationTimeoutId: number | undefined = undefined

  searchDestinationLocationTimeoutId: number | undefined = undefined

  downloadRates = async () => {
    this.validateEffectiveDate();
    this.validateExpirationDate();

    this.dispatch(R.actions.oceanRatesModal.setLoading(true));

    const effectiveDate = R.selectors.oceanRatesModal.getEffectiveDate(this.store.getState());
    const expirationDateDate = R.selectors.oceanRatesModal.getExpirationDate(this.store.getState());
    const originLocation = R.selectors.oceanRatesModal.getOriginLocation(this.store.getState());
    const destinationLocation = R.selectors.oceanRatesModal.getDestinationLocation(this.store.getState());
    const carrierScacList = R.selectors.oceanRatesModal.getCarrierScacList(this.store.getState());

    const isFormDataValid = R.selectors.oceanRatesModal.isFormDataValid(this.store.getState());

    if (
      !isFormDataValid || (
        !effectiveDate
        || !expirationDateDate
        || !carrierScacList
      )
    ) {
      return;
    }

    try {
      await R.services.rate.downloadOceanRate(effectiveDate, expirationDateDate, originLocation, destinationLocation, carrierScacList);
    } catch (e) {
      message.error(i18n.t('Something went wrong'));

      this.dispatch(R.actions.oceanRatesModal.setLoading(false));

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setOpen(false));
    this.dispatch(R.actions.oceanRatesModal.clear());

    message.success(i18n.t('File downloaded'));
  }

  downloadDryageRates = async () => {
    this.validateEffectiveDate();

    this.dispatch(R.actions.oceanRatesModal.setLoading(true));

    const effectiveDate = R.selectors.oceanRatesModal.getEffectiveDate(this.store.getState());
    const originLocation = R.selectors.oceanRatesModal.getOriginLocation(this.store.getState());

    const isFormDataValid = R.selectors.oceanRatesModal.getIsDrayageFormDataValid(this.store.getState());

    if (!isFormDataValid || !effectiveDate) {
      return;
    }

    const drayageRateType = R.selectors.oceanRatesModal.getDrayageDrawerRateType(this.store.getState());

    if (drayageRateType === ERateTypes.DRAYAGE_BASE_RATE) {
      await R.services.rate.downloadDrayageBaseRate(effectiveDate, originLocation);
    } else if (drayageRateType === ERateTypes.DRAYAGE_SURCHARGES) {
      await R.services.rate.downloadDrayageSurchargesRate(effectiveDate, originLocation);
    }

    appUC.drawer.closeDrawer();
    this.dispatch(R.actions.oceanRatesModal.clear());

    message.success(i18n.t('File downloaded'));
  }

  openModal = (rateType: ERateTypes) => {
    this.dispatch(R.actions.oceanRatesModal.setRateType(rateType));
    this.dispatch(R.actions.oceanRatesModal.setOpen(true));
  }

  closeModal = () => {
    this.dispatch(R.actions.oceanRatesModal.setOpen(false));
    this.dispatch(R.actions.oceanRatesModal.clear());
  }

  setEffectiveDate = (date: DateDtm | null) => {
    if (!date) {
      this.dispatch(R.actions.oceanRatesModal.clearEffectiveDate());

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setEffectiveDate(date));
  }

  blurEffectiveDateField = () => {
    this.validateEffectiveDate();
  }

  setExpirationDate = (date: DateDtm | null) => {
    if (!date) {
      this.dispatch(R.actions.oceanRatesModal.clearExpirationDate());

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setExpirationDate(date));
  }

  blurExpirationDateField = () => {
    this.validateExpirationDate();
  }

  setOriginLocation = (code: string) => {
    if (!code) {
      this.dispatch(R.actions.oceanRatesModal.clearOriginLocation());

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setOriginLocation(code));
  }

  blurOriginLocationField = () => {
    this.validateOriginLocation();
  }

  setDestinationLocation = (code: string) => {
    if (!code) {
      this.dispatch(R.actions.oceanRatesModal.clearDestinationLocation());

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setDestinationLocation(code));
  }

  blurDestinationLocationField = () => {
    this.validateDestinationLocation();
  }

  searchOriginLocation = async (searchValue: string) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    clearInterval(this.searchOriginLocationTimeoutId);

    await new Promise((resolve) => {
      this.searchOriginLocationTimeoutId = window.setTimeout(resolve, 400);
    });

    if (!searchValue) {
      this.dispatch(R.actions.oceanRatesModal.setOriginLocationSearchList([]));

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setOriginLocationSearchListLoading(true));

    try {
      response = await R.services.RFQServiceById.getLocationService({
        query: searchValue,
        size: 10,
      });

      if (!response) {
        return;
      }

      result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
        description: getLocationToOneString({
          country: itemLocation?.country?.name,
          state: itemLocation?.locationName,
          code: itemLocation?.code,
        }),
        code: itemLocation.code,
        timezoneId: itemLocation.timeZoneId,
      }));
    } catch (e) {
      console.error('getRFQPortAutocompleteOrigin: error', e);

      this.dispatch(R.actions.oceanRatesModal.setOriginLocationSearchListLoading(false));

      return;
    }

    if (!result) return;

    this.dispatch(R.actions.oceanRatesModal.setOriginLocationSearchList(result));
    this.dispatch(R.actions.oceanRatesModal.setOriginLocationSearchListLoading(false));
  }

  searchDestinationLocation = async (searchValue: string) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    clearInterval(this.searchDestinationLocationTimeoutId);

    await new Promise((resolve) => {
      this.searchDestinationLocationTimeoutId = window.setTimeout(resolve, 400);
    });

    if (!searchValue) {
      this.dispatch(R.actions.oceanRatesModal.setDestinationLocationSearchList([]));

      return;
    }

    this.dispatch(R.actions.oceanRatesModal.setDestinationLocationSearchListLoading(true));

    try {
      response = await R.services.RFQServiceById.getLocationService({
        query: searchValue,
        size: 10,
      });

      if (!response) {
        return;
      }

      result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
        description: getLocationToOneString({
          country: itemLocation?.country?.name,
          state: itemLocation?.locationName,
          code: itemLocation?.code,
        }),
        code: itemLocation.code,
        timezoneId: itemLocation.timeZoneId,
      }));
    } catch (e) {
      console.error('getRFQPortAutocompleteOrigin: error', e);

      this.dispatch(R.actions.oceanRatesModal.setDestinationLocationSearchListLoading(false));

      return;
    }

    if (!result) return;

    this.dispatch(R.actions.oceanRatesModal.setDestinationLocationSearchList(result));
    this.dispatch(R.actions.oceanRatesModal.setDestinationLocationSearchListLoading(false));
  }

  setCarrierSCACList = (carrierScacList: ECarrierSCAC[]) => {
    this.dispatch(R.actions.oceanRatesModal.setCarrierScacList(carrierScacList));
  }

  private validateEffectiveDate = () => {
    const effectiveDate = R.selectors.oceanRatesModal.getEffectiveDate(this.store.getState());

    this.dispatch(R.actions.oceanRatesModal.clearEffectiveDateError());

    if (!effectiveDate) {
      this.dispatch(R.actions.oceanRatesModal.setEffectiveDateError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }

  private validateExpirationDate = () => {
    const expirationDate = R.selectors.oceanRatesModal.getExpirationDate(this.store.getState());

    this.dispatch(R.actions.oceanRatesModal.clearExpirationDateError());

    if (!expirationDate) {
      this.dispatch(R.actions.oceanRatesModal.setExpirationDateError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }

  private validateOriginLocation = () => {
    this.dispatch(R.actions.oceanRatesModal.clearOriginLocationError());
  }

  private validateDestinationLocation = () => {
    this.dispatch(R.actions.oceanRatesModal.clearDestinationLocationError());
  }
}
