import message from 'antd/es/message';

import { R as appR } from 'app-wrapper/repository';
import { OverviewDashboardUseCase } from 'app-wrapper/usecases/OverviewDashboard.useCase';
import { hasAccess } from 'app-wrapper/utils';
import i18n from 'i18next';
import { BaseController, controller } from 'proto/BaseController';
import { useSelector } from 'react-redux';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { EOrganizationMemberRole } from 'user-management/constants';
import { R } from 'user-management/repository';

@controller
export class UserInviteController extends BaseController {
  public sendInvite = async () => {
    const organization = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const email = R.selectors.userInvite.getEmail(this.store.getState());
    const role = R.selectors.userInvite.getRole(this.store.getState());
    const departments = R.selectors.userInvite.getDepartments(this.store.getState());
    const teams = R.selectors.userInvite.getTeams(this.store.getState());
    const jobTitle = R.selectors.userInvite.getJobTitle(this.store.getState());

    this.validateEmail();
    this.validateRole();
    this.validateDepartments();

    const departmentsError = R.selectors.userInvite.getDepartmentsError(this.store.getState());

    if (!email || !role || departmentsError) {
      return;
    }

    if (!organization) {
      console.error('No organization data');

      return;
    }

    this.dispatch(R.actions.userInvite.setLoading(true));

    try {
      await R.services.organization.inviteOrganizationMember(organization.id, email, role, departments, teams, jobTitle);
    } catch (e) {
      this.dispatch(R.actions.userInvite.setLoading(false));

      throw e;
    }

    this.dispatch(R.actions.userInvite.clear());
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.userInvite.setLoading(false));

    message.success(i18n.t('User has been invited successfully'));

    const organizationMembersList = await R.services.organization.getOrganizationMembersList(organization.id);

    if (!organizationMembersList) {
      return;
    }

    this.dispatch(R.actions.userManagement.setUserList(organizationMembersList));
    this.dispatch(appR.actions.overview.setIsUserTheOnlyMember(organizationMembersList.length === 1));

    new OverviewDashboardUseCase(this).completeOnboarding();
  };

  public setDepartments = (departments: string[]) => {
    this.dispatch(R.actions.userInvite.setDepartments(departments));

    this.validateDepartments();
  };

  public setJobTitle = (jobTitle: string) => {
    this.dispatch(R.actions.userInvite.setJobTitle(jobTitle));
  }

  public setTeams = (teams: string[]) => {
    this.dispatch(R.actions.userInvite.setTeams(teams));
  };

  public setEmail = (value: string) => {
    this.dispatch(R.actions.userInvite.setEmail(value));
  }

  public onBlurEmail = () => {
    this.validateEmail();
  }

  public setRole = (value: EOrganizationMemberRole) => {
    this.dispatch(R.actions.userInvite.setRole(value));
  }

  public onBlurRole = () => {
    this.validateRole();
  }

  public closeUserInviteDrawer = () => {
    this.dispatch(R.actions.userInvite.clear());
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  private validateDepartments = () => {
    const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementProfilePermissions);

    if (!hasAccess(permissions?.userDepartmentsAndTeamsEdit, PermissionAttributePolicy.WRITE)) {
      this.dispatch(R.actions.userInvite.setDepartmentsError(false));

      return;
    }

    const departments = R.selectors.userInvite.getDepartments(this.store.getState());

    this.dispatch(R.actions.userInvite.setDepartmentsError(!departments.length));
  }

  private validateEmail = () => {
    const email = R.selectors.userInvite.getEmail(this.store.getState());

    this.dispatch(R.actions.userInvite.clearEmailError());

    if (!email) {
      this.dispatch(R.actions.userInvite.setEmailError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }

  private validateRole = () => {
    const lastName = R.selectors.userInvite.getRole(this.store.getState());

    this.dispatch(R.actions.userInvite.clearRoleError());

    if (!lastName) {
      this.dispatch(R.actions.userInvite.setRoleError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }
}
