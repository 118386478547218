import React, { useCallback, FC } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ArrowRightSvg } from 'app-wrapper/view/icons';

import {
  SBLIncompleteStub,
  SBLIncompleteTag,
  SBLIncompleteButton,
} from './IncompleteStub.styled';

interface IncompleteStubComponentProps {
  nameTag?: string;
  redirectURL: string;
  clickAction?: () => void;
  hideNavigateIcon?: boolean;
}

const IncompleteStubComponent: FC<IncompleteStubComponentProps> = ({
  nameTag,
  redirectURL, hideNavigateIcon, clickAction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => (clickAction ? clickAction() : navigate(redirectURL)), [navigate, redirectURL, clickAction]);

  return (
    <SBLIncompleteStub>
      <SBLIncompleteTag color="gold">
        {nameTag || t('Incomplete')}
      </SBLIncompleteTag>

      {!hideNavigateIcon ? (
        <SBLIncompleteButton
          onClick={handleNavigate}
          type="primary"
          icon={<ArrowRightSvg fill="#F1AE00" width="14px" height="14px" />}
        />
      ) : null}
    </SBLIncompleteStub>
  );
};

export { IncompleteStubComponent as IncompleteStub };
