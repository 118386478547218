import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { v4 as uuidv4 } from 'uuid';

import {
  CargoErrorsDTM,
  CargoReferenceDTM,
  CargoReferenceErrorsDTM, CargoBaseDTM, CargoDTM,
} from 'shipment-operations/models/dtm';
import { ICargoState } from 'shipment-operations/models/states';

type ChangeHsCodeAction = PayloadAction<Pick<CargoDTM, 'code' | 'name'> & {
  errorsCode: CargoErrorsDTM['code'],
  errorsName: CargoErrorsDTM['name']
}>

type ChangeValidatedFieldAction<T extends keyof Omit<CargoErrorsDTM, 'hasErrors'>> = PayloadAction<
  Pick<CargoDTM, T> & { error?: CargoErrorsDTM[T] }
>;
type ChangeUnvalidatedFieldAction<T extends keyof CargoDTM> = PayloadAction<
  CargoDTM[T]
>

const initialState: ICargoState = {
  isLoading: true,
  selectedCargo: 0,
  cargos: [],
  defaultCargo: CargoBaseDTM.createEmpty(),
};

export const cargoStore = createSlice({
  name: 'cargo',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = true;
      state.selectedCargo = 0;
      state.cargos = [];
      state.defaultCargo = CargoBaseDTM.createEmpty();
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTempertaureControl: (state, action: PayloadAction<boolean>) => {
      state.temperatureControl = action.payload;
    },
    selectCargo: (state, action: PayloadAction<number>) => {
      state.selectedCargo = action.payload;
    },
    setDefaultCargo: (state, action: PayloadAction<CargoBaseDTM>) => {
      state.defaultCargo = {
        ...action.payload,
      };
    },
    setCargos: (state, action: PayloadAction<CargoDTM[]>) => {
      state.cargos = [...action.payload];
    },
    addCargo: (state) => {
      const newCargo = CargoDTM.fromPlain({
        ...state.defaultCargo,
        renderId: uuidv4(),
        errors: {},
        touchedFields: {},
        isHazmat: false,
        isHazmatCollapseOpen: false,
        wasUpdateAttempted: false,
        hsCodeValidationStatus: state.temperatureControl ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
        initialState: state.defaultCargo,
      });

      state.cargos = [...state.cargos, newCargo];
    },

    setCargoErrors: (state, action: PayloadAction<{ index: number, errors: CargoErrorsDTM }>) => {
      const { index, errors } = action.payload;

      state.cargos[index] = CargoDTM.fromPlain({
        ...state.cargos[index],
        errors,
      });
    },

    setHazmatCollapseStatus: (state, action: PayloadAction<boolean>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        isHazmatCollapseOpen: action.payload,
      });
    },
    setCargo: (state, action: PayloadAction<CargoDTM>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...action.payload,
      });
    },
    removeCargo: (state, action: PayloadAction<number>) => {
      const cargos = [...state.cargos.filter((_, i) => i !== action.payload)];

      state.cargos = [...cargos];
    },
    setIsHazmat: (state, action: PayloadAction<CargoDTM['isHazmat']>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        isHazmat: action.payload,
      });
    },
    setWasUpdateAttempted: (state, action: ChangeUnvalidatedFieldAction<'wasUpdateAttempted'>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        wasUpdateAttempted: action.payload,
      });
    },
    touchField: (state, action: PayloadAction<string>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        touchedFields: {
          ...state.cargos[state.selectedCargo].touchedFields,
          [action.payload]: true,
        },
      });
    },
    clearTouchedFields: (state) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        touchedFields: {},
      });
    },
    setInitialState: (state, action: PayloadAction<CargoBaseDTM>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        initialState: {
          ...action.payload,
        },
      });
    },
    setHsCode: (state, action: ChangeHsCodeAction) => {
      const cargo = state.cargos[state.selectedCargo];
      const {
        code,
        name,
        errorsName,
        errorsCode,
      } = action.payload;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        code,
        name,
        errors: {
          ...cargo.errors,
          code: errorsCode,
          name: errorsName,
        },
        touchedFields: {
          ...cargo.touchedFields,
          code: true,
        },
      });
    },
    setHsCodeValidationStatus: (state, action: ChangeUnvalidatedFieldAction<'hsCodeValidationStatus'>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        hsCodeValidationStatus: action.payload,
      });
    },
    setDescription: (state, action: ChangeUnvalidatedFieldAction<'description'>) => {
      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        description: action.payload,
        touchedFields: {
          ...state.cargos[state.selectedCargo].touchedFields,
          description: true,
        },
      });
    },
    setValue: (state, { payload }: ChangeValidatedFieldAction<'value'>) => {
      const cargo = state.cargos[state.selectedCargo];
      const { value, error } = payload;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...state.cargos[state.selectedCargo],
        value,
        touchedFields: {
          ...state.cargos[state.selectedCargo].touchedFields,
          value: true,
        },
        errors: {
          ...cargo.errors,
          value: error,
        },
      });
    },
    setPackageType: (state, action: ChangeValidatedFieldAction<'packageType'>) => {
      const cargo = state.cargos[state.selectedCargo];
      const { packageType, error } = action.payload;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        packageType,
        errors: {
          ...cargo.errors,
          packageType: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          packageType: true,
        },
      });
    },
    setPackagesNumber: (state, action: ChangeValidatedFieldAction<'packagesNumber'>) => {
      const cargo = state.cargos[state.selectedCargo];
      const { packagesNumber, error } = action.payload;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        packagesNumber,
        errors: {
          ...cargo.errors,
          packagesNumber: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          packagesNumber: true,
        },
      });
    },
    setWeight: (state, action: ChangeValidatedFieldAction<'weight'>) => {
      const cargo = state.cargos[state.selectedCargo];
      const { weight, error } = action.payload;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        weight,
        errors: {
          ...cargo.errors,
          weight: error,
        },
        touchedFields: {
          ...cargo.touchedFields,
          weight: true,
        },
      });
    },
    setVolume: (state, action: ChangeUnvalidatedFieldAction<'volume'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        volume: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          volume: true,
        },
      });
    },
    setMarks: (state, action: ChangeUnvalidatedFieldAction<'marks'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        marks: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          marks: true,
        },
      });
    },

    touchReferenceType: (state, action: PayloadAction<number>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        touchedFields: {
          ...cargo.touchedFields,
          [`references.${action.payload}.type`]: true,
        },
      });
    },
    touchReferenceValue: (state, action: PayloadAction<number>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        touchedFields: {
          ...cargo.touchedFields,
          [`references.${action.payload}.type`]: true,
          [`references.${action.payload}.value`]: true,
        },
      });
    },
    setReferenceType: (state, action: PayloadAction<{ index: number } & Pick<CargoReferenceDTM, 'type'>>) => {
      const cargo = state.cargos[state.selectedCargo];
      const references = [...cargo.references];
      const { index, type } = action.payload;

      references[index].type = type;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        references,
        touchedFields: {
          ...cargo.touchedFields,
          [`references.${action.payload}.type`]: true,
        },
      });
    },
    setReferenceValue: (state, action: PayloadAction<{ index: number } & Pick<CargoReferenceDTM, 'value'>>) => {
      const cargo = state.cargos[state.selectedCargo];
      const references = [...cargo.references];
      const { index, value } = action.payload;

      references[index].value = value;

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        references,
        touchedFields: {
          ...cargo.touchedFields,
          [`references.${action.payload}.value`]: true,
        },
      });
    },
    addReference: (state) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        references: [
          ...cargo.references,
          { id: uuidv4() },
        ],
      });
    },
    removeReference: (state, action: PayloadAction<number>) => {
      const cargo = state.cargos[state.selectedCargo];
      const { references } = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        references: references.filter((_, i) => i !== action.payload),
        touchedFields: {
          ...cargo.touchedFields,
          [`references.${action.payload}.type`]: false,
          [`references.${action.payload}.value`]: false,
        },
      });
    },

    setImoClass: (state, action: ChangeUnvalidatedFieldAction<'imoClass'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        imoClass: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          imoClass: true,
        },
      });
    },
    setUnNumber: (state, action: ChangeUnvalidatedFieldAction<'unNumber'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        unNumber: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          unNumber: true,
        },
      });
    },
    setPackingGroup: (state, action: ChangeUnvalidatedFieldAction<'packingGroup'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        packingGroup: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          packingGroup: true,
        },
      });
    },
    setShippingName: (state, action: ChangeUnvalidatedFieldAction<'shippingName'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        shippingName: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          shippingName: true,
        },
      });
    },
    setMsdsDocument: (state, action: ChangeUnvalidatedFieldAction<'msdsDocument'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        msdsDocument: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          msdsDocument: true,
        },
      });
    },
    setContactName: (state, action: ChangeUnvalidatedFieldAction<'contactName'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        contactName: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          contactName: true,
        },
      });
    },
    setContactNumber: (state, action: ChangeUnvalidatedFieldAction<'contactNumber'>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        contactNumber: action.payload,
        touchedFields: {
          ...cargo.touchedFields,
          contactNumber: true,
        },
      });
    },

    setHsCodeError: (state, action: PayloadAction<CargoErrorsDTM['code']>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          code: action.payload,
        },
      });
    },
    setReferencesErrors: (state, action: PayloadAction<CargoReferenceErrorsDTM[] | undefined>) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          references: action.payload,
        },
      });
    },
    setHazmatErrors: (state, action: PayloadAction<Pick<CargoErrorsDTM, 'imoClass' | 'unNumber' | 'packingGroup' | 'shippingName' | 'msdsDocument'>>) => {
      const {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument,
      } = action.payload;
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          unNumber,
          imoClass,
          packingGroup,
          shippingName,
          msdsDocument,
        },
      });
    },
    clearHazmatErrors: (state) => {
      const cargo = state.cargos[state.selectedCargo];

      state.cargos[state.selectedCargo] = CargoDTM.fromPlain({
        ...cargo,
        errors: {
          ...cargo.errors,
          unNumber: undefined,
          imoClass: undefined,
          packingGroup: undefined,
          shippingName: undefined,
          msdsDocument: undefined,
        },
      });
    },
  },
});

export const cargoActions = cargoStore.actions;
export const cargoReducer = cargoStore.reducer;
