import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { Tooltip } from 'app-wrapper/view/components';
import {
  CustomSeparator,
  EmptyNumber,
  Number,
  NumberDisabled,
  SecondNumber,
  StepWrapper,
  Title,
  Wrapper,
  WarningIcon,
} from 'shipment-operations/view/components/BookingDrawer/components/StepsHeader/StepsHeader.styled';

interface StepsHeaderComponentProps {
  currentStep: number;
  isCustomerOrg: boolean;
  isShipmentWaitingApproval: boolean;
  isSuppliersInformationMissing: boolean;
  isOriginDoor?: boolean;
  isDestinationDoor?: boolean;
}

export const StepsHeaderComponent: FC<StepsHeaderComponentProps> = ({
  currentStep,
  isCustomerOrg,
  isSuppliersInformationMissing,
  isShipmentWaitingApproval,
  isOriginDoor,
  isDestinationDoor,
}) => {
  const { t } = useTranslation();

  const pickupDeliveryStepName = useMemo(() => {
    if (isOriginDoor && isDestinationDoor) {
      return t('Supplier/Receiver Information');
    }

    return isOriginDoor ? t('Supplier Information') : t('Receiver Information');
  }, [t, isOriginDoor, isDestinationDoor]);

  const isLastStep = currentStep === 3;

  return (
    <Wrapper>
      {isShipmentWaitingApproval ? (
        <>
          <StepWrapper>
            {currentStep > 1 ? (
              <EmptyNumber>
                <CheckOutlined style={{ color: '#6C79DE' }} />
              </EmptyNumber>
            ) : (
              <Number>1</Number>
            )}
            <Title isSecondStep>
              {t('Booking Details')}
            </Title>
          </StepWrapper>

          <CustomSeparator isSecondStep={currentStep > 1} />

          <StepWrapper>
            {currentStep > 2 ? (
              <EmptyNumber>
                <CheckOutlined style={{ color: '#6C79DE' }} />
              </EmptyNumber>
            ) : (
              <>
                {currentStep < 2 ? (
                  <NumberDisabled>
                    2
                  </NumberDisabled>
                ) : (
                  <Number>2</Number>
                )}
              </>
            )}

            <Title isSecondStep={currentStep >= 2}>
              {t('Schedule')}
            </Title>
          </StepWrapper>

          <CustomSeparator isSecondStep={isLastStep} />

          <StepWrapper>
            <>
              {currentStep > 3 ? (
                <EmptyNumber>
                  <CheckOutlined style={{ color: '#6C79DE' }} />
                </EmptyNumber>
              ) : (
                <>
                  {currentStep === 3 ? (
                    <Number>3</Number>
                  ) : (
                    <>
                      {isSuppliersInformationMissing ? (
                        <Tooltip title={t('Waiting for additional information')}>
                          <WarningIcon />
                        </Tooltip>
                      ) : (
                        <NumberDisabled>2</NumberDisabled>
                      )}
                    </>
                  )}
                </>
              )}
            </>

            <Title isSecondStep={isLastStep || isSuppliersInformationMissing}>
              {pickupDeliveryStepName}
            </Title>
          </StepWrapper>
        </>
      ) : (
        <>
          <StepWrapper>
            {currentStep > 1 ? (
              <EmptyNumber>
                <CheckOutlined style={{ color: '#6C79DE' }} />
              </EmptyNumber>
            ) : (
              <Number>1</Number>
            )}
            <Title isSecondStep>
              {t('Booking Details')}
            </Title>
          </StepWrapper>

          <CustomSeparator isSecondStep={currentStep > 1} />

          <StepWrapper>
            <>
              {currentStep > 2 ? (
                <EmptyNumber>
                  <CheckOutlined style={{ color: '#6C79DE' }} />
                </EmptyNumber>
              ) : (
                <>
                  {currentStep === 2 ? (
                    <Number>2</Number>
                  ) : (
                    <>
                      {isSuppliersInformationMissing ? (
                        <Tooltip title={t('Waiting for additional information')}>
                          <WarningIcon />
                        </Tooltip>
                      ) : (
                        <NumberDisabled>2</NumberDisabled>
                      )}
                    </>
                  )}
                </>
              )}
            </>

            <Title isSecondStep={currentStep > 1 || isSuppliersInformationMissing}>
              {pickupDeliveryStepName}
            </Title>
          </StepWrapper>

          {isCustomerOrg ? (
            <>
              <CustomSeparator isSecondStep={isLastStep} />

              <StepWrapper>
                <SecondNumber isSecondStep={isLastStep}>3</SecondNumber>

                <Title isSecondStep={isLastStep}>
                  {t('Review and Confirm')}
                </Title>
              </StepWrapper>
            </>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};
