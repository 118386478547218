import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { Select, Option } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  PackageTypeNames, PackageType, PackageTypesCodes, ReversePackageTypesCodes,
} from 'app-wrapper/types/PackageType';
import { CargoDTM } from 'shipment-operations/models/dtm';

interface ICargoFormPackageTypeSelectProps {
  packageType: CargoDTM['packageType']
  touchField: (field: string) => void
  setPackageType: (value: PackageType) => void
  error?: boolean
  cargosAvailability?: PermissionAttributePolicy
  customerCargosAvailability?: PermissionAttributePolicy
}

export const CargoFormPackageTypeSelectComponent: FC<ICargoFormPackageTypeSelectProps> = ({
  packageType,
  touchField,
  setPackageType,
  error,
  cargosAvailability,
  customerCargosAvailability,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((value: PackageType) => {
    setPackageType(PackageTypesCodes[value as keyof typeof PackageTypeNames] as PackageType);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('packageType');
  }, []);

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, customerCargosAvailability]);

  return (
    <Select
      value={packageType ? ReversePackageTypesCodes[packageType as unknown as keyof typeof ReversePackageTypesCodes] : ''}
      error={error}
      allowClear={!!packageType}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={isDisabled}
      showSearch
      listHeight={200}
    >
      {Object.keys(PackageTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(PackageTypeNames[type as PackageType])}
        </Option>
      ))}
    </Select>
  );
};
