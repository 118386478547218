import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { ShipmentStatusEnum } from 'shipment-operations/constants';

import { ContainersDetailsComponent } from './ContainersDetails.component';

const ContainersDetailsContainer = () => {
  const emptyReleaseDate = useSelector(R.selectors.bookingWizard.getEmptyReleaseDate);
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);
  const containers = useSelector(R.selectors.bookingWizard.getContainers);
  const hasSOC = useSelector(R.selectors.bookingWizard.getHasSOC);
  const emptyReleaseDateError = useSelector(R.selectors.bookingWizard.getEmptyReleaseDateError);
  const containersErrors = useSelector(R.selectors.bookingWizard.getContainersErrors);
  const shipment = useSelector(R.selectors.bookingWizard.getShipment);
  const isCustomer = useSelector(R.selectors.bookingWizard.getIsCustomer);
  const shouldChangeQuotaDueToContainer = useSelector(R.selectors.bookingWizard.getShouldChangeQuotaDueToContainer);
  const notSavedContainers = useSelector(R.selectors.bookingWizard.getNotSavedContainers);

  return (
    <ContainersDetailsComponent
      isPending={isContentUpdating}
      containers={containers}
      date={emptyReleaseDate}
      onChange={UC.bookingWizard.setEmptyReleaseDate}
      hasSOC={hasSOC}
      error={emptyReleaseDateError}
      setContainerWeightById={UC.bookingWizard.setContainerWeightById}
      setContainerVolumeById={UC.bookingWizard.setContainerVolumeById}
      setContainerReferenceById={UC.bookingWizard.setContainerReferenceById}
      containersErrors={containersErrors}
      isDoorShipment={shipment?.isDrayageShipment()}
      isCustomer={isCustomer}
      isOriginDoor={shipment?.getIsOriginDoor()}
      isShipmentWaitingApproval={shipment?.status === ShipmentStatusEnum.AWAITING_APPROVAL}
      shouldChangeQuotaDueToContainer={shouldChangeQuotaDueToContainer}
      notSavedContainers={notSavedContainers}
      openChangeBookingQuotaDrawer={appUC.drawer.openChangeBookingQuotaDrawer}
    />
  );
};

export { ContainersDetailsContainer as ContainersDetails };
