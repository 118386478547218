import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { LoadingDetailsComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/LoadingDetails';

export const LoadingDetails = React.memo(() => {
  const isDestinationPartner = useSelector(R.selectors.bookingWizard.getIsDestinationPartner);
  const isWarningVisible = useSelector(R.selectors.bookingWizard.getIsLoadingMethodsInformationMissing);
  const loadingControls = useSelector(R.selectors.bookingWizard.getReceiverLoadingControls);
  const requiredError = useSelector(R.selectors.bookingWizard.getIsCargoReceiverRequiredErrorVisible);

  return (
    <LoadingDetailsComponent
      loadingMethod={loadingControls?.loadingMethod}
      receivingMethod={loadingControls?.receivingMethod}
      setLoadingMethod={UC.bookingWizard.setReceiverLoadingMethod}
      setReceivingMethod={UC.bookingWizard.setReceiverReceivingMethod}
      isWarningVisible={isWarningVisible}
      isRequiredErrorVisible={requiredError}
      isOriginOrDestinationPartner={isDestinationPartner}
    />
  );
});
