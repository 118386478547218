import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomerAddInternalContactInformationComponent } from './CustomerAddInternalContactInformation.component';

const CustomerAddInternalContactInformationContainer: FC = () => {
  const customerOrganization = useSelector(R.selectors.userOrganizationData.getCustomersAddInternal);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalErrors);
  const organizationCountries = useSelector(R.selectors.userOrganizationData.organizationCountriesAll);

  return (
    <CustomerAddInternalContactInformationComponent
      organization={customerOrganization}
      organizationFieldsErrors={organizationFieldsErrors}
      countriesList={organizationCountries}
      onChangeUpdateAddress1={UC.organizationProfile.onChangeCustomerAddInternalAddress1}
      onChangeUpdateAddress2={UC.organizationProfile.onChangeCustomerAddInternalAddress2}
      onChangeUpdateAddressCity={UC.organizationProfile.onChangeCustomerAddInternalAddressCity}
      onChangeUpdateAddressPostalCode={UC.organizationProfile.onChangeCustomerAddInternalAddressPostalCode}
      onChangeUpdateAddressCountry={UC.organizationProfile.onChangeCustomerAddInternalAddressCountry}
      onClearUpdateAddressCountry={UC.organizationProfile.onClearCustomerAddInternalAddressCountry}
    />
  );
};

export const CustomerAddInternalContactInformation = memo(CustomerAddInternalContactInformationContainer);
