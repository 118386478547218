import React, { FC } from 'react';

import { ContainerDTM } from 'shipment-operations/models/dtm';
import { commonContainersTypesLong } from 'shipment-operations/constants';
import { ContainerIcon } from 'app-wrapper/view/icons';

import {
  ContainerInformation,
  Divider,
  Number,
  Name,
  Input,
} from 'shipment-operations/view/components/BookingDrawer/components/ContainersDetails/components/Container/Container.styled';

import { ContainerActions } from './components';
import { ContainerWrap } from './NotSavedContainer.styled';

interface INotSavedContainerProps {
  container: ContainerDTM;
  isAbleToDeleteContainer: boolean;
}

export const NotSavedContainer: FC<INotSavedContainerProps> = React.memo(({ container, isAbleToDeleteContainer }: INotSavedContainerProps) => (
  <ContainerWrap>
    <ContainerInformation>
      <ContainerIcon />

      <Number>
        × 1
      </Number>

      <Divider type="vertical" dashed />

      <Name>
        {commonContainersTypesLong[container.type]}
      </Name>
    </ContainerInformation>

    <Input disabled />

    <Input disabled />

    <Input disabled />

    {isAbleToDeleteContainer ? <ContainerActions containerId={container.id} /> : null}
  </ContainerWrap>
));
