import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const CargoCardBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  width: 100%;
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    color: ${colors.gray61};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 12px !important;
  }
`;

export const HazmatTagContainer = styled.div`
  margin-left: auto;
  > * {
    font-weight: 500;
    padding: 0px 4px;
    font-size: 10px;
    line-height: 14px;
    text-transform: capitalize;
  }
  height: max-content;
`;

export const PackageTypeText = styled.span`
  width: fit-content;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
