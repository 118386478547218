import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DatesComponent } from './Dates.component';

export const Dates = React.memo(() => {
  const exportSchedule = useSelector(R.selectors.bookingWizard.getExportSchedule);
  const isCargoReadyRequiredErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoReadyRequiredErrorVisible);
  // const importSchedule = useSelector(R.selectors.bookingWizard.getImportSchedule);
  // const isCargoDeliveryRequiredErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoDeliveryRequiredErrorVisible);

  return (
    <DatesComponent
      cargoReadyDate={exportSchedule.cargoReadyDate?.getDateAsMomentLocalOffset()}
      setCargoReadyDate={UC.bookingWizard.setExportScheduleDate}
      isRequiredCargoReadyVisible={isCargoReadyRequiredErrorVisible}
      // deliveryDate={importSchedule.cargoReadyDate?.getDateAsMomentLocalOffset()}
      // setDeliveryDate={UC.bookingWizard.setImportScheduleDate}
      // isRequiredDeliveryDateVisible={isCargoDeliveryRequiredErrorVisible}
    />
  );
});
