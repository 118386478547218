import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';
import { Link, useParams } from 'react-router-dom';

import { PAYABLES } from 'shipment-operations/constants';
import { ShipmentBillingInvoiceDtm, ShippingPartyDTM } from 'shipment-operations/models/dtm';

import {
  CardWrapper, Wrapper, CardsTitle, CardsValue, LargeTitle, MainSum,
} from 'shipment-operations/view/components';
import { RouteNames } from 'app-wrapper/constants';

interface ICardsWrapperProps {
  invoice?: ShipmentBillingInvoiceDtm
  companyFrom?: ShippingPartyDTM
  companyTo?: ShippingPartyDTM
  shipmentName?: string;
}

const CardsWrapperComponent: FC<ICardsWrapperProps> = ({
  invoice,
  companyFrom,
  companyTo,
  shipmentName,
}) => {
  const { t } = useTranslation();
  const { invoiceType } = useParams<'invoiceType'>();
  const { shipmentId } = useParams<'shipmentId'>();

  return (
    <Wrapper>
      <CardWrapper>
        {invoiceType === PAYABLES ? (
          <>
            <CardsTitle>{t('vendor')}</CardsTitle>
            <CardsValue>{companyFrom?.company?.name || invoice?.billFrom?.name}</CardsValue>
            <CardsValue>{companyFrom?.address?.address1}</CardsValue>
            <CardsValue>{companyFrom?.address?.address2}</CardsValue>
            <CardsValue>{companyFrom?.address?.city} {companyFrom?.address?.state}</CardsValue>
            <CardsValue>{companyFrom?.address?.postalCode} {companyFrom?.address?.country}</CardsValue>
          </>
        ) : (
          <>
            <CardsTitle>{t('billTo')}</CardsTitle>
            <CardsValue>{companyTo?.company?.name}</CardsValue>
            <CardsValue>{companyTo?.address?.address1}</CardsValue>
            <CardsValue>{companyTo?.address?.address2}</CardsValue>
            <CardsValue>{companyTo?.address?.city} {companyTo?.address?.state}</CardsValue>
            <CardsValue>{companyTo?.address?.postalCode} {companyTo?.address?.country}</CardsValue>
          </>
        )}
      </CardWrapper>

      <CardWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '40%' }}>
            <CardsTitle>{t('billedDate')}</CardsTitle>
            <CardsValue>{invoiceType === PAYABLES ? invoice?.billedDate : invoice?.createdAt}</CardsValue>
          </div>
          <div style={{ width: '50%' }}>
            <CardsTitle>{t('dueDate')}</CardsTitle>
            <CardsValue>{invoice?.dueDate}</CardsValue>
          </div>
        </div>
        <Divider style={{ margin: '5px 0' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '40%' }}>
            <CardsTitle>{t('shipment')}</CardsTitle>
            <CardsValue>
              <Link to={RouteNames.SHIPMENT(shipmentId)}>{shipmentName}</Link>
            </CardsValue>
          </div>
          <div style={{ width: '50%' }}>
            <CardsTitle>{t('reference')}</CardsTitle>
            <CardsValue>{invoice?.reference}</CardsValue>
          </div>
        </div>
      </CardWrapper>

      <CardWrapper>
        <LargeTitle>{t('balanceDue')}</LargeTitle>
        <MainSum>$ {invoice?.balance.toFixed(2)}</MainSum>
      </CardWrapper>
    </Wrapper>
  );
};

export { CardsWrapperComponent };
