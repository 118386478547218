import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AdditionalPhoneInputComponent } from './AdditionalPhoneInput.component';

const AdditionalPhoneInputContainer: FC = () => {
  const additionalPhone = useSelector(R.selectors.shippingParties.getAdditionalPhone);
  const errorMessage = useSelector(R.selectors.shippingParties.getAdditionalPhoneErrorMessage);

  return (
    <AdditionalPhoneInputComponent
      additionalPhone={additionalPhone}
      setAdditionalPhone={UC.shippingParties.setAdditionalPhone}
      errorMessage={errorMessage}
    />
  );
};

const AdditionalPhoneInputContainerCached = React.memo(AdditionalPhoneInputContainer);

export {
  AdditionalPhoneInputContainerCached as AdditionalPhoneInput,
};
