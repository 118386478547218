import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import Row from 'antd/es/row';

import { AccountDepartmentDTM } from 'user-management/models/dtm';
import { DEPARTMENT_TYPE_NAMES } from 'user-management/constants';

import {
  Item,
  ItemContentWrap,
  IconWrap,
  ItemList,
  ItemTitle,
  Tag,
} from './TeamsAndDepartments.styled';

interface TeamsAndDepartmentsComponentProps {
  departments: AccountDepartmentDTM[];
}

export const TeamsAndDepartmentsComponent: FC<TeamsAndDepartmentsComponentProps> = ({ departments }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Item>
        <Row>
          <IconWrap>
            <IdcardOutlined />
          </IconWrap>

          <ItemContentWrap>
            <ItemTitle>
              {t('Departments')}
            </ItemTitle>

            <ItemList>
              {departments.length ? departments.map((department) => <Tag key={department.id}>{DEPARTMENT_TYPE_NAMES[department.type]}</Tag>) : t('-')}
            </ItemList>
          </ItemContentWrap>
        </Row>
      </Item>
    </Row>
  );
};
