import { createContext, useContext } from 'react';
import { ShipmentPermissionsRepository } from 'shipment-operations/repositories/ShipmentPermissionsRepository';
import { OrganizationRepository } from 'user-management/repositories/OrganizationRepository';

export interface RepositoriesInterface {
  organizationRepository: OrganizationRepository,
  shipmentPermissionsRepository: ShipmentPermissionsRepository,
}

// @ts-ignore
export const RepositoriesContext = createContext<RepositoriesInterface>(null);

export const useRepositories = () => useContext(RepositoriesContext);
