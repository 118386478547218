import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TabPane, Tabs } from 'app-wrapper/view/components';

import { BreakdownItem } from 'monetary/view/components/BreakdownItem';
import { AccessorialsTabItem, RFQFreeTime, FAKException } from 'monetary/view/components';
import {
  DetailAccessorialsDTM, DetailBreakdownDTM, DetailRoutingDTM, getNameFreightPaymentTerms,
} from 'monetary/models/dtm';

import {
  RFQItemDetailDiv,
  RFQItemDetailDivContent,
  RFQItemDetailDivContentTabsTitle,
} from './RFQItemDetail.styled';
import { RFQItemDetailRouting } from '../RFQItemDetailRouting';

interface IRFQItemDetailComponentAccessorialsProps {
  origin?: DetailAccessorialsDTM
  freight?: DetailAccessorialsDTM
  destination?: DetailAccessorialsDTM
}

export interface IRFQItemDetailComponentProps {
  routing: {
    origin?: DetailRoutingDTM[]
    freight?: DetailRoutingDTM[]
    destination?: DetailRoutingDTM[]
  }
  breakdown: {
    origin?: DetailBreakdownDTM
    freight?: DetailBreakdownDTM
    destination?: DetailBreakdownDTM
  }
  paymentTermsOrigin: boolean
  paymentTermsDestination: boolean
  accessorials: IRFQItemDetailComponentAccessorialsProps
  idIndex?: number
}

export const RFQItemDetailComponent: FC<IRFQItemDetailComponentProps> = (props) => {
  const {
    routing,
    breakdown,
    accessorials,
    paymentTermsOrigin,
    paymentTermsDestination,
    idIndex,
  } = props;
  const { t } = useTranslation();

  const isShowBreakdownOrigin = (
    breakdown.origin?.transportation?.containers?.length
    || breakdown.origin?.fees || breakdown.origin?.summary
    || breakdown.origin?.service
  );
  const isShowBreakdownDestination = (
    breakdown.destination?.transportation?.containers?.length
    || breakdown.destination?.fees
    || breakdown.destination?.summary
    || breakdown.destination?.service
  );

  const isShowAccessorialsOrigin = (
    accessorials.origin?.accessorials?.length
    || accessorials.origin?.penalties?.length
  );

  const isShowAccessorialsFreight = (
    accessorials.freight?.accessorials?.length
    || accessorials.freight?.penalties?.length
  );

  const isShowAccessorialsDestination = (
    accessorials.destination?.accessorials?.length
    || accessorials.destination?.penalties?.length
  );

  const paymentTermsOriginTitle = breakdown.origin?.paymentTerms ? getNameFreightPaymentTerms[breakdown.origin.paymentTerms] : '';

  const paymentTermsFreightTitle = breakdown.freight?.paymentTerms ? getNameFreightPaymentTerms[breakdown.freight.paymentTerms] : '';

  const paymentTermsDestinationTitle = breakdown.destination?.paymentTerms ? getNameFreightPaymentTerms[breakdown.destination.paymentTerms] : '';

  return (
    <RFQItemDetailDiv>
      <RFQItemDetailDivContent>
        <Tabs
          className="RFQItemDetailDivContent__Tabs"
          onChange={() => {
            // This is intentional
          }}
        >
          <TabPane
            key="0"
            tab={(
              <RFQItemDetailDivContentTabsTitle className="RFQItemDetailDivContentTabsTitle__Routing">
                {t('Routing')}
              </RFQItemDetailDivContentTabsTitle>
            )}
          >
            <RFQItemDetailRouting
              freight={routing.freight}
              origin={routing.origin}
              destination={routing.destination}
            />
          </TabPane>
          <TabPane
            key="1"
            tab={(
              <RFQItemDetailDivContentTabsTitle className="RFQItemDetailDivContentTabsTitle__Breakdown">
                {t('Breakdown')}
              </RFQItemDetailDivContentTabsTitle>
            )}
          >
            {
              !!(breakdown.origin && isShowBreakdownOrigin) && (
                <BreakdownItem
                  transportation={breakdown.origin?.transportation}
                  fees={breakdown.origin?.fees}
                  service={breakdown.origin?.service}
                  fieldName={breakdown.origin?.fieldName || ''}
                  summary={breakdown.origin?.summary}
                  paymentTerms={paymentTermsOriginTitle}
                  isLast={false}
                  isCollect={paymentTermsOrigin}
                />
              )
            }
            <BreakdownItem
              transportation={breakdown.freight?.transportation}
              fees={breakdown.freight?.fees}
              service={breakdown.freight?.service}
              fieldName={breakdown.freight?.fieldName || ''}
              summary={breakdown.freight?.summary || 0}
              paymentTerms={paymentTermsFreightTitle}
              isLast={!(breakdown.destination && isShowBreakdownDestination)}
            />
            {
              !!(breakdown.destination && isShowBreakdownDestination) && (
                <BreakdownItem
                  transportation={breakdown.destination?.transportation}
                  fees={breakdown.destination?.fees}
                  service={breakdown.destination?.service}
                  fieldName={breakdown.destination?.fieldName || ''}
                  paymentTerms={paymentTermsDestinationTitle}
                  summary={breakdown.destination?.summary}
                  isLast
                  isCollect={paymentTermsDestination}
                />
              )
            }
          </TabPane>
          <TabPane
            key="2"
            tab={(
              <RFQItemDetailDivContentTabsTitle className="RFQItemDetailDivContentTabsTitle__AccessorialsPenaltyCharges">
                {t('AccessorialsPenaltyCharges')}
              </RFQItemDetailDivContentTabsTitle>
            )}
          >
            {
              !!(accessorials.origin && isShowAccessorialsOrigin)
              && (
                <AccessorialsTabItem
                  fieldName={accessorials.origin?.fieldName || ''}
                  summary={accessorials.origin?.summary || 0}
                  freeTime={accessorials.origin?.freeTime}
                  accessorials={accessorials.origin?.accessorials}
                  accessorialsGroup={accessorials.origin?.accessorialsGroup}
                  penalties={accessorials.origin?.penalties}
                  isLast={
                    !(accessorials.freight
                      && isShowAccessorialsFreight)
                    || !(accessorials.destination
                      && isShowAccessorialsDestination)
                  }
                />
              )
            }
            {
              !!(accessorials.freight && isShowAccessorialsFreight)
              && (
                <AccessorialsTabItem
                  fieldName={accessorials.freight?.fieldName || ''}
                  summary={accessorials.freight?.summary || 0}
                  freeTime={accessorials.freight?.freeTime}
                  accessorials={accessorials.freight?.accessorials}
                  accessorialsGroup={accessorials.freight?.accessorialsGroup}
                  penalties={accessorials.freight?.penalties}
                  isLast={
                    !(accessorials.destination
                      && isShowAccessorialsDestination)
                  }
                />
              )
            }
            {
              !!(accessorials.destination
                && isShowAccessorialsDestination)
              && (
                <AccessorialsTabItem
                  fieldName={accessorials.destination?.fieldName || ''}
                  summary={accessorials.destination?.summary || 0}
                  freeTime={accessorials.destination?.freeTime}
                  accessorials={accessorials.destination?.accessorials}
                  accessorialsGroup={accessorials.destination?.accessorialsGroup}
                  penalties={accessorials.destination?.penalties}
                  isLast
                />
              )
            }
          </TabPane>
          <TabPane
            key="3"
            tab={(
              <RFQItemDetailDivContentTabsTitle>
                {t('FreeTime')}
              </RFQItemDetailDivContentTabsTitle>
            )}
          >
            <RFQFreeTime idIndex={idIndex} />
          </TabPane>
          <TabPane
            key="4"
            tab={(
              <RFQItemDetailDivContentTabsTitle>
                {t('fakExceptions')}
              </RFQItemDetailDivContentTabsTitle>
            )}
          >
            <FAKException idIndex={idIndex} />
          </TabPane>
        </Tabs>
      </RFQItemDetailDivContent>
    </RFQItemDetailDiv>
  );
};
