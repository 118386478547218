import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const borderAfter = css`
  position: absolute;
  content: '';
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  left: calc(50% + 0.5px);
  transform: translateX(-50%);
  z-index: 2;
  height: 101%;
`;

// Content

export const BreakdownContentRowItemWrapper = styled.div.attrs((props: { borderShow: boolean }) => ({
  borderShow: props.borderShow,
  'data-class': 'BreakdownContentRowItemWrapper',
}))`
  ${divFlex}
  width: calc(100% - 19px - 19px);
  margin-left: 19px;
  margin-right: 19px;
  ${({ borderShow }) => borderShow && 'border-bottom: 1px solid;'};
  border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 3px;

  .ant-collapse {
    width: 100%;
    border: none;
    background: ${({ theme }) => theme.themesColors.neutralBranding1} !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 0px !important;
    padding-bottom: 0 !important;

    .ant-collapse-item-active {
      margin-bottom: 7px !important;
    }

    .ant-collapse-header {
      font-weight: 500;
      height: 55px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0 !important;
      transition: none;
      display: flex;
      align-items: center;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
      .ant-collapse-header {
      padding-top: 8px;
      padding-bottom: 8px;
      }
    }
    .ant-collapse {
      .ant-collapse-header {
        font-weight: 400;
        height: 40px;
      }
    .ant-collapse {
      .ant-collapse-header {
          height: 36px;
        }
      }
    }
  }
  .BreakdownContentRowItemContainerItem__Fees {
    height: 38px;
  }
  .BreakdownContentRowItem__Fees,
  .BreakdownContentRowItem__Services {
    .ant-collapse-item-active {
      margin-left: 34px !important;
      > .ant-collapse-header {
        left: -34px !important;
        width: 100% !important;
      }
      .ant-collapse-content-active {
        padding-left: 0 !important;
        margin-left: 7px !important;
        width: calc(100% - 42px) !important;
      }
    }
  }
  .ant-collapse-content {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
  }
  .ant-collapse-content-box {
    padding-right: 0;
  }
  .ant-collapse-header {
    width: 100%;
    padding-right: 0 !important;
  }
  .ant-collapse-item {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};

    .ant-collapse-item {
      width: calc(100%);
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }
    &.ant-collapse-item-active {
      margin-left: 19px;
      > .ant-collapse-header {
        color: ${({ theme }) => theme.themesColors.primaryBranding6} !important;
        left: -19px;
        width: calc(100% + 19px);
      }
      .ant-collapse-content-box {
        padding-left: 0;
        .ant-collapse-header {
          padding-left: 0;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content {
          .ant-collapse-content {
            margin-left: 10px;
            width: calc(100% - 10px);
          }
        }
      }
      .ant-collapse-item-active {
        margin-left: 0px;
        width: 100%;
        > .ant-collapse-header {
          left: 0px;
          width: 100%;
        }
        .ant-collapse-content {
          margin-left: 36px;
          width: calc(100% - 36px);
        }
        .ant-collapse-item {
          margin-left: -15px;
          width: calc(100% + 15px);
        }
        .ant-collapse-item-active {
          margin-left: 19px;
          width: calc(100% - 19px);
          > .ant-collapse-header {
            left: -34px;
            width: calc(100% + 34px);
          }
          .ant-collapse-content {
            padding-left: 0;
          }
        }
      }
    }
  }
  .FreightQuoteFormRequest__Row__Commodity__content_title_icon {
    color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  }
`;

export const BreakdownContentRowItem = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItem',
}))`
  ${divFlex}
`;

export const BreakdownContentRowItemHeaderWrapper = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemHeaderWrapper',
}))`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  align-items: start;

  padding-top: 6px;
  padding-bottom: 6px;
`;

export const BreakdownContentRowItemHeader = styled.div.attrs((props: {
  isCollapse: boolean,
}) => ({
  isCollapse: props.isCollapse,
  'data-class': 'BreakdownContentRowItemHeader',
}))`
  ${divFlex}
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme, isCollapse }) => (isCollapse ? theme.themesColors.primaryBranding6 : theme.themesColors.neutralBranding10)};
`;

export const BreakdownContentRowItemSubHeader = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemSubHeader',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const BreakdownContentRowItemSubHeaderCollect = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemSubHeaderCollect',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
  align-items: center;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const BreakdownContentRowItemSummary = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'BreakdownContentRowItemSummary',
}))`
  ${divFlex}
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.neutralBranding10)};
`;

export const BreakdownContentRowItemContainerItem = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerItem',
}))`
  ${divFlex}
  width: 100%;
  height: 32px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid  ${({ theme }) => theme.themesColors.neutralBranding4};
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

export const BreakdownContentRowItemContainerServicesItem = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerServicesItem',
}))`
  ${divFlex}
  width: 100%;
  height: 32px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid  ${({ theme }) => theme.themesColors.neutralBranding4};
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

export const BreakdownContentRowItemSummaryNext = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'BreakdownContentRowItemSummaryNext',
}))`
  ${divFlex}
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.neutralBranding10)};
`;

export const BreakdownContentRowContainerItemSummaryWrapper = styled.div.attrs(({
  'data-class': 'BreakdownContentRowContainerItemSummaryWrapper',
}))`
  ${divFlex}
`;

export const BreakdownContentRowContainerItemSummary = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'BreakdownContentRowContainerItemSummary',
}))`
  ${divFlex}
  font-size: 13px;
  font-weight: 400;
  margin-left: 8px;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.neutralBranding10)};
`;

export const BreakdownContentRowItemContainerItemText = styled.div.attrs((props: {
  $transformText: string,
  $marginLeft: string,
  isPrimary: boolean,
}) => ({
  $transformText: props.$transformText,
  $marginLeft: props.$marginLeft,
  isPrimary: props.isPrimary,
  'data-class': 'BreakdownContentRowItemContainerItemText',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  text-transform: ${({ $transformText }) => ($transformText || 'capitalize')};

  ${({ $marginLeft }) => ($marginLeft ? `margin-left: ${$marginLeft}` : '')};
  ${({ theme, isPrimary }) => (isPrimary ? `color: ${theme.themesColors.secondaryDot45}` : '')};
`;

export const BreakdownContentRowItemContainerItemTextInfo = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerItemTextInfo',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  margin-left: 5px;
`;

export const BreakdownContentRowItemContainerItemCost = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerItemCost',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  align-items: center;
  margin-left: auto;
`;

export const BreakdownContentRowItemContainerItemTotalCost = styled.div.attrs((props: {
  isCollect: boolean,
  isPrimary: boolean,
}) => ({
  isCollect: props.isCollect,
  isPrimary: props.isPrimary,
  'data-class': 'BreakdownContentRowItemContainerItemTotalCost',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.neutralBranding10)};
  align-items: center;
  margin-left: 8px;

  ${({ theme, isPrimary }) => (isPrimary ? `color: ${theme.themesColors.secondaryDot45}` : '')};
`;

export const BreakdownContentRowItemContainerItemSvg = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerItemSvg',
}))`
  ${divFlex}
  transform: translateY(-50%);
  position: absolute;
  left: -21px;
`;

export const BreakdownContentRowItemContainerServicesItemSvg = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerServicesItemSvg',
}))`
  ${divFlex}
  transform: translateY(-50%);
  position: absolute;
  left: -16px;
`;

export const BreakdownContentRowItemContainerServicesSubItemSvg = styled.div.attrs(({
  'data-class': 'BreakdownContentRowItemContainerServicesSubItemSvg',
}))`
  ${divFlex}
  transform: translateY(-50%);
  position: absolute;
  left: -24px;
`;
