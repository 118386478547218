import styled from 'styled-components';
import Typography from 'antd/es/typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { Button } from 'app-wrapper/view/components';

export const WarningWrap = styled.div.attrs({
  'data-class': 'ContainersChangesWarningWrap',
})`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 16px 12px 16px;
  flex-direction: row;
  background-color: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
`;

export const WarningText = styled(Typography)`
  white-space: pre-line;
  font-size: 14px;
  line-height: 22px;
`;

export const WarningIcon = styled(InfoCircleOutlined)`
  margin-right: 16px;
  color: #FAAD14;
  font-size: 21px;
`;

export const WarningButton = styled(Button)`
  margin-left: auto;
  background: #F1AE00;
  border-color: #F1AE00;

  &:hover {
    background: #c58f00;
    border-color: #C58F00FF;
  }
`;
