import styled from 'styled-components';
import Button from 'antd/es/button';
import EditOutlined from '@ant-design/icons/EditOutlined';

export const SBLEditButton = styled(Button)`
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding5};
  height: 22px;
  font-size: 12px;
  line-height: 20px;
  padding: 1px 8px;
`;

export const SBLEditButtonIcon = styled(EditOutlined)`
  width: 14px;
  height: 14px;
`;
