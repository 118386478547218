import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { ButtonWrap } from 'app-wrapper/view/pages/Overview/components/StepsManager/components/OrganizationCreated/OrganizationCreated.styled';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
} from './OrganizationActive.styled';
import illustrationSrc from './assets/illustration.png';

interface IOrganizationActiveProps {
  goToOrganization: () => void;
}

export const OrganizationActive: FC<IOrganizationActiveProps> = ({ goToOrganization }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Content>
        <Title>
          {t('Your organization has been successfully approved')}
        </Title>

        <Text>
          {t('You have successfully confirmed your organization and we have opened access to all the features of the platform.')}
        </Text>

        <ButtonWrap>
          <Button onClick={goToOrganization}>
            {t('View Organization')}
          </Button>
        </ButtonWrap>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
