import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';

import { ManualReferenceNumberModalComponent } from './ManualReferenceNumberModal.component';

const ManualReferenceNumberModalContainer = () => {
  const isPending = useSelector(R.selectors.overview.getIsManualReferenceNumberDrawerPending);
  const manualReferenceNumber = useSelector(R.selectors.overview.getManualReferenceNumber);

  return (
    <ManualReferenceNumberModalComponent
      isPending={isPending}
      onClose={appUC.drawer.closeDrawer}
      onOk={UC.overview.confirmManualReferenceNumber}
      manualReferenceNumber={manualReferenceNumber}
      setManualReferenceNumber={UC.overview.setManualReferenceNumber}
    />
  );
};

export { ManualReferenceNumberModalContainer as ManualReferenceNumberModal };
