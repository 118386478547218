import {
  instanceToInstance,
  Type,
} from 'class-transformer';
import {
  IsDefined,
  ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import {
  ShipmentBillingInvoicePayablesPermissionsDtm, TShipmentBillingInvoicePayablesPermissionsDtm,
} from 'shipment-operations/models/dtm/ModuleFunctionalityPermissions/ShipmentBillingInvoicePayablesPermissions.dtm';
import {
  ExportClearancePermissionsDtm,
  TExportClearancePermissionsDtm,
} from './ExportClearancePermissions.dtm';
import {
  TShippingInstructionsPermissionsDtm,
  ShippingInstructionsPermissionsDtm,
} from './ShippingInstructionsPermissions.dtm';
import {
  TDocumentsPermissionsDtm,
  DocumentsPermissionsDtm,
} from './DocumentsPermissions.dtm';
import {
  TContainersPermissionsDtm,
  ContainersPermissionsDtm,
} from './ContainersPermissions.dtm';
import {
  TCargosPermissionsDtm,
  CargosPermissionsDtm,
} from './CargosPermissions.dtm';
import {
  TShipmentChargesPermissionsDtm,
  ShipmentChargesPermissionsDtm,
} from './ShipmentChargesPermissions.dtm';
import {
  TOverviewPermissionsDTM,
  OverviewPermissionsDTM,
} from './OverviewPermissions.dtm';
import {
  TTransportationPermissionsDtm,
  TransportationPermissionsDtm,
} from './TransportationPermissionsDtm';
import {
  TDashboardsPermissionsDtm,
  DashboardsPermissionsDtm,
} from './DashboardsPermissions.dtm';
import {
  IWorkgroupPermissionsDTM,
  WorkgroupPermissionsDTM,
} from './WorkgroupPermissions.dtm';
import { TransporationDrayagePermissionsDTM, ITransporationDrayagePermissionsDTM } from './TransporationDrayagePermissions.dtm';

export type TModuleFunctionalityPermissionsDTM = {
  cargos: TCargosPermissionsDtm;
  containers: TContainersPermissionsDtm;
  documents: TDocumentsPermissionsDtm;
  exportClearance: TExportClearancePermissionsDtm;
  overview: TOverviewPermissionsDTM;
  shipmentCharges: TShipmentChargesPermissionsDtm;
  shipmentsBillingInvoicePayables: TShipmentBillingInvoicePayablesPermissionsDtm;
  shippingInstructions: TShippingInstructionsPermissionsDtm;
  transportation: TTransportationPermissionsDtm;
  dashboards: TDashboardsPermissionsDtm;
  workgroup: IWorkgroupPermissionsDTM;
  transportationDrayage: ITransporationDrayagePermissionsDTM;
}

export class ModuleFunctionalityPermissionsDtm extends BaseDTM<TModuleFunctionalityPermissionsDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => ExportClearancePermissionsDtm)
  exportClearance: ExportClearancePermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => WorkgroupPermissionsDTM)
  workgroup: WorkgroupPermissionsDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingInstructionsPermissionsDtm)
  shippingInstructions: ShippingInstructionsPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => DocumentsPermissionsDtm)
  documents: DocumentsPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => ContainersPermissionsDtm)
  containers: ContainersPermissionsDtm

  @IsDefined()
  @Type(() => ShipmentChargesPermissionsDtm)
  shipmentCharges: ShipmentChargesPermissionsDtm

  @IsDefined()
  @Type(() => ShipmentBillingInvoicePayablesPermissionsDtm)
  shipmentsBillingInvoicePayables: ShipmentBillingInvoicePayablesPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => CargosPermissionsDtm)
  cargos: CargosPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => OverviewPermissionsDTM)
  overview: OverviewPermissionsDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => TransportationPermissionsDtm)
  transportation: TransportationPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => DashboardsPermissionsDtm)
  dashboards: DashboardsPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => TransporationDrayagePermissionsDTM)
  transportationDrayage: TransporationDrayagePermissionsDTM;

  static createEmpty = () => ModuleFunctionalityPermissionsDtm.fromPlain({
    cargos: {},
    containers: {},
    documents: {},
    exportClearance: {},
    overview: {},
    shipmentCharges: {},
    shipmentsBillingInvoicePayables: {},
    shippingInstructions: {},
    transportation: {},
    dashboards: {},
    workgroup: {},
    transportationDrayage: {},
  });

  static getClone = () => instanceToInstance(this);
}
