import React, { FC, useCallback } from 'react';

import { Radio } from 'app-wrapper/view/components';
import { FlexCol } from 'app-wrapper/view/guideline';
import { colors } from 'app-wrapper/view/themes/themesColors';

import {
  Wrap,
  Title,
  Options,
  Option,
  OptionText,
  OptionTitle,
} from './DoubleRadioSelect.styled';

export interface IDoubleRadioOption {
  value: string;
  title: string;
  text: string;
}

interface IDoubleRadioSelectProps {
  options: [IDoubleRadioOption, IDoubleRadioOption];
  value?: string;
  setValue: (value: string) => void;
  title: string;
  error?: boolean;
}

const ERROR_STYLES = {
  borderColor: colors.red60,
};

export const DoubleRadioSelect: FC<IDoubleRadioSelectProps> = ({
  options,
  setValue,
  value,
  title,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, [setValue]);

  return (
    <Wrap
      style={{
        ...(error ? ERROR_STYLES : {}),
      }}
    >
      <Title>
        {title}
      </Title>

      <Options>
        {options.map((option) => (
          <Option key={option.value}>
            <Radio
              value={option.value}
              checked={option.value === value}
              onChange={handleChange}
            />

            <FlexCol>
              <OptionTitle>
                {option.title}
              </OptionTitle>

              <OptionText>
                {option.text}
              </OptionText>
            </FlexCol>
          </Option>
        ))}
      </Options>
    </Wrap>
  );
};
