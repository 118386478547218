import { SubPageContent } from 'app-wrapper/view/guideline/SubPageContent';
import styled from 'styled-components';

export const HintBoxContent = styled(SubPageContent)`
  background-color: ${({ theme }) => theme.themesColors.neutralBranding2}
`;

export const IconDiv = styled.div`
  height: 100%;
  margin: 0 5px;
  display: flex;
  align-items: center;
`;
