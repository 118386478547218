import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { LoadingControlDTM } from 'shipment-operations/models/dtm';
import { IGetLoadingDetailsResponse, IGetLoadingDetail } from 'shipment-operations/models/contracts';

export class LoadingControlService {
  public getLoadingControls = async (shipmentId: string): Promise<LoadingControlDTM[]> => {
    let loadingDetails: LoadingControlDTM[] = [];
    const response = await apiWorker.requestGet<IGetLoadingDetailsResponse>(this.formatUrl(shipmentId));

    if (response.data.length) {
      loadingDetails = response.data.map((loadingControl) => LoadingControlDTM.fromPlain({
        ...loadingControl,
        loadingMethod: loadingControl.handlingMethod,
      }));
    }

    return loadingDetails;
  };

  public createLoadingControl = async (shipmentId: string, loadingControl: LoadingControlDTM): Promise<LoadingControlDTM | null> => {
    let newLoadingControl: LoadingControlDTM | null = null;

    const response = await apiWorker.requestPost<IGetLoadingDetail>(this.formatUrl(shipmentId), this.formatRequestBody(loadingControl));

    if (response.data) {
      const { data } = response;

      newLoadingControl = LoadingControlDTM.fromPlain({
        id: data.id,
        drayageSide: data.drayageSide,
        loadingTime: data.loadingTime,
        loadingMethod: data.handlingMethod,
        receivingMethod: data.receivingMethod,
      });
    }

    return newLoadingControl;
  };

  public editLoadingControl = async (shipmentId: string, loadingControl: LoadingControlDTM): Promise<LoadingControlDTM | null> => {
    let newLoadingControl: LoadingControlDTM | null = null;

    const response = await apiWorker.requestPut<IGetLoadingDetail>(`${this.formatUrl(shipmentId)}/${loadingControl.id}`, this.formatRequestBody(loadingControl));

    if (response.data) {
      const { data } = response;

      newLoadingControl = LoadingControlDTM.fromPlain({
        id: data.id,
        drayageSide: data.drayageSide,
        loadingTime: data.loadingTime,
        loadingMethod: data.handlingMethod,
        receivingMethod: data.receivingMethod,
      });
    }

    return newLoadingControl;
  };

  private formatRequestBody = (loadingControl: LoadingControlDTM) => ({
    ...(loadingControl.id ? { id: loadingControl.id } : {}),
    drayageSide: loadingControl.drayageSide,
    loadingTime: loadingControl.loadingTime || null,
    handlingMethod: loadingControl.loadingMethod,
    receivingMethod: loadingControl.receivingMethod || null,
  });

  private formatUrl = (shipmentId?: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/handling-controls`
  );
}
