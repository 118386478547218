import styled from 'styled-components';

import { SmallTextFootnoteDescription } from 'app-wrapper/view/fonts';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Dates = styled(FlexRow)`
  gap: 20px;
`;

export const Date = styled(FlexRow)`
  gap: 8px;
`;

export const Label = styled.span`
  min-width: fit-content;
  ${SmallTextFootnoteDescription}
`;
