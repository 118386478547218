import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';

import { Input } from 'app-wrapper/view/components';
import {
  Label,
  SpinnerLayout,
  Title,
  TitleContent,
  TitleContentSubTitle,
  TitleContentTitle,
} from 'shipment-operations/view/pages/ShipmentOverview/components/CancelShipmentModal/CancelShipmentModal.styled';

import { StyledModal } from './ManualReferenceNumberModal.styled';

interface CancelShipmentModalComponentProps {
  isPending: boolean;
  onClose: () => void;
  onOk: (shipmentId: string) => void;
  manualReferenceNumber: string;
  setManualReferenceNumber: (reference: string) => void;
}

export const ManualReferenceNumberModalComponent: FC<CancelShipmentModalComponentProps> = ({
  isPending,
  onOk,
  onClose,
  manualReferenceNumber,
  setManualReferenceNumber,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleOk = useCallback(() => {
    onOk(shipmentId);
  }, [onOk, shipmentId]);

  const handleManualReferenceNumberChange = useCallback((e) => {
    setManualReferenceNumber(e.target.value);
  }, [setManualReferenceNumber]);

  return (
    <StyledModal
      visible
      onCancel={onClose}
      onOk={handleOk}
      okText={t('Confirm')}
      cancelText={t('Close')}
      okButtonProps={{
        disabled: isPending || !manualReferenceNumber.length,
      }}
      cancelButtonProps={{
        disabled: isPending,
      }}
    >
      {isPending ? (
        <SpinnerLayout>
          <Spinner size="large" />
        </SpinnerLayout>
      ) : null}

      <Title>
        <TitleContent>
          <TitleContentTitle>
            {t('Confirm Shipment')}
          </TitleContentTitle>

          <TitleContentSubTitle data-class="cancel-shipment-subtitle">
            {t('Please specify the Booking Reference Number received from the carrier below.')}
          </TitleContentSubTitle>
        </TitleContent>
      </Title>

      <Label>
        {t('Booking Reference Number')} *
      </Label>

      <Input
        value={manualReferenceNumber}
        onChange={handleManualReferenceNumberChange}
        maxLength={18}
      />
    </StyledModal>
  );
};
