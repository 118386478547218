import React, { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { ShipmentComponent } from 'app-wrapper/view/pages/Overview/components/Dashboard/components/Shipment/Shipment.component';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { MissingTextValue, TableCell } from 'app-wrapper/view/guideline';
import { Link } from 'react-router-dom';
import { RouteNames } from 'app-wrapper/constants';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/es/table';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { EmptyStateForSection } from 'app-wrapper/view/components';
import ImportOutlined from '@ant-design/icons/ImportOutlined';

const ArrivingShipmentContainer = observer(() => {
  const { overviewArrivingShipmentStore } = useMobxStore();
  const { t } = useTranslation();

  const columns: ColumnType<object>[] = useMemo(() => [
    {
      title: t('Shipment'),
      key: 'id',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          <Link to={RouteNames.SHIPMENT_OVERVIEW(shipment.id)}>{shipment.shipmentName}</Link>

        </TableCell>
      ),
    },
    {
      title: t('Reference'),
      key: 'reference',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment?.reference || <MissingTextValue />}
        </TableCell>
      ),
    },
    {
      title: t('Carrier'),
      key: 'carrier',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.carrier}
        </TableCell>
      ),
    },
    {
      title: t('Destination'),
      key: 'origin',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.destination.address}
        </TableCell>
      ),
    },
    {
      title: t('ETA'),
      key: 'ETA',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.destination.estimatedDate?.getFormatDMMMHHmm()}
        </TableCell>
      ),
    },
  ], [t, overviewArrivingShipmentStore.initialState.shipments]);

  const redirectLink = `${RouteNames.SHIPMENTS_ACTIVE()}?status=IN_TRANSIT&sortBy=ARRIVAL`;

  return (
    <ShipmentComponent
      title={t('Arriving Next')}
      emptyState={(
        <EmptyStateForSection
          icon={(
            <ImportOutlined
              style={{ color: themesColors.emptyStateIconColor }}
            />
          )}
          headerText={t('No Arriving Shipments Yet')}
          contentText={t('List of arriving shipments here')}
        />
      )}
      shipments={overviewArrivingShipmentStore.shipmentsWithKey}
      columns={columns}
      redirectLink={redirectLink}
      isLoading={overviewArrivingShipmentStore.initialState.isLoading}
    />
  );
});

export { ArrivingShipmentContainer as ArrivingShipment };
