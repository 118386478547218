import { CSSProperties } from 'react';
import styled from 'styled-components';

export const ContentGap = styled.div.attrs((props: {
  justifyContent?: CSSProperties['justifyContent'],
  alignContent?: CSSProperties['alignContent'],
}) => ({
  justifyContent: props.justifyContent,
  alignContent: props.alignContent,
  'data-class': 'ContentGap',
}))`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-content: ${({ alignContent }) => alignContent || 'normal'};
  align-items: center;
  width: 100%;
`;
