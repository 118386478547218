import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserOutlined from '@ant-design/icons/UserOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';

import { EditButton } from 'app-wrapper/view/components';
import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { ValueBlockWithIcon, MissingTextValue } from 'app-wrapper/view/guideline';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { hasAccess } from 'app-wrapper/utils';

import {
  Content,
  Title,
  Block,
} from './SalesRepresentative.styled';

interface ISalesRepresentativeComponentProps {
  openSalesRepresentativeDrawer: () => void;
  salesManager?: OrganizationMemberDTM;
  salesRepresentativePermissions?: PermissionAttributePolicy;
}

export const SalesRepresentativeComponent: FC<ISalesRepresentativeComponentProps> = ({
  openSalesRepresentativeDrawer,
  salesManager,
  salesRepresentativePermissions,
}) => {
  const { t } = useTranslation();

  const isAbleToEdit = hasAccess(salesRepresentativePermissions, PermissionAttributePolicy.WRITE);

  return (
    <Content>
      <Title>
        {t('Sales Representative')}
      </Title>

      <Block>
        <ValueBlockWithIcon
          icon={<UserOutlined />}
          label={t('Name')}
          value={salesManager?.getFullName() || <MissingTextValue />}
        />
      </Block>

      <Block>
        <ValueBlockWithIcon
          icon={<MailOutlined />}
          label={t('Email')}
          value={salesManager?.email || <MissingTextValue />}
        />
      </Block>

      <EditButton
        disabled={!isAbleToEdit}
        onClick={openSalesRepresentativeDrawer}
        type="default"
      />
    </Content>
  );
};
