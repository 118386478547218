import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';

import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { EDrayageLoadingSide } from 'shipment-operations/constants';

import { LoadingControlsComponent } from 'shipment-operations/view/components/TransportationDrayage';

export const LoadingControls = observer(() => {
  const { shipmentLoadingControlsStore } = useMobxStore();
  const permissions = useSelector(R.selectors.shipment.getTransportationDrayagePermissions);

  return (
    <LoadingControlsComponent
      drayageSide={EDrayageLoadingSide.ORIGIN_DRAYAGE}
      loadingControls={shipmentLoadingControlsStore.getExportLoadingControl}
      loadingControlsPermission={permissions?.editExportLoadingControls}
      openEditDrawer={appUC.drawer.openDrayageLoadingControlsDrawer}
    />
  );
});
