import { action, computed } from 'mobx';

import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { DateDtm } from 'app-wrapper/models/dtm';
import { ShipmentScheduleDTM } from 'shipment-operations/models/dtm';
import { EDrayageLoadingSide } from 'shipment-operations/constants';

interface IEditSupplierScheduleState {
  schedule?: ShipmentScheduleDTM;
  scheduleId?: number;
  drayageSide?: EDrayageLoadingSide;
  cargoReadyDate?: InputStore<DateDtm | undefined>;
}

const initialState: IControllerState<IEditSupplierScheduleState> = {
  isLoading: true,
  scheduleId: undefined,
  drayageSide: undefined,
  schedule: undefined,
  cargoReadyDate: new InputStore(),
};

export class EditSupplierScheduleStore extends ControllerStore<IControllerState<IEditSupplierScheduleState>> {
  @action
  setSchedule(schedule?: ShipmentScheduleDTM) {
    this.state.schedule = schedule;
  }

  @action
  setScheduleId(id?: number) {
    this.state.scheduleId = id;
  }

  @action
  setDrayageSide(side?: EDrayageLoadingSide) {
    this.state.drayageSide = side;
  }

  @action
  setCargoReadyDate(input: InputStore<DateDtm | undefined>) {
    this.state.cargoReadyDate = input;
  }

  @computed
  get scheduleId() {
    return this.state.scheduleId;
  }

  @computed
  get getSchedule() {
    return this.state.schedule;
  }

  @computed
  get getCargoReadyDate() {
    return this.state.cargoReadyDate;
  }

  @computed
  get getDrayageSide() {
    return this.state.drayageSide;
  }
}

export const createEditSupplierScheduleStore = (_initialState?: IControllerState<IEditSupplierScheduleState>) => new EditSupplierScheduleStore(_initialState || initialState);
