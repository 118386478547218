import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserOutlined from '@ant-design/icons/UserOutlined';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  OrganizationPrimaryContactPrimaryContactData,
  OrganizationPrimaryContactPrimaryContactHeader,
  OrganizationPrimaryContactPrimaryContactIconBG,
  OrganizationPrimaryContactPrimaryContactItem,
  OrganizationPrimaryContactPrimaryContactRow,
  OrganizationPrimaryContactPrimaryContactTitle,
  OrganizationPrimaryContactPrimaryContactValue,
  OrganizationPrimaryContactPrimaryContactWrapper,
} from './OrganizationPrimaryContact.styled';

interface IOrganizationPrimaryContactContactProps {
  organization?: OrganizationDTM
}

export const OrganizationPrimaryContactContact: FC<IOrganizationPrimaryContactContactProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  return (
    <OrganizationPrimaryContactPrimaryContactWrapper>
      <OrganizationPrimaryContactPrimaryContactHeader>{t('PrimaryContact')}</OrganizationPrimaryContactPrimaryContactHeader>
      <OrganizationPrimaryContactPrimaryContactRow>
        <OrganizationPrimaryContactPrimaryContactItem>
          <OrganizationPrimaryContactPrimaryContactIconBG>
            <UserOutlined />
          </OrganizationPrimaryContactPrimaryContactIconBG>
          <OrganizationPrimaryContactPrimaryContactData>
            <OrganizationPrimaryContactPrimaryContactTitle>{t('Name')}</OrganizationPrimaryContactPrimaryContactTitle>
            <OrganizationPrimaryContactPrimaryContactValue>{organization?.primaryContact?.getFullName() || <NoDataLineItem />}</OrganizationPrimaryContactPrimaryContactValue>
          </OrganizationPrimaryContactPrimaryContactData>
        </OrganizationPrimaryContactPrimaryContactItem>
        <OrganizationPrimaryContactPrimaryContactItem>
          <OrganizationPrimaryContactPrimaryContactIconBG>
            <MailOutlined />
          </OrganizationPrimaryContactPrimaryContactIconBG>
          <OrganizationPrimaryContactPrimaryContactData>
            <OrganizationPrimaryContactPrimaryContactTitle>{t('Email')}</OrganizationPrimaryContactPrimaryContactTitle>
            <OrganizationPrimaryContactPrimaryContactValue>{organization?.primaryContact?.email || <NoDataLineItem />}</OrganizationPrimaryContactPrimaryContactValue>
          </OrganizationPrimaryContactPrimaryContactData>
        </OrganizationPrimaryContactPrimaryContactItem>
      </OrganizationPrimaryContactPrimaryContactRow>

      <OrganizationPrimaryContactPrimaryContactRow>
        <OrganizationPrimaryContactPrimaryContactItem>
          <OrganizationPrimaryContactPrimaryContactIconBG>
            <PhoneOutlined />
          </OrganizationPrimaryContactPrimaryContactIconBG>
          <OrganizationPrimaryContactPrimaryContactData>
            <OrganizationPrimaryContactPrimaryContactTitle>{t('Phone')}</OrganizationPrimaryContactPrimaryContactTitle>
            <OrganizationPrimaryContactPrimaryContactValue>{organization?.primaryContact?.phone || <NoDataLineItem />}</OrganizationPrimaryContactPrimaryContactValue>
          </OrganizationPrimaryContactPrimaryContactData>
        </OrganizationPrimaryContactPrimaryContactItem>
      </OrganizationPrimaryContactPrimaryContactRow>
    </OrganizationPrimaryContactPrimaryContactWrapper>
  );
};
