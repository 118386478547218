import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CityInputComponent } from './CityInput.component';

const CityInputContainer: FC = () => {
  const city = useSelector(R.selectors.shippingParties.getCity);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <CityInputComponent
      city={city}
      setCity={UC.shippingParties.setCity}
      error={requiredError}
      isAbleToEdit={permissions.isAbleToEditMainFormData}
    />
  );
};

const CityInputContainerCached = React.memo(CityInputContainer);

export {
  CityInputContainerCached as CityInput,
};
