import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentGap,
  ContentSection,
  ContentSectionContainer,
  ContentSectionTitle,
  FooterContainer, GapHorizontalContainerStyled,
  GapVerticalContainerStyled,
  InputTitle,
  SubPage,
  SubPageContent,
  TaskInfo,
  TasksSteps,
  Title,
  Wrapper,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { CustomModal } from 'shipment-operations/view/components';
import { HintBox, SkeletonSection, WarningBlock } from 'app-wrapper/view/components';
import { TextContainer } from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/Text';
import {
  DocumentsSectionsContainer,
} from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/DocumentsSections';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { FooterButtonsContainer } from 'shipment-operations/view/drawers/ChangeRequestDrawer/components/FooterButtons';
import { EChangeRequestType, EChangeRequestTypeText, ENotificationStatus } from 'app-wrapper/constants';
import { PermissionsDTM } from 'shipment-operations/view/drawers/ChangeRequestDrawer/permissions/Permissions.dtm';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { IconDiv } from 'app-wrapper/view/components/HintBox/HintBox.styled';
import { DialogSvg } from 'app-wrapper/view/icons';
import { Description } from './ChangeRequestDrawer.styled';

interface IChargesCostChangesComponentProps {
  isLoading: boolean
  onClose: () => void
  changeRequest: NotificationTaskContentDTM | undefined
  isNewRequest: boolean
  type: EChangeRequestType
  editMode: boolean
  steps: {
    title: string
    text?: string
  }[]
  titleText: string
  permissions: PermissionsDTM | undefined
}

const ChangeRequestDrawerComponent: FC<IChargesCostChangesComponentProps> = ({
  onClose, isLoading, changeRequest, editMode, type, steps, titleText, permissions, isNewRequest,
}) => {
  const { t } = useTranslation();

  const handleCloseDrawer = useCallback(() => {
    onClose();
  }, []);

  const title = useMemo(() => {
    if (isLoading) {
      return '';
    }
    return changeRequest ? titleText : t('Create Change Request');
  }, [isLoading, changeRequest]);

  return (
    <CustomModal
      title={title}
      onClose={handleCloseDrawer}
      contentStyles={{
        left: 'unset',
        width: '736px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
        padding: '0',
      }}
    >
      <SubPage>
        {isLoading
          ? (
            <Wrapper>
              <GapVerticalContainerStyled width="100%">
                <SkeletonSection height="85px" />
                <SkeletonSection height="183px" />
              </GapVerticalContainerStyled>
            </Wrapper>
          )
          : (
            <>
              <Wrapper>
                <GapVerticalContainerStyled width="100%">
                  { changeRequest
                    ? <TaskInfo notificationTask={changeRequest} shipmentIdClick={handleCloseDrawer} />
                    : (
                      <HintBox>
                        <GapVerticalContainerStyled>
                          <Title>
                            {t('How does a change request work?')}
                          </Title>
                          <span>
                            {t('You specify the changes that need to be made, and our operator will process your request within 24 hours. After reviewing and approving the changes, the operator will update the information, and you’ll see the changes reflected in the system.')}
                          </span>
                        </GapVerticalContainerStyled>
                      </HintBox>
                    )}
                  {changeRequest && hasAccess(permissions?.requestInformation, PermissionAttributePolicy.WRITE) && changeRequest.status === ENotificationStatus.TODO
                    ? (
                      <WarningBlock
                        type="info"
                        text={t('We have received your request and are still processing it. You will be notified once the shipment information is updated based on the details you provided below.')}
                      />
                    )
                    : null}
                  <SubPageContent>
                    <ContentSectionTitle>{t('Request Details')}</ContentSectionTitle>
                    <GapVerticalContainerStyled width="100%">

                      { changeRequest && !editMode
                        ? (
                          <Description>
                            <GapHorizontalContainerStyled>
                              <IconDiv>
                                <DialogSvg />
                              </IconDiv>
                              {changeRequest?.payload?.changeRequest?.description}
                            </GapHorizontalContainerStyled>
                          </Description>
                        )
                        : (
                          <ContentSection>
                            <GapVerticalContainerStyled width="100%">
                              <ContentGap>
                                <InputTitle title={t('Topic')}>
                                  <Title>{EChangeRequestTypeText[type]}</Title>
                                </InputTitle>
                              </ContentGap>
                              <ContentSectionContainer />
                              <ContentGap>
                                <ContentSectionContainer>
                                  <InputTitle title={t('Description')}>
                                    <TextContainer />
                                  </InputTitle>
                                </ContentSectionContainer>
                              </ContentGap>
                            </GapVerticalContainerStyled>
                          </ContentSection>
                        )}
                      <ContentSection>
                        <DocumentsSectionsContainer />
                      </ContentSection>
                    </GapVerticalContainerStyled>
                  </SubPageContent>
                  {
                    !isNewRequest
                    && hasAccess(permissions?.completeRequestButton, PermissionAttributePolicy.WRITE)
                    && changeRequest?.status && ![ENotificationStatus.DONE, ENotificationStatus.REJECTED].includes(changeRequest.status)
                      ? <TasksSteps steps={steps} />
                      : null
                  }
                </GapVerticalContainerStyled>
              </Wrapper>
              {changeRequest?.status && [ENotificationStatus.DONE, ENotificationStatus.REJECTED].includes(changeRequest.status)
                ? null
                : (
                  <Footer>
                    <FooterContainer>
                      <FooterButtonsContainer />
                    </FooterContainer>
                  </Footer>
                )}
            </>
          )}
      </SubPage>
    </CustomModal>
  );
};

export { ChangeRequestDrawerComponent };
