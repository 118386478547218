import { IPaginationState, PaginationStore } from 'proto/BaseMobxStore';
import { CompanyDTM } from 'shipment-operations/models/dtm';

const initialState = {
  isLoading: false,
  items: [],
};

export class CompaniesStore extends PaginationStore<CompanyDTM> {

}

export const createCompaniesStore = (_initialState?: IPaginationState<CompanyDTM>) => new CompaniesStore(_initialState || initialState);
