import React, {
  ChangeEvent, FC, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { useParams } from 'react-router';
import Spin from 'antd/lib/spin';

import {
  CarrierWrapper, InputsWrapper, InputTitle, StyledInput, SubTitle,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';

import { ShipmentCanceled } from 'shipment-operations/view/components';
import { SearchWrapper, LoaderWrapper } from 'shipment-operations/view/drawers/MaerskChanges/MaerskChanges.styled';

interface ISecondStepComponentProps {
  inttraNumber: string;
  onChangeInttraNumber: (value: string) => void;
  onSearchRef: (shipmentId: string) => void;
  isSearchLoading: boolean;
  searchError: boolean;
  searchErrorMsg?: string;
  savedShipmentId: string;
}

const SecondStepComponent: FC<ISecondStepComponentProps> = ({
  inttraNumber, onChangeInttraNumber, onSearchRef, isSearchLoading, searchError, searchErrorMsg,
  savedShipmentId,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();

  const inttraChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeInttraNumber(event.target.value);
    },
    [inttraNumber],
  );

  const onSearch = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onSearchRef(shipmentId || savedShipmentId);
    }
  }, [savedShipmentId]);

  return (
    <>
      <CarrierWrapper style={{ marginBottom: '20px' }}>
        <SubTitle>{t('carrier')}</SubTitle>
        <InputsWrapper>
          <InputTitle>{t('carrierReferenceNumber')}</InputTitle>
          <StyledInput disabled style={{ marginRight: '15px' }} />
          <InputTitle>{t('inttraReferenceNumber')}</InputTitle>
          <StyledInput
            style={{ marginRight: '15px', borderColor: inttraNumber ? '' : 'red' }}
            value={inttraNumber}
            onChange={inttraChangeHandler}
            id="inttraNumber"
          />
          {isSearchLoading ? (
            <LoaderWrapper>
              <Spin />
            </LoaderWrapper>
          ) : (
            <SearchWrapper onClick={onSearch}>
              <SearchOutlined style={{ color: 'white' }} />
            </SearchWrapper>
          )}
        </InputsWrapper>
      </CarrierWrapper>
      {searchError && (
        <ShipmentCanceled message={searchErrorMsg} />
      )}
    </>
  );
};

export { SecondStepComponent };
