import { IPaginationState, PaginationStore } from 'proto/BaseMobxStore';
import { ShippingPartyDTM } from 'shipment-operations/models/dtm';
import { EShippingPartyTypes } from 'shipment-operations/constants';

const initialState: IPaginationState<ShippingPartyDTM> = {
  isLoading: false,
  items: [],
};

export class ShippingPartiesStore extends PaginationStore<ShippingPartyDTM> {
  public getShippingPartyByRole(role: EShippingPartyTypes) {
    return this.items.find((shippingParty) => shippingParty.role === role);
  }
}

export const createShippingPartiesStore = (_initialState?: IPaginationState<ShippingPartyDTM>) => new ShippingPartiesStore(_initialState || initialState);
