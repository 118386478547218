import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ShipmentEditContainerController,
} from 'shipment-operations/controllers/ShipmentEditContainerController/ShipmentEditContainer.controller';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import { InputWithError } from 'app-wrapper/view/components/InputWithError/InputWithError.component';

export const ReferenceContainer = observer(() => {
  const controller = useCurrentController<ShipmentEditContainerController>();
  const store = useCurrentControllerStore<ShipmentEditContainerStore>();
  const { reference } = store.state.form;

  const handleOnChange = useCallback((e) => {
    controller.reference().onChange(e.target.value);
  }, []);

  const handleOnBlur = useCallback(() => {
    controller.reference().onBlur();
  }, []);

  return (
    <InputWithError
      value={reference?.value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      data-class="reference"
    />
  );
});
