import styled from 'styled-components';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';

import { Button as ButtonCommon } from 'app-wrapper/view/components';

export const Button = styled(ButtonCommon)`
  height: 32px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
`;

export const PlusIcon = styled(PlusOutlined)`
  svg {
    width: 15px;
    height: 15px;
    color: #314258;
  }
`;

export const MinusIon = styled(MinusOutlined)`
  svg {
    width: 15px;
    height: 15px;
    color: #314258;
  }
`;
