import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentGap, ContentSection,
  ContentSectionTitle,
  FooterContainer,
  GapVerticalContainerStyled, SubPage,
  SubPageContent,
  Wrapper,
  ContentSectionContainer,
  InfoCardComponent,
  GapHorizontalContainerStyled,
  InputTitle,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import { CustomModal } from 'shipment-operations/view/components';
import {
  Button, ServicesDivider,
} from 'app-wrapper/view/components';
import { AccountDrawer } from 'user-management/view/pages/Account/components';

import {
  CONTAINER_TYPES_TO_NAME,
} from 'shipment-operations/constants';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import { ContainerOutlinedSvg } from 'app-wrapper/view/icons';
import {
  DispatchOrderContainer,
} from 'shipment-operations/view/drawers/ShipmentEditContainer/components/DispatchOrder/DispatchOrder.container';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import {
  ReferenceContainer, DepartureDateContainer, ArrivalDateContainer, StatusContainer, NumberContainer, SelectCarrier,
} from './components';

interface IChargesCostChangesComponentProps {
  isLoading: boolean
  onClose: () => void
  onSave: () => void
  container: ContainerDTM
  isSubmitting: boolean
  drayageSide: EDrayageSide
}

const ShipmentEditContainerComponent: FC<IChargesCostChangesComponentProps> = ({
  onClose, container, isLoading, onSave, isSubmitting, drayageSide,
}) => {
  const { t } = useTranslation();

  const handleCloseDrawer = useCallback(() => {
    onClose();
  }, []);

  const handleSaveContainer = useCallback(() => {
    onSave();
  }, []);

  return (
    <CustomModal
      title={t('Edit Container')}
      onClose={handleCloseDrawer}
      contentStyles={{
        left: 'unset',
        width: '736px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
        padding: '0',
      }}
    >
      <SubPage>
        <Wrapper>
          <SubPageContent>
            {isLoading
              ? <>Loading</>
              : (
                <GapVerticalContainerStyled width="100%">
                  <ContentSection>
                    <GapVerticalContainerStyled width="100%">
                      <ContentGap justifyContent="space-between">
                        <ContentSectionTitle>
                          {t('Container Details')}
                        </ContentSectionTitle>
                        <div>
                          <GapHorizontalContainerStyled style={{ alignItems: 'center' }}>
                            <span>{t('Status')}</span>
                            <StatusContainer />
                          </GapHorizontalContainerStyled>
                        </div>
                      </ContentGap>
                      <ContentSectionContainer>
                        <ServicesDivider noMargin />
                      </ContentSectionContainer>
                      <ContentGap>
                        <ContentSectionContainer>
                          <InfoCardComponent
                            icon={<ContainerOutlinedSvg />}
                            title={t('Container Type')}
                            value={CONTAINER_TYPES_TO_NAME[container.type]}
                          />
                        </ContentSectionContainer>
                        <ContentSectionContainer>
                          <InfoCardComponent
                            icon={<BorderlessTableOutlined />}
                            title={t('Container ID')}
                            value={`${t('ID')}-${container.id}`}
                          />
                        </ContentSectionContainer>
                      </ContentGap>
                      <ContentSectionContainer>
                        <ServicesDivider noMargin />
                      </ContentSectionContainer>
                      <ContentGap>
                        <GapHorizontalContainerStyled>
                          <ContentSectionContainer>
                            <InputTitle title={t('Number')}>
                              <NumberContainer />
                            </InputTitle>
                          </ContentSectionContainer>
                          <ContentSectionContainer>
                            <InputTitle title={t('Carrier')}>
                              <SelectCarrier />
                            </InputTitle>
                          </ContentSectionContainer>
                        </GapHorizontalContainerStyled>
                      </ContentGap>
                    </GapVerticalContainerStyled>
                  </ContentSection>
                  <ContentSection>
                    <GapVerticalContainerStyled width="100%">
                      <ContentGap>
                        <ContentSectionTitle>
                          {t('Dates')}
                        </ContentSectionTitle>
                      </ContentGap>
                      <ContentGap>
                        <GapHorizontalContainerStyled>
                          <ContentSectionContainer>
                            <InputTitle title={t(drayageSide === EDrayageSide.DESTITATION_DRAYAGE ? 'Full Arrival' : 'Empty Arrival')}>
                              <ArrivalDateContainer />
                            </InputTitle>
                          </ContentSectionContainer>
                          <ContentSectionContainer>
                            <InputTitle title={t(drayageSide === EDrayageSide.DESTITATION_DRAYAGE ? 'Empty Departure' : 'Full Departure')}>
                              <DepartureDateContainer />
                            </InputTitle>
                          </ContentSectionContainer>
                        </GapHorizontalContainerStyled>
                      </ContentGap>
                    </GapVerticalContainerStyled>
                  </ContentSection>
                  <ContentSection>
                    <GapVerticalContainerStyled width="100%">
                      <ContentGap>
                        <ContentSectionTitle>
                          {t('References')}
                        </ContentSectionTitle>
                      </ContentGap>
                      <ContentGap>
                        <GapHorizontalContainerStyled>
                          <ContentSectionContainer>
                            <InputTitle title={t('Pickup Reference(s)')}>
                              <ReferenceContainer />
                            </InputTitle>
                          </ContentSectionContainer>
                          <ContentSectionContainer>
                            <InputTitle title={t('Dispatch Order')}>
                              <DispatchOrderContainer />
                            </InputTitle>
                          </ContentSectionContainer>
                        </GapHorizontalContainerStyled>
                      </ContentGap>
                    </GapVerticalContainerStyled>
                  </ContentSection>
                </GapVerticalContainerStyled>
              )}
          </SubPageContent>
        </Wrapper>
        <Footer>
          <FooterContainer>
            <Button
              type="default"
              onClick={handleCloseDrawer}
            >
              {t('Cancel')}
            </Button>
            <Button
              htmlType="submit"
              disabled={false}
              loading={isSubmitting}
              onClick={handleSaveContainer}
            >
              {t('Save')}
            </Button>
          </FooterContainer>
        </Footer>
      </SubPage>
      <AccountDrawer />
    </CustomModal>
  );
};

export { ShipmentEditContainerComponent };
