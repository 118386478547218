import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R as userManagementR } from 'user-management/repository';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { CommandCenterTasksCompletedShipmentComponent } from 'app-wrapper/view/pages/CommandCenterPage/CommandCenter';
import { RouteNames } from 'app-wrapper/constants';

const CommandCenterTasksCompletedContainer: FC = () => {
  const { shipmentId } = useParams<'shipmentId'>();

  const commandCenterIsHaveActiveNotification = useSelector(R.selectors.commandCenter.getCommandCenterIsHaveActiveNotification);
  const commandCenterCurrent = useSelector(R.selectors.commandCenter.getCommandCenterCurrent);
  const userManagementPermissions = useSelector(userManagementR.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const getCommandCenterCurrentAllTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentAllTasks);

  const getCommandCenterCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatus);
  const getCommandCenterCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatusAdd);
  const commandCenterActionCurrentIsClearAllFiltersShipmentTasks = useSelector(R.selectors.commandCenter.getCommandCenterActionCurrentIsClearAllFiltersShipmentTasks);
  const isVisibleAssigneeOrganization = userManagementPermissions?.isVisibleAssigneeOrganizationTasks;

  return (
    <CommandCenterTasksCompletedShipmentComponent
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterTasksShowOnlyUnread(shipmentId)}
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStart={UC.CommandCenter.onStartTaskCompletedPage(shipmentId)}
      nextPageTasks={UC.CommandCenter.nextPageTasksCompleted(shipmentId)}
      checkAnotherDownloadNextPageTask={UC.CommandCenter.checkAnotherDownloadNextPageTask}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterCompletionDates(shipmentId)}
      onResetReceivedDate={UC.CommandCenter.onResetFilterCompletionDates(shipmentId)}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterTasksCompletionDates()}
      onApplyModule={UC.CommandCenter.onApplyTasksModule(shipmentId)}
      onResetModule={UC.CommandCenter.onResetTasksModule(shipmentId)}
      onChangeGroupModule={UC.CommandCenter.onChangeTasksGroupModule()}
      onApplyAssignee={UC.CommandCenter.onApplyTasksAssignee(shipmentId)}
      onResetAssignee={UC.CommandCenter.onResetTasksAssignee(shipmentId)}
      onChangeGroupAssignee={UC.CommandCenter.onChangeTasksGroupAssignee()}
      onApplyAssigneeOrganization={UC.CommandCenter.onApplyTasksAssigneeOrganization(shipmentId)}
      onResetAssigneeOrganization={UC.CommandCenter.onResetTasksAssigneeOrganization(shipmentId)}
      onChangeGroupAssigneeOrganization={UC.CommandCenter.onChangeTasksGroupAssigneeOrganization()}
      onChangeGroupObject={UC.CommandCenter.onChangeTasksGroupObject()}
      onApplyObject={UC.CommandCenter.onApplyTasksObject(shipmentId)}
      onResetObject={UC.CommandCenter.onResetTasksObject(shipmentId)}
      onClearAllFilters={UC.CommandCenter.onClearAllTasksFilters(shipmentId)}
      treeDataObject={commandCenterCurrent?.filters?.filterTreeDataObject?.treeData}
      filters={commandCenterCurrent?.filters}
      isLoad={getCommandCenterCurrentStatus}
      isLoadAdd={getCommandCenterCurrentStatusAdd}
      isVisibleAssigneeOrganization={isVisibleAssigneeOrganization}
      allTasks={getCommandCenterCurrentAllTasks}
      hideHeader
      isVisibleClearAll={commandCenterActionCurrentIsClearAllFiltersShipmentTasks}
      titleLink2={RouteNames.SHIPMENT_CENTER_TASKS(shipmentId)}
      countTasks={commandCenterCurrent.totalElementsLater}
      isHaveNotification={commandCenterIsHaveActiveNotification}
      openTaskDrawer={UC.drawer.openTaskDrawer}
    />
  );
};

const CommandCenterTasksCompletedContainerCached = memo(CommandCenterTasksCompletedContainer);

export { CommandCenterTasksCompletedContainerCached as ShipmentTasksPageCompleted };
