import {
  action, computed, makeObservable, observable,
} from 'mobx';

export abstract class BaseMobxStore<T> {
  @observable
  initialState: T

  @observable
  state: T

  constructor(_initialState: T) {
    makeObservable(this);
    this.init(_initialState);
  }

  @action
  init(initialState: T) {
    this.initialState = initialState;
    this.state = initialState;
  }

  @action
  clear() {
    this.state = { ...this.initialState };
  }
}

export type IControllerState<T> = {
  isLoading: boolean;
} & T

export class ControllerStore<T> extends BaseMobxStore<IControllerState<T>> {
  @action
  setLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }
}

export interface IPaginationState<T extends {id?: string | number}> {
  isLoading: boolean;
  items: T[];
}

export abstract class PaginationStore<T extends {id?: string | number}> extends BaseMobxStore<IPaginationState<T>> {
  @action
  setLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  @action
  setItems(items: T[]) {
    this.state.items = items;
    this.state.isLoading = true;
  }

  @action
  updateItem(item: T) {
    const itemIndex = this.items.findIndex((_item) => _item.id === item.id);
    this.state.items[itemIndex] = item;
    this.state.items = [...this.state.items];
  }

  @action
  addItems(items: T[]) {
    this.state.items = items;

    const requestsIds = items?.reduce((prev: string[], item) => {
      prev.push(String(item.id));
      return prev;
    }, []) || [];

    this.state.items = [
      ...items,
      ...this.state.items
        ?.filter((itemContent) => !requestsIds
          .includes(`${itemContent.id}`)) || [],
    ];
  }

  @computed
  get items() {
    return this.state.items;
  }
}

export interface IPayloadState<T> {
  isLoading: boolean;
  payload: T | undefined;
}

export abstract class PayloadStore<T> extends BaseMobxStore<IPayloadState<T>> {
  @action
  setLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  @action
  setPayload(payload: T) {
    this.state.payload = payload;
    this.state.isLoading = true;
  }

  @computed
  get payload() {
    return this.state.payload;
  }
}
