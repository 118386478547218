import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';
import { ReferenceDTM } from 'app-wrapper/types/Reference';
import {
  ContainerDTM,
  ShipmentDeclarationFileDTM,
} from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.shipmentContainers;

const getLists = createSelector(
  localState,
  (state) => state.lists,
);

const getContainerList = createSelector(
  getLists,
  (lists) => {
    let containers: ContainerDTM[] = [];

    Object.values(lists).forEach((list) => {
      containers = containers.concat(list);
    });

    return containers;
  },
);

const getContainersCommonCount = createSelector(
  localState,
  (state) => {
    const counter = 0;

    return Object.values(state.lists).reduce((value, item) => (value + item.length), counter);
  },
);

const getLoadingState = createSelector(
  localState,
  (state) => state.isLoading,
);

const getRemoveLoadingState = createSelector(
  localState,
  (state) => state.isRemoveLoading,
);

const getUpdateLoadingState = createSelector(
  localState,
  (state) => state.isUpdateLoading,
);

const getSelectedContainerId = createSelector(
  localState,
  (state) => state.selectedContainerId,
);

const getSelectedContainerNumber = createSelector(
  localState,
  (state) => state.selectedContainerNumber,
);

const getContainerNumberList = createSelector(
  localState,
  (state) => state.containerNumberList,
);

const getContainerNumberManual = createSelector(
  localState,
  (state) => state.containerNumberManual,
);

const getSelectedContainerNumberFieldError = createSelector(
  localState,
  (state) => state.selectedContainerNumberFieldError,
);

const getSealNumber = createSelector(
  localState,
  (state) => state.sealNumber,
);

const getReference = createSelector(
  localState,
  (state) => state.reference,
);

const getSelectedContainerReferenceFieldError = createSelector(
  localState,
  (state) => state.referenceFieldError,
);

const getCargoList = createSelector(
  localState,
  (state) => state.cargoList,
);

const getChangedCargoList = createSelector(
  localState,
  (state) => state.changedCargoList,
);

const getSelectedContainer = createSelector(
  localState,
  (state) => {
    let containersList: ContainerDTM[] = [];

    Object.values(state.lists).forEach((item) => {
      containersList = containersList.concat(item);
    });

    return containersList.find((item) => item.id === state.selectedContainerId);
  },
);

const getSelectedContainerCargo = createSelector(
  getSelectedContainer,
  getCargoList,
  (selectedContainer, cargoList) => selectedContainer?.cargoItems.find((item) => (
    !!cargoList.find((cargo) => item.cargoId === cargo.baseId)
  )),
);

const getIsOwnContainer = createSelector(
  localState,
  getContainerList,
  (state, containerList) => containerList.some(({ ownContainer }) => ownContainer),
);

const getContainerNumberListForSelectedContainer = createSelector(
  localState,
  getSelectedContainer,
  getContainerList,
  getSelectedContainerNumber,
  (state, selectedContainer, containerList, containerNumber) => {
    if (!selectedContainer) {
      return undefined;
    }

    const filteredByContainerType = state.containerNumberList.filter((item) => item.type === selectedContainer.type);
    const filteredByUsed = filteredByContainerType.filter(
      (numberItem) => !containerList.find((container) => (numberItem.isManual ? (container.number === numberItem.number && container.number === selectedContainer.number) : container.number === numberItem.number)),
    );

    if (containerNumber) {
      return filteredByUsed;
    }

    const numberItemOfSelectedContainer = state.containerNumberList.find((item) => item.number === selectedContainer.number);
    if (!numberItemOfSelectedContainer) {
      return filteredByUsed;
    }

    filteredByUsed.push(numberItemOfSelectedContainer);

    return filteredByUsed;
  },
);

const getSelectedContainersType = createSelector(
  localState,
  (state) => state.selectedContainersType,
);

const getSeaworthyCertificate = createSelector(
  localState,
  (state) => state.seaworthyCertificate,
);

const getIMODeclarationsList = createSelector(
  localState,
  (state) => state.imoDeclarations,
);

const getIMODeclarationDocument = createSelector(
  localState,
  (state) => state.imoDeclaration,
);

const getSelectedContainerIMODeclaration = createSelector(
  localState,
  getContainerList,
  getSelectedContainerId,
  getIMODeclarationsList,
  (state, containers, containerId, declarations) => {
    const declaration = declarations?.find((_declaration) => _declaration?.containerId === Number(containerId));

    if (!declaration) {
      return null;
    }

    return ShipmentDeclarationFileDTM.fromPlain({
      name: declaration.document.name,
      uid: String(declaration.id),
      response: declaration.document,
      fileId: String(declaration.document.id),
    });
  },
);

const isContainerChanged = createSelector(
  getSelectedContainer,
  getChangedCargoList,
  getSelectedContainerNumber,
  getSealNumber,
  getSeaworthyCertificate,
  getSelectedContainerIMODeclaration,
  getIMODeclarationDocument,
  getReference,
  (
    container,
    changedCargoList,
    containerNumber,
    sealNumber,
    seaworthyCertificate,
    selectedContainerIMODeclaration,
    imoDeclaration,
    reference,
  ) => {
    let changed = false;

    if (!container) {
      return changed;
    }

    if (seaworthyCertificate && !container.seaworthyCertificate) {
      changed = true;

      return changed;
    }

    if (!seaworthyCertificate && container.seaworthyCertificate) {
      changed = true;

      return changed;
    }

    if (seaworthyCertificate && container.seaworthyCertificate && seaworthyCertificate.response && container.seaworthyCertificate.response && seaworthyCertificate.response.id !== container.seaworthyCertificate.response.id) {
      changed = true;

      return changed;
    }

    if ((selectedContainerIMODeclaration && !imoDeclaration) || (!selectedContainerIMODeclaration && imoDeclaration)) {
      changed = true;

      return changed;
    }

    if (selectedContainerIMODeclaration && imoDeclaration && selectedContainerIMODeclaration.fileId !== imoDeclaration.fileId) {
      changed = true;

      return changed;
    }

    if (containerNumber !== container.number) {
      changed = true;

      return changed;
    }

    if (sealNumber !== container.sealNumber) {
      changed = true;

      return changed;
    }

    if (reference !== String(container.references[0]?.value || '')) {
      changed = true;

      return changed;
    }

    // check for every used cargo item - used and saved
    container.cargoItems.forEach((containerCargoData) => {
      const usedCargo = changedCargoList.find((item) => item.baseId === containerCargoData.cargoId);

      if (!containerCargoData || !usedCargo) {
        changed = true;

        return;
      }

      if (
        containerCargoData.packagesNumber !== usedCargo.packagesNumberValue
        || containerCargoData.weight !== usedCargo.weightValue
        || containerCargoData.volume !== usedCargo.volumeValue
        || containerCargoData.value !== usedCargo.value
      ) {
        changed = true;
      }
    });

    // if we have updated data - no need to continue, just resources usage minimising
    if (changed) {
      return changed;
    }

    // if we have some unsaved changes - form data state is changed
    changedCargoList.forEach((usedCargo) => {
      const containerCargoData = container.cargoItems.find((item) => item.cargoId === usedCargo.baseId);

      if (!containerCargoData) {
        changed = true;
      }
    });

    return changed;
  },
);

const isContainerDataHaveErrors = createSelector(
  localState,
  getChangedCargoList,
  (state, changedCargoList) => {
    let haveErrors = false;

    if (state.selectedContainerNumberFieldError) {
      haveErrors = true;

      return haveErrors;
    }

    changedCargoList.forEach((changedCargo) => {
      if (changedCargo.packagesNumberValueError || changedCargo.weightValueError || changedCargo.volumeValueError) {
        haveErrors = true;
      }
    });

    return haveErrors;
  },
);

const getCommonChangedReferencesForSelectedContainer = createSelector(
  getChangedCargoList,
  (changedCargoList) => {
    let newRefList: ReferenceDTM[] = [];

    changedCargoList.forEach((item) => {
      newRefList = newRefList.concat(item.references as ReferenceDTM[]); // TODO: should be fixed with models refactoring for
    });

    return newRefList;
  },
);

const getCommonChangedPackagesNumber = createSelector(
  getChangedCargoList,
  (changedCargoList) => changedCargoList.reduce((count, item) => (count + +item.packagesNumberValue), 0),
);

const getCommonChangedWeight = createSelector(
  getChangedCargoList,
  (changedCargoList) => changedCargoList.reduce((count, item) => (count + +item.weightValue), 0),
);

const getCommonChangedVolume = createSelector(
  getChangedCargoList,
  (changedCargoList) => changedCargoList.reduce((count, item) => (count + +item.volumeValue), 0) || 0,
);

const getSeaworthyCertificateError = createSelector(
  localState,
  (state) => state.seaworthyCertificateError,
);

const getHasHazmat = createSelector(
  localState,
  (state) => state.hasHazmat,
);

const getIMODeclarationError = createSelector(
  localState,
  (state) => state.imoDeclarationError,
);

const getHasIMODeclarationChanged = createSelector(
  localState,
  getSelectedContainerIMODeclaration,
  getIMODeclarationDocument,
  (
    state,
    selectedContainerIMODeclaration,
    imoDeclaration,
  ) => {
    if ((selectedContainerIMODeclaration && !imoDeclaration) || (!selectedContainerIMODeclaration && imoDeclaration)) {
      return true;
    }

    return !!(selectedContainerIMODeclaration && imoDeclaration && selectedContainerIMODeclaration.fileId !== imoDeclaration.fileId);
  },
);

const getShouldDeleteOldIMODeclaration = createSelector(
  localState,
  getIMODeclarationDocument,
  getSelectedContainerIMODeclaration,
  (state, imoDeclaration, selectedContainerIMO) => (selectedContainerIMO && selectedContainerIMO?.fileId !== imoDeclaration?.fileId),
);

const isNewContainerCreated = createSelector(
  getContainerList,
  (containersList) => {
    let result = false;

    containersList.forEach((container) => {
      if (container.isVirtual) {
        result = true;
      }
    });

    return result;
  },
);

const getContainerNumberManualError = createSelector(
  localState,
  (state) => state.containerNumberManualError,
);

export const shipmentContainersSelectors = {
  getCargoList,
  getChangedCargoList,
  getCommonChangedPackagesNumber,
  getCommonChangedReferencesForSelectedContainer,
  getCommonChangedVolume,
  getCommonChangedWeight,
  getContainerList,
  getContainerNumberListForSelectedContainer,
  getContainersCommonCount,
  getHasHazmat,
  getHasIMODeclarationChanged,
  getIMODeclarationDocument,
  getIMODeclarationError,
  getIsOwnContainer,
  getLists,
  getLoadingState,
  getRemoveLoadingState,
  getSealNumber,
  getReference,
  getSelectedContainerReferenceFieldError,
  getSeaworthyCertificate,
  getSeaworthyCertificateError,
  getSelectedContainer,
  getSelectedContainerId,
  getSelectedContainerIMODeclaration,
  getSelectedContainerNumber,
  getContainerNumberList,
  getContainerNumberManual,
  getSelectedContainerNumberFieldError,
  getSelectedContainersType,
  getShouldDeleteOldIMODeclaration,
  getUpdateLoadingState,
  isContainerChanged,
  isContainerDataHaveErrors,
  isNewContainerCreated,
  getSelectedContainerCargo,
  getIMODeclarationsList,
  getContainerNumberManualError,
};
