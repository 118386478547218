import { v4 as uuidv4 } from 'uuid';

import { ShippingPartyDTM, ShippingPartyReference } from 'shipment-operations/models/dtm';
import { EShippingPartyTypes } from 'shipment-operations/constants';

const baseRef = ShippingPartyReference.fromPlain({
  id: uuidv4(),
  value: '',
});

const createInitialShippingPartyFormData = (role: EShippingPartyTypes) => ShippingPartyDTM.fromPlain({
  role,
  references: [baseRef],
  addressList: [],
  contactList: [],
  isSameAsMyOrganisation: false,
});

export const initialFormState = () => ({
  [EShippingPartyTypes.SHIPPER]: createInitialShippingPartyFormData(EShippingPartyTypes.SHIPPER),
  [EShippingPartyTypes.CONSIGNEE]: createInitialShippingPartyFormData(EShippingPartyTypes.CONSIGNEE),
  [EShippingPartyTypes.NOTIFY_PARTY]: createInitialShippingPartyFormData(EShippingPartyTypes.NOTIFY_PARTY),
  [EShippingPartyTypes.CUSTOMER]: createInitialShippingPartyFormData(EShippingPartyTypes.CUSTOMER),
  [EShippingPartyTypes.HOUSE_SHIPPER]: createInitialShippingPartyFormData(EShippingPartyTypes.HOUSE_SHIPPER),
  [EShippingPartyTypes.HOUSE_CONSIGNEE]: createInitialShippingPartyFormData(EShippingPartyTypes.HOUSE_CONSIGNEE),
  [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: createInitialShippingPartyFormData(EShippingPartyTypes.HOUSE_NOTIFY_PARTY),
  [EShippingPartyTypes.EXPORT_BROKER]: createInitialShippingPartyFormData(EShippingPartyTypes.EXPORT_BROKER),
  [EShippingPartyTypes.IMPORT_BROKER]: createInitialShippingPartyFormData(EShippingPartyTypes.IMPORT_BROKER),
  [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: createInitialShippingPartyFormData(EShippingPartyTypes.SECOND_NOTIFY_PARTY),
  [EShippingPartyTypes.FORWARDER_AGENT]: createInitialShippingPartyFormData(EShippingPartyTypes.FORWARDER_AGENT),
  [EShippingPartyTypes.DELIVERY_AGENT]: createInitialShippingPartyFormData(EShippingPartyTypes.DELIVERY_AGENT),
  [EShippingPartyTypes.ACCOUNT_HOLDER]: createInitialShippingPartyFormData(EShippingPartyTypes.ACCOUNT_HOLDER),
  [EShippingPartyTypes.ULTIMATE_CUSTOMER]: createInitialShippingPartyFormData(EShippingPartyTypes.ULTIMATE_CUSTOMER),
  [EShippingPartyTypes.OCEAN_CARRIER]: createInitialShippingPartyFormData(EShippingPartyTypes.OCEAN_CARRIER),
  [EShippingPartyTypes.BOOKING_AGENT]: createInitialShippingPartyFormData(EShippingPartyTypes.BOOKING_AGENT),
  [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: createInitialShippingPartyFormData(EShippingPartyTypes.BOOKING_AGENT),
  [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: createInitialShippingPartyFormData(EShippingPartyTypes.BOOKING_AGENT),
  [EShippingPartyTypes.CARGO_SUPPLIER]: createInitialShippingPartyFormData(EShippingPartyTypes.CARGO_SUPPLIER),
  [EShippingPartyTypes.CARGO_RECEIVER]: createInitialShippingPartyFormData(EShippingPartyTypes.CARGO_RECEIVER),
});
