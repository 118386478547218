import i18n from 'app-wrapper/i18n/i18n';
import { EStatusType } from 'app-wrapper/view/guideline';
import { action, computed } from 'mobx';
import moment from 'moment';

import { BaseMobxStore } from 'proto/BaseMobxStore';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  DocumentType,
  EAdditionalServiceStep,
  EServiceActivityType,
  ExportClearanceType,
} from 'shipment-operations/constants';
import {
  AdditionalServiceDTM,
  AdditionalServiceDocumentActivityDTM,
  AdditionalServiceInputActivityDTM,
  ContainerDocumentDTM, IAdditionalServiceResponsiblePartyDTM,
} from 'shipment-operations/models/dtm';
import { EShipmentOrganizationRole } from 'user-management/constants';
import { IAdditionalServicesState } from 'shipment-operations/models/states';

const initialState: IAdditionalServicesState = {
  isLoading: false,
  services: [],
  service: undefined,
  rolesInShipment: [],
  documentActivities: [],
  inputActivities: [],
  othersDocumentActivities: [],
  othersInputActivities: [],
  isManager: false,
  step: undefined,
  documentToUploadType: undefined,
  documentToUpload: null,
  initialDocumentToUpload: null,
  inputValue: '',
  inputError: undefined,
  isInEditMode: false,
  documentsToDeleteIds: [],
};

export class AdditionalServicesStore extends BaseMobxStore<IAdditionalServicesState> {
  @action
  setLoading(loading: boolean) {
    this.state.isLoading = loading;
  }

  @action
  setServices(services: AdditionalServiceDTM[]) {
    this.state.services = services;
  }

  @action
  setService(service?: AdditionalServiceDTM) {
    this.state.service = service;
  }

  @action
  addRoleInShipment(role: EShipmentOrganizationRole) {
    this.state.rolesInShipment.push(role);
  }

  @action
  setDocumentActivities(activities: AdditionalServiceDocumentActivityDTM[]) {
    this.state.documentActivities = activities;
  }

  @action
  setProvidedDocumentForActivity(documentType: DocumentType, providedDocument: ContainerDocumentDTM | null) {
    const tempActivities = [...this.state.documentActivities];
    const targetActivity = this.state.documentActivities.find(({ code }) => code === documentType);
    const targetActivityIndex = this.state.documentActivities.findIndex(({ code }) => code === documentType);

    tempActivities.splice(targetActivityIndex, 1, AdditionalServiceDocumentActivityDTM.fromPlain({
      ...targetActivity,
      type: targetActivity?.type as EServiceActivityType,
      providers: targetActivity?.providers as IAdditionalServiceResponsiblePartyDTM[],
      isRequired: targetActivity?.isRequired as boolean,
      code: targetActivity?.code as DocumentType,
      providedDocuments: providedDocument ? [providedDocument] : [],
    }));

    this.state.documentActivities = tempActivities;
  }

  @action
  setOthersDocumentActivities(activities: AdditionalServiceDocumentActivityDTM[]) {
    this.state.othersDocumentActivities = activities;
  }

  @action
  setInputActivities(activities: AdditionalServiceInputActivityDTM[]) {
    this.state.inputActivities = activities;
  }

  @action
  setOthersInputActivities(activities: AdditionalServiceInputActivityDTM[]) {
    this.state.othersInputActivities = activities;
  }

  @action
  setIsManager(isManager: boolean) {
    this.state.isManager = isManager;
  }

  @action
  setStep(step: EAdditionalServiceStep) {
    this.state.step = step;
  }

  @action
  setRolesInShipment(roles: EShipmentOrganizationRole[]) {
    this.state.rolesInShipment = roles;
  }

  @action addDocumentToDeleteId(id: number) {
    this.state.documentsToDeleteIds.push(id);
  }

  @action setDocumentsToDeleteIds(ids: number[]) {
    this.state.documentsToDeleteIds = ids;
  }

  @action cleanDocumentsToDeleteIds() {
    this.state.documentsToDeleteIds = [];
  }

  @action
  setProvidedDocumentForOtherActivity(documentType: DocumentType, providedDocument: ContainerDocumentDTM | null) {
    const tempActivities = [...this.state.othersDocumentActivities];
    const targetActivity = this.state.othersDocumentActivities.find(({ code }) => code === documentType);
    const targetActivityIndex = this.state.othersDocumentActivities.findIndex(({ code }) => code === documentType);

    tempActivities.splice(targetActivityIndex, 1, AdditionalServiceDocumentActivityDTM.fromPlain({
      ...targetActivity,
      type: targetActivity?.type as EServiceActivityType,
      providers: targetActivity?.providers as IAdditionalServiceResponsiblePartyDTM[],
      isRequired: targetActivity?.isRequired as boolean,
      code: targetActivity?.code as DocumentType,
      providedDocuments: providedDocument ? [providedDocument] : [],
    }));

    this.state.othersDocumentActivities = tempActivities;
  }

  @action
  setDocumentToUploadType(type: DocumentType) {
    this.state.documentToUploadType = type;
  }

  @action
  setDocumentToUpload(document: ContainerDocumentDTM | null) {
    this.state.documentToUpload = document;
  }

  @action
  setInitialDocumentToUpload(document: ContainerDocumentDTM | null) {
    this.state.initialDocumentToUpload = document;
  }

  @action
  setInputValue(value: string) {
    this.state.inputValue = value;
  }

  @action
  setInputError(error?: string) {
    this.state.inputError = error;
  }

  @action
  setIsInEditMode(isInEditMode: boolean) {
    this.state.isInEditMode = isInEditMode;
  }

  @computed
  get getInputITNActivity() {
    return this.state.inputActivities.find(({ code }) => code === ExportClearanceType.ITN);
  }

  @computed
  get getInputMRNActivity() {
    return this.state.inputActivities.find(({ code }) => code === ExportClearanceType.MRN);
  }

  @computed
  get getInputISFActivity() {
    return this.state.inputActivities.find(({ code }) => code === ExportClearanceType.ISF);
  }

  @computed
  get getEarliestDueDate() {
    const allRelatedActivities = ([
      ...this.state.documentActivities.filter((activity) => activity.isRequired && !activity.isCompleted()).map(({ dueDate }) => dueDate),
      ...this.state.inputActivities.filter((activity) => activity.isRequired && !activity.isCompleted()).map(({ dueDate }) => dueDate),
      ...(this.state.isManager ? [
        ...this.state.othersInputActivities.filter((activity) => activity.isRequired && !activity.isCompleted()).map(({ dueDate }) => dueDate),
        ...this.state.othersDocumentActivities.filter((activity) => activity.isRequired && !activity.isCompleted()).map(({ dueDate }) => dueDate),
      ] : []),
    ].filter((dueDate) => !!dueDate) as DateDtm[])
      .map((dueDate) => dueDate.getDateAsMomentWithOffset());

    if (!allRelatedActivities.length) {
      return undefined;
    }

    const earliestMomentDate = moment.min(allRelatedActivities);

    return DateDtm.fromPlain({
      date: earliestMomentDate.format(),
      offset: moment.parseZone(earliestMomentDate).utcOffset(),
    });
  }

  @computed
  get getAreRelatedActivitiesCompleted() {
    if (this.state.isManager) {
      return AdditionalServiceDocumentActivityDTM.calculateIfRequiredActivitiesCompleted(this.state.documentActivities)
        && AdditionalServiceDocumentActivityDTM.calculateIfRequiredActivitiesCompleted(this.state.othersDocumentActivities)
        && AdditionalServiceInputActivityDTM.calculateIfRequiredActivitiesCompleted(this.state.inputActivities)
        && AdditionalServiceInputActivityDTM.calculateIfRequiredActivitiesCompleted(this.state.othersInputActivities);
    }

    return AdditionalServiceDocumentActivityDTM.calculateIfRequiredActivitiesCompleted(this.state.documentActivities) && AdditionalServiceInputActivityDTM.calculateIfRequiredActivitiesCompleted(this.state.inputActivities);
  }

  @computed
  get getIsThereAnyRelatedActivities() {
    return !!this.state.documentActivities.length || !!this.state.inputActivities.length;
  }

  @computed
  get getAreAllActivitiesNotCompleted() {
    const activities = [
      ...this.state.documentActivities,
      ...this.state.othersDocumentActivities,
      ...this.state.inputActivities,
      ...this.state.othersInputActivities,
    ];

    return !activities.filter((activity) => activity.isCompleted()).length;
  }

  @computed
  get getServicesStatus() {
    if (this.getAreAllActivitiesNotCompleted) {
      return {
        statusText: i18n.t('Not Started'),
        status: EStatusType.NOT_ACTIVE,
      };
    }

    if (this.getAreRelatedActivitiesCompleted) {
      return {
        statusText: i18n.t('Completed'),
        status: EStatusType.COMPLETED,
      };
    }

    return {
      statusText: i18n.t('In Progress'),
      status: EStatusType.IN_PROGRESS,
    };
  }
}

export const createAdditionalServicesStore = (_initialState?: IAdditionalServicesState) => new AdditionalServicesStore(_initialState || initialState);
