import {
  IsOptional, IsString, IsEnum, IsDefined,
} from 'class-validator';
import { Type } from 'class-transformer';

import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';
import { ELocationType } from 'app-wrapper/types/LocationType';
import moment from 'moment/moment';
import { BaseDTM } from 'proto/BaseDTM';

export interface IWaypointDTM {
  name: string | null
  address: string
  countryCode?: string
  estimatedDate?: IDateDTM
  realDate?: IDateDTM
  type: ELocationType
  code?: string
  city?: string;
  countryName?: string;
}

export class WaypointDTM extends BaseDTM<IWaypointDTM> {
  @IsString()
  @IsOptional()
  name: string | null;

  @IsString()
  @IsDefined()
  address: string;

  @IsString()
  @IsOptional()
  countryCode: string;

  @IsOptional()
  @Type(() => DateDtm)
  estimatedDate?: DateDtm;

  @IsOptional()
  @Type(() => DateDtm)
  realDate?: DateDtm;

  @IsDefined()
  @IsEnum(ELocationType)
  type: ELocationType;

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  countryName?: string;

  utcOffset() {
    return this.estimatedDate?.offset;
  }

  public getTimezoneByLocation = () => {
    const { countryCode, city } = this;

    const timezones = moment.tz.zonesForCountry(countryCode);
    const timezone = timezones.find((tz) => tz.includes(city || '')) || timezones[0];
    const timeZoneOffset = moment.tz(timezone);

    return timeZoneOffset.format('Z');
  }

  public getContainerEarliestDate = () => {
    const offset = this.getTimezoneByLocation();
    const today = moment(moment.now());
    today.utcOffset(offset).add('days', 4);
    today.startOf('day');

    return today.format();
  }

  public getContainerLatestDate = () => {
    const offset = this.getTimezoneByLocation();
    const now = moment(moment.now());
    const latestDate = moment(now.add('days', 4)).utcOffset(offset);
    latestDate.endOf('day');

    return latestDate.add('weeks', 4).format();
  }
}
