import AntLayout from 'antd/es/layout';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';
import styled from 'styled-components';

export const Layout = styled(AntLayout).attrs({
  'data-class': 'Layout',
  id: 'Layout',
})`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0;
  overflow: hidden;
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
  ${CustomScrollbar}
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RowSpaceBetween = styled(FlexRow)`
  justify-content: space-between;
`;
