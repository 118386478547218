import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';

export const useIsPosthogLoaded = () => {
  const posthog = usePostHog();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    posthog.featureFlags.onFeatureFlags(() => {
      setLoaded(true);
    });

    // if flags not loaded
    setTimeout(() => {
      if (!loaded) {
        setLoaded(true);
      }
    }, 2000);
  }, []);

  return loaded;
};
