import { ChargeDTM } from 'shipment-operations/models/dtm';
import { GetChargesContract, GetChargesParams } from 'shipment-operations/models/contracts';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ContainerTypesConst, ShortChargeStatusEnum } from 'shipment-operations/constants';

export class ShipmentTransportationChargesService {
  public getCharges = async (shipmentId: string, params?: GetChargesParams) => {
    let result: ChargeDTM[] | null;

    try {
      const response = await apiWorker.requestGet<GetChargesContract[]>(`/billing-service/api/v1/shipments/${shipmentId}/charge-pairs`, {
        params,
      });
      const items = response.data;

      const parsedResponse = items.map((parsedRes) => {
        const parsedItem = (ChargeDTM.fromPlain({
          applied: parsedRes.applied,
          active: parsedRes.active,
          additional: parsedRes.additional,
          description: parsedRes.description,
          status: parsedRes.status,
          createdAt: parsedRes.createdAt,
          createdBy: parsedRes.createdBy,
          applianceRange: parsedRes.applianceRange ? {
            minValue: parsedRes.applianceRange?.minValue,
            maxValue: parsedRes.applianceRange?.maxValue,
          } : undefined,
          currency: parsedRes.currency,
          contract: parsedRes.contract && {
            id: parsedRes.contract.id,
            name: parsedRes.contract.name,
            number: parsedRes.contract.number,
            scac: parsedRes.contract.scac,
          },
          id: parsedRes.id,
          designation: parsedRes.designation,
          measureBy: parsedRes.measureBy,
          priceBy: parsedRes.priceBy,
          chargeCode: {
            occurrence: parsedRes.chargeCode.occurrence,
            description: parsedRes.chargeCode.code === 'MSC' ? (parsedRes.metadata?.originalDescription?.toLowerCase() || parsedRes.chargeCode.description) : parsedRes.chargeCode.description,
            type: parsedRes.chargeCode.type,
            code: parsedRes.chargeCode.code,
            subType: parsedRes.chargeCode.subType,
            loadType: parsedRes.chargeCode.loadType,
            mode: parsedRes.chargeCode.mode,
            status: parsedRes.chargeCode.status,
          },
          costPerUnit: parsedRes.costPerUnit || 0,
          numberOfUnits: parsedRes.numberOfUnits || 0,
          totalCost: parsedRes.totalCost || 0,
          subjectTo: parsedRes.subjectTo,
          container: parsedRes.container ? {
            number: parsedRes.container.number,
            type: ContainerTypesConst[parsedRes.container.type as keyof typeof ContainerTypesConst],
            id: parsedRes.container.id,
          } : undefined,
          buyCostPerUnit: parsedRes.buyCostPerUnit || 0,
          buyTotalCost: parsedRes.buyTotalCost || 0,
          buyNumberOfUnits: parsedRes.buyNumberOfUnits || 0,
          profitAmount: parsedRes.profitAmount,
          profitPercent: parsedRes.profitPercent,
          apBudget: parsedRes.apBudget && {
            balance: parsedRes.apBudget.balance,
            costPerUnit: parsedRes.apBudget.costPerUnit,
            numberOfUnits: parsedRes.apBudget.numberOfUnits,
            totalCost: parsedRes.apBudget.totalCost,
          },
          metadata: parsedRes.metadata && parsedRes.metadata.originalDescription ? parsedRes.metadata : undefined,
          arBudget: parsedRes.arBudget && {
            balance: parsedRes.arBudget.balance,
            costPerUnit: parsedRes.arBudget.costPerUnit,
            numberOfUnits: parsedRes.arBudget.numberOfUnits,
            totalCost: parsedRes.arBudget.totalCost,
          },
          transportationIds: parsedRes.transportationIds,
          rateId: parsedRes.rateId,
          apStatus: parsedRes.apStatus as ShortChargeStatusEnum,
          arStatus: parsedRes.arStatus as ShortChargeStatusEnum,
          creditor: parsedRes.creditor && {
            id: parsedRes.creditor.id,
            organizationId: parsedRes.creditor.organizationId,
            name: parsedRes.creditor.name,
            phone: parsedRes.creditor.phone,
            phone2: parsedRes.creditor.phone2,
            email: parsedRes.creditor.email,
            usci: parsedRes.creditor.usci,
            taxId: parsedRes.creditor.taxId,
          },
          debtor: parsedRes.debtor && {
            id: parsedRes.debtor.id,
            organizationId: parsedRes.debtor.organizationId,
            name: parsedRes.debtor.name,
            phone: parsedRes.debtor.phone,
            phone2: parsedRes.debtor.phone2,
            email: parsedRes.debtor.email,
            usci: parsedRes.debtor.usci,
            taxId: parsedRes.debtor.taxId,
          },
          documents: parsedRes.documents && parsedRes.documents.map((doc) => ({
            id: doc.id,
            name: doc.name,
          })),
          arVersions: parsedRes.arVersions && parsedRes.arVersions.map((version) => ({
            id: version.id,
            active: version.active,
            createdAt: version.createdAt,
            createdBy: version.createdBy,
            description: version.description,
            designation: version.designation,
            measureBy: version.measureBy,
            applied: version.applied,
            currency: version.currency,
            costPerUnit: version.costPerUnit,
            numberOfUnits: version.numberOfUnits,
            totalCost: version.totalCost,
            removed: version.removed,
            documents: version.documents && version.documents.map((doc) => ({
              id: doc.id,
              name: doc.name,
            })),
          })),
          apVersions: parsedRes.apVersions && parsedRes.apVersions.map((version) => ({
            id: version.id,
            active: version.active,
            createdAt: version.createdAt,
            createdBy: version.createdBy,
            description: version.description,
            designation: version.designation,
            measureBy: version.measureBy,
            applied: version.applied,
            currency: version.currency,
            costPerUnit: version.costPerUnit,
            numberOfUnits: version.numberOfUnits,
            totalCost: version.totalCost,
            removed: version.removed,
            documents: version.documents && version.documents.map((doc) => ({
              id: doc.id,
              name: doc.name,
            })),
          })),
        }));
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as ChargeDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }
}
