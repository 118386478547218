import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';

import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  EditProfileOrganizationContact, EditProfileOrganizationHeader, EditProfileOrganizationInput, EditProfileOrganizationSpaceColumn, EditProfileOrganizationSpaceInput, EditProfileOrganizationSpaceRow, EditProfileOrganizationTitle,
} from './EditProfileOrganizationContact.styled';

interface IEditProfileOrganizationContactProps {
  organization?: OrganizationDTM
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  onChangeUpdatePhone: (phone: string) => void
  onChangeUpdatePhone2: (phone2: string) => void
  onChangeUpdateEmail: (email: string) => void
  onChangeUpdateWebUrl: (webUrl: string) => void
}

export const EditProfileOrganizationContactComponent: FC<IEditProfileOrganizationContactProps> = (props) => {
  const {
    organization,
    organizationFieldsErrors,
    onChangeUpdatePhone,
    onChangeUpdatePhone2,
    onChangeUpdateEmail,
    onChangeUpdateWebUrl,
  } = props;
  const { t } = useTranslation();

  const onChangePhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePhone(event.target.value);
  }, []);

  const onChangePhone2 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePhone2(event.target.value);
  }, []);

  const onChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateEmail(event.target.value);
  }, []);

  const onChangeWebUrl = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateWebUrl(event.target.value);
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.message),
    [],
  );

  return (
    <EditProfileOrganizationContact>
      <EditProfileOrganizationHeader>
        {t('ContactInformation')}
      </EditProfileOrganizationHeader>
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Phone')} *`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.phone?.message && organizationFieldsErrors?.phone?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.phone?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.phone?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <EditProfileOrganizationInput
              value={organization?.phone}
              hasError={getHasErrorField(organizationFieldsErrors?.phone)}
              onChange={onChangePhone}
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Additional Phone')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.phone2?.message && organizationFieldsErrors?.phone2?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.phone2?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.phone2?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <EditProfileOrganizationInput
              value={organization?.phone2}
              hasError={getHasErrorField(organizationFieldsErrors?.phone2)}
              onChange={onChangePhone2}
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Email')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.email?.message && organizationFieldsErrors?.email?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.email?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.email?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <EditProfileOrganizationInput
              value={organization?.email}
              hasError={getHasErrorField(organizationFieldsErrors?.email)}
              onChange={onChangeEmail}
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('CorporateWebsiteURL')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.webUrl?.message && organizationFieldsErrors?.webUrl?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.webUrl?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.webUrl?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <EditProfileOrganizationInput
              value={organization?.webUrl?.getValidValue()}
              hasError={getHasErrorField(organizationFieldsErrors?.webUrl)}
              onChange={onChangeWebUrl}
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
    </EditProfileOrganizationContact>
  );
};
