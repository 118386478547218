import { DateDtm } from 'app-wrapper/models/dtm';
import { DatePickerMobxUseCase } from 'app-wrapper/usecases/DatePickerMobx.useCase';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { BaseController, controller } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { ShipmentScheduleDTM } from 'shipment-operations/models/dtm';
import { EditSupplierScheduleStore } from 'shipment-operations/repository/mobxStores/EditSupplierScheduleStore';
import { R } from 'shipment-operations/repository';

@controller
export class EditSuppliersScheduleDrawerController extends BaseController<EditSupplierScheduleStore> {
  onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    const schedule = this.mobxStore.shipmentSchedulesStore.items.find((_schedule) => _schedule.id === this.currentStore?.scheduleId);
    this.currentStore?.setSchedule(schedule);

    if (schedule) {
      this.currentStore?.setCargoReadyDate(new InputStore(schedule?.cargoReadyDate || undefined));
    }

    this.currentStore?.setLoading(false);
  }

  cargoReadyDate() {
    return new DatePickerMobxUseCase(this, this.currentStore?.state.cargoReadyDate as InputStore<DateDtm>);
  }

  public saveSchedule = async () => {
    this.currentStore?.setLoading(true);
    const schedule = this.currentStore?.getSchedule;
    const cargoReadyDate = this.currentStore?.getCargoReadyDate;

    if (schedule) {
      const updatedSchedule = await R.services.shipmentSchedulesService.updateSchedule(this.shipmentId(), ShipmentScheduleDTM.fromPlain({
        ...schedule,
        cargoReadyDate: cargoReadyDate?.value || schedule.cargoReadyDate,
      }));

      this.mobxStore.shipmentSchedulesStore.updateItem(updatedSchedule);
      this.currentStore?.setLoading(false);
      this.closeDrawer();

      return;
    }

    const drayageSide = this.currentStore?.getDrayageSide;

    if (drayageSide && cargoReadyDate?.value) {
      const newSchedule = await R.services.shipmentSchedulesService.createSchedule(this.shipmentId(), drayageSide, cargoReadyDate?.value);

      this.mobxStore.shipmentSchedulesStore.addItems([newSchedule]);
    }

    this.currentStore?.setLoading(false);
    this.closeDrawer();
  }

  closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  private shipmentId() {
    return (this.params as { shipmentId: string }).shipmentId;
  }
}
