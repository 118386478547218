import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { DateDtm } from 'app-wrapper/models/dtm';

import { TOceanRatesModalState } from 'monetary/models/states';
import { ECarrierSCAC, ERateTypes } from 'monetary/constants';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';

const initialState: TOceanRatesModalState = {
  isLoading: false,
  isOpen: false,
  rateType: undefined,
  effectiveDate: undefined,
  effectiveDateError: undefined,
  expirationDate: undefined,
  expirationDateError: undefined,
  originLocation: undefined,
  originLocationError: undefined,
  destinationLocation: undefined,
  destinationLocationError: undefined,
  isOriginLocationSearchListLoading: false,
  originLocationSearchList: [],
  isDestinationLocationSearchListLoading: false,
  destinationLocationSearchList: [],
  carrierScacList: [],
  drayageDrawerRateType: undefined,
};

export const oceanRatesSlice = createSlice({
  name: 'oceanRatesModal',
  initialState,
  reducers: {
    setOriginLocationSearchListLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOriginLocationSearchListLoading: payload,
    }),
    setDestinationLocationSearchListLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDestinationLocationSearchListLoading: payload,
    }),
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setOpen: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOpen: payload,
    }),
    setDrayageDrawerRateType: (state, { payload }: PayloadAction<ERateTypes | undefined>) => ({
      ...state,
      drayageDrawerRateType: payload,
    }),
    setRateType: (state, { payload }: PayloadAction<ERateTypes>) => ({
      ...state,
      rateType: payload,
    }),
    clearRateType: (state) => ({
      ...state,
      rateType: undefined,
    }),
    setEffectiveDate: (state, { payload }: PayloadAction<DateDtm | undefined>) => ({
      ...state,
      effectiveDate: payload,
    }),
    clearEffectiveDate: (state) => ({
      ...state,
      effectiveDate: undefined,
    }),
    setEffectiveDateError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      effectiveDateError: payload,
    }),
    clearEffectiveDateError: (state) => ({
      ...state,
      effectiveDateError: undefined,
    }),
    setExpirationDate: (state, { payload }: PayloadAction<DateDtm>) => ({
      ...state,
      expirationDate: payload,
    }),
    clearExpirationDate: (state) => ({
      ...state,
      expirationDate: undefined,
    }),
    setExpirationDateError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      expirationDateError: payload,
    }),
    clearExpirationDateError: (state) => ({
      ...state,
      expirationDateError: undefined,
    }),
    setOriginLocation: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      originLocation: payload,
    }),
    clearOriginLocation: (state) => ({
      ...state,
      originLocation: undefined,
    }),
    setOriginLocationError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      originLocationError: payload,
    }),
    clearOriginLocationError: (state) => ({
      ...state,
      originLocationError: undefined,
    }),
    setDestinationLocation: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      destinationLocation: payload,
    }),
    clearDestinationLocation: (state) => ({
      ...state,
      destinationLocation: undefined,
    }),
    setDestinationLocationError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      destinationLocationError: payload,
    }),
    clearDestinationLocationError: (state) => ({
      ...state,
      destinationLocationError: undefined,
    }),
    setOriginLocationSearchList: (state, { payload }: PayloadAction<FreightSelectFieldDTM[]>) => ({
      ...state,
      originLocationSearchList: payload,
    }),
    setDestinationLocationSearchList: (state, { payload }: PayloadAction<FreightSelectFieldDTM[]>) => ({
      ...state,
      destinationLocationSearchList: payload,
    }),
    setCarrierScacList: (state, { payload }: PayloadAction<ECarrierSCAC[]>) => ({
      ...state,
      carrierScacList: payload,
    }),
  },
});

export const oceanRatesModalActions = oceanRatesSlice.actions;
export const oceanRatesModalReducer = oceanRatesSlice.reducer;
