import React, { FC, memo } from 'react';
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import FlagOutlined from '@ant-design/icons/FlagOutlined';

import { VerticalFormItemSvg, VerticalLongDashedSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  PaymentsTradeStatusContent, PaymentsTradeStatusItem, PaymentsTradeStatusItemIcon, PaymentsTradeStatusItemText, PaymentsTradeStatusWrap,
} from './PaymentsTradeStatus.styled';

interface IPaymentsTradeStatusContainerProps {
  isFull?: boolean
  moreElements?: boolean
  textOption?: string
  firstStep?: string
  secondStep?: string
  thirdsStep?: string
  isActiveTextOption?: boolean
  isActiveFirstStep?: boolean
  isActiveSecondStep?: boolean
  isActiveThirdsStep?: boolean
}

const PaymentsTradeStatusContainer: FC<IPaymentsTradeStatusContainerProps> = (props) => {
  const {
    isFull,
    moreElements,
    textOption,
    firstStep,
    secondStep,
    thirdsStep,
    isActiveTextOption,
    isActiveFirstStep,
    isActiveSecondStep,
    isActiveThirdsStep,
  } = props;

  return (
    <PaymentsTradeStatusWrap>
      <PaymentsTradeStatusContent isFull={isFull}>
        <PaymentsTradeStatusItem>
          {moreElements && (
            <PaymentsTradeStatusItemIcon isActive={isActiveTextOption}>
              <DollarCircleOutlined />
            </PaymentsTradeStatusItemIcon>
          )}
          <PaymentsTradeStatusItemText isFirst isActive={isActiveTextOption}>
            {textOption || ''}
          </PaymentsTradeStatusItemText>
          <PaymentsTradeStatusItemIcon>
            <VerticalFormItemSvg rotate={-90} />
          </PaymentsTradeStatusItemIcon>

          {moreElements && (
            <>
              <PaymentsTradeStatusItemIcon>
                <FlagOutlined />
              </PaymentsTradeStatusItemIcon>
              <PaymentsTradeStatusItemIcon svgColor={themesColors.neutralBranding6}>
                <VerticalLongDashedSvg />
              </PaymentsTradeStatusItemIcon>
            </>
          )}
        </PaymentsTradeStatusItem>

        <PaymentsTradeStatusItem>
          <PaymentsTradeStatusItemIcon isActive={isActiveFirstStep}>
            {isActiveFirstStep ? (<CheckCircleOutlined />) : (<CloseCircleOutlined />)}
          </PaymentsTradeStatusItemIcon>
          <PaymentsTradeStatusItemText isActive={isActiveFirstStep}>
            {firstStep || ''}
          </PaymentsTradeStatusItemText>
          <PaymentsTradeStatusItemIcon svgColor={themesColors.neutralBranding6}>
            <VerticalLongDashedSvg />
          </PaymentsTradeStatusItemIcon>
        </PaymentsTradeStatusItem>

        <PaymentsTradeStatusItem>
          <PaymentsTradeStatusItemIcon isActive={isActiveSecondStep}>
            {isActiveSecondStep ? (<CheckCircleOutlined />) : (<CloseCircleOutlined />)}
          </PaymentsTradeStatusItemIcon>
          <PaymentsTradeStatusItemText isActive={isActiveSecondStep}>
            {secondStep || ''}
          </PaymentsTradeStatusItemText>
          <PaymentsTradeStatusItemIcon svgColor={themesColors.neutralBranding6}>
            <VerticalLongDashedSvg />
          </PaymentsTradeStatusItemIcon>
        </PaymentsTradeStatusItem>

        <PaymentsTradeStatusItem>
          <PaymentsTradeStatusItemIcon isActive={isActiveThirdsStep}>
            {isActiveThirdsStep ? (<CheckCircleOutlined />) : (<CloseCircleOutlined />)}
          </PaymentsTradeStatusItemIcon>
          <PaymentsTradeStatusItemText isActive={isActiveThirdsStep}>
            {thirdsStep || ''}
          </PaymentsTradeStatusItemText>

          {moreElements && (
            <PaymentsTradeStatusItemIcon>
              <EnvironmentOutlined />
            </PaymentsTradeStatusItemIcon>
          )}
        </PaymentsTradeStatusItem>

      </PaymentsTradeStatusContent>

    </PaymentsTradeStatusWrap>
  );
};

const PaymentsTradeStatusContainerCache = memo(PaymentsTradeStatusContainer);

export { PaymentsTradeStatusContainerCache as PaymentsTradeStatusContainer };
