import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ServerError } from 'app-wrapper/types/ServerError';

import {
  AddressDTM,
  CompanyAddressDTM,
  CompanyContactDTM,
  CompanyDTM,
  ContactDTM,
  FullCompanyDTM,
  ICompanyAddressDTM,
  ICompanyContactDTM,
} from 'shipment-operations/models/dtm';
import {
  IGetAddressResponse,
  IGetCompanyResponse,
  IGetContactResponse,
  TGetAddressListResponse,
  TGetContactListResponse,
  TGetFullCompanyListResponse,
} from 'shipment-operations/models/contracts';
import { NetworkErrorProto } from 'app-wrapper/models/errors';
import {
  IPostAddressContactPersonRequest,
  IPostCompanyListAddressRequest,
  TPostCompanyListRequest, TPostCompanyListResponse, TPutCompanyListRequest, TPutCompanyListResponse,
} from 'user-management/models/contracts';
import { CompanyCollectedDTM } from 'user-management/models/dtm';

export class ContactsService {
  urlBase = '/contact-service/api/v1/companies'

  public getCompanyList = async () => {
    let list: CompanyDTM[] = [];

    const response = await apiWorker.requestGetBySchema(`${this.urlBase}?size=1000` as '/api/v1/companies');

    const parsedResponse = response.data?.content?.map((item) => {
      const parsedItem = CompanyDTM.fromPlain({
        id: item.id as number,
        name: item.name,
        phone: item.phone,
        phone2: item.phone,
        email: item.email,
        taxId: item.taxId,
        isPrimary: item.isPrimary,
        addresses: item.addresses ? item.addresses.map((address) => CompanyAddressDTM.fromPlain({
          id: address.id as number,
          customId: String(address.id),
          country: address.country,
          state: address.state,
          city: address.city,
          address1: address.address1,
          address2: address.address2 || '',
          postalCode: address.postalCode,
          closestPort: address.closestPort,
          isPrimary: address.primary,
          company: '',
        })) : [],
        contacts: item.contacts ? item.contacts.map((contact) => CompanyContactDTM.fromPlain({
          id: contact.id as number,
          customId: '',
          fullName: contact.fullName,
          email: contact.email || '',
          phone: contact.phone || '',
          phone2: contact.phone2 || '',
          primary: contact.primary,
          primaryDefault: undefined,
        })) : undefined,
      });
      if (!parsedItem.isValid()) {
        console.error('Data from API does not match with contract');
      }
      return parsedItem;
    });

    list = parsedResponse?.filter((el) => el !== null) as CompanyDTM[];

    return list || [];
  }

  public getFullCompanyList = async () => {
    let list: FullCompanyDTM[] = [];

    const response = await apiWorker.requestGet<TGetFullCompanyListResponse>(`${this.urlBase}?size=1000`);

    if (!response.data?.content.length) {
      return list;
    }

    const parsedResponse = response.data?.content.map((item) => {
      const parsedItem = FullCompanyDTM.fromPlain({
        id: item.id,
        name: item.name,
        phone: item.phone,
        phone2: item.phone,
        email: item.email,
        taxId: item.taxId,
        addresses: item.addresses || [],
        contacts: item.contacts || [],
      });

      if (!parsedItem.isValid()) {
        console.error('Data from API does not match with contract');
      }

      return parsedItem;
    });

    list = parsedResponse.filter((el) => el !== null) as FullCompanyDTM[];

    return list;
  }

  public getContactList = async (companyId: number) => {
    let list: ContactDTM[] = [];

    try {
      const response = await apiWorker.requestGet<TGetContactListResponse>(`${this.urlBase}/${companyId}/contacts`);

      if (response.status !== 200) {
        return list;
      }

      const parsedResponse = response.data.map((item) => {
        const parsedItem = ContactDTM.fromPlain({
          id: item.id,
          fullName: item.fullName,
          email: item.email,
          phone: item.phone,
          phone2: item.phone2,
        });

        if (!parsedItem.isValid()) {
          console.error('Get address list API contract errors: ', parsedItem.validate());
        }
        return parsedItem;
      });
      list = parsedResponse.filter((el) => el !== null) as ContactDTM[];
    } catch (e) {
      throw new Error('Contact list getting error');
    }

    return list;
  }

  public getAddressList = async (companyId: number) => {
    let list: AddressDTM[] = [];

    try {
      const response = await apiWorker.requestGet<TGetAddressListResponse>(`${this.urlBase}/${companyId}/addresses`);

      if (!response.data.length) {
        return list;
      }

      const parsedResponse = response.data.map((item) => AddressDTM.fromPlain({
        id: item.id,
        country: item.country,
        state: item.state,
        city: item.city,
        address1: item.address1,
        address2: item.address2,
        postalCode: item.postalCode,
        closestPort: item.closestPort,
        contacts: item.contacts?.map((itemContact) => ContactDTM.fromPlain({
          id: itemContact.id,
          fullName: itemContact.fullName,
          email: itemContact.email,
          phone: itemContact.phone,
          phone2: itemContact.phone2,
        })),
      }));
      list = parsedResponse.filter((el) => el !== null) as AddressDTM[];
    } catch (e) {
      throw new Error('Address list getting error');
    }

    return list;
  }

  public getCompany = async (companyId: number) => {
    let company: CompanyDTM | null = null;

    try {
      const response = await apiWorker.requestGet<IGetCompanyResponse>(`${this.urlBase}/${companyId}`);
      const { data } = response;

      company = CompanyDTM.fromPlain({
        id: data.id,
        name: data.name,
        phone: data.phone,
        phone2: data.phone,
        email: data.email,
        taxId: data.taxId,
      });
    } catch (e) {
      throw new Error('Company getting error');
    }

    return company;
  }

  public getCompanyContactById = async (companyId: number, addressId: string) => {
    let contacts: ContactDTM[] | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<IGetContactResponse[]>(`${this.urlBase}/${companyId}/contacts`, {
        params: {
          addressId,
        },
      });

      const response = rawResponse.data;

      contacts = response.map((itemContact) => {
        const newShipmentPreviewDtm: ContactDTM = ContactDTM.fromPlain({
          id: itemContact.id || 0,
          fullName: itemContact.fullName || '',
          email: itemContact.email || '',
          phone: itemContact.phone || '',
          phone2: itemContact.phone2 || '',
          primary: itemContact.primary,
        });

        return newShipmentPreviewDtm;
      });
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'getCompanyContactById getting error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'getCompanyAddressById getting error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return contacts;
  }

  public getContact = async (companyId: number, contactId: number) => {
    let contact: ContactDTM | null = null;

    try {
      const response = await apiWorker.requestGet<IGetContactResponse>(`${this.urlBase}/${companyId}/contacts/${contactId}`);
      const { data } = response;

      contact = ContactDTM.fromPlain({
        id: data.id,
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        phone2: data.phone2,
        primary: data.primary,
      });
    } catch (e) {
      throw new Error('Contact getting error');
    }

    return contact;
  }

  public getAddress = async (companyId: number, addressId: number) => {
    let address: AddressDTM | null = null;

    try {
      const response = await apiWorker.requestGet<IGetAddressResponse>(`${this.urlBase}/${companyId}/addresses/${addressId}`);
      const { data } = response;

      address = AddressDTM.fromPlain({
        id: data.id,
        country: data.country,
        state: data.state,
        city: data.city,
        address1: data.address1,
        address2: data.address2,
        postalCode: data.postalCode,
        closestPort: data.closestPort,
        contacts: data.contacts.map((contact) => ContactDTM.fromPlain({
          ...contact,
          phone2: contact.phone2 || undefined,
        })),
      });
    } catch (e) {
      throw new Error('Address getting error');
    }

    return address;
  }

  public putCompanyList = async (companyId: number | string, companyData: TPutCompanyListRequest) => {
    let companyDTM: CompanyDTM | undefined;
    const addresses: CompanyAddressDTM[] = [];
    const contacts: CompanyContactDTM[] = [];
    let companyCollected: CompanyCollectedDTM = CompanyCollectedDTM.fromPlain({
      companyList: [],
      addresses,
      contacts,
    });

    try {
      const rawResponse = await apiWorker.requestPut<TPutCompanyListResponse>(`${this.urlBase}/${companyId}`, companyData);

      const response = rawResponse.data;

      const addressIds: string[] = [];
      const contactsIds: string[] = [];

      response?.contacts?.forEach((itemContact) => {
        const contactId = uuidv4();
        const newShipmentPreviewDtm: CompanyContactDTM = CompanyContactDTM.fromPlain({
          id: itemContact.id,
          fullName: itemContact.fullName,
          email: itemContact.email,
          phone: itemContact.phone,
          phone2: itemContact.phone2,
          primary: itemContact.primary,
          customId: uuidv4(),
        });

        contactsIds.push(contactId);
        contacts.push(newShipmentPreviewDtm);
      });

      response?.addresses?.forEach((itemAddress) => {
        const addressId = uuidv4();
        const contactId = uuidv4();

        const primaryContact: CompanyContactDTM[] | null = itemAddress.contacts
          ?.filter((itemAddressContact) => itemAddressContact.primary)?.map((itemAddressContact) => {
            const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
              id: itemAddressContact.id,
              customId: contactId,
              fullName: itemAddressContact.fullName,
              email: itemAddressContact.email,
              phone: itemAddressContact.phone,
              phone2: itemAddressContact.phone2,
            });

            contactsIds.push(contactId);
            return companyContactDTM;
          }) || null;

        const companyAddressDTM: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
          id: itemAddress.id,
          customId: addressId,
          country: itemAddress.country,
          state: itemAddress.state,
          city: itemAddress.city,
          address1: itemAddress.address1,
          address2: itemAddress.address2,
          postalCode: itemAddress.postalCode,
          closestPort: itemAddress.closestPort,
          company: itemAddress.company,
          isPrimary: itemAddress.primary,
          primaryContact: primaryContact?.[0] && {
            id: primaryContact?.[0]?.id,
            email: primaryContact?.[0]?.email,
            fullName: primaryContact?.[0]?.fullName,
            phone: primaryContact?.[0]?.phone,
            phone2: primaryContact?.[0]?.phone2,
          },
        });

        addressIds.push(addressId);
        addresses.push(companyAddressDTM);
      });

      companyDTM = CompanyDTM.fromPlain({
        id: response.id,
        customId: uuidv4(),
        name: response.name,
        phone: response.phone,
        phone2: response.phone,
        email: response.email,
        usci: response.usci || undefined,
        taxId: response.taxId || undefined,
        addressesIds: addressIds,
        contactPersonsIds: contactsIds,
        isPrimary: response.isPrimary,
      });

      companyCollected = CompanyCollectedDTM.fromPlain({
        companyList: [companyDTM],
        addresses,
        contacts,
      });
    } catch (e: unknown) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'putCompanyList error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'putCompanyList error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return companyCollected;
  }

  public postCompanyList = async (companyData: TPostCompanyListRequest) => {
    let companyDTM: CompanyDTM | undefined;
    const addresses: CompanyAddressDTM[] = [];
    const contacts: CompanyContactDTM[] = [];
    let companyCollected: CompanyCollectedDTM = CompanyCollectedDTM.fromPlain({
      companyList: [],
      addresses,
      contacts,
    });

    try {
      const rawResponse = await apiWorker.requestPost<TPostCompanyListResponse>(`${this.urlBase}`, companyData);

      const response = rawResponse.data;

      const addressIds: string[] = [];
      const contactsIds: string[] = [];

      response?.contacts?.forEach((itemContact) => {
        const contactId = uuidv4();
        const newShipmentPreviewDtm: CompanyContactDTM = CompanyContactDTM.fromPlain({
          id: itemContact.id,
          fullName: itemContact.fullName,
          email: itemContact.email,
          phone: itemContact.phone,
          phone2: itemContact.phone2,
          primary: itemContact.primary,
          customId: uuidv4(),
        });

        contactsIds.push(contactId);
        contacts.push(newShipmentPreviewDtm);
      });

      response?.addresses?.forEach((itemAddress) => {
        const addressId = uuidv4();
        const contactId = uuidv4();

        const primaryContact: CompanyContactDTM[] | null = itemAddress.contacts
          ?.filter((itemAddressContact) => itemAddressContact.primary)?.map((itemAddressContact) => {
            const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
              id: itemAddressContact.id,
              customId: contactId,
              fullName: itemAddressContact.fullName,
              email: itemAddressContact.email,
              phone: itemAddressContact.phone,
              phone2: itemAddressContact.phone2,
            });

            contactsIds.push(contactId);
            return companyContactDTM;
          }) || null;

        const companyAddressDTM: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
          id: itemAddress.id,
          customId: addressId,
          country: itemAddress.country,
          state: itemAddress.state,
          city: itemAddress.city,
          address1: itemAddress.address1,
          address2: itemAddress.address2,
          postalCode: itemAddress.postalCode,
          closestPort: itemAddress.closestPort,
          company: itemAddress.company,
          isPrimary: itemAddress.primary,
          primaryContact: primaryContact?.[0] && {
            id: primaryContact?.[0]?.id,
            email: primaryContact?.[0]?.email,
            fullName: primaryContact?.[0]?.fullName,
            phone: primaryContact?.[0]?.phone,
            phone2: primaryContact?.[0]?.phone2,
          },
        });

        addressIds.push(addressId);
        addresses.push(companyAddressDTM);
      });

      companyDTM = CompanyDTM.fromPlain({
        id: response.id,
        customId: uuidv4(),
        name: response.name,
        phone: response.phone,
        phone2: response.phone,
        email: response.email,
        usci: response.usci || undefined,
        taxId: response.taxId || undefined,
        addressesIds: addressIds,
        contactPersonsIds: contactsIds,
        isPrimary: response.isPrimary,
      });

      companyCollected = CompanyCollectedDTM.fromPlain({
        companyList: [companyDTM],
        addresses,
        contacts,
      });
    } catch (e: unknown) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'postCompanyList error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'postCompanyList error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return companyCollected;
  }

  public postCompany = async (companyData: TPostCompanyListRequest) => {
    let address: CompanyAddressDTM | undefined;
    let contact: CompanyContactDTM | undefined;

    const rawResponse = await apiWorker.requestPost<TPostCompanyListResponse>(`${this.urlBase}`, companyData);

    const response = rawResponse.data;

    if (response.addresses.length) {
      address = CompanyAddressDTM.fromPlain({
        ...response.addresses[0],
      });

      if (address?.contacts?.length) {
        contact = CompanyContactDTM.fromPlain({
          ...address.contacts[0],
        });
      }
    }

    return CompanyDTM.fromPlain({
      id: response.id,
      customId: undefined,
      name: response.name,
      phone: response.phone,
      phone2: response.phone,
      email: response.email,
      usci: response.usci || undefined,
      taxId: response.taxId || undefined,
      addresses: address ? [address] : [],
      contacts: contact ? [contact] : [],
      addressesIds: [],
      contactPersonsIds: [],
      isPrimary: response.isPrimary,
    });
  }

  public postAddAddress = async (companyId: number, postData: IPostCompanyListAddressRequest) => {
    let address: CompanyAddressDTM | null = null;

    try {
      const rawResponse = await apiWorker.requestPost<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/`, postData);

      if (rawResponse.status !== 200) {
        const error = 'Company address post error';
        throw new Error(error);
      }

      const response = rawResponse.data;

      address = CompanyAddressDTM.fromPlain({
        id: response.id,
        country: response.country,
        state: response.state,
        city: response.city,
        address1: response.address1,
        address2: response.address2,
        postalCode: response.postalCode,
        closestPort: response.closestPort,
        contacts: response.contacts?.map((item) => CompanyContactDTM.fromPlain({
          email: item.email,
          fullName: item.fullName,
          id: item.id,
          phone: item.phone,
          phone2: item.phone2,
          customId: uuidv4(),
          primary: item.primary,
          primaryDefault: item.primary,
        })),
        company: response.company,
      });
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'Address post error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'Address post error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return address;
  }

  public postAddressContactByID = async (companyId: string, addressId: string, postData: IPostAddressContactPersonRequest) => {
    let contact: CompanyContactDTM | null = null;

    const data = {
      id: postData?.id || null,
      email: postData?.email || null,
      fullName: postData?.fullName || null,
      phone: postData?.phone || null,
      phone2: postData?.phone2 || null,
      primary: postData?.primary,
    };

    try {
      const rawResponse = await apiWorker.requestPost<ICompanyContactDTM>(`${this.urlBase}/${companyId}/addresses/${addressId}/contacts`, data);

      if (rawResponse.status !== 200) {
        const error = 'Company contact post error';
        throw new Error(error);
      }

      const response = rawResponse.data;

      contact = CompanyContactDTM.fromPlain({
        id: response.id,
        customId: uuidv4(),
        fullName: response.fullName,
        email: response.email,
        phone: response.phone,
        phone2: response.phone2,
        primary: response.primary,
        primaryDefault: response.primary,
      });
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'postAddressContactByID post error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'postAddressContactByID post error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return contact;
  }
}
