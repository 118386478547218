import styled from 'styled-components';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { Button } from 'app-wrapper/view/components';

export const MarkButton = styled(Button)`
  margin-left: 8px;
  width: 174px;
  height: 32px;
  box-shadow: none;
`;

export const CheckIcon = styled(CheckOutlined)`
  color: rgba(222, 225, 233, 1);
`;
