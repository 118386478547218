import styled from 'styled-components';
import Divider from 'antd/es/divider';

import {
  SmallTextFootnoteDescription,
  BodyTextMedium,
} from 'app-wrapper/view/fonts';

import {
  SubPageContent,
  FlexCol,
} from 'app-wrapper/view/guideline';

export const Content = styled(SubPageContent)`
  margin-top: 8px;
  min-height: 170px;
  flex-direction: row;
  justify-content: space-between;
`;

export const PartnerBlock = styled(FlexCol)`
  max-width: 296px;
  width: 100%;
`;

export const Title = styled.span`
  ${SmallTextFootnoteDescription}
  color: rgba(115, 129, 155, 1);
`;

export const ProviderName = styled.span`
  ${BodyTextMedium}
`;

export const HorizontalDivider = styled(Divider)`
  margin: 4px 0;
  width: 100%;
`;
