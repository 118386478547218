import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { SHIPPING_PARTY_MAX_LENGTHS } from 'app-wrapper/constants';

interface IFullNameInputComponentProps {
  fullName: string;
  setFullName: (fullName: string) => void;
  error?: boolean;
}

export const FullNameInputComponent: FC<IFullNameInputComponentProps> = ({
  fullName,
  setFullName,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setFullName(e.target.value);
  }, [setFullName]);

  return (
    <Input
      value={fullName}
      onChange={handleChange}
      maxLength={SHIPPING_PARTY_MAX_LENGTHS.FULL_NAME}
      error={error && !fullName.length}
    />
  );
};
