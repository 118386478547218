import styled from 'styled-components';
import RightOutlined from '@ant-design/icons/RightOutlined';
import Tag from 'antd/es/tag';

export const Info = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
`;

export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoShipmentName = styled.span`
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
`;

export const InfoDate = styled.span`
  margin-left: 4px;
  color: rgba(115, 129, 155, 1);
`;

export const DividerIcon = styled(RightOutlined)`
  color: rgba(209, 215, 226, 1);
  font-size: 12px;
  margin: 0 8px;
`;

export const ShipmentReference = styled(Tag)`
  margin: 0 0 auto auto;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
`;
