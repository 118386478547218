import AntCollapse, { CollapseProps } from 'antd/es/collapse';
import styled from 'styled-components';
import React, { FC } from 'react';
import { Panel } from 'app-wrapper/view/components/Collapse/Panel';
import omit from 'lodash/fp/omit';

export const Collapse = AntCollapse;

const CollapseWrapper = styled.div`
  .ant-collapse-header {
    display: none !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse.ant-collapse-icon-position-left,
  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-item.ant-collapse-item-active,
  .ant-collapse-item {
    border: none !important;
    background: none;
  }
`;

interface ICollapseProps {
  isCollapsed?: boolean
}

export const CollapseBodyOnly: FC<CollapseProps & ICollapseProps> = (props) => {
  const { isCollapsed, children } = props;
  return (
    <CollapseWrapper>
      <Collapse
        ghost
        {...omit(['children', 'show'], props)}
        activeKey={isCollapsed ? '1' : ''}
      >
        <Panel header="" key="1" extra={null}>
          {children}
        </Panel>
      </Collapse>
    </CollapseWrapper>
  );
};
