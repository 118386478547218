import { DateDtm } from 'app-wrapper/models/dtm';
import { action, computed } from 'mobx';
import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { DepartureDetailsStatusEnum } from 'shipment-operations/constants';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { ContainerDTM, LoadingControlDTM } from 'shipment-operations/models/dtm';

interface FormInputs {
  number?: InputStore<string>
  carrier?: InputStore<number>
  arrivalDate?: InputStore<DateDtm | undefined>
  departureDate?: InputStore<DateDtm | undefined>
  reference: InputStore<string>
  dispatchOrder: InputStore<string>
  status?: InputStore<keyof typeof DepartureDetailsStatusEnum>;
}

interface IOverviewControllerState {
  containerId?: number
  container?: ContainerDTM
  loadingControl?: LoadingControlDTM,
  drayageSide: EDrayageSide
  isSubmitting: boolean
  form: FormInputs
  selectedCompany?: number
}

const initialState: IControllerState<IOverviewControllerState> = {
  isLoading: true,
  containerId: undefined,
  container: undefined,
  loadingControl: undefined,
  drayageSide: EDrayageSide.DESTITATION_DRAYAGE,
  isSubmitting: false,
  form: {
    number: new InputStore(),
    carrier: new InputStore(),
    arrivalDate: new InputStore(),
    departureDate: new InputStore(),
    reference: new InputStore(),
    dispatchOrder: new InputStore(),
    status: new InputStore(),
  },
};

export class ShipmentEditContainerStore extends ControllerStore<IControllerState<IOverviewControllerState>> {
  @action
  setContainerId(id: number) {
    this.state.containerId = id;
  }

  @action
  setDrayageSide(id: EDrayageSide) {
    this.state.drayageSide = id;
  }

  setSubmitting(isSubmitting: boolean) {
    this.state.isSubmitting = isSubmitting;
  }

  @action
  setContainer(container?: ContainerDTM) {
    this.state.container = container;
  }

  @action
  setLoadingControl(loadingControl?: LoadingControlDTM) {
    this.state.loadingControl = loadingControl;
  }

  @action
  setForm(formInputs: FormInputs) {
    this.state.form = formInputs;
  }

  @computed
  get hasError() {
    return Object.values(this.state.form).some((input: InputStore<any>) => input.hasError);
  }

  get containerId() {
    return this.state.containerId;
  }
}

export const createShipmentEditContainerStore = (_initialState?: IControllerState<IOverviewControllerState>) => new ShipmentEditContainerStore(_initialState || initialState);
