import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import MailOutlined from '@ant-design/icons/MailOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';

import {
  UserContactsData,
  UserContactsHeader,
  UserContactsIconBG,
  UserContactsItem,
  UserContactsRow,
  UserContactsTitle,
  UserContactsValue,
  UserContactsWrapper,
} from './UserContacts.styled';

interface IUserContactsComponentProps {
  email: string
  phone?: string
}

export const UserContactsComponent: FC<IUserContactsComponentProps> = ({
  email,
  phone,
}) => {
  const { t } = useTranslation();

  return (
    <UserContactsWrapper>
      <UserContactsHeader>{t('Contacts')}</UserContactsHeader>
      <UserContactsRow>
        <UserContactsItem>
          <UserContactsIconBG>
            <MailOutlined />
          </UserContactsIconBG>
          <UserContactsData>
            <UserContactsTitle>{t('Email')}</UserContactsTitle>
            <UserContactsValue>
              <Typography.Text copyable={!!email}>{email || '-'}</Typography.Text>
            </UserContactsValue>
          </UserContactsData>
        </UserContactsItem>

        <UserContactsItem>
          <UserContactsIconBG>
            <PhoneOutlined />
          </UserContactsIconBG>
          <UserContactsData>
            <UserContactsTitle>{t('Phone')}</UserContactsTitle>
            <UserContactsValue>
              <Typography.Text copyable={!!phone}>{phone || '-'}</Typography.Text>
            </UserContactsValue>
          </UserContactsData>
        </UserContactsItem>
      </UserContactsRow>
    </UserContactsWrapper>
  );
};
