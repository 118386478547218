import { BaseController, controller } from 'proto/BaseController';
import { EmptyContactBookDefaultStateCompanyListStateDTMFactory } from 'user-management/models/dtm/CompanyBookState.dtm.factory';
import message from 'antd/es/message';
import { v4 as uuidv4 } from 'uuid';

import { REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import i18n from 'app-wrapper/i18n/i18n';
import { validationEmail, validationPhone } from 'app-wrapper/utils';

import { EAccountDrawerContent } from 'user-management/constants';
import {
  IGetCompanyListAddressRequest,
  IGetCompanyListContactRequest,
  IPostAddressContactPersonRequest,
  IPostCompanyListAddressRequest,
  TPutCompanyListRequest,
} from 'user-management/models/contracts';
import {
  CompanyAddressDTM,
  CompanyCollectedDTM,
  CompanyContactDTM,
  ContactBookDefaultStateCompanyListStateDTM,
  EmptyCompanyAddressDTMFactory,
  EmptyCompanyContactDTMFactory,
  ICompanyAddressDTM,
  ICompanyDTM,
  IContactBookDefaultStateCompanyListStateDTM,
} from 'user-management/models/dtm';
import { R } from 'user-management/repository';
import { ContactBookInitialState } from 'user-management/repository/store/ContactBook';

@controller
export class ContactBookDrawersController extends BaseController {
  public onRunContactBookByIdContactPerson = async (idCompany: string, idAddress: string) => {
    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());

    const address = await this.getCompanyByIdAddressById(idCompany, idAddress);
    const customId = uuidv4();
    const contactIds: string[] = [];

    if (address?.length) {
      this.dispatch(R.actions.contactBook.setDefaultAddCompanyListAddresses(address?.[0]));
      this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(address?.[0]));

      address.forEach((item) => {
        item.contacts?.forEach((itemContact) => {
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyListContactPersons(itemContact));
          this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(itemContact));
          contactIds.push(itemContact.customId);
        });
      });

      this.dispatch(R.actions.contactBook.setDefaultAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
        ...ContactBookInitialState.updateState.companyList?.[0],
        customId,
        addressesIds: [address?.[0]?.customId || ''],
        contactPersonsIds: contactIds,
      })));
      this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
        ...ContactBookInitialState.updateState.companyList?.[0],
        customId,
        addressesIds: [address?.[0]?.customId || ''],
        contactPersonsIds: contactIds,
      })));

      this.dispatch(R.actions.contactBook.setDefaultSetActiveListTabIndex(customId));
      this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(customId));
    }
  }

  public getCompanyByIdAddressById = async (idCompany: string, idAddress: string) => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    let addresses: CompanyAddressDTM[] | null = null;

    try {
      const address = await R.services.contacts.getAddress(Number(idCompany), Number(idAddress));
      if (address) {
        addresses = [CompanyAddressDTM.fromPlain(address)];
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    return addresses;
  };

  public openAddressesAndContactsAddAddressDrawer = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateCompanyList([]));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS));
  }

  public closeCBByIdEditCompanyDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public closeCBByIdAddContactDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public closeCBByIdEditContactDrawer = () => {
    const { contactBook: { defaultState, updateState } } = this.store.getState();

    const companyListById = updateState.activeCompanyEdit;
    const contactIds = companyListById?.contactPersonsIds;

    this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons([
      ...defaultState?.companyListContactPersons.map((item) => CompanyContactDTM.fromPlain(item)) || [],
      ...defaultState.companyListContactPersons.filter((item) => contactIds?.includes(item.customId || '')).map((item) => CompanyContactDTM.fromPlain(item)),
    ]));

    this.dispatch(R.actions.contactBook.resetUpdateActiveCompanyEdit());
    this.dispatch(R.actions.contactBook.resetUpdateErrors());

    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public closeContactBookByIdAddContactPersonDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public closeContactBookByIdEditContactPersonDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onChangeContactBookByIdEditCompanyName = (name: string) => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      ...updateState.activeCompanyEdit,
      name,
    })));

    this.validateContactBookByIdEditDrawer();

    this.compareContactBookByIdEditDrawer();
  };

  public onBlurContactBookByIdEditCompanyName = () => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...updateState.errorsEditCompanyDetailsDrawer,
      name: {
        ...updateState.errorsEditCompanyDetailsDrawer?.name,
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateContactBookByIdEditDrawer();
  };

  public onFocusContactBookByIdEditCompanyName = () => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...updateState.errorsEditCompanyDetailsDrawer,
      name: {
        ...updateState.errorsEditCompanyDetailsDrawer?.name,
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateContactBookByIdEditDrawer();
  };

  public onChangeContactBookByIdEditCompanyTaxId = (taxId: string) => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      ...updateState.activeCompanyEdit,
      taxId: taxId.slice(0, 35),
    })));

    this.validateContactBookByIdEditDrawer();

    this.compareContactBookByIdEditDrawer();
  };

  public onBlurContactBookByIdEditCompanyTaxId = () => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...updateState.errorsEditCompanyDetailsDrawer,
      taxId: {
        ...updateState.errorsEditCompanyDetailsDrawer?.taxId,
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateContactBookByIdEditDrawer();
  };

  public onFocusContactBookByIdEditCompanyTaxId = () => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...updateState.errorsEditCompanyDetailsDrawer,
      taxId: {
        ...updateState.errorsEditCompanyDetailsDrawer?.taxId,
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateContactBookByIdEditDrawer();
  };

  public onSaveContactBookByIdEditCompany = async () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    if (this.validateContactBookByIdEditDrawer()) {
      this.setAllVisitedContactBookByIdEditDrawer();
      return;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    const companyId = updateState.activeListTabIndex;
    const companyUpdate = updateState.activeCompanyEdit;
    const companyDefault = defaultState.companyList.filter((item) => item.customId === companyId)?.[0];
    const companyOld = updateState.companyList.filter((item) => item.customId === companyId)?.[0];

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((item) => companyOld.addressesIds?.includes(item.customId || ''))
      ?.map((addressItem, addressIndex) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || 'USOAK',
        id: addressItem.id || null,
        company: addressItem.company || null,
        primary: addressIndex === 0,
      }));

    let contactPersons: IGetCompanyListContactRequest[] | null = null;
    contactPersons = updateState?.companyListContactPersons
      ?.filter((item) => companyOld.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      }));

    const companyData: TPutCompanyListRequest = {
      name: companyUpdate?.name || '',
      taxId: companyUpdate?.taxId || null,
      id: companyUpdate?.id || null,
      addresses,
      contacts: contactPersons,
    };

    let response: CompanyCollectedDTM | undefined;

    try {
      response = await R.services.contacts.putCompanyList(companyData?.id || '', companyData);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    if (response) {
      this.dispatch(R.actions.contactBook.setDefaultCompanyListById({
        listId: companyId,
        companyList: {
          ...companyDefault,
          taxId: companyData.taxId || undefined,
          name: companyData.name || undefined,
        },
      }));
      this.dispatch(R.actions.contactBook.setUpdateCompanyListById({
        listId: companyId,
        companyList: {
          ...companyDefault,
          taxId: companyData.taxId || undefined,
          name: companyData.name || undefined,
        },
      }));
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Company is Updated'));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onRemoveContactBookByIdEditCompany = async () => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    const companyId = updateState.activeListTabIndex;
    const companyOld = updateState.companyList.filter((item) => item.customId === companyId)?.[0];

    try {
      await R.services.contacts.deleteCompanyById(Number(companyOld.id));
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Company is Deleted'));

    await this.navigate(RouteNames.CONTACT_BOOK());
  }

  public onClickContactBookByIdAddContactPerson = async () => {
    const customId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      contactPersonsIds: [contactId],
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_CONTACT));
  }

  public onChangeContactBookByIdAddAddressIsPrimary = (addressId: string) => (isPrimary: boolean) => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: addressId,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...updateState.companyListAddresses.filter((item) => item.customId === addressId)?.[0],
        isPrimary,
      }),
    }));
  }

  public onChangeContactBookByIdEditAddressIsPrimary = (addressId: string) => (isPrimary: boolean) => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: addressId,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...updateState.companyListAddresses.filter((item) => item.customId === addressId)?.[0],
        isPrimary,
      }),
    }));
  }

  public onCancelContactBookByIdAddAddress = () => {
    const { contactBook: { updateState: { activeCompanyEdit } } } = this.store.getState();
    activeCompanyEdit?.addressesIds?.forEach((item) => {
      this.dispatch(R.actions.contactBook.removeUpdateAddressPrimaryContactById(item));
    });
    activeCompanyEdit?.contactPersonsIds?.forEach((item) => {
      this.dispatch(R.actions.contactBook.removeUpdateContactsById(item));
    });
    this.dispatch(R.actions.contactBook.resetUpdateActiveCompanyEdit());

    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onCancelContactBookByIdEditAddress = () => {
    const { contactBook: { updateState: { activeCompanyEdit }, defaultState } } = this.store.getState();

    const defaultAddress = defaultState.companyListAddresses.filter((item) => activeCompanyEdit?.addressesIds?.includes(item?.customId || ''))?.[0];

    this.dispatch(R.actions.contactBook.resetUpdateErrors());
    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: activeCompanyEdit?.addressesIds?.[0] || '',
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...defaultAddress,
      }),
    }));

    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onAddCBByIdAddress = async () => {
    const { contactBook: { updateState } } = this.store.getState();

    if (this.validateContactBookByIdAddAddressDrawer()) {
      this.setAllVisitedContactBookByIdAddAddressDrawer();
      return;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisitedContactBookByIdAddAddressDrawer(false);

    const itemList = updateState.activeCompanyEdit;

    const contacts: IGetCompanyListContactRequest[] = updateState?.companyListContactPersons
      ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      })) || [];

    const addressesPost: IPostCompanyListAddressRequest = updateState?.companyListAddresses
      ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
      ?.map((addressItem) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || 'USOAK',
        id: addressItem.id || undefined,
        company: addressItem.company || null,
        primary: addressItem.isPrimary,
        contacts,
      }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ))?.[0];

    let response: ICompanyAddressDTM | undefined;

    try {
      response = await R.services.contacts.postAddress(Number(updateState?.companyList?.[0]?.id), addressesPost);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    const currentCompany = updateState.companyList.filter((item) => item.customId === updateState.activeListTabIndex)?.[0];

    if (response && currentCompany.id) {
      this.dispatch(R.actions.contactBook.resetUpdate());
      this.dispatch(R.actions.contactBook.resetDefault());

      await this.getCompanyByID(`${currentCompany.id}`);
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Address is Added'));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onSaveCBByIdEditAddress = async () => {
    const { contactBook: { updateState } } = this.store.getState();

    if (this.validateContactBookByIdEditAddressDrawer()) {
      this.setAllVisitedContactBookByIdAddAddressDrawer();
      return;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisitedContactBookByIdAddAddressDrawer(false);

    const itemList = updateState.activeCompanyEdit;

    const contacts: IGetCompanyListContactRequest[] = updateState?.companyListContactPersons
      ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      })) || [];

    const addressesPost: IPostCompanyListAddressRequest = updateState?.companyListAddresses
      ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
      ?.map((addressItem) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || 'USOAK',
        id: addressItem.id || undefined,
        company: addressItem.company || null,
        primary: addressItem.isPrimary,
        contacts,
      }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ))?.[0];

    let response: ICompanyAddressDTM | null;

    try {
      response = await R.services.contacts.putAddress(Number(updateState?.companyList?.[0]?.id), Number(addressesPost.id), addressesPost);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    const currentCompany = updateState.companyList.filter((item) => item.customId === updateState.activeListTabIndex)?.[0];

    if (response && currentCompany.id) {
      this.dispatch(R.actions.contactBook.resetUpdate());
      this.dispatch(R.actions.contactBook.resetDefault());

      await this.getCompanyByID(`${currentCompany.id}`);
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Contact is Updated'));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onRemoveContactBookByIdEditAddress = async () => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    const companyId = updateState.activeListTabIndex;
    const companyOld = updateState.companyList.filter((item) => item.customId === companyId)?.[0];
    const company = updateState.activeCompanyEdit;

    const address = updateState?.companyListAddresses
      ?.filter((item) => company?.addressesIds?.includes(item.customId || ''))?.[0];

    try {
      await R.services.contacts.deleteAddress(Number(companyOld.id), Number(address.id));
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());

    await this.getCompanyByID(`${companyOld.id}`);

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Address is Deleted'));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onSaveContactBookByIdAddContactPersonDrawer = async (contactId: string, addressId: string) => {
    this.setAllVisitedContactBookByIdAddAddressDrawer();
    if (this.validateContactBookByIdAddContactPersonDrawer()) {
      return;
    }
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));
    const { contactBook: { updateState } } = this.store.getState();

    const itemList = updateState.activeCompanyEdit;

    const contacts: IGetCompanyListContactRequest | undefined = updateState?.companyListContactPersons
      ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactItem.primary,
      }))?.[0];

    const request: IPostAddressContactPersonRequest = {
      email: contacts?.email || '',
      fullName: contacts?.fullName || '',
      phone: contacts?.phone || '',
      phone2: contacts?.phone2 || '',
      primary: contacts?.primary,
    };

    try {
      if (request) {
        await R.services.contacts.postAddressContactByID(contactId, addressId, request);
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());
    await this.onRunContactBookByIdContactPerson(contactId, addressId);

    this.dispatch(R.actions.accountDrawer.closeDrawer());
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Contact is Added'));
  }

  public onSaveContactBookByIdEditContactPersonDrawer = async (contactId: string, addressId: string) => {
    this.setAllVisitedContactBookByIdAddAddressDrawer();
    if (this.validateContactBookByIdAddContactPersonDrawer()) {
      return;
    }
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));
    const { contactBook: { updateState } } = this.store.getState();

    const itemList = updateState.activeCompanyEdit;

    const contacts: IGetCompanyListContactRequest | undefined = updateState?.companyListContactPersons
      ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactItem.primary,
      }))?.[0];

    const request: IPostAddressContactPersonRequest = {
      email: contacts?.email || '',
      fullName: contacts?.fullName || '',
      phone: contacts?.phone || '',
      phone2: contacts?.phone2 || '',
      primary: contacts?.primary,
    };

    try {
      if (request) {
        await R.services.contacts.putAddressContactByID(contactId, addressId, `${contacts.id}`, request);
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());
    await this.onRunContactBookByIdContactPerson(contactId, addressId);

    this.dispatch(R.actions.accountDrawer.closeDrawer());
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Contact is Updated'));
  }

  public onRemoveContactBookByIdEditContactPersonDrawer = async (contactId: string, addressId: string) => {
    const { contactBook: { updateState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    const company = updateState.activeCompanyEdit;

    const contactPerson = updateState?.companyListContactPersons
      ?.filter((item) => company?.contactPersonsIds?.includes(item.customId || ''))?.[0];

    try {
      await R.services.contacts.deleteAddressContactByID(contactId, addressId, `${contactPerson.id}`);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());

    await this.onRunContactBookByIdContactPerson(contactId, addressId);

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Contact is Deleted'));
  }

  public onClickContactBookByIdEditContactPerson = (contactId: string) => async () => {
    const customId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      contactPersonsIds: [contactId],
    })));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_CONTACT));
  }

  public onCancelContactBookByIdEditCompany = () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer(undefined));

    this.dispatch(R.actions.contactBook.resetUpdateActiveCompanyEdit());
    this.dispatch(R.actions.contactBook.resetUpdateErrors());

    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onOpenContactBookByIDAddAddress = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_ADDRESS));
  }

  public onOpenContactBookByIDEditAddress = (addressId: string) => () => {
    const { contactBook: { updateState } } = this.store.getState();
    const companyItem = updateState.companyList.filter((item) => item.customId === updateState.activeListTabIndex)?.[0];
    const address = updateState.companyListAddresses.filter((item) => item.customId === addressId)?.[0];

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId: updateState.activeListTabIndex,
      name: companyItem.name,
      taxId: companyItem.taxId,
      usci: companyItem.usci,
      id: companyItem.id,
      addressesIds: [...companyItem?.addressesIds?.filter((item) => item === addressId) || []],
      contactPersonsIds: [...address.contactsIds || []],
    })));

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer(undefined));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_ADDRESS));
  }

  public onOpenContactBookByIDEditCompany = () => {
    const { contactBook: { updateState } } = this.store.getState();
    const companyItem = updateState.companyList.filter((item) => item.customId === updateState.activeListTabIndex)?.[0];
    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId: updateState.activeListTabIndex,
      name: companyItem.name,
      taxId: companyItem.taxId,
      usci: companyItem.usci,
      id: companyItem.id,
      addressesIds: [...companyItem?.addressesIds || []],
      contactPersonsIds: [...companyItem?.contactPersonsIds || []],
    })));

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer(undefined));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_COMPANY));
  }

  // Updated Addresses

  public onChangeUpdateAddressesIsPrimaryByIndex = (compareCallback: Function) => (indexAddresses: string) => (isPrimary: boolean) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        isPrimary,
      }),
    }));

    compareCallback();
  };

  public onChangeUpdateAddressesAddressLineFirstByIndex = (compareCallback: Function) => (indexAddresses: string) => (addressLineFirst: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address1: addressLineFirst.slice(0, 50),
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateAddressesAddressLineFirstByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateAddressesAddressLineFirstByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onChangeUpdateAddressesAddressLineSecondByIndex = (compareCallback: Function) => (indexAddresses: string) => (addressLineSecond: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address2: addressLineSecond.slice(0, 50),
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateAddressesAddressLineSecondByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateAddressesAddressLineSecondByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onChangeUpdateAddressesCityByIndex = (compareCallback: Function) => (indexAddresses: string) => (city: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        city: city.slice(0, 50),
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateAddressesCityByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateAddressesCityByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onChangeUpdateAddressesCountryByIndex = (compareCallback: Function) => (indexAddresses: string) => (country: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    const address = companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0];

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...address,
        country: country?.slice(0, 50) || '',
        state: undefined,
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateAddressesCountryByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateAddressesCountryByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onClearUpdateAddressesCountryByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        country: '',
        state: undefined,
      }),
    }));

    compareCallback();
  };

  public onChangeUpdateAddressesStateByIndex = (compareCallback: Function) => (indexAddresses: string) => (state: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: state.slice(0, 25),
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateAddressesStateByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateAddressesStateByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onClearUpdateAddressesStateByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: '',
      }),
    }));

    compareCallback();
  };

  public onChangeUpdateAddressesPostalCodeByIndex = (compareCallback: Function) => (indexAddresses: string) => (postalCode: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        postalCode: postalCode.slice(0, 10),
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateAddressesPostalCodeByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateAddressesPostalCodeByIndex = (compareCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public setUpdateAddCompanyListStateAddressItem = (compareCallback: Function) => (listId: string) => () => {
    const addressId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(CompanyAddressDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListAddresses[0],
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddressByIndex({
      listId,
      addressesId: addressId,
    }));

    compareCallback();
  };

  public setUpdateRemoveCompanyListStateAddressItem = (compareCallback: Function) => (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateRemoveCompanyListAddressByIndex({
      listId: indexList,
      addressesId: indexValue,
    }));

    compareCallback();
  };

  // Updated Addresses End

  public onValidateContactBookByIdAddAddressDrawer = () => {
    this.validateContactBookByIdAddAddressDrawer();

    this.compareContactBookByIdAddAddressDrawer();
  }

  public onChangeContactBookByIdAddAddressDrawer = () => {
    this.compareContactBookByIdAddAddressDrawer();
  }

  public onChangeValidateContactBookByIdAddAddressDrawer = () => {
    this.validateContactBookByIdAddAddressDrawer();
  }

  public onChangeContactBookByIdEditAddressDrawer = () => {
    this.compareContactBookByIdEditAddressDrawer();
  }

  public onChangeContactBookByIdAddContactPersonDrawer = () => {
    // this.compareContactBookByIdEditAddressDrawer();
  }

  public onValidateContactBookByIdAddContactPersonDrawer = () => {
    this.validateContactBookByIdAddContactPersonDrawer();
  }

  public onChangeContactBookByIdEditContactPersonDrawer = () => {
    // this.compareContactBookByIdEditAddressDrawer();
  }

  public onValidateContactBookByIdEditContactPersonDrawer = () => {
    this.validateContactBookByIdAddContactPersonDrawer();
  }

  // Updated Contact Persons

  public onChangeUpdateContactsIsPrimaryByIndex = (compareCallback: Function) => (indexContacts: string) => (primary: boolean) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        primary,
      }),
    }));

    compareCallback();
  };

  public onChangeUpdateContactsContactPersonByIndex = (compareCallback: Function) => (indexContacts: string) => (fullName: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        fullName,
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateContactsContactPersonByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateContactsContactPersonByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onChangeUpdateContactsAdditionalPhoneByIndex = (compareCallback: Function) => (indexContacts: string) => (additionalPhone: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(additionalPhone, { isRequired: false });

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone2: checkPhone,
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateContactsAdditionalPhoneByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateContactsAdditionalPhoneByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onChangeUpdateContactsEmailByIndex = (compareCallback: Function) => (indexContacts: string) => (email: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, email: checkEmail } = validationEmail(email);

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }
    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        email: checkEmail,
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateContactsEmailByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateContactsEmailByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public onChangeUpdateContactsPhoneByIndex = (compareCallback: Function) => (indexContacts: string) => (phone: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(phone);

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone: checkPhone,
      }),
    }));

    compareCallback();
  };

  public onBlurUpdateContactsPhoneByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    compareCallback();
  };

  public onFocusUpdateContactsPhoneByIndex = (compareCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    compareCallback();
  };

  public setUpdateAddActiveCompanyEditContactPerson = (compareCallback: Function) => (id: string) => () => {
    const { contactBook: { updateState: { activeCompanyEdit } } } = this.store.getState();
    const contactId = uuidv4() || id;

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(CompanyContactDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListContactPersons[0],
      customId: contactId,
    })));

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...EmptyContactBookDefaultStateCompanyListStateDTMFactory.build(),
      ...activeCompanyEdit,
      contactPersonsIds: [
        ...activeCompanyEdit?.contactPersonsIds || [],
        contactId,
      ],
    })));

    compareCallback();
  };

  public setUpdateRemoveActiveCompanyEditContactPersonItem = (compareCallback: Function) => (indexList: string, contactId: string) => () => {
    const { contactBook: { updateState: { activeCompanyEdit } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.removeUpdateContactsById(contactId));

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...EmptyContactBookDefaultStateCompanyListStateDTMFactory.build(),
      ...activeCompanyEdit,
      contactPersonsIds: [
        ...activeCompanyEdit?.contactPersonsIds?.filter((item) => item !== contactId) || [],
      ],
    })));

    compareCallback();
  };

  // Updated Contact Persons End

  // P R I V A T E

  private setAllVisitedContactBookByIdAddAddressDrawer = (flag = true) => {
    const { contactBook: { updateState: { errorsEditCompanyDetailsDrawer, errors } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...errorsEditCompanyDetailsDrawer,
      name: {
        ...errorsEditCompanyDetailsDrawer?.name,
        isVisited: flag,
      },
      taxId: {
        ...errorsEditCompanyDetailsDrawer?.taxId,
        isVisited: flag,
      },
    }));

    errors.companyListState?.forEach(() => {
      errors.addresses?.forEach((addressKey) => {
        const addressIndex = addressKey.customId;

        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
      });

      errors.contactPersons?.forEach((contactPersonKey) => {
        const contactIndex = contactPersonKey.customId;

        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
      });
    });
  }

  private setAllVisitedContactBookByIdEditDrawer = (flag = true) => {
    const { contactBook: { updateState: { errorsEditCompanyDetailsDrawer } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...errorsEditCompanyDetailsDrawer,
      name: {
        ...errorsEditCompanyDetailsDrawer?.name,
        isVisited: flag,
      },
      taxId: {
        ...errorsEditCompanyDetailsDrawer?.taxId,
        isVisited: flag,
      },
    }));
  }

  private validateContactBookByIdEditDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;
    const companyListFind = updateState.activeCompanyEdit;

    if (!companyListFind?.name) {
      const newState = this.store.getState().contactBook.updateState;
      this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
        customId: '',
        ...newState.errorsEditCompanyDetailsDrawer,
        name: {
          ...newState.errorsEditCompanyDetailsDrawer?.name,
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isTooltipVisible: false,
        },
      }));

      hasError = true;
    } else {
      const newState = this.store.getState().contactBook.updateState;
      this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
        customId: '',
        ...newState.errorsEditCompanyDetailsDrawer,
        name: {
          ...newState.errorsEditCompanyDetailsDrawer?.name,
          message: '',
          isTooltipVisible: false,
        },
      }));
    }
    return hasError;
  }

  private validateContactBookByIdAddAddressDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;
    const newStateCompanyList = updateState.activeCompanyEdit;

    const addressesIds = newStateCompanyList?.addressesIds || [];
    const contactPersonIds = newStateCompanyList?.contactPersonsIds || [];

    updateState?.companyListAddresses
      ?.filter((item) => addressesIds?.includes(item.customId || ''))
      .forEach((addressItem, addressIndex) => {
        if (!addressItem.address1) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.city) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (addressItem?.state) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (addressItem?.country === 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else if (addressItem?.country && addressItem?.country !== 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (!addressItem?.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.postalCode) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        return hasError;
      });

    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (!contactItem.fullName) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
        if (!contactItem.email || errorMessageEmail) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
        if (!contactItem.phone || errorMessagePhone) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!contactItem.phone2) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  }

  private validateContactBookByIdAddContactPersonDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;
    const newStateCompanyList = updateState.activeCompanyEdit;

    const contactPersonIds = newStateCompanyList?.contactPersonsIds || [];

    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (!contactItem.fullName) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
        if (!contactItem.email || errorMessageEmail) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
        if (!contactItem.phone || errorMessagePhone) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!contactItem.phone2) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  }

  private validateContactBookByIdEditAddressDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;
    const newStateCompanyList = updateState.activeCompanyEdit;

    const addressesIds = newStateCompanyList?.addressesIds || [];

    updateState?.companyListAddresses
      ?.filter((item) => addressesIds?.includes(item.customId || ''))
      .forEach((addressItem, addressIndex) => {
        if (!addressItem.address1) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.city) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (addressItem?.state) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (addressItem?.country === 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else if (addressItem?.country && addressItem?.country !== 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (!addressItem?.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.postalCode) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        return hasError;
      });

    return hasError;
  }

  private compareContactBookByIdEditDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }

        const newStateCompanyList = updateState.activeCompanyEdit;

        const isCompare = itemList?.name !== newStateCompanyList?.name || itemList?.taxId !== newStateCompanyList?.taxId;

        this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
          ...ContactBookInitialState.updateState.companyList?.[0],
          ...updateState.activeCompanyEdit,
          isUpdate: isCompare,
        })));
      });
  }

  private compareContactBookByIdEditAddressDrawer = () => {

  }

  private compareContactBookByIdAddAddressDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }

        const newStateCompanyList = updateState.activeCompanyEdit;

        let isCompare = false;

        const addressesIds = newStateCompanyList?.addressesIds || [];
        const contactPersonIds = newStateCompanyList?.contactPersonsIds || [];

        if (itemList?.addressesIds?.length !== newStateCompanyList?.addressesIds?.length) {
          isCompare = true;
        }

        if (itemList?.contactPersonsIds?.length !== newStateCompanyList?.contactPersonsIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          const defaultAddress = updateState.companyListAddresses
            ?.filter((item) => itemList.addressesIds?.includes(item.customId || ''));

          updateState.companyListAddresses
            ?.filter((item) => addressesIds?.includes(item.customId || ''))
            .forEach((addressItem, addressIndex) => {
              if (!isCompare
                && (
                  (addressItem?.address1 !== defaultAddress?.[addressIndex]?.address1)
                  || (addressItem?.address2 !== defaultAddress?.[addressIndex]?.address2)
                  || (addressItem?.city !== defaultAddress?.[addressIndex]?.city)
                  || (addressItem?.country !== defaultAddress?.[addressIndex]?.country)
                  || (addressItem?.state !== defaultAddress?.[addressIndex]?.state)
                  || (addressItem?.postalCode !== defaultAddress?.[addressIndex]?.postalCode)
                )) {
                isCompare = true;
              }
            });
        }

        if (!isCompare) {
          const defaultContactPerson = updateState.companyListContactPersons
            ?.filter((item) => itemList.contactPersonsIds?.includes(item.customId || ''));

          updateState.companyListContactPersons
            ?.filter((item) => contactPersonIds?.includes(item.customId || ''))
            .forEach((contactItem, contactIndex) => {
              if (!isCompare
                && (
                  (contactItem?.phone2 !== defaultContactPerson?.[contactIndex]?.phone2)
                  || (contactItem?.fullName !== defaultContactPerson?.[contactIndex]?.fullName)
                  || (contactItem?.email !== defaultContactPerson?.[contactIndex]?.email)
                  || (contactItem?.phone !== defaultContactPerson?.[contactIndex]?.phone)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
          ...ContactBookInitialState.updateState.companyList?.[0],
          ...updateState.activeCompanyEdit,
          isUpdate: isCompare,
        })));
      });
  }

  private getCompanyAddressCompareId = (newAddress: CompanyAddressDTM[], oldAddress?: CompanyAddressDTM) => {
    let address: CompanyAddressDTM | undefined = oldAddress ? {
      ...oldAddress,
    } : EmptyCompanyAddressDTMFactory.build();

    newAddress.forEach((itemAddress) => {
      if (oldAddress?.address1 === itemAddress.address1
        && oldAddress?.city === itemAddress.city
        && oldAddress?.postalCode === itemAddress.postalCode
        && oldAddress?.country === itemAddress.country) {
        address = {
          ...oldAddress,
          id: itemAddress.id,
        };
      }
    });

    return address;
  }

  private getContactPersonsCompareId = (newContact: CompanyContactDTM[], oldContact?: CompanyContactDTM) => {
    let contactPerson: CompanyContactDTM | undefined = oldContact ? {
      ...oldContact,
    } : EmptyCompanyContactDTMFactory.build();

    newContact.forEach((itemContact) => {
      if (oldContact?.email === itemContact.email
        && oldContact?.fullName === itemContact.fullName
        && oldContact?.phone === itemContact.phone) {
        contactPerson = {
          ...oldContact,
          id: itemContact.id,
        };
      }
    });

    return contactPerson;
  }

  private getCompanyByID = async (id: string) => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    let response: ICompanyDTM[] = [];
    let listAddresses: CompanyAddressDTM[] = [];
    let listContacts: CompanyContactDTM[] = [];

    try {
      const companyCollected = await R.services.contacts.getCompanyById(id);

      response = companyCollected.companyList;
      listAddresses = companyCollected.addresses.sort((a) => (a.isPrimary ? -1 : 1));
      listContacts = companyCollected.contacts.sort((a) => (a.primary ? -1 : 1));
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
      throw e;
    }

    if (response.length) {
      this.dispatch(R.actions.contactBook.setEmptyUpdateCompanyListState());
      this.dispatch(R.actions.contactBook.setUpdateCompanyList([]));
    }
    let firstId: string | undefined;

    const collator = new Intl.Collator();
    this.parseResponse(response)
      .sort((a, b) => collator.compare(a.name, b.name))
      .forEach((itemList) => {
        if (!itemList.isPrimary) {
          if (!firstId) {
            firstId = itemList.customId;
          }
          this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
        }
      });

    if (firstId) {
      this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(firstId));
    }

    this.dispatch(R.actions.contactBook.setDefaultCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setDefaultCompanyListContactPersons(listContacts));

    this.dispatch(R.actions.contactBook.setUpdateCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(listContacts));
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
  };

  private parseResponse = (data: ICompanyDTM[]) => {
    const companyLists: IContactBookDefaultStateCompanyListStateDTM[] = [];

    data.forEach((item) => {
      const companyList: IContactBookDefaultStateCompanyListStateDTM = {
        ...ContactBookInitialState.updateState.companyList[0],
        name: item.name,
        id: item.id,
        customId: item.customId,
        addressesIds: item.addressesIds,
        contactPersonsIds: item.contactPersonsIds,
        usci: item.usci || null,
        taxId: item.taxId || null,
        isPrimary: item.isPrimary,
      };

      companyLists.push(companyList);
    });

    return companyLists;
  }
}
