import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const DialogSvg = ({ width, height }: IPropsIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 60}
    height={height || 60}
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M33.8602 30.5513C28.6411 30.5513 24.4102 34.7822 24.4102 40.0013V40.0254C24.4102 45.2445 28.6411 49.4754 33.8602 49.4754H47.7961C48.3167 49.4754 48.8274 49.4333 49.3251 49.3523L52.0251 51.9602C52.0985 52.0311 52.1966 52.0707 52.2986 52.0707C52.5161 52.0707 52.6924 51.8945 52.6924 51.677L52.6926 48.1095C55.4223 46.4526 57.2461 43.452 57.2461 40.0254V40.0013C57.2461 34.7822 53.0152 30.5513 47.7961 30.5513H33.8602Z" fill="#4E5C93" />
    <path d="M36.1523 7.91064H16.2539C8.79806 7.91064 2.75391 13.9548 2.75391 21.4106C2.75391 28.8665 8.79806 34.9106 16.2539 34.9106H36.1523C43.6082 34.9106 49.6523 28.8665 49.6523 21.4106C49.6523 13.9548 43.6082 7.91064 36.1523 7.91064Z" fill="#E4EBF7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.25781 30.9837V38.0511C9.25781 38.3617 9.50965 38.6136 9.82031 38.6136C9.96602 38.6136 10.106 38.557 10.2109 38.4559L15.4043 33.4448L9.25781 30.9837Z" fill="#E4EBF7" />
    <path d="M17.168 23.0981C18.3718 23.0981 19.3477 22.1223 19.3477 20.9185C19.3477 19.7146 18.3718 18.7388 17.168 18.7388C15.9642 18.7388 14.9883 19.7146 14.9883 20.9185C14.9883 22.1223 15.9642 23.0981 17.168 23.0981Z" fill="white" />
    <path d="M26.0977 23.0981C27.3015 23.0981 28.2773 22.1223 28.2773 20.9185C28.2773 19.7146 27.3015 18.7388 26.0977 18.7388C24.8938 18.7388 23.918 19.7146 23.918 20.9185C23.918 22.1223 24.8938 23.0981 26.0977 23.0981Z" fill="white" />
    <path d="M35.2383 23.0981C36.4421 23.0981 37.418 22.1223 37.418 20.9185C37.418 19.7146 36.4421 18.7388 35.2383 18.7388C34.0345 18.7388 33.0586 19.7146 33.0586 20.9185C33.0586 22.1223 34.0345 23.0981 35.2383 23.0981Z" fill="white" />
  </svg>
);
