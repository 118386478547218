import styled from 'styled-components';
import { Select } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SelectStyled = styled(Select).attrs({
  'data-class': 'ShippingPartiesCompanyNameComponent_SelectStyled',
})`
`;

export const SelectStyledFooter = styled.div.attrs({
  'data-class': 'ShippingPartiesCompanyNameComponent_SelectStyledFooter',
})`
  ${divFlex}
  align-items: center;
  z-index: 10;
  height: 32px;

  padding: 5px 12px;
  border-top: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const SelectStyledFooterText = styled.div.attrs({
  'data-class': 'ShippingPartiesCompanyNameComponent_SelectStyledFooterText',
})`
  ${divFlex}

  margin-left: 8px;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;
