import React from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { DocumentUploaderDrawerController } from 'app-wrapper/controllers/DocumentUploaderDrawerController';
import { DocumentUploaderDrawerStore } from 'app-wrapper/repository/mobxStores/DocumentUploaderDrawerStore';
import {
  DocumentUploaderDrawerComponent,
} from 'app-wrapper/view/drawers/DocumentUploaderDrawer/DocumentUploaderDrawer.component';
import { useAuthToken } from 'app-wrapper/hooks';

export const DocumentUploaderDrawerContainer = observer(() => {
  const controller = useCurrentController<DocumentUploaderDrawerController>();
  const store = useCurrentControllerStore<DocumentUploaderDrawerStore>();
  const authToken = useAuthToken();

  return (
    <DocumentUploaderDrawerComponent
      isLoading={store.state.isLoading}
      isSubmitting={store.state.isSubmitting}
      onClose={controller.closeDrawer}
      onSave={controller.updateContainer}
      authToken={authToken}
    />
  );
});
