import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentListState } from 'shipment-operations/models/states';
import {
  IShipmentListFiltersCheckboxContainerTypeDTM, IShipmentListFiltersCheckboxLocationDTM, IShipmentListFiltersCheckboxShippingPartyDTM, IShipmentListFiltersCheckboxStatusDTM, ShipmentAllStatsDTM, ShipmentListFiltersRadioSortByDTM, ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import {
  AppFiltersCheckboxDTM,
  AppFiltersCheckboxGroupDTM,
  AppFiltersRadioDTM,
  IAppFiltersCheckboxGroupDTM,
} from 'app-wrapper/models/dtm';

const initialState: IShipmentListState = {
  list: [],
  shipmentAllStats: [],
  filters: {},
  isLoading: false,
  isLoadingFilters: false,
  page: 0,
  wasLastResponseEmpty: false,
  searchValue: '',
};

export const shipmentListStore = createSlice({
  name: 'shipmentList',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<ShipmentPreviewDTM[]>) => ({
      ...state,
      isLoading: false,
      list: action.payload,
    }),
    setShipmentAllStats: (state, action: PayloadAction<ShipmentAllStatsDTM[]>) => ({
      ...state,
      shipmentAllStats: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    setLoadingFilters: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoadingFilters: action.payload,
    }),
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    setWasLastRequestEmpty: (state, action: PayloadAction<boolean>) => {
      state.wasLastResponseEmpty = action.payload;
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
    setFilterLocationChange: (state, action: PayloadAction<IShipmentListFiltersCheckboxLocationDTM>) => {
      const { payload: filterLocation } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation,
        },
      };
    },
    setFilterLocation: (state, action: PayloadAction<AppFiltersCheckboxDTM>) => {
      const { payload: filterLocation } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters?.filterLocation,
            values: filterLocation,
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterLocationGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters.filterLocation,
            values: {
              ...state.filters.filterLocation?.values,
              group,
            },
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterLocationGroupByDefault: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        filterLocation: {
          ...state.filters.filterLocation,
          values: {
            ...state.filters.filterLocation?.values,
            group: [...state.filters.filterLocation?.values.groupDefault || []],
          },
          response: [
            ...state.filters.filterLocation?.response || [],
          ],
        },
      },
    }),
    setFilterLocationGroupById: (state, action: PayloadAction<{ indexId: number, value: AppFiltersCheckboxGroupDTM }>) => {
      const { value, indexId } = action.payload;

      const newGroup = [...state?.filters?.filterLocation?.values?.group || []];

      newGroup[indexId] = value;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters.filterLocation,
            values: {
              ...state.filters.filterLocation?.values,
              group: [...newGroup],
            },
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterLocationLastGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: lastGroup } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters.filterLocation,
            values: {
              ...state.filters.filterLocation?.values,
              lastGroup,
            },
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterLocationGroupDefault: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters.filterLocation,
            values: {
              ...state.filters.filterLocation?.values,
              groupDefault,
            },
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterLocationDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters.filterLocation,
            values: {
              ...state.filters.filterLocation?.values,
              disableReset,
            },
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterLocationIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterLocation: {
            ...state.filters.filterLocation,
            values: {
              ...state.filters.filterLocation?.values,
              isUpdate,
            },
            response: [
              ...state.filters.filterLocation?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyChange: (state, action: PayloadAction<IShipmentListFiltersCheckboxShippingPartyDTM>) => {
      const { payload: filterShippingParty } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty,
        },
      };
    },
    setFilterShippingCarrierChange: (state, action: PayloadAction<IShipmentListFiltersCheckboxShippingPartyDTM>) => {
      const { payload: filterShippingCarrier } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier,
        },
      };
    },
    setFilterShippingParty: (state, action: PayloadAction<AppFiltersCheckboxDTM>) => {
      const { payload: filterShippingParty } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters?.filterShippingParty,
            values: filterShippingParty,
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters.filterShippingParty,
            values: {
              ...state.filters.filterShippingParty?.values,
              group,
            },
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyGroupById: (state, action: PayloadAction<{ indexId: number, value: AppFiltersCheckboxGroupDTM }>) => {
      const { value, indexId } = action.payload;

      const newGroup = [...state?.filters?.filterShippingParty?.values?.group || []];

      newGroup[indexId] = value;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters.filterShippingParty,
            values: {
              ...state.filters.filterShippingParty?.values,
              group: [...newGroup],
            },
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyGroupByDefault: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        filterShippingParty: {
          ...state.filters.filterShippingParty,
          values: {
            ...state.filters.filterShippingParty?.values,
            group: [...state.filters.filterShippingParty?.values.groupDefault || []],
          },
          response: [
            ...state.filters.filterShippingParty?.response || [],
          ],
        },
      },
    }),
    setFilterShippingPartyLastGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: lastGroup } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters.filterShippingParty,
            values: {
              ...state.filters.filterShippingParty?.values,
              lastGroup,
            },
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyGroupDefault: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters.filterShippingParty,
            values: {
              ...state.filters.filterShippingParty?.values,
              groupDefault,
            },
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters.filterShippingParty,
            values: {
              ...state.filters.filterShippingParty?.values,
              disableReset,
            },
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingPartyIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingParty: {
            ...state.filters.filterShippingParty,
            values: {
              ...state.filters.filterShippingParty?.values,
              isUpdate,
            },
            response: [
              ...state.filters.filterShippingParty?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrier: (state, action: PayloadAction<AppFiltersCheckboxDTM>) => {
      const { payload: filterShippingCarrier } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters?.filterShippingCarrier,
            values: filterShippingCarrier,
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrierGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters.filterShippingCarrier,
            values: {
              ...state.filters.filterShippingCarrier?.values,
              group,
            },
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrierGroupById: (state, action: PayloadAction<{ indexId: number, value: AppFiltersCheckboxGroupDTM }>) => {
      const { value, indexId } = action.payload;

      const newGroup = [...state?.filters?.filterShippingCarrier?.values?.group || []];

      newGroup[indexId] = value;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters.filterShippingCarrier,
            values: {
              ...state.filters.filterShippingCarrier?.values,
              group: [...newGroup],
            },
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrierGroupByDefault: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        filterShippingCarrier: {
          ...state.filters.filterShippingCarrier,
          values: {
            ...state.filters.filterShippingCarrier?.values,
            group: [...state.filters.filterShippingCarrier?.values.groupDefault || []],
          },
          response: [
            ...state.filters.filterShippingCarrier?.response || [],
          ],
        },
      },
    }),
    setFilterShippingCarrierLastGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: lastGroup } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters.filterShippingCarrier,
            values: {
              ...state.filters.filterShippingCarrier?.values,
              lastGroup,
            },
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrierGroupDefault: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters.filterShippingCarrier,
            values: {
              ...state.filters.filterShippingCarrier?.values,
              groupDefault,
            },
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrierDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters.filterShippingCarrier,
            values: {
              ...state.filters.filterShippingCarrier?.values,
              disableReset,
            },
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterShippingCarrierIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterShippingCarrier: {
            ...state.filters.filterShippingCarrier,
            values: {
              ...state.filters.filterShippingCarrier?.values,
              isUpdate,
            },
            response: [
              ...state.filters.filterShippingCarrier?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeChange: (state, action: PayloadAction<IShipmentListFiltersCheckboxContainerTypeDTM>) => {
      const { payload: filterContainerType } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType,
        },
      };
    },
    setFilterContainerType: (state, action: PayloadAction<AppFiltersCheckboxDTM>) => {
      const { payload: filterContainerType } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: filterContainerType,
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: {
              ...state.filters.filterContainerType?.values,
              group,
            },
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeGroupById: (state, action: PayloadAction<{ indexId: number, value: AppFiltersCheckboxGroupDTM }>) => {
      const { value, indexId } = action.payload;

      const newGroup = [...state?.filters?.filterContainerType?.values?.group || []];

      newGroup[indexId] = value;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: {
              ...state.filters.filterContainerType?.values,
              group: [...newGroup],
            },
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeGroupByDefault: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        filterContainerType: {
          ...state.filters.filterContainerType,
          values: {
            ...state.filters.filterContainerType?.values,
            group: [...state.filters.filterContainerType?.values.groupDefault || []],
          },
          response: [
            ...state.filters.filterContainerType?.response || [],
          ],
        },
      },
    }),
    setFilterContainerTypeLastGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: lastGroup } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: {
              ...state.filters.filterContainerType?.values,
              lastGroup,
            },
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeGroupDefault: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: {
              ...state.filters.filterContainerType?.values,
              groupDefault,
            },
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: {
              ...state.filters.filterContainerType?.values,
              disableReset,
            },
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterContainerTypeIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterContainerType: {
            ...state.filters.filterContainerType,
            values: {
              ...state.filters.filterContainerType?.values,
              isUpdate,
            },
            response: [
              ...state.filters.filterContainerType?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusChange: (state, action: PayloadAction<IShipmentListFiltersCheckboxStatusDTM>) => {
      const { payload: filterStatus } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus,
        },
      };
    },
    setFilterStatus: (state, action: PayloadAction<AppFiltersCheckboxDTM>) => {
      const { payload: filterStatus } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters?.filterStatus,
            values: filterStatus,
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters.filterStatus,
            values: {
              ...state.filters.filterStatus?.values,
              group,
            },
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusGroupById: (state, action: PayloadAction<{ indexId: number, value: AppFiltersCheckboxGroupDTM }>) => {
      const { value, indexId } = action.payload;

      const newGroup = [...state?.filters?.filterStatus?.values?.group || []];

      newGroup[indexId] = value;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters.filterStatus,
            values: {
              ...state.filters.filterStatus?.values,
              group: [...newGroup],
            },
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusGroupByDefault: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        filterStatus: {
          ...state.filters.filterStatus,
          values: {
            ...state.filters.filterStatus?.values,
            group: [...state.filters.filterStatus?.values.groupDefault || []],
          },
          response: [
            ...state.filters.filterStatus?.response || [],
          ],
        },
      },
    }),
    setFilterStatusLastGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: lastGroup } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters.filterStatus,
            values: {
              ...state.filters.filterStatus?.values,
              lastGroup,
            },
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusGroupDefault: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters.filterStatus,
            values: {
              ...state.filters.filterStatus?.values,
              groupDefault,
            },
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters.filterStatus,
            values: {
              ...state.filters.filterStatus?.values,
              disableReset,
            },
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterStatusIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterStatus: {
            ...state.filters.filterStatus,
            values: {
              ...state.filters.filterStatus?.values,
              isUpdate,
            },
            response: [
              ...state.filters.filterStatus?.response || [],
            ],
          },
        },
      };
    },
    setFilterSortByChange: (state, action: PayloadAction<ShipmentListFiltersRadioSortByDTM>) => {
      const { payload: filterSortBy } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy,
        },
      };
    },
    setFilterSortBy: (state, action: PayloadAction<AppFiltersRadioDTM>) => {
      const { payload: filterSortBy } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              ...filterSortBy,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    setFilterSortByGroup: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              group,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    setFilterSortByGroupDefault: (state, action: PayloadAction<IAppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              groupDefault,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    setFilterSortByValue: (state, action: PayloadAction<string>) => {
      const { payload: value } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              value,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    setFilterSortByValueDefault: (state, action: PayloadAction<string>) => {
      const { payload: valueDefault } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              valueDefault,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    setFilterSortByValueLast: (state, action: PayloadAction<string>) => {
      const { payload: valueLast } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              valueLast,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    setFilterSortByIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          filterSortBy: ShipmentListFiltersRadioSortByDTM.fromPlain({
            ...state.filters.filterSortBy,
            values: {
              ...state.filters.filterSortBy?.values,
              isUpdate,
            },
            response: {
              ...state.filters.filterSortBy?.response,
            },
          }),
        },
      };
    },
    clear: () => (initialState),
  },
});

export const shipmentListActions = shipmentListStore.actions;

export const shipmentListReducer = shipmentListStore.reducer;
