import styled from 'styled-components';
import Menu from 'antd/es/menu';

import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

const { Item, SubMenu } = Menu;

export const StyledMenu = styled(Menu)`
  height: calc(100% - 40px);
  border-right: none;
  background-color: #F9F9FB;
  z-index: 1;
  position: relative;
  ${CustomScrollbar}
  
  .ant-menu {
    border: none;
    svg {
      color: #73819B;
    }
  }
  
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #F0F2FC;
    
    & .ant-menu-title-content {
      color: #73819B;
    }
  }
  
  &.ant-menu:not(.ant-menu-horizontal).ant-menu-inline-collapsed .ant-menu-item-selected {
    background-color: #F9F9FB;
  }
  
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    color: #73819B;
  }
  
  &.ant-menu:not(.ant-menu-horizontal) a:hover {
    color: #73819B;
  }
`;

export const Skeletons = styled(GapVerticalContainerStyled)`
  padding: 8px;
`;

export const StyledItem = styled(Item)`  
  & a:hover {
    color: #73819B;
  }
`;

export const StyledItemSunWarningDot = styled.div.attrs((props: {
  isSub: boolean,
  isVisible: boolean,
}) => ({
  isSub: props.isSub,
  isVisible: props.isVisible,
  'data-class': 'StyledItemSunWarningDot',
}))`
  ${divFlex}

  ${({ isSub }) => (isSub ? 'display: inline-flex;' : '')};
  ${({ isSub }) => (isSub ? 'position: relative;' : 'position: absolute;')};
  ${({ isSub }) => (isSub ? '' : 'right: 61px')};
  ${({ isSub }) => (isSub ? 'margin-left: 2px;' : '')};
  ${({ isSub }) => (isSub ? 'top: 0;' : 'top: 13px;')};
  ${({ isSub }) => (isSub ? 'margin-bottom: 6px;' : 'margin-bottom: auto;')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger};
`;

export const StyledSubMenu = styled(SubMenu)`
  &.ant-menu-submenu-selected .ant-menu-title-content {
    color: #73819B;
  }
`;

export const IconWrapper = styled.span.attrs((props: {
  colorSvg: string
  'data-class'?: string
}) => ({
  colorSvg: props.colorSvg,
  'data-class': props?.['data-class'] || 'IconWrapper',
}))`
  display: flex;
  align-items: center;

  svg {
    ${({ colorSvg }) => (colorSvg ? `color: ${colorSvg}` : '')};
  }
`;
