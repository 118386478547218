import React from 'react';
import LinkOutlined from '@ant-design/icons/LinkOutlined';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const LinkSvg = (props: IPropsIcon) => {
  const { fill } = props;
  return (
    <LinkOutlined style={{ color: fill }} />
  );
};
