import styled from 'styled-components';
import CloseOutlined from '@ant-design/icons/CloseOutlined';

export const Wrapper = styled.div.attrs({
  'data-class': 'CustomModal_Wrapper',
})`
  display: block;
  position: fixed;
  z-index: 12;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`;

export const Header = styled.div.attrs({
  'data-class': 'CustomModal_Header',
})`
  padding: 14px 24px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div.attrs({
  'data-class': 'CustomModal_Content',
})`
  background-color: #F4F5F8;
  position: absolute;
  left: 16%;
  right: 0;
  top: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #202C3C;
`;

export const StyledClose = styled(CloseOutlined)`
  position: absolute;
  top: 15px;
  right: 26px;
  font-size: 14px;
  border: 1px solid #DEE1E9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  padding: 5px;
`;

export const ChildrenWrapper = styled.div.attrs({
  'data-class': 'CustomModal_ChildrenWrapper',
})`
  padding: 17px 24px;
  height: 100%;
`;
