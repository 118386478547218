import { BaseController, controller } from 'proto/BaseController';

import { R as appR } from 'app-wrapper/repository';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM, CommandCenterGetTasksDTM,
} from 'app-wrapper/models/dtm';

import {
  ShipmentAllStatsDTM,
  ShipmentAllStatsNotificationStatsDTM,
  ShipmentAllStatsTaskStatsDTM,
} from 'shipment-operations/models/dtm';
import { R as shipmentR } from 'shipment-operations/repository';
import { ENotificationStatus, ENotificationTaskType } from 'app-wrapper/constants';
import { EOrganizationMemberRole } from 'user-management/constants';

const IntervalUpdateTasksStats = 180000;
const IntervalFirstUpdateTasksStats = 4000;

const defaultParams = CommandCenterGetTasksDTM.fromPlain({
  page: `${0}`,
  size: '100',
  sort: 'dueDate,asc',
  query: 'TODO',
});
let isFirst = false;

const getTasksStats = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<ShipmentAllStatsDTM | undefined>((resolve, reject) => {
  try {
    const res = appR.services.CommandCenter.getOrganizationStats({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

@controller
export class RoutesController extends BaseController {
  public async showNotificationNewAppVersion(showNotification: () => void) {
    // @ts-ignore
    if (process.env.NODE_ENV === 'development' && !window.Cypress) {
      return;
    }

    const prevAssetManifestHash = appR.selectors.routes.getAssetManifestHash(this.store.getState());
    const assetManifestHash = await appR.services.AssetManifestService.getAssetManifestHash(window.location.origin);

    if (prevAssetManifestHash && assetManifestHash && prevAssetManifestHash !== assetManifestHash) {
      showNotification();
    }

    this.dispatch(appR.actions.routes.setAssetManifestHash(assetManifestHash));
  }

  public saveLastPath = (lastUrl: string) => {
    this.dispatch(appR.actions.routes.setLastPath(lastUrl));
  }

  public onChangePath = (currentPath: string) => {
    const { email } = this.store.getState().auth;

    if (email) {
      this.loadTasksStats();
    }

    this.dispatch(appR.actions.routes.setCurrentPath(currentPath));
  }

  public onRunLoadStatistics = async () => {
    const tasksStatsInterval = setInterval(() => {
      this.loadTasksStats();
    }, IntervalUpdateTasksStats);

    this.loadTasksStats();
    this.dispatch(appR.actions.overview.setTasksStatsInterval(tasksStatsInterval));
  }

  clearIntervalLoadStatistics = () => {
    const { tasksStatsInterval } = this.store.getState().rootOverview;

    if (tasksStatsInterval) {
      clearInterval(tasksStatsInterval);
    }
  }

  public loadTasksStats = async () => {
    const { email } = this.store.getState().auth;
    const organization = await this.repositories.organizationRepository.get();

    if (!isFirst) {
      isFirst = true;
      setTimeout(() => {
        this.loadTasksStats();
      }, IntervalFirstUpdateTasksStats);
      return;
    }

    if (!email || !organization?.id) {
      return;
    }
    const assigneeUserEmails = email ? [email] : undefined;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;
    const isCustomerOrganizationId = (organization?.role) === EOrganizationMemberRole.CUSTOMER;

    const paramsNotification = CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
      page: `${0}`,
      size: '1',
      sort: 'createdAt,desc',
      assigneeAudienceOrganizationIds,
    });

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: ENotificationStatus.TODO,
      type: [ENotificationTaskType.TASK, ENotificationTaskType.ALERT],
      assigneeUserEmails,
    });
    const paramsRequest = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: ENotificationStatus.TODO,
      type: ENotificationTaskType.CHANGE_REQUEST,
      assigneeUserEmails: !isCustomerOrganizationId ? assigneeUserEmails : undefined,
      createdBy: isCustomerOrganizationId ? email : undefined,
    });

    const [resTasks, resRequests] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...paramsRequest,
      })),
    ]);

    const [resNotification] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...paramsNotification,
        assigneeUserEmails: undefined,
        targetAudienceOrganizationIds: assigneeAudienceOrganizationIds,
      })),
    ]);

    const tasksOverdue = resTasks?.taskStats.overdue;
    const alerts = resTasks?.taskStats.alerts;
    const alertsRequests = resRequests?.taskStats.alerts;

    const notificationImportantCount = resNotification?.notificationStats.unreadCritical;

    const allStats: ShipmentAllStatsDTM | undefined = ShipmentAllStatsDTM.fromPlain({
      taskStats: ShipmentAllStatsTaskStatsDTM.fromPlain({
        todo: 0,
        done: 0,
        expiring: 0,
        overdue: 0,
        alerts: 0,
        highTodo: 0,
        highExpiring: 0,
        highOverdue: 0,
      }),
      notificationStats: ShipmentAllStatsNotificationStatsDTM.fromPlain({
        unread: 0,
        read: 0,
        unreadCritical: 0,
        unreadRegular: 0,
      }),
      alertsHighStats: alerts ? ShipmentAllStatsTaskStatsDTM.fromPlain({
        todo: 0,
        done: 0,
        expiring: alerts || 0,
        overdue: 0,
        alerts: 0,
        highTodo: 0,
        highExpiring: 0,
        highOverdue: 0,
      }) : undefined,
      requestsHighStats: alertsRequests ? ShipmentAllStatsTaskStatsDTM.fromPlain({
        todo: 0,
        done: 0,
        expiring: alertsRequests || 0,
        overdue: 0,
        alerts: 0,
        highTodo: 0,
        highExpiring: 0,
        highOverdue: 0,
      }) : undefined,
      taskHighStats: tasksOverdue ? ShipmentAllStatsTaskStatsDTM.fromPlain({
        todo: 0,
        done: 0,
        expiring: tasksOverdue || 0,
        overdue: 0,
        alerts: 0,
        highTodo: 0,
        highExpiring: 0,
        highOverdue: 0,
      }) : undefined,
      notificationHighStats: notificationImportantCount ? ShipmentAllStatsNotificationStatsDTM.fromPlain({
        unread: 0,
        read: 0,
        unreadCritical: notificationImportantCount || 0,
        unreadRegular: 0,
      }) : undefined,
    });

    this.dispatch(appR.actions.overview.setTasksStats(allStats));

    const allStatsNotification: ShipmentAllStatsDTM | undefined = await shipmentR.services.shipment.getShipmentStatsById(CommandCenterGetTasksDTM.fromPlain({}));

    if (allStatsNotification) {
      this.dispatch(appR.actions.overview.setTasksStats({
        ...allStats,
        ...allStatsNotification,
      }));
    }
  }
}
