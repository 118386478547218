import React from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { DocumentUploaderDrawerController } from 'app-wrapper/controllers/DocumentUploaderDrawerController';
import { DocumentUploaderDrawerStore } from 'app-wrapper/repository/mobxStores/DocumentUploaderDrawerStore';
import { useAuthToken } from 'app-wrapper/hooks';
import { DocumentUploadComponent } from 'app-wrapper/view/guideline';

export const FileContainer = observer(() => {
  const controller = useCurrentController<DocumentUploaderDrawerController>();
  const store = useCurrentControllerStore<DocumentUploaderDrawerStore>();
  const authToken = useAuthToken();

  // const file = useMemo(() => controller.file(), []);

  return (
    <DocumentUploadComponent
      authToken={authToken}
      shipmentId="1"
      containerDocument={store?.state.form.file?.value}
      setContainerDocument={controller.setDocument}
      downloadDocument={controller.ddownloadDocument}
      documentType="type"
      disabled={false}
    />
  );
});
