import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TeamOutlined from '@ant-design/icons/TeamOutlined';

import { CompanyDTM } from 'shipment-operations/models/dtm';
import { Label } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/ShippingPartiesForm.styled';

import { SectionButton } from '../SectionButton';
import { TaxIDInput } from './components';
import {
  Section,
  SectionHeader,
  Title,
} from './CompanySection.styled';

interface ICompanySectionComponentProps {
  company?: CompanyDTM;
  isAbleToEditCompany: boolean;
  openSelectCompanyDrawer: () => void;
  hideDrawerButtons?: boolean;
}

export const CompanySectionComponent: FC<ICompanySectionComponentProps> = ({
  company,
  openSelectCompanyDrawer,
  isAbleToEditCompany,
  hideDrawerButtons,
}) => {
  const { t } = useTranslation();

  if (!company) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <Title>{company.name}</Title>

        {isAbleToEditCompany && !hideDrawerButtons ? (
          <SectionButton onClick={openSelectCompanyDrawer} icon={<TeamOutlined />}>
            {t('Change Company')}
          </SectionButton>
        ) : null}
      </SectionHeader>

      <Label>
        {t('Tax ID Number')}
      </Label>
      <TaxIDInput />
    </Section>
  );
};
