import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainerReferenceFieldComponent } from './ShipmentContainerReferenceField.component';

const ShipmentContainerReferenceFieldContainer: FC = memo(() => {
  const value = useSelector(R.selectors.shipmentContainers.getReference);
  const error = useSelector(R.selectors.shipmentContainers.getSelectedContainerReferenceFieldError);
  const isUpdating = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainerReferenceFieldComponent
      value={value}
      disabled={isUpdating}
      error={error}
      onChange={UC.shipmentContainers.updateContainerReference}
      onBlur={UC.shipmentContainers.onBlurContainerReferenceField}
      containersAvailability={permissions.containersAvailability}
      customerEditAvailability={permissions.customerEditAvailability}
      maxLength={16}
    />
  );
});

export {
  ShipmentContainerReferenceFieldContainer as ShipmentContainerReferenceField,
};
