import React from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { EditSuppliersScheduleDrawerController } from 'shipment-operations/controllers/EditSuppliersScheduleDrawer/EditSuppliersScheduleDrawer.controller';
import { EditSupplierScheduleStore } from 'shipment-operations/repository/mobxStores/EditSupplierScheduleStore';
import { EditSupplierScheduleDrawerComponent } from 'shipment-operations/view/drawers/EditSupplierScheduleDrawer/EditSupplierScheduleDrawer.component';

export const EditSupplierScheduleDrawerContainer = observer(() => {
  const controller = useCurrentController<EditSuppliersScheduleDrawerController>();
  const store = useCurrentControllerStore<EditSupplierScheduleStore>();
  const {
    isLoading,
    cargoReadyDate,
    drayageSide,
  } = store.state;

  return (
    <EditSupplierScheduleDrawerComponent
      isLoading={isLoading}
      isAbleToSave={!!cargoReadyDate?.value}
      onClose={controller.closeDrawer}
      onSave={controller.saveSchedule}
      drayageSide={drayageSide}
    />
  );
});
