import React, {
  FC, useCallback, useMemo, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';

import { PreparedRoutesDTM } from 'shipment-operations/models/dtm';
import {
  DEPARTURE, ARRIVAL_CODES, DEPARTURE_CODES, ARRIVAL, RAIL, ROAD, SEA, WATER,
} from 'shipment-operations/constants';
import { TrainSvg, TruckSvg, ShipIcon } from 'app-wrapper/view/icons';
import {
  StepsContainer,
  StepsDivider,
  LittleCircle,
  Tag,
} from './TrackerHeaderSteps.styled';

const tooltipColor = '#314258';

export interface ITTrackerHeaderStepsProps {
  routes: PreparedRoutesDTM
  isDrayage?: boolean
}

export const TrackerHeaderSteps: FC<ITTrackerHeaderStepsProps> = ({ routes, isDrayage = false }) => {
  const { t } = useTranslation();

  const renderIcon = useCallback((type, active) => {
    switch (type) {
      case SEA:
      case WATER:
        return <ShipIcon color={active ? '#6C79DE' : '#D1D7E2'} style={{ cursor: 'pointer' }} />;

      case RAIL:
        return <TrainSvg style={{ color: active ? '#6C79DE' : '#D1D7E2', cursor: 'pointer' }} />;

      case ROAD:
        return <TruckSvg fill={active ? '#6C79DE' : '#D1D7E2'} style={{ cursor: 'pointer' }} />;

      default:
        return null;
    }
  }, []);

  const departureDividerState = useMemo(() => (routes.route[DEPARTURE] ? !!routes.route[DEPARTURE][0]?.passed : false), [routes]);
  const departureIcon = useMemo(() => (routes.route[DEPARTURE] ? renderIcon(routes.route[DEPARTURE][0]?.transport.type, routes.route[DEPARTURE][0]?.passed) : null), [routes]);
  const departure = routes.route[DEPARTURE] ? routes.route[DEPARTURE][0] : null;
  const departureTransport = useMemo(() => (routes.route[DEPARTURE] ? routes.route[DEPARTURE][0]?.transport : null), [routes]);

  const arrivalDividerState = useMemo(() => (routes.route[ARRIVAL] ? routes.route[ARRIVAL][0]?.passed : false), [routes]);

  const renderRoute = useMemo(() => (routes.codes.length ? routes.codes.map((item: string) => {
    const arrivalEvent = routes.route[item].find((elem) => elem?.code && [...ARRIVAL_CODES, 'RA', 'I'].includes(elem?.code));
    const departureEvent = routes.route[item].find((elem) => elem?.code && [...DEPARTURE_CODES, 'OA', 'DS'].includes(elem?.code));
    return (
      <Fragment key={item}>
        <StepsDivider active={arrivalEvent?.passed ? 1 : 0} />
        <Tooltip
          color={tooltipColor}
          title={(
            <>
              {t('Location')}: {routes.route[item][0]?.location.name}
              <br />
              {arrivalEvent?.actual ? (
                `${t('ATA')}: ${arrivalEvent?.actual.getDateDMMMYYYYHHmmWithOffset()}`
              ) : (
                `${t('ETA')}: ${arrivalEvent?.estimated ? arrivalEvent?.estimated?.getDateDMMMYYYYHHmmWithOffset() : arrivalEvent?.predicted?.getDateDMMMYYYYHHmmWithOffset()}`
              )}
              <br />
              {departureEvent?.actual ? (
                `${t('ATD')}: ${departureEvent?.actual.getDateDMMMYYYYHHmmWithOffset()}`
              ) : (
                `${t('ETD')}: ${departureEvent?.estimated ? departureEvent?.estimated?.getDateDMMMYYYYHHmmWithOffset() : departureEvent?.predicted?.getDateDMMMYYYYHHmmWithOffset()}`
              )}
            </>
          )}
        >
          <LittleCircle active={arrivalEvent?.passed ? 1 : 0} />
        </Tooltip>
        <StepsDivider active={departureEvent?.passed ? 1 : 0} />
        <div>
          {departureEvent?.transport.type === ROAD ? renderIcon(departureEvent?.transport.type, departureEvent?.passed) : (
            <Tooltip
              color={tooltipColor}
              title={(
                <>
                  {t('vessel')}: {departureEvent?.transport.name || '~'}
                  <br />
                  {t('voyage')}: {departureEvent?.voyageCode || '~'}
                </>
              )}
            >
              {renderIcon(departureEvent?.transport.type, departureEvent?.passed)}
            </Tooltip>
          )}
        </div>
      </Fragment>
    );
  }) : null), [routes]);

  return (
    <StepsContainer>
      <Tag $mRight="5px" active={departureDividerState ? 1 : 0}>{t('Departure')}</Tag>
      <StepsDivider active={departureDividerState ? 1 : 0} />
      <div>
        {(!isDrayage && routes.route[DEPARTURE][0]?.transport.type !== ROAD) ? (
          <Tooltip
            color={tooltipColor}
            title={(
              <>
                {t('vessel')}: {departureTransport?.name || '~'}
                <br />
                {t('voyage')}: {departure?.voyageCode || '~'}
              </>
            )}
          >
            {departureIcon}
          </Tooltip>
        ) : (departureIcon)}
      </div>
      {renderRoute}
      <StepsDivider active={arrivalDividerState ? 1 : 0} />
      <Tag $mLeft="5px" active={arrivalDividerState ? 1 : 0}>{t('Arrival')}</Tag>
    </StepsContainer>
  );
};
