import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { DrayageTransportationTabsComponent } from './DrayageTransportationTabs.component';

const DrayageTransportationTabsContainer = memo(() => {
  const drayagePermissions = useSelector(R.selectors.shipment.getTransportationDrayagePermissions);

  const { isExport } = useIsExportOrImport();

  const openChangeRequestDrawer = isExport
    ? appUC.drawer.openChangeRequestExportPickupDrawer
    : appUC.drawer.openChangeRequestImportPickupDrawer;

  const requestChangesVisibility = isExport
    ? drayagePermissions.exportRequestChangesVisibility
    : drayagePermissions.importRequestChangesVisibility;

  return (
    <DrayageTransportationTabsComponent
      openAddCharge={UC.shipmentActiveCharge.openTransportationActiveCharge}
      addChargesExportPermissions={drayagePermissions.addChargeExportCharges}
      addChargesImportPermissions={drayagePermissions.addChargeImportCharges}
      trackerAndChargesVisibility={drayagePermissions.chargesAndTrackerVisibility}
      requestChangesVisibility={requestChangesVisibility}
      openChangeRequestDrawer={openChangeRequestDrawer}
    />
  );
});

export { DrayageTransportationTabsContainer as DrayageTransportationTabs };
