import React, {
  FC,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';

import { hasAccess } from 'app-wrapper/utils';
import { Input } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface ICargoFormWeightInputComponentProps {
  weight: CargoDTM['weight']
  touchField: (field: string) => void
  setWeight: (weight: CargoDTM['weight']) => void
  error?: boolean
  cargosAvailability?: PermissionAttributePolicy
  customerCargosAvailability?: PermissionAttributePolicy
}

export const CargoFormWeightInputComponent: FC<ICargoFormWeightInputComponentProps> = ({
  weight,
  touchField,
  setWeight,
  error,
  cargosAvailability,
  customerCargosAvailability,
}) => {
  const handleChangeWeight = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('weight');
  }, []);

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, customerCargosAvailability]);

  return (
    <Input
      disabled={isDisabled}
      value={weight}
      onChange={handleChangeWeight}
      onBlur={handleBlur}
      error={error}
    />
  );
};
