import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  ContainerSealNumberField, ContainerSealNumberWrapper,
} from './ShipmentContainerSealNumberField.styled';

interface IShipmentContainerSealNumberFieldComponentProps {
  value?: string
  disabled: boolean
  onChange: (value: string) => void
  containersAvailability?: PermissionAttributePolicy
  customerEditAvailability?: PermissionAttributePolicy
  maxLength?: number
}

export const ShipmentContainerSealNumberFieldComponent: FC<IShipmentContainerSealNumberFieldComponentProps> = ({
  value,
  disabled,
  onChange,
  containersAvailability,
  customerEditAvailability,
  maxLength,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, []);

  const isDisabled = useMemo(() => !hasAccess(containersAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerEditAvailability, PermissionAttributePolicy.WRITE) || disabled, [containersAvailability, disabled, customerEditAvailability]);

  return (
    <ContainerSealNumberWrapper>
      <span>{ t('Seal Number') }</span>
      <ContainerSealNumberField
        value={value}
        disabled={isDisabled}
        onChange={handleChange}
        maxLength={maxLength}
      />
    </ContainerSealNumberWrapper>
  );
};
