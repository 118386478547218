import i18n from 'app-wrapper/i18n/i18n';

export enum ECommandCenterPageType2 {
}
export enum ECommandCenterPageType {
  COMMAND_CENTER_TASKS_REQUESTS = 'COMMAND_CENTER_TASKS_REQUESTS',
  COMMAND_CENTER_TASKS_REQUESTS_COMPLETED = 'COMMAND_CENTER_TASKS_REQUESTS_COMPLETED',
  COMMAND_CENTER_TASKS_REQUESTS_REJECTED = 'COMMAND_CENTER_TASKS_REQUESTS_REJECTED',
  SHIPMENT_CENTER_REQUEST_REJECTED = 'SHIPMENT_CENTER_REQUEST_REJECTED',
}

interface ICommandCenterPageTypeValuesValues {
  viewStatus?: boolean
  viewDueDate?: boolean
  viewCreatedAt?: boolean
  viewCreatedBy?: boolean
  viewModule?: boolean
  viewAssignee?: boolean
  viewCompletedBy?: boolean
  nameRowCompletionDate?: string
  nameRowCompletedBy?: string
}

type ICommandCenterPageTypeValuesReturn = {
  [key in ECommandCenterPageType]: ICommandCenterPageTypeValuesValues;
}

interface ICommandCenterPageTypeValuesProps {
  isCustomer?: boolean
  isUsersOrganizationIsAssignee?: boolean
  isUsersOrganizationIsCreated?: boolean
}

export const CommandCenterPageTypeValues = (props?:ICommandCenterPageTypeValuesProps) => {
  const {
    isCustomer,
    isUsersOrganizationIsAssignee,
    isUsersOrganizationIsCreated,
  } = props || {};

  if (isCustomer) {
    return {
      [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS]: {
        viewDueDate: true,
        viewCreatedAt: true,
        viewCreatedBy: isUsersOrganizationIsCreated,
        viewModule: true,
        viewAssignee: isUsersOrganizationIsAssignee,
        nameRowCompletionDate: undefined,
      },
      [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS_COMPLETED]: {
        viewStatus: undefined,
        viewDueDate: undefined,
        viewCreatedAt: undefined,
        viewCreatedBy: undefined,
        viewModule: undefined,
        viewAssignee: undefined,
        viewCompletedBy: undefined,
        nameRowCompletionDate: undefined,
      },
      [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS_REJECTED]: {
        viewStatus: false,
        viewDueDate: false,
        viewCreatedAt: false,
        viewCreatedBy: false,
        viewModule: true,
        viewAssignee: false,
        viewCompletedBy: true,
        nameRowCompletionDate: i18n.t('Rejection Date'),
        nameRowCompletedBy: i18n.t('Rejected By'),
      },
      [ECommandCenterPageType.SHIPMENT_CENTER_REQUEST_REJECTED]: {
        viewStatus: false,
        viewDueDate: false,
        viewCreatedAt: false,
        viewCreatedBy: false,
        viewModule: true,
        viewAssignee: false,
        viewCompletedBy: true,
        nameRowCompletionDate: i18n.t('Rejection Date'),
        nameRowCompletedBy: i18n.t('Rejected By'),
      },
    };
  }

  return {
    [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS]: {
      viewDueDate: true,
      viewCreatedAt: true,
      viewCreatedBy: isUsersOrganizationIsCreated,
      viewModule: true,
      viewAssignee: isUsersOrganizationIsAssignee,
      nameRowCompletionDate: undefined,
    },
    [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS_COMPLETED]: {
      viewStatus: undefined,
      viewDueDate: undefined,
      viewCreatedAt: undefined,
      viewCreatedBy: undefined,
      viewModule: undefined,
      viewAssignee: undefined,
      viewCompletedBy: undefined,
      nameRowCompletionDate: undefined,
    },
    [ECommandCenterPageType.COMMAND_CENTER_TASKS_REQUESTS_REJECTED]: {
      viewStatus: false,
      viewDueDate: false,
      viewCreatedAt: false,
      viewCreatedBy: false,
      viewModule: true,
      viewAssignee: false,
      viewCompletedBy: true,
      nameRowCompletionDate: i18n.t('Rejection Date'),
      nameRowCompletedBy: i18n.t('Rejected By'),
    },
    [ECommandCenterPageType.SHIPMENT_CENTER_REQUEST_REJECTED]: {
      viewStatus: false,
      viewDueDate: false,
      viewCreatedAt: false,
      viewCreatedBy: false,
      viewModule: true,
      viewAssignee: false,
      viewCompletedBy: true,
      nameRowCompletionDate: i18n.t('Rejection Date'),
      nameRowCompletedBy: i18n.t('Rejected By'),
    },
  } as ICommandCenterPageTypeValuesReturn;
};
