import React, { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { ChangeRequestStore } from 'shipment-operations/repository/mobxStores';
import { ChangeRequestDrawerController } from 'shipment-operations/controllers/ChangeRequestDrawerController';
import { useTranslation } from 'react-i18next';
import { DocumentsSection } from 'app-wrapper/view/guideline';
import { ColumnType } from 'antd/es/table';
import { BoldText, TableCell } from 'app-wrapper/view/guideline/DocumentsSection/DocumentsSection.styled';
import { ActionMenu } from 'app-wrapper/view/guideline/DocumentsSection/components';
import { UploadFile } from 'antd/lib/upload/interface';
import { NotificationTaskContentAttachmentDTM } from 'app-wrapper/models/dtm';

export const DocumentsSectionsContainer = observer(() => {
  const { t } = useTranslation();
  const controller = useCurrentController<ChangeRequestDrawerController>();
  const store = useCurrentControllerStore<ChangeRequestStore>();

  const columns: ColumnType<object>[] = useMemo(() => [
    {
      title: t('Document Name'),
      width: '25%',
      align: 'left' as 'left',
      render: (file: UploadFile | NotificationTaskContentAttachmentDTM) => (
        <BoldText>
          {file.name}
        </BoldText>
      ),
    },
    {
      title: t('Action'),
      width: '15%',
      align: 'right' as 'right',
      render: (file: UploadFile | NotificationTaskContentAttachmentDTM) => {
        const isTaskData = file instanceof NotificationTaskContentAttachmentDTM;
        const handleDelete = () => (isTaskData ? controller.deleteLoadedFile(file) : controller.removeFile(file));
        const handleDownload = () => (isTaskData ? controller.downloadFile(file) : null);

        return (
          <TableCell>
            <ActionMenu
              onDownload={isTaskData ? handleDownload : undefined}
              onDelete={store.state.editMode ? handleDelete : undefined}
            />
          </TableCell>
        );
      },
    },
  ], []);

  const files = useMemo(
    () => [
      ...store.state.loadedFiles,
      ...store.state.form.files.map((inputStore) => inputStore.value) as UploadFile[],
    ],
    [store.state.loadedFiles, store.state.form.files],
  );

  return (
    <DocumentsSection
      sectionName={t('Attached Documents')}
      columns={columns}
      files={files}
      disableUpload={!store.state.editMode}
      addFile={controller.addFile}
    />
  );
});
