import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import styled from 'styled-components';

import { StyledTable } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export const CustomersTable = styled(StyledTable)`
  max-width: 1614px;
  width: 100%;
  margin: 0 auto;

  .ant-table table {
    padding: 8px 16px 0 16px;
  }

  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 15.5px 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }

  .ant-table-container table > thead > tr:first-child th:last-child, .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
`;

export const TableCell = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  margin-left: auto;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  transition: .2s ease-in color;

  &:hover {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;
