import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentGap,
  ContentSection,
  GapVerticalContainerStyled,
  InfoCardComponent, MissingTextValue,
} from 'app-wrapper/view/guideline';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import { ServicesDivider } from 'app-wrapper/view/components';
import { SectionTitle } from 'shipment-operations/view/components/TransportationDrayage/LoadingControls/LoadingControls.styled';
import { DateDtm } from 'app-wrapper/models/dtm';

interface ICutoffComponentProps {
  terminalReleaseDate?: DateDtm
  customsReleaseDate?: DateDtm
}

export const CutoffComponent: FC<ICutoffComponentProps> = ({
  terminalReleaseDate,
  customsReleaseDate,
}) => {
  const { t } = useTranslation();

  return (
    <ContentSection>
      <GapVerticalContainerStyled width="100%">
        <ContentSectionContainer>
          <SectionTitle>
            {t('Release Dates')}
          </SectionTitle>
        </ContentSectionContainer>
        <ContentSectionContainer>
          <ServicesDivider noMargin />
        </ContentSectionContainer>
        <ContentSectionContainer>
          <ContentGap>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Terminal Release Date')}
                value={terminalReleaseDate?.getDateDMMMYYYYHHmmWithOffset() || <MissingTextValue />}
              />
            </ContentSectionContainer>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Customs Release Date')}
                value={customsReleaseDate?.getDateDMMMYYYYHHmmWithOffset() || <MissingTextValue />}
              />
            </ContentSectionContainer>
            <ContentSectionContainer />
            <ContentSectionContainer />
            {/* <ContentSectionContainer> */}
            {/*   <InfoCardComponent */}
            {/*     title={t('Dangerous Goods Cutoff')} */}
            {/*     value={dangerousGoodsCutOff ? moment(dangerousGoodsCutOff).format(timeFormat) : <MissingTextValue />} */}
            {/*   /> */}
            {/* </ContentSectionContainer> */}
          </ContentGap>
        </ContentSectionContainer>
      </GapVerticalContainerStyled>
    </ContentSection>
  );
};
