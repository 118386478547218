import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { DataItem } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/PendingSubmissionContent/components';
import { Tooltip } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  Content,
  Row,
  Button,
  Alert,
  AlertIcon,
  AlertText,
  ButtonWrap,
} from './MissMatchesContent.styled';

interface IMissMatchesContentComponentProps {
  isThereAreShippingPartiesMissMatches: boolean;
  isThereAreCargosMissMatches: boolean;
  isThereAreContainersMissMatches: boolean;
  isThereAreBLReleaseMissMatches: boolean;
  isThereAreEntryNumberMissMatches: boolean;
  isDraftMBLApproved: boolean;
  openMissMatchesDrawer: (withSubmitButton?: boolean) => void;
  isOriginUS: boolean;
  isDestinationPartner: boolean;
  submitSIPermissions?: PermissionAttributePolicy;
}

export const MissMatchesContentComponent: FC<IMissMatchesContentComponentProps> = ({
  isThereAreCargosMissMatches,
  isThereAreEntryNumberMissMatches,
  isThereAreContainersMissMatches,
  isThereAreBLReleaseMissMatches,
  isThereAreShippingPartiesMissMatches,
  isDraftMBLApproved,
  openMissMatchesDrawer,
  isOriginUS,
  isDestinationPartner,
  submitSIPermissions,
}) => {
  const { t } = useTranslation();

  const handleOpenMissMatchesDrawer = useCallback(() => {
    openMissMatchesDrawer(isDraftMBLApproved);
  }, [openMissMatchesDrawer, isDraftMBLApproved]);

  const hasPermissionsToSubmit = hasAccess(submitSIPermissions, PermissionAttributePolicy.WRITE);

  return (
    <>
      <Alert>
        <AlertIcon />

        <AlertText>
          {isDraftMBLApproved ? t('You have made amendments to the Bill of Lading that require confirmation from the carrier. Please review and submit.') : t('You have made amendments to the Bill of Lading that require confirmation from the carrier. You will be able to submit them once the current version is confirmed by the carrier.')}
        </AlertText>
      </Alert>

      <Content>
        <Row>
          <DataItem
            name={t('Shipping parties')}
            isRequiredDataFilled={!isThereAreShippingPartiesMissMatches}
            isThereMissMatches={isThereAreShippingPartiesMissMatches}
          />

          <DataItem
            name={t('Cargo')}
            isRequiredDataFilled={!isThereAreCargosMissMatches}
            isThereMissMatches={isThereAreCargosMissMatches}
          />

          <DataItem
            name={t('Containers')}
            isRequiredDataFilled={!isThereAreContainersMissMatches}
            isThereMissMatches={isThereAreContainersMissMatches}
          />

          <DataItem
            name={t('BL Release Type')}
            isRequiredDataFilled={!isThereAreBLReleaseMissMatches}
            isThereMissMatches={isThereAreBLReleaseMissMatches}
          />

          {isOriginUS ? (
            <DataItem
              name={t('Entry Number')}
              isRequiredDataFilled={!isThereAreEntryNumberMissMatches}
              isThereMissMatches={isThereAreEntryNumberMissMatches}
            />
          ) : null}
        </Row>

        {isDestinationPartner ? (
          <>
            {isDraftMBLApproved ? (
              <Tooltip
                title={t('Only Partner at Origin is allowed to submit information for MBL')}
              >
                <ButtonWrap>
                  <Button
                    onClick={handleOpenMissMatchesDrawer}
                    disabled
                  >
                    {t('View & Submit Amendments')}
                  </Button>
                </ButtonWrap>
              </Tooltip>
            ) : (
              <ButtonWrap>
                <Button onClick={handleOpenMissMatchesDrawer}>
                  {t('View Amendments')}
                </Button>
              </ButtonWrap>
            )}
          </>
        ) : (
          <ButtonWrap>
            <Button
              disabled={!hasPermissionsToSubmit}
              onClick={handleOpenMissMatchesDrawer}
            >
              {isDraftMBLApproved ? t('View & Submit Amendments') : t('View Amendments')}
            </Button>
          </ButtonWrap>
        )}
      </Content>
    </>
  );
};
