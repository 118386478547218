import moment from 'moment/moment';

import { DateDtm } from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { EDrayageLoadingSide } from 'shipment-operations/constants';
import { ShipmentScheduleDTM } from 'shipment-operations/models/dtm';

export class ShipmentSchedulesService {
  public getSchedules = async (shipmentId: string) => {
    const response = await apiWorker.requestGetBySchema(`/shipment-service/api/v1/shipments/${shipmentId}/schedules` as '/api/v1/shipments/{shipmentId}/schedules');
    const items = response.data;

    const result = items.map((item) => ShipmentScheduleDTM.fromPlain({
      id: item.id as number,
      drayageSide: item.drayageSide as EDrayageLoadingSide,
      cargoReadyDate: item.cargoReadyDate ? DateDtm.fromPlain({
        date: item.cargoReadyDate,
        offset: moment.parseZone(item.cargoReadyDate).utcOffset(),
      }) : undefined,
      earliestPickupDate: item.earliestPickupDate ? DateDtm.fromPlain({
        date: item.earliestPickupDate,
        offset: moment.parseZone(item.earliestPickupDate).utcOffset(),
      }) : undefined,
      latestPickupDate: item.latestPickupDate ? DateDtm.fromPlain({
        date: item.latestPickupDate,
        offset: moment.parseZone(item.latestPickupDate).utcOffset(),
      }) : undefined,
    }));

    return result;
  }

  public createSchedule = async (shipmentId: string, drayageSide: EDrayageLoadingSide, cargoReadyDate: DateDtm) => {
    const requestBody = {
      drayageSide,
      cargoReadyDate: cargoReadyDate.getDateISO(),
    };

    const response = await apiWorker.requestPost(`/shipment-service/api/v1/shipments/${shipmentId}/schedules`, requestBody);

    const result = ShipmentScheduleDTM.fromPlain({
      id: response.data.id as number,
      drayageSide: response.data.drayageSide as EDrayageLoadingSide,
      cargoReadyDate: response.data.cargoReadyDate ? DateDtm.fromPlain({
        date: response.data.cargoReadyDate,
        offset: moment.parseZone(response.data.cargoReadyDate).utcOffset(),
      }) : undefined,
      earliestPickupDate: response.data.earliestPickupDate ? DateDtm.fromPlain({
        date: response.data.earliestPickupDate,
        offset: moment.parseZone(response.data.earliestPickupDate).utcOffset(),
      }) : undefined,
      latestPickupDate: response.data.latestPickupDate ? DateDtm.fromPlain({
        date: response.data.latestPickupDate,
        offset: moment.parseZone(response.data.latestPickupDate).utcOffset(),
      }) : undefined,
    });

    return result;
  };

  public updateSchedule = async (shipmentId: string, schedule: ShipmentScheduleDTM) => {
    const requestBody = {
      ...schedule,
      cargoReadyDate: (schedule.cargoReadyDate?.getDateISO() || null) as string,
      earliestPickupDate: schedule.earliestPickupDate?.getDateISO() || undefined,
      latestPickupDate: schedule.latestPickupDate?.getDateISO() || undefined,
    };

    const response = await apiWorker.requestPutBySchema(`/shipment-service/api/v1/shipments/${shipmentId}/schedules/${schedule.id}` as '/api/v1/shipments/{shipmentId}/schedules/{scheduleId}', requestBody);

    const result = ShipmentScheduleDTM.fromPlain({
      id: response.data.id as number,
      drayageSide: response.data.drayageSide as EDrayageLoadingSide,
      cargoReadyDate: response.data.cargoReadyDate ? DateDtm.fromPlain({
        date: response.data.cargoReadyDate,
        offset: moment.parseZone(response.data.cargoReadyDate).utcOffset(),
      }) : undefined,
      earliestPickupDate: response.data.earliestPickupDate ? DateDtm.fromPlain({
        date: response.data.earliestPickupDate,
        offset: moment.parseZone(response.data.earliestPickupDate).utcOffset(),
      }) : undefined,
      latestPickupDate: response.data.latestPickupDate ? DateDtm.fromPlain({
        date: response.data.latestPickupDate,
        offset: moment.parseZone(response.data.latestPickupDate).utcOffset(),
      }) : undefined,
    });

    return result;
  };
}
