export const UK_BANK_INFORMATION = {
  accountNumber: '8310558991',
  routingNumber: '026073150',
  beneficiaryBank: 'Community Federal Savings Bank',
  swiftOrBIC: 'CMFGUS33',
};

export const US_BANK_INFORMATION = {
  accountNumber: '363299162',
  routingNumber: '322271627',
  beneficiaryBank: 'JP Morgan Chase Bank',
  swiftOrBIC: 'CHASUS33',
};

export const PL_BANK_INFORMATION = {
  accountHolder: 'Maxton Shipping Inc.',
  iban: 'PL08109017530000000151560897',
  bankName: 'Santander Bank Polska S.A.',
  swiftOrBIC: 'WBKPPLPP',
  currency: 'USD',
};
