import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IHBLDocumentPDFState } from 'shipment-operations/models/states';
import { HBLDocumentBOLDTM, ShippingPartyDTM } from 'shipment-operations/models/dtm';
import { AdminPublicInfoDTM, AccountDepartmentDTM } from 'user-management/models/dtm';

const initialState: IHBLDocumentPDFState = {
  isLoading: false,
  billOfLadingHBL: null,
  currentOrgRelatedAdminPublicInfo: null,
  accountHolderRelatedDocExportDepartment: null,
  cargoReceiver: null,
  cargoSupplier: null,
};

export const documentHBLPDFSlice = createSlice({
  name: 'documentHBLPDFSlice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setHBLDocumentBOL: (state, { payload }: PayloadAction<HBLDocumentBOLDTM>) => ({
      ...state,
      billOfLadingHBL: payload,
    }),
    setCurrentOrgRelatedAdminPublicInfo: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdminPublicInfo: payload,
    }),
    setAccountHolderRelatedDocsExportDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderRelatedDocExportDepartment: payload,
    }),
    setCargoSupplier: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      cargoSupplier: payload,
    }),
    setCargoReceiver: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      cargoReceiver: payload,
    }),
    clear: () => initialState,
  },
});

export const documentHBLPDFActions = documentHBLPDFSlice.actions;
export const documentHBLPDFReducer = documentHBLPDFSlice.reducer;
