import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TContainersPermissionsDtm = {
  containersAvailability?: PermissionAttributePolicy;
  customerEditAvailability?: PermissionAttributePolicy;
  requestChangesAvailability?: PermissionAttributePolicy;
}

export class ContainersPermissionsDtm extends BaseDTM<TContainersPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  containersAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  customerEditAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  requestChangesAvailability?: PermissionAttributePolicy;
}
