import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainerSealNumberFieldComponent } from './ShipmentContainerSealNumberField.component';

const ShipmentContainerSealNumberFieldContainer: FC = memo(() => {
  const value = useSelector(R.selectors.shipmentContainers.getSealNumber);
  const isUpdating = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainerSealNumberFieldComponent
      value={value}
      disabled={isUpdating}
      onChange={UC.shipmentContainers.updateSealNumberVolume}
      containersAvailability={permissions.containersAvailability}
      customerEditAvailability={permissions.customerEditAvailability}
      maxLength={15}
    />
  );
});

export {
  ShipmentContainerSealNumberFieldContainer as ShipmentContainerSealNumberField,
};
