import React, { memo } from 'react';

import { PageComponent } from 'app-wrapper/view/routes/components';
import { EDrayageLoadingSide } from 'shipment-operations/constants';
import {
  createEditSupplierScheduleStore,
} from 'shipment-operations/repository/mobxStores/EditSupplierScheduleStore';
import {
  EditSupplierScheduleDrawerContainer,
} from 'shipment-operations/view/drawers/EditSupplierScheduleDrawer/EditSupplierScheduleDrawer.container';
import { EditSuppliersScheduleDrawerController } from 'shipment-operations/controllers/EditSuppliersScheduleDrawer';

interface EditSupplierScheduleDrawerPageProps {
  id?: number
  drayageSide: EDrayageLoadingSide
}

export const EditSupplierScheduleDrawerPage = memo(({ id, drayageSide }: EditSupplierScheduleDrawerPageProps) => (
  <PageComponent
    ControllerClass={EditSuppliersScheduleDrawerController}
    StoreFactory={() => {
      const store = createEditSupplierScheduleStore();

      store.setScheduleId(id);
      store.setDrayageSide(drayageSide);

      return store;
    }}
  >
    <EditSupplierScheduleDrawerContainer />
  </PageComponent>
));
