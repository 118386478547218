import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { UC as ShipmentUC } from 'shipment-operations/controllers';

import { CommandCenterTasksComponent } from './CommandCenterTasks.component';

const CommandCenterTasksContainer: FC = () => {
  const commandCenterIsHaveActiveNotification = useSelector(R.selectors.commandCenter.getCommandCenterIsHaveActiveNotification);
  const commandCenterCurrent = useSelector(R.selectors.commandCenter.getCommandCenterCurrent);

  const commandCenterCurrentOverdueTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentOverdueTasks);
  const commandCenterCurrentTodayTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentTodayTasks);
  const commandCenterCurrentThisWeekTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentThisWeekTasks);
  const commandCenterCurrentLaterTasks = useSelector(R.selectors.commandCenter.getCommandCenterCurrentLaterTasks);

  const commandCenterCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatus);
  const commandCenterCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterCurrentStatusAdd);
  const commandCenterActionCurrentIsClearAllFiltersTasks = useSelector(R.selectors.commandCenter.getCommandCenterActionCurrentIsClearAllFiltersTasks);

  return (
    <CommandCenterTasksComponent
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterTasksShowOnlyUnread()}
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStart={UC.CommandCenter.onStartTaskPage}
      nextPageTasks={UC.CommandCenter.nextPageTasks()}
      checkAnotherDownloadNextPageTask={UC.CommandCenter.checkAnotherDownloadNextPageTask}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterDueDates()}
      onResetReceivedDate={UC.CommandCenter.onResetFilterDueDates()}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterTasksDueDates()}
      onApplyModule={UC.CommandCenter.onApplyTasksModule()}
      onResetModule={UC.CommandCenter.onResetTasksModule()}
      onChangeGroupModule={UC.CommandCenter.onChangeTasksGroupModule()}
      onApplyAssignee={UC.CommandCenter.onApplyTasksAssignee()}
      onResetAssignee={UC.CommandCenter.onResetTasksAssignee()}
      onChangeGroupAssignee={UC.CommandCenter.onChangeTasksGroupAssignee()}
      onChangeGroupObject={UC.CommandCenter.onChangeTasksGroupObject()}
      onApplyObject={UC.CommandCenter.onApplyTasksObject()}
      onResetObject={UC.CommandCenter.onResetTasksObject()}
      openAgreementsDrawer={UC.agreements.openDrawerFromTasks}
      onClearAllFilters={UC.CommandCenter.onClearAllTasksFilters()}
      treeDataObject={commandCenterCurrent?.filters?.filterTreeDataObject?.treeData}
      isVisibleClearAll={commandCenterActionCurrentIsClearAllFiltersTasks}
      filters={commandCenterCurrent?.filters}
      isLoad={commandCenterCurrentStatus}
      isLoadAdd={commandCenterCurrentStatusAdd}
      overdueTasks={commandCenterCurrentOverdueTasks}
      todayTasks={commandCenterCurrentTodayTasks}
      thisWeekTasks={commandCenterCurrentThisWeekTasks}
      laterTasks={commandCenterCurrentLaterTasks}
      countTasksOverdue={commandCenterCurrent.totalElementsOverdue}
      countTasksToday={commandCenterCurrent.totalElementsToday}
      countTasksThisWeek={commandCenterCurrent.totalElementsThisWeek}
      countTasksLater={commandCenterCurrent.totalElementsLater}
      infoCountAllTasks={commandCenterCurrent.infoCountAllTasks}
      infoCountOverdueTasks={commandCenterCurrent.infoCountOverdueTasks}
      infoCountDueTasks={commandCenterCurrent.infoCountDueTasks}
      infoCountPlannedTasks={commandCenterCurrent.infoCountPlannedTasks}
      isHaveNotification={commandCenterIsHaveActiveNotification}
      setShipmentId={ShipmentUC.overview.setShipmentId}
      openTaskDrawer={UC.drawer.openTaskDrawer}
    />
  );
};

export { CommandCenterTasksContainer as CommandCenterTasks };
