import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';

import { DrayageLoadingControlsDrawerComponent } from './DrayageLoadingControlsDrawer.component';

const DrayageLoadingControlsDrawerContainer = observer(() => {
  const { editLoadingControlsDrawer } = useMobxStore();

  return (
    <DrayageLoadingControlsDrawerComponent
      isLoading={editLoadingControlsDrawer.state.isLoading}
      onClose={appUC.drawer.closeDrawer}
      onSave={UC.editLoadingControlsDrawer.saveLoadingControls}
    />
  );
});

export { DrayageLoadingControlsDrawerContainer as DrayageLoadingControlsDrawer };
