export enum ShipmentStatusEnum {
  SI_SUBMITTED = 'SI_SUBMITTED',
  SI_CONFIRMED = 'SI_CONFIRMED',
  CANCELLED = 'CANCELLED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  DRAFT = 'DRAFT',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
}

export enum ShipmentBookingStatusEnum {
  BOOKING_PREPARATION = 'BOOKING_PREPARATION',
  BOOKING_SUBMITTED = 'BOOKING_SUBMITTED',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_DECLINED = 'BOOKING_DECLINED',
  BOOKING_CANCEL = 'BOOKING_CANCEL',
  BOOKING_MANUAL_CONFIRMED = 'BOOKING_MANUAL_CONFIRMED',
  BOOKING_AMENDMENT_SUBMITTED = 'BOOKING_AMENDMENT_SUBMITTED',
  BOOKING_AMENDMENT_CONFIRMED = 'BOOKING_AMENDMENT_CONFIRMED',
  BOOKING_AMENDMENT_DECLINED = 'BOOKING_AMENDMENT_DECLINED',
  BOOKING_AMENDMENT_MANUAL_CONFIRMED = 'BOOKING_AMENDMENT_MANUAL_CONFIRMED',
}

export const AmendmentTypes = [
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_MANUAL_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_MANUAL_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
];
