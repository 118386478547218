import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const getLocalState = (state: RootState) => state.overview;

const getServerError = createSelector(
  getLocalState,
  (state) => state.serverError,
);

const getRequestsResult = createSelector(
  getLocalState,
  (state) => ({
    wasBookingRequestSuccessful: state.wasBookingRequestSuccessful,
    wasShipmentInstructionRequestSuccessful: state.wasShipmentInstructionRequestSuccessful,
  }),
);

const getCargos = createSelector(
  getLocalState,
  (state) => state.cargos.map((item) => ({
    ...item,
    key: item.id,
  })),
);

const getContainersSumWithType = createSelector(
  getLocalState,
  (state) => state.containersSumWithType.map((item) => ({
    ...item,
    key: item.type,
  })),
);

const getHouseShipper = createSelector(
  getLocalState,
  (state) => state.shippingParties.shipper,
);

const getAccountHolder = createSelector(
  getLocalState,
  (state) => state.shippingParties.accountHolder,
);

const getForwarderAgent = createSelector(
  getLocalState,
  (state) => state.shippingParties.forwarderAgent,
);

const getDeliveryAgent = createSelector(
  getLocalState,
  (state) => state.shippingParties.deliveryAgent,
);

const getHouseConsignee = createSelector(
  getLocalState,
  (state) => state.shippingParties.consignee,
);

const getOceanCarrier = createSelector(
  getLocalState,
  (state) => state.shippingParties.oceanCarrier,
);

const getOceanCarrierScac = createSelector(
  getOceanCarrier,
  (carrier) => carrier.scac,
);

const getHouseNotifyParty = createSelector(
  getLocalState,
  (state) => state.shippingParties.notifyParty,
);

const getLoadingStatus = createSelector(
  getLocalState,
  (state) => state.isLoading,
);

const getReferences = createSelector(
  getLocalState,
  (state) => state.references,
);

const getShipmentReference = createSelector(
  getLocalState,
  (state) => state.shipmentReference,
);

const getModalShipmentReference = createSelector(
  getLocalState,
  (state) => state.modalShipmentReference,
);

const getModalShipmentReferenceIsError = createSelector(
  getLocalState,
  (state) => state.shipmentReferenceIsError,
);

const getWasManualBookingConfirmationSuccessful = createSelector(
  getLocalState,
  (state) => state.wasManualBookingConfirmationSuccessful,
);

const getIsCustomerBookingDrawerOpened = createSelector(
  getLocalState,
  (state) => state.isCustomerBookingDrawerOpened,
);

const getIsTermsAndConditionsConfirmed = createSelector(
  getLocalState,
  (state) => state.isTermsAndConditionsConfirmed,
);

const getIsNRAConfirmed = createSelector(
  getLocalState,
  (state) => state.isNRAConfirmed,
);

const getIsWaitingAcceptFromCustomer = createSelector(
  getLocalState,
  (state) => state.isWaitingAcceptFromCustomer,
);

const getIsAcceptWithLoading = createSelector(
  getIsWaitingAcceptFromCustomer,
  getLoadingStatus,
  (isWaitingAcceptFromCustomer, isLoading) => isWaitingAcceptFromCustomer || isLoading,
);

const getIsAbleToAcceptCustomerDrawer = createSelector(
  getIsTermsAndConditionsConfirmed,
  getIsNRAConfirmed,
  (isTermsAndConditionsConfirmed, isNRAConfirmed) => isTermsAndConditionsConfirmed && isNRAConfirmed,
);

const getChargesState = createSelector(
  getLocalState,
  (state) => state.charges,
);

const getIsLoadingCharges = createSelector(
  getChargesState,
  (charges) => charges.isLoading,
);

const getCharges = createSelector(
  getChargesState,
  (state) => state.data,
);

const getAppliedCharges = createSelector(
  getCharges,
  (charges) => charges.filter((item) => item.applied && item.active),
);

const getBuyTotalCost = createSelector(
  getAppliedCharges,
  (charges) => charges.reduce((acc, cur) => acc + cur.buyTotalCost, 0),
);

const getTotalCost = createSelector(
  getAppliedCharges,
  (charges) => charges.reduce((acc, cur) => acc + cur.totalCost, 0),
);

const getTotalProfit = createSelector(
  getBuyTotalCost,
  getTotalCost,
  (buyTotalCost, totalCost) => totalCost - buyTotalCost,
);

const getTotalProfitPercent = createSelector(
  getTotalProfit,
  getBuyTotalCost,
  (profit, totalCost) => (profit / totalCost) * 100,
);

const getArBudgetTotalCost = createSelector(
  getAppliedCharges,
  (charges) => {
    const filtered = charges.filter((item) => !item.additional);
    return filtered.reduce((acc, cur) => acc + (cur.arBudget?.totalCost || 0), 0);
  },
);

const getApBudgetTotalCost = createSelector(
  getAppliedCharges,
  (charges) => {
    const filtered = charges.filter((item) => !item.additional);
    return filtered.reduce((acc, cur) => acc + (cur.apBudget?.totalCost || 0), 0);
  },
);

const getReceivablesDifferent = createSelector(
  getTotalCost,
  getArBudgetTotalCost,
  (totalCost, arBudget) => totalCost - arBudget,
);

const getPayablesDifferent = createSelector(
  getBuyTotalCost,
  getApBudgetTotalCost,
  (totalCost, arBudget) => totalCost - arBudget,
);

const getReceivablesDifferentPercent = createSelector(
  getReceivablesDifferent,
  getArBudgetTotalCost,
  (delta, arBudget) => (delta / arBudget) * 100,
);

const getPayablesDifferentPercent = createSelector(
  getPayablesDifferent,
  getApBudgetTotalCost,
  (delta, arBudget) => (delta / arBudget) * 100,
);

const getRollWarningInfo = createSelector(
  getLocalState,
  (state) => state.rollWarningInfo,
);

const getStats = createSelector(
  getLocalState,
  (state) => state.stats.data,
);

const getStatsLoading = createSelector(
  getLocalState,
  (state) => state.stats.isLoading,
);

const getTotalInvoiced = createSelector(
  getStats,
  (stats) => stats.totalInvoiced,
);

const getTotalUnInvoiced = createSelector(
  getStats,
  (stats) => stats.totalUnInvoiced,
);

const getTotalPaid = createSelector(
  getStats,
  (stats) => stats.totalPaid,
);

const getTotalInvoicedPercent = createSelector(
  getTotalInvoiced,
  getTotalUnInvoiced,
  (invoiced, unInvoiced) => {
    if (unInvoiced === 0 && invoiced === 0) {
      return 0;
    }
    if (unInvoiced === 0) {
      return 100;
    }
    if (invoiced === 0) {
      return 0;
    }
    return (invoiced * 100) / (invoiced + unInvoiced);
  },
);

const getTotalPaidPercent = createSelector(
  getTotalPaid,
  getTotalInvoiced,
  getTotalUnInvoiced,
  (paid, invoiced, unInvoiced) => {
    if (invoiced === 0 && unInvoiced === 0 && paid === 0) {
      return 0;
    }
    if (paid === invoiced + unInvoiced) {
      return 100;
    }
    if (paid === 0) {
      return 0;
    }
    return (paid * 100) / (invoiced + unInvoiced);
  },
);

const getIsCancelShipmentModalOpened = createSelector(
  getLocalState,
  (state) => state.isCancelShipmentModalOpened,
);

const getShipmentCancellationType = createSelector(
  getLocalState,
  (state) => state.shipmentCancellationType,
);

const getShipmentCancellationMessage = createSelector(
  getLocalState,
  (state) => state.shipmentCancellationMessage,
);

const getIsCancelShipmentPending = createSelector(
  getLocalState,
  (state) => state.isCancelShipmentPending,
);

const getShipmentId = createSelector(
  getLocalState,
  (state) => state.shipmentId,
);

const getManualReferenceNumber = createSelector(
  getLocalState,
  (state) => state.manualReferenceNumber,
);

const getIsManualReferenceNumberDrawerPending = createSelector(
  getLocalState,
  (state) => state.isManualReferenceNumberDrawerPending,
);

export const overviewSelectors = {
  getServerError,
  getRequestsResult,
  getCargos,
  getContainersSumWithType,
  getHouseShipper,
  getHouseConsignee,
  getHouseNotifyParty,
  getLoadingStatus,
  getReferences,
  getShipmentReference,
  getModalShipmentReference,
  getModalShipmentReferenceIsError,
  getWasManualBookingConfirmationSuccessful,
  getAccountHolder,
  getForwarderAgent,
  getIsCustomerBookingDrawerOpened,
  getDeliveryAgent,
  getIsTermsAndConditionsConfirmed,
  getIsNRAConfirmed,
  getIsWaitingAcceptFromCustomer,
  getIsAbleToAcceptCustomerDrawer,
  getIsLoadingCharges,
  getTotalProfit,
  getTotalProfitPercent,
  getTotalCost,
  getArBudgetTotalCost,
  getReceivablesDifferent,
  getReceivablesDifferentPercent,
  getPayablesDifferent,
  getPayablesDifferentPercent,
  getBuyTotalCost,
  getApBudgetTotalCost,
  getRollWarningInfo,
  getTotalInvoiced,
  getTotalInvoicedPercent,
  getTotalPaid,
  getTotalPaidPercent,
  getStatsLoading,
  getOceanCarrierScac,
  getIsAcceptWithLoading,
  getIsCancelShipmentModalOpened,
  getShipmentCancellationType,
  getShipmentCancellationMessage,
  getIsCancelShipmentPending,
  getShipmentId,
  getManualReferenceNumber,
  getIsManualReferenceNumberDrawerPending,
};
