import { ENotificationStatus } from 'app-wrapper/constants';
import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { instanceToPlain } from 'app-wrapper/utils';
import { BaseController, controller } from 'proto/BaseController';
import { EContainerVGMTypes, ETasksType, EContainerVGMMethodTypes } from 'shipment-operations/constants';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import { R as shipmentR } from 'shipment-operations/repository';

@controller
export class VGMTaskDrawerController extends BaseController {
  public init = async (shipmentId: string) => {
    this.dispatch(R.actions.vgmTaskDrawer.setIsLoading(true));

    const shipment = await shipmentR.services.shipment.getShipmentShortById(+shipmentId);
    const containers = await shipmentR.services.shipmentContainers.getContainersList(shipmentId);
    const markedContainers = containers.map((container) => (container.vgm && container.vgm.status === EContainerVGMTypes.SUBMITTED ? container.id : ''))
      .filter((item) => !!item.length);

    const commandCenterState = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const commandCenterTasks = [
      ...commandCenterState.content,
      ...(commandCenterState.contentLater || []),
      ...(commandCenterState.contentToday || []),
      ...(commandCenterState.contentThisWeek || []),
      ...(commandCenterState.contentOverdue || []),
    ];
    const overviewTasks = R.selectors.overview.getTasks(this.store.getState());
    const task = [...commandCenterTasks, ...overviewTasks].find(({ metadata }) => metadata && metadata.shipmentId === String(shipment.id) && metadata.taskType === ETasksType.VGM_TASK);

    this.dispatch(R.actions.vgmTaskDrawer.setDueDate(task?.dueDate || null));
    this.dispatch(R.actions.vgmTaskDrawer.setMarkedContainers(markedContainers));
    this.dispatch(R.actions.vgmTaskDrawer.setContainers(containers));
    this.dispatch(R.actions.vgmTaskDrawer.setShipment(shipment));
    this.dispatch(R.actions.vgmTaskDrawer.setIsLoading(false));
  };

  public markAllContainers = () => {
    const containers = R.selectors.vgmTaskDrawer.getContainers(this.store.getState());

    this.dispatch(R.actions.vgmTaskDrawer.setMarkedContainers(containers.map(({ id }) => id)));
  };

  public markContainer = (id: string) => {
    const markedContainers = R.selectors.vgmTaskDrawer.getMarkedContainers(this.store.getState());

    this.dispatch(R.actions.vgmTaskDrawer.setMarkedContainers([
      ...markedContainers,
      id,
    ]));
  };

  public acceptVGM = async () => {
    const shipment = R.selectors.vgmTaskDrawer.getShipment(this.store.getState());
    let containers = R.selectors.vgmTaskDrawer.getContainers(this.store.getState());

    if (!shipment) {
      return;
    }

    this.dispatch(R.actions.vgmTaskDrawer.setIsLoading(true));

    containers = containers.map((container) => ContainerDTM.fromPlain({
      ...container,
      vgm: {
        value: 0,
        status: EContainerVGMTypes.SUBMITTED,
        method: EContainerVGMMethodTypes.CARGO_WEIGHT_ADDED_TO_CONTAINER,
      },
    }));

    await Promise.all(containers.map((container) => shipmentR.services.shipmentContainers.putContainer(String(shipment.id), container)));

    const commandCenterState = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const commandCenterTasks = [
      ...commandCenterState.content,
      ...(commandCenterState.contentLater || []),
      ...(commandCenterState.contentToday || []),
      ...(commandCenterState.contentThisWeek || []),
      ...(commandCenterState.contentOverdue || []),
    ];
    const overviewTasks = R.selectors.overview.getTasks(this.store.getState());
    const task = [...commandCenterTasks, ...overviewTasks].find(({ metadata }) => metadata && metadata.shipmentId === String(shipment.id) && metadata.taskType === ETasksType.VGM_TASK);

    await R.services.CommandCenter.putTask({
      ...instanceToPlain(task),
      status: ENotificationStatus.DONE,
      dueDate: this.currentStore?.state.changeRequest?.dueDate,
      createdAt: this.currentStore?.state.changeRequest?.createdAt,
    });

    UC.CommandCenter.removeTaskFromTheStore(String(shipment.id), ETasksType.VGM_TASK);
    UC.overview.removeTaskFromDashboard(String(shipment.id), ETasksType.VGM_TASK);

    this.closeVGMDrawer();
  };

  public closeVGMDrawer = () => {
    this.dispatch(R.actions.vgmTaskDrawer.setIsLoading(false));
    new DrawersUseCase(this).closeDrawer();
  };
}
