import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Typography from 'antd/es/typography';
import BellOutlined from '@ant-design/icons/BellOutlined';
import moment from 'moment-timezone';

import { IncompleteStub } from 'shipment-operations/view/components';
import { RouteNames } from 'app-wrapper/constants';

import {
  TrackerHeaderDatesContainer, TrackerHeaderDateWithLabel, Label, BellFilled,
} from './TrackerHeaderDates.styled';

const timeFormat = 'DD MMM, HH:mm';

export interface ITrackerHeaderDatesProps {
  earliestCyEmptyRelease?: string
  earliestCtoFullDropDate?: string
  portCutoff?: string
  vgmCutoff?: string
  documentCutOff?: string
  isImport?: boolean
}

export const TrackerHeaderDates: FC<ITrackerHeaderDatesProps> = ({
  earliestCyEmptyRelease,
  earliestCtoFullDropDate,
  portCutoff,
  vgmCutoff,
  documentCutOff,
  isImport = false,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const datesRedirectURL = useMemo(() => RouteNames.SHIPMENT_LOCATIONS(shipmentId), [shipmentId]);

  return (
    <TrackerHeaderDatesContainer>
      <TrackerHeaderDateWithLabel>
        {isImport ? (
          <>
            <Label>{t('Terminal Release Date')}</Label>
            {earliestCyEmptyRelease ? (
              <Typography.Text>
                {moment(earliestCyEmptyRelease).format(timeFormat)}
              </Typography.Text>
            ) : (
              <IncompleteStub
                nameTag={t('TBD')}
                hideNavigateIcon
                redirectURL={datesRedirectURL}
              />
            )}
          </>
        ) : (
          <>
            <Label>{t('Earliest CY Empty Release')}</Label>
            {earliestCyEmptyRelease ? (
              <Typography.Text>
                {moment(earliestCyEmptyRelease).format(timeFormat)}
              </Typography.Text>
            ) : (
              <IncompleteStub
                nameTag={t('TBD')}
                hideNavigateIcon
                redirectURL={datesRedirectURL}
              />
            )}
          </>
        )}
      </TrackerHeaderDateWithLabel>
      <TrackerHeaderDateWithLabel>
        {isImport ? (
          <>
            <Label>{t('Customs Release Date')}</Label>
            {earliestCtoFullDropDate ? (
              <Typography.Text>
                {moment(earliestCtoFullDropDate).format(timeFormat)}
              </Typography.Text>
            ) : (
              <IncompleteStub
                nameTag={t('TBD')}
                hideNavigateIcon
                redirectURL={datesRedirectURL}
              />
            )}
          </>
        ) : (
          <>
            <Label>{t('Earliest CTO Full Drop Date')}</Label>
            {earliestCtoFullDropDate ? (
              <Typography.Text>
                {moment(earliestCtoFullDropDate).format(timeFormat)}
              </Typography.Text>
            ) : (
              <IncompleteStub
                nameTag={t('TBD')}
                hideNavigateIcon
                redirectURL={datesRedirectURL}
              />
            )}
          </>
        )}
      </TrackerHeaderDateWithLabel>
      {!isImport && (
        <>
          <TrackerHeaderDateWithLabel>
            <Label>
              {t('Port Cutoff')}
              {portCutoff && moment().diff(portCutoff, 'days') >= -5 ? (
                <BellFilled />
              ) : (
                <BellOutlined />
              )}
            </Label>
            <Typography.Text>{portCutoff ? moment(portCutoff).format(timeFormat) : ''}</Typography.Text>
          </TrackerHeaderDateWithLabel>
          <TrackerHeaderDateWithLabel>
            <Label>
              {t('VGM Cutoff')}
              {vgmCutoff && moment().diff(vgmCutoff, 'days') >= -5 ? (
                <BellFilled />
              ) : (
                <BellOutlined />
              )}
            </Label>
            <Typography.Text>{vgmCutoff ? moment(vgmCutoff).format(timeFormat) : ''}</Typography.Text>
          </TrackerHeaderDateWithLabel>

          <TrackerHeaderDateWithLabel>
            <Label>
              {t('Doc Cutoff')}
              {documentCutOff && moment().diff(documentCutOff, 'days') >= -5 ? (
                <BellFilled />
              ) : (
                <BellOutlined />
              )}
            </Label>
            <Typography.Text>{documentCutOff ? moment(documentCutOff).format(timeFormat) : ''}</Typography.Text>
          </TrackerHeaderDateWithLabel>
        </>
      )}
    </TrackerHeaderDatesContainer>
  );
};
