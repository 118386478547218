import styled from 'styled-components';

export const GapHorizontalContainerStyled = styled.div.attrs((props: {width?: string}) => ({
  'data-class': 'GapHorizontalContainerStyled',
  width: props.width,
}))`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${({ width }) => (width ? `width: ${width};` : 'width: 100%;')}
  justify-content: flex-start;
`;
