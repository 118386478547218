import styled from 'styled-components';
import AntRadio from 'antd/es/radio';

export const RadioStyled = styled(AntRadio)`
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-checked::after,
  .ant-radio:hover .ant-radio-inner,
  &:hover .ant-radio-checked .ant-radio-inner,
  &:hover .ant-radio-checked::after {
    border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding9} !important;
  }

  .ant-radio-checked .ant-radio-inner::after,
  .ant-radio-inner::after,
  &:hover .ant-radio-checked .ant-radio-inner::after {
    background-color: ${({ theme }) => theme?.themesColors?.neutralBranding9};
  }
`;
