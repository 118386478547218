import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { Textarea as TextareaComponent } from 'app-wrapper/view/components';
import {
  BodyTextMedium,
  SmallTextFootnoteDescription,
} from 'app-wrapper/view/fonts';
import {
  FlexRow,
  FlexCol,
  SubPageContent,
} from 'app-wrapper/view/guideline';

export const Layout = styled(SubPageContent)``;

export const Title = styled.span`
  ${BodyTextMedium}
`;

export const Content = styled(FlexRow)`
  align-items: flex-start;
  gap: 8px;

  input {
    height: 32px;
    min-height: 32px;
  }
`;

export const Column = styled(FlexCol)`
  width: 50%;
  gap: 8px;
`;

export const Section = styled(FlexCol)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  padding: 16px;
`;

export const CompanySection = styled(Section)`
  min-height: 88px;
`;

export const AddressSection = styled(Section)`
  min-height: 344px;
`;

export const ContactSection = styled(Section)`
  min-height: 198px;
`;

export const DetailsSection = styled(Section)`
  min-height: 274px;
`;

export const ReferencesSection = styled(Section)`
  min-height: 102px;
`;

export const SectionTitle = styled.span`
  ${BodyTextMedium}
`;

export const Label = styled.span`
  margin-top: 16px;
  margin-bottom: 4px;
  ${SmallTextFootnoteDescription}
`;

export const LabelGrey = styled(Label)`
  color: ${colors.gray61};
`;

export const Textarea = styled(TextareaComponent)`
  height: 152px;
  max-height: 152px;
`;
