import i18n from 'i18next';

import { EMembershipType } from './MembershipType.enum';

export const MembershipTypeNames = {
  [EMembershipType.WCA_ID]: i18n.t('WCA ID'),
  [EMembershipType.DNB_ID]: i18n.t('DNB ID'),
  [EMembershipType.XLP]: i18n.t('XLP'),
  [EMembershipType.GAA]: i18n.t('GAA'),
  [EMembershipType.JC_TRANS]: i18n.t('JCTrans'),
  [EMembershipType.GLA]: i18n.t('GLA'),
};
