import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MEMBERSHIP_REFERENCES_MAX_LENGTHS, MAX_LENGTH_9 } from 'app-wrapper/constants';
import { Option, Tooltip } from 'app-wrapper/view/components';
import { EBusinessRegistrationNumberType, EMembershipType, MembershipTypeNames } from 'user-management/constants';
import {
  OrganizationDTM,
  BusinessRegistrationNumberDtm,
  OrganizationFieldsErrorsDTM,
  OrganizationFieldsErrorsFieldDTM,
} from 'user-management/models/dtm';

import {
  EditProfileOrganizationFinancial,
  EditProfileOrganizationFinancialSelect,
  EditProfileOrganizationHeader,
  EditProfileOrganizationInput,
  EditProfileOrganizationSpaceColumn,
  EditProfileOrganizationSpaceGroup,
  EditProfileOrganizationSpaceInput,
  EditProfileOrganizationSpaceRow,
  EditProfileOrganizationTitle,
  EditProfileOrganizationMembershipCountButton,
  EditProfileOrganizationSpaceGroupFlex,
  EditProfileMembershipInput,
} from './CustomerAddInternalFinancial.styled';

interface IEditProfileOrganizationFinancialProps {
  organization?: OrganizationDTM
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  onChangeUpdateBusinessRegistrationNumber: (businessRegistrationNumber: BusinessRegistrationNumberDtm) => void
  onAddMembership: () => void
  onRemoveMembership: (id: string) => void
  onChangeUpdateMembershipType: (id: string, type: EMembershipType) => void
  onChangeUpdateMembershipValue: (id: string, value: string) => void
  onChangeUpdateTaxId: (taxId: string) => void
  allowedMembershipTypes: EMembershipType[];
  onBlurUpdateBusinessRegistrationNumber: () => void
  onBlurUpdateMembership: (id: string) => void
  onBlurUpdateTaxId: () => void
}

export const CustomerAddInternalFinancialComponent: FC<IEditProfileOrganizationFinancialProps> = ({
  organization,
  organizationFieldsErrors,
  onChangeUpdateBusinessRegistrationNumber,
  onAddMembership,
  onRemoveMembership,
  onChangeUpdateMembershipType,
  onChangeUpdateMembershipValue,
  allowedMembershipTypes,
  onChangeUpdateTaxId,
}) => {
  const { t } = useTranslation();

  const onChangeUpdateBusinessRegistrationNumberHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newBusinessRegistrationNumberHandler = BusinessRegistrationNumberDtm.fromPlain({
      type: EBusinessRegistrationNumberType.EIN,
      value: event.target.value,
    });
    onChangeUpdateBusinessRegistrationNumber(newBusinessRegistrationNumberHandler);
  }, []);

  const onChangeUpdateMembershipTypeHandler = useCallback((id: string) => (type: EMembershipType) => {
    onChangeUpdateMembershipType(id, type);
  }, []);

  const onChangeUpdateMembershipValueHandler = useCallback((id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateMembershipValue(id, event.target.value);
  }, []);

  const onAddMembershipHandler = useCallback(() => {
    onAddMembership();
  }, []);

  const onRemoveMembershipHandler = useCallback((id: string) => () => {
    onRemoveMembership(id);
  }, []);

  const onChangeTaxId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateTaxId(event.target.value);
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.message),
    [],
  );

  const isBusinessRegNumberErrorTooltipVisible = !!organizationFieldsErrors?.businessRegistrationNumber?.message
    && organizationFieldsErrors?.businessRegistrationNumber?.message !== t('basicErrors.REQUIRED_MESSAGE');
  const businessRegNumberErrorTooltipTitle = organizationFieldsErrors?.businessRegistrationNumber?.message === t('basicErrors.REQUIRED_MESSAGE')
    ? '' : organizationFieldsErrors?.businessRegistrationNumber?.message || '';

  return (
    <EditProfileOrganizationFinancial>
      <EditProfileOrganizationHeader>
        {t('Business Identifiers')}
      </EditProfileOrganizationHeader>
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Company Registration ID')} *`}
          </EditProfileOrganizationTitle>
          <EditProfileOrganizationSpaceGroupFlex>
            {/* Currently off */}
            {/* <EditProfileOrganizationFinancialSelect
              disabled
              showSearch
              value={EBusinessRegistrationNumberType.EIN}
              optionFilterProp="children"
            >
              <Option value={EBusinessRegistrationNumberType.EIN}>{EBusinessRegistrationNumberType.EIN}</Option>
            </EditProfileOrganizationFinancialSelect> */}
            <Tooltip
              visible={isBusinessRegNumberErrorTooltipVisible}
              title={businessRegNumberErrorTooltipTitle}
              placement="bottom"
              mode="danger"
            >
              <EditProfileOrganizationInput
                // placeholder="xx - xxxxxxxxx"
                data-class="CAIInputBusinessRegistrationNumber"
                value={organization?.businessRegistrationNumber.getValidValue()}
                hasError={getHasErrorField(organizationFieldsErrors?.businessRegistrationNumber)}
                onChange={onChangeUpdateBusinessRegistrationNumberHandler}
              />
            </Tooltip>
          </EditProfileOrganizationSpaceGroupFlex>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('TaxID')}`}
          </EditProfileOrganizationTitle>
          <EditProfileOrganizationInput
            value={organization?.taxId}
            hasError={getHasErrorField(organizationFieldsErrors?.taxId)}
            onChange={onChangeTaxId}
          />
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Membership')}`}
          </EditProfileOrganizationTitle>
          {
            organization?.memberships?.map((item, index) => {
              const error = organizationFieldsErrors?.memberships?.find((errorItem) => errorItem?.id === item.id);
              const isErrorTooltipVisible = !!error?.message && error?.message !== t('basicErrors.REQUIRED_MESSAGE') && !!error?.isVisited;
              const errorTooltipTitle = error?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : error?.message || '';

              return (
                <EditProfileOrganizationSpaceGroup key={item.id}>
                  <EditProfileOrganizationFinancialSelect
                    showSearch
                    value={item.type}
                    optionFilterProp="children"
                    onChange={onChangeUpdateMembershipTypeHandler(item.id)}
                  >
                    {allowedMembershipTypes.includes(EMembershipType.WCA_ID) ? (
                      <Option value={EMembershipType.WCA_ID}>{MembershipTypeNames[EMembershipType.WCA_ID]}</Option>
                    ) : null}

                    {allowedMembershipTypes.includes(EMembershipType.DNB_ID) ? (
                      <Option value={EMembershipType.DNB_ID}>{MembershipTypeNames[EMembershipType.DNB_ID]}</Option>
                    ) : null}

                    {allowedMembershipTypes.includes(EMembershipType.XLP) ? (
                      <Option value={EMembershipType.XLP}>{MembershipTypeNames[EMembershipType.XLP]}</Option>
                    ) : null}

                    {allowedMembershipTypes.includes(EMembershipType.GAA) ? (
                      <Option value={EMembershipType.GAA}>{MembershipTypeNames[EMembershipType.GAA]}</Option>
                    ) : null}

                    {allowedMembershipTypes.includes(EMembershipType.JC_TRANS) ? (
                      <Option value={EMembershipType.JC_TRANS}>{MembershipTypeNames[EMembershipType.JC_TRANS]}</Option>
                    ) : null}

                    {allowedMembershipTypes.includes(EMembershipType.GLA) ? (
                      <Option value={EMembershipType.GLA}>{MembershipTypeNames[EMembershipType.GLA]}</Option>
                    ) : null}
                  </EditProfileOrganizationFinancialSelect>
                  <Tooltip
                    visible={isErrorTooltipVisible}
                    title={errorTooltipTitle}
                    placement="bottom"
                    mode="danger"
                  >
                    <EditProfileMembershipInput
                      value={item.value}
                      hasError={getHasErrorField(error)}
                      onChange={onChangeUpdateMembershipValueHandler(item.id)}
                      onBlur={onBlurUpdateMembershipHandler(item.id)}
                      maxLength={item.type ? MEMBERSHIP_REFERENCES_MAX_LENGTHS[item.type] : MAX_LENGTH_9}
                    />
                  </Tooltip>
                  {
                    index === 0 && (
                      <EditProfileOrganizationMembershipCountButton
                        type="dashed"
                        disabled={!allowedMembershipTypes.length}
                        icon={<PlusOutlined />}
                        onClick={onAddMembershipHandler}
                      />
                    )
                  }
                  {
                    index !== 0 && (
                      <EditProfileOrganizationMembershipCountButton
                        type="dashed"
                        icon={<MinusOutlined />}
                        onClick={onRemoveMembershipHandler(item.id)}
                      />
                    )
                  }
                </EditProfileOrganizationSpaceGroup>
              );
            })
          }
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%" />
      </EditProfileOrganizationSpaceRow>
      <EditProfileOrganizationSpaceInput />
    </EditProfileOrganizationFinancial>
  );
};
