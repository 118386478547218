export const CARGO = 'CARGO';
export const TRANSPORTATION_PLAN = 'TRANSPORTATION_PLAN';
export const CUTOFF = 'CUTOFF';
export const LOCATIONS = 'locations';
export const SHIPMENT_PARTY = 'SHIPMENT_PARTY';

export const GENSET = 'GENSET';
export const SOC = 'SOC';

export const ETD = 'ETD';
export const ETA = 'ETA';
export const VOYAGE = 'VOYAGE';
export const VESSEL = 'VESSEL';
export const PORT = 'PORT';
export const VGM = 'VGM';
export const DOCUMENTATION = 'DOCUMENTATION';
export const ORIGIN_CONTAINER_YARD = 'ORIGIN_CONTAINER_YARD';
export const TERMINAL_NAME = 'TERMINAL_NAME';
export const TERMINAL_DATE = 'TERMINAL_DATE';
export const ORIGIN_CONTAINER_TERMINAL = 'ORIGIN_CONTAINER_TERMINAL';

export const REFERENCES = 'REFERENCE';

export const LOAD_PLAN = 'LOAD_PLAN';

export const TYPE = 'TYPE';

export const WATER = 'WATER';
export const SEA = 'SEA';
export const ROAD = 'ROAD';
export const RAIL = 'RAIL';
