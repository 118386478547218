import styled from 'styled-components';

interface PhaseProps {
  excluded?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
`;

export const HeaderWrapper = styled.div.attrs((props: {
  light: boolean
  'data-class'?: string
}) => ({
  light: props.light,
  'data-class': props?.['data-class'] || 'HeaderWrapper',
}))`
  background-color: ${({ light }) => (light ? 'white' : '#202C3C')};
  height: 113px;
  min-height: 113px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ light }) => (light ? '#202C3C' : 'white')};
  margin-top: -42px;
`;

export const Logo = styled.div`
  width: auto;
`;

export const HeaderData = styled.div`
  display: flex;
`;

export const HeaderValue = styled.p`
  font-weight: 400;
  font-size: 13.5px;
  margin: 4px 0;
`;

export const HeaderBoldValue = styled(HeaderValue)`
  font-weight: 700;
`;

export const QuoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding: 0 32px;
`;

export const QuoteData = styled.div`
  width: 60%;
  align-items: flex-start;
`;

export const QuoteTotal = styled.div`
  width: 35%;
  align-items: flex-start;
`;

export const QuoteTotalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuoteTitle = styled.span`
  font-weight: 600;
  font-size: 25px;
  color: #202C3C;
`;

export const QuoteTitleValue = styled(QuoteTitle)`
  font-weight: 400;
`;

export const ValidDates = styled.div`
  font-weight: 400;
  font-size: 13.5px;
  color: #202C3C;
  margin: 10.6px 0 5.3px 0;
`;

export const Text = styled.div`
  text-align: justify;
  display: block;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const ValidDatesBold = styled.span`
  font-weight: 700;
  font-size: 13.5px;
  color: #202C3C;
`;

export const Separator = styled.div`
  width: 100%;
  height: 0.3px;
  background-color: #D1D7E2;
`;

export const QuoteCompany = styled.div`
  display: flex;
`;

export const QuoteValue = styled(HeaderValue)`
  color: #202C3C;
`;

export const QuoteValueBold = styled(HeaderBoldValue)`
  color: #202C3C;
`;

export const TotalItemWrapper = styled.div<PhaseProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6.5px 0;
  font-weight: 400;
  font-size: 13.5px;
  color: ${({ excluded }) => (excluded && '#D1D7E2')};
`;

export const StyledPaymentTerms = styled.span`
  text-transform: capitalize;
`;

export const Section = styled.div`
  padding: 0 32px;
  margin-top: 42.5px;
`;

export const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #202C3C;
  margin-bottom: 8px;
  page-break-inside: avoid;
`;

export const SectionTitleRegular = styled(SectionTitle)`
  font-weight: 400;
`;

export const SectionSubTitle = styled.span`
  font-weight: 700;
  font-size: 13.5px;
  color: black;
  margin-top: 16px;
  margin-bottom: 6.5px;
  display: block;
`;

export const Value = styled.span`
  font-weight: 400;
  font-size: 13.5px;
  color: #202C3C;
`;

export const ValueBold = styled.span`
  font-weight: 700;
  font-size: 13.5px;
  color: #202C3C;
`;

export const Table = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'FreeTimeTable',
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const TableHeader = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'FreeTimeTableHeader',
}))`
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 10.6px;
  color: #202C3C;
`;

export const TableBody = styled.div`
  font-size: 13.5px;
  color: #202C3C;
  font-weight: 400;
`;

export const TableRow = styled.div<PhaseProps>`
  width: 100%;
  display: flex;
  padding: 5.3px 0;
  color: ${({ excluded }) => (excluded && '#D1D7E2')};
`;

export const TableTitleExcludedText = styled.div`
  display: inline-flex;
  align-items: end;
  margin-left: 4px;
  padding-top: 4px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-style: italic;
  font-weight: 400;
  ::before {
    content: '(';
  }
  &&::after {
    content: ')';
  }
`;

export const TableName = styled.div`
  font-weight: 600;
  font-size: 10.6px;
  color: black;
  display: block;
  margin-top: 18px;
`;

export const SubTotal = styled.div`
  width: 100%;
  height: 21px;
  background: #F4F5F8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 13.5px;
  color: #000000;
  padding: 0 10.6px;
  margin: 26.5px 0;
  border-radius: 3px;
`;

export const BoldSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: black;
`;

export const Total = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 16px;
  color: black;
`;

export const FreeTimeSubHeader = styled.div.attrs({
  'data-class': 'FreeTimeSubHeader',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Label = styled.div`
  width: auto;
  padding: 0 10.5px;
  background: #F4F5F8;
  border-radius: 2px;
  font-weight: 400;
  font-size: 10.5px;
  color: black;
`;

export const TermsWrapper = styled.div.attrs({
  'data-class': 'TermsWrapper',
})`
  margin-top: 26.5px;
  font-weight: 400;
  font-size: 10.5px;
  color: black;
  
  ul {
    padding-left: 16px;
  }
  
  li {
    margin-top: 6.5px;
  }
  
  p {
    margin: 0 0 10px 0;
  }
`;

export const CustomHeaderWrapper = styled.div.attrs({
  'data-class': 'CustomHeaderWrapper',
})`
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

export const CustomRowWrapperRest = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

export const CustomRowWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 26px;
  align-items: center;
`;

export const PageBreak = styled.div`
  page-break-before: always;
`;

export const TermsSubTitle = styled.div`
  font-weight: 700;
  font-size: 13.5px;
  margin: 20px 0 10.5px 0;
  background: #F4F5F8;
  padding-left: 10px;
`;
