import styled, { css } from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Input, Select } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const EditProfileOrganizationTitle = styled.div.attrs({
  'data-class': 'EditProfileOrganizationTitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const EditProfileOrganizationHeader = styled.div.attrs({
  'data-class': 'EditProfileOrganizationHeader',
})`
  ${divFlex}
  justify-content: start;

  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const EditProfileOrganizationInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'EditProfileOrganizationInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;
  margin-right: 4px;

  ${responsive.forFullMedia`
    min-height: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const EditProfileMembershipInput = styled(EditProfileOrganizationInput).attrs({
  'data-class': 'EditProfileMembershipInput',
})``;

export const EditProfileOrganizationSpaceInput = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSpaceInput',
})`
  ${divFlex}
  width: 100%;

  margin-bottom: 16px;
`;

export const EditProfileOrganizationSpaceRow = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSpaceRow',
})`
  ${divFlex}
  width: 100%;

  > div:not(:first-child) {
    margin-left: 8px;
  }
`;

export const EditProfileOrganizationSpaceGroup = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSpaceGroup',
})`
  display: grid;
  grid-template-columns: 80px 1fr auto;
  justify-items: center;
  width: 100%;

  > div:not(:first-child) {
    margin-left: 0;
  }
  > button {
    margin-top: 4px;
  }
`;

export const EditProfileOrganizationSpaceGroupFlex = styled.div.attrs({
  'data-class': 'EditProfileOrganizationSpaceGroupFlex',
})`
  ${divFlex}
  justify-items: center;
  width: 100%;

  > div:not(:first-child) {
    margin-left: 0;
  }
  > button {
    margin-top: 4px;
  }
`;

export const EditProfileOrganizationSpaceColumn = styled.div.attrs((props: {
  widthProp: string,
}) => ({
  widthProp: props.widthProp,
  'data-class': 'EditProfileOrganizationSpaceColumn',
}))`
  ${divFlex}
  flex-direction: column;

  ${({ widthProp }) => widthProp && `width: ${widthProp}`};
`;

export const EditProfileOrganizationFinancial = styled.div.attrs({
  'data-class': 'EditProfileOrganizationFinancial',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const EditProfileOrganizationFinancialSelect = styled(Select).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'EditProfileOrganizationGeneralInformationSelect',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;


  .ant-select-item-option-content {
    font-size: 12px;
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  border-width: 0px;
  .ant-select-item-option-content {
    font-family: "Roboto";
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
