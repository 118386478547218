import React, { FC, useCallback } from 'react';

import { Select, Option } from 'app-wrapper/view/components';
import { ICountryListDTM } from 'app-wrapper/models/dtm';

interface ICountryInputComponentProps {
  country?: string;
  setCountry: (country: string) => void;
  error?: boolean;
  countries: ICountryListDTM[];
  isAbleToEdit: boolean;
}

export const CountryInputComponent: FC<ICountryInputComponentProps> = ({
  country,
  setCountry,
  error,
  countries,
  isAbleToEdit,
}) => {
  const handleChange = useCallback((value: string) => {
    setCountry(value);
  }, [setCountry]);

  return (
    <Select
      value={country}
      onChange={handleChange}
      error={error && !country?.length}
      disabled={!isAbleToEdit}
    >
      {countries.map((item) => (
        <Option
          value={item.code}
          key={item.code}
        >
          {item.name}
        </Option>
      ))}
    </Select>
  );
};
