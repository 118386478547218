import styled from 'styled-components';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Row = styled(FlexRow)`
  align-items: center;
`;

export const InfoIcon = styled(InfoCircleFilled)`
  font-size: 12px;
  margin-left: 8px;
  color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
  cursor: pointer;
`;
