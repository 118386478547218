import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentSectionTitle,
  GapVerticalContainerStyled,
  SubPageContent,
  GapHorizontalContainerStyled,
} from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  CutoffContainer,
  LoadingControls,
  SuppliersSchedule,
  CarriersDetails,
  SupplierSection,
} from './components';
import {
  DepartureContainersContainer,
} from './components/DepartureContainers';

interface OverviewComponentProps {
  isLoading: boolean;
}

export const OverviewComponent = ({ isLoading }: OverviewComponentProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <GapVerticalContainerStyled>
        <SkeletonSection height="238px" />
        <SkeletonSection height="182px" />
        <SkeletonSection height="295px" />
        <SkeletonSection height="199px" />
      </GapVerticalContainerStyled>
    );
  }

  return (
    <GapVerticalContainerStyled>
      <SupplierSection />

      <SubPageContent>
        <ContentSectionTitle>
          {t('Loading')}
        </ContentSectionTitle>
        <GapHorizontalContainerStyled>
          <LoadingControls />
          <SuppliersSchedule />
        </GapHorizontalContainerStyled>
      </SubPageContent>
      <SubPageContent>
        <ContentSectionTitle>
          {t('Carrier\'s Details')}
        </ContentSectionTitle>
        <GapVerticalContainerStyled>
          <CarriersDetails />
          <CutoffContainer />
        </GapVerticalContainerStyled>
      </SubPageContent>
      <DepartureContainersContainer />
    </GapVerticalContainerStyled>
  );
};
