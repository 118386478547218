import React, { forwardRef } from 'react';
import { RadioGroupProps } from 'antd/es/radio';

import { RadioGroup } from './RadioGroup.styled';

interface IRadioGroupComponentProps {
  hasError?: boolean
}

// TODO: fix type for ref
const RadioGroupComponent = forwardRef<any, RadioGroupProps & IRadioGroupComponentProps>((props, ref) => (
  <RadioGroup
    ref={ref}
    {...props}
  />
));

export { RadioGroupComponent as RadioGroup };
