import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompanyLogoComponent,
  ContentGap,
  ContentSection,
  InfoCardComponent, MissingTextValue,
} from 'app-wrapper/view/guideline';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import { ECarrierSCAC } from 'monetary/constants';
import { RoadSvg } from 'app-wrapper/view/icons';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';

interface ICarriersDetailsComponentProps {
  carrierSCAC: ECarrierSCAC
  carrierName?: string
  carrierReferenceNumber?: string
}

export const CarriersDetailsComponent: FC<ICarriersDetailsComponentProps> = ({
  carrierSCAC,
  carrierName,
  carrierReferenceNumber,
}) => {
  const { t } = useTranslation();

  return (
    <ContentSection>
      <ContentGap>
        <ContentSectionContainer>
          <CompanyLogoComponent carrierSCAC={carrierSCAC} />
        </ContentSectionContainer>
        <ContentSectionContainer>
          <InfoCardComponent
            title={t('Carrier')}
            value={carrierName ?? <MissingTextValue />}
            icon={<RoadSvg />}
          />
        </ContentSectionContainer>
        <ContentSectionContainer>
          <InfoCardComponent
            title={t('Booking Reference')}
            value={carrierReferenceNumber ?? <MissingTextValue />}
            icon={<BorderlessTableOutlined />}
          />
        </ContentSectionContainer>
      </ContentGap>
    </ContentSection>
  );
};
