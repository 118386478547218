import React, {
  FC, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import TreeSelect from 'antd/es/tree-select';
import type { DataNode } from 'antd/es/tree';
import type { DefaultValueType } from 'rc-tree-select/lib/interface';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import {
  ShipmentDrawerContent,
  CONTAINER, applyToOptions, measureByOptions, phasesOptions, DestinationOption, FreightDestinationOptions,
  OriginOption, OriginFreightOptions,
} from 'shipment-operations/constants';
import { Select } from 'app-wrapper/view/components';

import {
  FieldName, FirstFieldName, InputsHeader, StyledTreeSelect, EditContainerWrapper,
} from 'shipment-operations/view/components/ActiveCharge';
import { ContainerForTableDTM } from 'shipment-operations/models/dtm';

interface IHeaderComponentProps {
  applyTo: string
  measureBy: string
  designation: string
  onChangeDropDown: (value: number, field: string) => void
  currency: string
  dropdownCharges: {
    value: string
    label: string
  }[]
  setCharge: (value: string, mode?: ShipmentDrawerContent, isDrayage?: boolean) => void
  selectedChargeTitle: string
  chargeError: boolean
  containersTree: ContainerForTableDTM[] | []
  selectContainer: (value: DefaultValueType) => void
  containersError: boolean
  errors: string[]
  mode?: ShipmentDrawerContent
  container?: string
  containerType?: string
  modeType: string
  companies?: any
  customerValue?: string
  vendorValue?: string
  isDisableDesignation: boolean
  isExport?: boolean
  isOriginPartner?: boolean
  isDestinationPartner?: boolean
}

const HeaderComponent: FC<IHeaderComponentProps> = ({
  applyTo, currency, dropdownCharges, setCharge, onChangeDropDown, companies,
  selectedChargeTitle, chargeError, measureBy, errors, mode, containerType,
  containersTree, selectContainer, containersError, designation, container, modeType,
  customerValue, vendorValue, isDisableDesignation, isExport, isOriginPartner, isDestinationPartner,
}) => {
  const { t } = useTranslation();
  const { isExportOrImport, isExport: isExportRoute, isImport } = useIsExportOrImport();

  const onChangeMeasureBy = useCallback((value) => onChangeDropDown(value, 'measureBy'), []);
  const onChangeDesignation = useCallback((value) => onChangeDropDown(value, 'designation'), []);
  const onChangeApplyTo = useCallback((value) => onChangeDropDown(value, 'applyTo'), []);

  const onChangeCharge = useCallback((value) => {
    setCharge(value, mode, isExportOrImport);
    if (isExportOrImport) {
      onChangeApplyTo(CONTAINER);
    }
    if (isExportRoute) {
      onChangeDesignation(OriginOption[0].value);
    }
    if (isImport) {
      onChangeDesignation(DestinationOption[0].value);
    }
  }, []);

  const isDisabledMeasureBy = isExportOrImport ? !applyTo : mode === modeType && (applyTo === 'SHIPMENT' ? !applyTo : !containerType);

  const isDisabledChargeTitle = mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE || mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE;
  const isDisabledApplyTo = mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE || mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE || !selectedChargeTitle;
  const isEditableContainer = mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE || mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE;

  const phaseOptions = useMemo(() => {
    if (isOriginPartner) {
      if (isExport) {
        return OriginFreightOptions;
      }
      return OriginOption;
    }
    if (isDestinationPartner) {
      if (isExport) {
        return DestinationOption;
      }
      return FreightDestinationOptions;
    }
    return phasesOptions;
  }, [isExport, isOriginPartner, isDestinationPartner]);

  return (
    <>
      <InputsHeader>
        <FieldName>{t('addChargeMenu.charge')}</FieldName>
      </InputsHeader>
      <Select
        options={dropdownCharges}
        onChange={onChangeCharge}
        value={selectedChargeTitle}
        error={chargeError}
        showSearch
        optionFilterProp="label"
        disabled={isDisabledChargeTitle}
      />

      <FirstFieldName>{t('addChargeMenu.Apply To')}</FirstFieldName>
      <Select
        value={applyTo}
        options={applyToOptions}
        onChange={onChangeApplyTo}
        defaultValue=""
        error={errors.includes('applyTo')}
        disabled={isDisabledApplyTo || isExportOrImport}
      />

      {applyTo === CONTAINER && (
        <>
          {!isEditableContainer ? (
            <>
              <FieldName>{t('addChargeMenu.container')}</FieldName>
              <StyledTreeSelect
                treeData={containersTree as DataNode[]}
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                onChange={selectContainer}
                error={containersError ? 1 : 0}
                allowClear
                showSearch
                showArrow
                suffixIcon={<VerticalFormItemSvg />}
              />
            </>
          ) : (
            <EditContainerWrapper>
              <FieldName>{t('addChargeMenu.container')}</FieldName>
              <span style={{ marginTop: '10px', fontWeight: 500 }}>{container}</span>
            </EditContainerWrapper>
          )}
        </>
      )}

      <FirstFieldName>{t('addChargeMenu.Measure by')}</FirstFieldName>
      <Select
        value={measureBy}
        options={measureByOptions}
        onChange={onChangeMeasureBy}
        defaultValue=""
        error={errors.includes('measureBy')}
        disabled={isDisabledMeasureBy}
      />

      <FirstFieldName>{t('addChargeMenu.phase')}</FirstFieldName>
      <Select
        value={designation}
        options={phaseOptions}
        onChange={onChangeDesignation}
        defaultValue=""
        error={errors.includes('designation')}
        disabled={!measureBy || isDisableDesignation || mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE || isExportOrImport}
      />

      <FieldName>{t('addChargeMenu.currency')}</FieldName>
      <Select value={currency} disabled />

      <FirstFieldName>{t('vendor')}</FirstFieldName>
      <Select
        options={companies}
        error={errors.includes('selectedVendorCompany')}
        disabled
        value={vendorValue}
      />

      <FirstFieldName>{t('customer')}</FirstFieldName>
      <Select
        options={companies}
        error={errors.includes('selectedCustomerCompany')}
        disabled
        value={customerValue}
      />
    </>
  );
};

export { HeaderComponent };
