import styled from 'styled-components';
import Table from 'antd/es/table';
import Button from 'antd/es/button';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface ITableProps {
  isOpen?: boolean,
  active?: boolean,
}

export const PaddingLeftLayout = '24px';

export const Container = styled.div`
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 8px;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(4)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(5)::before {
    display: none;
  }

  .ant-table-tbody tr .ant-table-cell:nth-child(1)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(3)::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  .ant-table-expanded-row .ant-table-cell {
    background: white;
  }

  .customExpandRow {
    top: -1px;
    position: relative;
  }

  .customExpandRow > .ant-table-cell {
    padding-top: 0;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #4E5D70;
  border: none;

  &:hover {
    background-color: #798aa2;
  }
`;

export const FixedBottomWrapper = styled.div`
  position: fixed;
  width: calc(100% + ${PaddingLeftLayout});
  bottom: 0;
  height: 64px;
  background-color: white;
  left: -${PaddingLeftLayout};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const StyledType = styled.div<ITableProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ isOpen }) => isOpen && '#6C79DE'};
`;

export const LinkWrapper = styled.div`
  border: 1px solid #DEE1E9;
  border-radius: 3px;
  padding: 5px 16px;
  cursor: pointer;
  max-width: 120px;
  display: flex;
  align-items: center;
`;
