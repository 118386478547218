import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import React, {
  FC, memo, useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
  AddressBlockSelectComponent,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlockSelect.component';
import { useTranslation } from 'react-i18next';

export const AddressBlockDestinationContainer: FC = memo(() => {
  const getDestinationDoorAddress = useSelector(R.selectors.freightQuote.getDestinationDoorAddress);

  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getDestination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentDestination);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  const getLocationPortStatus = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentLocationPortStatus);

  const errors = useMemo(() => ({
    isPort: getErrors?.destination?.isPort,
    dateDoor: getErrors?.destination?.dateDoor,
    datePort: getErrors?.destination?.datePort,
    location: getErrors?.destination?.location,
    locationDoor: getErrors?.destination?.locationDoor,
  }), [getErrors?.destination]);

  const { t } = useTranslation();

  const notForbiddenOptions = useMemo(
    () => getDestination?.locationValues || [],
    // Revert in next pr
    // () => getDestination?.locationValues?.filter((value) => (getCurrent.incotermsTrade === EFreightIncotermsTrade.IMPORT ? !value.forbidden : true)) || [],
    [getDestination?.locationValues, getCurrent.incotermsTrade],
  );
  const forbiddenOptions = useMemo(
    () => [],
    // Revert in next pr
    // () => getDestination?.locationValues?.filter((value) => (getCurrent.incotermsTrade === EFreightIncotermsTrade.IMPORT ? value.forbidden : false)) || [],
    [getDestination?.locationValues, getCurrent.incotermsTrade],
  );

  return (
    <AddressBlockSelectComponent
      dataClass="destinationAddressCode"
      isAllDisabled={getCurrent.isAllFieldDisabled}
      isIncludeRelatedPort={getCurrent?.form?.isIncludeRelatedPort}
      // destination
      origin={getDestination}
      options={notForbiddenOptions}
      disabledOptions={forbiddenOptions}
      incotermsTrade={getCurrent.incotermsTrade}
      onChangeDoorAutocomplete={UC.FreightQuote.getRFQDoorAutocompleteDestination}
      doorAutocompleteStatus={getCurrent.doorsAutocomplete?.destination?.status || ''}
      toPort={!!getDestination?.isPort}
      onChangeAddress={UC.FreightQuote.onChangeDestinationAddress}
      onFocusAddress={UC.FreightQuote.onFocusDestinationAddress}
      onBlurAddress={UC.FreightQuote.onBlurDestinationAddress}
      onClearAddress={UC.FreightQuote.onClearDestinationAddress}
      getRFQPortAutocomplete={UC.FreightQuote.getRFQPortAutocompleteDestination}
      locationPortStatus={getLocationPortStatus?.destination?.status || ''}
      onKeyPressAddress={UC.FreightQuote.onKeyPressDestinationAddress}
      doorAutocompleteOptions={getDestinationDoorAddress}
      // errors
      errors={errors}
      isSubmitVisited={getCurrent.isSubmitVisited}
      placeholder={`${t('OverviewSecondSectionsFormAddress')} *`}
    />
  );
});
