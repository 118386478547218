import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './ServicesIconSvg.styled';

export const ServicesIconSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="ServicesIconSvg"
      width={width || '15'}
      height={height || '11'}
      viewBox="0 0 15 11"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.0625 9.6875H13.625V8.375C13.625 5.3125 11.3281 2.76953 8.375 2.33203V1.8125H9.6875C9.90625 1.8125 10.125 1.62109 10.125 1.375V0.9375C10.125 0.71875 9.90625 0.5 9.6875 0.5H5.3125C5.06641 0.5 4.875 0.71875 4.875 0.9375V1.375C4.875 1.62109 5.06641 1.8125 5.3125 1.8125H6.625V2.33203C3.64453 2.76953 1.375 5.3125 1.375 8.375V9.6875H0.9375C0.691406 9.6875 0.5 9.90625 0.5 10.125V10.5625C0.5 10.8086 0.691406 11 0.9375 11H14.0625C14.2812 11 14.5 10.8086 14.5 10.5625V10.125C14.5 9.90625 14.2812 9.6875 14.0625 9.6875ZM12.3125 9.6875H2.6875V8.375C2.6875 5.72266 4.82031 3.5625 7.5 3.5625C10.1523 3.5625 12.3125 5.72266 12.3125 8.375V9.6875Z" fill="currentColor" />
    </Icon>
  );
};
