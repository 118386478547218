import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import UserOutlined from '@ant-design/icons/UserOutlined';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  CIPrimaryContactData,
  CIPrimaryContactHeader,
  CIPrimaryContactIconBG,
  CIPrimaryContactItem,
  CIPrimaryContactRow,
  CIPrimaryContactTitle,
  CIPrimaryContactValue,
  CIPrimaryContactWrapper,
} from './CIPrimaryContact.styled';

interface ICIPrimaryContactComponentProps {
  organization?: OrganizationDTM
}

export const CIPrimaryContactComponent: FC<ICIPrimaryContactComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  return (
    <CIPrimaryContactWrapper>
      <CIPrimaryContactHeader>{t('PrimaryContact')}</CIPrimaryContactHeader>
      <CIPrimaryContactRow>
        <CIPrimaryContactItem>
          <CIPrimaryContactIconBG>
            <UserOutlined />
          </CIPrimaryContactIconBG>
          <CIPrimaryContactData>
            <CIPrimaryContactTitle>{t('Name')}</CIPrimaryContactTitle>
            <CIPrimaryContactValue>{organization?.primaryContact?.getFullName() || <NoDataLineItem />}</CIPrimaryContactValue>
          </CIPrimaryContactData>
        </CIPrimaryContactItem>
        <CIPrimaryContactItem>
          <CIPrimaryContactIconBG>
            <MailOutlined />
          </CIPrimaryContactIconBG>
          <CIPrimaryContactData>
            <CIPrimaryContactTitle>{t('Email')}</CIPrimaryContactTitle>
            <CIPrimaryContactValue>{organization?.primaryContact?.email || <NoDataLineItem />}</CIPrimaryContactValue>
          </CIPrimaryContactData>
        </CIPrimaryContactItem>
      </CIPrimaryContactRow>

      <CIPrimaryContactRow>
        <CIPrimaryContactItem>
          <CIPrimaryContactIconBG>
            <PhoneOutlined />
          </CIPrimaryContactIconBG>
          <CIPrimaryContactData>
            <CIPrimaryContactTitle>{t('Phone')}</CIPrimaryContactTitle>
            <CIPrimaryContactValue>{organization?.primaryContact?.phone || <NoDataLineItem />}</CIPrimaryContactValue>
          </CIPrimaryContactData>
        </CIPrimaryContactItem>
      </CIPrimaryContactRow>
    </CIPrimaryContactWrapper>
  );
};
