import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ValueInputComponent } from './ValueInput.component';

interface IValueInputContainerProps {
  cargoId: number;
}

const ValueInputContainer: FC<IValueInputContainerProps> = ({ cargoId }) => {
  const { value = '', errors } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const { value: error } = errors;

  return (
    <ValueInputComponent
      cargoId={cargoId}
      value={value}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setValue={UC.bookingWizard.setValue}
      error={error}
    />
  );
};

export { ValueInputContainer as ValueInput };
