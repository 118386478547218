import React from 'react';
import { observer } from 'mobx-react-lite';

import { useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import { ShipmentTransportationExportInlandPickupOverviewStore } from 'shipment-operations/repository/mobxStores';
import {
  OverviewComponent,
} from './Overview.component';

export const OverviewContainer = observer(() => {
  const store = useCurrentControllerStore<ShipmentTransportationExportInlandPickupOverviewStore>();

  return (
    <OverviewComponent
      isLoading={store.state.isLoading}
    />
  );
});
