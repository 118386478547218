import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { FooterContainer, SpinnerWrap } from 'app-wrapper/view/guideline';
import { Button, FooterSumSub } from 'app-wrapper/view/components';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { normalizationCost, validateDecimalFraction } from 'app-wrapper/utils';

import { CustomModal } from 'shipment-operations/view/components';

import { AdditionalRatesServiceDTM } from 'monetary/models/dtm/Quotas';

import { AddAdditionalServicesSelectItem, BookingAddAdditionalServicesSelectItem, ShipmentAddAdditionalServicesSelectItem } from 'user-management/view/components';

import {
  RFQAddAdditionalServices,
  RFQAddAdditionalServicesContent, RFQAddAdditionalServicesContentItem, RFQAddAdditionalServicesFooter, RFQAddAdditionalServicesFooterSum, RFQAddAdditionalServicesSectionTitle, RFQAddAdditionalServicesTitle, RFQAddAdditionalServicesWrap,
} from './RFQAddAdditionalServices.styled';

interface IRFQAddAdditionalServicesComponentProps {
  servicesOrigin?: AdditionalRatesServiceDTM[];
  servicesFreight?: AdditionalRatesServiceDTM[];
  servicesDestination?: AdditionalRatesServiceDTM[];
  totalCost?: number;
  totalCheckedServices?: number;
  isBooking?: boolean;
  isShipment?: boolean;
  onCloseDrawer: () => void;
  addServicesDrawer: () => void;
  isLoading?: boolean;
}

const RFQAddAdditionalServicesComponent: FC<IRFQAddAdditionalServicesComponentProps> = (props) => {
  const {
    servicesOrigin,
    servicesFreight,
    servicesDestination,
    totalCost,
    totalCheckedServices,
    isBooking,
    isShipment,
    onCloseDrawer,
    addServicesDrawer,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const getItem = useCallback(
    (item: AdditionalRatesServiceDTM) => {
      if (isBooking) {
        return (
          <BookingAddAdditionalServicesSelectItem
            key={item.customId}
            services={item}
          />
        );
      }

      if (isShipment) {
        return (
          <ShipmentAddAdditionalServicesSelectItem
            key={item.customId}
            services={item}
          />
        );
      }

      return (
        <AddAdditionalServicesSelectItem
          key={item.customId}
          services={item}
        />
      );
    },
    [isShipment, isBooking],
  );

  return (
    <CustomModal
      title={t('Add Service(s)')}
      onClose={onCloseDrawer}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : (
        <RFQAddAdditionalServices>
          <RFQAddAdditionalServicesWrap>
            <RFQAddAdditionalServicesContent>
              <RFQAddAdditionalServicesTitle>
                {t('Formalities')}
              </RFQAddAdditionalServicesTitle>

              {servicesOrigin?.length ? (
                <RFQAddAdditionalServicesContentItem>
                  <RFQAddAdditionalServicesSectionTitle>
                    {t('Origin')}
                  </RFQAddAdditionalServicesSectionTitle>

                  {servicesOrigin?.map((item) => getItem(item))}
                </RFQAddAdditionalServicesContentItem>
              ) : null}

              {servicesFreight?.length ? (
                <RFQAddAdditionalServicesContentItem>
                  <RFQAddAdditionalServicesSectionTitle>
                    {t('Freight')}
                  </RFQAddAdditionalServicesSectionTitle>

                  {servicesFreight?.map((item) => getItem(item))}
                </RFQAddAdditionalServicesContentItem>
              ) : null}

              {servicesDestination?.length ? (
                <RFQAddAdditionalServicesContentItem>
                  <RFQAddAdditionalServicesSectionTitle>
                    {t('Destination')}
                  </RFQAddAdditionalServicesSectionTitle>

                  {servicesDestination?.map((item) => getItem(item))}
                </RFQAddAdditionalServicesContentItem>
              ) : null}
            </RFQAddAdditionalServicesContent>
          </RFQAddAdditionalServicesWrap>
        </RFQAddAdditionalServices>
      )}

      <Footer
        position="absolute"
      >
        <FooterContainer>
          <RFQAddAdditionalServicesFooter>
            <RFQAddAdditionalServicesFooterSum>
              Total $ {
                normalizationCost(validateDecimalFraction(`${totalCost || 0}`, 20, 2, { forcePrecision: true }), {
                  toFixed: 2,
                  thousandthSeparatorComma: true,
                })
              }
              <FooterSumSub> / </FooterSumSub>
              {totalCheckedServices || 0} Service(s)
            </RFQAddAdditionalServicesFooterSum>
          </RFQAddAdditionalServicesFooter>
          <Button
            type="default"
            htmlType="submit"
            disabled={false}
            loading={false}
            onClick={onCloseDrawer}
          >
            {t('Cancel')}
          </Button>
          <Button
            data-class="drawer-add-services-button"
            htmlType="submit"
            disabled={false}
            loading={false}
            onClick={addServicesDrawer}
          >
            {t('Add Service(s)')}
          </Button>
        </FooterContainer>
      </Footer>
    </CustomModal>
  );
};

const RFQAddAdditionalServicesComponentCache = memo(RFQAddAdditionalServicesComponent);

export { RFQAddAdditionalServicesComponentCache as RFQAddAdditionalServicesComponent };
