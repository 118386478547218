import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { AddCompanyComponent } from 'user-management/view/components';

const BookingAddCompanyContainer: FC = () => {
  const contactBookDefault = useSelector(R.selectors.bookingWizard.getContactBookDefault);
  const contactBook = useSelector(R.selectors.bookingWizard.getContactBookUpdate);

  const listIndex = contactBook.activeListTabIndex;

  const companyListState = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListByIds(listIndex));
  const errors = useSelector(R.selectors.bookingWizard.getContactBookUpdateErrorsCompanyListStateById(listIndex));

  const addressesIds = companyListState?.addressesIds || [];
  const contactPersonsIds = companyListState?.contactPersonsIds || [];

  const companyListAddresses = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListAddressesByIds(addressesIds));
  const companyListContactPersons = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListContactPersonsByIds(contactPersonsIds));
  const isAddressBlocked = useSelector(R.selectors.bookingWizard.getIsCreateCompanyAddressBlocked);

  const validateAll = UC.bookingWizardDrawer.validateFullShipmentPartyAddress;
  const validateOnly = UC.bookingWizardDrawer.validateOnlyShipmentPartyAddress;

  return (
    <AddCompanyComponent
      companyListState={contactBook.companyList}
      companyListAddresses={companyListAddresses}
      companyListContactPersons={companyListContactPersons}
      companyListStateIndex={listIndex}
      isUpdating={contactBookDefault.getManualRequestStatus === REQUEST_STATUS.pending || !companyListState?.isUpdate}
      errors={errors}
      updateForm={UC.bookingWizardDrawer.onWCBAddCompany}
      onDiscard={UC.bookingWizardDrawer.onCancelBookingAddCompany}
      onChangeListName={UC.bookingWizardDrawer.onChangeListNameByIndex(validateAll)}
      onBlurListName={UC.bookingWizardDrawer.onBlurListNameByIndex(validateOnly)(listIndex)}
      onFocusListName={UC.bookingWizardDrawer.onFocusListNameByIndex(validateOnly)(listIndex)}
      onChangeTaxId={UC.bookingWizardDrawer.onChangeTaxIdByIndex(validateAll)}
      onBlurTaxId={UC.bookingWizardDrawer.onBlurTaxIdByIndex(validateOnly)(listIndex)}
      onFocusTaxId={UC.bookingWizardDrawer.onFocusTaxIdByIndex(validateOnly)(listIndex)}
      isAddressBlocked={isAddressBlocked}
    />
  );
};

const BookingAddCompanyContainerCache = memo(BookingAddCompanyContainer);

export { BookingAddCompanyContainerCache as BookingAddCompanyPage };
