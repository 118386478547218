import { DateDtm } from 'app-wrapper/models/dtm';
import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { Moment } from 'moment';
import { BaseController } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';

export class DatePickerMobxUseCase<T extends InputStore<DateDtm | undefined>> extends BaseUseCase {
  storeMobx: T

  validate: (useCase: DatePickerMobxUseCase<T>) => void

  constructor(
    controller: BaseController,
    storeMobx: T,
    validate?: (useCase: DatePickerMobxUseCase<T>) => void,
  ) {
    super(controller);
    this.storeMobx = storeMobx;
    this.validate = validate || (() => {});
  }

  onChange(value: Moment | null) {
    this.storeMobx.setValue(value ? DateDtm.fromPlain({ date: value.utcOffset(this.storeMobx.state.utcOffset || value.utcOffset(), true).format(), offset: this.storeMobx.state.utcOffset || value.utcOffset() }) : undefined);

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate(this);
  }

  onClear() {
    this.storeMobx.setValue(undefined);

    this.storeMobx.setStates({
      isFocus: true,
      isBlur: false,
    });

    this.validate(this);
  }

  public onBlur() {
    this.storeMobx.setStates({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    });
    this.validate(this);
  }

  public setError(error: string | null) {
    this.storeMobx.setError(error);
  }
}
