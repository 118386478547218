import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import minBy from 'lodash/fp/minBy';

import { ContainerAllTypesNamesShortConst, isContainerAllTypes } from 'shipment-operations/constants';

import { IFreightQuotaContentSchedulesChargesDTM } from 'monetary/models/dtm';
import { compareMinMax } from 'monetary/utils';
import { chargeCodeMeasureBy } from 'monetary/constants';

import {
  CustomHeaderWrapper, CustomRowWrapper, CustomRowWrapperRest,
  FreeTimeSubHeader, Label, SectionSubTitle, Separator, Table, TableBody, TableHeader,
  TableName,
} from './PrintRFQ.styled';

interface IFreeTimeProps {
  freeTimeCharges?: IFreightQuotaContentSchedulesChargesDTM[]
}

interface ICustomTableProps {
  data: IFreightQuotaContentSchedulesChargesDTM[]
  name?: string
}

const CustomTable: FC<ICustomTableProps> = ({ data, name }) => {
  const { t } = useTranslation();
  const containers = [...new Set(data.map((item) => item.customContainerType))];

  const groupedData = data.reduce((acc: { [key: string]: IFreightQuotaContentSchedulesChargesDTM[] }, item) => {
    const key = item.customAppliance;
    if (key) {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
    }
    return acc;
  }, {});

  const minItem = minBy((item) => item?.applianceRange?.minValue, data);
  const min = minItem?.applianceRange?.minValue;

  return (
    <>
      <FreeTimeSubHeader>
        <TableName>{name}</TableName>
        <Label>{min ? min - 1 : '-'}{` ${minItem?.measureBy === chargeCodeMeasureBy.HOUR ? t('free hour(s)') : t('free day(s)')}`}</Label>
      </FreeTimeSubHeader>
      <Table>
        <TableHeader>
          <div style={{ width: '20%' }}>{minItem?.measureBy === chargeCodeMeasureBy.HOUR ? t('Hour(s) Range') : t('print.daysRange')}</div>
          <CustomHeaderWrapper>
            {containers.sort().map((container) => (
              <div style={{ textAlign: 'right', width: '100%' }} key={container}>
                {container && isContainerAllTypes(container) ? `${ContainerAllTypesNamesShortConst[container]}` : ''}
              </div>
            ))}
          </CustomHeaderWrapper>
        </TableHeader>
        <Separator style={{ marginTop: '4px' }} />
        <TableBody>
          {Object.keys(groupedData).sort(compareMinMax).map((range) => (
            <>
              <CustomRowWrapper key={range}>
                <div style={{ width: '20%' }}>{range.replace(/[^\d+-]/g, '')}</div>
                <CustomRowWrapperRest>
                  {containers.map((container) => {
                    const item = groupedData[range].find((elem: IFreightQuotaContentSchedulesChargesDTM) => elem.customContainerType === container);
                    return <div style={{ textAlign: 'right', width: '100%' }} key={container}>{item ? `$ ${item.totalCost?.toFixed(2)}` : '-'}</div>;
                  })}
                </CustomRowWrapperRest>
              </CustomRowWrapper>
              <Separator />
            </>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const FreeTime: FC<IFreeTimeProps> = ({ freeTimeCharges }) => {
  const { t } = useTranslation();

  const origin = useMemo(() => (freeTimeCharges?.length ? freeTimeCharges.filter((item) => item.designation === 'ORIGIN') : []), [freeTimeCharges]);
  const freight = useMemo(() => (freeTimeCharges?.length ? freeTimeCharges.filter((item) => item.designation === 'FREIGHT') : []), [freeTimeCharges]);
  const destination = useMemo(() => (freeTimeCharges?.length ? freeTimeCharges.filter((item) => item.designation === 'DESTINATION') : []), [freeTimeCharges]);

  const tables = (data: IFreightQuotaContentSchedulesChargesDTM[]) => [...new Set(data.map((item) => item.chargeCode?.description))].map((name) => {
    const filteredData = data.filter((item) => item.chargeCode?.description === name);

    return <CustomTable key={name} data={filteredData} name={name} />;
  });

  return (
    <>
      {!!origin.length && (
        <>
          <SectionSubTitle>{t('print.origin')}</SectionSubTitle>
          {tables(origin)}
        </>
      )}
      {!!freight.length && (
        <>
          <SectionSubTitle>{t('print.freight')}</SectionSubTitle>
          {tables(freight)}
        </>
      )}
      {!!destination.length && (
        <>
          <SectionSubTitle>{t('print.destination')}</SectionSubTitle>
          {tables(destination)}
        </>
      )}
    </>
  );
};

export { FreeTime };
