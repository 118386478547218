import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';
import { EAccountDrawerType, EOrganizationType, OrganizationIndustryArray } from 'user-management/constants';

import {
  EditProfileOrganizationGeneralInformationComponent,
} from './EditProfileOrganizationGeneralInformation.component';

const EditProfileOrganizationGeneralInformationContainer: FC = () => {
  const organizationUpdate = useSelector(R.selectors.userOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.organizationFieldsErrors);
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const organizationDisabledFieldsErrors = userManagementPermissions?.organizationEditProfileIdentifiers;
  const organizationEditProfileIdentifiersTypeErrors = userManagementPermissions?.organizationEditProfileIdentifiersType;
  const organizationEditProfileIdentifiersTypeIndustryErrors = userManagementPermissions?.organizationEditProfileIdentifiersTypeIndustry;

  const isOrganizationDisabledFieldsErrors = organizationDisabledFieldsErrors || organizationEditProfileIdentifiersTypeErrors;

  const organizationEditProfileIdentifiersIndustryErrors = organizationEditProfileIdentifiersTypeIndustryErrors
    || organizationUpdate?.type === EOrganizationType.FF
    || organizationUpdate?.type !== EOrganizationType.BCO;

  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getPermissions);

  const drawerType = useSelector(R.selectors.accountDrawer.getType);

  let formPermissions;

  switch (drawerType) {
    case EAccountDrawerType.PARTNER:
      formPermissions = permissions?.editPartnerOrganizationProfileDrawer;
      break;
    case EAccountDrawerType.CUSTOMER:
      formPermissions = permissions?.editCustomerOrganizationProfileDrawer;
      break;
    default:
      formPermissions = permissions?.editOrganizationProfileDrawer;
      break;
  }

  return (
    <EditProfileOrganizationGeneralInformationComponent
      organization={organizationUpdate}
      organizationIndustry={OrganizationIndustryArray}
      organizationDisabledFieldsErrors={isOrganizationDisabledFieldsErrors}
      organizationEditProfileIdentifiersIndustryErrors={organizationEditProfileIdentifiersIndustryErrors}
      organizationFieldsErrors={organizationFieldsErrors}
      formPermissions={formPermissions}
      onChangeUpdateName={UC.organizationProfile.onChangeUpdateName}
      onChangeUpdateType={UC.organizationProfile.onChangeUpdateType}
      onChangeUpdateIndustry={UC.organizationProfile.onChangeUpdateIndustry}
    />
  );
};

export const EditProfileOrganizationGeneralInformation = memo(EditProfileOrganizationGeneralInformationContainer);
