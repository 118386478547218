import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormPackagesNumberInputComponent } from './CargoFormPackagesNumberInput.component';

const CargoFormPackagesNumberInputContainer: FC = () => {
  const {
    packagesNumber,
    errors,
    touchedFields,
    wasUpdateAttempted,
  } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormPackagesNumberInputComponent
      packagesNumber={packagesNumber}
      touchField={UC.cargo.touchField}
      setPackagesNumber={UC.cargo.setPackagesNumber}
      error={!!errors.packagesNumber && (touchedFields.packagesNumber || wasUpdateAttempted)}
      cargosAvailability={permissions.cargosAvailability}
      customerCargosAvailability={permissions.customerCargosAvailability}
    />
  );
};

export {
  CargoFormPackagesNumberInputContainer as CargoFormPackagesNumberInput,
};
