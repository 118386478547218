import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { ExpandRenderComponent } from './ExpandRender.component';

const ExpandRenderContainer = memo(({ scheduleId }: { scheduleId: number }) => {
  const { origin, freight, destination } = useSelector(R.selectors.changeBookingSchedule.getScheduleSchemeById(scheduleId));
  const chargesTableBol = useSelector(R.selectors.changeBookingSchedule.getComparedChargesTableBol(scheduleId));
  const chargesTableContainers = useSelector(R.selectors.changeBookingSchedule.getComparedChargesTableContainer(scheduleId));

  return (
    <ExpandRenderComponent
      origin={origin}
      freight={freight}
      destination={destination}
      chargesTableBol={chargesTableBol}
      chargesTableContainers={chargesTableContainers}
    />
  );
});

export { ExpandRenderContainer as ExpandRender };
