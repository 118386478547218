import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { MissMatchesContentComponent } from './MissMatchesContent.component';

export const MissMatchesContent = React.memo(() => {
  const isThereAreShippingPartiesMissMatches = useSelector(R.selectors.billOfLadingCommon.getIsThereAreShippingPartiesMissMatches);
  const isThereAreCargosMissMatches = useSelector(R.selectors.billOfLadingCommon.getIsThereAreCargosMissMatches);
  const isThereAreContainersMissMatches = useSelector(R.selectors.billOfLadingCommon.getIsThereAreContainersMissMatches);
  const isThereAreBLReleaseMissMatches = useSelector(R.selectors.billOfLadingCommon.getIsThereAreBLReleaseMissMatches);
  const isThereAreEntryNumberMissMatches = useSelector(R.selectors.billOfLadingCommon.getIsThereAreEntryNumberMissMatches);
  const isDraftMBLApproved = useSelector(R.selectors.billOfLadingCommon.getIsDraftMBLApproved);
  const isOriginUS = useSelector(R.selectors.billOfLadingCommon.getIsOriginUS);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);

  return (
    <MissMatchesContentComponent
      isThereAreShippingPartiesMissMatches={isThereAreShippingPartiesMissMatches}
      isThereAreCargosMissMatches={isThereAreCargosMissMatches}
      isThereAreContainersMissMatches={isThereAreContainersMissMatches}
      isThereAreBLReleaseMissMatches={isThereAreBLReleaseMissMatches}
      isThereAreEntryNumberMissMatches={isThereAreEntryNumberMissMatches}
      isDraftMBLApproved={isDraftMBLApproved}
      openMissMatchesDrawer={UC.billOfLading.openMissMatchesDrawer}
      isOriginUS={isOriginUS}
      isDestinationPartner={isDestinationPartner}
      submitSIPermissions={permissions?.isAbleToSubmitSI}
    />
  );
});
