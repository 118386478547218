import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PickupDeliveryStepComponent } from './PickupDeliveryStep.component';

export const PickupDeliveryStep = React.memo(() => {
  const isCargoReceiverErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoReceiverRequiredErrorVisible);
  const isCargoSupplierErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoSupplierRequiredErrorVisible);
  const cargoSupplier = useSelector(R.selectors.bookingWizard.getCargoSupplier);
  const hasCargoReceiver = useSelector(R.selectors.bookingWizard.getCargoReceiver);
  const activeTab = useSelector(R.selectors.bookingWizard.getSupplierStepActiveTab);

  return (
    <PickupDeliveryStepComponent
      isCargoReceiverErrorVisible={isCargoReceiverErrorVisible}
      isCargoSupplierErrorVisible={isCargoSupplierErrorVisible}
      validateSuppliers={UC.bookingWizard.validateSuppliers}
      setActiveTab={UC.bookingWizard.setSuppliersStepActiveTab}
      activeTab={activeTab}
      hasCargoSupplier={!!cargoSupplier}
      hasCargoReceiver={!!hasCargoReceiver}
    />
  );
});
