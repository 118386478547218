import i18n from 'app-wrapper/i18n/i18n';
import { ETasksType } from 'shipment-operations/constants';

export interface ITaskStep {
  title: string;
  text?: string;
}

export const TASKS_STEPS: Record<ETasksType, ITaskStep[]> = {
  [ETasksType.NRA_AGREEMENT_TASK]: [],
  [ETasksType.CREATE_BOOKING_REQUEST_TASK]: [],
  [ETasksType.MISSING_DATES]: [],
  [ETasksType.VGM_TASK]: [],
  [ETasksType.SUBMIT_RAIL_BILLING]: [],
  [ETasksType.VALIDATE_BOOKING_INFORMATION]: [],
  [ETasksType.VALIDATE_SCHEDULE_UPDATES]: [],
  [ETasksType.VALIDATE_SHIPMENT_CHANGES]: [],
  [ETasksType.SUBMIT_BOOKING_AMEND_REQUEST]: [],
  [ETasksType.CONFIRM_SHIPMENT_REQUEST]: [],
  [ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK]: [],
  [ETasksType.SEND_ISF_TASK]: [
    {
      title: i18n.t('Check shipment information'),
      text: i18n.t('Review the shipment details to ensure accuracy before sending the ISF form.'),
    },
    {
      title: i18n.t('Prepare the ISF form'),
      text: undefined,
    },
    {
      title: i18n.t('Send the ISF form to the consignee'),
      text: i18n.t('Find the email address of the consignee on Shipping Party page or People page.'),
    },
    {
      title: i18n.t('Mark the task as completed'),
      text: i18n.t('Once the email is sent, please complete the task by clicking on the button at the bottom.'),
    },
  ],
  [ETasksType.CHANGE_REQUEST]: [],
  [ETasksType.COMPLETE_BOOKING_TASK]: [],
};
