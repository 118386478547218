import i18n from 'app-wrapper/i18n/i18n';

export enum EFreightIncoterms {
  EXW = 'EXW',
  FCA = 'FCA',
  FAS = 'FAS',
  FOB = 'FOB',
  DPU = 'DPU',
  DAP = 'DAP',
  CFR = 'CFR',
  CIF = 'CIF',
}

export enum EFreightIncotermsByImport {
  EXW = 'EXW',
  FCA = 'FCA',
  FAS = 'FAS',
  FOB = 'FOB',
}

export const FreightIncotermsByImportArray = [
  EFreightIncotermsByImport.EXW,
  EFreightIncotermsByImport.FCA,
  EFreightIncotermsByImport.FAS,
  EFreightIncotermsByImport.FOB,
];

export enum EFreightIncotermsByExport {
  DPU = 'DPU',
  DAP = 'DAP',
  CFR = 'CFR',
  CIF = 'CIF',
}

export const FreightIncotermsByExportArray = [
  EFreightIncotermsByExport.DPU,
  EFreightIncotermsByExport.DAP,
  EFreightIncotermsByExport.CFR,
  EFreightIncotermsByExport.CIF,
];

export const FreightOriginIncotermsByImportArray = [
  EFreightIncotermsByImport.EXW,
];

export const FreightOriginIncotermsByExportArray = [
  EFreightIncotermsByExport.DPU,
  EFreightIncotermsByExport.DAP,
  EFreightIncotermsByExport.CFR,
  EFreightIncotermsByExport.CIF,
];

export const FreightDestinationIncotermsByImportArray = [
  EFreightIncotermsByImport.EXW,
  EFreightIncotermsByImport.FCA,
  EFreightIncotermsByImport.FAS,
  EFreightIncotermsByImport.FOB,
];

export const FreightDestinationIncotermsByExportArray = [
  EFreightIncotermsByExport.DPU,
  EFreightIncotermsByExport.DAP,
];

export const getOriginAndDestinationIncotermsImportArray = (isOriginDoor?: boolean, isDestinationDoor?: boolean) => {
  if (isOriginDoor && isDestinationDoor) {
    const newArray = new Set(FreightOriginIncotermsByImportArray);
    FreightDestinationIncotermsByImportArray.forEach((item) => newArray.add(item));
    return Array(...newArray);
  }

  if (isOriginDoor) {
    return FreightOriginIncotermsByImportArray;
  }

  if (isDestinationDoor) {
    return FreightDestinationIncotermsByImportArray;
  }

  return FreightIncotermsByImportArray;
};

export const getOriginAndDestinationIncotermsExportArray = (isOriginDoor?: boolean, isDestinationDoor?: boolean) => {
  if (isOriginDoor && isDestinationDoor) {
    const newArray = new Set(FreightOriginIncotermsByExportArray);
    FreightDestinationIncotermsByExportArray.forEach((item) => newArray.add(item));
    return Array(...newArray);
  }

  if (isOriginDoor) {
    return FreightOriginIncotermsByExportArray;
  }

  if (isDestinationDoor) {
    return FreightDestinationIncotermsByExportArray;
  }

  return FreightIncotermsByExportArray;
};

export const FreightIncotermsByImportNames = {
  [EFreightIncotermsByImport.EXW]: i18n.t('Ex Works'),
  [EFreightIncotermsByImport.FCA]: i18n.t('Free Carrier'),
  [EFreightIncotermsByImport.FAS]: i18n.t('Free Alongside Ship'),
  [EFreightIncotermsByImport.FOB]: i18n.t('Free On Board'),
};

export const FreightIncotermsByExportNames = {
  [EFreightIncotermsByExport.DPU]: i18n.t('Delivered at Place Unloaded'),
  [EFreightIncotermsByExport.DAP]: i18n.t('Delivered At Place'),
  [EFreightIncotermsByExport.CFR]: i18n.t('Cost and Freight'),
  [EFreightIncotermsByExport.CIF]: i18n.t('Cost, Insurance, and Freight'),
};

export const FreightIncotermsNames = {
  [EFreightIncotermsByImport.EXW]: i18n.t('Ex Works'),
  [EFreightIncotermsByImport.FCA]: i18n.t('Free Carrier'),
  [EFreightIncotermsByImport.FAS]: i18n.t('Free Alongside Ship'),
  [EFreightIncotermsByImport.FOB]: i18n.t('Free On Board'),
  [EFreightIncotermsByExport.DPU]: i18n.t('Delivered at Place Unloaded'),
  [EFreightIncotermsByExport.DAP]: i18n.t('Delivered At Place'),
  [EFreightIncotermsByExport.CFR]: i18n.t('Cost and Freight'),
  [EFreightIncotermsByExport.CIF]: i18n.t('Cost, Insurance, and Freight'),
};
