export enum EPlaceTypes {
  'street_address' = 'street_address',
  'route' = 'route',
  'country' = 'country',
  'locality' = 'locality',
  'postal_code' = 'postal_code',
  'city_hall' = 'city_hall',
  'train_station' = 'train_station',
  'transit_station' = 'transit_station',
  'administrative_area_level_1' = 'administrative_area_level_1',
  'administrative_area_level_2' = 'administrative_area_level_2',
  'geocode' = 'geocode',
  'political' = 'political',
  'premise' = 'premise',
  'street_number' = 'street_number',
  'sublocality' = 'sublocality',
  'sublocality_level_1' = 'sublocality_level_1',
  'sublocality_level_2' = 'sublocality_level_2',
  'sublocality_level_3' = 'sublocality_level_3',
  'subpremise' = 'subpremise',
  'town_square' = 'town_square',
}
