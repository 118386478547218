import i18n from 'app-wrapper/i18n/i18n';

export enum ELoadingMethod {
  LIVE_LOAD = 'LIVE_LOAD',
  DROP_AND_PICK = 'DROP_AND_PICK'
}

export const LoadingMethodTranslations = {
  [ELoadingMethod.LIVE_LOAD]: i18n.t('Live Load'),
  [ELoadingMethod.DROP_AND_PICK]: i18n.t('Drop and Pick'),
};
