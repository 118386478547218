import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { RFQTabsComponent } from './RFQTabs.component';

const RFQTabsContainer: FC = () => {
  const {
    loadType, incotermsTrade, isSubmitVisited,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getFreightQuoteErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);
  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <RFQTabsComponent
      loadType={loadType || ''}
      isAllDisabled={getCurrent.isAllFieldDisabled}
      incotermsTrade={incotermsTrade || ''}
      isSubmitVisited={isSubmitVisited}
      incotermsTradeError={getFreightQuoteErrors?.incotermsTrade}
      onChangeFreightLoadType={UC.FreightQuote.onChangeFreightLoadType}
      onChangeFreightIncotermsTrade={UC.FreightQuote.onChangeFreightIncotermsTrade}
    />
  );
};

const RFQTabsContainerCache = memo(RFQTabsContainer);

export { RFQTabsContainerCache as RFQTabs };
