import { Textarea } from 'app-wrapper/view/components/Textarea';
import styled from 'styled-components';

export const TextAreaWithErrorStyled = styled(Textarea).attrs((props: {
  hasError: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  'data-class': props['data-class'] || 'TextAreaWithError',
}))`
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
