import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentGap,
  ContentSectionTitle,
  FooterContainer,
  GapVerticalContainerStyled, SubPage,
  SubPageContent,
  InputTitle, Wrapper,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { CustomModal } from 'shipment-operations/view/components';
import {
  Button,
} from 'app-wrapper/view/components';
import { FileContainer } from 'app-wrapper/view/drawers/DocumentUploaderDrawer/components/File';

interface IChargesCostChangesComponentProps {
  isLoading: boolean
  onClose: () => void
  onSave: () => void
  isSubmitting: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  authToken: string
}

const DocumentUploaderDrawerComponent: FC<IChargesCostChangesComponentProps> = ({
  onClose, isLoading, onSave, isSubmitting,
}) => {
  const { t } = useTranslation();

  const handleCloseDrawer = useCallback(() => {
    onClose();
  }, []);

  const handleSaveContainer = useCallback(() => {
    onSave();
  }, []);

  return (
    <CustomModal
      title={t('Upload Document')}
      onClose={handleCloseDrawer}
      contentStyles={{
        left: 'unset',
        width: '378px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
        padding: '0',
      }}
    >
      <SubPage>
        <Wrapper>
          <SubPageContent>
            {isLoading
              ? <>Loading</>
              : (
                <GapVerticalContainerStyled width="100%">
                  <ContentGap>
                    <GapVerticalContainerStyled width="100%">
                      <ContentSectionTitle>
                        {t('Upload Document')}
                      </ContentSectionTitle>
                      <InputTitle title={t('Document')}>
                        <FileContainer />
                      </InputTitle>
                    </GapVerticalContainerStyled>
                  </ContentGap>
                </GapVerticalContainerStyled>
              )}
          </SubPageContent>
        </Wrapper>
        <Footer>
          <FooterContainer>
            <Button
              type="default"
              onClick={handleCloseDrawer}
            >
              {t('Cancel')}
            </Button>
            <Button
              htmlType="submit"
              disabled={false}
              loading={isSubmitting}
              onClick={handleSaveContainer}
            >
              {t('Save')}
            </Button>
          </FooterContainer>
        </Footer>
      </SubPage>
    </CustomModal>
  );
};

export { DocumentUploaderDrawerComponent };
