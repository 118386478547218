import styled from 'styled-components';
import AntTag from 'antd/es/tag';
import { SmallTextFootnoteDescription } from 'app-wrapper/view/guideline/Font';

export const Tag = styled(AntTag)`
  margin-left: auto;
  margin-right: 0;
  border-radius: 2px;
  width: fit-content;
  height: 24px;
  padding: 1px 8px;
  ${SmallTextFootnoteDescription}
`;

export const YellowTag = styled(Tag)`
  background: #FEF9E6;
  border: 1px solid #F7D24D;
  color: #B07400;
`;

export const GreenTag = styled(Tag)`
  background: #F6FFED;
  border: 1px solid #D9F7BE;
  color: #389E0D;
`;

export const BlueTag = styled(Tag)`
  background: #F0F2FC;
  border: 1px solid #A7AFEB;
  color: #4A55AD;
`;

export const RedTag = styled(Tag)`
  background: #FFF1F0;
  border: 1px solid #FFA39E;
  color: #FF4D4F;
`;
