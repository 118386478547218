import { apiWorker } from 'app-wrapper/repository/utilsServices';
import moment from 'moment/moment';
import { ShippingInstructionsStatusEnum } from 'shipment-operations/constants';

import { ShipmentInstructionsDetailsDTM } from 'shipment-operations/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';

export class ShipmentInstructionDetailsService {
  public getSIDetails = async (shipmentId: string): Promise<ShipmentInstructionsDetailsDTM> => {
    let details: ShipmentInstructionsDetailsDTM | null = null;

    const response = await apiWorker.requestGetBySchema(this.formatUrl(shipmentId) as '/api/v1/shipments/{shipmentId}/si-details');

    if (response.status === 200) {
      details = ShipmentInstructionsDetailsDTM.fromPlain({
        ...response.data,
        clauses: response.data.clauses as string[],
        blComment: response.data.blComment as string,
        id: response.data.id as number,
        status: response.data.status as ShippingInstructionsStatusEnum,
        mblDraftId: response.data.mblDraftId as number,
        mblFinalId: response.data.mblFinalId as number,
        // @ts-ignore
        finalMblDocument: response.data.finalMblDocument ? {
          ...response.data.finalMblDocument,
          createdAt: response.data.finalMblDocument.createdAt ? DateDtm.fromPlain({
            date: response.data.finalMblDocument.createdAt,
            offset: moment.parseZone(response.data.finalMblDocument.createdAt).utcOffset(),
          }) : undefined,
        } : null,
      });
    }

    return details as ShipmentInstructionsDetailsDTM;
  };

  public editSIDetails = async (shipmentId: string, bodyDetails: ShipmentInstructionsDetailsDTM): Promise<ShipmentInstructionsDetailsDTM> => {
    let details: ShipmentInstructionsDetailsDTM | null = null;

    // fix put params
    // @ts-ignore
    const response = await apiWorker.requestPutBySchema(this.formatUrl(shipmentId) as '/api/v1/shipments/{shipmentId}/si-details', bodyDetails);

    if (response.status === 200) {
      details = ShipmentInstructionsDetailsDTM.fromPlain({
        ...response.data,
        clauses: response.data.clauses as string[],
        blComment: response.data.blComment as string,
        id: response.data.id as number,
        status: response.data.status as ShippingInstructionsStatusEnum,
        mblDraftId: response.data.mblDraftId as number,
        mblFinalId: response.data.mblFinalId as number,
        // @ts-ignore
        finalMblDocument: response.data.finalMblDocument ? {
          ...response.data.finalMblDocument,
          createdAt: response.data.finalMblDocument.createdAt ? DateDtm.fromPlain({
            date: response.data.finalMblDocument.createdAt,
            offset: moment.parseZone(response.data.finalMblDocument.createdAt).utcOffset(),
          }) : undefined,
        } : null,
      });
    }

    return details as ShipmentInstructionsDetailsDTM;
  };

  private formatUrl = (shipmentId?: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/si-details`
  );
}
