import React, {
  FC, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { Modal } from 'app-wrapper/view/components';
import { InfoCircleSvg } from 'app-wrapper/view/icons';
import { DateDtm } from 'app-wrapper/models/dtm';

import { DefaultFieldErrors } from 'monetary/models/errors';

import {
  ModalRateCancelBtn,
  ModalRateContent, ModalRateDate, ModalRateDateDatePicker, ModalRateDateSelect, ModalRateDateTitle, ModalRateFooter, ModalRateHeader, ModalRateSaveBtn, ModalRateTextInfo, ModalRateTextInfoIcon, ModalRateTextInfoWrap, Wrapper,
} from './ModalRateDate.styled';

interface IModalRateDateProps {
  valueDate?: string;
  errorRateDate?: DefaultFieldErrors;
  isDoor?: boolean;
  onClickCloseModal: () => void;
  onClickEmptyQuotaHasSchedule: () => void
  onChangeModalRateDate: (fromDate: string) => void
}

const ModalRateDateComponent: FC<IModalRateDateProps> = ({
  valueDate,
  errorRateDate,
  isDoor,
  onClickCloseModal,
  onClickEmptyQuotaHasSchedule,
  onChangeModalRateDate,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const disabledDate = useCallback((current: moment.Moment): boolean => {
    const dataNow = Date.now();
    let minDate = moment(dataNow).add(7, 'days');
    if (isDoor) {
      minDate = DateDtm.addWorkingsDaysDisabled(moment(dataNow), 3);
    }

    const maxDate = moment(dataNow).add(28, 'days');

    return current && (current < minDate.subtract(1, 'day').endOf('day') || (current > maxDate.endOf('day')));
  }, [isDoor]);

  const onChangeModalRateDateHandler = useCallback(
    (dates, dateStrings) => {
      onChangeModalRateDate(dateStrings);
    },
    [onChangeModalRateDate],
  );

  const onCloseHandler = useCallback(
    () => setIsModalOpen(false),
    [onChangeModalRateDate],
  );

  const renderFooter = useCallback(() => <></>, []);

  const isErrorRateDate = !!errorRateDate?.message
    && (errorRateDate?.isVisited);

  return (
    <Wrapper>
      <Modal
        title=""
        open={!isModalOpen}
        onClose={onCloseHandler}
        renderFooter={renderFooter}
      >
        <ModalRateContent>
          <ModalRateHeader>
            {t('Rates are unavailable at this time. Would you like us to add rates in 2-48 hrs?')}
          </ModalRateHeader>
          <ModalRateTextInfoWrap>
            <ModalRateTextInfoIcon>
              <InfoCircleSvg />
            </ModalRateTextInfoIcon>
            <ModalRateTextInfo>
              {t('Please specify the cargo ready date and we will work on adding rates for your request.')}
            </ModalRateTextInfo>
          </ModalRateTextInfoWrap>

          <ModalRateDate>
            <ModalRateDateTitle>
              {`${t('Cargo Ready Date')} *`}
            </ModalRateDateTitle>

            <ModalRateDateSelect>
              <ModalRateDateDatePicker
                className="ModalRateDateDatePicker"
                format="YYYY-MM-DD"
                isError={isErrorRateDate}
                value={valueDate ? moment(valueDate) : null}
                disabledDate={disabledDate}
                onChange={onChangeModalRateDateHandler}
              />
            </ModalRateDateSelect>
          </ModalRateDate>

          <ModalRateFooter>
            <ModalRateCancelBtn
              onClick={onClickCloseModal}
            >
              {t('addChargeMenu.cancel')}
            </ModalRateCancelBtn>
            <ModalRateSaveBtn
              onClick={onClickEmptyQuotaHasSchedule}
            >
              {t('Send Request')}
            </ModalRateSaveBtn>
          </ModalRateFooter>

        </ModalRateContent>
      </Modal>
    </Wrapper>
  );
};

export { ModalRateDateComponent };
