import styled from 'styled-components';

import {
  CommodityRow as CommonCommodityRow,
  CommodityRowNoHazmat as CommonCommodityRowNoHazmat,
} from 'shipment-operations/view/components/BookingDrawer/components/Commodity/components/CommodityRow/CommodityRow.styled';

export const CommodityRow = styled(CommonCommodityRow)`
  grid-template-columns: 205px 205px 95px 95px 95px 95px 103px 103px 32px;
`;

export const CommodityRowNoHazmat = styled(CommonCommodityRowNoHazmat)`
  grid-template-columns: 194px 194px 194px 104px 104px 104px 104px 32px;
`;
