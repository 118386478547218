import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import {
  SubPageContent,
  FlexRow,
} from 'app-wrapper/view/guideline';

import { CurrentSchedule, Dates } from './components';
import {
  Content,
  Title,
  Header,
  HeaderContent,
} from './ScheduleStep.styled';

interface IScheduleStepProps {
  openChangeScheduleDrawer: () => void;
  openChangeQuotaDrawer: () => void;
  isOriginDoor?: boolean;
}

export const ScheduleStepComponent: FC<IScheduleStepProps> = ({
  openChangeScheduleDrawer,
  openChangeQuotaDrawer,
  isOriginDoor,
}) => {
  const { t } = useTranslation();

  return (
    <Content>
      {isOriginDoor ? (
        <Header>
          <Title>
            {t('Dates')}
          </Title>

          <HeaderContent>
            <Dates />
          </HeaderContent>
        </Header>
      ) : null}

      <SubPageContent>
        <FlexRow>
          <Title>
            {t('Schedule')}
          </Title>

          <HeaderContent>
            <Button
              onClick={openChangeQuotaDrawer}
              type="default"
            >
              {t('Change Quota')}
            </Button>

            <Button
              onClick={openChangeScheduleDrawer}
              type="default"
            >
              {t('Change Schedule')}
            </Button>
          </HeaderContent>
        </FlexRow>

        <CurrentSchedule />
      </SubPageContent>
    </Content>
  );
};
