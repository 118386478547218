import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShareInvoiceState } from 'shipment-operations/models/states';
import {
  FullShipmentDTM,
  IRouteLegDTM,
  ShipmentBillingInvoiceDtm,
  AddressDTM,
  ContactDTM,
  CompanyDTM,
  ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import { IRFQServiceByIdContentRoutesTransportation } from 'monetary/models/dtm/Quotas';
import { AccountDepartmentDTM, AdminPublicInfoDTM, OrganizationDTM } from 'user-management/models/dtm';

const initialState: IShareInvoiceState = {
  isLoading: false,
  isLoadingFinished: false,
  payableInvoice: null,
  receivableInvoice: null,
  fullShipment: null,
  isTermVisible: false,
  customerCreditTerm: undefined,
  currentOrgRelatedAdmin: null,
  customerAccountingDepartment: null,
  isVATVisible: false,
  shipment: undefined,
  shouldShowPLBankInfo: false,
};

export const shareInvoiceSlice = createSlice({
  name: 'shareInvoice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsLoadingFinished: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoadingFinished: payload,
    }),
    setFullShipment: (state, { payload }: PayloadAction<FullShipmentDTM | null>) => ({
      ...state,
      fullShipment: payload,
    }),
    setPayableInvoice: (state, { payload }: PayloadAction<ShipmentBillingInvoiceDtm | null>) => ({
      ...state,
      payableInvoice: payload,
    }),
    setIsTermVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTermVisible: payload,
    }),
    setIsVATVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isVATVisible: payload,
    }),
    setCustomerCreditTerm: (state, { payload }: PayloadAction<number | undefined>) => ({
      ...state,
      customerCreditTerm: payload,
    }),
    setReceivableInvoice: (state, { payload }) => ({
      ...state,
      receivableInvoice: payload,
    }),
    setBillToOrganization: (state, { payload }: PayloadAction<OrganizationDTM | undefined>) => ({
      ...state,
      billToOrganization: payload,
    }),
    setBillToAddress: (state, { payload }: PayloadAction<AddressDTM | undefined>) => ({
      ...state,
      billToAddress: payload,
    }),
    setBillFromAddress: (state, { payload }: PayloadAction<AddressDTM | undefined>) => ({
      ...state,
      billFromAddress: payload,
    }),
    setBillToContact: (state, { payload }: PayloadAction<ContactDTM | undefined>) => ({
      ...state,
      billToContact: payload,
    }),
    setBillFromContact: (state, { payload }: PayloadAction<ContactDTM | undefined>) => ({
      ...state,
      billFromContact: payload,
    }),
    setBillToCompany: (state, { payload }: PayloadAction<CompanyDTM | undefined>) => ({
      ...state,
      billToCompany: payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM | undefined>) => ({
      ...state,
      shipment: payload,
    }),
    setBillFromCompany: (state, { payload }: PayloadAction<CompanyDTM | undefined>) => ({
      ...state,
      billFromCompany: payload,
    }),
    setLowestSequenceLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      lowestSequenceLeg: action.payload,
    }),
    setLowestSequenceSeaLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      lowestSequenceSeaLeg: action.payload,
    }),
    setHighestSequenceSeaLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      highestSequenceSeaLeg: action.payload,
    }),
    setHighestSequenceLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      highestSequenceLeg: action.payload,
    }),
    setLowestSequenceTransportation: (state, action: PayloadAction<IRFQServiceByIdContentRoutesTransportation>) => ({
      ...state,
      lowestSeaTransportation: action.payload,
    }),
    setCurrentOrgRelatedAdmin: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdmin: payload,
    }),
    setShouldShowPLBankInfo: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldShowPLBankInfo: payload,
    }),
    setCustomerAccountingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      customerAccountingDepartment: payload,
    }),
    clear: () => initialState,
  },
});

export const shareInvoiceActions = shareInvoiceSlice.actions;
export const shareInvoiceReducer = shareInvoiceSlice.reducer;
