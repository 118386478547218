import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EMembershipType, MembershipTypeNames } from 'user-management/constants';
import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';

import {
  OrganizationAddressData,
  OrganizationAddressHeader,
  OrganizationAddressIconBG,
  OrganizationAddressItem,
  OrganizationAddressRow,
  OrganizationAddressTitle,
  OrganizationAddressValue,
  OrganizationAddressWrapper,
  OrganizationAddressMembershipItem,
} from './OrganizationFinancialAndLegal.styled';

interface IOrganizationAddressComponentProps {
  organization?: OrganizationDTM
}

export const OrganizationFinancialAndLegalComponent: FC<IOrganizationAddressComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  const membershipWCA = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.WCA_ID))
  ), [organization]);

  const membershipDNB = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.DNB_ID))
  ), [organization]);

  const membershipXLP = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.XLP))
  ), [organization]);

  const membershipGAA = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.GAA))
  ), [organization]);

  const membershipJSTRANS = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.JC_TRANS))
  ), [organization]);

  const membershipGLA = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.GLA))
  ), [organization]);

  const hasMemberships = Boolean(membershipWCA?.getValidValue() || membershipDNB?.getValidValue() || membershipXLP?.getValidValue() || membershipGAA?.getValidValue() || membershipJSTRANS?.getValidValue() || membershipGLA?.getValidValue());

  return (
    <OrganizationAddressWrapper>
      <OrganizationAddressHeader>{t('Financial & Legal Information')}</OrganizationAddressHeader>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <HomeOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Tax ID')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.taxId || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <BorderlessTableOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('EIN Number')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.businessRegistrationNumber.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
      </OrganizationAddressRow>

      {hasMemberships ? (
        <>
          <OrganizationAddressHeader
            style={{
              margin: '16px 0',
            }}
          >
            {t('Memberships')}
          </OrganizationAddressHeader>

          <GapHorizontalContainerStyled
            style={{
              flexWrap: 'wrap',
            }}
          >
            {membershipWCA?.getValidValue() ? (
              <OrganizationAddressMembershipItem>
                <OrganizationAddressIconBG>
                  <BorderlessTableOutlined />
                </OrganizationAddressIconBG>

                <OrganizationAddressData>
                  <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.WCA_ID]}</OrganizationAddressTitle>
                  <OrganizationAddressValue>{membershipWCA?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
                </OrganizationAddressData>
              </OrganizationAddressMembershipItem>
            ) : null}

            {membershipDNB?.getValidValue() ? (
              <OrganizationAddressMembershipItem>
                <OrganizationAddressIconBG>
                  <BorderlessTableOutlined />
                </OrganizationAddressIconBG>
                <OrganizationAddressData>
                  <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.DNB_ID]}</OrganizationAddressTitle>
                  <OrganizationAddressValue>{membershipDNB?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
                </OrganizationAddressData>
              </OrganizationAddressMembershipItem>
            ) : null}

            {membershipXLP?.getValidValue() ? (
              <OrganizationAddressMembershipItem>
                <OrganizationAddressIconBG>
                  <BorderlessTableOutlined />
                </OrganizationAddressIconBG>
                <OrganizationAddressData>
                  <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.XLP]}</OrganizationAddressTitle>
                  <OrganizationAddressValue>{membershipXLP?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
                </OrganizationAddressData>
              </OrganizationAddressMembershipItem>
            ) : null}

            {membershipGAA?.getValidValue() ? (
              <OrganizationAddressMembershipItem>
                <OrganizationAddressIconBG>
                  <BorderlessTableOutlined />
                </OrganizationAddressIconBG>
                <OrganizationAddressData>
                  <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.GAA]}</OrganizationAddressTitle>
                  <OrganizationAddressValue>{membershipGAA?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
                </OrganizationAddressData>
              </OrganizationAddressMembershipItem>
            ) : null}

            {membershipJSTRANS?.getValidValue() ? (
              <OrganizationAddressMembershipItem>
                <OrganizationAddressIconBG>
                  <BorderlessTableOutlined />
                </OrganizationAddressIconBG>
                <OrganizationAddressData>
                  <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.JC_TRANS]}</OrganizationAddressTitle>
                  <OrganizationAddressValue>{membershipJSTRANS?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
                </OrganizationAddressData>
              </OrganizationAddressMembershipItem>
            ) : null}

            {membershipGLA?.getValidValue() ? (
              <OrganizationAddressMembershipItem>
                <OrganizationAddressIconBG>
                  <BorderlessTableOutlined />
                </OrganizationAddressIconBG>
                <OrganizationAddressData>
                  <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.GLA]}</OrganizationAddressTitle>
                  <OrganizationAddressValue>{membershipGLA?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
                </OrganizationAddressData>
              </OrganizationAddressMembershipItem>
            ) : null}
          </GapHorizontalContainerStyled>
        </>
      ) : null}
    </OrganizationAddressWrapper>
  );
};
