import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { ReferenceTags, Tooltip } from 'app-wrapper/view/components';
import { ReferenceDTM } from 'app-wrapper/types/Reference';
import { ShipmentDetailsCardWithThreeSections } from 'shipment-operations/view/components';
import { CargoReferenceTypeNamesShort, PermissionAttributePolicy } from 'shipment-operations/constants';
import { CargoBaseDTM, ContainerCargoDTM, ContainerDTM } from 'shipment-operations/models/dtm';

import {
  CardSelectCheckbox,
  CardUnitField,
  CardVolumeField,
  CardWeightField,
  CardValueField,
  ContainerCardCargoData,
} from './components';
import {
  ContainerCardTopContainer,
  ContainerCardTopName,
  Divider,
  InfoIcon,
  ShipmentContainersFormCardFullDataItem,
  ShipmentContainersFormCardFullDataLongItem,
  ShipmentContainersFormCardListContainer, SuccessIcon,
} from './ShipmentContainersFormCardList.styled';

interface IShipmentContainersFormCardListComponentProps {
  containers?: ContainerDTM[]
  selectedContainer?: ContainerDTM
  cargoList: CargoBaseDTM[]
  isInDraft: boolean
  changedCargoList: ContainerCargoDTM[]
  containersAvailability?: PermissionAttributePolicy
  customerEditAvailability?: PermissionAttributePolicy
}

export const ShipmentContainersFormCardListComponent: FC<IShipmentContainersFormCardListComponentProps> = ({
  containers,
  selectedContainer,
  cargoList,
  isInDraft,
  changedCargoList,
  containersAvailability,
  customerEditAvailability,
}) => {
  const { t } = useTranslation();

  const isDisabled = useMemo(() => !hasAccess(containersAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerEditAvailability, PermissionAttributePolicy.WRITE), [containersAvailability, customerEditAvailability]);

  return (
    <ShipmentContainersFormCardListContainer>
      {
        cargoList.map((cargo) => {
          if (!cargo.baseId || !cargo.packagesNumber || !cargo.weight) {
            console.error('Some problem with cargo item - empty baseId or packagesNumber or weight');

            return null;
          }

          const selectedContainerCargo = selectedContainer?.cargoItems.find((item) => (
            item.cargoId === cargo.baseId
          ));

          const savedPackagesNumber = selectedContainerCargo ? selectedContainerCargo.packagesNumber : '0';
          const savedWeight = selectedContainerCargo ? selectedContainerCargo.weight : '0';
          const savedVolume = selectedContainerCargo ? selectedContainerCargo.volume : '0';

          const changedCargo = changedCargoList.find((changedCargoItem) => (
            changedCargoItem.baseId
            && (changedCargoItem.baseId === cargo.baseId)
          ));
          const isSelected = !!changedCargo;

          const isFullLoaded = cargo.loadSummary.packagesNumber === +cargo.packagesNumber
            || cargo.loadSummary.weight === +cargo.weight
            || (!!cargo.volume && (cargo.loadSummary.volume === +cargo.volume));

          const isDisabledForSelection = !selectedContainerCargo && isFullLoaded;

          const cargoName = cargo.description || cargo.name || cargo.baseId;

          const packagesLeft = changedCargo
            ? +cargo.packagesNumber - (+changedCargo.packagesNumberValue - +savedPackagesNumber + cargo.loadSummary.packagesNumber)
            : +cargo.packagesNumber - (0 - +savedPackagesNumber + cargo.loadSummary.packagesNumber);
          const weightLeft = changedCargo
            ? +cargo.weight - (+changedCargo.weightValue - +savedWeight + cargo.loadSummary.weight)
            : +cargo.weight - (0 - +savedWeight + cargo.loadSummary.weight);
          let volumeLeft = 0;

          if (cargo.volume) {
            volumeLeft = changedCargo
              ? +cargo.volume - (+changedCargo.volumeValue - +savedVolume + cargo.loadSummary.volume)
              : +cargo.volume - (0 - +savedVolume + cargo.loadSummary.volume);
          }

          const usedContainers: string[] = [];

          containers?.forEach((container) => {
            const containerCargo = container.cargoItems.find(
              (item) => item.cargoId === cargo.baseId,
            );

            if (containerCargo) {
              const containerName = container?.name ? container.name : t('Number pending entry');

              usedContainers.push(
                `${containerName} (${containerCargo.packagesNumber} ${t('pcs')}, ${containerCargo.weight} ${t('kg')}, ${containerCargo.volume} ${t('cbm')})`,
              );
            }
          });

          const usedContainersInfo = !usedContainers.length ? t('Not used yet') : usedContainers.join(', ');

          const isNotificationEnabled = !isSelected && isFullLoaded && !isInDraft;

          const isVolumeFieldDisabled = isDisabled || !cargo.volume || !isSelected;
          const fieldDisabled = isDisabled || !isSelected;

          return (
            <ShipmentDetailsCardWithThreeSections key={cargo.baseId} isSelected={isSelected}>
              <ContainerCardTopContainer>
                <CardSelectCheckbox
                  checked={isSelected}
                  cargo={cargo}
                  disabled={isDisabled || isDisabledForSelection}
                />
                <Tooltip title={cargoName}>
                  <ContainerCardTopName>{cargoName}</ContainerCardTopName>
                </Tooltip>
                <CardUnitField
                  cargoId={cargo.baseId}
                  value={changedCargo?.packagesNumberValue || ''}
                  isError={!!changedCargo?.packagesNumberValueError}
                  disabled={fieldDisabled}
                />
                <CardWeightField
                  cargoId={cargo.baseId}
                  value={changedCargo?.weightValue || ''}
                  isError={!!changedCargo?.weightValueError}
                  disabled={fieldDisabled}
                />
                <CardVolumeField
                  cargoId={cargo.baseId}
                  value={changedCargo?.volumeValue || ''}
                  isError={!!changedCargo?.volumeValueError}
                  disabled={isVolumeFieldDisabled}
                />
                <CardValueField
                  cargoId={cargo.baseId}
                  value={changedCargo?.value || ''}
                  isError={!!changedCargo?.volumeValueError}
                  disabled={fieldDisabled}
                />

                {
                  !isFullLoaded && (
                    <Tooltip title={usedContainersInfo}>
                      <InfoIcon />
                    </Tooltip>
                  )
                }
                {
                  isFullLoaded && (
                    <Tooltip title={usedContainersInfo}>
                      <SuccessIcon />
                    </Tooltip>
                  )
                }
                <ContainerCardCargoData
                  packagesNumber={cargo.packagesNumber}
                  weight={cargo.weight}
                  volume={cargo.volume}
                />
                {
                  isNotificationEnabled ? (
                    <Tooltip title={usedContainersInfo}>
                      <ShipmentContainersFormCardFullDataLongItem>
                        {
                          // TODO: wrap and move to icons folder
                        }
                        <InfoIcon />
                        {` ${t('Cargo item is fully loaded')}`}
                      </ShipmentContainersFormCardFullDataLongItem>
                    </Tooltip>
                  ) : (
                    <>
                      <ShipmentContainersFormCardFullDataItem>/{packagesLeft} {t('pcs')}</ShipmentContainersFormCardFullDataItem>
                      <ShipmentContainersFormCardFullDataItem>/{weightLeft} {t('kg')}</ShipmentContainersFormCardFullDataItem>
                      <ShipmentContainersFormCardFullDataItem>/{volumeLeft} {t('cbm')}</ShipmentContainersFormCardFullDataItem>
                    </>
                  )
                }
              </ContainerCardTopContainer>
              <Divider dashed />
              <ReferenceTags
                referenceTypeNamesMap={CargoReferenceTypeNamesShort}
                references={cargo.references as ReferenceDTM[]} // TODO: cargo reference type will be fixed to common IReference
              />
            </ShipmentDetailsCardWithThreeSections>
          );
        })
      }

    </ShipmentContainersFormCardListContainer>
  );
};
