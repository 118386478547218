import styled from 'styled-components';
import Typography from 'antd/es/typography';
import MailOutlined from '@ant-design/icons/MailOutlined';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  width: 100%;
  padding: 8px 20px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 238, 248, 1);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  margin-right: 20px;
  width: 56px;
  height: 56px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;

export const TextColored = styled.span`
  color: rgba(74, 85, 173, 1);
  margin: 0 3px;
`;

export const ContactLink = styled.a`
  margin-left: auto;
`;

export const ContactIcon = styled(MailOutlined)`
  color: rgba(153, 163, 181, 1);
`;

export const ContactButton = styled(Button)`
  height: 32px;
`;
