import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentBillingInvoiceState } from 'shipment-operations/models/states';
import { ModuleFunctionalityPermissionsDtm } from 'shipment-operations/models/dtm';
import { AdminPublicInfoDTM } from 'user-management/models/dtm';

const initialState: IShipmentBillingInvoiceState = {
  invoice: undefined,
  error: false,
  isLoading: false,
  creditNote: {
    selectedItems: [],
    notes: '',
    updatedItems: [],
    isLoading: false,
    error: false,
    errorTitle: '',
    success: false,
    createdCreditNote: undefined,
  },
  isOpenCreditNote: false,
  linkedCreditNotes: {
    isLoading: false,
    isError: false,
    items: [],
  },
  isOpenPayment: false,
  linkedPaymentsError: false,
  shipmentParties: {
    isLoading: false,
    isError: false,
    companies: [],
  },
  relatedAdmin: null,
  permissions: ModuleFunctionalityPermissionsDtm.createEmpty(),
  shouldShowPLBankInfo: false,
};

export const ShipmentBillingInvoiceSlice = createSlice({
  name: 'shipmentBillingInvoice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setInvoice: (state, { payload }) => ({
      ...state,
      error: false,
      invoice: payload,
    }),
    setPermissions: (state, { payload }: PayloadAction<ModuleFunctionalityPermissionsDtm>) => {
      state.permissions = payload;
    },
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setRelatedAdmin: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      relatedAdmin: payload,
    }),
    setSelectedItem: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        selectedItems: (state.creditNote.selectedItems.includes(payload)
          ? state.creditNote.selectedItems.filter((item) => item !== payload) : [...state.creditNote.selectedItems, payload]),
      },
    }),
    setSubTableItems: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        selectedItems: payload.every((item: number) => state.creditNote.selectedItems.includes(item))
          ? state.creditNote.selectedItems.filter((item) => !payload.includes(item))
          : [...state.creditNote.selectedItems.filter((item) => !payload.includes(item)), ...payload],
      },
    }),
    clearSelectedItems: (state) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        selectedItems: [],
      },
    }),
    setNotes: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        notes: payload,
      },
    }),
    setListUpdatedItems: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        updatedItems: payload,
      },
    }),
    setUpdatedItem: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        updatedItems: state.creditNote.updatedItems.map((item) => (item.invoiceItem.id === payload.invoiceItem.id ? payload : item)),
      },
    }),
    clearUpdatedItems: (state) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        updatedItems: initialState.creditNote.updatedItems,
      },
    }),
    setCreditNoteLoading: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        isLoading: payload,
      },
    }),
    setCreditNoteError: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        error: payload,
      },
    }),
    setCreditNoteSuccess: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        success: payload,
      },
    }),
    onCreateCreditNoteSuccess: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        success: true,
        error: false,
        errorTitle: '',
        isLoading: false,
        createdCreditNote: payload,
      },
    }),
    onCreateCreditNoteError: (state, { payload }) => ({
      ...state,
      creditNote: {
        ...state.creditNote,
        success: false,
        error: true,
        errorTitle: payload,
        isLoading: false,
        createdCreditNote: initialState.creditNote.createdCreditNote,
      },
    }),
    clearCreditNote: (state) => ({
      ...state,
      creditNote: initialState.creditNote,
    }),
    setIsOpenCreditNote: (state, { payload }) => ({
      ...state,
      isOpenCreditNote: payload,
    }),
    setIsLoadingLinkedCreditNotes: (state, { payload }) => ({
      ...state,
      linkedCreditNotes: {
        ...state.linkedCreditNotes,
        isLoading: payload,
      },
    }),
    loadLinkedCreditNotesSuccess: (state, { payload }) => ({
      ...state,
      linkedCreditNotes: {
        isLoading: false,
        isError: false,
        items: payload,
      },
    }),
    loadLinkedCreditNotesFailed: (state) => ({
      ...state,
      linkedCreditNotes: {
        isLoading: false,
        isError: true,
        items: initialState.linkedCreditNotes.items,
      },
    }),
    setIsOpenPayment: (state, { payload }) => ({
      ...state,
      isOpenPayment: payload,
    }),
    setLinkedPayments: (state, { payload }) => ({
      ...state,
      linkedPayments: payload,
      linkedPaymentsError: false,
    }),
    setLinkedPaymentsError: (state, { payload }) => ({
      ...state,
      linkedPaymentsError: payload,
    }),
    setIsLoadingCompanies: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        isLoading: payload,
      },
    }),
    setLoadCompaniesError: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        isError: payload,
      },
    }),
    onLoadCompaniesSuccess: (state, { payload }) => ({
      ...state,
      shipmentParties: {
        ...state.shipmentParties,
        isLoading: false,
        isError: false,
        companies: payload,
      },
    }),
    setShouldShowPLBankInfo: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldShowPLBankInfo: payload,
    }),
  },
});

export const shipmentBillingInvoiceActions = ShipmentBillingInvoiceSlice.actions;
export const shipmentBillingInvoiceReducer = ShipmentBillingInvoiceSlice.reducer;
