import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import React, { FC, ButtonHTMLAttributes } from 'react';
import omit from 'lodash/fp/omit';

import { MenuTriggerButton } from './MenuTrigger.styled';

interface ISiderTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isCollapsed: boolean;
}

export const MenuTrigger: FC<ISiderTriggerProps> = ((
  props,
) => {
  const { className, isCollapsed } = props;
  const componentClassName = `${className} SiderTrigger`;

  return (
    <MenuTriggerButton
      type="button"
      className={componentClassName}
      {...omit('isCollapsed', props)}
    >
      {
        isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
      }
    </MenuTriggerButton>
  );
});
