import styled, { css } from 'styled-components';

export const TitleText = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const TitleTextSpan = styled.span`
  ${TitleText}
`;

export const BodyTextRegular = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const BodyTextMedium = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const BodyTextMediumSpan = styled.span`
  ${BodyTextMedium}
`;

export const BodyTextBold = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

export const SmallTextFootnoteDescription = css`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const SmallTextFootnoteDescriptionSpan = styled.span`
  ${SmallTextFootnoteDescription}
`;

export const SmallTextFootnoteMedium = css`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
