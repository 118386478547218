import { AirVentDTM } from 'shipment-operations/models/dtm/AirVent.dtm';
import { v4 as uuidv4 } from 'uuid';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { IGetMasterBillOfLadingContract } from 'shipment-operations/models/contracts';
import {
  MasterBillOfLadingDTM,
  CargoBaseDTM,
  MSDSDocumentDTM,
  HsCodeValidationStatus,
  TemperatureControlDTM, ContainerDTM,
} from 'shipment-operations/models/dtm';
import { AirVentUnitsType } from 'shipment-operations/constants';
import { ReferenceDTM } from 'app-wrapper/types/Reference';
import { IDocumentDTM } from 'app-wrapper/models/dtm';

export class MasterBillOfLadingsService {
  public getMasterBillOfLadingById = async (shipmentId: string, mblId: string): Promise<MasterBillOfLadingDTM | null> => {
    let masterBillOfLading: MasterBillOfLadingDTM | null = null;

    const response = await apiWorker.requestGet<IGetMasterBillOfLadingContract>(this.formatUrl(shipmentId, mblId));

    if (response.status === 200) {
      masterBillOfLading = this.formatMasterBillOfLadingDTMFromResponse(response.data);
    }

    return masterBillOfLading;
  };

  private formatMasterBillOfLadingDTMFromResponse = (data: IGetMasterBillOfLadingContract): MasterBillOfLadingDTM => {
    const containers = data.containers.map((container) => {
      const cargoItems = container.cargoItems.map((cargoItem) => ({
        id: `${cargoItem.id}`,
        cargoId: `${cargoItem.cargoId}`,
        packagesNumber: `${cargoItem.packagesNumber}`,
        weight: `${cargoItem.weight}`,
        volume: `${cargoItem.volume}`,
        value: `${cargoItem.value}`,
      }));

      let references: ReferenceDTM[] = [];

      if (container.references) {
        references = container.references.map((ref) => (ReferenceDTM.fromPlain({
          id: `${ref.id}`,
          type: ref.type,
          value: ref.value,
        })));
      }

      return ContainerDTM.fromPlain({
        ...container,
        name: container.number || '',
        isInDraft: false,
        isVirtual: false,
        number: `${container.number}`,
        rateId: `${container.rateId}`,
        id: `${container.id}`,
        planId: `${container.planId}`,
        cargoItems,
        references,
        seaworthyCertificate: null,
        estimatedVolume: '0',
        estimatedWeight: '0',
      });
    });

    const cargos = data.cargos.map((cargo) => {
      const {
        id,
        code = '',
        name = '',
        description = '',
        value,
        packageType,
        packagesNumber,
        weight,
        volume,
        marks = '',
        loadSummary,
        references = [],
        hazmat,
      } = cargo;

      const baseFields = CargoBaseDTM.fromPlain({
        code,
        name,
        description: description || '',
        packageType,
        marks: marks || '',
        loadSummary,
        references: references.length ? references.map((reference) => ({
          ...reference,
          id: uuidv4(),
        })) : [{ id: uuidv4() }],
        packagesNumber: packagesNumber ? packagesNumber.toString() : '',
        value: value ? value.toString() : '',
        weight: weight ? weight.toString() : '',
        volume: volume ? volume.toString() : '',
        unNumber: hazmat?.unNumber || '',
        imoClass: hazmat?.imoClass,
        shippingName: hazmat?.shippingName || '',
        packingGroup: hazmat?.packingGroup,
        msdsDocument: this.convertFromDocumentResponse(hazmat?.msdsDocument),
        contactName: hazmat?.emergencyContact?.name || '',
        contactNumber: hazmat?.emergencyContact?.phone || '',
      });

      const msdsStatus = (
        baseFields.msdsDocument
        && !!baseFields.msdsDocument.length
        && baseFields.msdsDocument[0].status === 'done'
        && !!baseFields.msdsDocument[0].response.id
        && !!baseFields.msdsDocument[0].response.name
        && !!baseFields.msdsDocument[0].response.type
      );

      const isHazmat = (
        msdsStatus
        || !!baseFields.imoClass
        || !!baseFields.unNumber
        || !!baseFields.packingGroup
        || !!baseFields.shippingName
        || !!baseFields.contactName
        || !!baseFields.contactNumber
      );

      return {
        renderId: `${id}`,
        touchedFields: {},
        errors: {},
        initialState: { ...baseFields },
        wasUpdateAttempted: false,
        hsCodeValidationStatus: (code ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT') as HsCodeValidationStatus,
        isHazmatCollapseOpen: isHazmat,
        isHazmat,
        id,
        ...baseFields,
      };
    });

    const temperatureControl = data.temperatureControls.length
      ? data.temperatureControls[data.temperatureControls.length - 1]
      : null;

    const temperatureControlDTM = temperatureControl ? TemperatureControlDTM.fromPlain({
      airVent: AirVentDTM.fromPlain({
        flowRateUnit: temperatureControl?.airVent?.flowRateUnit as AirVentUnitsType || undefined,
        flowRate: temperatureControl?.airVent?.flowRate ? String(temperatureControl?.airVent?.flowRate) : undefined,
      }),
      id: temperatureControl?.id ? temperatureControl?.id : 0,
      genset: temperatureControl?.genset || false,
      temperature: temperatureControl?.temperature ? String(temperatureControl?.temperature) : '0',
    }) : null;

    return MasterBillOfLadingDTM.fromPlain({
      ...data,
      comments: data.comments || [],
      blLocations: data.blLocations || [],
      transportations: data.transportations || [],
      references: data.references || [],
      containers,
      cargos,
      temperatureControl: temperatureControlDTM,
    });
  };

  private convertFromDocumentResponse = (document?: IDocumentDTM) => (
    document ? ([MSDSDocumentDTM.fromPlain({
      uid: document.id.toString(),
      name: document.name,
      status: 'done',
      response: document,
      url: '/',
    })]) : []
  );

  private formatUrl = (shipmentId: string, mblId: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/master-bill-of-ladings/${mblId}`
  );
}
