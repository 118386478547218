import React from 'react';
import { useCurrentController } from 'app-wrapper/view/routes/components';
import {
  ShipmentTransportationImportInlandDeliveryOverviewController,
} from 'shipment-operations/controllers/ShipmentTransportationImportInlandDeliveryOverview';
import { observer } from 'mobx-react-lite';
import { DepartureContainersComponent } from 'shipment-operations/view/components/TransportationDrayage';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { hasAccess } from 'app-wrapper/utils';

export const DepartureContainersContainer = observer(() => {
  const controller = useCurrentController<ShipmentTransportationImportInlandDeliveryOverviewController>();
  const { shipmentContainersStore } = useMobxStore();
  const permissions = useSelector(R.selectors.shipment.getTransportationDrayagePermissions);

  return (
    <DepartureContainersComponent
      drayageSide={EDrayageSide.DESTITATION_DRAYAGE}
      containers={shipmentContainersStore.items}
      openEditDrawer={controller.openEditContainers}
      isAbleToEdit={hasAccess(permissions.editImportContainer, PermissionAttributePolicy.WRITE)}
    />
  );
});
