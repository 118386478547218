import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ShipmentEditContainerController,
} from 'shipment-operations/controllers/ShipmentEditContainerController/ShipmentEditContainer.controller';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import { Select } from 'app-wrapper/view/components';
import { DepartureDetailsStatusEnum, DepartureDetailsStatusTranslations } from 'shipment-operations/constants';

export const StatusContainer = observer(() => {
  const controller = useCurrentController<ShipmentEditContainerController>();
  const store = useCurrentControllerStore<ShipmentEditContainerStore>();
  const numberStore = store.state.form.status;

  const handleOnChange = useCallback((value) => {
    controller.status().onChange(value);
  }, []);

  const handleOnBlur = useCallback(() => {
    controller.status().onBlur();
  }, []);

  const options = useMemo(() => [{
    label: DepartureDetailsStatusTranslations[DepartureDetailsStatusEnum.DISPATCHED],
    value: DepartureDetailsStatusEnum.DISPATCHED,
  },
  {
    label: DepartureDetailsStatusTranslations[DepartureDetailsStatusEnum.UNASSIGNED],
    value: DepartureDetailsStatusEnum.UNASSIGNED,
  },
  ], []);

  return (
    <Select
      value={numberStore?.value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      options={options}
    />
  );
});
