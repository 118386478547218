import React, {
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import {
  Button,
  Row,
  Col,
  Tooltip,
  ExpandIcon,
  Panel,
  Collapse,
  AlertBlock,
  TypeSwitch,
  Option,
} from 'app-wrapper/view/components';
import {
  useWindowSize, useCollapseOnChangeHandler,
} from 'app-wrapper/hooks';
import {
  CloseSvg, VerticalFormItemSvg, PlusSvg, MinusSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import {
  ContainerReeferTypesArray, ContainerReeferTypesNamesLongConst, ContainerUsualTypesArray, ContainerUsualTypesNamesLongConst,
} from 'shipment-operations/constants';

import { FreightFromContainersValuesStateDTM, IFreightSelectFieldDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import {
  ContainersInput,
  ContainersSelect,
  ContainersSwitchGensetWrap,
  ContainersSwitchSOCWrap,
  ContainersWrapper,
} from './Containers.styled';

const collapseIcon = (<VerticalFormItemSvg />);

export interface IContainersComponentProps {
  quantityInfoBlock: boolean
  isAllDisabled?: boolean
  formLocalState: { isCollapsed?: boolean }
  setFormLocalState: React.Dispatch<{ isCollapsed?: boolean }>
  forceCollapse?: boolean
  isCollapsed?: boolean
  // new
  isMetric?: boolean
  socControl?: boolean
  gensetControl?: boolean
  containersValues?: FreightFromContainersValuesStateDTM[]
  errorsContainers?: {
    // this simulation array keys
    [key: number]: {
      error?: IDefaultFieldErrors
      type?: IDefaultFieldErrors
      quantity?: IDefaultFieldErrors
      weight?: IDefaultFieldErrors
      volume?: IDefaultFieldErrors
    }
  }
  isContainersError?: boolean
  isSubmitVisited?: boolean
  isSubmitClicked?: boolean
  quotaStatus?: string
  temperatureControl?: boolean
  onChangeContainersIsMetric: (isMetric: boolean) => void
  onChangeContainersSocControl: (socControl: boolean) => void
  onChangeContainersGensetControl: (socControl: boolean) => void
  onAddContainersValuesItem: () => void
  onRemoveContainersValuesItem: (indexItem: number) => () => void
  // Type
  onChangeContainersType: (indexItem: number) => (value: IFreightSelectFieldDTM) => void
  onClearContainersType: (indexItem: number) => () => void
  onFocusContainersType: (indexItem: number) => () => void
  onBlurContainersType: (indexItem: number) => () => void
  // Quantity
  onChangeContainersQuantity: (indexItem: number) => (value: string) => void
  onFocusContainersQuantity: (indexItem: number) => () => void
  onBlurContainersQuantity: (indexItem: number) => () => void
  // Weight
  onChangeContainersWeight: (indexItem: number) => (value: string) => void
  onFocusContainersWeight: (indexItem: number) => () => void
  onBlurContainersWeight: (indexItem: number) => () => void
  // Volume
  onChangeContainersVolume: (indexItem: number) => (value: string) => void
  onFocusContainersVolume: (indexItem: number) => () => void
  onBlurContainersVolume: (indexItem: number) => () => void
}

export const ContainersComponent: FC<IContainersComponentProps> = ((props) => {
  const {
    quantityInfoBlock,
    formLocalState,
    isAllDisabled,
    forceCollapse,
    isCollapsed,
    // new
    isMetric,
    socControl,
    gensetControl,
    containersValues,
    errorsContainers,
    isSubmitVisited,
    isSubmitClicked,
    isContainersError,
    quotaStatus,
    temperatureControl,
    onChangeContainersIsMetric,
    onChangeContainersSocControl,
    onChangeContainersGensetControl,
    onAddContainersValuesItem,
    onRemoveContainersValuesItem,
    // Type
    onChangeContainersType,
    onClearContainersType,
    onFocusContainersType,
    onBlurContainersType,
    // Quantity
    onChangeContainersQuantity,
    onFocusContainersQuantity,
    onBlurContainersQuantity,
    // Weight
    onChangeContainersWeight,
    onFocusContainersWeight,
    onBlurContainersWeight,
    // Volume
    onChangeContainersVolume,
    onFocusContainersVolume,
    onBlurContainersVolume,
  } = props;

  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      setIsResponsive(!!formLocalState?.isCollapsed);
    }
  }, [forceCollapse, formLocalState?.isCollapsed]);
  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);
  const { t } = useTranslation();

  const typeOptions = useMemo(() => {
    if (temperatureControl) {
      return (
        <>
          {ContainerReeferTypesArray.map((item) => (
            <Option key={item} value={item} description={ContainerReeferTypesNamesLongConst[item]}>{ContainerReeferTypesNamesLongConst[item]}</Option>
          ))}
        </>
      );
    }

    return (
      <>
        {ContainerUsualTypesArray.map((item) => (
          <Option key={item} value={item} description={ContainerUsualTypesNamesLongConst[item]}>{ContainerUsualTypesNamesLongConst[item]}</Option>
        ))}
      </>
    );
  }, [temperatureControl, t]);

  const switchSoc = useCallback((invertActive?: boolean) => (
    <ContainersSwitchSOCWrap>
      <TypeSwitch
        value={!!socControl}
        data-class="switchSoc"
        invertActive={!!invertActive}
        rightText={t('SOC')}
        checkedChildren="On"
        unCheckedChildren="Off"
        disabledbg="true"
        onChange={onChangeContainersSocControl}
        disabled={isAllDisabled}
      />
    </ContainersSwitchSOCWrap>
  ), [socControl, isAllDisabled, isResponsive, onChangeContainersSocControl]);

  const switchGenset = useCallback((invertActive?: boolean) => (
    temperatureControl
      ? (
        <ContainersSwitchGensetWrap>
          <TypeSwitch
            value={!!gensetControl}
            data-class="switchGenset"
            invertActive={!!invertActive}
            rightText={t('Genset')}
            checkedChildren="On"
            unCheckedChildren="Off"
            disabledbg="true"
            onChange={onChangeContainersGensetControl}
            disabled={isAllDisabled}
          />
        </ContainersSwitchGensetWrap>
      )
      : null
  ), [gensetControl, isAllDisabled, temperatureControl, isResponsive, onChangeContainersGensetControl]);

  const switchMetric = useMemo(() => (
    <TypeSwitch
      value={!!isMetric}
      data-class="switchMetric"
      leftText={`${t('lbs')}/${t('inch')}`}
      rightText={`${t('kg')}/${t('cm')}`}
      disabledbg="true"
      onChange={onChangeContainersIsMetric}
      disabled={isAllDisabled}
    />
  ), [isMetric, isAllDisabled, onChangeContainersIsMetric, t]);

  const addBtnIsMedium = useMemo(() => (
    <Button
      type="dashed"
      disabled={isAllDisabled}
      size="small"
      onClick={onAddContainersValuesItem}
    >
      <PlusSvg
        height={11}
        width={11}
      /><span className="addContainer__title">{t('addContainer')}</span>
    </Button>
  ), [onAddContainersValuesItem, isAllDisabled, t]);

  const headerIsFullComponent = useMemo(() => isResponsive && (
    <>
      <Row className="FreightQuoteFormRequest__Row__Containers__content">
        <Col span={10}>
          <div className="FreightQuoteFormRequest__Row__Containers__content_title">
            <Typography.Text>{t('Type')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">*</span>}</Typography.Text>
          </div>
        </Col>
        <Col span={4}>
          <div className="FreightQuoteFormRequest__Row__Containers__content_title">
            <Typography.Text>{t('QTY')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">*</span>}</Typography.Text>
          </div>
        </Col>
        <Col span={4}>
          <div className="FreightQuoteFormRequest__Row__Containers__content_title">
            <Typography.Text>{t('Weight')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">kg *</span>}</Typography.Text>
            <Tooltip title={t('RFQMonetary.Weight that will be carried in a single container of particular type')} placement="top">
              <InfoCircleOutlined className="FreightQuoteFormRequest__Row__Commodity__content_title_icon" />
            </Tooltip>
          </div>
        </Col>
        <Col span={4}>
          <div className="FreightQuoteFormRequest__Row__Containers__content_title">
            <Typography.Text>{t('Volume')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">cbm *</span>}</Typography.Text>
            <Tooltip title={t('RFQMonetary.Volume that will be carried in a single container of particular type')} placement="top">
              <InfoCircleOutlined className="FreightQuoteFormRequest__Row__Commodity__content_title_icon" />
            </Tooltip>
          </div>
        </Col>
        <Col span={1} style={{ minWidth: isFullMediaWidth ? '40px' : '32px' }} />
      </Row>
    </>
  ), [isFullMediaWidth, isResponsive, t]);

  const onChangeContainersTypeHandler = useCallback(
    (index: number) => (value: string, option: unknown) => {
      const { description } = option as { description: string } || {};

      if (value) {
        onChangeContainersType(index)({
          code: value,
          description: description || '',
        });
      }
    },
    [onChangeContainersType],
  );

  const getContainerType = useCallback(
    (index: number) => {
      let value = null;

      if (containersValues?.[index]?.type?.code) {
        value = t(containersValues?.[index]?.type?.code || '');
      }

      return value;
    },
    [containersValues],
  );

  const containerTypeComponent = useCallback((index) => (
    <ContainersSelect
      value={getContainerType(index)}
      data-class={`containerTypeComponent_${index}`}
      size={sizeSelect}
      allowClear={!!containersValues?.[index]?.type?.code}
      placeholder={t('Container Type')}
      disabled={isAllDisabled}
      suffixIcon={collapseIcon}
      $isError={!!errorsContainers?.[index]?.type?.message
        && (!!errorsContainers?.[index]?.type?.isVisited || isSubmitVisited)}
      onClear={onClearContainersType(index)}
      onChange={onChangeContainersTypeHandler(index)}
      onFocus={onFocusContainersType(index)}
      onBlur={onBlurContainersType(index)}
    >
      {typeOptions}
    </ContainersSelect>
  ), [containersValues,
    errorsContainers,
    isAllDisabled,
    isSubmitVisited,
    onClearContainersType,
    onChangeContainersTypeHandler,
    onFocusContainersType,
    onBlurContainersType,
    getContainerType,
    sizeSelect, t, typeOptions,
  ]);

  const onChangeContainerWeightHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeContainersWeight(index)(event.target.value);
    },
    [onChangeContainersWeight],
  );

  const WeightComponent = useCallback((index: number) => (
    <ContainersInput
      value={containersValues?.[index]?.weight || ''}
      data-class={`WeightComponent_${index}`}
      $isError={!!errorsContainers?.[index]?.weight?.message
        && (!!errorsContainers?.[index]?.weight?.isVisited || isSubmitVisited)}
      disabled={isAllDisabled}
      onChange={onChangeContainerWeightHandler(index)}
      onFocus={onFocusContainersWeight(index)}
      onBlur={onBlurContainersWeight(index)}
    />
  ), [containersValues, errorsContainers, isSubmitVisited, isAllDisabled, onChangeContainerWeightHandler, onFocusContainersWeight, onBlurContainersWeight]);

  const onChangeContainerQuantityHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeContainersQuantity(index)(event.target.value);
    },
    [onChangeContainersQuantity],
  );

  const QuantityComponent = useCallback((index: number) => (
    <ContainersInput
      value={containersValues?.[index]?.quantity || ''}
      data-class={`QuantityComponent_${index}`}
      $isError={!!errorsContainers?.[index]?.quantity?.message
        && (!!errorsContainers?.[index]?.quantity?.isVisited || isSubmitVisited)}
      disabled={isAllDisabled}
      onChange={onChangeContainerQuantityHandler(index)}
      onFocus={onFocusContainersQuantity(index)}
      onBlur={onBlurContainersQuantity(index)}
    />
  ), [containersValues, errorsContainers, isSubmitVisited, isAllDisabled, onChangeContainerQuantityHandler, onFocusContainersQuantity, onBlurContainersQuantity]);

  const onChangeContainerVolumeHandler = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeContainersVolume(index)(event.target.value);
    },
    [onChangeContainersVolume],
  );

  const VolumeComponent = useCallback((index: number) => (
    <ContainersInput
      value={containersValues?.[index]?.volume || ''}
      data-class={`VolumeComponent_${index}`}
      $isError={!!errorsContainers?.[index]?.volume?.message
        && (!!errorsContainers?.[index]?.volume?.isVisited || isSubmitVisited)}
      disabled={isAllDisabled}
      onChange={onChangeContainerVolumeHandler(index)}
      onFocus={onFocusContainersVolume(index)}
      onBlur={onBlurContainersVolume(index)}
    />
  ), [containersValues, errorsContainers, isSubmitVisited, isAllDisabled, onChangeContainerVolumeHandler, onFocusContainersVolume, onBlurContainersVolume]);

  const itemContainerIsFull = useCallback((item: FreightFromContainersValuesStateDTM, index) => (
    <Row
      className={`${index === 0 ? 'FreightQuoteFormRequest__Row__Containers__parent_content_item_first' : ''} FreightQuoteFormRequest__Row__Containers__content FreightQuoteFormRequest__Row__Containers__parent_content_item`}
      key={`${item.id}`}
    >
      <Col span={10}>
        {containerTypeComponent(index)}
      </Col>
      <Col span={4}>
        {QuantityComponent(index)}
      </Col>
      <Col span={4}>
        {WeightComponent(index)}
      </Col>
      <Col span={4}>
        {VolumeComponent(index)}
      </Col>
      <Col span={1} className="FreightQuoteFormRequest__Row__Containers__content__col_add">
        {(index === 0
          && (
            <Button
              type="dashed"
              disabled={isAllDisabled}
              onClick={onAddContainersValuesItem}
              icon={<PlusSvg height={11} width={11} />}
              className="FreightQuoteFormRequest__Row__Containers__content__col_add_btn"
            />
          ))
          || (
            <Button
              type="dashed"
              disabled={isAllDisabled}
              onClick={onRemoveContainersValuesItem(index)}
              icon={<MinusSvg height={11} width={11} />}
              className="FreightQuoteFormRequest__Row__Containers__content__col_add_btn"
            />
          )}
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled,
    QuantityComponent,
    WeightComponent,
    VolumeComponent,
    onAddContainersValuesItem, onRemoveContainersValuesItem,
  ]);

  const itemContainerIsMedium = useCallback((item: FreightFromContainersValuesStateDTM, index) => (
    <Row
      className={`${index === 0 ? 'FreightQuoteFormRequest__Row__Containers__parent_content_item_first' : ''} FreightQuoteFormRequest__Row__Containers__content FreightQuoteFormRequest__Row__Containers__parent_content_item
        FreightQuoteFormRequest__Row__Containers__parent_content_item__medium`}
      key={`${item.id}`}
    >
      <Col span={24}>
        <Row>
          <Col span={18}>
            <div className="FreightQuoteFormRequest__Row__Containers__content_title">
              <Typography.Text>{t('Type')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">*</span>}</Typography.Text>
            </div>
          </Col>
          <Col span={6} className="FreightQuoteFormRequest__Row__Containers__content__col_add">
            {index !== 0 && (
              <Button
                onClick={onRemoveContainersValuesItem(index)}
                disabled={isAllDisabled}
                type="dashed"
                size="small"
                icon={<CloseSvg height={11} width={11} />}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {containerTypeComponent(index)}
          </Col>
        </Row>
      </Col>
      <Col span={24} className="FreightQuoteFormRequest__Row__Containers__content__row_second">
        <Row>
          <Col span={6}>
            <div className="FreightQuoteFormRequest__Row__Containers__content_title">
              <Typography.Text>{t('QTY')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">*</span>}</Typography.Text>
            </div>
          </Col>
          <Col span={10}>
            <div className="FreightQuoteFormRequest__Row__Containers__content_title">
              <Typography.Text>{t('Weight')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">kg *</span>}</Typography.Text>
              <Tooltip title={t('RFQMonetary.Weight that will be carried in a single container of particular type')} placement="top">
                <InfoCircleOutlined className="FreightQuoteFormRequest__Row__Commodity__content_title_icon" />
              </Tooltip>
            </div>
          </Col>
          <Col span={7}>
            <div className="FreightQuoteFormRequest__Row__Containers__content_title">
              <Typography.Text>{t('Volume')}{<span className="FreightQuoteFormRequest__Row__Element__Type_required">cbm *</span>}</Typography.Text>
              <Tooltip title={t('RFQMonetary.Volume that will be carried in a single container of particular type')} placement="top">
                <InfoCircleOutlined className="FreightQuoteFormRequest__Row__Commodity__content_title_icon" />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            {QuantityComponent(index)}
          </Col>
          <Col span={10}>
            {WeightComponent(index)}
          </Col>
          <Col span={7}>
            {VolumeComponent(index)}
          </Col>
        </Row>
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled,
    QuantityComponent,
    WeightComponent,
    VolumeComponent, t,
  ]);

  const containersComponent = useMemo(() => (
    <>
      {isResponsive && (
        <Row justify="space-between" className="FreightQuoteFormRequest__Row__col_both__title FreightQuoteFormRequest__Row__Containers__temperature">
          <div>
            <Typography.Text>{t('Containers')}</Typography.Text>
          </div>
          {/* Currently hidden */}
          {false && switchMetric}
        </Row>
      )}

      {headerIsFullComponent}
      {containersValues?.map((item, index) => (
        isResponsive ? itemContainerIsFull(item, index) : itemContainerIsMedium(item, index)
      ))}
      {quantityInfoBlock && isSubmitVisited && (
        <Row className="FreightQuoteFormRequest__Row__msg">
          <Col span={24}><AlertBlock type="warning" message={`${t('quantityMaxLength')} 99`} /></Col>
        </Row>
      )}
      <Row justify="start" className="FreightQuoteFormRequest__Row__col_both_col__title_owned switchSoc">
        {isResponsive ? (
          <>
            {/* temporarily hidden: tests cypress\e2e\localhost\RFQSubmitQuotas.cy.ts:163 */}
            {false && switchSoc()}
            {switchGenset()}
          </>
        ) : (
          <>
            <Col span={10} className="isMedium">
              {addBtnIsMedium}
            </Col>
            <Col span={switchGenset() ? 6 : 13} className="isMedium switchSoc">
              {/* temporarily hidden: tests cypress\e2e\localhost\RFQSubmitQuotas.cy.ts:163 */}
              {false && switchSoc(true)}
            </Col>
            <Col span={switchGenset() ? 8 : 0} className="isMedium switchGenset">
              {switchGenset(true)}
            </Col>
          </>
        )}
      </Row>
    </>
  ), [
    containersValues,
    quantityInfoBlock,
    addBtnIsMedium,
    switchSoc,
    switchGenset,
    t,
    headerIsFullComponent,
    switchMetric,
    isResponsive,
    itemContainerIsFull,
    itemContainerIsMedium,
  ]);
  const endVisible = useRef<HTMLElement>(null);

  const genExtra = useCallback(
    () => (
      <div
        onClick={(event) => event.stopPropagation()}
        role="button"
        onKeyDown={() => ''}
        tabIndex={0}
        className="FreightQuoteFormRequest__Row__Containers__temperature"
      >
        {switchMetric}
      </div>
    ),
    [switchMetric],
  );

  const [
    changeErrorIsCollapseState, setChangeErrorIsCollapseState,
  ] = useState<Array<string>>([]);

  useEffect(() => {
    if (isSubmitVisited && isContainersError) {
      setChangeErrorIsCollapseState(['1']);
    }
  }, [isSubmitVisited, isContainersError, isSubmitClicked]);

  const changeErrorIsCollapseCallback = useCallback(
    () => {
      setChangeErrorIsCollapseState((prev) => (prev.length ? [] : ['1']));
    },
    [],
  );
  useEffect(() => {
    if (quotaStatus === REQUEST_STATUS.pending) {
      if (containersValues?.some((item) => (item.type?.code || item.quantity || item.volume || item.weight))) {
        setChangeErrorIsCollapseState(['1']);
        return;
      }
      setChangeErrorIsCollapseState([]);
    }
  }, [quotaStatus, containersValues]);

  const collapseOnChangeHandler = useCollapseOnChangeHandler(
    endVisible.current, changeErrorIsCollapseCallback,
  );

  const collapseComponent = useMemo(() => (
    <Collapse
      expandIcon={({ isActive }) => ExpandIcon({
        isActive,
        color: `${changeErrorIsCollapseState.length ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
      })}
      onChange={collapseOnChangeHandler}
      activeKey={changeErrorIsCollapseState}
    >
      {/* Currently hidden */}
      <Panel header={t('Containers')} key="1" extra={false && genExtra()}>
        {containersComponent}
      </Panel>
    </Collapse>
  ), [changeErrorIsCollapseState, collapseOnChangeHandler, containersComponent, genExtra, t]);

  return (
    <ContainersWrapper isNotCollapsed={!isCollapsed}>
      <Row className="FreightQuoteFormRequest__Row__col_both FreightQuoteFormRequest__Row__Containers FreightQuoteFormRequest__Row__col__containersPanel">
        <Col span={24}>
          {!isResponsive ? (
            collapseComponent
          ) : (
            containersComponent
          )}
          <span
            id="FreightQuoteFormRequest__Row__col__containerssPanel__ref_endVisible"
            ref={endVisible}
          />
        </Col>
      </Row>
    </ContainersWrapper>
  );
});
