import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MissingTextValue } from 'app-wrapper/view/guideline';
import {
  HBLDocumentTransportationPlanDTM,
  ShipmentPreviewDTM,
  ShippingPartyDTM,
} from 'shipment-operations/models/dtm';

import { Cell } from '../Cell';
import { Column, Row, ShipmentTransportWrapper } from '../Common.styled';

enum TYPES {
  'SEA' = 'CY',
  'WATER' = 'CY',
  'RAIL' = 'RL',
  'AIR' = 'CY',
  'ROAD' = 'TR',
  'OCEAN' = 'CY',
}

interface ShipmentTransportProps {
  transportationPlanInfo: HBLDocumentTransportationPlanDTM
  shipment?: ShipmentPreviewDTM;
  cargoSupplier: ShippingPartyDTM | null;
  cargoReceiver: ShippingPartyDTM | null;
}

export const ShipmentTransport: FC<ShipmentTransportProps> = ({
  transportationPlanInfo,
  shipment,
  cargoSupplier,
  cargoReceiver,
}) => {
  const { t } = useTranslation();

  const preCarriageLocation = useMemo(() => {
    if (shipment?.getIsOriginDoor()) {
      return cargoSupplier?.address?.city || <MissingTextValue />;
    }

    return <MissingTextValue />;
  }, [shipment, cargoSupplier]);

  const placeOfReceipt = useMemo(() => {
    if (shipment?.getIsOriginDoor()) {
      return cargoSupplier?.address?.city || <MissingTextValue />;
    }

    return transportationPlanInfo.placeOfReceipt;
  }, [shipment, transportationPlanInfo, cargoSupplier]);

  const onCarriageLocation = useMemo(() => {
    if (shipment?.getIsDestinationDoor()) {
      return cargoReceiver?.address?.city || <MissingTextValue />;
    }

    return <MissingTextValue />;
  }, [shipment, cargoReceiver]);

  const placeOfDelivery = useMemo(() => {
    if (shipment?.getIsDestinationDoor()) {
      return cargoReceiver?.address?.city || <MissingTextValue />;
    }

    return transportationPlanInfo.placeOfDelivery;
  }, [shipment, cargoReceiver, transportationPlanInfo]);

  return (
    <ShipmentTransportWrapper>
      <Row>
        <Column>
          <Cell label="Vessel" value={transportationPlanInfo.vesselName} />
        </Column>
        <Column>
          <Cell label="Voyage" value={transportationPlanInfo.voyagerNumber} />
        </Column>
        <Column>
          <Cell
            label="Pre-carriage Location"
            value={preCarriageLocation}
            sign="DOOR"
          />
        </Column>
        <Column>
          <Cell
            label="On-carriage Location"
            value={onCarriageLocation}
            sign="DOOR"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Cell
            label={t('Place of Receipt')}
            value={placeOfReceipt}
            sign={TYPES[transportationPlanInfo.firstLegTransportType]}
          />
        </Column>
        <Column>
          <Cell
            label={t('Port of Loading')}
            value={transportationPlanInfo.portOfLoading}
            sign={TYPES[transportationPlanInfo.lastLegTransportType]}
          />
        </Column>
        <Column>
          <Cell
            label={t('Port of Discharge')}
            value={transportationPlanInfo.portOfDischarge}
            sign={TYPES[transportationPlanInfo.firstSeaLegTransportType]}
          />
        </Column>
        <Column>
          <Cell
            label={t('Place of Delivery')}
            value={placeOfDelivery}
            sign={TYPES[transportationPlanInfo.lastSeaLegTransportType]}
          />
        </Column>
      </Row>
    </ShipmentTransportWrapper>
  );
};
