import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EOrganizationStatus } from 'user-management/models/dtm';

import { MarkButton, CheckIcon } from './MarkAsCompleted.styled';

interface MarkAsCompletedComponentProps {
  status?: EOrganizationStatus;
  hasOwnRequestedQuotas: boolean;
  hasOwnBookedShipments: boolean;
  isUserTheOnlyMember: boolean;
  isUserOnboarded: boolean;
  onMarkAsCompleted: () => void;
}

export const MarkAsCompletedComponent: FC<MarkAsCompletedComponentProps> = ({
  onMarkAsCompleted,
  hasOwnRequestedQuotas,
  hasOwnBookedShipments,
  isUserTheOnlyMember,
  isUserOnboarded,
  status,
}) => {
  const { t } = useTranslation();

  const isVisible = useMemo(() => {
    if (!status || status !== EOrganizationStatus.ACTIVE || isUserOnboarded || (hasOwnBookedShipments && hasOwnRequestedQuotas && !isUserTheOnlyMember)) {
      return false;
    }

    return true;
  }, [status, hasOwnBookedShipments, hasOwnRequestedQuotas, isUserOnboarded, isUserTheOnlyMember]);

  if (!isVisible) {
    return null;
  }

  return (
    <MarkButton
      onClick={onMarkAsCompleted}
      icon={(
        <CheckIcon />
      )}
      type="default"
    >
      {t('Mark as Completed')}
    </MarkButton>
  );
};
