import React, { FC } from 'react';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { parseDurationFromHoursToDays } from 'app-wrapper/utils';
import { FreightQuotaContentDTM } from 'monetary/models/dtm';

import {
  RFQItemHeaderSecond,
  RFQItemHeaderSecondShipping,
  RFQItemHeaderSecondShippingArrow,
  RFQItemHeaderSecondShippingFirstAddress,
  RFQItemHeaderSecondShippingSecondAddress,
  RFQItemHeaderSecondTime,
  RFQItemHeaderSecondTimeIcon,
  RFQItemHeaderSecondTimeIconDiv,
  RFQItemHeaderSecondTimeText,
} from '../styled';

export interface IRFQItemHeaderComponentProps {
  data?: FreightQuotaContentDTM
  indexSchedule: number
}

export const RFQItemHeaderComponent: FC<IRFQItemHeaderComponentProps> = ({ data, indexSchedule }) => {
  const newData = FreightQuotaContentDTM.fromPlain({
    ...data,
    schedules: [
      ...data?.schedules?.map((item) => ({
        ...item,
        transportations: [
          ...item.transportations || [],
        ],
      })) || [],
    ],
  });

  return (
    <RFQItemHeaderSecond>
      <RFQItemHeaderSecondShipping>
        <RFQItemHeaderSecondShippingFirstAddress>
          {data?.getOriginLocationToOneString()}
        </RFQItemHeaderSecondShippingFirstAddress>

        {newData?.isFirstNotSamePOLLeg() && (
          <>
            <RFQItemHeaderSecondShippingArrow>
              <VerticalFormItemSvg style={{ color: themesColors.neutralBranding6 }} />
            </RFQItemHeaderSecondShippingArrow>
            <RFQItemHeaderSecondShippingFirstAddress>
              {newData?.getFirstNotSamePOLLeg()}
            </RFQItemHeaderSecondShippingFirstAddress>
          </>
        )}

        {newData?.isFirstPOLLeg() && (
          <>
            <RFQItemHeaderSecondShippingArrow>
              <VerticalFormItemSvg style={{ color: themesColors.neutralBranding6 }} />
            </RFQItemHeaderSecondShippingArrow>
            <RFQItemHeaderSecondShippingFirstAddress>
              {newData?.getFirstPOLLeg()}
            </RFQItemHeaderSecondShippingFirstAddress>
          </>
        )}

        {newData?.isSecondPOLLeg() && (
          <>
            <RFQItemHeaderSecondShippingArrow>
              <VerticalFormItemSvg style={{ color: themesColors.neutralBranding6 }} />
            </RFQItemHeaderSecondShippingArrow>
            <RFQItemHeaderSecondShippingFirstAddress>
              {newData?.getSecondPOLLeg()}
            </RFQItemHeaderSecondShippingFirstAddress>
          </>
        )}

        {newData?.isSecondNotSamePOLLeg() && (
          <>
            <RFQItemHeaderSecondShippingArrow>
              <VerticalFormItemSvg style={{ color: themesColors.neutralBranding6 }} />
            </RFQItemHeaderSecondShippingArrow>
            <RFQItemHeaderSecondShippingFirstAddress>
              {newData?.getSecondNotSamePOLLeg()}
            </RFQItemHeaderSecondShippingFirstAddress>
          </>
        )}

        <RFQItemHeaderSecondShippingArrow>
          <VerticalFormItemSvg style={{ color: themesColors.neutralBranding6 }} />
        </RFQItemHeaderSecondShippingArrow>
        <RFQItemHeaderSecondShippingSecondAddress>
          {newData?.getDestinationLocationToOneString()}
        </RFQItemHeaderSecondShippingSecondAddress>
      </RFQItemHeaderSecondShipping>

      <RFQItemHeaderSecondTime>
        <RFQItemHeaderSecondTimeIconDiv>
          <RFQItemHeaderSecondTimeIcon />
        </RFQItemHeaderSecondTimeIconDiv>
        <RFQItemHeaderSecondTimeText>{
          parseDurationFromHoursToDays(newData?.schedules?.[indexSchedule]?.totalDuration || 0)
        }
        </RFQItemHeaderSecondTimeText>
      </RFQItemHeaderSecondTime>

    </RFQItemHeaderSecond>
  );
};
