import HomeOutlined from '@ant-design/icons/HomeOutlined';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';
import { EMembershipType, MembershipTypeNames } from 'user-management/constants';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';

import {
  CIFinancialAndLegalData,
  CIFinancialAndLegalHeader,
  CIFinancialAndLegalIconBG,
  CIFinancialAndLegalItem,
  CIFinancialAndLegalRow,
  CIFinancialAndLegalTitle,
  CIFinancialAndLegalValue,
  CIFinancialAndLegalWrapper,
  CIFinancialAndLegalMembershipItem,
} from './CIFinancialAndLegal.styled';

interface ICIFinancialAndLegalComponentProps {
  organization?: OrganizationDTM
}

export const CIFinancialAndLegalComponent: FC<ICIFinancialAndLegalComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  const membershipWCA = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.WCA_ID))
  ), [organization]);

  const membershipDNB = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.DNB_ID))
  ), [organization]);

  const membershipXLP = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.XLP))
  ), [organization]);

  const membershipGAA = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.GAA))
  ), [organization]);

  const membershipJSTRANS = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.JC_TRANS))
  ), [organization]);

  const membershipGLA = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.GLA))
  ), [organization]);

  const hasMemberships = Boolean(membershipWCA?.getValidValue() || membershipDNB?.getValidValue() || membershipXLP?.getValidValue() || membershipGAA?.getValidValue() || membershipJSTRANS?.getValidValue() || membershipGLA?.getValidValue());

  return (
    <CIFinancialAndLegalWrapper>
      <CIFinancialAndLegalHeader>{t('Financial & Legal Information')}</CIFinancialAndLegalHeader>
      <CIFinancialAndLegalRow>
        <CIFinancialAndLegalItem>
          <CIFinancialAndLegalIconBG>
            <HomeOutlined />
          </CIFinancialAndLegalIconBG>
          <CIFinancialAndLegalData>
            <CIFinancialAndLegalTitle>{t('Tax ID')}</CIFinancialAndLegalTitle>
            <CIFinancialAndLegalValue>{organization?.taxId || <NoDataLineItem />}</CIFinancialAndLegalValue>
          </CIFinancialAndLegalData>
        </CIFinancialAndLegalItem>
        <CIFinancialAndLegalItem>
          <CIFinancialAndLegalIconBG>
            <BorderlessTableOutlined />
          </CIFinancialAndLegalIconBG>
          <CIFinancialAndLegalData>
            <CIFinancialAndLegalTitle>{t('EIN Number')}</CIFinancialAndLegalTitle>
            <CIFinancialAndLegalValue>{organization?.businessRegistrationNumber.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
          </CIFinancialAndLegalData>
        </CIFinancialAndLegalItem>
      </CIFinancialAndLegalRow>

      {hasMemberships ? (
        <>
          <CIFinancialAndLegalHeader
            style={{
              margin: '16px 0',
            }}
          >
            {t('Memberships')}
          </CIFinancialAndLegalHeader>

          <GapHorizontalContainerStyled
            style={{
              flexWrap: 'wrap',
            }}
          >
            {membershipWCA?.getValidValue() ? (
              <CIFinancialAndLegalMembershipItem>
                <CIFinancialAndLegalIconBG>
                  <BorderlessTableOutlined />
                </CIFinancialAndLegalIconBG>
                <CIFinancialAndLegalData>
                  <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.WCA_ID]}</CIFinancialAndLegalTitle>
                  <CIFinancialAndLegalValue>{membershipWCA?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
                </CIFinancialAndLegalData>
              </CIFinancialAndLegalMembershipItem>
            ) : null}

            {membershipDNB?.getValidValue() ? (
              <CIFinancialAndLegalMembershipItem>
                <CIFinancialAndLegalIconBG>
                  <BorderlessTableOutlined />
                </CIFinancialAndLegalIconBG>
                <CIFinancialAndLegalData>
                  <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.DNB_ID]}</CIFinancialAndLegalTitle>
                  <CIFinancialAndLegalValue>{membershipDNB?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
                </CIFinancialAndLegalData>
              </CIFinancialAndLegalMembershipItem>
            ) : null}

            {membershipXLP?.getValidValue() ? (
              <CIFinancialAndLegalMembershipItem>
                <CIFinancialAndLegalIconBG>
                  <BorderlessTableOutlined />
                </CIFinancialAndLegalIconBG>
                <CIFinancialAndLegalData>
                  <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.XLP]}</CIFinancialAndLegalTitle>
                  <CIFinancialAndLegalValue>{membershipXLP?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
                </CIFinancialAndLegalData>
              </CIFinancialAndLegalMembershipItem>
            ) : null}

            {membershipGAA?.getValidValue() ? (
              <CIFinancialAndLegalMembershipItem>
                <CIFinancialAndLegalIconBG>
                  <BorderlessTableOutlined />
                </CIFinancialAndLegalIconBG>
                <CIFinancialAndLegalData>
                  <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.GAA]}</CIFinancialAndLegalTitle>
                  <CIFinancialAndLegalValue>{membershipGAA?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
                </CIFinancialAndLegalData>
              </CIFinancialAndLegalMembershipItem>
            ) : null}

            {membershipJSTRANS?.getValidValue() ? (
              <CIFinancialAndLegalMembershipItem>
                <CIFinancialAndLegalIconBG>
                  <BorderlessTableOutlined />
                </CIFinancialAndLegalIconBG>
                <CIFinancialAndLegalData>
                  <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.JC_TRANS]}</CIFinancialAndLegalTitle>
                  <CIFinancialAndLegalValue>{membershipJSTRANS?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
                </CIFinancialAndLegalData>
              </CIFinancialAndLegalMembershipItem>
            ) : null}

            {membershipGLA?.getValidValue() ? (
              <CIFinancialAndLegalMembershipItem>
                <CIFinancialAndLegalIconBG>
                  <BorderlessTableOutlined />
                </CIFinancialAndLegalIconBG>
                <CIFinancialAndLegalData>
                  <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.GLA]}</CIFinancialAndLegalTitle>
                  <CIFinancialAndLegalValue>{membershipGLA?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
                </CIFinancialAndLegalData>
              </CIFinancialAndLegalMembershipItem>
            ) : null}
          </GapHorizontalContainerStyled>
        </>
      ) : null}
    </CIFinancialAndLegalWrapper>
  );
};
