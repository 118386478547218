import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContentSectionContainer,
  FooterContainer,
  GapVerticalContainerStyled,
  InputTitle,
  SubPage,
} from 'app-wrapper/view/guideline';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';
import { CustomModal } from 'shipment-operations/view/components';
import { EDrayageLoadingSide } from 'shipment-operations/constants';
import { Button, SkeletonSection } from 'app-wrapper/view/components';

import { CargoReadyDate } from './components';
import { StyledWrapper } from './EditSupplierScheduleDrawer.styled';

interface IChargesCostChangesComponentProps {
  isLoading: boolean;
  isAbleToSave: boolean;
  onClose: () => void;
  onSave: () => void;
  drayageSide?: EDrayageLoadingSide;
}

const EditSupplierScheduleDrawerComponent: FC<IChargesCostChangesComponentProps> = ({
  onClose,
  isAbleToSave,
  isLoading,
  onSave,
  drayageSide,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      title={drayageSide === EDrayageLoadingSide.ORIGIN_DRAYAGE ? t('Edit Supplier’s Schedule') : t('Edit Receiver’s Schedule')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '400px',
        background: 'white',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
        padding: '0',
        background: 'white',
      }}
    >
      <SubPage>
        <StyledWrapper>
          {isLoading ? (
            <SkeletonSection height="32px" />
          ) : (
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                <InputTitle title={drayageSide === EDrayageLoadingSide.ORIGIN_DRAYAGE ? t('Cargo Ready Date') : t('Delivery Date')}>
                  <CargoReadyDate />
                </InputTitle>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          )}
        </StyledWrapper>

        <Footer>
          <FooterContainer>
            <Button
              htmlType="submit"
              disabled={!isAbleToSave}
              loading={false}
              onClick={onSave}
            >
              {t('Save')}
            </Button>
          </FooterContainer>
        </Footer>
      </SubPage>
    </CustomModal>
  );
};

export { EditSupplierScheduleDrawerComponent };
