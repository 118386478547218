import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import minBy from 'lodash/fp/minBy';
import Collapse from 'antd/es/collapse';

import { compareMinMax } from 'monetary/utils';
import { IFreightQuotaContentSchedulesChargesDTM } from 'monetary/models/dtm';
import { StyledExpandIcon } from 'monetary/view/components';

import {
  FreeDays, RowRestWrapper,
  InnerTable, InnerTableHeader, InnerTableRow,
  StyledHeaderInner, TablePointer, StyledInnerPanel, Separator,
} from 'monetary/view/components/RFQResponse/RFQFreeTime/RFQFreeTime.styled';
import { ContainerAllTypesNamesShortConst, isContainerAllTypes } from 'shipment-operations/constants';
import { chargeCodeMeasureBy } from 'monetary/constants';

const CustomPanel = ({ data, name }: { data: IFreightQuotaContentSchedulesChargesDTM[], name: string }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const containers = [...new Set(data.map((item) => item.customContainerType))];

  const groupedData = data.reduce((acc: { [key: string]: IFreightQuotaContentSchedulesChargesDTM[] }, item) => {
    const key = item.customAppliance;
    if (key) {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
    }
    return acc;
  }, {});

  const minItem = minBy((item) => item?.applianceRange?.minValue, data);
  const min = minItem?.applianceRange?.minValue;

  return (
    <>
      <Separator />
      <Collapse ghost expandIcon={StyledExpandIcon} key={name} onChange={() => setIsOpen(!isOpen)}>
        <StyledInnerPanel
          key={name}
          header={(
            <StyledHeaderInner>
              <div style={{ color: isOpen ? '#6C79DE' : '' }}>{name}</div>
              <FreeDays><span style={{ color: '#202C3C' }}>{min ? min - 1 : '-'}</span>{` ${minItem?.measureBy === chargeCodeMeasureBy.HOUR ? t('free hour(s)') : t('free day(s)')}`}</FreeDays>
            </StyledHeaderInner>
          )}
        >
          <InnerTable>
            <Separator />
            <InnerTableHeader>
              <TablePointer />
              <div style={{ width: '20%' }}>{minItem?.measureBy === chargeCodeMeasureBy.HOUR ? t('Hour(s) Range') : t('daysRange')}</div>
              <RowRestWrapper>
                {containers.sort().map((container) => (
                  <div style={{ textAlign: 'right', width: '100%' }} key={container}>
                    {container && isContainerAllTypes(container) ? `${ContainerAllTypesNamesShortConst[container]}` : ''}
                  </div>
                ))}
              </RowRestWrapper>
            </InnerTableHeader>
            <Separator />
            <div>
              {Object.keys(groupedData).sort(compareMinMax).map((range) => (
                <>
                  <InnerTableRow>
                    <TablePointer />
                    <div style={{ width: '20%' }}>{range.replace(/[^\d+-]/g, '')}</div>
                    <RowRestWrapper>
                      {containers.map((container) => {
                        const item = groupedData[range].find((elem: IFreightQuotaContentSchedulesChargesDTM) => elem.customContainerType === container);
                        return <div style={{ textAlign: 'right', width: '100%' }} key={container}>{item ? `$ ${item.totalCost?.toFixed(2)}` : '-'}</div>;
                      })}
                    </RowRestWrapper>
                  </InnerTableRow>
                  <Separator style={{ marginTop: '4px' }} />
                </>
              ))}
            </div>
          </InnerTable>
        </StyledInnerPanel>
      </Collapse>
    </>
  );
};

export { CustomPanel };
