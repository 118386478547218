import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { Button } from 'app-wrapper/view/components';

import imageSRC from './assets/no-services.jpg';
import {
  Content,
  ContentTitle,
  ContentText,
  Image,
} from './NoServices.styled';

interface INoServicesProps {
  onAddServices: () => void
}

export const NoServices: FC<INoServicesProps> = (props) => {
  const {
    onAddServices,
  } = props;
  const { t } = useTranslation();

  const onAddServicesHandler = useCallback(
    () => {
      onAddServices();
    },
    [onAddServices],
  );

  return (
    <Content>
      <Image src={imageSRC} />

      <ContentTitle>
        {t('No Services Added')}
      </ContentTitle>

      <ContentText>
        {t('You can add services, this may affect the cost of the shipment.')}
      </ContentText>

      <Button
        onClick={onAddServicesHandler}
        icon={<PlusOutlined />}
      >
        {t('Add Service(s)')}
      </Button>
    </Content>
  );
};
