import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';

import { ShipmentInstructionsDetailsDTM } from 'shipment-operations/models/dtm';
import { ValueBlockWithIcon, DownloadCurrentStep, MissingTextValue } from 'app-wrapper/view/guideline';

import {
  Row,
  InfoBlock,
  InfoBlockItem,
  DownloadBlock,
  Button,
} from './ClearedContent.styled';

interface IClearedContentComponentProps {
  isCustomer: boolean;
  isOriginPartner: boolean;
  isDestinationPartner: boolean;
  downloadFinalMBL: (shipmentId: string) => void;
  openUploadFileDrawer: () => void;
  siDetails: ShipmentInstructionsDetailsDTM;
}

export const ClearedContentComponent: FC<IClearedContentComponentProps> = ({
  isCustomer,
  isOriginPartner,
  openUploadFileDrawer,
  isDestinationPartner,
  downloadFinalMBL,
  siDetails,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const { finalMblDocument } = siDetails;

  const handleDownloadFinalMBLDocument = useCallback(() => downloadFinalMBL(shipmentId), [downloadFinalMBL, shipmentId]);

  return (
    <Row>
      <InfoBlock>
        <InfoBlockItem>
          <ValueBlockWithIcon
            icon={<FileTextOutlined />}
            label={t('File name')}
            value={finalMblDocument ? (
              <DownloadCurrentStep
                onClick={handleDownloadFinalMBLDocument}
                text={t('MBL Document')}
              />
            ) : <MissingTextValue />}
          />
        </InfoBlockItem>

        <InfoBlockItem>
          <ValueBlockWithIcon
            icon={<UserOutlined />}
            label={t('Uploaded By')}
            value={finalMblDocument ? t('Skypace') : <MissingTextValue />}
          />
        </InfoBlockItem>

        <InfoBlockItem>
          <ValueBlockWithIcon
            icon={<CalendarOutlined />}
            label={t('Uploaded')}
            value={finalMblDocument?.createdAt ? finalMblDocument?.createdAt.getDateDMMMYYYYWithOffset() : <MissingTextValue />}
          />
        </InfoBlockItem>
      </InfoBlock>

      <DownloadBlock>
        {isCustomer || isDestinationPartner ? (
          <Button disabled={!finalMblDocument} onClick={handleDownloadFinalMBLDocument}>
            {t('Download')}
          </Button>
        ) : null}

        {isOriginPartner ? (
          <Button onClick={openUploadFileDrawer}>
            {!finalMblDocument ? t('Upload') : t('Edit')}
          </Button>
        ) : null}
      </DownloadBlock>
    </Row>
  );
};
