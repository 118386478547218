import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const borderAfter = css`
  position: absolute;
  content: '';
  border: 0.5px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  left: calc(50% + 0.5px);
  transform: translateX(-50%);
  z-index: 2;
  height: 101%;
`;

// Content

export const RFQItemDetailDivRoutingContentOrigin = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOrigin',
})`
  ${divFlex}
  width: 100%;
  flex-direction: column;
`;

export const RFQItemDetailDivRoutingContentOriginHeader = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginHeader',
})`
  ${divFlex}
  width: 100%;
  margin-top: 12px;
`;

export const RFQItemDetailDivRoutingContentOriginHeaderIcon = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginHeaderIcon',
})`
  ${divFlex}
  align-items: center;
  z-index: 3;
  width: 14px;
  &:after {
    ${borderAfter}
    top: 19px;
  }
`;

export const RFQItemDetailDivRoutingContentOriginHeaderText = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginHeaderText',
})`
  ${divFlex}
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  margin-left: 9px;
`;

export const RFQItemDetailDivRoutingContentOriginItem = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItem',
})`
  ${divFlex}
  margin-top: 28px;
`;

export const RFQItemDetailDivRoutingContentOriginItems = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItems',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  min-height: 56px;
  justify-content: center;
`;

export const RFQItemDetailDivRoutingContentOriginItemIcon = styled
  .div
  .attrs(
    (props: {
      isLast: boolean,
    }) => ({
      isLast: props.isLast,
    }),
  )`
  ${divFlex}
  margin-right: 10px;
  margin-left: 1px;
  svg {
    z-index: 3;
    margin-top: 2px;
  }
  &:after {
    ${borderAfter}
    top: 13px;
    display: ${(props) => (props.isLast ? 'none' : 'block')};
  }
`;

// First

const originRow = css`
  width: 100%;
  height: auto;
  min-height: 28px;
  align-items: center;
`;

const originFrom = css`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  min-width: 28px;
  width: 8%;
  font-size: 12px;
`;

const originFromName = css`
  width: 26%;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
`;

const originTo = css`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  min-width: 28px;
  width: 8%;
  font-size: 12px;
  font-weight: 400;
`;

const originToName = css`
  width: 28%;
  font-size: 12px;
  font-weight: 500;
`;

const originToFirstName = css`
  flex-direction: column;
  justify-content: center;
`;

const originToSecondName = css`
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  width: 100%;
  margin-left: 0;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
`;

const originDays = css`
  width: 30%;
  justify-content: center;
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstRow = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstRow',
})`
  ${divFlex}
  ${originRow}
  align-items: start;
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstFrom = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstFrom',
})`
  ${divFlex}
  ${originFrom}
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstName = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstName',
})`
  ${divFlex}
  ${originFromName}
  ${originToFirstName}
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstSecondName = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstSecondName',
})`
  ${divFlex}
  ${originFromName}
  ${originToSecondName}
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstDays = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstDays',
})`
  ${divFlex}
  ${originDays}
  align-items: center;
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstDaysIcon = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstDaysIcon',
})`
  ${divFlex}
  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding8};
  }
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstDaysText = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstDaysText',
})`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
  margin-right: 5px;
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstDaysInfo = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstDaysInfo',
})`
  ${divFlex}
  font-size: 10.5px;
  svg {
    color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  }
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstTo = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstTo',
})`
  ${divFlex}
  ${originTo}
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstToName = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstToName',
})`
  ${divFlex}
  ${originToName}
  ${originToFirstName}
`;

export const RFQItemDetailDivRoutingContentOriginItemFirstToSecondName = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemFirstToSecondName',
})`
  ${divFlex}
  ${originToName}
  ${originToSecondName}
`;

// Second

export const RFQItemDetailDivRoutingContentOriginItemSecondRow = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemSecondRow',
})`
  ${divFlex}
  ${originRow}
`;

export const RFQItemDetailDivRoutingContentOriginItemSecondEtd = styled.div.attrs((props: {
  $width: string
  'data-class'?: string
}) => ({
  $width: props.$width,
  'data-class': props?.['data-class'] || 'RFQItemDetailDivRoutingContentOriginItemSecondEtd',
}))`
  ${divFlex}
  ${originFrom}
  ${({ $width }) => ($width ? `width: ${$width}` : '')};
`;

export const RFQItemDetailDivRoutingContentOriginItemSecondEtdName = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemSecondEtdName',
})`
  ${divFlex}
  flex-direction: column;
  ${originFromName}
  font-weight: 400;
`;

export const RFQItemDetailDivRoutingContentOriginItemSecondDays = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemSecondDays',
})`
  ${divFlex}
  ${originDays}
`;

export const RFQItemDetailDivRoutingContentOriginItemSecondTo = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemSecondTo',
})`
  ${divFlex}
  ${originTo}
`;

export const RFQItemDetailDivRoutingContentOriginItemSecondToName = styled.div.attrs({
  'data-class': 'RFQItemDetailDivRoutingContentOriginItemSecondToName',
})`
  ${divFlex}
  flex-direction: column;
  ${originToName}
  font-weight: 400;
`;
