import styled from 'styled-components';
import Typography from 'antd/es/typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLCargoLayout = styled('div').attrs({
  'data-class': 'SBLCargoLayout',
})`
  ${divFlex}
  flex-direction: column;
`;

export const SBLCargoWarningWrap = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  padding: 18px 0 18px 28px;
  flex-direction: row;
  background-color: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
`;

export const SBLCargoWarningColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SBLCargoWarningTitle = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;

export const SBLCargoWarningText = styled(Typography)`
  white-space: pre-line;
  font-size: 14px;
  line-height: 22px;
`;

export const SBLWarningIcon = styled(InfoCircleOutlined)`
  margin-right: 16px;
  color: #FAAD14;
  font-size: 21px;
`;
