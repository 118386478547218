import styled from 'styled-components';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Typography from 'antd/es/typography';

import { Input } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px 0;
  width: 100%;
  border-top: 1px dashed rgba(234, 238, 248, 1);
  border-bottom: 1px dashed rgba(234, 238, 248, 1);
`;

export const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const CollapseHeaderTitle = styled(Typography)`
  margin-left: 12px;
  font-size: 14px;
  line-height: 22px;
  transition: .2s all ease-in;
`;

export const CollapseHeaderIcon = styled(InfoCircleOutlined)`
  cursor: initial;
  margin-left: 4px;
  color: rgba(115, 129, 155, 1);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  transition: .2s height ease;
  overflow: hidden;
`;

export const InfoWarning = styled.div`
  margin-top: 16px;
  padding: 0 16px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  background-color: rgba(240, 242, 252, 1);
  border: 1px solid rgba(196, 201, 242, 1);
`;

export const InfoWarningIcon = styled(InfoCircleOutlined)`
  margin-right: 12px;
  color: rgba(108, 121, 222, 1);
`;

export const InfoWarningTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PairedField = styled(Field)`
  width: 50%;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

export const Label = styled(Typography)`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 22px;
`;

export const Row = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
