import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  Container,
  ContainerWrap,
  Button,
} from 'shipment-operations/view/components/RollShipmentDrawer/components/Footer/Footer.styled';

interface FooterComponentProps {
  changeBookingSchedule: (shipmentId: string) => void;
  isLoading: boolean;
  isChangeQuotaMode: boolean;
}

export const FooterComponent: FC<FooterComponentProps> = ({
  isLoading,
  changeBookingSchedule,
  isChangeQuotaMode,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleChangeBookingSchedule = useCallback(() => {
    changeBookingSchedule(shipmentId);
  }, [changeBookingSchedule]);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <Button onClick={handleChangeBookingSchedule}>
            {isChangeQuotaMode ? t('Change quota') : t('Change schedule')}
          </Button>
        </ContainerWrap>
      )}
    </Container>
  );
};
