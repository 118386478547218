import { R as AuthR } from 'authentication/repository';
import { Moment } from 'moment';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { DateDtm } from 'app-wrapper/models/dtm';
import { BaseController, controller } from 'proto/BaseController';

import {
  CompanyDTM,
  ContactDTM,
  AddressDTM,
  ShippingPartyDTM,
  ShippingPartyOverviewDTM,
  TransportationOverviewDTM, CutoffsDTM, CarrierOverviewDTM, ReferencesOverviewDTM, PaymentTermsDTM,
} from 'shipment-operations/models/dtm';
import { EShippingPartyTypes } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';

@controller
export class TransportationOverviewController extends BaseController {
  public fetchTransportationOverviewData = async (shipmentId: string) => {
    this.dispatch(R.actions.transportationOverview.setLoading(true));
    const paymentTerms: PaymentTermsDTM | null = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId, true);
    const userData = AuthR.selectors.auth.getUser(this.store.getState());

    const shipmentPermissions = await this.repositories.shipmentPermissionsRepository.get(String(shipmentId), paymentTerms, userData.isAdmin, true);

    if (!shipmentPermissions) {
      return;
    }

    this.dispatch(R.actions.shipment.setPermissions(shipmentPermissions));

    let shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (!shipment) {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId);

      this.dispatch(R.actions.shipment.setShipment(shipment));
    }

    await this.fetchBookingOverview(shipmentId);
    await this.fetchShippingParties(shipmentId);
    this.dispatch(R.actions.transportationOverview.setLoading(false));
  };

  public sendCutoffs = async (shipmentId: string) => {
    this.dispatch(R.actions.transportationOverview.setLoading(true));

    this.dispatch(R.actions.transportationOverview.closeCutoffEditModal());

    const transportationOverview = R.selectors.transportationOverview.getTransportationOverview(this.store.getState());
    const savedCarrierCutoffDates = R.selectors.transportationOverview.getSavedCarrierCutoffDates(this.store.getState());

    if (
      savedCarrierCutoffDates
      && transportationOverview.documentationCarrierDate
      && (savedCarrierCutoffDates.documentationCarrierDate !== transportationOverview.documentationCarrierDate)
    ) {
      try {
        await R.services.inttra.submitDocumentationCutoff(shipmentId, transportationOverview.documentationCarrierDate);
      } catch (e) {
        console.error('TransportationOverviewController.sendCutoffs documentationCarrierDate error');

        this.dispatch(R.actions.transportationOverview.setLoading(false));

        return;
      }
    }

    if (
      savedCarrierCutoffDates
      && transportationOverview.portCarrierDate
      && (savedCarrierCutoffDates.portCarrierDate !== transportationOverview.portCarrierDate)
    ) {
      try {
        await R.services.inttra.submitPortCutoff(shipmentId, transportationOverview.portCarrierDate);
      } catch (e) {
        console.error('TransportationOverviewController.sendCutoffs portCarrierDate error');

        this.dispatch(R.actions.transportationOverview.setLoading(false));
        return;
      }
    }

    if (
      savedCarrierCutoffDates
      && transportationOverview.vgmCarrierDate
      && (savedCarrierCutoffDates.vgmCarrierDate !== transportationOverview.vgmCarrierDate)
    ) {
      try {
        await R.services.inttra.submitVgmCutoff(shipmentId, transportationOverview.vgmCarrierDate);
      } catch (e) {
        console.error('TransportationOverviewController.sendCutoffs vgmCarrierDate error');

        this.dispatch(R.actions.transportationOverview.setLoading(false));

        return;
      }
    }

    if (
      savedCarrierCutoffDates
      && transportationOverview.hazmatCarrierDate
      && (savedCarrierCutoffDates.hazmatCarrierDate !== transportationOverview.hazmatCarrierDate)
    ) {
      try {
        await R.services.inttra.submitHazmatCutoff(shipmentId, transportationOverview.hazmatCarrierDate);
      } catch (e) {
        console.error('TransportationOverviewController.sendCutoffs hazmatCarrierDate error');

        this.dispatch(R.actions.transportationOverview.setLoading(false));

        return;
      }
    }

    await this.fetchBookingOverview(shipmentId);

    this.dispatch(R.actions.transportationOverview.setLoading(false));
  };

  public openCutoffEditModal = () => {
    this.dispatch(R.actions.transportationOverview.openCutoffEditModal());
  };

  public closeCutoffEditModal = () => {
    this.dispatch(R.actions.transportationOverview.closeCutoffEditModal());
    this.dispatch(R.actions.transportationOverview.discardCutoffsChanges());
  };

  public setDocumentationCutoff = (date?: Moment | null) => {
    const MONDAY = 1;
    const SUNDAY = 7;

    const freightuneCutoffDate = R.selectors.transportationOverview.getSavedFreightuneCutoffDates(this.store.getState());
    if (!freightuneCutoffDate || !freightuneCutoffDate.documentationCarrierDate) {
      console.error('TransportationOverviewController.setDocumentationCutoff: no freightuneCutoffDate data');

      return;
    }

    // we can get date with current location offset, but wee need to have Date object with time for target location offset
    // so next line handle it, beware with moment.utcOffset

    let newDate = date ? DateDtm.fromPlainWithDateShift({
      date: date.format(),
      offset: freightuneCutoffDate.documentationCarrierDate.offset,
    }) : undefined;

    this.dispatch(R.actions.transportationOverview.setDocumentationCarrierCutoff(newDate));

    if (!date) {
      this.dispatch(R.actions.transportationOverview.setDocumentationFreightuneCutoff(date));

      return;
    }
    const documentationDynamicFreightuneDate = date.clone();

    if (date.isoWeekday() === MONDAY) {
      documentationDynamicFreightuneDate.subtract(3, 'days');

      newDate = DateDtm.fromPlainWithDateShift({
        date: documentationDynamicFreightuneDate.format(),
        offset: freightuneCutoffDate.documentationCarrierDate.offset,
      });

      this.dispatch(R.actions.transportationOverview.setDocumentationFreightuneCutoff(newDate));

      return;
    }

    if (date.isoWeekday() === SUNDAY) {
      documentationDynamicFreightuneDate.subtract(2, 'days');

      newDate = DateDtm.fromPlainWithDateShift({
        date: documentationDynamicFreightuneDate.format(),
        offset: freightuneCutoffDate.documentationCarrierDate.offset,
      });

      this.dispatch(R.actions.transportationOverview.setDocumentationFreightuneCutoff(newDate));

      return;
    }

    documentationDynamicFreightuneDate.subtract(1, 'days');

    newDate = DateDtm.fromPlainWithDateShift({
      date: documentationDynamicFreightuneDate.format(),
      offset: freightuneCutoffDate.documentationCarrierDate.offset,
    });

    this.dispatch(R.actions.transportationOverview.setDocumentationFreightuneCutoff(newDate));
  };

  public setPortCutoff = (date?: Moment | null) => {
    const freightuneCutoffDate = R.selectors.transportationOverview.getSavedFreightuneCutoffDates(this.store.getState());
    if (!freightuneCutoffDate || !freightuneCutoffDate.portCarrierDate) {
      console.error('TransportationOverviewController.setPortCutoff: no freightuneCutoffDate data');

      return;
    }

    const newDate = date ? DateDtm.fromPlainWithDateShift({
      date: date.format(),
      offset: freightuneCutoffDate.portCarrierDate.offset,
    }) : undefined;

    this.dispatch(R.actions.transportationOverview.setPortCarrierCutoff(newDate));
    this.dispatch(R.actions.transportationOverview.setPortFreightuneCutoff(newDate));
  };

  public setVgmCutoff = (date?: Moment | null) => {
    const freightuneCutoffDate = R.selectors.transportationOverview.getSavedFreightuneCutoffDates(this.store.getState());
    if (!freightuneCutoffDate || !freightuneCutoffDate.vgmCarrierDate) {
      console.error('TransportationOverviewController.setVgmCutoff: no freightuneCutoffDate data');

      return;
    }

    const newDate = date ? DateDtm.fromPlainWithDateShift({
      date: date.format(),
      offset: freightuneCutoffDate.vgmCarrierDate.offset,
    }) : undefined;

    this.dispatch(R.actions.transportationOverview.setVgmCarrierCutoff(newDate));
    this.dispatch(R.actions.transportationOverview.setVgmFreightuneCutoff(newDate));
  };

  public setHazmatCutoff = (date?: Moment | null) => {
    const freightuneCutoffDate = R.selectors.transportationOverview.getSavedFreightuneCutoffDates(this.store.getState());
    if (!freightuneCutoffDate || !freightuneCutoffDate.hazmatCarrierDate) {
      console.error('TransportationOverviewController.setDocumentationCutoff: no freightuneCutoffDate data');

      return;
    }

    const newDate = date ? DateDtm.fromPlainWithDateShift({
      date: date.format(),
      offset: freightuneCutoffDate.hazmatCarrierDate.offset,
    }) : undefined;

    this.dispatch(R.actions.transportationOverview.setHazmatCarrierCutoff(newDate));
    this.dispatch(R.actions.transportationOverview.setHazmatFreightuneCutoff(newDate));
  };

  public reset = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.transportationOverview.reset());
  };

  private getFullShippingParty = async (shippingParty?: ShippingPartyDTM) => {
    if (!shippingParty || !shippingParty.company) {
      return null;
    }

    const company: CompanyDTM | null = shippingParty.company || null;
    const address: AddressDTM | null = shippingParty.address ? AddressDTM.fromPlain({
      ...shippingParty.address,
      id: 0,
    }) : null;
    const contact: ContactDTM | null = shippingParty.contact ? ContactDTM.fromPlain({
      ...shippingParty.contact,
      id: 0,
    }) : null;

    const shippingPartyOverview = ShippingPartyOverviewDTM.fromPlain({
      companyName: company?.name,
      addressLine: [
        address?.address1,
        address?.address2,
        address?.city,
        address?.state,
        address?.postalCode,
        address?.country,
      ].filter((v) => !!v).join(', '),
      contactPerson: contact?.fullName,
      email: contact?.email,
      phone: contact?.phone,
      additionalPhone: contact?.phone2,
      taxId: company?.taxId,
      references: shippingParty.references,
    });

    if (!shippingPartyOverview.isValid()) {
      console.error(shippingPartyOverview.validate());
    }

    return shippingPartyOverview;
  };

  private fetchBookingOverview = async (shipmentId: string) => {
    let data: TransportationOverviewDTM | null = null;

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    try {
      data = await R.services.inttra.getBookingOverview(shipmentId);
    } catch (e) {
      console.error('Booking overview request error');

      return;
    }

    if (!data) {
      return;
    }

    this.dispatch(R.actions.transportationOverview.setBookingOverviewData({
      carrierName: data.carrierName,
      carrierReference: data.carrierReference,
      termsAndConditions: data.termsAndConditions,
      comments: data.comments,
      contactName: data.contactName,
      phoneList: data.phoneList,
      emailList: data.emailList,
      mblNumber: data.mblNumber || shipment?.carrierReferenceNumber,
      inttraReferenceNumber: data.inttraReferenceNumber,
      contractNumber: data.contractNumber,
      agentsReference: data.agentsReference,
    } as CarrierOverviewDTM & ReferencesOverviewDTM));

    this.dispatch(R.actions.transportationOverview.setCarrierSCAC(data.carrierName));

    this.dispatch(R.actions.transportationOverview.setCarrierCutoffs(CutoffsDTM.fromPlain({
      documentationCarrierDate: data.documentationCarrierDate,
      portCarrierDate: data.portCarrierDate,
      vgmCarrierDate: data.vgmCarrierDate,
      hazmatCarrierDate: data.hazmatCarrierDate,
    })));

    this.dispatch(R.actions.transportationOverview.setSavedCarrierCutoffs(CutoffsDTM.fromPlain({
      documentationCarrierDate: data.documentationCarrierDate,
      portCarrierDate: data.portCarrierDate,
      vgmCarrierDate: data.vgmCarrierDate,
      hazmatCarrierDate: data.hazmatCarrierDate,
    })));

    if (data.freightuneCutoffs) {
      this.dispatch(R.actions.transportationOverview.setFreightuneCutoffs(data.freightuneCutoffs));
      this.dispatch(R.actions.transportationOverview.setSavedFreightuneCutoffs(data.freightuneCutoffs));
    }
  };

  private fetchShippingParties = async (shipmentId: string) => {
    let shippingPartyList: ShippingPartyDTM[] = [];

    try {
      shippingPartyList = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('Shipping parties data request error', e);
    }

    if (!shippingPartyList) {
      return;
    }

    const shipper = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.SHIPPER);
    const consignee = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.CONSIGNEE);
    const notifyParty = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.NOTIFY_PARTY);
    const oceanCarrier = shippingPartyList.find(({ role }) => role === EShippingPartyTypes.OCEAN_CARRIER);

    if (oceanCarrier && oceanCarrier?.company) {
      const { name, scac } = oceanCarrier.company;

      if (name) {
        this.dispatch(R.actions.transportationOverview.setOceanCarrierName(name));
      }

      if (scac) {
        this.dispatch(R.actions.transportationOverview.setCarrierSCAC(scac || ''));
      }
    }

    this.dispatch(R.actions.transportationOverview.setShippingParties({
      shipper: await this.getFullShippingParty(shipper) || ShippingPartyOverviewDTM.createEmpty(),
      consignee: await this.getFullShippingParty(consignee) || ShippingPartyOverviewDTM.createEmpty(),
      notifyParty: await this.getFullShippingParty(notifyParty) || ShippingPartyOverviewDTM.createEmpty(),
    }));
  };
}
