import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { HeaderComponent } from 'shipment-operations/view/components/RollShipmentDrawer/components/Header/Header.component';

const HeaderContainer = () => {
  const originDate = useSelector(R.selectors.changeBookingSchedule.getOriginDate);
  const destinationDate = useSelector(R.selectors.changeBookingSchedule.getDestinationDate);
  const originLocationName = useSelector(R.selectors.changeBookingSchedule.getOriginLocationName);
  const destinationLocationName = useSelector(R.selectors.changeBookingSchedule.getDestinationLocationName);
  const totalCost = useSelector(R.selectors.changeBookingSchedule.getShipmentAppliedChargesTotalCost);
  const bookingWizardStep = useSelector(R.selectors.bookingWizard.getCurrentStep);
  const shipment = useSelector(R.selectors.bookingWizard.getShipment);

  return (
    <HeaderComponent
      originDate={originDate}
      destinationDate={destinationDate}
      originLocationName={originLocationName}
      destinationLocationName={destinationLocationName}
      totalCost={totalCost}
      hideTotalCost={bookingWizardStep === 1}
      carrierName={shipment?.carrier}
      isOriginDoor={shipment?.getIsOriginDoor()}
      isDestinationDoor={shipment?.getIsDestinationDoor()}
    />
  );
};

export { HeaderContainer as Header };
