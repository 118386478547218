import React, { FC } from 'react';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { DateDtm } from 'app-wrapper/models/dtm';
import { DatePicker } from 'app-wrapper/view/components';
import { colors } from 'app-wrapper/view/themes/themesColors';

import {
  Dates,
  Date,
  Label,
} from './Dates.styled';

const ERROR_STYLES = {
  borderColor: colors.red60,
};

interface IDatesComponentProps {
  cargoReadyDate?: Moment;
  setCargoReadyDate: (date: Moment | null) => void;
  isRequiredCargoReadyVisible: boolean;
  // setDeliveryDate: (date: Moment | null) => void;
  // deliveryDate?: Moment;
  // isRequiredDeliveryDateVisible: boolean;
}

export const DatesComponent: FC<IDatesComponentProps> = ({
  cargoReadyDate,
  setCargoReadyDate,
  // deliveryDate,
  // setDeliveryDate,
  // isRequiredDeliveryDateVisible,
  isRequiredCargoReadyVisible,
}) => {
  const { t } = useTranslation();

  const disabledDate = (current: moment.Moment) => {
    const threeWorkingDaysFromToday = DateDtm.getThreeWorkingDaysFromToday();

    return current && current.isBefore(threeWorkingDaysFromToday, 'day');
  };

  return (
    <Dates>
      <Date>
        <Label>
          {t('Cargo Ready Date')}
        </Label>

        <DatePicker
          allowClear={false}
          style={{
            ...(isRequiredCargoReadyVisible && !cargoReadyDate ? ERROR_STYLES : {}),
          }}
          value={cargoReadyDate}
          onChange={setCargoReadyDate}
          disabledDate={disabledDate}
        />
      </Date>

      {/* <Date> */}
      {/*  <Label> */}
      {/*    {t('Required Delivery Date')} */}
      {/*  </Label> */}

      {/*  <DatePicker */}
      {/*    allowClear={false} */}
      {/*    style={{ */}
      {/*      ...(isRequiredDeliveryDateVisible && !deliveryDate ? ERROR_STYLES : {}), */}
      {/*    }} */}
      {/*    value={deliveryDate} */}
      {/*    onChange={setDeliveryDate} */}
      {/*  /> */}
      {/* </Date> */}
    </Dates>
  );
};
