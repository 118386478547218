import React from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { R } from 'shipment-operations/repository';
import {
  CutoffComponent,
} from 'shipment-operations/view/components/TransportationDrayage';

export const CutoffContainer = observer(() => {
  const schedule = useSelector(R.selectors.shipmentTrackerRoutes.getSchedule);

  return (
    <CutoffComponent
      portCutOff={schedule.terminalCutOff}
      vgmCutOff={schedule.vgmCutOff}
      docCutOff={schedule.documentCutOff}
    />
  );
});
