import { IPaginationState, PaginationStore } from 'proto/BaseMobxStore';
import { LoadingControlDTM } from 'shipment-operations/models/dtm';
import { EDrayageLoadingSide } from 'shipment-operations/constants';

const initialState: IPaginationState<LoadingControlDTM> = {
  isLoading: false,
  items: [],
};

export class ShipmentLoadingControlsStore extends PaginationStore<LoadingControlDTM> {
  public get getExportLoadingControl() {
    return this.items.find((item) => item.drayageSide === EDrayageLoadingSide.ORIGIN_DRAYAGE);
  }

  public get getImportLoadingControl() {
    return this.items.find((item) => item.drayageSide === EDrayageLoadingSide.DESTITATION_DRAYAGE);
  }
}

export const createShipmentLoadingControlsStore = (_initialState?: IPaginationState<LoadingControlDTM>) => new ShipmentLoadingControlsStore(_initialState || initialState);
