import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { ShipmentPreviewDTM, ContainerDTM } from 'shipment-operations/models/dtm';
import { ContainerTypesConst } from 'shipment-operations/constants';
import { ShipmentInfo } from 'app-wrapper/view/drawers/components';

import {
  Section,
  Header,
  HeaderTitle,
  MarkAllButton,
  Container,
  ContainerType,
  ContainerName,
  MarkButton,
  MarkedButton,
} from './MarkingContainers.styled';

interface IMarkingContainersProps {
  shipment?: ShipmentPreviewDTM;
  containers: ContainerDTM[];
  markAll: () => void;
  markContainer: (id: string) => void;
  markedContainers: string[];
  markedLabel: string;
}

export const MarkingContainers: FC<IMarkingContainersProps> = ({
  shipment,
  containers,
  markAll,
  markContainer,
  markedContainers,
  markedLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Section>
      <ShipmentInfo borderless shipment={shipment} />

      <Header>
        <HeaderTitle>
          {t('Container')}
        </HeaderTitle>

        <MarkAllButton
          type="default"
          onClick={markAll}
        >
          {t('Mark All')}
        </MarkAllButton>
      </Header>

      {containers.map((container) => (
        <Container key={container.id}>
          <ContainerType>
            {ContainerTypesConst[container.type]} &apos;
          </ContainerType>

          <ContainerName>
            {container.name || t('Number pending entry')}
          </ContainerName>

          {markedContainers.includes(container?.id) ? (
            <MarkedButton
              type="default"
              icon={<CheckOutlined />}
            >
              {markedLabel}
            </MarkedButton>
          ) : (
            <MarkButton
              data-class="mark-as-provided"
              type="default"
              onClick={() => markContainer(container.id)}
            >
              {t('Mark as Provided')}
            </MarkButton>
          )}
        </Container>
      ))}
    </Section>
  );
};
