import {
  IsDefined, IsEnum,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TPermissionsDTM = {
  rejectButton?: PermissionAttributePolicy
  completeRequestButton?: PermissionAttributePolicy
  requestInformation?: PermissionAttributePolicy
}

export class PermissionsDTM extends BaseDTM<TPermissionsDTM> {
  @IsDefined()
  @IsEnum(() => PermissionAttributePolicy)
  rejectButton?: PermissionAttributePolicy

  @IsDefined()
  @IsEnum(() => PermissionAttributePolicy)
  completeRequestButton?: PermissionAttributePolicy

  @IsDefined()
  @IsEnum(() => PermissionAttributePolicy)
  requestInformation?: PermissionAttributePolicy
}
