import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from 'antd/es/menu';

import {
  Dropdown,
  MenuItem,
  MenuRemoveItem,
  Icon,
} from './ActionMenu.styled';

interface ActionMenuComponentProps {
  onDownload?: () => void;
  onDelete?: () => void;
}

export const ActionMenu: FC<ActionMenuComponentProps> = ({
  onDownload,
  onDelete,
}) => {
  const { t } = useTranslation();

  const menu = useMemo(() => (
    <Menu>
      {onDownload ? (
        <MenuItem onClick={onDownload}>
          {t('Download')}
        </MenuItem>
      ) : null}
      {onDelete ? (
        <MenuRemoveItem onClick={onDelete}>
          {t('Delete')}
        </MenuRemoveItem>
      ) : null}
    </Menu>
  ), [onDownload, onDelete]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={menu}
    >
      <Icon />
    </Dropdown>
  );
};
