import styled from 'styled-components';

import { Wrapper } from 'app-wrapper/view/guideline';

import {
  StyledTable,
} from 'shipment-operations/view/pages/ShipmentTransportationTracker/components/ContainersTable/ContainersTable.styled';

export const StyledWrapper = styled(Wrapper)`
  background: rgba(244, 245, 248, 1);
`;

export const StyledTableRadio = styled(StyledTable)`
  .ant-table-tbody tr .ant-table-cell:nth-child(1)::before, .dlTxbW .ant-table-tbody tr .ant-table-cell:nth-child(3)::before {
    display: none;
  }
`;

export const HeaderItemEvent = styled.div`
  width: 50%;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;
