import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';

import { PageHeader } from 'app-wrapper/view/components';
import { QUOTAS_STATUS, REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { AccountDrawer } from 'user-management/view/pages/Account/components';

import { AllRequestedQuotes, FreightQuoteContentModalSave, IARQQuotesItem } from 'monetary/view/components';
import { IFreightQuotasStateDTM } from 'monetary/models/dtm';

import {
  AllRequestedQuotesPageDiv,
} from './AllRequestedQuotesPage.styled';
import { AllRequestedSecondStep } from './AllRequestedSecondStep';
import { FreightQuoteContentHeaderSaveBtn } from '../FreightQuotePage/FreightQuoteContent/FreightQuoteContent.styled';

interface IAllRequestedQuotesPageComponentProps {
  allQuotesState?: IFreightQuotasStateDTM
  allQuotesData: IARQQuotesItem[]
  quotaStatus?: string
  quotaName?: string
  isFavorite?: boolean
  isFavoriteDisabled?: boolean
  allRequestBumpersPageStatus?: string
  savedRequestName?: string;
  isShowModalRequestName?: boolean;
  isLoadingRequestName?: boolean;
  isDisableModalRequestName?: boolean;
  isShowModalRequestBtn?: boolean;
  hasMore?: boolean;
  onChangeSavedName: (name: string) => void;
  onOpenRequestSavedName: () => void;
  onSaveRequestSavedName: (id?: number) => void;
  onCloseRequestSavedName: () => void;
  onRunAllQuotasPage: () => void
  onRunAllQuotasBumpersPage: (quotaId: string) => void
  onScrollNextInfinityPage: () => void
  onChangeAllRequestShowSaved: (value: boolean) => void
  onClickEmptyQuotesButton: () => void
}

export const AllRequestedQuotesPageComponent: FC<IAllRequestedQuotesPageComponentProps> = (props) => {
  const {
    allQuotesState,
    allQuotesData,
    quotaStatus,
    quotaName,
    isFavorite,
    isFavoriteDisabled,
    allRequestBumpersPageStatus,
    savedRequestName,
    isShowModalRequestName,
    isLoadingRequestName,
    isDisableModalRequestName,
    isShowModalRequestBtn,
    hasMore,
    onChangeSavedName,
    onOpenRequestSavedName,
    onSaveRequestSavedName,
    onCloseRequestSavedName,
    onRunAllQuotasPage,
    onRunAllQuotasBumpersPage,
    onScrollNextInfinityPage,
    onChangeAllRequestShowSaved,
    onClickEmptyQuotesButton,
  } = props;

  const { t } = useTranslation();
  const title = t('Requests');
  const [isRunScroll, setIsRunScroll] = useState(false);
  const [step, setStep] = useState(0);
  const [firstRun, setFirstRun] = useState(false);
  const navigate = useNavigate();

  const linkToFunc = useCallback(() => {
    setStep(1);
  }, []);

  const location = useLocation();
  const quotaSearchId = useMemo(() => {
    const url = new URLSearchParams(location.search);
    return url.get('quotaid');
  }, [location.search]);

  const resetPage = useCallback(
    () => {
      setIsRunScroll(false);

      onRunAllQuotasPage();
    },
    [onRunAllQuotasPage],
  );

  const resetSecondStepPage = useCallback(
    (quotaId: string) => {
      setIsRunScroll(false);

      onRunAllQuotasBumpersPage(quotaId);
    },
    [onRunAllQuotasBumpersPage],
  );

  useEffect(() => {
    if (!quotaSearchId) {
      setFirstRun(true);
      resetPage();
    }
  }, []);

  useEffect(() => {
    if (!quotaSearchId && firstRun) {
      setStep(0);
      resetPage();
      return;
    }

    if (quotaSearchId) {
      setStep(1);
      setFirstRun(true);
      resetSecondStepPage(quotaSearchId);
    }
  }, [quotaSearchId]);

  const nextScrollOnHandler = useCallback(
    () => {
      if (!isRunScroll) setIsRunScroll(true);
      if (allQuotesState?.quotasOption?.isEnd) return;

      onScrollNextInfinityPage();
    },
    [
      onScrollNextInfinityPage,
      allQuotesState?.quotasOption,
      isRunScroll,
    ],
  );

  const statusText = useCallback((status: string) => {
    switch (status) {
      case REQUEST_STATUS.pending:
        return t('Pending');
      case REQUEST_STATUS.complete:
        return t('Proposed');
      case REQUEST_STATUS.rejected:
        return t('Rejected');
      default:
        return '';
    }
  }, [t]);

  const statusColor = useCallback((status: string) => {
    switch (status) {
      case REQUEST_STATUS.pending:
        return themesColors.characterBrandingWarning;
      case REQUEST_STATUS.complete:
        return themesColors.characterBrandingSuccess;
      case REQUEST_STATUS.rejected:
        return themesColors.characterBrandingDanger;
      default:
        return '';
    }
  }, []);

  const stepComponent = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <AllRequestedQuotes
            dataElements={allQuotesData}
            isFavorite={isFavorite}
            hasMore={hasMore}
            isFavoriteDisabled={isFavoriteDisabled}
            savedRequestName={savedRequestName}
            isShowModalRequestName={isShowModalRequestName}
            isLoadingRequestName={isLoadingRequestName}
            onChangeSavedName={onChangeSavedName}
            onOpenRequestSavedName={onOpenRequestSavedName}
            onSaveRequestSavedName={onSaveRequestSavedName}
            onCloseRequestSavedName={onCloseRequestSavedName}
            onChangeAllRequestShowSaved={onChangeAllRequestShowSaved}
            onClickEmptyQuotesButton={onClickEmptyQuotesButton}
            linkToFunc={linkToFunc}
            isLoad={quotaStatus === QUOTAS_STATUS.pending}
            nextScrollOnHandler={nextScrollOnHandler}
            totalElements={allQuotesState?.totalElements || 0}
          />
        );
      case 1:
        return (
          <AllRequestedSecondStep />
        );
      default:
        return (<></>);
    }
  }, [allQuotesData,
    savedRequestName,
    hasMore,
    isShowModalRequestName,
    isLoadingRequestName,
    allQuotesState?.quotasOption,
    quotaStatus, nextScrollOnHandler, step, isFavorite]);

  const onBackHandler = useCallback(
    () => {
      setStep(0);
      navigate(RouteNames.ALL_REQUESTED_QUOTES());
    },
    [navigate],
  );

  const pageHeader = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <PageHeader
            title={title}
            ghost={false}
          />
        );
      case 1:
        return (
          (
            <PageHeader
              // onBack={onBackHandler}
              title={t('New Request')}
              ghost={false}
            >
              {isShowModalRequestBtn && (
                <FreightQuoteContentHeaderSaveBtn
                  disabled={isDisableModalRequestName}
                  onClick={onOpenRequestSavedName}
                >
                  {t('SaveRequest')}
                </FreightQuoteContentHeaderSaveBtn>
              )}
            </PageHeader>
          )
        );
      default:
        return (<></>);
    }
  }, [onBackHandler, allRequestBumpersPageStatus, isShowModalRequestBtn, statusColor, statusText, step, t, title, quotaName]);

  return (
    <AllRequestedQuotesPageDiv>
      <AccountDrawer />

      {isShowModalRequestName && (
        <FreightQuoteContentModalSave />
      )}

      {pageHeader}
      {stepComponent}
    </AllRequestedQuotesPageDiv>
  );
};
