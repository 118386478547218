import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import {
  Wrapper,
  Header,
  StepItem,
  IconsWrapper,
  StyledStepIcon,
  Line,
  Title,
  Location,
  InfoBlock,
} from 'shipment-operations/view/pages/ShipmentTransportationTracker/components/InnerSteps/InnerSteps.styled';
import { DateDtm } from 'app-wrapper/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { HeaderItemEvent, DescriptionWrapper } from './LinkContainer.styled';

interface InnerStepsProps {
  data: {
    id: string;
    title: string;
    city: string;
    country: string;
    date: DateDtm;
  }[];
}

const InnerSteps: FC<InnerStepsProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <HeaderItemEvent>{t('events')}</HeaderItemEvent>
        <HeaderItemEvent>{t('Date')}</HeaderItemEvent>
      </Header>
      {data.map((item, index) => (
        <StepItem key={item.id}>
          <IconsWrapper>
            <StyledStepIcon>
              <CheckOutlined style={{ color: 'white', fontSize: '10px' }} />
            </StyledStepIcon>
            {index + 1 !== data.length && (
              <Line active />
            )}
          </IconsWrapper>
          <InfoBlock lastItem={index + 1 === data.length}>
            <DescriptionWrapper>
              <Title style={{ color: themesColors.neutralBranding10 }}>
                {t(`eventsCodes.${item.title}`)}
              </Title>
              <div>
                <Location>
                  {item.city}, {item.country}
                </Location>
              </div>
            </DescriptionWrapper>
            <div>{item.date.getDateDMMMYYYYHHmmWithOffset()}</div>
          </InfoBlock>
        </StepItem>
      ))}
    </Wrapper>
  );
};

export { InnerSteps };
