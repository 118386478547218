import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'app-wrapper/i18n/i18n';
import { EReviewReason } from 'user-management/constants';

import { Textarea } from 'app-wrapper/view/components';
import {
  Modal,
  Title,
  TitleIcon,
  TitleContentTitle,
  TitleContent,
  TitleContentSubTitle,
  Label,
  ReasonSelect,
} from './RejectModal.styled';

const REASON_OPTIONS = [
  {
    value: EReviewReason.ALREADY_EXISTS,
    label: i18n.t('Already exists'),
  },
  {
    value: EReviewReason.OUTSIDE_SERVICEABLE_REGION,
    label: i18n.t('Outside serviceable region'),
  },
  {
    value: EReviewReason.SECURITY_CONCERNS,
    label: i18n.t('Security concerns'),
  },
  {
    value: EReviewReason.OTHER,
    label: i18n.t('Other'),
  },
];

interface RejectModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  rejectionNote: string;
  setRejectionNote: (note: string) => void;
  setRejectReason: (reason: string) => void;
  rejectReason: string;
}

export const RejectModalComponent: FC<RejectModalComponentProps> = ({
  isOpen,
  onOk,
  onClose,
  rejectionNote,
  setRejectionNote,
  rejectReason,
  setRejectReason,
}) => {
  const { t } = useTranslation();

  const handleRejectionNote = useCallback((e) => {
    setRejectionNote(e.target.value);
  }, [setRejectionNote]);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      onOk={onOk}
      okText={t('Reject')}
      okButtonProps={{
        disabled: !rejectReason.length,
      }}
    >
      <Title>
        <TitleIcon />

        <TitleContent>
          <TitleContentTitle>
            {t('Reject Customer')}
          </TitleContentTitle>

          <TitleContentSubTitle>
            {t('Select the reason for refusal and preferably comment on your decision.')}
          </TitleContentSubTitle>
        </TitleContent>
      </Title>

      <Label>
        {t('Rejection Reason *')}
      </Label>

      <ReasonSelect
        options={REASON_OPTIONS}
        value={rejectReason}
        placeholder={t('Reason')}
        onChange={setRejectReason}
        allowClear
      />

      <Label>
        {t('Comment')}
      </Label>

      <Textarea
        placeholder={t('Type your comment here...')}
        value={rejectionNote}
        onChange={handleRejectionNote}
      />
    </Modal>
  );
};
