import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { EditProfileOrganizationComponent } from './EditProfileOrganization.component';

const EditProfileOrganizationContainer: FC = () => {
  const organizationUpdate = useSelector(R.selectors.userOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.organizationFieldsErrors);
  const organizationCountries = useSelector(R.selectors.userOrganizationData.organizationCountriesLimited);
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const organizationEditProfileCountry = userManagementPermissions?.organizationEditProfileCountry;

  return (
    <EditProfileOrganizationComponent
      organization={organizationUpdate}
      organizationEditProfileCountry={organizationEditProfileCountry}
      organizationFieldsErrors={organizationFieldsErrors}
      countriesList={organizationCountries}
      closeEditProfileOrganizationDrawer={UC.organizationProfile.closeEditProfileOrganizationDrawer}
      saveEditProfileOrganization={UC.organizationProfile.saveEditProfileOrganization}
      onChangeUpdateAddress1={UC.organizationProfile.onChangeUpdateAddress1}
      onChangeUpdateAddress2={UC.organizationProfile.onChangeUpdateAddress2}
      onChangeUpdateAddressCity={UC.organizationProfile.onChangeUpdateAddressCity}
      onChangeUpdateAddressPostalCode={UC.organizationProfile.onChangeUpdateAddressPostalCode}
      onChangeUpdateAddressCountry={UC.organizationProfile.onChangeUpdateAddressCountry}
      onChangeUpdatePhone={UC.organizationProfile.onChangeUpdatePhone}
      onChangeUpdatePhone2={UC.organizationProfile.onChangeUpdatePhone2}
      onChangeUpdateEmail={UC.organizationProfile.onChangeUpdateEmail}
      onChangeUpdateWebUrl={UC.organizationProfile.onChangeUpdateWebUrl}
      onClearUpdateAddressCountry={UC.organizationProfile.onClearUpdateAddressCountry}
      onChangeUpdatePrimaryName={UC.organizationProfile.onChangeUpdatePrimaryName}
      onChangeUpdatePrimaryEmail={UC.organizationProfile.onChangeUpdatePrimaryEmail}
      onChangeUpdatePrimaryPhone={UC.organizationProfile.onChangeUpdatePrimaryPhone}
      onChangeUpdatePrimaryPhone2={UC.organizationProfile.onChangeUpdatePrimaryPhone2}
    />
  );
};

const EditProfileOrganizationContainerCache = memo(EditProfileOrganizationContainer);

export { EditProfileOrganizationContainerCache as EditProfileOrganization };
