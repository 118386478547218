import styled from 'styled-components';

import {
  TitleText,
  BodyTextRegular,
} from 'app-wrapper/view/fonts';
import {
  SubPageContent,
} from 'app-wrapper/view/guideline';
import { AgreeTextPdf } from 'app-wrapper/view/drawers/AgreementsDrawer/components/SkypaceTermsAndConditions/SkypaceTermsAndConditions.styled';

export const Content = styled(SubPageContent)``;

export const Image = styled.img`
  margin-bottom: 24px;
  width: 100%;
  height: auto;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  border-radius: 3px;
`;

export const Title = styled.span`
  margin-bottom: 8px;
  ${TitleText}
`;

export const Text = styled.span`
  ${BodyTextRegular}
  color: rgba(115, 129, 155, 1);
`;

export const AgreeTextLink = styled(AgreeTextPdf)`
  ${BodyTextRegular}
`;
