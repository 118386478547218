import moment from 'moment';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';

import { UC as shipmentUC } from 'shipment-operations/controllers';

import { UC } from 'monetary/controllers';
import { AdditionalServiceCheckedDTM } from 'monetary/models/dtm/Quotas';
import { FreightDoorsByPlaceIdDTM, FreightFromStateDTM } from 'monetary/models/dtm';
import { R as monetaryR } from 'monetary/repository';

const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD';

export class AdditionalDrawersUseCase extends BaseUseCase {
  public resetStateAddAdditionalServicesDrawer() {
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesChecked(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerBookingRFQFormState(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesGetQuote(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(undefined);
  }

  public getAdditionalServicePropsByRFQForm = async (form?: FreightFromStateDTM) => {
    const origin = !form?.origin?.isPort ? await this.getGoogleAddressByPlaceId(form?.origin?.location?.code) : undefined;
    const destination = !form?.destination?.isPort ? await this.getGoogleAddressByPlaceId(form?.destination?.location?.code) : undefined;

    const props = {
      origin: !form?.origin?.isPort ? origin?.getCountryCode() : form?.origin?.location?.code,
      destination: !form?.destination?.isPort ? destination?.getCountryCode() : form?.destination?.location?.code,
      fromDate: moment(form?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT),
      toDate: !form?.origin?.isPort ? moment(form?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT) : moment(form?.origin?.datePort?.latestDate).format(CALENDAR_DATE_FORMAT),
    };

    return props;
  }

  public resetBySubmitStateAddAdditionalServicesBookingDrawer() {
    const addAdditionalServicesDrawer = this.mobxStores.additionalServicesDrawerStore.state.servicesGetQuote?.map((item) => ({ ...item }));
    const addAdditionalServicesDrawerChecked = this.mobxStores.additionalServicesDrawerStore.state.servicesGetQuote?.map((item) => ({ ...item }));

    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(addAdditionalServicesDrawer);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(addAdditionalServicesDrawerChecked);
  }

  public setStartAdditionalServiceDrawer(rateService: AdditionalServiceCheckedDTM[]) {
    UC.AdditionalServices.setStartAdditionalServiceDrawer(rateService);
  }

  public updateShipmentAdditionalPage(shipmentId: string) {
    this.mobxStores.additionalServicesDrawerStore?.setAddAdditionalServicesDrawerServices(undefined);
    shipmentUC.shipmentServices.initServices(shipmentId);
  }

  public getGoogleAddressByPlaceId = async (placeId?: string) => {
    let placeResult: FreightDoorsByPlaceIdDTM | undefined;

    if (!placeId) {
      return placeResult;
    }

    try {
      placeResult = await monetaryR.services.RFQServiceById.getSessionRFQDoorByPlaceId(placeId);
    } catch (e) {
      await monetaryR.services.RFQServiceById.deleteSessionRFQDoorByPlaceId(placeId);

      throw e;
    }

    if (!placeResult) {
      placeResult = await monetaryR.services.RFQServiceById.getRFQLocationServiceByPlaceId(placeId);

      if (placeResult) {
        monetaryR.services.RFQServiceById.setSessionRFQDoorByPlaceId(placeId, placeResult);
      }
    }

    return placeResult;
  }
}
