import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Option,
  SelectWithError,
  Tooltip,
} from 'app-wrapper/view/components';
import { InputWithError } from 'app-wrapper/view/components/InputWithError/InputWithError.component';
import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  EditProfileOrganizationContactInformation, EditProfileOrganizationHeader, EditProfileOrganizationSpaceColumn, EditProfileOrganizationSpaceInput, EditProfileOrganizationSpaceRow, EditProfileOrganizationTitle,
} from './EditProfileOrganizationContactInformation.styled';

interface IEditProfileOrganizationContactInformationProps {
  organization?: OrganizationDTM
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  onChangeUpdatePrimaryName: (id: string) => void
  onChangeUpdatePrimaryEmail: (email: string) => void
  onChangeUpdatePrimaryPhone: (email: string) => void
  onChangeUpdatePrimaryPhone2: (email: string) => void
}

export const EditProfileOrganizationContactInformationComponent: FC<IEditProfileOrganizationContactInformationProps> = (props) => {
  const {
    organization,
    organizationFieldsErrors,
    onChangeUpdatePrimaryName,
    onChangeUpdatePrimaryEmail,
    onChangeUpdatePrimaryPhone,
    onChangeUpdatePrimaryPhone2,
  } = props;
  const { t } = useTranslation();

  const onChangePrimaryName = useCallback((id: string) => {
    onChangeUpdatePrimaryName(id);
  }, []);

  const onChangePrimaryEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryEmail(event.target.value);
  }, []);

  const onChangePrimaryPhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryPhone(event.target.value);
  }, []);

  const onChangePrimaryPhone2 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryPhone2(event.target.value);
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.message),
    [],
  );

  return (
    <EditProfileOrganizationContactInformation>
      <EditProfileOrganizationHeader>
        {t('PrimaryContact')}
      </EditProfileOrganizationHeader>
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Name')} *`}
          </EditProfileOrganizationTitle>
          <SelectWithError
            data-class="EditProfileOrganizationSelectPrimaryName"
            value={organization?.membersContactsValue?.id || ''}
            hasError={getHasErrorField(organizationFieldsErrors?.MembersContactsValue)}
            showSearch
            onChange={onChangePrimaryName}
            optionFilterProp="children"
          >
            {organization?.membersContacts?.map((item) => (
              <Option
                value={item.id || ''}
                key={`EditProfileOrganizationSelect_${item.id}`}
              >
                {item.getFullName() || item.email}
              </Option>
            ))}

          </SelectWithError>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Email')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.primaryEmail?.message && organizationFieldsErrors?.primaryEmail?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.primaryEmail?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryEmail?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <InputWithError
              value={organization?.membersContactsValue?.email}
              onChange={onChangePrimaryEmail}
              disabled
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Phone')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.primaryPhone?.message && organizationFieldsErrors?.primaryPhone?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.primaryPhone?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryPhone?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <InputWithError
              value={organization?.membersContactsValue?.phone}
              onChange={onChangePrimaryPhone}
              disabled
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Additional Phone')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.primaryPhone2?.message && organizationFieldsErrors?.primaryPhone2?.message !== t('basicErrors.REQUIRED_MESSAGE')}
            title={`${organizationFieldsErrors?.primaryPhone2?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryPhone2?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <InputWithError
              value={organization?.membersContactsValue?.phone2 || ''}
              onChange={onChangePrimaryPhone2}
              disabled
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
    </EditProfileOrganizationContactInformation>
  );
};
