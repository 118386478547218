import React, { FC } from 'react';

import {
  ContainersDetails,
  ShipmentAdditionalServices,
  ShipperInformation,
  ShipperReference,
  TemperatureControl,
} from 'shipment-operations/view/components/BookingDrawer/components';
import { Commodity } from 'shipment-operations/view/components/BookingDrayageDrawer/components';
import { ConsigneeInformationContainer } from 'shipment-operations/view/components/BookingDrawer/components/ConsigneeInformation';
import { BookingDrawerDivider, Content } from 'shipment-operations/view/components/BookingDrawer/BookingDrawer.styled';

interface IBookingDetailsProps {
  shouldShowShipperInformation: boolean;
  shouldShowConsigneeInformation: boolean;
  hasTemperatureControl: boolean;
}

export const BookingDetailsComponent: FC<IBookingDetailsProps> = ({
  shouldShowShipperInformation,
  shouldShowConsigneeInformation,
  hasTemperatureControl,
}) => (
  <Content>
    <Commodity />

    {hasTemperatureControl ? <TemperatureControl /> : null}

    <ContainersDetails />

    <ShipperReference />

    {shouldShowShipperInformation ? (
      <ShipperInformation />
    ) : null}

    {shouldShowConsigneeInformation ? (
      <ConsigneeInformationContainer />
    ) : null}

    <BookingDrawerDivider />

    <ShipmentAdditionalServices />
  </Content>
);
