import { createSelector } from 'reselect';
import get from 'lodash/fp/get';

import { RootState } from 'app-wrapper/store';
import { FRT } from 'monetary/constants';
import { ELocationType } from 'app-wrapper/types/LocationType';

const localState = (state: RootState) => state.addRates;

export const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

export const getChargeCodes = createSelector(
  localState,
  (state) => state.chargeCodes,
);

export const getChargeCodesOptions = createSelector(
  getChargeCodes,
  (chargeCodes) => chargeCodes.map((chargeCode) => ({
    value: chargeCode.code,
    label: chargeCode.description,
  })),
);

export const getUniqContainers = createSelector(
  localState,
  (state) => state.uniqContainers,
);

export const getEditableDataByContainer = createSelector(
  localState,
  (state) => state.editableDataByContainer,
);

export const getEditableDataByShipment = createSelector(
  localState,
  (state) => state.editableDataByShipment,
);

export const getContractNumber = createSelector(
  localState,
  (state) => state.contractNumber,
);

export const getValidFrom = createSelector(
  localState,
  (state) => state.validFrom,
);

export const getValidTo = createSelector(
  localState,
  (state) => state.validTo,
);

export const getFRTChargeCode = createSelector(
  localState,
  (state) => state.frtCharge,
);

export const getFRTCharge = createSelector(
  getEditableDataByContainer,
  (data) => data.find((item) => item.chargeCode?.code === FRT),
);

export const getTransportationPlans = createSelector(
  localState,
  (state) => state.transportationPlans,
);

export const getTransportLegs = createSelector(
  getTransportationPlans,
  (transportationPlans) => (
    transportationPlans?.route.legs.map((leg) => ({
      departureLocation: {
        code: leg.departureLocation.code,
        type: leg.departureLocation.type,
      },
      arrivalLocation: {
        code: leg.arrivalLocation.code,
        type: leg.departureLocation.type,
      },
      sequence: leg.sequence,
    }))
  ),
);

export const getTransportLegsFiltered = createSelector(
  getTransportLegs,
  (legs) => {
    const filtered = legs?.filter((leg) => leg.departureLocation.type !== ELocationType.DOOR || leg.arrivalLocation.type !== ELocationType.DOOR);
    return filtered?.map((leg, index) => ({
      ...leg,
      sequence: index,
    }));
  },
);

export const getApplicationLevel = createSelector(
  getTransportLegsFiltered,
  (legs) => {
    const originCode = get([0, 'departureLocation', 'code'], legs);
    const destinationCode = get([(legs?.length || 0) - 1, 'arrivalLocation', 'code'], legs);
    return (
      {
        type: 'ROUTE',
        origin: originCode,
        destination: destinationCode,
      }
    );
  },
);

export const getBookValidity = createSelector(
  localState,
  (state) => state.bookValidity,
);

export const getHeaderErrors = createSelector(
  localState,
  (state) => state.headerErrors,
);

export const getIsShipmentTabError = createSelector(
  localState,
  (state) => state.shipmentTabError,
);

export const getIsContainerTabError = createSelector(
  localState,
  (state) => state.containerTabError,
);

export const getIsTableError = createSelector(
  getIsShipmentTabError,
  getIsContainerTabError,
  (shipmentTabError, containerTabError) => shipmentTabError || containerTabError,
);

const getIsLoadingAddCharges = createSelector(
  localState,
  (state) => state.addChargesLoading,
);

export const addRatesSelectors = {
  getIsLoading,
  getChargeCodesOptions,
  getEditableDataByContainer,
  getUniqContainers,
  getEditableDataByShipment,
  getContractNumber,
  getFRTChargeCode,
  getFRTCharge,
  getValidFrom,
  getValidTo,
  getChargeCodes,
  getApplicationLevel,
  getTransportLegs,
  getBookValidity,
  getHeaderErrors,
  getIsTableError,
  getIsShipmentTabError,
  getIsContainerTabError,
  getIsLoadingAddCharges,
  getTransportLegsFiltered,
};
