import { SmallTextFootnoteDescription, BodyTextRegular } from 'app-wrapper/view/fonts';
import themesColors from 'app-wrapper/view/themes/themesColors';
import styled from 'styled-components';

export const InfoCardContainer = styled.div.attrs({
  'data-class': 'InfoCardContainer',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

export const InfoCardIconBG = styled.div.attrs({
  'data-class': 'InfoCardIconBG',
})`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background-color: transparent;
  border-radius: 4px;
  color: ${themesColors.primaryBranding5};
  border: 1px solid ${themesColors.neutralBranding4};
  font-size: 14px;
`;

export const InfoCardData = styled.div.attrs({
  'data-class': 'InfoCardData',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`;

export const InfoCardTitle = styled.div.attrs({
  'data-class': 'InfoCardTitle',
})`
  ${SmallTextFootnoteDescription}
  color: ${themesColors.darkGrayMiddle};
`;

export const InfoCardValue = styled.div.attrs(() => ({
  'data-class': 'OrganizationAddressValue',
}))`
  ${BodyTextRegular};
`;
