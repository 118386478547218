import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { TaxIdInputComponent } from './TaxIDInput.component';

export const TaxIDInput = () => {
  const taxId = useSelector(R.selectors.shippingParties.getTaxId);
  const role = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissions = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(role));

  return (
    <TaxIdInputComponent
      taxId={taxId}
      setTaxId={UC.shippingParties.setTaxId}
      isAbleToEdit={permissions.isAbleToEditMainFormData}
    />
  );
};
