import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const SystemPageSvg = ({ width, height }: IPropsIcon) => (
  <svg
    width={width || 60}
    height={height || 60}
    viewBox="0 0 1024 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M237.478 267.322H144.347C132.606 267.322 123 257.851 123 246.278V153.365C123 141.791 132.606 132.32 144.347 132.32H237.478C249.219 132.32 258.826 141.791 258.826 153.365V246.278C258.826 257.851 249.219 267.322 237.478 267.322Z" fill="#E4EBF7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M786.121 884.123C774.38 884.123 764.775 874.571 764.775 862.898V769.183C764.775 757.509 774.38 747.957 786.121 747.957H879.252C890.993 747.957 900.6 757.509 900.6 769.183V862.898C900.6 874.571 890.993 884.123 879.252 884.123H786.121Z" fill="#CCD7EE" />
    <path d="M825.794 125H404.939C374.877 125 350.507 149.37 350.507 179.432V601.614C350.507 631.676 374.877 656.046 404.939 656.046H825.794C855.856 656.046 880.226 631.676 880.226 601.614V179.432C880.226 149.37 855.856 125 825.794 125Z" fill="#253368" />
    <path fillRule="evenodd" clipRule="evenodd" d="M487.465 310.807C484.962 310.807 482.937 307.614 482.937 303.667V275.108C482.937 271.168 484.962 267.975 487.465 267.975H614.234C616.737 267.975 618.762 271.168 618.762 275.108V303.667C618.762 307.614 616.737 310.807 614.234 310.807H487.465Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M811.18 412.93H494.257C488 412.93 482.937 409.737 482.937 405.791V377.231C482.937 373.291 488 370.098 494.257 370.098H811.18C817.437 370.098 822.5 373.291 822.5 377.231V405.791C822.5 409.737 817.437 412.93 811.18 412.93Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M491.993 515.055C486.988 515.055 482.937 511.862 482.937 507.915V479.356C482.937 475.416 486.988 472.223 491.993 472.223H745.532C750.537 472.223 754.588 475.416 754.588 479.356V507.915C754.588 511.862 750.537 515.055 745.532 515.055H491.993Z" fill="white" />
    <path d="M598.287 366.693H177.432C147.37 366.693 123 391.063 123 421.125V843.307C123 873.369 147.37 897.739 177.432 897.739H598.287C628.349 897.739 652.719 873.369 652.719 843.307V421.125C652.719 391.063 628.349 366.693 598.287 366.693Z" fill="#CCD7EE" />
    <path d="M177.432 366.693H598.287C628.349 366.693 652.719 391.063 652.719 421.125V491.805H123V421.125C123 391.063 147.37 366.693 177.432 366.693Z" fill="#4E5C93" />
    <path d="M560.998 547.113H211.325C200.051 547.113 190.913 556.252 190.913 567.525V611.805C190.913 623.078 200.051 632.217 211.325 632.217H560.998C572.272 632.217 581.41 623.078 581.41 611.805V567.525C581.41 556.252 572.272 547.113 560.998 547.113Z" fill="white" />
    <path d="M560.998 688.104H211.325C200.051 688.104 190.913 697.242 190.913 708.516V817.474C190.913 828.747 200.051 837.886 211.325 837.886H560.998C572.272 837.886 581.41 828.747 581.41 817.474V708.516C581.41 697.242 572.272 688.104 560.998 688.104Z" fill="white" />
  </svg>

);
