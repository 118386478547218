import { action, computed } from 'mobx';

import { CompanyDTM } from 'shipment-operations/models/dtm';
import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';

interface ICreatedCompanyState {
  newCompany?: CompanyDTM
}

const initialState = {
  newCompany: undefined,
  isLoading: false,
};

export class CreatedCompanyStore extends ControllerStore<IControllerState<ICreatedCompanyState>> {
  @action
  setNewCompany(newCompany?: CompanyDTM) {
    this.state.newCompany = newCompany;
  }

  @computed
  get getNewCompany() {
    return this.state.newCompany;
  }
}

export const createCreatedCompanyStore = (_initialState?: IControllerState<ICreatedCompanyState>) => new CreatedCompanyStore(_initialState || initialState);
