import React, { FC } from 'react';
import map from 'lodash/fp/map';

import {
  HBLDocumentCargoTotalDTM,
  HBLDocumentContainerTotalDTM,
} from 'shipment-operations/models/dtm';
import { CONTAINER_TYPES_TO_NAME, CargoReferenceType } from 'shipment-operations/constants';
import {
  Cell,
  Row,
  Column,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { HeaderComponent, CargoItem } from './components';
import {
  Cell as StyledCell,
  StyledSign,
  MarksAndNumbersWrapper,
  SectionWrapper,
  Bold,
} from './CargoAndContainers.styled';

interface Props {
  cargo: HBLDocumentCargoTotalDTM
  container: HBLDocumentContainerTotalDTM
}

export const CargoAndContainers: FC<Props> = ({ cargo, container }) => {
  const containerTypes = map((item) => CONTAINER_TYPES_TO_NAME[item], container.containerTypes).join(' ');
  const { quantity, references, marks } = container;

  return (
    <Row>
      <Column>
        <SectionWrapper>
          <HeaderComponent />
          <Row>
            <StyledCell>
              <CargoItem label="Goods Description" value="General Cargo" />
            </StyledCell>
            <StyledCell>
              <CargoItem label="PCS" value={cargo.packagesNumber} alignItems="flex-end" />
            </StyledCell>
            <StyledCell>
              <CargoItem label="Gross Weight, kg" value={cargo.weight} alignItems="flex-end" />
            </StyledCell>
            <StyledCell>
              <CargoItem label="Volume, cbm" value={cargo.volume} alignItems="flex-end" />
            </StyledCell>
          </Row>
          <Row>
            <Column><Cell label="Container Type(s)" value={containerTypes} /></Column>
            <Column><Cell label="Total No of Packages" value={`(${quantity}) containers`} /></Column>
          </Row>

          <Column>
            <Bold>Marks & Numbers</Bold>
            <MarksAndNumbersWrapper>
              <Row>
                <StyledCell>
                  {references.map(({ type, value }) => (
                    <StyledSign key={value}>{type ? CargoReferenceType[type] : ''}-{value}</StyledSign>
                  ))}
                </StyledCell>
                <StyledCell flex={2}>
                  <StyledCell>
                    {map((item) => <span key={item}>{item}</span>, marks)}
                  </StyledCell>
                </StyledCell>
              </Row>
            </MarksAndNumbersWrapper>
          </Column>
        </SectionWrapper>
      </Column>
    </Row>
  );
};
