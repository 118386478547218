import React, {
  FC,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';

import { hasAccess } from 'app-wrapper/utils';
import { Input } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface ICargoFormDescriptionInputComponentProps {
  description: CargoDTM['description']
  touchField: (field: string) => void
  setDescription: (description: CargoDTM['description']) => void
  cargosAvailability?: PermissionAttributePolicy
  customerCargosAvailability?: PermissionAttributePolicy
}

export const CargoFormDescriptionInputComponent: FC<ICargoFormDescriptionInputComponentProps> = ({
  description,
  touchField,
  setDescription,
  cargosAvailability,
  customerCargosAvailability,
}) => {
  const handleChangeDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('description');
  }, []);

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, customerCargosAvailability]);

  return (
    <Input
      disabled={isDisabled}
      value={description}
      onChange={handleChangeDescription}
      onBlur={handleBlur}
    />
  );
};
