import styled from 'styled-components';
import Typography from 'antd/es/typography';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { Button } from 'app-wrapper/view/components';

export const WarningWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 9px 16px;
  flex-direction: row;
  align-items: center;
  background-color: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
`;

export const WarningText = styled(Typography)`
  white-space: pre-line;
  font-size: 14px;
  line-height: 22px;
`;

export const WarningIcon = styled(InfoCircleOutlined)`
  margin-right: 12px;
  color: #FAAD14;
  font-size: 14px;
`;

export const WarningActions = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
`;

export const CancelButton = styled(Button)`
  margin-right: 8px;
  border-color: rgba(255, 77, 79, 1);
  color: rgba(255, 77, 79, 1);
`;
