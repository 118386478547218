import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ShipmentTransportationChargesComponent } from 'shipment-operations/view/pages/ShipmentTransportationCharges/ShipmentTransportationCharges.component';

const ChargesContainer = React.memo(() => {
  const isLoading = useSelector(R.selectors.shipmentTransportationCharges.getIsLoading);
  const isError = useSelector(R.selectors.shipmentTransportationCharges.getIsError);
  const isPercentage = useSelector(R.selectors.shipmentTransportationCharges.getIsPercentage);
  const successCreation = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSuccessCreation);
  const successDelete = useSelector(R.selectors.shipmentActiveCharge.getIsDeleteSuccess);
  const permissions = useSelector(R.selectors.shipment.getDashboardsPermissions);

  return (
    <ShipmentTransportationChargesComponent
      fetchData={UC.shipmentTransportationCharges.loadDrayageImport}
      onToggle={UC.shipmentTransportationCharges.togglePercentage}
      closeDrawer={UC.shipmentActiveCharge.closeDrawer}
      onLeave={UC.shipmentTransportationCharges.onLeave}
      onClearSuccess={UC.shipmentActiveCharge.onClearSuccess}
      onClearDelete={UC.shipmentActiveCharge.onClearDelete}
      isLoading={isLoading}
      isError={isError}
      isPercentage={isPercentage}
      successCreation={successCreation}
      successDelete={successDelete}
      permissions={permissions}
    />
  );
});

export { ChargesContainer as ImportInlandDeliveryCharges };
