import React, {
  FC, useMemo,
} from 'react';
import Skeleton from 'antd/es/skeleton';
import getOr from 'lodash/fp/getOr';

import { useIsExportOrImport } from 'app-wrapper/hooks';
import { PreparedRoutesDTM, ShipmentEventDTM, TrackerScheduleDTM } from 'shipment-operations/models/dtm';
import { DEPARTURE, ARRIVAL } from 'shipment-operations/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { TrackerHeaderDestination, TrackerHeaderSteps, TrackerHeaderDates } from 'shipment-operations/view/components/ShipmentTrackerHeader/components';
import {
  TrackerHeaderContainer, TrackerHeaderDivider, TrackerHeaderWaypoints,
} from 'shipment-operations/view/components/ShipmentTrackerHeader/ShipmentTrackerHeader.styled';

export interface ITrackerHeaderComponentProps {
  routes: PreparedRoutesDTM
  isPageLoading: boolean
  skeletonHeight: string
  isLoadingSchedules: boolean
  isErrorSchedules: boolean
  schedule: TrackerScheduleDTM
  onClear: () => void
  setDefaultLegs: () => void
  reEvent?: ShipmentEventDTM
}

const TrackerHeaderDrayageComponent: FC<ITrackerHeaderComponentProps> = ({
  routes,
  isPageLoading,
  isLoadingSchedules,
  isErrorSchedules,
  schedule,
  skeletonHeight,
  reEvent,
}) => {
  const { isImport } = useIsExportOrImport();
  const departure = useMemo(() => {
    const name = getOr('', ['route', DEPARTURE, 0, 'location', 'name'], routes);
    const city = getOr('', ['route', DEPARTURE, 0, 'location', 'city'], routes);
    const country = getOr('', ['route', DEPARTURE, 0, 'location', 'country', 'code'], routes);
    const actualTime = getOr(0, ['route', DEPARTURE, 0, 'actual'], routes);
    const estimatedTime = getOr(0, ['route', DEPARTURE, 0, 'estimated'], routes);
    const predictedTime = getOr(0, ['route', DEPARTURE, 0, 'predicted'], routes);
    return {
      name: `${city || name}, ${country}`,
      actualTime,
      estimatedTime: estimatedTime || predictedTime,
    };
  }, [routes]);

  const arrival = useMemo(() => {
    const city = getOr('', ['route', ARRIVAL, 0, 'location', 'name'], routes);
    const name = getOr('', ['route', ARRIVAL, 0, 'location', 'city'], routes);
    const country = getOr('', ['route', ARRIVAL, 0, 'location', 'country', 'code'], routes);
    const actualTime = getOr(0, ['route', ARRIVAL, 0, 'actual'], routes);
    const estimatedTime = getOr(0, ['route', ARRIVAL, 0, 'estimated'], routes);
    const predictedTime = getOr(0, ['route', ARRIVAL, 0, 'predicted'], routes);
    return {
      name: `${city || name}, ${country}`,
      actualTime,
      estimatedTime: estimatedTime || predictedTime,
    };
  }, [routes]);

  if (isPageLoading) {
    return (
      <SkeletonSection height={skeletonHeight} />
    );
  }

  return (
    <TrackerHeaderContainer>
      <TrackerHeaderSteps routes={routes} isDrayage />
      <TrackerHeaderWaypoints>
        <TrackerHeaderDestination
          name={departure.name}
          estimatedTime={departure.estimatedTime}
          actualTime={departure.actualTime}
        />
        <TrackerHeaderDestination
          arrival
          name={arrival.name}
          estimatedTime={arrival.estimatedTime}
          actualTime={arrival.actualTime}
        />
      </TrackerHeaderWaypoints>
      <TrackerHeaderDivider />
      {isLoadingSchedules && (
        <Skeleton />
      )}
      {!isLoadingSchedules && !isErrorSchedules && (
        <TrackerHeaderDates
          earliestCyEmptyRelease={isImport ? reEvent?.estimated?.getDateDMMMYYYYHHmmWithOffset() : schedule.cyAvailable}
          earliestCtoFullDropDate={isImport ? reEvent?.estimated?.getDateDMMMYYYYHHmmWithOffset() : schedule.ctoAvailable}
          portCutoff={isImport ? '' : schedule.terminalCutOff}
          vgmCutoff={isImport ? '' : schedule.vgmCutOff}
          documentCutOff={isImport ? '' : schedule.documentCutOff}
          isImport={isImport}
        />
      )}
    </TrackerHeaderContainer>
  );
};

export { TrackerHeaderDrayageComponent };
