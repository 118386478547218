import uniqBy from 'lodash/fp/uniqBy';
import uniq from 'lodash/fp/uniq';
import { v4 as uuidv4 } from 'uuid';
import notification from 'antd/es/notification';
import { AxiosError } from 'axios';

import i18n from 'app-wrapper/i18n/i18n';
import { BaseController } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import {
  MatchedSubjectTo,
  DataType,
  ContainerAllTypesArraySort,
  CONTAINER_COLUMN,
  priceByContainer,
  priceByBol,
  typeFee,
  ORIGIN, FREIGHT, DESTINATION, SUBJECT_TO, occurrenceConditional, seaMode, ACTIVE, ADDITIONAL, ChargeCodeLoadTypeEnum,
} from 'shipment-operations/constants';
import { ChargeDTM } from 'shipment-operations/models/dtm';
import { GetEstimatedChargesUseCase } from 'shipment-operations/usecases';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { ServerError } from 'app-wrapper/types/ServerError';
import { addRatesCodeFilter, FRT } from 'monetary/constants';
import { InternalServerError } from 'app-wrapper/models/errors';

export class AddRatesController extends BaseController {
  insertAfterKey = (array: DataType[], key: number, newObjects: DataType[]) => {
    const index = array.findIndex((item) => item.key === key);
    if (index !== -1) {
      array.splice(index + 1, 0, ...newObjects);
    }
  }

  getApplicability = (charge: ChargeDTM) => {
    if (charge.subjectTo) {
      return MatchedSubjectTo[charge.subjectTo as keyof typeof MatchedSubjectTo];
    }
    if (charge.chargeCode.code === FRT) {
      return i18n.t('Applicable');
    }
    return '';
  }

  parseCharges = (charges: ChargeDTM[]) => charges.map((charge) => ({
    id: charge.id,
    key: charge.id,
    charge: charge.chargeCode.code,
    chargeCode: charge.chargeCode,
    applicability: this.getApplicability(charge),
    measureBy: charge.chargeCode.code === FRT ? i18n.t('Container') : charge.measureBy,
    currency: 'USD',
    emptyRender: false,
    designation: charge.designation,
    title: false,
  }));

  sortArrayByOrder = (containers: string[], order: string[]): string[] => {
    const orderMap: { [key: string]: number } = {};
    order.forEach((item, index) => {
      orderMap[item] = index;
    });

    return containers.sort((a, b) => {
      const indexA = orderMap[a] !== undefined ? orderMap[a] : order.length;
      const indexB = orderMap[b] !== undefined ? orderMap[b] : order.length;
      return indexA - indexB;
    });
  }

  getEditableData = (type: string) => {
    if (type === CONTAINER_COLUMN) {
      return R.selectors.addRates.getEditableDataByContainer(this.store.getState());
    }
    return R.selectors.addRates.getEditableDataByShipment(this.store.getState());
  }

  setEditableData = (type: string, data: DataType[]) => {
    if (type === CONTAINER_COLUMN) {
      this.dispatch(R.actions.addRates.setEditableDataByContainer(data));
    } else {
      this.dispatch(R.actions.addRates.setEditableDataByShipment(data));
    }
  }

  loadCharges = async (shipmentId: string) => {
    if (!shipmentId) {
      return;
    }
    const companyId = R.selectors.shipment.getShipmentCustomerCompanyId(this.store.getState());
    if (!companyId) {
      return;
    }
    this.dispatch(R.actions.addRates.setIsLoading(true));
    const charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, companyId);
    this.dispatch(R.actions.addRates.setCharges(charges));

    const chargeCodes = await R.services.shipmentActiveCharge.getFullListCharges();
    const filteredChargeCodes = chargeCodes.filter((charge) => seaMode.includes(charge.mode) && addRatesCodeFilter.includes(charge.type) && charge.status === ACTIVE && charge.loadType !== ChargeCodeLoadTypeEnum.DRAYAGE);
    const withoutFRT = filteredChargeCodes.filter((charge) => charge.code !== FRT);
    const withoutAdditional = withoutFRT.filter((charge) => charge.occurrence !== ADDITIONAL);
    const frtCharge = chargeCodes.find((charge) => charge.code === FRT);
    this.dispatch(R.actions.addRates.setFRTCharge(frtCharge));
    this.dispatch(R.actions.addRates.setChargeCodes(withoutAdditional));

    const fullShipment = await R.services.shipment.getFullShipment(shipmentId);
    if (fullShipment) {
      const { transportationPlans } = fullShipment;
      this.dispatch(R.actions.addRates.setTransportationPlans(transportationPlans[0]));
    }

    const chargesWithoutAdditional = charges.filter((charge) => charge.chargeCode.occurrence !== ADDITIONAL);

    const containersCharges = chargesWithoutAdditional.filter((charge) => charge.applied && charge.active && !charge.additional && (charge.chargeCode.type !== typeFee) && (charge.priceBy === priceByContainer));
    const shipmentCharges = chargesWithoutAdditional.filter((charge) => charge.applied && charge.active && !charge.additional && (charge.chargeCode.type !== typeFee) && (charge.priceBy === priceByBol));
    const uniqByContainer = uniqBy((item) => item.container.originalType, containersCharges);
    const uniqContainers = uniq(uniqByContainer.map((charge) => charge.container.originalType)).map((item) => item);
    const sortedContainers = this.sortArrayByOrder(uniqContainers as string[], ContainerAllTypesArraySort);
    this.dispatch(R.actions.addRates.setUniqContainers(sortedContainers));
    const basicTable: DataType[] = [
      {
        key: 1,
        charge: '',
        applicability: '',
        measureBy: '',
        currency: '',
        title: 'Origin',
        emptyRender: true,
      },
      {
        key: 2,
        charge: '',
        applicability: '',
        measureBy: '',
        currency: '',
        title: 'Freight',
        emptyRender: true,
      },
      {
        key: 3,
        charge: '',
        applicability: '',
        measureBy: '',
        currency: '',
        title: 'Destination',
        emptyRender: true,
      },
    ];

    const containersTableData = [...basicTable];
    const shipmentTableData = [...basicTable];

    const containersParsedCharges = this.parseCharges(uniqBy((item) => item.chargeCode.code, containersCharges));
    const shipmentParsedCharges = this.parseCharges(uniqBy((item) => item.chargeCode.code, shipmentCharges));

    const originChargesContainer = containersParsedCharges.filter((charge) => charge.designation === ORIGIN);
    const freightChargesContainer = containersParsedCharges.filter((charge) => charge.designation === FREIGHT);
    const destinationChargesContainer = containersParsedCharges.filter((charge) => charge.designation === DESTINATION);

    const originChargesShipment = shipmentParsedCharges.filter((charge) => charge.designation === ORIGIN);
    const freightChargesShipment = shipmentParsedCharges.filter((charge) => charge.designation === FREIGHT);
    const destinationChargesShipment = shipmentParsedCharges.filter((charge) => charge.designation === DESTINATION);

    const indexToMove = freightChargesContainer.findIndex((charge) => charge.charge === FRT);

    if (indexToMove !== -1) {
      const [objectToMove] = freightChargesContainer.splice(indexToMove, 1);
      freightChargesContainer.unshift(objectToMove);
    }

    this.insertAfterKey(containersTableData, 1, originChargesContainer);
    this.insertAfterKey(containersTableData, 2, freightChargesContainer);
    this.insertAfterKey(containersTableData, 3, destinationChargesContainer);

    this.insertAfterKey(shipmentTableData, 1, originChargesShipment);
    this.insertAfterKey(shipmentTableData, 2, freightChargesShipment);
    this.insertAfterKey(shipmentTableData, 3, destinationChargesShipment);

    containersTableData.forEach((obj) => {
      sortedContainers.forEach((key) => {
        if (key && obj.applicability === 'Included') {
          obj[key] = 0;
        }
      });
    });
    shipmentTableData.forEach((obj) => {
      if (obj.applicability === 'Included') {
        obj.price = 0;
      }
    });
    this.dispatch(R.actions.addRates.setEditableDataByContainer(containersTableData));
    this.dispatch(R.actions.addRates.setEditableDataByShipment(shipmentTableData));
    this.dispatch(R.actions.addRates.setIsLoading(false));
  }

  deleteCharge = (id: number, type: string) => {
    const editableData = this.getEditableData(type);
    const newEditableData = editableData.filter((charge) => charge.id !== id);
    this.setEditableData(type, newEditableData);
  }

  addCharge = (title: string, type: string) => {
    const editableData = this.getEditableData(type);
    const id = uuidv4();
    const newCharge = {
      key: id,
      id,
      charge: '',
      applicability: '',
      measureBy: '',
      currency: 'USD',
      title: false,
      emptyRender: false,
      designation: title,
    };
    const index = editableData.findIndex((item) => item.title === title);
    const newData = [...editableData];
    newData.splice(index + 1, 0, newCharge);
    this.setEditableData(type, newData);
  }

  onChangeCharge = (row: DataType, type: string) => {
    const editableData = this.getEditableData(type);
    const newData = [...editableData];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, row);
    this.setEditableData(type, newData);
  }

  onChangeHeader = (value: string, name: string) => {
    const headerErrors = R.selectors.addRates.getHeaderErrors(this.store.getState());
    if (headerErrors.includes(name)) {
      this.dispatch(R.actions.addRates.setHeaderErrors(headerErrors.filter((error) => error !== name)));
    }
    this.dispatch(R.actions.addRates.setHeaderValue({ value, name }));
  }

  onClear = () => {
    this.dispatch(R.actions.addRates.clear());
  }

  saveCharges = async (shipmentId: string, type: string) => {
    const validFrom = R.selectors.addRates.getValidFrom(this.store.getState());
    const validTo = R.selectors.addRates.getValidTo(this.store.getState());
    const contractNumber = R.selectors.addRates.getContractNumber(this.store.getState());

    let errors: string[] = [];

    const headerFields = [
      { name: 'validFrom', value: validFrom },
      { name: 'validTo', value: validTo },
      { name: 'contractNumber', value: contractNumber },
    ];

    headerFields.forEach((value) => {
      if (!value.value) {
        errors = [...errors, value.name];
      }
    });

    if (errors.length) {
      this.dispatch(R.actions.addRates.setHeaderErrors(errors));
      return;
    }

    const shipmentData = R.selectors.addRates.getEditableDataByShipment(this.store.getState());
    const filteredShipmentCharges = shipmentData.filter((charge) => !charge.title);
    const containersData = R.selectors.addRates.getEditableDataByContainer(this.store.getState());
    const filteredContainersCharges = containersData.filter((charge) => !charge.title && charge?.chargeCode?.code !== 'FRT');
    const uniqContainers = R.selectors.addRates.getUniqContainers(this.store.getState());

    const requiredFieldsShipment = ['charge', 'applicability', 'measureBy', 'price'];
    const requiredFieldsContainers = ['charge', 'applicability', 'measureBy', ...uniqContainers];

    const isShipmentTabError = !filteredShipmentCharges.every((obj) => requiredFieldsShipment.every((key) => Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== ''));
    const isContainerTabError = !filteredContainersCharges.every((obj) => requiredFieldsContainers.every((key) => Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== ''));

    this.dispatch(R.actions.addRates.setTabError({ tab: 'shipmentTabError', value: isShipmentTabError }));

    this.dispatch(R.actions.addRates.setTabError({ tab: 'containerTabError', value: isContainerTabError }));

    if (isShipmentTabError || isContainerTabError) {
      return;
    }

    this.dispatch(R.actions.addRates.setAddChargesLoading(true));

    const chargeCodes = R.selectors.addRates.getChargeCodes(this.store.getState());
    const scac = R.selectors.shipment.getShipmentCarrierSCAC(this.store.getState());
    const applicationLevel = R.selectors.addRates.getApplicationLevel(this.store.getState());
    const preparedShipmentCharges = filteredShipmentCharges.map((charge) => ({
      chargeCode: chargeCodes.find((code) => code.code === charge.charge),
      designation: charge.designation.toUpperCase(),
      priceBy: 'BOL',
      measureBy: charge.measureBy,
      buyPrice: charge.price,
      currency: 'USD',
      subjectTo: charge.applicability === SUBJECT_TO ? occurrenceConditional : charge?.applicability?.toUpperCase(),
      validPeriod: {
        from: validFrom,
        to: validTo,
      },
      scac,
      applicationLevel,
    }));

    const FRTChargeCode = R.selectors.addRates.getFRTChargeCode(this.store.getState());
    const FRTCharge = R.selectors.addRates.getFRTCharge(this.store.getState());
    const bookValidity = R.selectors.addRates.getBookValidity(this.store.getState());
    const transportLegs = R.selectors.addRates.getTransportLegsFiltered(this.store.getState());

    const body = uniqContainers.map((container) => {
      const containersCharges = filteredContainersCharges.map((charge) => ({
        chargeCode: chargeCodes.find((code) => code.code === charge.charge),
        designation: charge.designation.toUpperCase(),
        priceBy: 'CONTAINER',
        measureBy: charge.measureBy,
        buyPrice: charge[container],
        currency: 'USD',
        containerType: container,
        subjectTo: charge.applicability === SUBJECT_TO ? occurrenceConditional : charge?.applicability?.toUpperCase(),
        validPeriod: {
          from: validFrom,
          to: validTo,
        },
        applicationLevel,
        scac,
      }));

      return ({
        contract: {
          name: contractNumber,
          number: contractNumber,
          scac,
        },
        productCode: 'CONTRACT',
        chargeCode: FRTChargeCode,
        designation: 'FREIGHT',
        applicationLevel,
        priceBy: 'CONTAINER',
        measureBy: 'CONTAINER_TYPE',
        currency: 'USD',
        loadType: 'FCL',
        unitType: container,
        buyPrice: FRTCharge?.[container],
        validPeriod: {
          from: validFrom,
          to: validTo,
        },
        transportLegs,
        commodityGroup: {
          code: '9999',
          type: 'NON_HAZARDOUS',
          fakCommodityGroup: true,
          generalCommodityGroup: true,
        },
        bookValidityDate: bookValidity,
        surcharges: [
          ...preparedShipmentCharges,
          ...containersCharges,
        ],
      });
    });
    try {
      await R.services.addRates.addRates(body);
      await new GetEstimatedChargesUseCase(this).getEstimatedCharges(shipmentId, type);
      new DrawersUseCase(this).closeDrawer();
    } catch (e) {
      const error = e as AxiosError<ServerError>;
      if (error instanceof InternalServerError) {
        notification.error({
          message: i18n.t('No matching rates have been found. Please validate that the information you have provided is correct.'),
          placement: 'bottomRight',
          bottom: 60,
          duration: null,
        });
        this.dispatch(R.actions.addRates.setAddChargesLoading(false));
        throw e;
      }
      this.dispatch(R.actions.addRates.setAddChargesLoading(false));
      throw e;
    }
    this.dispatch(R.actions.addRates.setAddChargesLoading(false));
  }
}
