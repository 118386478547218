import React, { FC, useCallback } from 'react';

import { Select, Option } from 'app-wrapper/view/components';
import { US_COUNTRY_CODE } from 'app-wrapper/constants';
import states from 'app-wrapper/constants/states.json';

type State = {
  code: string,
  name: string
}

type States = Record<string, State[]>;

interface IShippingPartiesStateInputComponentProps {
  state?: string;
  country?: string;
  setState: (state: string) => void;
  error?: boolean;
  isAbleToEdit: boolean;
}

export const StateInputComponent: FC<IShippingPartiesStateInputComponentProps> = ({
  state,
  country,
  setState,
  error,
  isAbleToEdit,
}) => {
  const handleChange = useCallback((_state: string) => {
    setState(_state);
  }, [setState]);

  const options = country ? (states as States)[country] : [] as State[];

  return (
    <Select
      value={state}
      onChange={handleChange}
      error={error && !state?.length && country === US_COUNTRY_CODE}
      disabled={!isAbleToEdit}
    >
      {options?.map((item) => (
        <Option
          value={item.code}
          key={item.code}
        >
          {item.name}
        </Option>
      ))}
    </Select>
  );
};
