import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import {
  NotificationTaskContentDTM,
  NotificationTaskDTM, NotificationViewedDTM,
  NotificationViewedFiltersDTM,
} from 'app-wrapper/models/dtm';
import { ICommandCenterInitialState } from 'app-wrapper/models/states';

const initialState: ICommandCenterInitialState = {
  currentState: NotificationTaskDTM.fromPlain({
    content: [],
  }),
  notificationCurrent: NotificationViewedDTM.fromPlain({
    content: [],
  }),
};

export const CommandCenter = createSlice({
  name: 'CommandCenter',
  initialState,
  reducers: {
    setIsTaskFirstRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: isTaskFirstRequest } = action;

      return {
        ...state,
        isTaskFirstRequest,
      };
    },
    setIsNotificationFirstRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: isNotificationFirstRequest } = action;

      return {
        ...state,
        isNotificationFirstRequest,
      };
    },

    setDefaultCurrentState: (state) => ({
      ...state,
      currentState: {
        ...initialState.currentState,
      },
    }),

    setTotalPages: (state, action: PayloadAction<number>) => {
      const { payload: totalPages } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          totalPages,
        },
      };
    },

    setCurrentStatusPage: (state, action: PayloadAction<string | undefined>) => {
      const { payload: statusPage } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          statusPage,
        },
      };
    },

    setTotalElements: (state, action: PayloadAction<number>) => {
      const { payload: totalElements } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          totalElements,
        },
      };
    },

    setTotalElementsOverdue: (state, action: PayloadAction<number>) => {
      const { payload: totalElementsOverdue } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          totalElementsOverdue,
        },
      };
    },

    setTotalElementsToday: (state, action: PayloadAction<number>) => {
      const { payload: totalElementsToday } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          totalElementsToday,
        },
      };
    },

    setTotalElementsTisWeek: (state, action: PayloadAction<number>) => {
      const { payload: totalElementsThisWeek } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          totalElementsThisWeek,
        },
      };
    },

    setTotalElementsLater: (state, action: PayloadAction<number>) => {
      const { payload: totalElementsLater } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          totalElementsLater,
        },
      };
    },

    setInfoCountAllTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountAllTasks } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          infoCountAllTasks,
        },
      };
    },

    setInfoCountOverdueTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountOverdueTasks } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          infoCountOverdueTasks,
        },
      };
    },

    setInfoCountDueTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountDueTasks } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          infoCountDueTasks,
        },
      };
    },

    setInfoCountPlannedTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountPlannedTasks } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          infoCountPlannedTasks,
        },
      };
    },

    setCurrentPage: (state, action: PayloadAction<number>) => {
      const { payload: currentPage } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          currentPage,
        },
      };
    },

    setCurrentStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload: status } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          status,
        },
      };
    },

    setCurrentCriticality: (state, action: PayloadAction<string[] | undefined>) => {
      const { payload: criticality } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          criticality,
        },
      };
    },

    setCurrentStatusAddTasks: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload: statusAdd } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          statusAdd,
        },
      };
    },

    setCurrentIsNextFirstRunDownload: (state, action: PayloadAction<boolean>) => {
      const { payload: isNextFirstRunDownload } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          isNextFirstRunDownload,
        },
      };
    },

    setContent: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: content } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          content,
        },
      };
    },

    setContentOverdue: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: contentOverdue } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          contentOverdue,
        },
      };
    },

    setContentToday: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: contentToday } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          contentToday,
        },
      };
    },

    setContentThisWeek: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: contentThisWeek } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          contentThisWeek,
        },
      };
    },

    setContentLater: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: contentLater } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          contentLater,
        },
      };
    },

    setTasksFilters: (state, action: PayloadAction<NotificationViewedFiltersDTM>) => {
      const { payload: filters } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters,
        },
      };
    },

    addContent: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: content } = action;

      const useIds = state.currentState?.content.map((item) => item.id).filter((item) => item) || [];

      return {
        ...state,
        currentState: {
          ...state.currentState,
          content: [
            ...state.currentState?.content || [],
            ...content?.filter((item) => !useIds.includes(item.id))?.map((item) => item) || [],
          ],
        },
      };
    },

    setDefaultNotificationCurrentState: (state) => ({
      ...state,
      notificationCurrent: {
        ...initialState.notificationCurrent,
      },
    }),

    setNotificationFilters: (state, action: PayloadAction<NotificationViewedFiltersDTM>) => {
      const { payload: filters } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          filters,
        },
      };
    },

    setNotificationTotalPages: (state, action: PayloadAction<number>) => {
      const { payload: totalPages } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          totalPages,
        },
      };
    },

    setNotificationTotalElements: (state, action: PayloadAction<number>) => {
      const { payload: totalElements } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          totalElements,
        },
      };
    },

    setNotificationCurrentPage: (state, action: PayloadAction<number>) => {
      const { payload: currentPage } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          currentPage,
        },
      };
    },

    setNotificationCurrentStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload: status } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          status,
        },
      };
    },

    setNotificationCurrentStatusAddTasks: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload: statusAdd } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          statusAdd,
        },
      };
    },

    setCountUnreadNotifications: (state, action: PayloadAction<number>) => {
      const { payload: countUnreadNotifications } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          countUnreadNotifications,
        },
      };
    },

    setCountImportantNotifications: (state, action: PayloadAction<number>) => {
      const { payload: countImportantNotifications } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          countImportantNotifications,
        },
      };
    },

    setCountRegularNotifications: (state, action: PayloadAction<number>) => {
      const { payload: countRegularNotifications } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          countRegularNotifications,
        },
      };
    },

    setNotificationContent: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: content } = action;

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          content,
        },
      };
    },

    addNotificationContent: (state, action: PayloadAction<NotificationTaskContentDTM[]>) => {
      const { payload: content } = action;

      const useIds = state.notificationCurrent?.content.map((item) => item.id).filter((item) => item) || [];

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          content: [
            ...state.notificationCurrent?.content || [],
            ...content?.filter((item) => !useIds.includes(item.id))?.map((item) => item) || [],
          ],
        },
      };
    },

    onCheckNotificationViewedById: (state, action: PayloadAction<{
      id: string
      viewedId: string
    }>) => {
      const { id, viewedId } = action.payload;

      const newContent = state.notificationCurrent?.content.map((item) => {
        if (`${item.id}` === id) {
          return {
            ...item,
            view: true,
            viewId: viewedId,
          };
        }

        return item;
      });

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          content: newContent,
        },
      };
    },

    onCheckUnviewedNotificationViewedById: (state, action: PayloadAction<string>) => {
      const { payload: id } = action;

      const newContent = state.notificationCurrent?.content.map((item) => {
        if (`${item.id}` === id) {
          return {
            ...item,
            view: false,
            viewId: undefined,
          };
        }

        return item;
      });

      return {
        ...state,
        notificationCurrent: {
          ...state.notificationCurrent,
          content: newContent,
        },
      };
    },
  },
});

export const commandCenterReducer = CommandCenter.reducer;
export const commandCenterActions = CommandCenter.actions;
