import styled from 'styled-components';
import Table from 'antd/es/table';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { BodyTextMedium, TitleText, BodyTextRegular } from 'app-wrapper/view/fonts';

export const Wrap = styled.div``;

export const TableWrap = styled.div`
  padding: 0 16px;
`;

export const Title = styled.span`
  ${TitleText}
  margin-bottom: 12px;
`;

export const SubTitle = styled.span`
  ${BodyTextMedium}
  margin-bottom: 12px;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${colors.purple15};
    border-top: 1px solid ${colors.purple15};
  }

  .ant-table-thead .ant-table-cell {
    ${BodyTextRegular}
    color: ${colors.gray61};

    &:last-of-type {
      padding-right: 0;
    }
  }

  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }

  .ant-table-tbody > tr:first-of-type > td {
    border-top: none;
  }

  .ant-table-tbody .ant-table-cell {
    &:last-of-type {
      padding-right: 1px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }

  .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding: 0;
  }

  .ant-table-cell::before {
    content: none !important;
  }
`;
