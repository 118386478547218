import styled from 'styled-components';

import {
  CommodityRowsHeader as CommonRowsHeader,
  CommodityRowsHeaderNoHazmat as CommonRowsHeaderNoHazmat,
} from 'shipment-operations/view/components/BookingDrawer/components/Commodity/Commodity.styled';

export const CommodityRowsHeader = styled(CommonRowsHeader)`
  grid-template-columns: 205px 205px 95px 95px 95px 95px 103px 103px 32px;
`;

export const CommodityRowsHeaderNoHazmat = styled(CommonRowsHeaderNoHazmat)`
  grid-template-columns: 194px 194px 194px 104px 104px 104px 104px 32px;
`;
