import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const ArrowRightSvg = ({ width, height, fill }: IPropsIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill || 'currentColor'}
    viewBox="0 0 14 14"
  >
    <path
      fillRule="evenodd"
      d="M4.895 3.339a.778.778 0 000 1.1L7.455 7l-2.56 2.561a.778.778 0 001.1 1.1l3.11-3.111a.778.778 0 000-1.1l-3.11-3.111a.778.778 0 00-1.1 0z"
      clipRule="evenodd"
    />
  </svg>
);
