import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';

import { Button } from 'app-wrapper/view/components';
import {
  ContainerReeferTypes,
  ContainerReeferTypesArray,
  ContainerReeferTypesNamesLongConst,
  ContainerUsualTypes,
  ContainerUsualTypesArray,
  ContainerUsualTypesNamesLongConst,
} from 'shipment-operations/constants';

interface IAddContainerComponentProps {
  isLoading: boolean
  isTemperatureControlled: boolean
  onAddContainer: (type: ContainerReeferTypes | ContainerUsualTypes) => void
}

export const AddContainerComponent: FC<IAddContainerComponentProps> = ({
  isLoading,
  isTemperatureControlled,
  onAddContainer,
}) => {
  const { t } = useTranslation();

  const onAdd = useCallback((type: ContainerReeferTypes | ContainerUsualTypes) => () => {
    onAddContainer(type);
  }, [onAddContainer]);

  const menu = useMemo(() => (
    <Menu>
      {(isTemperatureControlled ? ContainerReeferTypesArray : ContainerUsualTypesArray).map((item) => (
        <Menu.Item key={item}>
          <Button
            size="small"
            type="text"
            onClick={onAdd(item)}
          >
            {(isTemperatureControlled
              ? ContainerReeferTypesNamesLongConst[item as ContainerReeferTypes]
              : ContainerUsualTypesNamesLongConst[item as ContainerUsualTypes])}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  ), [isTemperatureControlled]);

  return (
    <Dropdown overlay={menu}>
      <Button
        type="default"
        icon={<PlusOutlined />}
        size="middle"
        loading={isLoading}
      >
        {t('addContainer')}
      </Button>
    </Dropdown>
  );
};
