import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { Footer } from 'app-wrapper/view/drawers/components';

import {
  LoadingMethod,
  LoadingTime,
  ReceivingMethod,
} from './components';
import { Drawer, Content } from './DrayageLoadingControlsDrawer.styled';

const DRAWER_WIDTH = 736;

interface DrayageLoadingControlsDrawerComponentProps {
  onClose: () => void
  onSave: (shipmentId: string) => void;
  isLoading: boolean
}

export const DrayageLoadingControlsDrawerComponent: FC<DrayageLoadingControlsDrawerComponentProps> = ({
  onClose,
  isLoading,
  onSave,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleSave = useCallback(() => {
    onSave(shipmentId);
  }, [shipmentId, onSave]);

  return (
    <Drawer
      visible
      title={t('Edit Shipping Method')}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Content>
          <LoadingMethod />
          <LoadingTime />
          <ReceivingMethod />
        </Content>
      )}

      <Footer
        onAccept={handleSave}
        closeDrawer={onClose}
        isLoading={isLoading}
        okText={t('Save')}
        isAbleToAccept
      />
    </Drawer>
  );
};
