import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { ChangeBookingScheduleDrawerComponent } from './ChangeBookingScheduleDrawer.component';

const ChangeBookingScheduleDrawerContainer = React.memo(() => {
  const { shipmentId = '0' } = useParams();
  const isLoading = useSelector(R.selectors.changeBookingSchedule.getIsLoading);
  const schedules = useSelector(R.selectors.changeBookingSchedule.getSchedules);
  const isChangeQuotaMode = useSelector(R.selectors.changeBookingSchedule.getIsChangeQuotaMode);

  return (
    <ChangeBookingScheduleDrawerComponent
      onClose={appUC.drawer.closeDrawer}
      onClear={UC.changeBookingScheduleDrawer.clearRequestsTimeoutsAndIntervals}
      shipmentId={shipmentId}
      init={UC.changeBookingScheduleDrawer.getInitialRFQRequestData}
      isLoading={isLoading}
      schedules={schedules}
      isChangeQuotaMode={isChangeQuotaMode}
    />
  );
});

export { ChangeBookingScheduleDrawerContainer as ChangeBookingScheduleDrawer };
