import BaseInfiniteScroll from 'react-infinite-scroll-component';
import { Props } from 'react-infinite-scroll-component/src';

export class InfiniteScroll extends BaseInfiniteScroll {
  triggerNext() {
    // no access to private vars in ts
    // @ts-ignore
    this.actionTriggered = true;
    if (this.props.next) {
      this.props.next();
    }
  }

  componentDidMount() {
    super.componentDidMount();
    // @ts-ignore
    if (this.el.scrollHeight === this.el.clientHeight && this.props.hasMore && this.props.dataLength !== 0) {
      this.triggerNext();
    }
  }

  componentDidUpdate(prevProps: Props) {
    super.componentDidUpdate(prevProps);
    // no access to private vars in ts
    // @ts-ignore
    if (this.props.dataLength !== prevProps.dataLength && this.el.scrollHeight === this.el.clientHeight && this.props.hasMore) {
      this.triggerNext();
    }
  }
}
