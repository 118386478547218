import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';

import { ISFTaskDrawerComponent } from './ISFTaskDrawer.component';

interface IISFTaskDrawerContainerProps {
  taskId: string;
}

const ISFTaskDrawerContainer: FC<IISFTaskDrawerContainerProps> = observer(({ taskId }) => {
  const { isfDrawer } = useMobxStore();
  const { isLoading, task } = isfDrawer.state;

  return (
    <ISFTaskDrawerComponent
      init={UC.isfDrawer.init}
      isLoading={isLoading}
      onClose={UC.drawer.closeDrawer}
      onAccept={UC.isfDrawer.acceptISFTask}
      taskId={taskId}
      task={task}
    />
  );
});

export { ISFTaskDrawerContainer as ISFTaskDrawer };
