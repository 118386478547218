import styled from 'styled-components';

import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import AntTag from 'antd/es/tag';

import {
  BodyTextRegular,
  SmallTextFootnoteDescription,
} from 'app-wrapper/view/fonts';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Item = styled(FlexRow)`
  position: relative;
  gap: 16px;
`;

export const Text = styled.span`
  ${BodyTextRegular}
`;

export const CheckIcon = styled(CheckCircleOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.themesColors.characterBrandingSuccess};
`;

export const WarningIcon = styled(ExclamationCircleOutlined)`
  font-size: 24px;
  color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
`;

export const Tag = styled(AntTag)`
  ${SmallTextFootnoteDescription}
  position: absolute;
  bottom: -20px;
  left: 40px;
  height: 20px;
  border-color: ${({ theme }) => theme.themesColors.yellowBranding4};
  color: ${({ theme }) => theme.themesColors.yellowBranding8};
`;
