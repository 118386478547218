import styled from 'styled-components';
import Collapse from 'antd/es/collapse';
import Typography from 'antd/es/typography';
import FolderOutlined from '@ant-design/icons/FolderOutlined';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';
import AntTable from 'antd/es/table';

export const DocumentCollapse = styled(Collapse)`
  &.ant-collapse {
    padding: 16px 0;
    border: none;
    border-top: 1px solid rgba(244, 245, 248, 1);
    background-color: transparent;
  }
`;

export const CollapsePanel = styled(Collapse.Panel)`
  &.ant-collapse-item, &.ant-collapse-header {
    background-color: transparent;
    border: none;
  }

  &.ant-collapse-header > div:first-of-type > span {
    font-size: 10px;
    vertical-align: 0;
  }

  div[role="button"].ant-collapse-header {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse-content {
    padding-left: 48px;
    padding-top: 16px;
    border-top: none;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`;

export const CollapseLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CollapseLabelText = styled(Typography).attrs((props: {
  isCollapsed: boolean,
}) => ({
  isCollapsed: props.isCollapsed,
}))`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${({ isCollapsed }) => (isCollapsed ? 'rgba(108, 121, 222, 1)' : 'rgba(32, 44, 60, 1)')}
`;

export const CollapseIconActive = styled(FolderOpenOutlined)`
  color: rgba(108,121,222,1);
`;

export const CollapseIcon = styled(FolderOutlined)``;

export const TableDocuments = styled(AntTable).attrs({
  'data-class': 'SDA_TableDocuments',
})`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  
  .ant-table-thead .ant-table-cell {
    padding: 8px 0px;
    border-top: 1px solid rgba(234, 238, 248, 1);
    border-bottom: 1px solid rgba(234, 238, 248, 1);
    color: rgba(115, 129, 155, 1);
    background-color: transparent;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }

  .ant-table-tbody .ant-table-cell {
    background-color: white !important;
    padding: 12px 0;
    color: rgba(32, 44, 60, 1);
    font-size: 14px;
    line-height: 22px;
    border-bottom: 1px solid rgba(234, 238, 248, 1);
  }

  .ant-table-tbody > tr:last-of-type .ant-table-cell {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-table-placeholder > .ant-table-cell {
    border-bottom: 0px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const BoldText = styled(Typography)`
  font-weight: 500;
`;

export const TableCell = styled.div``;
