import React, { FC, ReactNode } from 'react';

import { InfoSvg } from 'app-wrapper/view/icons/InfoSvg';
import {
  Warning,
  WarningIcon,
  WarningText,
  Content,
  WarningTitle,
  IconContainer,
} from './WarningBlock.styled';

interface WarningBlockProps {
  title?: string;
  text?: string;
  content?: ReactNode;
  type?: 'warning' | 'info'
}

export const WarningBlock: FC<WarningBlockProps> = ({
  text, title, content, type,
}) => (
  <Warning type={type}>
    <IconContainer>
      {type === 'info' ? <InfoSvg /> : <WarningIcon /> }
    </IconContainer>

    <Content>
      {title
        ? (
          <WarningTitle>
            {title}
          </WarningTitle>
        )
        : null}

      {content}

      {text ? (
        <WarningText>
          {text}
        </WarningText>
      ) : null}
    </Content>
  </Warning>
);
