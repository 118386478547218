import i18n from 'app-wrapper/i18n/i18n';
import { IDoubleRadioOption } from 'app-wrapper/view/guideline';
import { EShipmentLoadingMethod, EShipmentReceivingMethod } from 'shipment-operations/constants';

export enum EDrayageLoadingSide {
  ORIGIN_DRAYAGE = 'ORIGIN_DRAYAGE',
  DESTITATION_DRAYAGE = 'DESTITATION_DRAYAGE',
}

export const LOADING_METHOD_NAMES: Record<EShipmentLoadingMethod, string> = {
  [EShipmentLoadingMethod.LIVE_LOAD]: i18n.t('Live Load'),
  [EShipmentLoadingMethod.DROP_AND_PICKUP]: i18n.t('Drop and Pick'),
};

export const RECEIVING_METHOD_NAMES: Record<EShipmentReceivingMethod, string> = {
  [EShipmentReceivingMethod.FIRST_COME_FIRST_SERVE]: i18n.t('First Come / First Serve (FCFS)'),
  [EShipmentReceivingMethod.APPOINTMENT]: i18n.t('Appointment'),
};

export const LOADING_METHODS: [IDoubleRadioOption, IDoubleRadioOption] = [
  {
    value: EShipmentLoadingMethod.LIVE_LOAD,
    title: i18n.t('Live Load'),
    text: i18n.t('The container is loaded/unloaded while the vehicle waits on-site within a set time frame (maximum 4 hours)'),
  },
  {
    value: EShipmentLoadingMethod.DROP_AND_PICKUP,
    title: i18n.t('Drop and Pick'),
    text: i18n.t('The container is dropped off and picked up later after loading/unloading is complete.'),
  },
];

export const RECEIVING_METHODS: [IDoubleRadioOption, IDoubleRadioOption] = [
  {
    value: EShipmentReceivingMethod.FIRST_COME_FIRST_SERVE,
    title: i18n.t('First Come / First Serve (FCFS)'),
    text: i18n.t('Containers are handled in the order of arrival, with no prior appointment required'),
  },
  {
    value: EShipmentReceivingMethod.APPOINTMENT,
    title: i18n.t('Appointment'),
    text: i18n.t('A scheduled time is set for the container to be loaded/unloaded, ensuring minimal waiting time'),
  },
];

export const LOADING_TIME_NAMES: Record<number, string> = {
  1: i18n.t('1 hour'),
  2: i18n.t('2 hours'),
  3: i18n.t('3 hours'),
  4: i18n.t('4 hours'),
};
