export enum ChargeCodeLoadTypeEnum {
  DRAYAGE = 'DRAYAGE',
  FCL = 'FCL',
  LCL = 'LCL',
  LTL = 'LTL',
  FTL = 'FTL',
  OOG = 'OOG',
  ALL = 'ALL'
}

export enum ShortChargeStatusEnum {
  UN_INVOICED = 'UNINVOICED',
  PARTIALLY_INVOICED = 'PARTIALLY_INVOICED',
  OVER_INVOICED = 'OVER_INVOICED',
  INVOICED = 'INVOICED'
}

export enum ChargeCodeType {
  CHARGE = 'CHARGE',
  SURCHARGE = 'SURCHARGE',
  FEE = 'FEE',
}

export enum ChargeCodeCode {
  PKP = 'PKP',
  FRT = 'FRT',
  DLV = 'DLV',
}

export enum ChargeCodeDesignation {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
  FREIGHT = 'FREIGHT',
}

export enum ChargeCodePriceBy {
  CONTAINER = 'CONTAINER',
  BOL = 'BOL',
}

export enum ChargeCodeMeasureBy {
  CONTAINER_TYPE = 'CONTAINER_TYPE',
  WM = 'WM',
  KG_LB = 'KG_LB',
  DAY = 'DAY',
  CBM_CF = 'CBM_CF',
  MILE = 'MILE',
  KM = 'KM',
  HOUR = 'HOUR',
  KM_MILE = 'KM_MILE',
  FLAT = 'FLAT',
}

export enum ChargeCodeMode {
  ROAD = 'ROAD',
  OCEAN = 'OCEAN',
  AIR = 'AIR',
  ALL = 'ALL',
}

export enum ChargeCodeOccurrence {
  MANDATORY = 'MANDATORY',
  ADDITIONAL = 'ADDITIONAL',
  CONDITIONAL = 'CONDITIONAL',
}

export enum ChargeCodeSubType {
  ACCESSORIAL = 'ACCESSORIAL',
  EXCEPTION = 'EXCEPTION',
  FREE_TIME = 'FREE_TIME',
}

export enum ChargeCodeSubjectTo {
  APPLICABLE = 'APPLICABLE',
  INCLUDED = 'INCLUDED',
}

export const ACTIVE = 'ACTIVE';

export const ADDITIONAL = 'ADDITIONAL';

export const SCF = 'SCF';
export const AHF = 'AHF';

export const ACCESSORIAL = 'accessorial';

export const GEN = 'GEN';

export const MatchedSubjectTo = {
  APPLICABLE: 'Applicable',
  INCLUDED: 'Included',
  CONDITIONAL: 'Subject To',
};
