import { IsDefined, IsBoolean } from 'class-validator';

import { EShippingPartyTypes, TradeType } from 'shipment-operations/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { EShipmentOrganizationRole } from 'user-management/constants';

export type ShippingPartiesPermissions = Record<EShippingPartyTypes, ShippingPartyPermissionsDTM>;

export interface IShippingPartyPermissionsDTM {
  isAbleToView: boolean;
  isAbleToEditCompany: boolean;
  isAbleToEditAddressAndContact: boolean;
  isAbleToWriteNRA: boolean;
  isAbleToEditMainFormData: boolean;
  isAbleToRequestChanges: boolean;
}

export class ShippingPartyPermissionsDTM extends BaseDTM<IShippingPartyPermissionsDTM> {
  @IsDefined()
  @IsBoolean()
  isAbleToView: boolean;

  @IsDefined()
  @IsBoolean()
  isAbleToEditCompany: boolean;

  @IsDefined()
  @IsBoolean()
  isAbleToEditAddressAndContact: boolean;

  @IsDefined()
  @IsBoolean()
  isAbleToWriteNRA: boolean;

  @IsDefined()
  @IsBoolean()
  isAbleToEditMainFormData: boolean;

  @IsDefined()
  @IsBoolean()
  isAbleToRequestChanges: boolean;

  static getForbiddenShippingPartyPermissions = () => ShippingPartyPermissionsDTM.fromPlain({
    isAbleToView: false,
    isAbleToEditCompany: false,
    isAbleToEditAddressAndContact: false,
    isAbleToWriteNRA: false,
    isAbleToEditMainFormData: true,
    isAbleToRequestChanges: false,
  });

  static getDefaultPermissions = () => ({
    [EShippingPartyTypes.SHIPPER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.CONSIGNEE]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.NOTIFY_PARTY]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.CUSTOMER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.HOUSE_SHIPPER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.HOUSE_CONSIGNEE]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.EXPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.IMPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.FORWARDER_AGENT]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.DELIVERY_AGENT]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.ACCOUNT_HOLDER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.ULTIMATE_CUSTOMER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.OCEAN_CARRIER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.BOOKING_AGENT]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.CARGO_SUPPLIER]: this.getForbiddenShippingPartyPermissions(),
    [EShippingPartyTypes.CARGO_RECEIVER]: this.getForbiddenShippingPartyPermissions(),
  });

  static getShippingPartyPermissions = (roles: EShipmentOrganizationRole[], isSetBySystemMap: Record<EShippingPartyTypes, boolean>, tradeType?: TradeType, isOriginDoor?: boolean): ShippingPartiesPermissions => {
    if (roles.includes(EShipmentOrganizationRole.CUSTOMER)) {
      return {
        [EShippingPartyTypes.SHIPPER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.CONSIGNEE]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.NOTIFY_PARTY]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: false,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CUSTOMER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: tradeType === TradeType.EXPORT,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: tradeType === TradeType.IMPORT,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.EXPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.IMPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.FORWARDER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DELIVERY_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ACCOUNT_HOLDER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ULTIMATE_CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.OCEAN_CARRIER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.BOOKING_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.CARGO_SUPPLIER]: tradeType === TradeType.IMPORT || isOriginDoor ? ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: false,
          isAbleToEditAddressAndContact: false,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: false,
          isAbleToRequestChanges: true,
        }) : ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: false,
          isAbleToEditAddressAndContact: false,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CARGO_RECEIVER]: tradeType === TradeType.IMPORT || isOriginDoor ? ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: false,
          isAbleToEditAddressAndContact: false,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: false,
          isAbleToRequestChanges: true,
        }) : ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: false,
          isAbleToEditAddressAndContact: false,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
      };
    }

    if (roles.includes(EShipmentOrganizationRole.ORIGIN_PARTNER)) {
      return {
        [EShippingPartyTypes.SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.NOTIFY_PARTY],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CUSTOMER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_NOTIFY_PARTY],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.EXPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.IMPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.FORWARDER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DELIVERY_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ACCOUNT_HOLDER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ULTIMATE_CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.OCEAN_CARRIER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.BOOKING_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.CARGO_SUPPLIER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CARGO_RECEIVER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
      };
    }

    if (roles.includes(EShipmentOrganizationRole.DESTINATION_PARTNER)) {
      return {
        [EShippingPartyTypes.SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.NOTIFY_PARTY],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CUSTOMER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_NOTIFY_PARTY],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.EXPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.IMPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.FORWARDER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DELIVERY_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ACCOUNT_HOLDER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ULTIMATE_CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.OCEAN_CARRIER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.BOOKING_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.CARGO_SUPPLIER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CARGO_RECEIVER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
      };
    }

    if (roles.includes(EShipmentOrganizationRole.ACCOUNT_HOLDER)) {
      return {
        [EShippingPartyTypes.SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.NOTIFY_PARTY],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.CUSTOMER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_SHIPPER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_SHIPPER],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_CONSIGNEE]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_CONSIGNEE],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: !isSetBySystemMap[EShippingPartyTypes.HOUSE_NOTIFY_PARTY],
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.EXPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.IMPORT_BROKER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.FORWARDER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DELIVERY_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ACCOUNT_HOLDER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ULTIMATE_CUSTOMER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: true,
          isAbleToEditAddressAndContact: true,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.OCEAN_CARRIER]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.BOOKING_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: this.getForbiddenShippingPartyPermissions(),
        [EShippingPartyTypes.CARGO_SUPPLIER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: false,
          isAbleToEditAddressAndContact: false,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
        [EShippingPartyTypes.CARGO_RECEIVER]: ShippingPartyPermissionsDTM.fromPlain({
          isAbleToView: true,
          isAbleToEditCompany: false,
          isAbleToEditAddressAndContact: false,
          isAbleToWriteNRA: false,
          isAbleToEditMainFormData: true,
          isAbleToRequestChanges: false,
        }),
      };
    }

    return this.getDefaultPermissions();
  };
}
