import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'antd/es/badge';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { hasAccess } from 'app-wrapper/utils';
import { colors } from 'app-wrapper/view/themes/themesColors';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { Button } from 'app-wrapper/view/components';
import { SubPageHeaderContainer, SubPageHeaderTitle } from 'app-wrapper/view/guideline';

import EditOutlined from '@ant-design/icons/EditOutlined';
import { CargoTitleLeftPart } from './CargoTitle.styled';

const BADGE_STYLE = {
  backgroundColor: 'transparent',
  borderColor: colors.gray43,
  color: 'black',
};

interface ICargoTitleComponentProps {
  amountOfCargos: number
  addCargo: () => void
  openChangeRequestDrawer: () => void
  cargosAvailability?: PermissionAttributePolicy
  customerCargosAvailability?: PermissionAttributePolicy
}

export const CargoTitleComponent: FC<ICargoTitleComponentProps> = ({
  amountOfCargos,
  addCargo,
  cargosAvailability,
  customerCargosAvailability,
  openChangeRequestDrawer,
}) => {
  const { t } = useTranslation();

  const isDisabled = useMemo(() => !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE) || !hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, customerCargosAvailability]);

  const handelOpenChangeRequestDrawer = useCallback(() => {
    openChangeRequestDrawer();
  }, []);

  return (
    <SubPageHeaderContainer>
      <CargoTitleLeftPart>
        <SubPageHeaderTitle>
          {t('Cargo')}
        </SubPageHeaderTitle>
        <Badge count={amountOfCargos} style={BADGE_STYLE} size="default" />
      </CargoTitleLeftPart>
      {
        hasAccess(customerCargosAvailability, PermissionAttributePolicy.WRITE)
          ? (
            <Button
              disabled={isDisabled}
              icon={<PlusOutlined />}
              size="middle"
              onClick={addCargo}
            >
              {t('Add Cargo')}
            </Button>
          )
          : (
            <Button
              icon={<EditOutlined />}
              size="middle"
              onClick={handelOpenChangeRequestDrawer}
            >
              {t('Request Changes')}
            </Button>
          )
      }
    </SubPageHeaderContainer>
  );
};
