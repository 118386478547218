import React, { memo } from 'react';

import { PageComponent } from 'app-wrapper/view/routes/components';
import { createDocumentUploaderDrawerStore } from 'app-wrapper/repository/mobxStores/DocumentUploaderDrawerStore';
import {
  DocumentUploaderDrawerContainer,
} from 'app-wrapper/view/drawers/DocumentUploaderDrawer/DocumentUploaderDrawer.container';
import { DocumentUploaderDrawerController } from 'app-wrapper/controllers/DocumentUploaderDrawerController';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

interface DocumentUploaderDrawerPageProps {
  onAddDocument: (document: ContainerDocumentDTM) => void
}

export const DocumentUploaderDrawerPage = memo(({ onAddDocument }: DocumentUploaderDrawerPageProps) => (
  <PageComponent
    ControllerClass={DocumentUploaderDrawerController}
    StoreFactory={() => {
      const store = createDocumentUploaderDrawerStore();
      store.onAddDocument = onAddDocument;
      return store;
    }}
  >
    <DocumentUploaderDrawerContainer />
  </PageComponent>
));
